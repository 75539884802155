import { takeLatest, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'
import config from '../config'

import {
  UPDATE_GROUP,
  FETCH_BASIC_GROUP_INFO,
  UPDATE_GROUP_ACTIVE,
  updateGroupSuccess,
  updateGroupActiveSuccess,
  fetchGroupBasicInfoSuccess,
  groupEditActionFailed,
  FETCH_GROUP_REPORT_INFO,
  fetchGroupReportsSuccess,
  FETCH_GROUP_EDIT_PERMISSIONS,
  fetchGroupPermissionsSuccess,
  FETCH_GROUP_EDIT_PRODUCTS,
  fetchGroupProductsSuccess,
  TOGGLE_GROUP_PRODUCT,
  toggleGroupProductSuccess,
  FETCH_GROUP_EDIT_FEED_INFO,
  fetchGroupFeedsInfoSuccess
} from '../actions/group-edit-actions'

import { createAlert } from '../actions/app-actions'

function* fetchBasicInfo(action) {
  try {
    const { groupId } = action

    const basicInfo = yield call(() => axios.get(`${config.apiBaseUrl}/admin/group/${groupId}/basic`))

    yield put(fetchGroupBasicInfoSuccess(basicInfo.data))
  } catch (error) {
    console.error(error.message)
    window.captureException(error)
    yield put(groupEditActionFailed(error.message))
  }
}

function* fetchGroupReports(action) {
  try {
    const { groupId } = action

    const groupReports = yield call(() => axios.get(`${config.apiBaseUrl}/admin/group/${groupId}/reports`))

    yield put(fetchGroupReportsSuccess(groupReports.data))
  } catch (error) {
    console.error(error.message)
    window.captureException(error)
    yield put(groupEditActionFailed(error.message))
  }
}

function* fetchFeedsInfo(action) {
  try {
    const { groupId } = action

    const feedsData = yield call(() => axios.get(`${config.apiBaseUrl}/admin/group/${groupId}/feeds`))

    yield put(fetchGroupFeedsInfoSuccess(feedsData.data))
  } catch (error) {
    console.error(error.message)
    window.captureException(error)
    yield put(groupEditActionFailed(error.message))
  }
}

function* fetchGroupPermissions(action) {
  try {
    const { groupId } = action

    const permissionsData = yield call(() => axios.get(`${config.apiBaseUrl}/admin/group/${groupId}/permissions`))

    yield put(fetchGroupPermissionsSuccess(permissionsData.data))
  } catch (error) {
    console.error(error.message)
    window.captureException(error)
    yield put(groupEditActionFailed(error.message))
  }
}

function* fetchGroupProducts(action) {
  try {
    const { groupId } = action

    const productsData = yield call(() => axios.get(`${config.apiBaseUrl}/admin/group/${groupId}/products`))

    yield put(fetchGroupProductsSuccess(productsData.data))
  } catch (error) {
    console.error(error.message)
    window.captureException(error)
    yield put(groupEditActionFailed(error.message))
  }
}

function* updateGroupActive(action) {
  try {
    const { groupId, isActive } = action

    const updateResult = yield call(() =>
      axios.post(`${config.apiBaseUrl}/admin/group/active`, {
        groupId,
        isActive
      })
    )

    yield put(
      createAlert(
        isActive ? 'success' : 'danger',
        `Group ${groupId} has been ${isActive ? 'activated' : 'deactivated'}`,
        `Group ${isActive ? 'Activated' : 'Deactivated'}`
      )
    )

    yield put(updateGroupActiveSuccess(updateResult.data))
  } catch (error) {
    console.error(error.message)
    window.captureException(error)
    yield put(groupEditActionFailed(error.message))
  }
}

function* updateGroup(action) {
  try {
    const { groupId, groupPayload } = action

    const updateResult = yield call(() =>
      axios.post(`${config.apiBaseUrl}/admin/group`, {
        groupId,
        groupPayload
      })
    )

    const newGroup = groupId === 'new'

    yield put(
      createAlert(
        'success',
        newGroup ? `New group ${updateResult.data.id} created` : 'Group updates have been saved',
        newGroup ? 'Group Created' : 'Group Updated'
      )
    )

    yield put(updateGroupSuccess(updateResult.data))
  } catch (error) {
    console.error(error.message)
    window.captureException(error)
    yield put(groupEditActionFailed(error.message))
  }
}

function* toggleGroupProduct(action) {
  try {
    const { groupId, productId, enabled } = action

    const updateResult = yield call(() =>
      axios.post(`${config.apiBaseUrl}/admin/group/product`, {
        groupId,
        productId,
        enabled
      })
    )

    yield put(toggleGroupProductSuccess(updateResult.data))
  } catch (error) {
    console.error(error.message)
    window.captureException(error)
    yield put(groupEditActionFailed(error.message))
  }
}

function* fetchGroupInfoFlow() {
  yield takeLatest(FETCH_BASIC_GROUP_INFO, fetchBasicInfo)
  yield takeLatest(FETCH_GROUP_REPORT_INFO, fetchGroupReports)
  yield takeLatest(FETCH_GROUP_EDIT_FEED_INFO, fetchFeedsInfo)
  yield takeLatest(FETCH_GROUP_EDIT_PERMISSIONS, fetchGroupPermissions)
  yield takeLatest(FETCH_GROUP_EDIT_PRODUCTS, fetchGroupProducts)
}

function* toggleGroupProductFlow() {
  yield takeLatest(TOGGLE_GROUP_PRODUCT, toggleGroupProduct)
}

function* updateGroupActiveFlow() {
  yield takeLatest(UPDATE_GROUP_ACTIVE, updateGroupActive)
}

function* updateGroupFlow() {
  yield takeLatest(UPDATE_GROUP, updateGroup)
}

export default [
  fork(updateGroupFlow),
  fork(fetchGroupInfoFlow),
  fork(updateGroupActiveFlow),
  fork(toggleGroupProductFlow)
]
