/* eslint-disable max-len */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'

/**
 * ArrowNav component
 */
class ArrowNav extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object
  }

  render() {
    return (
      <IconBase viewBox="0 0 30 30" {...this.props}>
        <g id="Arrow-line">
          <rect id="spacing" x="0" y="0" width="30" height="30" />
          <polyline id="Path-2" stroke="currentColor" points="12 10 17.7458624 15.5 12 21" />
        </g>
      </IconBase>
    )
  }
}

ArrowNav.defaultProps = {
  width: 30,
  height: 20,
  fill: 'none'
}

export default ArrowNav
