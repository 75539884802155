import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

import Highlighter from 'react-highlight-words'

export default class ReportsMenuList extends Component {
  static propTypes = {
    options: PropTypes.array,
    keys: PropTypes.array,
    searchText: PropTypes.string,
    valueDidChange: PropTypes.func,
    searchHighlight: PropTypes.bool
  }

  static defaultProps = {
    searchText: '',
    searchHighlight: false,
    valueDidChange: () => {}
  }

  filterValueChanged = id => {
    this.props.valueDidChange(id)
  }

  getListItem = filterValue => {
    const { valueDidChange, searchHighlight, searchText } = this.props

    return (
      <li key={filterValue.id} className="selectedMenuItem" onClick={() => valueDidChange(filterValue.value)}>
        <span>
          {searchHighlight && searchText !== '' ? (
            <Highlighter
              searchWords={[searchText]}
              highlightClassName="highlight-text"
              textToHighlight={filterValue.aliasValue}
            />
          ) : (
            filterValue.aliasValue
          )}
        </span>
      </li>
    )
  }

  getFilterValues = () => {
    const { options = [] } = this.props

    if (options.length === 0) {
      return [
        <li key="no-result" className="no-result-li">
          No results
        </li>
      ]
    }

    return options.map(filterValue => this.getListItem(filterValue))
  }

  render() {
    return <ul className="ReportsMenuList">{this.getFilterValues()}</ul>
  }
}
