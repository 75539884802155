import { createSlice } from '@reduxjs/toolkit'

const merchantFields = createSlice({
  name: 'merchantFields',
  initialState: {
    data: [],
    loading: false
  },
  reducers: {
    fetchMerchantFields(state) {
      state.data = []
      state.loading = true
    },
    successLoadMerchantFields(state, action) {
      state.data = [...action.payload]
      state.loading = false
    },
    faliedLoadMerchantFields(state, action) {
      state.data = []
      state.loading = false
    }
  }
})

export const { fetchMerchantFields, successLoadMerchantFields, faliedLoadMerchantFields } = merchantFields.actions
export const { MERCHANT_FIELDS_STORE_PATH } = 'merchantFields'

export default merchantFields.reducer
