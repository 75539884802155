// @flow
import { keys, findIndex } from 'lodash'

import GroupService from './group-service'

import { products as PRODUCTS } from '../constants/constants'

class UserService {
  /**
   * Returns the account type of a user within a group
   * - Portal Admin: 2
   * - Group Admin: 1
   * - Standard User: 0
   * @param Object groupUser
   * @param Object group
   *
   *@returns integer
   */
  getAccountType(groupUser, group) {
    return groupUser.isAdmin ? (group.isPortalGroup ? 2 : 1) : 0
  }

  /**
   * Takes the groups of a user object and returns the index of the admin group or if not found, the zeroth index
   *
   * @param {[]} [groups=[]]
   * @returns {number} Index of the admin group
   * @memberof UserService
   */
  getAdminIndex(groups: [] = []): number {
    const index = findIndex(groups, group => group.isPortalGroup)
    return index !== -1 ? index : 0
  }

  /**
   * Returns the account type of a user within a group, as an understandable string
   * @param Object groupUser
   * @param Object group
   *
   *@return string
   */
  getAccountTypeString(groupUser, group) {
    const type = this.getAccountType(groupUser, group)

    switch (type) {
      case -1:
        return 'Not on this group'
      case 1:
        return 'Group admin'
      case 2:
        return 'Portal admin'
      case 0:
      default:
        return 'Standard user'
    }
  }

  /**
   * Whether the user is a portal admin (aka Slice admin)
   * - User only has to be admin on 1 portal group
   * @param  {Object}  user
   * @return {Boolean}
   */
  isPortalAdmin(user) {
    return user && user.Groups
      ? Object.values(user.Groups).filter(group => group.isPortalGroup && group.GroupUser.isAdmin).length !== 0
      : false
  }

  /**
   * Whether the user is a regular group admin
   * - User only has to be admin on 1 group
   * @param  {Object}  user
   * @return {Boolean}
   */
  isGroupAdmin(user) {
    return Object.values(user.Groups).filter(group => group.GroupUser.isAdmin).length !== 0
  }

  hasAdminToolsAccess(user) {
    return user && user.products
      ? keys(user.products).filter(key => key.startsWith(PRODUCTS.adminTools) && user.products[key].enabled === true)
          .length > 0
      : false
  }

  /**
   * Whether the user is either a group or portal admin
   * @param  {Object}  user
   * @return {Boolean}
   */
  isAdmin(user) {
    return user ? this.isPortalAdmin(user) || this.isGroupAdmin(user) : false
  }

  isUserActive(user = {}) {
    if (user.Groups === undefined) {
      return false
    }

    let isActive = false

    user.Groups.forEach(group => {
      if (group.GroupUser && group.GroupUser.isActive) {
        isActive = true
      }
    })

    return isActive
  }

  isGroupAdminOnGroup(user, group) {
    return (
      Object.values(user.Groups).filter(item => item.id === group.id && this.getAccountType(item.GroupUser, group) > 0)
        .length > 0
    )
  }

  /**
   * Whether the user can edit the other user
   * - Portal admin can edit all users
   * - Group admin can edit users belonging to their group
   * - Group admin can add users
   * @param  {Object} user     User trying to edit
   * @param  {Object} userEdit User being edited
   * @return {Boolean}
   */
  canEditUser(user, userEdit = null) {
    if (this.isPortalAdmin(user)) {
      return true
    }

    // Group admin can create users
    if (this.isGroupAdmin(user) && userEdit === null) {
      return true
    }

    // Can only edit the user if we are admin on one of the users group
    return Object.values(userEdit.Groups).filter(group => {
      const userGroup = Object.values(user.Groups).filter(item => item.id === group.id)

      if (!userGroup.length) {
        return false
      }

      return this.getAccountType(userGroup[0].GroupUser, group) > 0
    }).length
  }

  /**
   * Gets the groups a user cannot edit on another user
   * @param  {Object}  user
   * @param  {Object}  userEdit
   * @return {Array}
   */
  getNonEditableGroups(user, userEdit) {
    // There is no group we cannot edit as a portal admin or when no user is specified (ie for user creation)
    if (this.isPortalAdmin(user) || userEdit === null) {
      return []
    }

    return Object.values(userEdit.Groups || []).filter(group => {
      const userGroup = Object.values(user.Groups || []).filter(item => item.id === group.id)
      // User trying to edit does not belong to the group
      if (!userGroup.length) {
        return true
      }

      return this.getAccountType(userGroup[0].GroupUser, group) <= 0
    })
  }

  /**
   * Whether given user can add other given user to given group
   * @param  {Object} user      User performing the add action
   * @param  {Object} userToAdd User being added
   * @param  {Object} group     Group to add the user to
   * @return {Boolean}           [description]
   */
  canAddUserToGroup(user, userToAdd = null, group) {
    if (!this.canEditUser(user, userToAdd)) {
      return false
    }

    if (GroupService.getOpenSeatsLeft(group, userToAdd) > 0) {
      return true
    } else {
      return false
    }
  }

  getCustomReports(user) {
    return user && user.customReports ? user.customReports : []
  }

  /**
    Get array of users filtered

    @param {Array} users
    @param {String} filterOnName
    @param {String} filterOnGroupName
    @return {Array} users filtered
  */
  getFilteredUsers(users, filterOnName, filterOnGroupName) {
    let results = users
    if (filterOnName) {
      results = users.filter(user => user.fullname.toLowerCase().indexOf(filterOnName.toLowerCase()) > -1)
    }
    if (filterOnGroupName) {
      results = results.filter(user => user.Group.name.toLowerCase().indexOf(filterOnGroupName.toLowerCase()) > -1)
    }
    return results
  }

  /**
   * Return high level products accessible to user
   *
   * @param {any} user
   * @returns {object} products
   *
   * @memberof UserService
   */
  getProducts(user: Object) {
    return user && user.products ? user.products : {}
  }

  /**
   * Return permissions of user
   *
   * @param {any} user
   * @returns {object} permissions
   *
   * @memberof UserService
   */
  getPermissions(user: Object) {
    return user && user.permissions ? user.permissions : {}
  }

  /**
   * Check if a user has authority to view a top level product page
   *
   * @param {Object} products - Object with products available to user. Key is product name, value is product object
   * @param {any} key - Product name
   * @returns {boolean} True or false
   *
   * @memberof UserService
   */
  isProductAuthorized(products: Object, key: string): boolean {
    return products && products[key] ? products[key].enabled : false
  }

  /**
   * Check if a user has a specific permission
   * Portal Admins have all permissions
   * @param {Object} user - User Object
   * @param {string} key - Permission key
   * @returns {boolean} True or false
   */
  hasPermission(user, key) {
    if (!user) return false
    if (this.isPortalAdmin(user)) return true
    return (user?.permissions && user?.permissions[key]) ?? false
  }
}

export default new UserService()
