// @flow
import cuid from 'cuid'

export const APP_MOUNTED = 'APP_MOUNTED'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGOUT = 'LOGOUT'
export const SESSION_UPDATE_SUCCESS = 'SESSION_UPDATE_SUCCESS'
export const SESSION_UPDATE_ERROR = 'SESSION_UPDATE_ERROR'
export const ALERT_CREATE = 'ALERT_CREATE'
export const ALERT_DISMISS = 'ALERT_DISMISS'
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS'
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED'
export const FETCH_GROUP_PRODUCTS = 'FETCH_GROUP_PRODUCTS'
export const FETCH_GROUP_PRODUCTS_SUCCESS = 'FETCH_GROUP_PRODUCTS_SUCCESS'
export const FETCH_GROUP_PRODUCTS_FAILED = 'FETCH_GROUP_PRODUCTS_FAILED'
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS'
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS'
export const FETCH_PERMISSIONS_FAILED = 'FETCH_PERMISSIONS_FAILED'

export function updateSession(payload: Object): Object {
  return {
    type: SESSION_UPDATE_SUCCESS,
    payload
  }
}

export function appMounted(payload: Object): Object {
  return {
    type: APP_MOUNTED,
    payload
  }
}

export function login(payload: Object): Object {
  return {
    type: LOGIN_REQUEST,
    payload
  }
}

export function logout(): Object {
  return {
    type: LOGOUT
  }
}

export function requestError(): Object {
  return {
    type: SESSION_UPDATE_ERROR
  }
}

export function onDismissAlert(alert: Object): Object {
  return {
    type: ALERT_DISMISS,
    id: alert.id
  }
}

export function fetchProducts(): Object {
  return {
    type: FETCH_PRODUCTS
  }
}

export function fetchPermissions(offset: String, count: Number, search: String): Object {
  return {
    type: FETCH_PERMISSIONS,
    offset,
    count,
    search
  }
}

export function fetchGroupProducts(id): Object {
  return {
    type: FETCH_GROUP_PRODUCTS,
    id
  }
}

export const createAlert = (type: String, message: String, headline: String) => ({
  type: ALERT_CREATE,
  alerts: [
    {
      id: cuid(),
      type,
      message,
      headline
    }
  ]
})
