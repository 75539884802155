export const HOVER_BEACON = 'HOVER_BEACON'
export const BEACON_VIEWED = 'BEACON_VIEWED'
export const TOGGLE_BEACONS = 'TOGGLE_BEACONS'
export const REGISTER_BEACON = 'REGISTER_BEACON'
export const ACTIVATE_WALKTHROUGH = 'ACTIVATE_WALKTHROUGH'
export const SET_PRODUCT_ONBOARDED = 'SET_PRODUCT_ONBOARDED'
export const UPDATE_BEACON_LOCATIONS = 'UPDATE_BEACON_LOCATIONS'
export const SET_PRODUCT_ONBOARDED_SUCCESS = 'SET_PRODUCT_ONBOARDED_SUCCESS'
export const SET_PRODUCT_ONBOARDED_FAILED = 'SET_PRODUCT_ONBOARDED_FAILED'

export const toggleBeacons = beaconGroup => ({
  type: TOGGLE_BEACONS,
  beaconGroup
})

export const hoverBeacon = activeBeacon => ({
  type: HOVER_BEACON,
  activeBeacon
})

export const beaconViewed = (beaconGroup, beaconId) => ({
  type: BEACON_VIEWED,
  beaconGroup,
  beaconId
})

export const registerBeacon = (beaconId, beaconRef) => ({
  type: REGISTER_BEACON,
  beaconId,
  beaconRef
})

export const updateBeaconLocations = () => ({
  type: UPDATE_BEACON_LOCATIONS
})

export const activateWalkthrough = products => ({
  type: ACTIVATE_WALKTHROUGH,
  products
})

export const setProductOnboarded = () => ({
  type: SET_PRODUCT_ONBOARDED
})

export const setProductOnboardedSuccess = () => ({
  type: SET_PRODUCT_ONBOARDED_SUCCESS
})

export const setProductOnboardedFailed = error => ({
  type: SET_PRODUCT_ONBOARDED_FAILED,
  error
})
