import React from 'react'
import PropTypes from 'prop-types'

import { Label } from 'react-bootstrap'
import GroupLogo from '../../GroupLogo'

const GroupInfo = ({ basicInfo }) => {
  const { isActive, userCount = 0, seatCount = 0, name = 'New Group' } = basicInfo

  return (
    <div className="group-edit-toolbar-left">
      <GroupLogo useIntelligenceLogo width="50px" height="50px" />

      <div className="group-edit-info">
        <Label id={isActive ? '' : 'inactive'} bsStyle={isActive ? 'default' : 'info'}>
          {isActive ? 'ACTIVE' : 'INACTIVE'}
        </Label>

        <span id="group-edit-name">{name}</span>

        {(userCount !== 0 || seatCount !== 0) && <span>{`${userCount}/${seatCount} users`}</span>}
      </div>
    </div>
  )
}

GroupInfo.propTypes = {
  basicInfo: PropTypes.object
}

export default GroupInfo
