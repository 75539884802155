// @flow
import { FETCH_ENTRIES, FETCH_ENTRIES_DONE, RESOLVE_LOCATION, RESOLVE_LOCATION_DONE } from '../actions/authlog-actions'
import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialState = {
  data: [],
  loading: false,
  count: 0,
  pageNumber: 1,
  sizePerPage: 20,
  resolvingLocations: false,
  ipLocationMap: {}
}

const authlogsReducer = (state: Object = initialState, action: Object): Object => {
  switch (action.type) {
    case FETCH_ENTRIES:
      return {
        ...state,
        loading: true
      }
    case FETCH_ENTRIES_DONE:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: action.error,
        pageNumber: action.pageNumber,
        sizePerPage: action.sizePerPage
      }
    case RESOLVE_LOCATION:
      return {
        ...state,
        resolvingLocations: true
      }
    case RESOLVE_LOCATION_DONE:
      return {
        ...state,
        resolvingLocations: false,
        ipLocationMap: {
          ...state.ipLocationMap,
          ...action.data
        },
        error: action.error
      }
    case LOGOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default authlogsReducer
