import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import config from '../../config'
import { FOOTER_HEIGHT } from '../../constants/constants'
import './index.scss'

const Footer = ({ appVersion, isAuthValid }) => (
  <footer className={cx('copyright', { 'copyright-post-login': isAuthValid })} style={{ height: `${FOOTER_HEIGHT}px` }}>
    Copyright
    <span role="img" aria-label="copyright">
      {' © '}
    </span>
    {`${config.copyright} ${new Date().getFullYear()}`}
    {`${appVersion ? `. v${appVersion}` : ''}`}
  </footer>
)

Footer.propTypes = {
  appVersion: PropTypes.string,
  isAuthValid: PropTypes.bool
}

export default Footer
