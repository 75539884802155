import {
  FETCH_MAILBOX_PROVIDERS,
  FETCH_MAILBOX_PROVIDERS_SUCCESS,
  FETCH_MAILBOX_PROVIDERS_FAILURE
} from '../actions/mailbox-provider-actions'

import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialState = {
  loading: false,
  error: null,
  data: []
}

const mailboxProviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MAILBOX_PROVIDERS:
      return {
        ...state,
        loading: true,
        error: null,
        data: []
      }
    case FETCH_MAILBOX_PROVIDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.data
      }
    case FETCH_MAILBOX_PROVIDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error && action.error.message,
        data: []
      }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default mailboxProviderReducer
