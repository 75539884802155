// @flow
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import style from './index.scss'

class Box extends Component {
  static propTypes = Object.keys(style).reduce((props, name) => {
    props[name] = PropTypes.bool
    return props
  }, {})

  render() {
    const classes = Object.keys(style)
      .reduce(
        (classes, className) => {
          return this.props[className] ? classes.concat(style[className]) : classes
        },
        ['flex', this.props.className || '']
      )
      .join(' ')

    const { children, ...restProps } = this.props

    return (
      <div {...restProps} className={classes}>
        {children}
      </div>
    )
  }
}

Box.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element
}

export default Box
