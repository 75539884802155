import React, { createContext, useState, useContext } from 'react'
import PropTypes from 'prop-types'

const LeftNavContext = createContext({})

export const LeftNavProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [subMenuState, setSubMenuState] = useState({})

  const openMenu = () => {
    setIsOpen(true)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  // opens/closes submenus
  const toggleSubMenu = menuKey => {
    if (isOpen) {
      setSubMenuState(state => ({ ...state, [menuKey]: !state[menuKey] }))
    } else {
      openMenu()
      setSubMenuState({ [menuKey]: true }) // Open only selected subMenu
    }
  }

  const value = {
    isOpen,
    subMenuState,
    openMenu,
    closeMenu,
    toggleSubMenu
  }
  return <LeftNavContext.Provider value={value}>{children}</LeftNavContext.Provider>
}

LeftNavProvider.propTypes = {
  children: PropTypes.node.isRequired
}

const useLeftNav = () => {
  const context = useContext(LeftNavContext)

  if (context === undefined) {
    throw new Error('useLeftNav must be used within LeftNavContext')
  }

  return context
}

export default useLeftNav
