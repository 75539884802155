import React from 'react'
import PropTypes from 'prop-types'
// load tenant specific file
// eslint-disable-next-line
import welcomeImage from './welcome-modal-APP_TENANT.png'
import './index.scss'

const WelcomeModal = props => {
  const { modalOpen, setOnboarded } = props

  if (!modalOpen) return null

  return (
    <div className="welcomeModal">
      <div className="welcomeLayout">
        <div>
          <img alt="" src={welcomeImage} className="welcome-modal-img" />
        </div>

        <div className="takeOurTourBoxed">
          <div className="takeOurTour">
            {`Our e-commerce analytics tools are designed to give you unprecedented
              visibility to the online shopper. This is the pulse of digital commerce
              with data made simple.`}
          </div>
        </div>

        <div className="btnLayout">
          <button onClick={setOnboarded} className="btnTakeTour">
            Get Started
          </button>
        </div>
      </div>
    </div>
  )
}

WelcomeModal.propTypes = {
  modalOpen: PropTypes.bool,
  setOnboarded: PropTypes.func
}

export default WelcomeModal
