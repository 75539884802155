import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Button, ControlLabel } from 'react-bootstrap'
import TabFilterList from './ListContainer'
import { keys } from 'lodash'
import { arrayMove } from 'react-sortable-hoc'

export default class TabFiltersPanel extends Component {
  static propTypes = {
    filters: PropTypes.object,
    filterIdList: PropTypes.array,
    defaultList: PropTypes.array,
    onUpdateFilterIdList: PropTypes.func
  }

  onSelect = id => {
    const { filterIdList, onUpdateFilterIdList } = this.props
    const newFilters = [...filterIdList, id]
    onUpdateFilterIdList(newFilters)
  }

  onUnselect = (id, index) => {
    const { filterIdList, onUpdateFilterIdList } = this.props
    filterIdList.splice(index, 1)
    onUpdateFilterIdList([...filterIdList])
  }

  resetFilters = () => {
    const { onUpdateFilterIdList, defaultList } = this.props
    onUpdateFilterIdList([...defaultList])
  }

  clearFilters = () => {
    const { onUpdateFilterIdList } = this.props
    onUpdateFilterIdList([])
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onUpdateFilterIdList } = this.props
    const { selected } = this.getValues()
    const newFilters = arrayMove(selected, oldIndex, newIndex)
    onUpdateFilterIdList(newFilters.map(item => item.id))
  }

  getValues() {
    const { filters: filterMap, filterIdList = [] } = this.props
    const selected = filterIdList.map(id => filterMap[id]).filter(item => item && item.action !== 'delete')
    const unselected = keys(filterMap)
      .filter(id => {
        return filterMap[id].action !== 'delete' && filterIdList.indexOf(id) === -1
      })
      .map(id => filterMap[id])
    return {
      selected,
      unselected
    }
  }

  render() {
    const { selected, unselected } = this.getValues()
    return (
      <div className="cre-tab-filters">
        <Row>
          <Col sm={12}>
            <ControlLabel>Tab Filters</ControlLabel>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TabFilterList
              className="selected"
              items={selected}
              onItemClick={this.onUnselect}
              emptyMessage="No Filters added to the Tab"
              tooltipText="Click to Remove"
              sorting
              useDragHandle
              axis="xy"
              helperClass="cre-tab-filter-sort-edit"
              onSortEnd={this.onSortEnd}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={10}>
            <div className="pull-right">
              <Button className="resetButton" bsStyle="link" onClick={this.resetFilters}>
                Reset
              </Button>
              <Button className="resetButton" bsStyle="link" onClick={this.clearFilters}>
                Clear
              </Button>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={10}>
            <TabFilterList
              className="unselected"
              items={unselected}
              onItemClick={this.onSelect}
              emptyMessage="No Filters available to add to the Tab"
              tooltipText="Click to Add"
            />
          </Col>
        </Row>
      </div>
    )
  }
}
