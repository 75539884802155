// @flow
// eslint-disable-next-line no-redeclare
/* global TENANT */
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { loadAsyncComponents } from 'utils/route-util'

const asyncPages = {}

const isProduction = process.env.REACT_APP_DEPLOY_ENV.toLowerCase() === 'production'

if (TENANT === 'ri' && !isProduction) {
  loadAsyncComponents(asyncPages, {
    NIQSearchPage: import('../NIQSearchPage' /* webpackMode: "eager" */)
  })
}

/**
 * Container for the NIQ page root (/niq). Required by the router.
 */
const NIQPage = () => {
  const { user } = useSelector(state => state.session)
  if (!user) return null

  return (
    <div className="admin-container">
      <Switch>
        <Route path="/niq/search" component={asyncPages.NIQSearchPage} />
      </Switch>
    </div>
  )
}

export default NIQPage
