import React from 'react'
import PropTypes from 'prop-types'
import FolderItem from './FolderItem'
import cx from 'classnames'

export default function File(props) {
  const { className, id } = props
  return <FolderItem dragKey={`file-${id}`} {...props} className={cx('file', className)} />
}

File.defaultProps = {
  ...FolderItem.defaultProps,
  className: 'file',
  glyph: 'list-alt',
  droppable: false,
  draggable: true,
  tabIndex: 0
}

File.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string
}
