import React from 'react'

import { products, permissions } from 'constants/constants'
import usePermissions from './../usePermissions'
import SubMenuItem from './../SubMenuItem'

const CMESubMenu = () => {
  const { isPortalAdmin, hasAdminToolsAccess, isProductAuthorized, hasPermission } = usePermissions()

  const menuItems = [
    {
      label: 'Search / QC Tool',
      route: '/cme/search',
      isAuthorized: (isPortalAdmin || hasAdminToolsAccess) && isProductAuthorized(products.adminToolsQCTool)
    },
    {
      label: 'Taxonomy',
      route: '/cme/taxonomy',
      isAuthorized: hasAdminToolsAccess && hasPermission(permissions.ropsTaxonomyView)
    },
    {
      label: 'Rules',
      route: '/cme/rules',
      isAuthorized: hasAdminToolsAccess && hasPermission(permissions.ropsTaxonomyView)
    },
    {
      label: 'Dictionary',
      route: '/cme/dictionary',
      isAuthorized: hasAdminToolsAccess && hasPermission(permissions.ropsTaxonomyView)
    },
    {
      label: 'Retrainer',
      route: '/cme/retrainer',
      isAuthorized: hasAdminToolsAccess && hasPermission(permissions.ropsTaxonomyView)
    },
    {
      label: 'Inspector',
      route: '/cme/cme-inspector',
      isAuthorized: hasAdminToolsAccess && hasPermission(permissions.ropsTaxonomyView)
    },
    {
      label: 'Delivery Jobs',
      route: '/cme/delivery-jobs',
      isAuthorized: hasAdminToolsAccess && hasPermission(permissions.ropsTaxonomyView)
    },
    {
      label: 'QC Support',
      route: '/cme/qc-support',
      isAuthorized: hasAdminToolsAccess && hasPermission(permissions.ropsTaxonomyView)
    }
  ]

  return (
    <>
      {menuItems.map(
        (item, index) =>
          item.isAuthorized && <SubMenuItem key={index} label={item.label} route={item.route} isBeta={item.isBeta} />
      )}
    </>
  )
}

export default CMESubMenu
