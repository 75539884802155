const createDownloadLink = (csvData, csvName) => {
  const url = window.URL.createObjectURL(csvData)
  const downloadLink = document.createElement('a')
  downloadLink.href = url

  downloadLink.setAttribute('download', csvName)

  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export default createDownloadLink
