// @flow
import * as actions from '../actions/delivery-config-actions'
import * as dmActions from '../actions/dm-actions'
import * as deliveryActions from '../actions/delivery-actions'
import { deliverableConfigSteps as steps } from '../utils/delivery-center'
import { findRule, findParent } from '../utils/portal-query-builder'
import { pick, orderBy, cloneDeep, findIndex } from 'lodash'
import moment from 'moment'

const createMultipleRules = (action, queryTree) => {
  const rule = findRule(action.ruleId, queryTree)
  const newRules = action.values.map((value, index) => ({
    ...rule,
    id: `${rule.id}-${index}`,
    value
  }))
  const parent = findParent(rule.id, queryTree)
  if (action.event === 'addInCurrentGroup') {
    const index = findIndex(parent.rules, { id: rule.id })
    // replace the current rule with new rules
    parent.rules.splice(index, 1, ...newRules)
  } else if (action.event === 'addNewRuleGroup') {
    // convert current rule to a ruleGroup
    // with newRules as its childRules
    rule.id = `g-${rule.id}`
    // TODO: dynamically make combinator 'or' or 'and'
    rule.value = null
    rule.combinator = 'or'
    rule.ruleGroupLabel = action.ruleGroupLabel
    rule.rules = newRules
    rule.isMinimized = true
  }
  return queryTree
}

const createChildrenRules = (action, queryTree) => {
  // find rule object from the tree
  const rule = findRule(action.ruleId, queryTree)
  const childRules = []
  // create child rule for each value
  action.data.forEach((element, index) => {
    childRules.push({
      ...rule,
      id: `${rule.id}-${index}`,
      value: element.value
    })
  })
  /**
   * convert current rule to a rule group.
   * Also, since we haven't modified the original rule object, no need to reassign it to queryTree
   */
  rule.id = `g-${rule.id}`
  rule.combinator = rule.operator === 'matches' ? 'or' : 'and'
  rule.ruleGroupLabel = action.ruleGroupLabel
  rule.rules = childRules
  rule.isMinimized = true
  return queryTree
}

const defaultIncExcQueryTree = {
  combinator: 'and',
  id: 'g-default',
  rules: [
    {
      field: 'description',
      id: 'r-default',
      operator: 'contains',
      value: ''
    }
  ]
}

const insertEmptyBTQuery = (array, btIndex, btqIndex) => {
  return [
    ...array.slice(0, btqIndex),
    {
      id: `btq-new-${btIndex}-${btqIndex}`,
      action: 'create',
      brandId: null,
      brandQuery: cloneDeep(defaultIncExcQueryTree),
      query: cloneDeep(defaultIncExcQueryTree)
    },
    ...array.slice(btqIndex)
  ]
}

const insertEmptyBT = (array, index) => {
  return [
    ...array.slice(0, index),
    {
      id: `bt-new-${index}`,
      action: 'create',
      label: null,
      query: cloneDeep(defaultIncExcQueryTree),
      queries: insertEmptyBTQuery([], index, 0)
    },
    ...array.slice(index)
  ]
}

const initialState = {
  deliverableData: {},
  stepsCompleted: {
    [steps.BASIC_INFO]: null,
    [steps.DATA_SELECTION]: null,
    [steps.REPORTS]: null,
    [steps.SCRIPTS]: null
  },
  editable: true,
  activeStep: null,
  basicForm: {
    loading: false
  },
  clientSuggestions: [],
  contractSuggestions: [],
  associatedCategories: [],
  categoryForm: {
    dirty: false,
    loading: false,
    offset: 0,
    limit: 0,
    sortBy: 'full_path',
    sortOrder: 'asc',
    data: [],
    searchTerm: '',
    totalCount: 0,
    filteredCount: 0,
    selectedCategoryIds: [],
    categoryLabel: '',
    updatedCategoryLabel: '',
    isLabelValid: true
  },
  incExcForm: {
    dirty: false,
    defaultIncExcQueryTree: null,
    queryTree: null,
    action: '',
    loading: false
  },
  brandTrackerForm: {
    dirty: false,
    error: {
      invalidBTIds: [],
      invalidBTQIds: []
    },
    defaultBrandTrackers: [],
    brandTrackers: [],
    brandSuggestions: [],
    associatedBrandMap: {},
    loading: false
  },
  reportsForm: {
    dirty: false,
    reportSuggestions: [],
    prodReport: null,
    qcReport: null,
    qcTabId: null,
    qcReportTabs: []
  },
  scriptsData: undefined,
  scriptsForm: {
    dirty: false,
    loading: false,
    updateScript: null,
    scriptTemplate: ''
  },
  modalSQL: '',
  modalSQLLoading: false
}
const defaultStartDate = new Date()
defaultStartDate.setMonth(defaultStartDate.getMonth() + 1) // By default start date is next month
const basicFormDefaults = {
  startYear: defaultStartDate.getFullYear(),
  startMonth: defaultStartDate.getMonth() + 1, // Converting 0-11 to 1-12
  frequency: 1,
  deliveryDay: 10,
  deliveryDayType: 'MONTH_DAY',
  totalDeliveries: 1
}

const initializeBasicFormData = deliverableData => {
  if (!deliverableData) {
    return { ...basicFormDefaults }
  }
  const { Contract: { Client = {} } = {}, firstDelivery: firstDeliveryUTC } = deliverableData
  let firstDelivery
  if (firstDeliveryUTC) {
    firstDelivery = moment.utc(firstDeliveryUTC).format('MM/DD/YYYY')
  }
  return {
    Client,
    ...pick(deliverableData, [
      'id',
      'name',
      'Client',
      'Contract',
      'isActive',
      'totalDeliveries',
      'frequency',
      'startMonth',
      'startYear',
      'deliveryDay',
      'deliveryDayType'
    ]),
    firstDelivery
  }
}

const initCategoryFormData = (associatedCategories = []) => {
  const selectedCategoryIds = associatedCategories.map(({ category_id }) => category_id)
  return {
    selectedCategoryIds,
    dirty: false,
    loading: false,
    isLabelValid: true
  }
}

const initIncExcFormData = (incExcQuery = null) => {
  return {
    dirty: false,
    defaultIncExcQueryTree: cloneDeep(incExcQuery),
    queryTree: cloneDeep(incExcQuery),
    action: '',
    loading: false
  }
}

const initBTFormData = () => {
  return {
    dirty: false,
    error: {
      invalidBTIds: [],
      invalidBTQIds: []
    },
    defaultBrandTrackers: [],
    brandTrackers: [],
    brandSuggestions: [],
    associatedBrandMap: {},
    loading: false
  }
}

const initReportsForm = (deliverableData = {}) => {
  return {
    prodReport: deliverableData.prodReport,
    qcReport: deliverableData.qcReport,
    dirty: false,
    reportSuggestions: [],
    qcTabId: deliverableData.qcReportTabId,
    qcReportTabs: []
  }
}

const initScriptsForm = (scriptsData = {}, state = { scriptsForm: {} }) => {
  return {
    dirty: false,
    updateScript: scriptsData.updateScript,
    scriptTemplate: state.scriptsForm.scriptTemplate
  }
}

const areBasicDetailsConfigured = deliverableData => {
  return !!(deliverableData && deliverableData.id)
}
const areDataOptionsConfigured = (deliverableData = {}) => {
  const { categories: categoryIds = [], brandTrackers = [] } = deliverableData
  return categoryIds.length > 0 || brandTrackers.length > 0
}
const areReportsConfigured = ({ prodReport } = {}) => {
  return !!(prodReport && prodReport.id)
}

const areScriptsConfigured = ({ updateScriptPath } = {}) => {
  return !!updateScriptPath
}

const deliveryConfigReducer = (state: Array<Object> = initialState, action: Object): Array<Object> => {
  switch (action.type) {
    case actions.INITIALIZE_NEW: {
      const { preSelectedClient: Client, preSelectedContract: Contract } = action.payload
      return {
        ...state,
        editable: true,
        stepsCompleted: {
          [steps.BASIC_INFO]: false,
          [steps.DATA_SELECTION]: false,
          [steps.REPORTS]: false,
          [steps.SCRIPTS]: false
        },
        activeStep: steps.BASIC_INFO,
        deliverableData: {},
        basicForm: { ...basicFormDefaults, Client, Contract },
        associatedCategories: [],
        categoryForm: { ...initCategoryFormData() },
        incExcForm: { ...initIncExcFormData() },
        brandTrackerForm: { ...initBTFormData() },
        reportsForm: { ...initReportsForm() },
        scriptsData: {},
        scriptsForm: { ...initScriptsForm({}, state) }
      }
    }
    case actions.INITIALIZE_EXISTING: {
      return {
        ...state,
        editable: false,
        stepsCompleted: {
          [steps.BASIC_INFO]: null,
          [steps.DATA_SELECTION]: null,
          [steps.REPORTS]: null,
          [steps.SCRIPTS]: null
        },
        activeStep: null,
        deliverableData: {},
        basicForm: {},
        associatedCategories: [],
        reportsForm: { ...initReportsForm() },
        scriptsData: {},
        scriptsForm: { ...initScriptsForm({}, state) }
      }
    }
    case actions.UPDATE_CONFIG_FORM_DATA: {
      const { deliverableData = {} } = action
      return {
        ...state,
        deliverableData,
        basicForm: { ...initializeBasicFormData(deliverableData) },
        reportsForm: { ...initReportsForm(deliverableData) },
        scriptsForm: { ...initScriptsForm(deliverableData, state) },
        lastUpdated: deliverableData.updatedAt,
        stepsCompleted: {
          ...state.stepsCompleted,
          [steps.BASIC_INFO]: areBasicDetailsConfigured(deliverableData),
          [steps.DATA_SELECTION]: areDataOptionsConfigured(deliverableData),
          [steps.REPORTS]: areReportsConfigured(deliverableData),
          [steps.SCRIPTS]: areScriptsConfigured(deliverableData)
        }
      }
    }

    case actions.CANCEL_EDITING: {
      const { deliverableData, associatedCategories, categoryForm } = state
      return {
        ...state,
        basicForm: {
          ...initializeBasicFormData(deliverableData)
        },
        categoryForm: {
          ...categoryForm,
          ...initCategoryFormData(associatedCategories),
          updatedCategoryLabel: categoryForm.categoryLabel
        },
        reportsForm: {
          ...initReportsForm(deliverableData)
        },
        scriptsForm: {
          ...initScriptsForm(state.scriptsData, state),
          loading: false
        },
        editable: false
      }
    }

    case actions.UPDATE_FORM_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }

    case actions.UPDATE_BASIC_DETAILS_FORM: {
      return {
        ...state,
        clientSuggestions: [],
        contractSuggestions: [],
        basicForm: {
          ...state.basicForm,
          ...action.payload
        }
      }
    }

    case actions.RESET_BASIC_DETAILS_FORM: {
      return {
        ...state,
        basicForm: {
          ...initializeBasicFormData(state.deliverableData)
        }
      }
    }

    case actions.SAVE_BASIC_DETAILS_FORM:
      return {
        ...state,
        basicForm: {
          ...state.basicForm,
          loading: true
        }
      }

    case actions.SAVE_BASIC_DETAILS_FORM_SUCCESS:
      return {
        ...state,
        basicForm: {
          ...state.basicForm,
          loading: false
        }
      }

    case dmActions.SEARCH_CLIENTS_SUCCESS: {
      return {
        ...state,
        clientSuggestions: action.payload
      }
    }

    case dmActions.SEARCH_CONTRACTS_SUCCESS: {
      return {
        ...state,
        contractSuggestions: action.payload
      }
    }

    case dmActions.UPDATE_DELIVERABLE_NAME_AVAILABLE:
      const { name, isNameAvailable } = action.payload
      if (state.basicForm.name === name) {
        return {
          ...state,
          basicForm: {
            ...state.basicForm,
            name,
            isNameAvailable
          }
        }
      }
      break
    case actions.SORT_ASSOCIATED_CATEGORIES: {
      const { sortBy, sortOrder } = action.payload
      return {
        ...state,
        associatedCategories: orderBy(state.associatedCategories, sortBy, sortOrder),
        categoryForm: {
          ...state.categoryForm,
          sortBy,
          sortOrder,
          loading: false
        }
      }
    }
    case actions.FETCH_DELIVERABLE_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categoryForm: {
          ...state.categoryForm,
          data: action.offset ? [...state.categoryForm.data, ...action.payload] : action.payload,
          totalCount: action.totalCount,
          filteredCount: action.filteredCount,
          offset: action.offset,
          limit: action.limit,
          sortBy: action.sortBy,
          sortOrder: action.sortOrder,
          loading: false
        }
      }
    }
    case actions.UPDATE_DELIVERABLE_CATEGORIES_SEARCH_FILTER: {
      return {
        ...state,
        categoryForm: {
          ...state.categoryForm,
          searchTerm: action.searchTerm
        }
      }
    }
    case actions.FETCH_DELIVERABLE_CATEGORIES: {
      return {
        ...state,
        categoryForm: {
          ...state.categoryForm,
          loading: true
        }
      }
    }
    case actions.FETCH_DELIVERABLE_CATEGORY_CONFIG:
    case actions.SAVE_DELIVERABLE_CATEGORY_CONFIG: {
      return {
        ...state,
        categoryForm: {
          ...state.categoryForm,
          loading: true
        },
        incExcForm: {
          ...state.incExcForm,
          loading: true
        },
        brandTrackerForm: {
          ...state.brandTrackerForm,
          loading: true
        }
      }
    }

    case actions.FETCH_DELIVERABLE_CATEGORIES_ERROR: {
      return {
        ...state,
        categoryForm: {
          ...state.categoryForm,
          loading: false
        }
      }
    }

    case actions.SAVE_DELIVERABLE_CATEGORY_CONFIG_ERROR:
    case actions.FETCH_DELIVERABLE_CATEGORY_CONFIG_ERROR: {
      return {
        ...state,
        categoryForm: {
          ...state.categoryForm,
          loading: false
        },
        incExcForm: {
          ...state.incExcForm,
          loading: false
        },
        brandTrackerForm: {
          ...state.brandTrackerForm,
          loading: false
        }
      }
    }

    case actions.FETCH_DELIVERABLE_CATEGORY_CONFIG_SUCCESS: {
      const {
        categoryForm: { sortBy, sortOrder }
      } = state
      const associatedCategories = orderBy(action.categories, sortBy, sortOrder)
      return {
        ...state,
        associatedCategories,
        categoryForm: {
          ...state.categoryForm,
          ...initCategoryFormData(associatedCategories),
          categoryLabel: action.categoryLabel,
          updatedCategoryLabel: action.categoryLabel
        },
        incExcForm: {
          ...state.incExcForm,
          ...initIncExcFormData(action.inclExclQuery)
        },
        brandTrackerForm: {
          ...state.brandTrackerForm,
          defaultBrandTrackers: cloneDeep(action.brandTrackers),
          brandTrackers: cloneDeep(action.brandTrackers),
          dirty: false,
          associatedBrandMap: action.associatedBrandMap,
          loading: false
        }
      }
    }
    case actions.RESET_CATEGORY_FORM: {
      return {
        ...state,
        categoryForm: {
          ...state.categoryForm,
          ...initCategoryFormData(state.associatedCategories),
          updatedCategoryLabel: state.categoryForm.categoryLabel
        },
        incExcForm: {
          ...state.incExcForm,
          ...initIncExcFormData(state.incExcForm.defaultIncExcQueryTree)
        },
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers: cloneDeep(state.brandTrackerForm.defaultBrandTrackers),
          dirty: false,
          loading: false,
          error: {
            ...state.brandTrackerForm.error,
            invalidBTIds: [],
            invalidBTQIds: []
          }
        }
      }
    }
    case actions.UPDATE_SELECTED_DELIVERABLE_CATEGORY_SELECTION: {
      const selectedCategoryIds = [...state.categoryForm.selectedCategoryIds]
      const currentIdx = selectedCategoryIds.indexOf(action.categoryId)
      if (currentIdx > -1) {
        selectedCategoryIds.splice(currentIdx, 1)
      } else {
        selectedCategoryIds.push(action.categoryId)
      }
      return {
        ...state,
        categoryForm: {
          ...state.categoryForm,
          dirty: true,
          selectedCategoryIds
        }
      }
    }
    case actions.REPORT_SEARCH_SUCCESS: {
      return {
        ...state,
        reportsForm: {
          ...state.reportsForm,
          reportSuggestions: action.payload
        }
      }
    }
    case actions.REPORT_TABS_LOAD_SUCCESS: {
      return {
        ...state,
        reportsForm: {
          ...state.reportsForm,
          qcReportTabs: action.payload
        }
      }
    }
    case actions.UPDATE_REPORTS_FORM: {
      return {
        ...state,
        reportsForm: {
          ...state.reportsForm,
          ...action.payload
        }
      }
    }
    case actions.RESET_REPORTS_FROM: {
      return {
        ...state,
        reportsForm: { ...initReportsForm(state.deliverableData) }
      }
    }
    case actions.LOAD_SCRIPTS_DATA:
      return {
        ...state,
        scriptsData: undefined,
        scriptsForm: {
          ...initScriptsForm({}, state),
          loading: true
        }
      }
    case actions.LOAD_SCRIPTS_DATA_SUCCESS:
      return {
        ...state,
        lastUpdated: action.payload.updatedAt,
        scriptsData: action.payload,
        scriptsForm: {
          ...initScriptsForm(action.payload, state),
          loading: false
        },
        stepsCompleted: {
          ...state.stepsCompleted,
          [steps.SCRIPTS]: areScriptsConfigured(action.payload)
        }
      }
    case actions.UPDATE_SCRIPTS_FORM:
      return {
        ...state,
        scriptsForm: {
          ...state.scriptsForm,
          ...action.payload
        }
      }

    case actions.RESET_SRIPTS_FORM:
      return {
        ...state,
        scriptsForm: {
          ...initScriptsForm(state.scriptsData, state),
          loading: false
        }
      }
    case actions.SAVE_SCRIPTS_FORM:
      return {
        ...state,
        scriptsForm: {
          ...state.scriptsForm,
          loading: true
        }
      }

    case actions.SAVE_SCRIPTS_FORM_ERROR:
      return {
        ...state,
        scriptsForm: {
          ...state.scriptsForm,
          loading: false
        }
      }
    case actions.LOAD_SCRIPTS_DATA_ERROR:
      return {
        ...state,
        scriptsForm: {
          ...initScriptsForm({}, state),
          loading: false
        }
      }
    case deliveryActions.FETCH_CURRENT_DELIVERY_SUCCESS:
      const { payload = {} } = action
      return {
        ...state,
        deliverableData: {
          ...state.deliverableData,
          currentDeliveryId: payload ? payload.id : null
        }
      }
    case actions.UPDATE_CATEGORY_LABEL:
      return {
        ...state,
        categoryForm: {
          ...state.categoryForm,
          ...action.payload
        }
      }

    case actions.FETCH_DELIVERY_SCRIPT_TEMPLATE:
      return {
        ...state,
        scriptsForm: {
          ...state.scriptsForm,
          loading: true
        }
      }

    case actions.FETCH_DELIVERY_SCRIPT_TEMPLATE_SUCCESS:
      return {
        ...state,
        scriptsForm: {
          ...state.scriptsForm,
          loading: false,
          scriptTemplate: action.scriptTemplate
        }
      }

    case actions.FETCH_DELIVERY_SCRIPT_TEMPLATE_FAILED:
      return {
        ...state,
        scriptsForm: {
          ...state.scriptsForm,
          loading: false
        }
      }

    case actions.UPDATE_INC_EXC_QUERY_TREE:
      let { dirty, action: formAction } = state.incExcForm
      if (action.event !== 'onMinimizeToggle') {
        dirty = true
        formAction = state.incExcForm.defaultIncExcQueryTree ? 'update' : 'create'
      }
      return {
        ...state,
        incExcForm: {
          ...state.incExcForm,
          dirty,
          queryTree: action.queryTree,
          action: formAction
        }
      }

    case actions.EMPTY_INC_EXC_QUERY_TREE:
      return {
        ...state,
        incExcForm: {
          ...state.incExcForm,
          queryTree: cloneDeep(defaultIncExcQueryTree),
          action: state.incExcForm.defaultIncExcQueryTree ? 'update' : 'create',
          dirty: true
        }
      }

    case actions.CREATE_INC_EXC_QUERY_TREE:
      return {
        ...state,
        incExcForm: {
          ...state.incExcForm,
          queryTree: cloneDeep(defaultIncExcQueryTree),
          action: state.incExcForm.defaultIncExcQueryTree ? 'update' : 'create',
          dirty: true
        }
      }

    case actions.REMOVE_INC_EXC_QUERY_TREE:
      return {
        ...state,
        incExcForm: {
          ...state.incExcForm,
          queryTree: null,
          action: state.incExcForm.defaultIncExcQueryTree ? 'delete' : '',
          dirty: !!state.incExcForm.defaultIncExcQueryTree
        }
      }

    case actions.REVERT_INC_EXC_QUERY_TREE:
      return {
        ...state,
        incExcForm: {
          ...state.incExcForm,
          queryTree: cloneDeep(state.incExcForm.defaultIncExcQueryTree),
          action: '',
          dirty: false
        }
      }

    case actions.CREATE_BRAND_TRACKER: {
      const numberOfBTs = state.brandTrackerForm.brandTrackers.length
      const brandTrackers = insertEmptyBT(state.brandTrackerForm.brandTrackers, numberOfBTs)
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true,
          error: {
            ...state.brandTrackerForm.error,
            invalidBTIds: [...state.brandTrackerForm.error.invalidBTIds, `bt-new-${numberOfBTs}`],
            invalidBTQIds: [...state.brandTrackerForm.error.invalidBTQIds, `btq-new-${numberOfBTs}-0`]
          }
        }
      }
    }

    case actions.ADD_BRAND_TRACKER_QUERY_TREE: {
      let btq_idx
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.index) {
          return bt
        }
        const btQueries = bt.queries
        btq_idx = btQueries.length
        const updatedBTQueries = insertEmptyBTQuery(btQueries, action.index, btq_idx)
        return {
          ...bt,
          queries: updatedBTQueries
        }
      })
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true,
          error: {
            ...state.brandTrackerForm.error,
            invalidBTQIds: [...state.brandTrackerForm.error.invalidBTQIds, `btq-new-${action.index}-${btq_idx}`]
          }
        }
      }
    }

    case actions.HANDLE_BRAND_SEARCH_CHANGE:
    case action.FETCH_BT_CHILDREN:
    case action.FETCH_BTQ_CHILDREN:
    case action.FETCH_BTBQ_CHILDREN: {
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          loading: true
        }
      }
    }

    case actions.HANDLE_BRAND_SEARCH_CHANGE_FAILED:
    case action.FETCH_BT_CHILDREN_ERROR:
    case actions.FETCH_BTQ_CHILDREN_ERROR:
    case actions.FETCH_BTBQ_CHILDREN_ERROR: {
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          loading: false
        }
      }
    }

    case actions.HANDLE_BRAND_SEARCH_CHANGE_SUCCESS: {
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandSuggestions: action.data,
          loading: false
        }
      }
    }

    case actions.SELECT_BRAND: {
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const btQueries = bt.queries.map((queryObj, index) => {
          if (index !== action.btqIndex) {
            return queryObj
          }
          const updatedQueryObj = {
            isBrandInvalid: !action.brand,
            brandId: action.brand ? action.brand.id : null
          }
          if (!queryObj.action) {
            updatedQueryObj.action = 'update'
          }

          return {
            ...queryObj,
            ...updatedQueryObj
          }
        })
        return {
          ...bt,
          queries: btQueries
        }
      })
      const associatedBrandMap = { ...state.brandTrackerForm.associatedBrandMap }
      let invalidBTQIds = [...state.brandTrackerForm.error.invalidBTQIds]
      if (action.brand && action.brand.id) {
        associatedBrandMap[action.brand.id] = { brandId: action.brand.id, brandName: action.brand.value }
        invalidBTQIds.splice(invalidBTQIds.indexOf(action.btqId), 1)
      } else if (!invalidBTQIds.includes(action.btqId)) {
        invalidBTQIds = [...invalidBTQIds, action.btqId]
      }
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          associatedBrandMap,
          dirty: true,
          error: {
            ...state.brandTrackerForm.error,
            invalidBTQIds
          }
        }
      }
    }

    case actions.BT_LABEL_CHANGE: {
      const btLabels = state.brandTrackerForm.brandTrackers.map(bt => bt.label)
      const isLabelPresent = btLabels.includes(action.label)
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const updatedBTObj = {
          label: action.label,
          isLabelPresent
        }
        if (!bt.action) {
          updatedBTObj.action = 'update'
        }
        return {
          ...bt,
          ...updatedBTObj
        }
      })

      let invalidBTIds = [...state.brandTrackerForm.error.invalidBTIds]
      if (!invalidBTIds.includes(action.btId) && (!action.label.length || isLabelPresent)) {
        invalidBTIds = [...invalidBTIds, action.btId]
      } else {
        invalidBTIds.splice(invalidBTIds.indexOf(action.btId), 1)
      }
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true,
          error: {
            ...state.brandTrackerForm.error,
            invalidBTIds
          }
        }
      }
    }

    case actions.UPDATE_BT_QUERY_TREE: {
      let { dirty } = state.brandTrackerForm
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const updatedBT = {
          query: action.query
        }
        if (action.event !== 'onMinimizeToggle') {
          dirty = true
          if (!bt.action) {
            updatedBT.action = 'update'
          }
        }
        return {
          ...bt,
          ...updatedBT
        }
      })

      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty
        }
      }
    }

    case actions.UPDATE_BTQ_QUERY_TREE: {
      let { dirty } = state.brandTrackerForm
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const btQueries = bt.queries.map((queryObj, index) => {
          if (index !== action.btqIndex) {
            return queryObj
          }
          const updatedQueryObj = {
            query: action.query
          }
          if (action.event !== 'onMinimizeToggle') {
            dirty = true
            if (!queryObj.action) {
              updatedQueryObj.action = 'update'
            }
          }

          return {
            ...queryObj,
            ...updatedQueryObj
          }
        })
        return {
          ...bt,
          queries: btQueries
        }
      })

      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty
        }
      }
    }

    case actions.UPDATE_BTBQ_QUERY_TREE: {
      let { dirty } = state.brandTrackerForm
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const btQueries = bt.queries.map((queryObj, index) => {
          if (index !== action.btqIndex) {
            return queryObj
          }
          const updatedQueryObj = {
            brandQuery: action.brandQuery
          }
          if (action.event !== 'onMinimizeToggle') {
            dirty = true
            if (!queryObj.action) {
              updatedQueryObj.action = 'update'
            }
          }

          return {
            ...queryObj,
            ...updatedQueryObj
          }
        })
        return {
          ...bt,
          queries: btQueries
        }
      })

      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty
        }
      }
    }

    case actions.EMPTY_BT_QUERY_TREE: {
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const updatedBT = {
          query: cloneDeep(defaultIncExcQueryTree)
        }
        if (!bt.action) {
          updatedBT.action = 'update'
        }
        return {
          ...bt,
          ...updatedBT
        }
      })
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true
        }
      }
    }

    case actions.EMPTY_BTQ_QUERY_TREE: {
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const btQueries = bt.queries.map((queryObj, index) => {
          if (index !== action.btqIndex) {
            return queryObj
          }
          const updatedQueryObj = {
            query: cloneDeep(defaultIncExcQueryTree)
          }
          if (!queryObj.action) {
            updatedQueryObj.action = 'update'
          }

          return {
            ...queryObj,
            ...updatedQueryObj
          }
        })
        return {
          ...bt,
          queries: btQueries
        }
      })
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true
        }
      }
    }

    case actions.EMPTY_BTBQ_QUERY_TREE: {
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const btQueries = bt.queries.map((queryObj, index) => {
          if (index !== action.btqIndex) {
            return queryObj
          }
          const updatedQueryObj = {
            brandQuery: cloneDeep(defaultIncExcQueryTree)
          }
          if (!queryObj.action) {
            updatedQueryObj.action = 'update'
          }

          return {
            ...queryObj,
            ...updatedQueryObj
          }
        })
        return {
          ...bt,
          queries: btQueries
        }
      })
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true
        }
      }
    }

    case actions.DELETE_BT_QUERY: {
      const invalidBTQIds = [...state.brandTrackerForm.error.invalidBTQIds]
      invalidBTQIds.splice(invalidBTQIds.indexOf(action.btqId), 1)
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        let btQueries = []
        if (bt.queries[action.btqIndex].action === 'create') {
          btQueries = [...bt.queries.slice(0, action.btqIndex), ...bt.queries.slice(action.btqIndex + 1)]
        } else {
          btQueries = bt.queries.map((queryObj, index) => {
            if (index !== action.btqIndex) {
              return queryObj
            }
            return {
              ...queryObj,
              action: 'delete'
            }
          })
        }
        return {
          ...bt,
          queries: btQueries
        }
      })

      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true,
          error: {
            ...state.brandTrackerForm.error,
            invalidBTQIds
          }
        }
      }
    }

    case actions.DELETE_BT: {
      const brandTrackers = state.brandTrackerForm.brandTrackers
      let updatedBTs = []
      if (brandTrackers[action.btIndex].action === 'create') {
        updatedBTs = [...brandTrackers.slice(0, action.btIndex), ...brandTrackers.slice(action.btIndex + 1)]
      } else {
        updatedBTs = state.brandTrackerForm.brandTrackers.map((bt, index) => {
          if (index !== action.btIndex) {
            return bt
          }
          return {
            ...bt,
            action: 'delete'
          }
        })
      }
      const invalidBTIds = [...state.brandTrackerForm.error.invalidBTIds]
      invalidBTIds.splice(invalidBTIds.indexOf(action.btId), 1)

      const btQueryIds = brandTrackers[action.btIndex].queries.map(query => query.id)
      const invalidBTQIds = [...state.brandTrackerForm.error.invalidBTQIds]
      btQueryIds.forEach(id => invalidBTQIds.splice(invalidBTQIds.indexOf(id), 1))
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers: updatedBTs,
          dirty: true,
          error: {
            ...state.brandTrackerForm.error,
            invalidBTIds,
            invalidBTQIds
          }
        }
      }
    }

    case actions.REVERT_ALL_BT_CHANGES: {
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers: cloneDeep(state.brandTrackerForm.defaultBrandTrackers),
          dirty: false,
          error: {
            ...state.brandTrackerForm.error,
            invalidBTIds: [],
            invalidBTQIds: []
          }
        }
      }
    }

    case actions.CREATE_INC_EXC_MULTIPLE_RULES: {
      const queryTree = createMultipleRules(action, cloneDeep(state.incExcForm.queryTree))
      return {
        ...state,
        incExcForm: {
          ...state.incExcForm,
          queryTree,
          action: state.incExcForm.defaultIncExcQueryTree ? 'update' : 'create',
          dirty: true
        }
      }
    }

    case actions.FETCH_INC_EXC_CHILDREN: {
      return {
        ...state,
        incExcForm: {
          ...state.incExcForm,
          loading: true
        }
      }
    }

    case actions.FETCH_INC_EXC_CHILDREN_ERROR: {
      return {
        ...state,
        incExcForm: {
          ...state.incExcForm,
          loading: false
        }
      }
    }

    case actions.FETCH_INC_EXC_CHILDREN_SUCCESS: {
      const clonedQT = cloneDeep(state.incExcForm.queryTree)
      const queryTree = createChildrenRules(action, clonedQT)
      return {
        ...state,
        incExcForm: {
          ...state.incExcForm,
          queryTree,
          action: state.incExcForm.defaultIncExcQueryTree ? 'update' : 'create',
          dirty: true,
          loading: false
        }
      }
    }

    case actions.CREATE_BT_MULTIPLE_RULES: {
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const updatedBT = {}
        const queryTree = createMultipleRules(action, cloneDeep(bt.query))
        if (!bt.action) {
          updatedBT.action = 'update'
        }
        updatedBT.query = queryTree
        return {
          ...bt,
          ...updatedBT
        }
      })
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true
        }
      }
    }

    case actions.CREATE_BTQ_MULTIPLE_RULES: {
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const btQueries = bt.queries.map((queryObj, index) => {
          if (index !== action.btqIndex) {
            return queryObj
          }
          const updatedQueryObj = {}
          const clonedBTQuery = cloneDeep(queryObj.query)
          createMultipleRules(action, clonedBTQuery)
          if (!queryObj.action) {
            updatedQueryObj.action = 'update'
          }
          updatedQueryObj.query = clonedBTQuery
          return {
            ...queryObj,
            ...updatedQueryObj
          }
        })
        return {
          ...bt,
          queries: btQueries
        }
      })

      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true
        }
      }
    }

    case actions.CREATE_BTBQ_MULTIPLE_RULES: {
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const btQueries = bt.queries.map((queryObj, index) => {
          if (index !== action.btqIndex) {
            return queryObj
          }
          const updatedQueryObj = {}
          const clonedBTBQuery = cloneDeep(queryObj.brandQuery)
          createMultipleRules(action, clonedBTBQuery)
          if (!queryObj.action) {
            updatedQueryObj.action = 'update'
          }
          updatedQueryObj.brandQuery = clonedBTBQuery
          return {
            ...queryObj,
            ...updatedQueryObj
          }
        })
        return {
          ...bt,
          queries: btQueries
        }
      })

      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true
        }
      }
    }

    case actions.FETCH_BTBQ_CHILDREN_SUCCESS: {
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const btQueries = bt.queries.map((queryObj, index) => {
          if (index !== action.btqIndex) {
            return queryObj
          }
          const updatedQueryObj = {}
          const clonedBTBQuery = cloneDeep(queryObj.brandQuery)
          createChildrenRules(action, clonedBTBQuery)
          if (!queryObj.action) {
            updatedQueryObj.action = 'update'
          }
          updatedQueryObj.brandQuery = clonedBTBQuery
          return {
            ...queryObj,
            ...updatedQueryObj
          }
        })
        return {
          ...bt,
          queries: btQueries
        }
      })

      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true,
          loading: false
        }
      }
    }

    case actions.FETCH_BTQ_CHILDREN_SUCCESS: {
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const btQueries = bt.queries.map((queryObj, index) => {
          if (index !== action.btqIndex) {
            return queryObj
          }
          const updatedQueryObj = {}
          const clonedBTQuery = cloneDeep(queryObj.query)
          createChildrenRules(action, clonedBTQuery)
          if (!queryObj.action) {
            updatedQueryObj.action = 'update'
          }
          updatedQueryObj.query = clonedBTQuery
          return {
            ...queryObj,
            ...updatedQueryObj
          }
        })
        return {
          ...bt,
          queries: btQueries
        }
      })

      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true,
          loading: false
        }
      }
    }

    case actions.FETCH_BT_CHILDREN_SUCCESS: {
      const brandTrackers = state.brandTrackerForm.brandTrackers.map((bt, index) => {
        if (index !== action.btIndex) {
          return bt
        }
        const updatedBT = {}
        const queryTree = createChildrenRules(action, cloneDeep(bt.query))
        if (!bt.action) {
          updatedBT.action = 'update'
        }
        updatedBT.query = queryTree
        return {
          ...bt,
          ...updatedBT
        }
      })
      return {
        ...state,
        brandTrackerForm: {
          ...state.brandTrackerForm,
          brandTrackers,
          dirty: true,
          loading: false
        }
      }
    }

    case actions.GET_BT_INSERT_SQL:
    case actions.GET_BT_UPDATE_SQL: {
      return {
        ...state,
        modalSQLLoading: true
      }
    }

    case actions.GET_BT_INSERT_SQL_SUCCESS:
    case actions.GET_BT_UPDATE_SQL_SUCCESS: {
      return {
        ...state,
        modalSQLLoading: false,
        modalSQL: action.sql
      }
    }

    case actions.GET_BT_INSERT_SQL_ERROR:
    case actions.GET_BT_UPDATE_SQL_ERROR: {
      return {
        ...state,
        modalSQLLoading: false,
        modalSQL: ''
      }
    }

    default:
      return state
  }
}

export default deliveryConfigReducer
