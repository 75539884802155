// import { takeLatest, takeEvery, select, call, put, all } from 'redux-saga/effects'
import { takeLatest, select, call, put, all } from 'redux-saga/effects'
import _, { keys, keyBy, map, groupBy, mapValues, chunk, filter, isEqual, cloneDeep, omit, get } from 'lodash'
import axios from 'axios'
import cuid from 'cuid'
import { delay } from 'redux-saga'
import moment from 'moment'

import constants, { searchToolTabs, aggregationSettings } from 'constants/constants'
import config from 'config/index'
import { createAlert } from 'actions/app-actions'
import { LIMIT_EXTENDED_SEARCH_RESULTS } from '../constants/constants'

// import metricsActions from './../components/content/widgets/Metrics/metricsActions'
// import { METRICS_STORE_PATH } from './../components/content/widgets/Metrics/metricsReducer'
// import { getGraphOptions } from './../components/content/widgets/Metrics/constants'
// import { qcMonitoringResult } from './../components/content/widgets/Metrics/utils'

import {
  QUERY_RUN_STARTED,
  QUERY_RUN_SUCCEEDED,
  QUERY_RUN_FAILED,
  // QUERY_SET_QUALITY,
  AUTOSUGGEST_SUCCEEDED,
  AUTOSUGGEST_FAILED,
  SEARCH_TERM_SUGGEST,
  FETCH_TYPE_CHILDREN,
  FETCH_TYPE_CHILDREN_SUCCEEDED,
  FETCH_TYPE_CHILDREN_FAILED,
  TOGGLE_QC_MODE,
  GET_SEARCH_METADATA,
  GET_SEARCH_METADATA_SUCCEEDED,
  GET_SEARCH_METADATA_FAILED,
  CHANGE_CATEGORY_FILTER,
  APPLY_QUERY,
  FETCH_SAVED_GROUP,
  FETCH_SAVED_GROUP_SUCCEEDED,
  FETCH_SAVED_GROUP_FAILED,
  GROUP_CREATE,
  CREATE_GROUP_SUCCEEDED,
  CREATE_GROUP_FAILED,
  FETCH_QUERIES_BY_GROUP,
  FETCH_QUERIES_BY_GROUP_SUCCEEDED,
  FETCH_QUERIES_BY_GROUP_FAILED,
  QUERY_CREATE,
  CREATE_QUERY_SUCCEEDED,
  CREATE_QUERY_FAILED,
  DELETE_GROUP,
  DELETE_GROUP_SUCCEEDED,
  DELETE_GROUP_FAILED,
  DELETE_QUERY,
  DELETE_QUERY_SUCCEEDED,
  DELETE_QUERY_FAILED,
  SAVE_SEARCH_QUERY,
  SAVE_SEARCH_QUERY_SUCCEEDED,
  SAVE_SEARCH_QUERY_FAILED,
  QUERY_STATS_STARTED,
  QUERY_STATS_SUCCEEDED,
  QUERY_STATS_FAILED,
  RESET_QUERY_HEADER,
  SET_ACTIVE_SEARCH_TAB,
  GET_QUERY_SQL,
  GET_QUERY_SQL_STARTED,
  GET_QUERY_SQL_SUCCEEDED,
  GET_QUERY_SQL_FAILED
} from '../actions/niq-search-actions'

import {
  SEARCH_TOGGLE_LOCK_DATA,
  DESCRIPTION_SAVE_EDITS,
  DESCRIPTION_SAVE_EDITS_STARTED,
  DESCRIPTION_SAVE_EDITS_SUCCEEDED,
  DESCRIPTION_SAVE_EDITS_FAILED,
  DESCRIPTION_BRAND_SUGGEST,
  DESCRIPTION_BRAND_SUGGEST_SUCCEEDED,
  DESCRIPTION_CATEGORY_SUGGEST,
  DESCRIPTION_CATEGORY_SUGGEST_SUCCEEDED,
  DESCRIPTION_EDIT_STATUS_UPDATED,
  REFRESH_DESCRIPTION_EDIT_STATUS,
  DESCRIPTION_EDIT_STATUS_UPDATE_FAILED,
  TIME_DIMENSION_CHANGED,
  TIME_AGGREGATION_CHANGED,
  UPDATE_AGGREGATION,
  SORT_TABLE,
  SEARCH_FILTER_CHANGED,
  SEARCH_REFRESH_DESCRIPTION_DATA,
  APPLY_TEMP_FILTER,
  REMOVE_TEMP_FILTER,
  UPDATE_WIDGET_DATA,
  UPDATE_STATS_DATA,
  FETCH_DESCRIPTION_EDIT_SUGGESTIONS,
  FETCH_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED,
  FETCH_DESCRIPTION_EDIT_SUGGESTIONS_FAILED
} from '../actions/niq-widget-actions'

// import { QC_MONITORING_GRAPHS_CLEANUP } from '../actions/niq-qc-actions'

import createESQuery from '../utils/niq-es-query-utils'
// import parseDictionaryCoverageResult from 'utils/dictionary-coverage-parser'
import { buildAggregationRequest } from '../utils/niq-agg-request-helper'

import { NIQ_SEARCH_STORE_PATH, NIQ_SEARCH_EDIT_STORE_PATH } from '../reducers/niq-reducers'

// This is the whitelist of actions for which a search query will run. For any of these actions, shouldQuery is executed
const queryActionWhitelist = [
  APPLY_QUERY,
  SEARCH_FILTER_CHANGED,
  UPDATE_AGGREGATION,
  SEARCH_TOGGLE_LOCK_DATA,
  TOGGLE_QC_MODE,
  CHANGE_CATEGORY_FILTER,
  SET_ACTIVE_SEARCH_TAB
]

const overTimeWidgetActionList = [
  TIME_DIMENSION_CHANGED,
  TIME_AGGREGATION_CHANGED,
  APPLY_QUERY,
  SET_ACTIVE_SEARCH_TAB,
  SEARCH_FILTER_CHANGED
]

const {
  aggregations: {
    BRAND,
    DESCRIPTION,
    DESCRIPTION_FIRST_WORD,
    CATEGORY,
    MERCHANT,
    TIME,
    CONTEXT_REVENUE,
    CONTEXT_OTHER_REVENUE,
    QUERIED_REVENUE,
    QUERIED_OTHER_REVENUE,
    CONTEXT_DESCRIPTION_COUNT,
    QUERIED_DESCRIPTION_COUNT,
    STATS,
    SLICE_CATEGORY,
    SLICE_BRAND,
    INVALID_CATEGORY,
    INVALID_BRAND
    // DICTIONARY_COVERAGE
  }
} = constants

const CURRENT_DEFAULT_AGGREGATIONS = [
  DESCRIPTION,
  BRAND,
  MERCHANT,
  CATEGORY,
  DESCRIPTION_FIRST_WORD,
  SLICE_CATEGORY,
  SLICE_BRAND,
  INVALID_CATEGORY,
  INVALID_BRAND
]

const { EDIT_MODE, TRENDS } = searchToolTabs

const getSearch = state => state[NIQ_SEARCH_STORE_PATH]
const getSearchEdit = state => state[NIQ_SEARCH_EDIT_STORE_PATH]

// Map data for server call
function* transformTreeState({
  aggType,
  queryFilters,
  ignoreQuery = false,
  // monitoring = {},
  useFullDateRange = false
}) {
  const search = yield select(getSearch)
  const searchEdit = yield select(getSearchEdit)

  const query = ignoreQuery ? null : createESQuery(search.queryTree, true)
  let terms = [{ fieldName: 'in_current', values: [true] }]

  // for category filter
  if (
    search.searchFilters &&
    search.searchFilters.categoryFilterList &&
    search.searchFilters.categoryFilterList.length
  ) {
    terms = [...terms, createContextFilter(search.searchFilters.categoryFilterList)]
  }

  // for user filter

  if (search.searchFilters.selectedBrandUsers.length) {
    terms = [...terms, ...prepareUserFilter('brand_added_by', search.searchFilters.selectedBrandUsers)]
  }

  if (search.searchFilters.selectedCategoryUsers.length) {
    terms = [...terms, ...prepareUserFilter('category_added_by', search.searchFilters.selectedCategoryUsers)]
  }

  if (queryFilters && queryFilters.terms) {
    terms = [...terms, ...queryFilters.terms]
  }

  if (aggregationSettings[aggType]?.term) {
    terms = [...terms, aggregationSettings[aggType]?.term]
  }

  // converting selected dates to their utc timezone equivalent
  let fromDate = search.searchFilters.from
  let toDate = search.searchFilters.to
  if (useFullDateRange) {
    fromDate = search.searchFilters.min_range
    toDate = search.searchFilters.max_range
  }
  const fromTimestamp = moment.utc(fromDate).valueOf()
  const toTimestamp = moment.utc(toDate).valueOf()

  return {
    query,
    aggregationFields: { [aggType]: buildAggregationRequest(aggType, searchEdit[aggType], fromTimestamp, toTimestamp) },
    filters: {
      ...omit(search.searchFilters, ['selectedBrandUsers', 'selectedCategoryUsers']),
      from: fromTimestamp,
      to: toTimestamp,
      terms
    },
    queryCurrent: true,
    esTimeout: config.extendedTimeout
  }
}
function createContextFilter(categoryFilterList) {
  return {
    fieldName: constants.esFields.category,
    values: categoryFilterList.map(filter => filter.value)
  }
}

function prepareUserFilter(type, selectedUsers) {
  const clonedUserList = [...selectedUsers]
  if (clonedUserList.includes('all users')) {
    clonedUserList.splice(clonedUserList.indexOf('all users'), 1)
  }
  return [
    {
      fieldName: type,
      values: clonedUserList
    }
  ]
}

function* postSuccessForAggQuery(aggType, data) {
  if (aggType === DESCRIPTION) {
    const ids = map(data[DESCRIPTION], 'dictionary_key')
    yield fetchDescriptionEditStatus(ids)
  }
}

function* fetchAllDescriptionEditStatus() {
  const searchEdit = yield select(getSearchEdit)
  yield fetchDescriptionEditStatus(map(searchEdit.description.data, 'dictionary_key'))
}

function* fetchDescriptionEditStatus(ids) {
  try {
    if (!ids || !ids.length) {
      return
    }
    const batches = chunk(ids, 500)
    for (const batch of batches) {
      const result = yield call(() =>
        axios.post('/api/niq/dictionary/entries/search', {
          ids: batch
        })
      )
      if (result.status === 200) {
        yield put({
          type: DESCRIPTION_EDIT_STATUS_UPDATED,
          data: processDictUpdateData(result.data)
        })
      } else {
        yield put({
          type: DESCRIPTION_EDIT_STATUS_UPDATE_FAILED,
          alerts: [
            {
              id: cuid(),
              type: 'warning',
              message: 'There was an error while fetching edit status for descriptions',
              headline: 'Edit Items'
            }
          ],
          message: 'Backend Error'
        })
      }
    }
  } catch (error) {
    window.captureException(error)
    yield put({
      type: DESCRIPTION_EDIT_STATUS_UPDATE_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'warning',
          message: 'There was an error while fetching edit status for descriptions',
          headline: 'Edit Items'
        }
      ],
      message: error.message
    })
  }
}

function processDictUpdateData(data) {
  const groupedById = groupBy(data, 'id')
  return mapValues(groupedById, arr => {
    return keyBy(arr, 'attributeEdited')
  })
}

function* query(aggType, queryFilters, isTemp) {
  const search = yield select(getSearch)
  if (search.isLocked) {
    // if data reloading is locked don't run any query
    return
  }
  const apiPath = '/api/es/query'
  try {
    yield put({
      type: QUERY_RUN_STARTED,
      aggType,
      loading: true,
      isTemp
    })
    const postParams = yield transformTreeState({ aggType, queryFilters })
    const queryResult = yield call(() => axios.post(apiPath, postParams, { timeout: config.extendedTimeout }))
    yield put({
      type: QUERY_RUN_SUCCEEDED,
      data: queryResult.data,
      aggType,
      loading: false,
      isTemp
    })
    yield postSuccessForAggQuery(aggType, queryResult.data)
  } catch (error) {
    window.captureException(error)
    yield put({
      type: QUERY_RUN_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: `${aggType}`
        }
      ],
      alertTimeout: 10000,
      message: error.message,
      aggType,
      loading: false
    })
  }
}

function* queryStats(aggType, queryFilters, ignoreQuery, statType) {
  const search = yield select(getSearch)
  if (search.isLocked) {
    // if data reloading is locked don't run any query
    return
  }
  const apiPath = '/api/es/query'
  const queryParams = { aggType, statType }
  try {
    yield put({
      type: QUERY_STATS_STARTED,
      ...queryParams,
      loading: true
    })
    const postParams = yield transformTreeState({ aggType, queryFilters, ignoreQuery })
    const queryResult = yield call(() => axios.post(apiPath, postParams, { timeout: config.extendedTimeout }))
    yield put({
      type: QUERY_STATS_SUCCEEDED,
      data: queryResult.data,
      ...queryParams,
      loading: false
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: QUERY_STATS_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: `Stats query: ${aggType}`
        }
      ],
      alertTimeout: 10000,
      message: error.message,
      ...queryParams,
      loading: false
    })
  }
}

function validateSearchFilters({ brandFilters, categoryFilters }) {
  return filter(brandFilters, value => value).length === 0 || filter(categoryFilters, value => value).length === 0
}

/** TODO: move following to state */
let previousQueryTree = null

function* shouldQuery(action) {
  const search = yield select(getSearch)
  if (action.type !== 'SEARCH_FILTER_CHANGED' || action.key === 'scoreCard') {
    yield put({
      type: GET_SEARCH_METADATA
    })
  }

  if (search.isActiveTab !== EDIT_MODE || search.isLocked) {
    return
  }
  const queryTree = createESQuery(search.queryTree, true)
  if (search.isStale[EDIT_MODE] || !isEqual(previousQueryTree, queryTree) || (action.data && action.data.updateAggs)) {
    if (validateSearchFilters(search.searchFilters)) {
      yield put({
        type: QUERY_RUN_FAILED,
        alerts: [
          {
            id: cuid(),
            type: 'warning',
            message: `User must select at least one Brand and Category filter`,
            headline: `No filters applied`
          }
        ],
        alertTimeout: 6000
      })
      return
    }
    const currentAggregations =
      action.data && action.data.updateAggs ? action.data.updateAggs : CURRENT_DEFAULT_AGGREGATIONS
    // TODO: fix this
    yield queryAll(currentAggregations)
    previousQueryTree = cloneDeep(queryTree)
    if (search.widgetEnabledStatus[STATS]) {
      yield all([contextDataStats(), queryDataStats()])
    }
  }
}

function* queryAll(currentAggregations = CURRENT_DEFAULT_AGGREGATIONS, filters) {
  const search = yield select(getSearch)
  yield currentAggregations
    .filter(aggregationType => {
      if (aggregationType in search.widgetEnabledStatus) {
        return search.widgetEnabledStatus[aggregationType]
      }
      return true
    })
    .map(aggregationType => {
      return query(aggregationType, filters)
    })
}

function* queryDataStats(queryParams = {}) {
  const otherBrandParams = cloneDeep(queryParams)
  if (!otherBrandParams.terms) {
    otherBrandParams.terms = []
  }
  otherBrandParams.terms.push({
    fieldName: constants.esFields.brand,
    values: ['other']
  })

  yield all([
    queryStats(QUERIED_REVENUE, { ...queryParams }, false),
    queryStats(QUERIED_DESCRIPTION_COUNT, { ...queryParams }, false, 'descriptionStat'),
    queryStats(QUERIED_OTHER_REVENUE, otherBrandParams, false)
  ])
}

function* contextDataStats() {
  const otherBrandParams = {
    terms: [
      {
        fieldName: constants.esFields.brand,
        values: ['other']
      }
    ]
  }
  yield all([
    queryStats(CONTEXT_REVENUE, null, true),
    queryStats(CONTEXT_DESCRIPTION_COUNT, null, true, 'descriptionStat'),
    queryStats(CONTEXT_OTHER_REVENUE, otherBrandParams, true)
  ])
}

function* applyTempFilter(action) {
  const search = yield select(getSearch)
  const aggsToQuery = filter(CURRENT_DEFAULT_AGGREGATIONS, agg => {
    return agg !== action.aggType && search.widgetEnabledStatus[agg]
  })
  return yield queryWithTempFilter(aggsToQuery)
}

function* getTempFilterParam() {
  const searchEdit = yield select(getSearchEdit)
  const tempFilters = searchEdit.description.tempFilters
  const tempFilterParams = keys(tempFilters).map(aggType => {
    return {
      fieldName: constants.esFields[aggType],
      values: [tempFilters[aggType]]
    }
  })
  return {
    terms: tempFilterParams
  }
}

function* queryWithTempFilter(aggsToQuery = CURRENT_DEFAULT_AGGREGATIONS) {
  const search = yield select(getSearch)
  const queryParams = yield getTempFilterParam()
  yield aggsToQuery.map(aggregationType => {
    return query(aggregationType, { ...queryParams }, true)
  })
  if (search.widgetEnabledStatus[STATS]) {
    yield queryDataStats({ ...queryParams })
  }
}

function* removeTempFilter(action) {
  const search = yield select(getSearch)
  const aggsToQuery = filter(CURRENT_DEFAULT_AGGREGATIONS, agg => {
    return search.widgetEnabledStatus[agg]
  })
  return yield queryWithTempFilter(aggsToQuery)
}

function* queryDescriptions() {
  const search = yield select(getSearch)
  if (search.widgetEnabledStatus[DESCRIPTION]) {
    yield queryWithTempFilter([DESCRIPTION])
  }
}

function* autosuggest(action) {
  yield call(delay, 300) // debounce
  const search = yield select(getSearch)
  const filterApiMap = {
    brand: {
      path: '/api/niq/taxonomy/brands/suggestions',
      params: {
        responseType: 'qc-tool',
        fullPath: search.searchValue,
        isLeaf: action.excludeParents,
        isActive: action.excludeInactive,
        limit: 100
      }
    },
    category: {
      path: '/api/niq/taxonomy/categories/suggestions',
      params: {
        responseType: 'qc-tool',
        fullPath: search.searchValue,
        isLeaf: action.excludeParents,
        isActive: action.excludeInactive,
        limit: 100
      }
    },
    slice_category: {
      path: '/api/taxonomy/categories/suggestions',
      params: {
        responseType: 'qc-tool',
        fullPath: search.searchValue,
        isLeaf: action.excludeParents,
        isActive: action.excludeInactive,
        limit: 100
      }
    },
    slice_brand: {
      path: '/api/taxonomy/brands/suggestions',
      params: {
        responseType: 'qc-tool',
        fullPath: search.searchValue,
        isLeaf: action.excludeParents,
        isActive: action.excludeInactive,
        limit: 100
      }
    },
    merchant: {
      path: '/api/niq/taxonomy/merchants/suggestions',
      params: {
        responseType: 'qc-tool',
        merchantName: search.searchValue,
        isClientQualified: true,
        limit: 100
      }
    },
    omnisales_module: {
      path: '/api/niq/es/autocomplete',
      params: {
        fieldName: 'omnisales_module',
        searchText: search.searchValue,
        limit: 50
      }
    },
    coding_mode: {
      path: '/api/niq/es/autocomplete',
      params: {
        fieldName: 'coding_mode',
        searchText: search.searchValue,
        limit: 50
      }
    },
    item_type: {
      path: '/api/niq/es/autocomplete',
      params: {
        fieldName: 'item_type',
        searchText: search.searchValue,
        limit: 50
      }
    },
    brand_owner: {
      path: '/api/niq/es/autocomplete',
      params: {
        fieldName: 'brand_owner',
        searchText: search.searchValue,
        limit: 50
      }
    }
  }
  if (!filterApiMap[action.filter]) {
    return
  }
  try {
    const { path, params } = filterApiMap[action.filter]
    const autoSuggestResult = yield call(() => axios.get(path, { params }))
    const data = autoSuggestResult.data.suggestions
    yield put({
      type: AUTOSUGGEST_SUCCEEDED,
      data: [...data]
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: AUTOSUGGEST_FAILED,
      data: error.message
    })
    yield put(createAlert('danger', error.message, `Failed to get auto suggestions`))
  }
}

function* autosuggestEdits(action) {
  yield call(delay, 300) // debounce
  /* @todo TODO: Data from server is correct but the client side does not show the right auto complete values that contain > . The below replace is to partially fix this problem */
  const searchValue = (action.data.text || '').replace(/\s*>\s*/g, ' > ')
  const searchType = action.data.type
  const categoryFullPath = action.data.categoryFullPath
  const filterApiMap = {
    brand_name: '/api/niq/taxonomy/brands/suggestions',
    category_full_path: '/api/niq/taxonomy/categories/suggestions'
  }
  const params = {
    responseType: 'qc-tool',
    fullPath: searchValue,
    isLeaf: true,
    isActive: true,
    limit: 100,
    categoryFullPath
  }
  try {
    const autoSuggestResult = yield call(() => axios.get(filterApiMap[searchType], { params }))

    yield put({
      type: searchType === 'brand_name' ? DESCRIPTION_BRAND_SUGGEST_SUCCEEDED : DESCRIPTION_CATEGORY_SUGGEST_SUCCEEDED,
      data: autoSuggestResult.data.suggestions
    })
  } catch (error) {
    window.captureException(error)
    // TODO: FIX MISSING ACTIONS
    // yield put({ type: searchType === 'brand_name' ? DESCRIPTION_BRAND_SUGGEST_FAILED : DESCRIPTION_CATEGORY_SUGGEST_FAILED,
    //   alerts: [{
    //     id: cuid(),
    //     type: 'danger',
    //     message: `Failed to get suggestions for ${searchType === 'brand_name' ? 'brand' : 'category'}`,
    //     headline: 'Search Suggestions'
    //   }],
    //   alertTimeout: 10000,
    //   data: error.message
    // })
  }
}

function* saveDictionaryEntries(action) {
  const searchEdit = yield select(getSearchEdit)
  try {
    yield put({
      type: DESCRIPTION_SAVE_EDITS_STARTED,
      alerts: [
        {
          id: cuid(),
          type: 'info',
          message: 'Submitting changes...',
          headline: 'Dictionary Edits'
        }
      ]
    })
    const removeKeys = yield batchSaveDictionary(searchEdit.description.stagedEdits, searchEdit.description.data)
    yield put({
      type: DESCRIPTION_SAVE_EDITS_SUCCEEDED,
      removeKeys,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: 'All changes were successfully submitted.',
          headline: 'Dictionary Edits'
        }
      ]
    })
  } catch (error) {
    window.captureException(error)
    // TODO: handle timeouts and other errors
    yield put({
      type: DESCRIPTION_SAVE_EDITS_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: 'An error occurred while creating edit jobs.',
          headline: 'Dictionary Edit'
        }
      ],
      alertTimeout: 10000,
      message: error.message
    })
  } finally {
    // Refresh description edits after dictionary update
    const ids = map(searchEdit.description.data, 'dictionary_key')
    yield fetchDescriptionEditStatus(ids)
  }
}

function* batchSaveDictionary(stagedEdits, descriptions) {
  const search = yield select(getSearch)
  const selectedIndex = get(search, 'searchFilters.scoreCard')

  const edits = []
  const updateKeys = []

  _(descriptions)
    .keyBy('dictionary_key')
    .at(keys(stagedEdits))
    .value()
    .forEach(entry => {
      if (entry && entry.dictionary_key) {
        edits.push({
          ...entry,
          selectedIndex,
          edits: stagedEdits[entry.dictionary_key]
        })
        updateKeys.push(entry.dictionary_key)
      }
    })

  const batches = chunk(edits, 100)
  yield batches.map(batch =>
    call(() =>
      axios.post('/api/niq/dictionary/update', {
        rows: batch
      })
    )
  )
  return updateKeys
}

function* getSearchMetaData() {
  try {
    const search = yield select(getSearch)
    let index = search.searchFilters.scoreCard
    if (index === 'all') {
      index = 'products'
    }
    const result = yield call(() => axios.get('/api/search-tool/metadata', { params: { index } }))
    if (result && result.data) {
      const { dataRefresh, dateRange, descUpdateUsers } = result.data
      const dateRangeInit = search.searchFilters.dateRangeInit
      const data = {
        dataRefresh,
        dateRange: {
          from: dateRangeInit
            ? moment.utc(dateRange[0]).format(constants.dateFormat.qcTool)
            : search.searchFilters.from,
          to: dateRangeInit ? moment.utc(dateRange[1]).format(constants.dateFormat.qcTool) : search.searchFilters.to,
          min_range: moment.utc(dateRange[0]).format(constants.dateFormat.qcTool),
          max_range: moment.utc(dateRange[1]).format(constants.dateFormat.qcTool)
        },
        dateRangeInit: false,
        brandUpdateUsers: descUpdateUsers.brand_added_by,
        categoryUpdateUsers: descUpdateUsers.category_added_by
      }
      yield put({
        type: GET_SEARCH_METADATA_SUCCEEDED,
        data
      })
    }
  } catch (error) {
    window.captureException(error)
    yield put({
      type: GET_SEARCH_METADATA_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: `There was an error loading metadata for Search / QC tool`,
          headline: 'Search / QC tool'
        }
      ],
      alertTimeout: 10000
    })
  }
}

function* fetchTypeChildren(action) {
  try {
    const replaceRegex = new RegExp(`^${constants.parentElementPrefix}`)
    const searchValue = action.data.value.replace(replaceRegex, '')
    const filterApiMap = {
      brand: '/api/niq/taxonomy/brands/suggestions',
      category: '/api/niq/taxonomy/categories/suggestions',
      slice_category: '/api/taxonomy/categories/suggestions',
      slice_brand: '/api/taxonomy/brands/suggestions'
    }
    const params = {
      responseType: 'qc-tool',
      fullPath: searchValue,
      isLeaf: true,
      isActive: true,
      condition: action.data.condition,
      limit: LIMIT_EXTENDED_SEARCH_RESULTS
    }
    const result = yield call(() => axios.get(filterApiMap[action.data.type], { params }))
    yield put({
      type: FETCH_TYPE_CHILDREN_SUCCEEDED,
      data: result.data.suggestions,
      ruleId: action.data.id,
      ruleGroupLabel: searchValue
    })

    const totalItems = result.data.total

    if (totalItems > LIMIT_EXTENDED_SEARCH_RESULTS) {
      yield put(
        createAlert(
          'warning',
          `Please note: Your selection contains ${totalItems} items, but only ${LIMIT_EXTENDED_SEARCH_RESULTS} have been added to the query.`,
          'Note: Not All Items Were Added to the Query'
        )
      )
    }
  } catch (error) {
    window.captureException(error)
    yield put({
      type: FETCH_TYPE_CHILDREN_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: `Failed to fetch children of ${action.data.value}`,
          headline: 'Search'
        }
      ],
      alertTimeout: 10000
    })
  }
}

function* updateWidgetData(action) {
  const search = yield select(getSearch)
  if (
    search.isActiveTab !== EDIT_MODE ||
    search.isLocked ||
    (action.aggType in search.widgetEnabledStatus && !search.widgetEnabledStatus[action.aggType])
  ) {
    return
  }
  yield queryWithTempFilter([action.aggType])
}

function* fetchGroups() {
  try {
    const response = yield call(() => axios.get('/api/search-query/group'))
    yield put({
      type: FETCH_SAVED_GROUP_SUCCEEDED,
      data: response.data || []
    })
  } catch (error) {
    yield put({
      type: FETCH_SAVED_GROUP_FAILED
    })
  }
}

function* onCreateGroup(action) {
  try {
    const response = yield call(() => axios.post('/api/search-query/group', { name: action.value }))
    yield put({
      type: CREATE_GROUP_SUCCEEDED,
      data: response.data
    })
    yield fetchGroups()
    yield fetchQueriesByGroup(false)
  } catch (error) {
    yield put({
      type: CREATE_GROUP_FAILED
    })
  }
}

function* fetchSavedQueries() {
  yield fetchQueriesByGroup(false)
}

function* fetchQueriesByGroup(clear) {
  const search = yield select(getSearch)
  try {
    const response = yield call(() =>
      axios.get('/api/search-query/query', { params: { id: search.queryHeader.group.selectedID } })
    )
    yield put({
      type: FETCH_QUERIES_BY_GROUP_SUCCEEDED,
      clear,
      data: response.data || []
    })
  } catch (error) {
    yield put({
      type: FETCH_QUERIES_BY_GROUP_FAILED
    })
    yield put(createAlert('danger', error.message, `Queries by Group fetch failed`))
  }
}

function* onCreateGroupQuery(action) {
  const search = yield select(getSearch)
  try {
    const response = yield call(() =>
      axios.post('/api/search-query/query', {
        queryName: action.value,
        query: search.queryTree,
        groupName: search.queryHeader.group.selectedValue
      })
    )
    yield put({
      type: CREATE_QUERY_SUCCEEDED,
      data: response.data,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: 'Successfully created Query',
          headline: 'Query Created'
        }
      ]
    })
    yield fetchQueriesByGroup(true)
  } catch (error) {
    window.captureException(error)
    const errorMessage = 'Failed to create query group'
    yield put({
      type: CREATE_QUERY_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: errorMessage,
          headline: 'Create Query Group'
        }
      ],
      alertTimeout: 10000
    })
  }
}

function* deleteGroup() {
  const search = yield select(getSearch)
  try {
    const response = yield call(() =>
      axios.delete('/api/search-query/group', { data: { id: search.queryHeader.group.selectedID } })
    )
    yield put({
      type: DELETE_GROUP_SUCCEEDED,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: response.data,
          headline: 'Group Deleted'
        }
      ]
    })
    yield fetchGroups()
  } catch (error) {
    window.captureException(error)
    const errorMessage = 'Failed to delete query group'
    yield put({
      type: DELETE_GROUP_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: errorMessage,
          headline: 'Delete Query Group'
        }
      ],
      alertTimeout: 10000
    })
  }
}

function* deleteQuery() {
  const search = yield select(getSearch)
  try {
    const response = yield call(() =>
      axios.delete('/api/search-query/query', { data: { id: search.queryHeader.query.selectedID } })
    )
    yield put({
      type: DELETE_QUERY_SUCCEEDED,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: response.data,
          headline: 'Query Deleted'
        }
      ]
    })
    yield fetchQueriesByGroup(true)
  } catch (error) {
    window.captureException(error)
    const errorMessage = 'Failed to delete query'
    yield put({
      type: DELETE_QUERY_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: errorMessage,
          headline: 'Delete Query'
        }
      ],
      alertTimeout: 10000
    })
  }
}

function* saveSearchQuery() {
  try {
    const search = yield select(getSearch)
    const payload = {
      group: { id: search.queryHeader.group.selectedID, name: search.queryHeader.group.updatedValue },
      query: { id: search.queryHeader.query.selectedID, name: search.queryHeader.query.updatedValue },
      queryTree: search.queryTree
    }
    const response = yield call(() => axios.post('/api/search-query', payload))
    yield put({
      type: SAVE_SEARCH_QUERY_SUCCEEDED,
      data: response.data,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: `Updated Query Successfully`,
          headline: 'Query Manager'
        }
      ]
    })
  } catch (error) {
    window.captureException(error)
    const errorMessage = 'Failed to update query'
    yield put({
      type: SAVE_SEARCH_QUERY_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: errorMessage,
          headline: 'Query Manager'
        }
      ],
      alertTimeout: 10000
    })
  }
}

function* fetchQuerySQL(action) {
  try {
    yield put({
      type: GET_QUERY_SQL_STARTED
    })
    const result = yield call(() =>
      axios.post('/api/search-query/get-sql', { queryTree: action.queryTree }, { timeout: config.extendedTimeout })
    )
    yield put({
      type: GET_QUERY_SQL_SUCCEEDED,
      data: result.data.sql
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: GET_QUERY_SQL_FAILED
    })
    yield put(createAlert('danger', error.message, `Error in fetching sql`))
  }
}

function* overTimeWidgetQuery(action) {
  const search = yield select(getSearch)
  if (
    search.isActiveTab === TRENDS &&
    (search.isStale[TRENDS] || [TIME_DIMENSION_CHANGED, TIME_AGGREGATION_CHANGED].includes(action.type))
  ) {
    yield query(TIME, null, true)
  }
}

// ************* Metrics ******************** */

/**
 * Inform metrics graphs about category change
 */
/* function* updateMetrcisWidgets() {
  // get selected categorires
  const search = yield select(getSearch)
  const searchCategories = search.searchFilters.categoryFilterList
  const newCategoryId = searchCategories[0]?.id ?? null

  // current categories used in Metrics
  const metricCategories = yield select(state => state[METRICS_STORE_PATH].categories)
  const currentCategoryId = metricCategories[0]?.id ?? null

  const isNewCategorySelected = newCategoryId !== currentCategoryId
  const isChangedCountOfSelectedCategories = searchCategories.length !== metricCategories.length
  if (isNewCategorySelected || isChangedCountOfSelectedCategories) {
    // clean up metrics graphs data
    yield put({
      type: QC_MONITORING_GRAPHS_CLEANUP
    })

    // cleanup queries information
    yield put({
      // type: metricsActions.cleanupQueries.type
    })

    // apply new category in metrics tab
    yield put({
      // type: metricsActions.setCategory.type,
      payload: {
        categories: search.searchFilters.categoryFilterList
      }
    })
  }
} */

/* function* fetchMonitoringDictionaryData() {
  const search = yield select(getSearch)
  if (search.isLocked) {
    // if data reloading is locked don't run any query
    return
  }
  try {
    const apiPath = '/api/es/dictionarycoverage'
    yield put({
      type: QUERY_RUN_STARTED,
      aggType: DICTIONARY_COVERAGE,
      loading: true
    })
    const postParams = yield transformTreeState({ aggType: DICTIONARY_COVERAGE })
    const queryResult = yield call(() => axios.post(apiPath, postParams, { timeout: config.extendedTimeout }))
    yield put({
      type: QUERY_RUN_SUCCEEDED,
      data: parseDictionaryCoverageResult(queryResult.data),
      aggType: DICTIONARY_COVERAGE,
      loading: false
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: QUERY_RUN_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: `${DICTIONARY_COVERAGE}`
        }
      ],
      alertTimeout: 10000,
      message: error.message,
      aggType: DICTIONARY_COVERAGE,
      loading: false
    })
  }
} */

/* function* fetchMonitoringData(action) {
  const { graphType, queryId, categoryId } = action.payload

  const { storeKey, aggregation, type } = getGraphOptions(graphType)

  const postParams = yield transformTreeState({ aggType: aggregation, useFullDateRange: true })

  postParams.options = {
    type,
    queryId,
    graphType,
    categoryId
  }

  try {
    // set loading=true
    yield put({
      type: QUERY_RUN_STARTED,
      aggType: storeKey,
      loading: true,
      isTemp: true
    })
    const apiPath = '/api/es/monitoring'
    const response = yield call(() => axios.post(apiPath, postParams, { timeout: config.extendedTimeout }))
    const isQueryCorrect = response.data.isQueryCorrect === true
    if (isQueryCorrect) {
      yield put({
        type: QUERY_SET_QUALITY,
        aggType: storeKey,
        isQueryCorrect: true
      })
      yield put({
        type: QUERY_RUN_SUCCEEDED,
        data: { [storeKey]: qcMonitoringResult(response.data, graphType) },
        aggType: storeKey,
        loading: false
      })
    } else {
      yield put({
        type: QUERY_SET_QUALITY,
        aggType: storeKey,
        isQueryCorrect: false
      })
      yield put({
        type: QUERY_RUN_SUCCEEDED,
        data: {},
        aggType: storeKey,
        loading: false
      })
    }
  } catch (error) {
    window.captureException(error)
    yield put({
      type: QUERY_RUN_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: `${storeKey}`
        }
      ],
      alertTimeout: 10000,
      message: error.message,
      aggType: storeKey,
      loading: false
    })
  }
} */
// ************* /Metrics  ******************** */

function* updateStatsData() {
  const search = yield select(getSearch)
  if (search.widgetEnabledStatus[STATS]) {
    const queryParams = yield getTempFilterParam()
    yield all([contextDataStats(), queryDataStats({ ...queryParams })])
  }
}

function* sortTable(action) {
  const queryParams = yield getTempFilterParam()
  yield queryAll(action.data.updateAggs, { ...queryParams })
}

function* fetchDescriptionEditSuggestions() {
  const searchEdit = yield select(getSearchEdit)
  const payload = []
  // rows property will always present
  searchEdit.description.edits.rows.forEach(row => {
    if (row.isBrandEditable) {
      payload.push({
        description: row.description,
        merchantId: row.merchantId,
        categoryId: row.category_id,
        descriptionId: row.id
      })
    }
  })

  try {
    const CHUNK_SIZE = 50
    const batches = chunk(payload, CHUNK_SIZE)
    for (const batch of batches) {
      const autoSuggestResult = yield call(() => axios.post('/api/dictionary/edits/suggestions', batch))
      if (autoSuggestResult && autoSuggestResult.data) {
        yield put({
          type: FETCH_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED,
          data: autoSuggestResult.data,
          loading: true
        })
      }
    }
    yield put({
      type: FETCH_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED,
      loading: false
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: FETCH_DESCRIPTION_EDIT_SUGGESTIONS_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: `Failed to get brand suggestions for some descriptions`,
          headline: 'Description Edit'
        }
      ],
      alertTimeout: 10000,
      data: error.message
    })
  }
}

function* searchSaga() {
  yield takeLatest(action => queryActionWhitelist.includes(action.type), shouldQuery)
  yield takeLatest(DESCRIPTION_SAVE_EDITS, saveDictionaryEntries)
  yield takeLatest([DESCRIPTION_BRAND_SUGGEST, DESCRIPTION_CATEGORY_SUGGEST], autosuggestEdits)
  yield takeLatest(SEARCH_TERM_SUGGEST, autosuggest)
  yield takeLatest(FETCH_TYPE_CHILDREN, fetchTypeChildren)
  yield takeLatest(SEARCH_REFRESH_DESCRIPTION_DATA, queryDescriptions)
  yield takeLatest(GET_SEARCH_METADATA, getSearchMetaData)
  yield takeLatest(REFRESH_DESCRIPTION_EDIT_STATUS, fetchAllDescriptionEditStatus)
  yield takeLatest(APPLY_TEMP_FILTER, applyTempFilter)
  yield takeLatest(REMOVE_TEMP_FILTER, removeTempFilter)
  yield takeLatest(UPDATE_WIDGET_DATA, updateWidgetData)
  yield takeLatest(SORT_TABLE, sortTable)
  yield takeLatest([FETCH_SAVED_GROUP, RESET_QUERY_HEADER], fetchGroups)
  yield takeLatest(GROUP_CREATE, onCreateGroup)
  yield takeLatest(FETCH_QUERIES_BY_GROUP, fetchSavedQueries)
  yield takeLatest(QUERY_CREATE, onCreateGroupQuery)
  yield takeLatest(DELETE_GROUP, deleteGroup)
  yield takeLatest(DELETE_QUERY, deleteQuery)
  yield takeLatest(SAVE_SEARCH_QUERY, saveSearchQuery)
  yield takeLatest(UPDATE_STATS_DATA, updateStatsData)
  yield takeLatest(action => overTimeWidgetActionList.includes(action.type), overTimeWidgetQuery)
  yield takeLatest(FETCH_DESCRIPTION_EDIT_SUGGESTIONS, fetchDescriptionEditSuggestions)
  yield takeLatest(GET_QUERY_SQL, fetchQuerySQL)
  // Metrics
  // yield takeEvery(metricsActions.fetchDictionaryGraph.type, fetchMonitoringDictionaryData)
  // yield takeEvery(metricsActions.fetchGraph.type, fetchMonitoringData)
  // yield takeLatest(APPLY_QUERY, updateMetrcisWidgets)
}

export default searchSaga
