import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// components
import Box from '../Box'
import CheckMark from '../Icon/CheckMark'
import Remove from '../Icon/Remove'

// constants
import constants from './constants'

class ThreeWayCheckbox extends Component {
  static propTypes = {
    valueDidChange: PropTypes.func,
    checkedStatus: PropTypes.any,
    children: PropTypes.element,
    className: PropTypes.string,
    label: PropTypes.string
  }

  _didClick = () => {
    const { checkedStatus, valueDidChange } = this.props

    if (checkedStatus === constants.STATUS.ALL || checkedStatus === constants.STATUS.PARTIAL) {
      return valueDidChange(false)
    }

    return valueDidChange(true)
  }

  render() {
    const { children, className, checkedStatus, label } = this.props

    return (
      <Box onClick={this._didClick}>
        <div className={cx('CheckBox noWrap alignItemsCenter flex', className)}>
          {children}
          {checkedStatus === constants.STATUS.ALL && <CheckMark checked className="checkmark checked" />}
          {checkedStatus === constants.STATUS.PARTIAL && <Remove className="checkmark checked" />}
          {checkedStatus === constants.STATUS.NONE && <CheckMark className="checkmark" />}
          <label className={'CheckBox__label'}>{label}</label>
        </div>
      </Box>
    )
  }
}

export default ThreeWayCheckbox
