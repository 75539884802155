// eslint-disable-next-line
import tenantConfig from './config-APP_TENANT.js'
import _debug from 'debug'

/** THIS FILE IS THE CLIENT SIDE CONFIG, it could be retrieved by an XHR call */
const ENV = process.env.REACT_APP_DEPLOY_ENV
const { NAME: appName, colors, sentry = {}, appBaseUrls = {}, serverBaseUrls = {} } = tenantConfig
const SENTRY_DSN = sentry[ENV]
const serverBaseUrl = serverBaseUrls[ENV]

const boomerBaseURL =
  ENV === 'production' ? 'https://boomer.produmap.slicetest.com/boomer' : 'https://boomer.staging.slicetest.com/boomer'

const defaultConfig = {
  appName,
  colors,
  authMode: 'local',
  boomerBaseURL,
  appBaseUrl: appBaseUrls[ENV],
  apiBaseUrl: `${serverBaseUrl}/api`,
  serverBaseUrl,
  periscopeBaseUrl: 'https://www.periscopedata.com',
  enforceHttps: true,
  log: {
    analytics: {
      enabled: false
    },
    socket: {
      enabled: false
    }
  },
  onboardUsingTempPassword: false,
  alert: {
    defaultNotificationTimeout: 3000
  },
  extendedTimeout: 300000,
  copyright: 'Nielsen Consumer LLC'
}

let config
switch (ENV) {
  case 'test':
  case 'development':
    config = {
      ...defaultConfig,
      allowJobRetryOnSuccess: true,
      enforceHttps: false,
      log: {
        ...defaultConfig.log,
        sentry: {
          enabled: false, // To prevent getting errors from every developer on their develop environment
          dsn: SENTRY_DSN
        }
      }
    }
    break

  case 'staging':
    config = {
      ...defaultConfig,
      allowJobRetryOnSuccess: true,
      log: {
        ...defaultConfig.log,
        sentry: {
          enabled: true,
          dsn: SENTRY_DSN
        }
      }
    }
    break

  case 'production':
    config = {
      ...defaultConfig,
      log: {
        ...defaultConfig.log,
        sentry: {
          enabled: true,
          dsn: SENTRY_DSN
        }
      }
    }
    break

  default:
    _debug('global')(`REACT_APP_DEPLOY_ENV ${ENV} is unknown for the client configuration`)
}

export default config
