import { takeLatest, select, put, call } from 'redux-saga/effects'
import axios from 'axios'
import contentRange from 'content-range'

import {
  FETCH_PERMISSIONS,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAILED,
  FETCH_PRODUCTS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILED
} from '../actions/app-actions'

const fetchProducts = () => axios.get('/api/products')

function* getProducts() {
  try {
    const products = yield fetchProducts()
    yield put({
      type: FETCH_PRODUCTS_SUCCESS,
      products: products.data
    })
  } catch (err) {
    yield put({
      type: FETCH_PRODUCTS_FAILED,
      message: err.message
    })
  }
}

function* getPermissions(action) {
  try {
    const state = yield select(state => state)
    const response = yield call(() =>
      axios.get('/api/permissions', {
        params: {
          offset: action.offset,
          count: action.count,
          q: action.search ? action.search : undefined,
          sort: 'label'
        }
      })
    )
    const parts = contentRange.parse(response.headers['content-range'])
    yield put({
      type: FETCH_PERMISSIONS_SUCCESS,
      data: response.data,
      offset: action.offset,
      count: action.search ? state.permissions.count : parts ? parts.length : 0
    })
  } catch (err) {
    yield put({
      type: FETCH_PERMISSIONS_FAILED,
      message: err.message
    })
  }
}

export default [takeLatest(FETCH_PRODUCTS, getProducts), takeLatest(FETCH_PERMISSIONS, getPermissions)]
