import { createReducer } from '@reduxjs/toolkit'

import categoryTestsActions from './categoryTestsActions'
import { DATA_TABLE } from 'components/DataTable/constants'

const CATEGORY_TEST_STORE_PATH = 'categoryTests'

export { CATEGORY_TEST_STORE_PATH }

const CategoryTestsReducers = createReducer(
  {
    loading: false,
    page: 1,
    pageSize: DATA_TABLE.PAGE_SIZE,
    sortBy: 'id',
    sortOrder: 'asc',
    filters: {
      // set default filters here
      isDeliveryJobActive: { value: '1' }
    },
    data: {
      count: 0,
      rows: []
    },
    globalConfiguration: {
      loading: false,
      error: null,
      data: {}
    }
  },
  builder => {
    builder
      .addCase(categoryTestsActions.fetch, (state, action) => {
        state.loading = true
      })
      .addCase(categoryTestsActions.successLoad, (state, action) => {
        state.data.rows = action.payload.rows
        state.data.count = action.payload.count
        state.loading = false
      })
      .addCase(categoryTestsActions.failedLoad, (state, action) => {
        state.loading = false
      })

      .addCase(categoryTestsActions.updateSettings, (state, action) => {
        return {
          ...state,
          ...action.payload
        }
      })
      .addCase(categoryTestsActions.clearFilters, (state, action) => {
        state.filters = {}
        // also clear sorting
        state.sortBy = 'id'
        state.sortOrder = 'asc'
      })
      // Global configs
      .addCase(categoryTestsActions.fetchGlobalConfig, (state, action) => {
        state.globalConfiguration.loading = true
        state.globalConfiguration.error = null
      })
      .addCase(categoryTestsActions.successLoadGlobalConfig, (state, action) => {
        state.globalConfiguration.data = action.payload
        state.globalConfiguration.loading = false
        state.globalConfiguration.error = null
      })
      .addCase(categoryTestsActions.failedLoadGlobalConfig, (state, action) => {
        state.globalConfiguration.loading = false
        state.globalConfiguration.error = action.payload.error
      })
      .addCase(categoryTestsActions.clearGlobalConfig, (state, action) => {
        state.globalConfiguration.data = {}
      })
  }
)

export default CategoryTestsReducers
