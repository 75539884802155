export const FETCH_REPORT_FOR_EDIT = 'FETCH_REPORT_FOR_EDIT'
export const FETCH_REPORT_FOR_EDIT_SUCCESS = 'FETCH_REPORT_FOR_EDIT_SUCCESS'
export const FETCH_REPORT_FOR_EDIT_FAILED = 'FETCH_REPORT_FOR_EDIT_FAILED'
export const DISMISS_REPORT_EDIT_ERROR = 'DISMISS_REPORT_EDIT_ERROR'
export const INITIALIZE_NEW_REPORT = 'INITIALIZE_NEW_REPORT'
export const UPDATE_BASIC_DETAILS = 'CRE_UPDATE_BASIC_DETAILS_FORM'
export const UPDATE_FILTER_DETAILS = 'CRE_UPDATE_FILTER_DETAILS_FORM'
export const UPDATE_ALL_FILTER_DETAILS = 'CRE_UPDATE_ALL_FILTER_DETAILS_FORM'
export const DELETE_FILTER = 'CRE_DELETE_FILTER'
export const SAVE_FILTER = 'CRE_SAVE_FILTER'
export const DISCARD_CHANGES = 'CRE_DISCARD_CHANGES'
export const UPDATE_TAB_DETAILS = 'CRE_UPDATE_TAB_DETAILS'
export const DELETE_TAB = 'CRE_DELETE_TAB'
export const ADD_TAB = 'CRE_ADD_TAB'
export const SAVE_TAB = 'CRE_SAVE_TAB'
export const SAVE_REPORT = 'CRE_SAVE_REPORT'
export const SAVE_REPORT_DONE = 'CRE_SAVE_REPORT_DONE'
export const QUERY_FILTER_VALUES = 'CRE_QUERY_FILTER_VALUES'
export const QUERY_FILTER_VALUES_SUCCESS = 'CRE_QUERY_FILTER_VALUES_SUCCESS'
export const QUERY_FILTER_VALUES_FAILED = 'CRE_QUERY_FILTER_VALUES_FAILED'
export const FETCH_REPORT_FOLDERS = 'CRE_FETCH_REPORT_FOLDERS'
export const FETCH_REPORT_FOLDERS_SUCCESS = 'CRE_FETCH_REPORT_FOLDERS_SUCCESS'

export function fetchReportForEdit(id) {
  return {
    type: FETCH_REPORT_FOR_EDIT,
    id
  }
}

export function initializeNewReport() {
  return {
    type: INITIALIZE_NEW_REPORT
  }
}

export function dismissError() {
  return {
    type: DISMISS_REPORT_EDIT_ERROR
  }
}

export function updateBasicDetails(payload, merge = true) {
  return {
    type: UPDATE_BASIC_DETAILS,
    payload,
    merge
  }
}

export function updateFilterDetails(payload, merge = true) {
  return {
    type: UPDATE_FILTER_DETAILS,
    payload,
    merge
  }
}

export function updateAllFilterDetails(payload) {
  return {
    type: UPDATE_ALL_FILTER_DETAILS,
    payload
  }
}

export function discardChanges() {
  return {
    type: DISCARD_CHANGES
  }
}

export function deleteFilter(id) {
  return {
    type: DELETE_FILTER,
    id
  }
}

export function saveFilter(payload) {
  return {
    type: SAVE_FILTER,
    payload
  }
}

export function updateTabDetails(payload, merge = true) {
  return {
    type: UPDATE_TAB_DETAILS,
    payload,
    merge
  }
}

export function deleteTab(id) {
  return {
    type: DELETE_TAB,
    id
  }
}

export function addTab() {
  return {
    type: ADD_TAB
  }
}

export function saveTab(payload) {
  return {
    type: SAVE_TAB,
    payload
  }
}

export function saveReport() {
  return {
    type: SAVE_REPORT
  }
}

export function queryFilterValues(payload) {
  return {
    type: QUERY_FILTER_VALUES,
    payload
  }
}

export function queryFilterValuesSuccess(payload) {
  return {
    type: QUERY_FILTER_VALUES_SUCCESS,
    payload
  }
}

export function fetchReportFolders(id) {
  return {
    type: FETCH_REPORT_FOLDERS,
    id
  }
}
