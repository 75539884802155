import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { each, debounce } from 'lodash'

// components
import { Field } from 'redux-form'
import renderUserEditField from './render-field'
import SearchTypeahead from '../SearchTypeahead'

import config from '../../config'

const onboardUsingTempPassword = config.onboardUsingTempPassword

export default class BasicInfos extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showPasswordText: false
    }

    this.onChange = this.onChange.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
  }

  onChange = updatedGroups => {
    // Check for existing group in state for admin
    const isGroupAdmin = (user, group) => {
      if (user && user.Groups && user.Groups.length > 0) {
        const existingGroup = user.Groups.find(userGroup => userGroup.id === group.value)
        if (!existingGroup) return false
        return existingGroup.GroupUser ? existingGroup.GroupUser.isAdmin : false
      } else {
        return false
      }
    }
    const groupRoles = {}
    each(updatedGroups, group => {
      if (groupRoles[group.value]) {
        groupRoles[group.value].visible = true
      } else {
        groupRoles[group.value] = {
          visible: true,
          isAdmin: isGroupAdmin(this.props.userEdit, group),
          id: group.value,
          name: group.label,
          isActive: group.isActive
        }
      }
    })

    this.props.setBasicInfosState(groupRoles)
  }

  onInputChange = searchTerm => {
    this.fetchGroupsDebounce(searchTerm)
  }

  fetchGroupsDebounce = debounce(searchTerm => {
    this.props.fetchGroupSuggestions(searchTerm)
  }, 200)

  onShowPasswordClick = () => {
    const showPasswordText = !this.state.showPasswordText
    this.setState({ showPasswordText })
  }

  render() {
    const { id, user, userEdit, groupSuggestions } = this.props
    const { showPasswordText } = this.state

    const passwordFieldType = showPasswordText ? 'text' : 'password'

    const passwordButtonText = showPasswordText ? 'Hide Password' : 'Show Password'

    return (
      <div>
        <h3>{!id && !user.isOnboarded ? 'MODIFY DISPLAY NAME' : 'BASIC INFORMATION'}</h3>
        <table>
          <tbody>
            <Field
              type="text"
              name="fullname"
              required
              component={renderUserEditField}
              label={`${!id && !user.isOnboarded ? 'New ' : ''}Display Name`}
            />
            {id === 'new' && <Field name="email" type="text" label="Email" component={renderUserEditField} />}
            {onboardUsingTempPassword && id === 'new' && (
              <Field
                name="password"
                type={passwordFieldType}
                label="Temporary Password"
                component={renderUserEditField}
                required={onboardUsingTempPassword}
              />
            )}
            {onboardUsingTempPassword && id === 'new' && (
              <tr>
                <td />
                <td>
                  <button type="button" onClick={this.onShowPasswordClick}>
                    {passwordButtonText}
                  </button>
                </td>
              </tr>
            )}
            {id && (
              <tr>
                <td>Groups</td>
                <td>
                  <SearchTypeahead
                    multiple
                    size={'large'}
                    justify={'left'}
                    disabled={false}
                    autoFocus={false}
                    labelKey={'label'}
                    placeholder={'Search Groups..'}
                    onChange={this.onChange}
                    onInputChange={this.onInputChange}
                    suggestions={getSelectableGroups(groupSuggestions)}
                    selectedValues={getSelectableGroups(userEdit.Groups)}
                  />
                </td>
                <td />
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

BasicInfos.propTypes = {
  id: PropTypes.string,
  user: PropTypes.object,
  setBasicInfosState: PropTypes.func,
  groupRoles: PropTypes.object,
  groups: PropTypes.array,
  userEdit: PropTypes.object,
  fetchGroupSuggestions: PropTypes.func,
  groupSuggestions: PropTypes.array
}

export function getSelectableGroups(groups) {
  return (groups || []).reduce((acc, group) => {
    acc.push({
      value: group.id,
      label: group.name,
      disabled: !group.isActive,
      isActive: group.isActive,
      isPortalGroup: group.isPortalGroup,
      s3Bucket: group.s3Bucket
    })
    return acc
  }, [])
}
