import { takeLatest, call, put, fork, select } from 'redux-saga/effects'
import axios from 'axios'
import cuid from 'cuid'
import moment from 'moment'

import config from '../config'
import createDownloadLink from './../utils/download-link'

import {
  FETCH_ROPS_BRANDS,
  FETCH_ROPS_CATEGORIES,
  FETCH_ROPS_MERCHANTS,
  FETCH_DELIVERABLES_BY_CATEGORY,
  CREATE_BRAND,
  createBrandSuccess,
  fetchBrandsSuccess,
  fetchCategoriesSuccess,
  fetchMerchantsSuccess,
  fetchDeliverablesByCategorySuccess,
  taxonomyError,
  DELETE_BRAND,
  deleteBrandSuccess,
  EDIT_BRAND,
  EDIT_CATEGORY,
  EDIT_BRAND_SUCCESS,
  createCategorySuccess,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY_SUCCESS,
  deleteCategorySuccess,
  fetchBrandSuggestionsSuccess,
  FETCH_BRAND_SUGGESTIONS,
  FETCH_CATEGORY_SUGGESTIONS,
  fetchCategorySuggestionsSuccess,
  DOWNLOAD_BRANDS,
  DOWNLOAD_CATEGORIES,
  DOWNLOAD_MERCHANTS
} from '../actions/taxonomy-actions'

import { ALERT_CREATE, createAlert } from '../actions/app-actions'

const DOWNLOAD_DATETIME = 'MM-DD-YYYY:hh:mm:ss'

/*
 * BRANDS
 */

function* fetchBrands({ brandsPayload }) {
  try {
    const brandsState = yield select(state => state.taxonomy.brands)

    const {
      page = brandsState.page,
      sortBy = brandsState.sortBy,
      pageSize = brandsState.pageSize,
      sortOrder = brandsState.sortOrder,
      filters = brandsState.filters
    } = brandsPayload

    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/rops/taxonomy/brands`, {
        params: {
          page,
          sortBy,
          pageSize,
          sortOrder,
          filters
        }
      })
    )
    yield put(fetchBrandsSuccess(data))
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: 'Brand fetch failed'
        }
      ]
    })
  }
}

function* createBrand({ brandPayload }) {
  try {
    const { data } = yield call(() => axios.post(`${config.apiBaseUrl}/rops/taxonomy/brand`, { brandPayload }))

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: `${data.count} brand(s) added to taxonomy`,
          headline: 'Brand Created'
        }
      ]
    })

    const brandsState = yield select(state => state.taxonomy.brands)
    const { page, sortBy, sortOrder, pageSize } = brandsState

    yield put(createBrandSuccess())
    yield put({
      type: FETCH_ROPS_BRANDS,
      brandsPayload: {
        page,
        pageSize,
        sortBy,
        sortOrder
      }
    })
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.response.data.message || error.message,
          headline: 'Create brand failed'
        }
      ]
    })
  }
}

function* editBrand(action = {}) {
  try {
    const { rowId, name, isActive } = action.brandPayload

    yield call(() =>
      axios.post(`${config.apiBaseUrl}/rops/taxonomy/brand/${rowId}`, { brandPayload: { name, isActive } })
    )

    yield put({
      type: EDIT_BRAND_SUCCESS
    })

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: `Brand '${rowId}' has been successfully updated`,
          headline: 'Brand Updated'
        }
      ]
    })

    const brandsState = yield select(state => state.taxonomy.brands)
    const { page, sortBy, sortOrder, pageSize } = brandsState

    yield put({
      type: FETCH_ROPS_BRANDS,
      brandsPayload: {
        page,
        pageSize,
        sortBy,
        sortOrder
      }
    })
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.response.data.message || error.message,
          headline: 'Brand edit failed'
        }
      ]
    })
  }
}

function* deleteBrand({ brandId }) {
  try {
    const { data } = yield call(() => axios.delete(`${config.apiBaseUrl}/rops/taxonomy/brand/${brandId}`))

    yield put(deleteBrandSuccess())

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: `${
            data.length > 1 ? data.length + ' Brands' : "Brand '" + data[0] + "'"
          } has been successfully removed from taxonomy.`,
          headline: 'Brand Removed'
        }
      ]
    })

    const brandsState = yield select(state => state.taxonomy.brands)
    const { page, sortBy, sortOrder, pageSize } = brandsState

    yield put({
      type: FETCH_ROPS_BRANDS,
      brandsPayload: {
        page,
        pageSize,
        sortBy,
        sortOrder
      }
    })
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: 'Brand delete failed'
        }
      ]
    })
  }
}

function* fetchBrandSuggestions({ searchText }) {
  try {
    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/rops/taxonomy/brands/suggestions`, {
        params: { name: searchText }
      })
    )

    yield put(fetchBrandSuggestionsSuccess(data.suggestions))
  } catch (error) {
    window.captureException(error)

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: 'Brand suggestions fetch failed'
        }
      ]
    })
  }
}

/******************************
 ******* CATEGORIES **********
 ******************************/
function* fetchCategories({ categoriesPayload }) {
  try {
    const categoriesState = yield select(state => state.taxonomy.categories)

    const {
      page = categoriesState.page,
      sortBy = categoriesState.sortBy,
      pageSize = categoriesState.pageSize,
      sortOrder = categoriesState.sortOrder,
      filters = categoriesState.filters
    } = categoriesPayload

    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/rops/taxonomy/categories`, {
        params: {
          page,
          sortBy,
          pageSize,
          sortOrder,
          filters
        }
      })
    )

    yield put(fetchCategoriesSuccess(data))
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: 'Category fetch failed'
        }
      ]
    })
  }
}

function* createCategory({ categoryPayload }) {
  try {
    const { data } = yield call(() => axios.post(`${config.apiBaseUrl}/rops/taxonomy/category`, { categoryPayload }))
    yield put(createCategorySuccess())
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: `${data.count} category added to taxonomy`,
          headline: 'Category Created'
        }
      ]
    })

    const categoriesState = yield select(state => state.taxonomy.categories)
    const { page, sortBy, sortOrder, pageSize } = categoriesState

    yield put({
      type: FETCH_ROPS_CATEGORIES,
      categoriesPayload: {
        page,
        pageSize,
        sortBy,
        sortOrder
      }
    })
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.response.data.message || error.message,
          headline: 'Create category failed'
        }
      ]
    })
  }
}

function* editCategory(action = {}) {
  try {
    const { rowId, name, isCPG, isActive } = action.categoryPayload

    yield call(() =>
      axios.post(`${config.apiBaseUrl}/rops/taxonomy/category/${rowId}`, { categoryPayload: { name, isCPG, isActive } })
    )

    yield put({
      type: EDIT_CATEGORY_SUCCESS
    })
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: `Category '${rowId}' has been successfully updated`,
          headline: 'Category Updated'
        }
      ]
    })

    const categoriesState = yield select(state => state.taxonomy.categories)
    const { page, sortBy, sortOrder, pageSize } = categoriesState

    yield put({
      type: FETCH_ROPS_CATEGORIES,
      categoriesPayload: {
        page,
        pageSize,
        sortBy,
        sortOrder
      }
    })
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.response.data.message || error.message,
          headline: 'Category edit failed'
        }
      ]
    })
  }
}

function* deleteCategory({ categoryId }) {
  try {
    const { data } = yield call(() => axios.delete(`${config.apiBaseUrl}/rops/taxonomy/category/${categoryId}`))

    yield put(deleteCategorySuccess())

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: `${
            data.length > 1 ? data.length + ' Categories' : "Category '" + data[0] + "'"
          } has been successfully removed from taxonomy.`,
          headline: 'Category Removed'
        }
      ]
    })

    const categoriesState = yield select(state => state.taxonomy.categories)
    const { page, sortBy, sortOrder, pageSize } = categoriesState

    yield put({
      type: FETCH_ROPS_CATEGORIES,
      categoriesPayload: {
        page,
        pageSize,
        sortBy,
        sortOrder
      }
    })
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: 'Category delete failed'
        }
      ]
    })
  }
}

function* fetchCategorySuggestions({ searchText }) {
  try {
    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/rops/taxonomy/categories/suggestions`, {
        params: { name: searchText, attributes: ['is_leaf'] }
      })
    )

    yield put(fetchCategorySuggestionsSuccess(data.suggestions))
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: 'Category suggestions fetch failed'
        }
      ]
    })
  }
}

function* fetchDeliverablesByCategory({ deliverablesPayload }) {
  try {
    const { categoryId, includeChildren } = deliverablesPayload
    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/deliverables/by/category/${categoryId}`, {
        params: { includeChildren }
      })
    )

    yield put(fetchDeliverablesByCategorySuccess(data))
  } catch (error) {
    window.captureException(error)

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: 'Deliverables by categoryId fetch failed'
        }
      ]
    })
  }
}

/******************************
 ******* /CATEGORIES **********
 ******************************/

/******************************
 ******** MERCHANTS **********
 ******************************/
function* fetchMerchants({ merchantsPayload }) {
  try {
    const merchantsState = yield select(state => state.taxonomy.merchants)

    const {
      page = merchantsState.page,
      sortBy = merchantsState.sortBy,
      pageSize = merchantsState.pageSize,
      sortOrder = merchantsState.sortOrder,
      filters = merchantsState.filters
    } = merchantsPayload

    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/rops/taxonomy/merchants`, {
        params: {
          page,
          sortBy,
          pageSize,
          sortOrder,
          filters
        }
      })
    )

    yield put(fetchMerchantsSuccess(data))
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: 'Merchants fetch failed'
        }
      ]
    })
  }
}

/******************************
 ******** /MERCHANTS **********
 ******************************/

function* downloadBrands() {
  try {
    yield put(createAlert('success', '', 'Preparing CSV for download'))

    const { sortBy, sortOrder, filters } = yield select(state => state.taxonomy.brands)

    const response = yield call(() =>
      axios({
        url: 'api/rops/taxonomy/brands/download',
        method: 'get',
        responseType: 'blob',
        params: {
          sortBy,
          sortOrder,
          filters
        }
      })
    )

    createDownloadLink(response.data, `brands-${moment().format(DOWNLOAD_DATETIME)}.csv`)
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put(createAlert('danger', error.message, 'Brands CSV download failed'))
  }
}

function* downloadCategories() {
  try {
    yield put(createAlert('success', '', 'Preparing CSV for download'))

    const { sortBy, sortOrder, filters } = yield select(state => state.taxonomy.categories)

    const response = yield call(() =>
      axios({
        url: 'api/rops/taxonomy/categories/download',
        method: 'get',
        responseType: 'blob',
        params: {
          sortBy,
          sortOrder,
          filters
        }
      })
    )

    createDownloadLink(response.data, `categories-${moment().format(DOWNLOAD_DATETIME)}.csv`)
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put(createAlert('danger', error.message, 'Categories CSV download failed'))
  }
}

function* downloadMerchants() {
  try {
    yield put(createAlert('success', '', 'Preparing CSV for download'))

    const { sortBy, sortOrder, filters } = yield select(state => state.taxonomy.merchants)

    const response = yield call(() =>
      axios({
        url: 'api/rops/taxonomy/merchants/download',
        method: 'get',
        responseType: 'blob',
        params: {
          sortBy,
          sortOrder,
          filters
        }
      })
    )

    createDownloadLink(response.data, `merchants-${moment().format(DOWNLOAD_DATETIME)}.csv`)
  } catch (error) {
    window.captureException(error)
    yield put(taxonomyError())

    yield put(createAlert('danger', error.message, 'Merchants CSV download failed'))
  }
}

/*
 * FLOWS
 */

function* fetchTaxonomyFlow() {
  yield takeLatest(FETCH_ROPS_BRANDS, fetchBrands)
  yield takeLatest(FETCH_ROPS_CATEGORIES, fetchCategories)
  yield takeLatest(FETCH_ROPS_MERCHANTS, fetchMerchants)
  yield takeLatest(FETCH_DELIVERABLES_BY_CATEGORY, fetchDeliverablesByCategory)
}

function* fetchSuggestionsFlow() {
  yield takeLatest(FETCH_BRAND_SUGGESTIONS, fetchBrandSuggestions)
  yield takeLatest(FETCH_CATEGORY_SUGGESTIONS, fetchCategorySuggestions)
}

function* createTaxonomyFlow() {
  yield takeLatest(CREATE_BRAND, createBrand)
  yield takeLatest(CREATE_CATEGORY, createCategory)
}

function* editTaxonomyFlow() {
  yield takeLatest(EDIT_BRAND, editBrand)
  yield takeLatest(EDIT_CATEGORY, editCategory)
}

function* deleteTaxonomyFlow() {
  yield takeLatest(DELETE_BRAND, deleteBrand)
  yield takeLatest(DELETE_CATEGORY, deleteCategory)
}

function* downloadTaxonomyFlow() {
  yield takeLatest(DOWNLOAD_BRANDS, downloadBrands)
  yield takeLatest(DOWNLOAD_CATEGORIES, downloadCategories)
  yield takeLatest(DOWNLOAD_MERCHANTS, downloadMerchants)
}

export default [
  fork(fetchTaxonomyFlow),
  fork(createTaxonomyFlow),
  fork(editTaxonomyFlow),
  fork(deleteTaxonomyFlow),
  fork(fetchSuggestionsFlow),
  fork(downloadTaxonomyFlow)
]
