import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'

// components
import Box from '../Box'
import Input from '../Input'
import PersonIcon from '../Icon/Person'
import Loader from '../../components/Loader'

// actions
import { sendResetPasswordEmail } from '../../actions/user-actions'

import { appName } from '../../constants/constants'

class ForgotPasswordPage extends Component {
  state = {
    email: '',
    password: '',
    keepMeLoggedIn: true,
    loginMessage: null,
    view: 'login'
  }

  handleSubmitForgotPassword = e => {
    e.preventDefault()
    this.props.onForgotPassword({ email: this.state.email })
  }

  updateInput = (value = '') => {
    this.setState({ email: value.toLowerCase().trim() })
  }

  redirectToLogin = () => {
    this.props.history.push('/login')
  }

  render() {
    const LoginLink = (
      <span onClick={this.redirectToLogin} className="login-link btn_link">
        Login?
      </span>
    )
    let message = null
    if (this.props.sessionMessageType === 'forgotPassword') {
      message = (
        <div>
          <div className="errorMessage">{this.props.sessionMessage}</div>
          <br />
        </div>
      )
    }
    return (
      <DocumentTitle title={this.props.documentTitle ? `${appName} | ${this.props.documentTitle}` : appName}>
        <div>
          <form onSubmit={this.handleSubmitForgotPassword}>
            <Box className="authView alignItemsCenter justifyContentCenter flex">
              <Box className="authBox alignItemsCenter directionColumn flex">
                <>
                  <h1 className="title">Forgot password</h1>
                  {message}
                  <Input
                    className="email inputWithBackgroundColor"
                    iconName={<PersonIcon width="24" height="24" />}
                    type="text"
                    value={this.state.email}
                    textDidChange={this.updateInput}
                    placeholder="Email"
                    tabIndex="1"
                  />
                  {!this.props.loading && <input className="submit" value="Submit" type="submit" />}

                  {this.props.loading && (
                    <div className="submit loading">
                      <Loader height={45} />
                    </div>
                  )}
                  {LoginLink}
                  <div className="footer" />
                </>
              </Box>
            </Box>
          </form>
        </div>
      </DocumentTitle>
    )
  }
}

ForgotPasswordPage.propTypes = {
  onForgotPassword: PropTypes.func,
  sessionMessageType: PropTypes.string,
  sessionMessage: PropTypes.string,
  loading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  documentTitle: PropTypes.string
}

ForgotPasswordPage.defaultProps = {
  documentTitle: 'Forgot Password'
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onForgotPassword: payload =>
      dispatch(
        sendResetPasswordEmail({
          ...payload,
          callback: () => ownProps.history.push('/login')
        })
      )
  }
}

function mapStateToProps({ session }) {
  if (!session) {
    return {}
  }

  return {
    loading: session.loading,
    sessionMessageType: session.sessionMessageType,
    sessionMessage: session.sessionMessage
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage)
