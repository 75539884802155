import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'

class EmptyQueryTreeButton extends Component {
  static propTypes = {
    label: PropTypes.string, // "x"
    className: PropTypes.string, // css classNames to be applied
    handleOnClick: PropTypes.func, // callback function to invoke removing
    level: PropTypes.number // The level of the current group
  }

  render() {
    const tooltip = name => <Tooltip id="remove-button-tooltip">{name}</Tooltip>
    return (
      <Button className={`${this.props.className}`} bsSize="xsmall" bsStyle="link" onClick={this.props.handleOnClick}>
        <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Empty Query Tree')}>
          <Glyphicon glyph={'trash'} />
        </OverlayTrigger>
      </Button>
    )
  }
}
EmptyQueryTreeButton.propTypes = {
  label: PropTypes.string, // "x"
  className: PropTypes.string, // css classNames to be applied
  handleOnClick: PropTypes.func, // callback function to invoke removing
  level: PropTypes.number // The level of the current group
}

export default EmptyQueryTreeButton
