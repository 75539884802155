import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './toggle.scss'

const Toggle = props => {
  const { text, inactiveText, isActive: isActiveExternal, onChange, disabled, isStateful, className } = props
  const [isActiveLocal, setIsActiveLocal] = useState(isActiveExternal)

  const isActive = isStateful ? isActiveLocal : isActiveExternal

  const handleOnClick = () => {
    if (disabled) return null
    const newIsAcative = !isActive
    if (isStateful) {
      setIsActiveLocal(newIsAcative)
    }
    onChange(newIsAcative)
  }

  return (
    <div
      onClick={handleOnClick}
      className={cx('Toggle', className, { active: isActive }, { inactive: !isActive })}
      disabled={disabled}
    >
      <span className="Toggle-inner-button" />
      {isActive ? text : inactiveText}
    </div>
  )
}

Toggle.defaultProps = {
  isActive: true,
  onChange: () => {},
  text: 'Active',
  inactiveText: 'Inactive',
  disabled: false,
  isStateful: false
}

Toggle.propTypes = {
  isActive: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  inactiveText: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isStateful: PropTypes.bool // NOTE: we use this option only in GroupEdit page. It's better to remove it.
}

export default Toggle
