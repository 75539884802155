import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'
import { map } from 'lodash'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import Calendar from './Calendar'
import Box from '../../../Box'
import ArrowUpIcon from '../../../Icon/ArrowUp'
import ArrowDownIcon from '../../../Icon/ArrowDown'
import Popup from '../../Popup'

import theme from '../../../../utils/date-selector-theme'
import { rangeToString } from '../../../../utils/date-range-util'
import config from '../../../../config'
import './index.scss'

const getLabel = (startDate, endDate) => rangeToString([startDate, endDate])
/* TODO @todo Move to a date utility ? */
const formatDate = date => moment(date).format('MM/DD/YYYY')
const defaultFormatDate = date => moment(date).format('YYYY-MM-DD')

const isAfter = (dateOne, dateTwo) => moment(dateOne).isAfter(dateTwo, 'day')
const isBefore = (dateOne, dateTwo) => moment(dateOne).isBefore(dateTwo, 'day')

export default class DateRangeFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      dateRange: {
        from: formatDate(props.startDate),
        to: formatDate(props.endDate)
      },
      error: {
        from: '',
        to: ''
      }
    }
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick)
  }

  toggleOpenPanel = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleDateSelect = (dateType, date) => {
    let { error } = this.state
    const { dateRange } = this.state
    dateRange[dateType] = formatDate(date)
    if (dateType === 'to' && isAfter(dateRange.from, dateRange[dateType])) {
      error.to = 'End date is before start date'
    } else if (dateType === 'from' && isBefore(dateRange.to, dateRange[dateType])) {
      error.from = 'Start date is after end date'
    } else {
      error = {
        from: '',
        to: ''
      }
    }
    this.setState({
      error,
      dateRange
    })
  }

  setDateInputValue = () => {
    const { error, dateRange } = this.state
    const { rangeStart, rangeEnd, defaultEndDate, defaultStartDate } = this.props

    const minDate = formatDate(rangeStart)
    const maxDate = formatDate(rangeEnd)

    const fromDate = dateRange.from.length !== 0 ? formatDate(dateRange.from) : formatDate(defaultStartDate)

    const toDate = dateRange.to.length !== 0 ? formatDate(dateRange.to) : formatDate(defaultEndDate)

    let hasError = false
    if (isBefore(fromDate, minDate)) {
      error.from = `Start date is before the min date ${minDate}`
      hasError = true
    } else if (isAfter(fromDate, maxDate)) {
      error.from = `Start date is after the max date ${maxDate}`
      hasError = true
    } else if (isAfter(toDate, maxDate)) {
      error.to = `End date is after the max date ${maxDate}`
      hasError = true
    } else if (isBefore(toDate, minDate)) {
      error.to = `End date input value is before the min date ${minDate}`
      hasError = true
    } else if (fromDate === 'Invalid date') {
      hasError = true
      error.from = `Please enter a valid start date`
    } else if (toDate === 'Invalid date') {
      hasError = true
      error.to = `Please enter a valid end date`
    }

    if (hasError) {
      this.setState({
        error: { ...error },
        isOpen: true
      })
    } else {
      dateRange.from = fromDate
      dateRange.to = toDate

      this.props.handleDateSelect(dateRange, this.props.name)
      this.handleDateSelect('from', fromDate)
      this.handleDateSelect('to', toDate)

      this.setState({ isOpen: false })
    }
    return hasError
  }

  textDidChange = (dateType, value) => {
    const { dateRange } = this.state
    value = value.trim().slice(0, 10)
    dateRange[dateType] = value
    this.setState({
      dateRange
    })
  }

  submitDateSearch = () => {
    const { error, dateRange } = this.state
    let startError = false
    let endError = false

    if (dateRange.from.length !== 0 && dateRange.from.length < 10) {
      startError = true
      error.from = `Please provide valid start date`
    } else if (dateRange.from.length === 10) {
      error.from = ``
    }

    if (dateRange.to.length !== 0 && dateRange.to.length < 10) {
      endError = true
      error.to = `Please provide valid end date`
    } else if (dateRange.to.length === 0) {
      error.to = ``
    }

    if (endError || startError) {
      this.setState({ error })
    } else {
      this.setDateInputValue()
    }
  }

  closeDateSelector = () => {
    const { startDate, endDate } = this.props

    this.setState({
      error: {
        from: '',
        to: ''
      },
      dateRange: {
        from: moment(startDate),
        to: moment(endDate)
      },
      isOpen: false
    })
  }

  handleClick = event => {
    const excludedTargetIds = [`arrowUp${this.props.id}`, `arrowDown${this.props.id}`]
    const { startDate, endDate } = this.props
    const wrapperDom = this.dateRangeFilterWrapper

    if (excludedTargetIds.includes(event.target.id)) return

    if (!wrapperDom.contains(event.target)) {
      const clearButtonIds = [`close-icon-path-${this.props.id}`, `close-icon-svg-${this.props.id}`]

      this.setState({
        isOpen: clearButtonIds.includes(event.target.id),
        error: {
          from: '',
          to: ''
        },
        dateRange: {
          from: formatDate(startDate),
          to: formatDate(endDate)
        }
      })
    }
  }

  handleQuickOptionChange = dateObj => {
    this.setState({
      dateRange: {
        from: dateObj.startDate,
        to: dateObj.endDate
      },
      error: {
        from: '',
        to: ''
      }
    })
  }

  isPresetOptionActive = currentPreset => {
    const { dateRange } = this.state
    return (
      currentPreset.key !== 'clear' &&
      dateRange.from === currentPreset.startDate &&
      dateRange.to === currentPreset.endDate
    )
  }

  render() {
    const {
      className,
      rangeStart,
      rangeEnd,
      startDate,
      endDate,
      name,
      label,
      showQuickSelectOptions,
      quickSelectOptions
    } = this.props
    const { dateRange } = this.state

    const selectedRange = {
      startDate: dateRange.from,
      endDate: dateRange.to
    }

    const tooltip = name => (
      <Tooltip id={name}>
        <div className="tooltip-box">
          {name}:<br />
          {getLabel(startDate, endDate)}
        </div>
      </Tooltip>
    )

    const quickSelections = map(quickSelectOptions, (value, key) => (
      <span
        key={key}
        onClick={() => this.handleQuickOptionChange(value)}
        className={cx('reports-date-quick-option', {
          'quick-select-active': this.isPresetOptionActive(value)
        })}
      >
        {value.label}
      </span>
    ))

    return (
      <div
        className={cx('dateRange-filter', className)}
        ref={el => {
          this.dateRangeFilterWrapper = el
        }}
      >
        <OverlayTrigger rootClose placement="top" overlay={tooltip(label)}>
          <div
            className={'squareBoxWithArrow'}
            style={this.state.isOpen ? { borderColor: config.colors.primaryColor } : {}}
            onClick={() => {
              this.toggleOpenPanel()
            }}
          >
            <span className="filterDetailsWrap">
              <div className={cx('filterDescriptorTop', '')}>{`${label}`}</div>

              <div className={cx('filterSelector')}>{getLabel(startDate, endDate)}</div>
            </span>

            <span
              onClick={() => {
                this.toggleOpenPanel()
              }}
              className="spanRightArrowIconFilter"
            >
              {this.state.isOpen ? (
                <ArrowUpIcon fill="#4C4C4C" id={`arrowUp${this.props.id}`} width={32} height={32} />
              ) : (
                <ArrowDownIcon className="defaultArrowDown" id={`arrowDown${this.props.id}`} width={32} height={32} />
              )}
            </span>
          </div>
        </OverlayTrigger>
        <Popup isOpen={this.state.isOpen} className="filter-popup Z2-shadow">
          {showQuickSelectOptions && <div className="report-date-quick-options">{quickSelections}</div>}
          <div className={'check'}>
            <Box>
              <div style={{ display: 'block' }}>
                <div className="date-search-container">
                  <input
                    type="date"
                    className={cx('input', 'flex1', 'date-search')}
                    value={defaultFormatDate(this.state.dateRange.from)}
                    onChange={dateChanged => {
                      this.textDidChange('from', dateChanged.target.value)
                    }}
                  />

                  <span className={'search-divider-text'}> to </span>

                  <input
                    type="date"
                    className={cx('input', 'flex1', 'date-search')}
                    value={defaultFormatDate(this.state.dateRange.to)}
                    onChange={dateChanged => {
                      this.textDidChange('to', dateChanged.target.value)
                    }}
                  />
                </div>

                <div className="date-search-container">
                  {this.state.error.from && <span className={'search-errors'}>{this.state.error.from}</span>}
                  {this.state.error.to && <span className={'search-errors'}>{this.state.error.to}</span>}
                </div>

                {
                  <div className="report-calendar-container">
                    <Calendar
                      theme={theme}
                      key={`${name}_from`}
                      date={moment(this.state.dateRange.from)}
                      onChange={date => this.handleDateSelect('from', date)}
                      minDate={rangeStart}
                      maxDate={rangeEnd}
                      selectedRange={selectedRange}
                    />

                    <Calendar
                      theme={theme}
                      key={`${name}_end`}
                      date={moment(this.state.dateRange.to)}
                      onChange={date => this.handleDateSelect('to', date)}
                      minDate={rangeStart}
                      maxDate={rangeEnd}
                      selectedRange={selectedRange}
                    />
                  </div>
                }

                <div className="flex justifyContentAround action">
                  <span className="resetButton" onClick={this.closeDateSelector}>
                    Cancel
                  </span>

                  <span className="resetButton" onClick={this.submitDateSearch}>
                    Ok
                  </span>
                </div>
              </div>
            </Box>
          </div>
        </Popup>
      </div>
    )
  }
}

DateRangeFilter.defaultProps = {
  dateRange: {
    from: '',
    to: ''
  }
}

DateRangeFilter.propTypes = {
  className: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  rangeStart: PropTypes.string,
  rangeEnd: PropTypes.string,
  handleDateSelect: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.number,
  showQuickSelectOptions: PropTypes.bool,
  quickSelectOptions: PropTypes.array,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string
}
