import React from 'react'
import PropTypes from 'prop-types'

import Beacon from '../Beacon'
import { modalOffsets, arrowOffsets, beaconOffsets, periscopeBeacons } from '../beacon-locations'

const PeriscopeBeacons = props => {
  if (!props.periscopeEnabled) return <div />

  const { beaconLocations } = props

  return (
    <div>
      {periscopeBeacons.map(beacon => (
        <Beacon
          isEnabled
          id={beacon.id}
          key={beacon.id}
          showtoolTip={false}
          title={beacon.title}
          position={'absolute'}
          content={beacon.content}
          modalPosition={'absolute'}
          width={beacon.style.width}
          height={beacon.style.height}
          dataStyle={beacon.style.dataStyle}
          arrowStyle={beacon.style.arrowStyle}
          buttonStyle={beacon.style.buttonStyle}
          arrowLocation={beacon.style.arrowLocation}
          zIndexProperty={beacon.style.zIndexProperty}
          arrowTop={arrowOffsets(beacon.arrowPosition, beaconLocations[beacon.id]).top}
          arrowLeft={arrowOffsets(beacon.arrowPosition, beaconLocations[beacon.id]).left}
          beaconTop={beaconOffsets(beacon.beaconPosition, beaconLocations[beacon.id]).top}
          beaconLeft={beaconOffsets(beacon.beaconPosition, beaconLocations[beacon.id]).left}
          modalTop={modalOffsets(beacon.modalPosition, beaconLocations[beacon.id], beacon.style.width).top}
          modalLeft={modalOffsets(beacon.modalPosition, beaconLocations[beacon.id], beacon.style.width).left}
        />
      ))}
    </div>
  )
}

PeriscopeBeacons.propTypes = {
  periscopeEnabled: PropTypes.bool,
  beaconLocations: PropTypes.object
}

export default PeriscopeBeacons
