import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Calendar } from 'react-date-range'
import { Popover, OverlayTrigger, Button, Glyphicon, Tooltip } from 'react-bootstrap'
import moment from 'moment'
import cx from 'classnames'
import config from '../../config'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './index.scss'

const { primaryColor } = config.colors

export default class SingleDateInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    selectLabel: PropTypes.string,
    popupTitle: PropTypes.string,
    format: PropTypes.string,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    onDateChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    popupClassName: PropTypes.string,
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.bool,
    tooltip: PropTypes.string
  }

  static defaultProps = {
    selectLabel: 'Select',
    popupTitle: 'Select date',
    onDateChange: () => {},
    format: 'MM/DD/YYYY'
  }

  handleChange = value => {
    const { onDateChange } = this.props
    onDateChange(moment(value))
    if (this.overlay && this.overlay.handleHide) {
      this.overlay.handleHide()
    }
  }

  getLabel = value => {
    return value ? moment(value).format(this.props.format) : this.props.selectLabel
  }

  render() {
    const {
      selectedDate,
      maxDate,
      minDate,
      disabled,
      popupTitle,
      className,
      popupClassName,
      name,
      readOnly,
      autoFocus,
      tooltip
    } = this.props

    const date = selectedDate ? moment(selectedDate).toDate() : null

    let Btn = (
      <Button autoFocus={autoFocus} disabled={disabled} className={cx('single-date-input-btn', className)}>
        {name && <div className="field-name">{name}</div>}
        <div className="field-label">{this.getLabel(selectedDate)}</div>
      </Button>
    )
    if (tooltip) {
      Btn = (
        <OverlayTrigger placement={'top'} overlay={<Tooltip id="info">{tooltip}</Tooltip>}>
          {Btn}
        </OverlayTrigger>
      )
    }
    if (disabled || readOnly) {
      return Btn
    }
    const datePopover = (
      <Popover
        id={'dateSelectorPopup'}
        className={cx('single-date-input-popup', popupClassName)}
        title={
          <div>
            {popupTitle}{' '}
            <Glyphicon
              className="pull-right btn-close"
              glyph="remove"
              onClick={() => {
                const handleHide = this.overlay ? this.overlay.handleHide : undefined
                if (handleHide) handleHide()
              }}
            />
          </div>
        }
        ref={el => {
          this.popover = el
        }}
      >
        <Calendar
          date={date}
          minDate={minDate ? moment(minDate).toDate() : undefined}
          maxDate={maxDate ? moment(maxDate).toDate() : undefined}
          onChange={this.handleChange}
          autoFocus={autoFocus}
          color={primaryColor}
        />
      </Popover>
    )
    return (
      <OverlayTrigger
        placement={'bottom'}
        trigger={'click'}
        rootClose
        overlay={datePopover}
        ref={el => {
          this.overlay = el
        }}
      >
        {Btn}
      </OverlayTrigger>
    )
  }
}

/**
 * This is version for using inside redux-form Field component
 */
export const SingleDateInputField = props => {
  const {
    input: { value, onChange },
    ...passThroughProps
  } = props

  return <SingleDateInput selectedDate={value} onDateChange={onChange} {...passThroughProps} />
}

SingleDateInputField.propTypes = {
  input: PropTypes.object
}
