import { createSlice } from '@reduxjs/toolkit'

const cmeDictionary = createSlice({
  name: 'cmeDictionary',
  initialState: {
    addDictionaryEntryModal: {
      show: false
    },
    editDictionaryEntryModal: {
      show: false,
      selectedRow: {}
    }
  },
  reducers: {
    openAddDictionaryEntryModal(state, action) {
      state.addDictionaryEntryModal.show = true
    },
    closeAddDictionaryEntryModal(state, action) {
      state.addDictionaryEntryModal.show = false
    },
    openEditDictionaryEntryModal(state, action) {
      state.editDictionaryEntryModal.show = true
      state.editDictionaryEntryModal.selectedRow = action.payload.selectedRow
    },
    closeEditDictionaryEntryModal(state, action) {
      state.editDictionaryEntryModal.show = false
      state.editDictionaryEntryModal.selectedRow = {}
    }
  }
})

export const {
  openAddDictionaryEntryModal,
  closeAddDictionaryEntryModal,
  openEditDictionaryEntryModal,
  closeEditDictionaryEntryModal
} = cmeDictionary.actions

export default cmeDictionary.reducer
