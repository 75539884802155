import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import BeaconModal from '../BeaconModal'
import { hoverBeacon, beaconViewed } from '../../../actions/walkthrough-actions'
import './index.scss'

class Beacon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      hideBeacon: false,
      showtoolTip: this.props.showtoolTip
    }
  }

  componentDidMount() {
    if (this.props.id === 'left-nav') {
      this.setState({ isOpen: true })
    }
  }

  componentDidUpdate(prevProps) {
    const { walkthrough, id } = prevProps
    if (
      walkthrough.periscopeEnabled !== this.props.walkthrough.periscopeEnabled &&
      (this.getBeaconLocation(id) === 'periscope' || this.getBeaconLocation(id) === 'left-nav')
    ) {
      if (this.props.walkthrough.periscopeEnabled) {
        this.setState({ hideBeacon: false })
      } else {
        this.setState({ hideBeacon: true })
      }
    }
  }

  getBeaconLocation() {
    switch (this.props.id) {
      case 'periscope-filters':
      case 'periscope-actions':
      default:
        return 'periscope'
    }
  }

  openModal = () => {
    const { id, hoverBeacon } = this.props
    hoverBeacon(id)

    this.setState({ isOpen: true })
  }

  closeModal = () => {
    this.setState({ isOpen: false, hideBeacon: true })
  }

  onButtonClick = () => {
    this.setState({ isOpen: false, hideBeacon: true })
    this.props.beaconViewed(this.getBeaconLocation(), this.props.id)
  }

  render() {
    if (this.state.hideBeacon || !this.props.walkthrough.beacons[this.getBeaconLocation()][this.props.id]) return null

    return (
      <div onMouseEnter={this.openModal}>
        <div
          className="outerBeacon"
          style={{
            top: this.props.beaconTop,
            left: this.props.beaconLeft,
            zIndex: this.props.zIndexProperty,
            position: this.props.position
          }}
        >
          <div className="middleBeacon">
            <div className="innerBeacon" />
          </div>
        </div>

        <BeaconModal
          {...this.props}
          header={this.props.title}
          isOpen={this.state.isOpen}
          onButtonClick={this.onButtonClick}
          showtoolTip={this.state.showtoolTip}
          activeBeacon={this.props.walkthrough.activeBeacon}
        />
      </div>
    )
  }
}

Beacon.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  beaconTop: PropTypes.number,
  beaconLeft: PropTypes.number,
  modalLeft: PropTypes.number,
  modalTop: PropTypes.number,
  arrowStyle: PropTypes.string,
  buttonStyle: PropTypes.string,
  zIndexProperty: PropTypes.number,
  showtoolTip: PropTypes.bool,
  hoverBeacon: PropTypes.func,
  walkthrough: PropTypes.object,
  activeBeacon: PropTypes.string,
  beaconViewed: PropTypes.func,
  tabHover: PropTypes.func,
  titleHeight: PropTypes.number,
  titleWidth: PropTypes.number,
  position: PropTypes.string,
  modalPosition: PropTypes.string,
  arrowLocation: PropTypes.string,
  arrowLeft: PropTypes.number,
  arrowTop: PropTypes.number
}

Beacon.defaultProps = {
  position: 'absolute'
}

// connect

const mapStateToProps = ({ walkthrough }) => ({
  walkthrough
})

const mapDispatchToProps = dispatch => ({
  beaconViewed: (beaconGroup, beaconId) => dispatch(beaconViewed(beaconGroup, beaconId)),
  hoverBeacon: activeBeacon => dispatch(hoverBeacon(activeBeacon))
})

export default connect(mapStateToProps, mapDispatchToProps)(Beacon)
