import { createReducer } from '@reduxjs/toolkit'
import moment from 'moment'

import deliveryJobsActions from './deliveryJobsActions'
import { DATA_TABLE } from 'components/DataTable/constants'
import { DELIVERY_DATE_FORMAT } from 'constants/deliveryJobsConstants'

const DeliveryJobsReducers = createReducer(
  {
    loading: false,
    page: 1,
    pageSize: DATA_TABLE.PAGE_SIZE,
    sortBy: 'id',
    sortOrder: 'desc',
    filters: {
      deliveryDate: {
        value: {
          from: moment()
            .startOf('month')
            .format(DELIVERY_DATE_FORMAT),
          to: moment()
            .endOf('month')
            .format(DELIVERY_DATE_FORMAT)
        }
      }
    },
    modal: {},
    data: {
      count: 0,
      rows: []
    },
    etlTask: {
      task: null,
      loading: false
    }
  },
  builder => {
    builder
      .addCase(deliveryJobsActions.fetch, (state, action) => {
        state.loading = true
      })
      .addCase(deliveryJobsActions.successLoad, (state, action) => {
        state.data.rows = action.payload.rows
        state.data.count = action.payload.count
        state.loading = false
      })
      .addCase(deliveryJobsActions.failedLoad, (state, action) => {
        state.loading = false
      })
      .addCase(deliveryJobsActions.fetchByCategoryAndDeliveryDate, (state, action) => {
        state.modal.loading = true
        state.modal.conflictDeliveryJob = null
      })
      .addCase(deliveryJobsActions.successLoadByCategoryAndDeliveryDate, (state, action) => {
        state.modal.loading = false
        const value = action.payload.rows && action.payload.rows[0] ? action.payload.rows[0] : null
        state.modal.conflictDeliveryJob = value
      })
      .addCase(deliveryJobsActions.updateSettings, (state, action) => {
        return {
          ...state,
          ...action.payload
        }
      })
      .addCase(deliveryJobsActions.clearFilters, (state, action) => {
        state.filters = {}
        // also clear sorting
        state.sortBy = 'id'
        state.sortOrder = 'desc'
      })
      .addCase(deliveryJobsActions.setLoadingDeliveryJobsTasks, (state, action) => {
        state.etlTask.loading = action.payload
      })
      .addCase(deliveryJobsActions.successfetchDeliveryJobsTasks, (state, action) => {
        state.etlTask.task = action.payload.task
      })
  }
)

export default DeliveryJobsReducers
