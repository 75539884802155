// @flow
export const SORT_TABLE = 'SORT_TABLE'
export const UPDATE_AGGREGATION = 'UPDATE_AGGREGATION'
export const TIME_AGGREGATION_CHANGED = 'TIME_AGGREGATION_CHANGED'
export const TIME_DIMENSION_CHANGED = 'TIME_DIMENSION_CHANGED'
export const VIEW_CHANGED = 'VIEW_CHANGED'
export const DIMENSION_CHANGED = 'DIMENSION_CHANGED'
export const DESCRIPTION_TAG_FOR_EDIT = 'DESCRIPTION_TAG_FOR_EDIT'
export const DESCRIPTION_TOGGLE_EDIT = 'DESCRIPTION_TOGGLE_EDIT'
export const SEARCH_TOGGLE_LOCK_DATA = 'SEARCH_TOGGLE_LOCK_DATA'
export const BULK_STAGE_DICT_EDITS = 'BULK_STAGE_DICT_EDITS'
export const BULK_UNSTAGE_DICT_EDITS = 'BULK_UNSTAGE_DICT_EDITS'
export const STAGE_DICT_EDIT = 'STAGE_DICT_EDIT'
export const DESCRIPTION_SAVE_EDITS = 'DESCRIPTION_SAVE_EDITS'
export const DESCRIPTION_SAVE_EDITS_STARTED = 'DESCRIPTION_SAVE_EDITS_STARTED'
export const DESCRIPTION_SAVE_EDITS_SUCCEEDED = 'DESCRIPTION_SAVE_EDITS_SUCCEEDED'
export const DESCRIPTION_SAVE_EDITS_FAILED = 'DESCRIPTION_SAVE_EDITS_FAILED'
export const DESCRIPTION_BRAND_SUGGEST = 'DESCRIPTION_BRAND_SUGGEST'
export const DESCRIPTION_CATEGORY_SUGGEST = 'DESCRIPTION_CATEGORY_SUGGEST'
export const DESCRIPTION_BRAND_SUGGEST_SUCCEEDED = 'DESCRIPTION_BRAND_SUGGEST_SUCCEEDED'
export const REMOVE_STAGED_EDIT = 'REMOVE_STAGED_EDIT'
export const DESCRIPTION_CATEGORY_SUGGEST_SUCCEEDED = 'DESCRIPTION_CATEGORY_SUGGEST_SUCCEEDED'
export const DESCRIPTION_BRAND_EDIT_SELECT = 'DESCRIPTION_BRAND_EDIT_SELECT'
export const DESCRIPTION_CATEGORY_EDIT_SELECT = 'DESCRIPTION_CATEGORY_EDIT_SELECT'
export const DESCRIPTION_EDIT_STATUS_UPDATED = 'DESCRIPTION_EDIT_STATUS_UPDATED'
export const DESCRIPTION_EDIT_STATUS_UPDATE_FAILED = 'DESCRIPTION_EDIT_STATUS_UPDATE_FAILED'
export const REFRESH_DESCRIPTION_EDIT_STATUS = 'REFRESH_DESCRIPTION_EDIT_STATUS'
export const START_LOADING_WHEEL = 'START_LOADING_WHEEL'
export const SEARCH_FILTER_CHANGED = 'SEARCH_FILTER_CHANGED'
export const SEARCH_DESCRIPTION_SELECTION_CHANGED = 'SEARCH_DESCRIPTION_SELECTION_CHANGED'
export const SEARCH_REFRESH_DESCRIPTION_DATA = 'SEARCH_REFRESH_DESCRIPTION_DATA'
export const CHANGE_TOOLBAR_OPTION = 'CHANGE_TOOLBAR_OPTION'
export const CHANGE_SEARCH_COLUMNS_VISIBILITY = 'CHANGE_SEARCH_COLUMNS_VISIBILITY'
export const APPLY_TEMP_FILTER = 'APPLY_TEMP_FILTER'
export const REMOVE_TEMP_FILTER = 'REMOVE_TEMP_FILTER'
export const WIDGETS_OPTIONS_CHANGED = 'WIDGETS_OPTIONS_CHANGED'
export const UPDATE_WIDGET_DATA = 'UPDATE_WIDGET_DATA'
export const QC_DESCRIPTION_SAVE_EDITS = 'QC_DESCRIPTION_SAVE_EDITS'
export const QC_DESCRIPTION_SAVE_EDITS_STARTED = 'QC_DESCRIPTION_SAVE_EDITS_STARTED'
export const QC_DESCRIPTION_SAVE_EDITS_FAILED = 'QC_DESCRIPTION_SAVE_EDITS_FAILED'
export const REFRESH_QC_DESCRIPTION_EDIT_STATUS = 'REFRESH_QC_DESCRIPTION_EDIT_STATUS'
export const TOGGLE_WIDGET_ENABLED_STATUS = 'TOGGLE_WIDGET_ENABLED_STATUS'
export const UPDATE_STATS_DATA = 'UPDATE_STATS_DATA'
export const TOGGLE_DESCRIPTION_SEARCH_STATUS = 'TOGGLE_DESCRIPTION_SEARCH_STATUS'
export const TOGGLE_QC_DESCRIPTION_SEARCH_STATUS = 'TOGGLE_QC_DESCRIPTION_SEARCH_STATUS'
export const FETCH_DESCRIPTION_EDIT_SUGGESTIONS = 'FETCH_DESCRIPTION_EDIT_SUGGESTIONS'
export const FETCH_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED = 'FETCH_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED'
export const FETCH_DESCRIPTION_EDIT_SUGGESTIONS_FAILED = 'FETCH_DESCRIPTION_EDIT_SUGGESTIONS_FAILED'
export const FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS = 'FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS'
export const FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED = 'FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED'
export const FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_FAILED = 'FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_FAILED'

export function onSearchFilterChanged(key: String, value: String) {
  return {
    type: SEARCH_FILTER_CHANGED,
    key,
    value
  }
}

export function onToggleDescriptionEdit(): Object {
  return {
    type: DESCRIPTION_TOGGLE_EDIT
  }
}

export function onToggleSearchLockData(): Object {
  return {
    type: SEARCH_TOGGLE_LOCK_DATA
  }
}

export function onTagForEdit(rows: Object[], add: Object): Object {
  return {
    type: DESCRIPTION_TAG_FOR_EDIT,
    data: {
      rows,
      add
    }
  }
}

export function onSaveDictionaryEntries(): Object {
  return {
    type: DESCRIPTION_SAVE_EDITS
  }
}

export function onSaveQCDictionaryEntries(): Object {
  return {
    type: QC_DESCRIPTION_SAVE_EDITS
  }
}

export function onBulkStageDictionaryEdits(): Object {
  return {
    type: BULK_STAGE_DICT_EDITS
  }
}

export function onBulkUnstageDictionaryEdits(): Object {
  return {
    type: BULK_UNSTAGE_DICT_EDITS
  }
}

export function onBrandSuggest(text: String): Object {
  return {
    type: DESCRIPTION_BRAND_SUGGEST,
    data: {
      text,
      type: 'brand_name'
    }
  }
}

export function onCategorySuggest(text: String): Object {
  return {
    type: DESCRIPTION_CATEGORY_SUGGEST,
    data: {
      text,
      type: 'category_full_path'
    }
  }
}

export function onBrandEditSelect(data: Object): Object {
  return {
    type: DESCRIPTION_BRAND_EDIT_SELECT,
    data:
      data && data.length
        ? {
            id: data[0].id,
            value: data[0].value
          }
        : null
  }
}

export function onCategoryEditSelect(data: Object): Object {
  return {
    type: DESCRIPTION_CATEGORY_EDIT_SELECT,
    data:
      data && data.length
        ? {
            id: data[0].id,
            value: data[0].value
          }
        : null
  }
}

export function onStartLoadWheel(aggType: String): Object {
  return {
    type: START_LOADING_WHEEL,
    aggType,
    loading: true
  }
}
export function onDescriptionSelectionChanged(attribute, value) {
  return {
    type: SEARCH_DESCRIPTION_SELECTION_CHANGED,
    attribute,
    value
  }
}
export function onTableSort(aggType: String, sortBy: String, sortOrder: String): Object {
  return {
    type: SORT_TABLE,
    data: {
      aggType,
      sortBy,
      sortOrder,
      updateAggs: [aggType]
    }
  }
}

export function onTimeAggregationChanged(
  aggregation: Object,
  aggType: String,
  selectedFilter: String,
  selectedInterval: String
): Object {
  return {
    type: TIME_AGGREGATION_CHANGED,
    data: {
      aggregation,
      selectedFilter,
      selectedInterval,
      aggType,
      updateAggs: [aggType]
    }
  }
}

export function onTimeDimensionChanged(axis: String, aggregation: Object, aggType: String): Object {
  return {
    type: TIME_DIMENSION_CHANGED,
    data: {
      axis,
      aggregation,
      aggType,
      updateAggs: [aggType]
    }
  }
}

export function onDimensionChanged(axis: String, aggType: String): Object {
  return {
    type: DIMENSION_CHANGED,
    data: {
      axis,
      aggType
    }
  }
}

export function onViewChanged(view: String, aggType: String): Object {
  return {
    type: VIEW_CHANGED,
    data: {
      view,
      aggType
    }
  }
}

export function onUpdateAggregation(aggType: String, aggregationUpdates: Object): Object {
  return {
    type: UPDATE_AGGREGATION,
    data: {
      aggType,
      aggregationUpdates,
      updateAggs: [aggType]
    }
  }
}
export function refreshSearchDescriptionData() {
  return {
    type: SEARCH_REFRESH_DESCRIPTION_DATA
  }
}
export function refreshDesciptionEditStatus() {
  return {
    type: REFRESH_DESCRIPTION_EDIT_STATUS
  }
}

export function refreshQCDescriptionEditStatus() {
  return {
    type: REFRESH_QC_DESCRIPTION_EDIT_STATUS
  }
}

export function stageDictEdit(key: String, attribute: String, id: String, name: String) {
  return {
    type: STAGE_DICT_EDIT,
    key,
    attribute,
    id,
    name
  }
}

export function removeStagedEdit(key: String, attribute: String) {
  return {
    type: REMOVE_STAGED_EDIT,
    key,
    attribute
  }
}

export function onToggleToolbarOption() {
  return {
    type: CHANGE_TOOLBAR_OPTION
  }
}

export function onChangeSearchColumnsVisibility(column) {
  return {
    type: CHANGE_SEARCH_COLUMNS_VISIBILITY,
    column
  }
}

export function onRemoveTempFilter(aggType: String, value: String) {
  return {
    type: REMOVE_TEMP_FILTER,
    aggType,
    value
  }
}

export function onApplyTempFilter(aggType: String, value: String) {
  return {
    type: APPLY_TEMP_FILTER,
    aggType,
    value
  }
}

export function onWidgetsOptionsChanged(aggType: String, attribute: String, value: Number) {
  return {
    type: WIDGETS_OPTIONS_CHANGED,
    aggType,
    attribute,
    value
  }
}

export function updateWidgetData(aggType: String) {
  return {
    type: UPDATE_WIDGET_DATA,
    aggType
  }
}

export function onChangeWidgetEnabledStatus(widgetType) {
  return {
    type: TOGGLE_WIDGET_ENABLED_STATUS,
    widgetType
  }
}

export function updateStatsData() {
  return {
    type: UPDATE_STATS_DATA
  }
}

export function toggleDescriptionSearchStatus() {
  return {
    type: TOGGLE_DESCRIPTION_SEARCH_STATUS
  }
}

export function toggleQCDescriptionSearchStatus() {
  return {
    type: TOGGLE_QC_DESCRIPTION_SEARCH_STATUS
  }
}

export function fetchDescriptionEditSuggestions() {
  return {
    type: FETCH_DESCRIPTION_EDIT_SUGGESTIONS
  }
}

export function fetchQCDescriptionEditSuggestions() {
  return {
    type: FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS
  }
}
