import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Input from '../../Input'
import SearchIcon from '../../Icon/SearchIcon'
import PaginationControls from '../../PaginationControls'
import SortIcon from '../../SortIcon'
import { Row, Col, HelpBlock } from 'react-bootstrap'
import { arrayMove } from 'react-sortable-hoc'
import SortableList from './ListContainer'

import { sortBy, entries, groupBy, chain, uniq } from 'lodash'
import cuid from 'cuid'
import cx from 'classnames'

const initialState = {
  pageNumber: 1,
  sorting: true,
  searchText: '',
  sortBy: '',
  duplicateIds: null
}

/**
 * Class to render a sortable list of Filter values for editing.
 * Any changes, makes a new Array and propogates it to the parent by onChange method
 * @export
 * @class FilterValues
 * @extends {Component}
 */
export default class FilterValuesContainer extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState || prevState.id !== nextProps.id) {
      return {
        ...initialState,
        id: nextProps.id,
        type: nextProps.type
      }
    }
    return {}
  }

  constructor(newProps) {
    super(newProps)
    this.state = {
      ...initialState
    }
  }

  componentDidUpdate(prevProps) {
    // incase the type has changed for current filter,
    // just process the values to ensure values are logically correct
    // ex. radio filter can have only 1 default
    if (this.props.type !== prevProps.type && this.props.id === prevProps.id) {
      this.processUpdatedValues()
    }
  }

  // handler called after user sorts value
  onSortEnd = ({ oldIndex, newIndex }) => {
    const items = arrayMove(this.props.items, oldIndex, newIndex)
    this.processUpdatedValues(items)
    this.setState({ sortBy: '' })
  }

  // handle update for the key, value in the index'th item.
  onUpdateValue = (index, key, value) => {
    const { items, onChange, type } = this.props

    // unless an action exists ('create'|'update'|'delete'), change it to 'update'
    const { action = 'update' } = items[index]
    const payload = {
      ...items[index],
      [key]: value,
      action
    }

    // if value is modifed & aliasValue is missing or same as value, modify it as well
    if (key === 'value' && (!payload.aliasValue || payload.aliasValue === items[index].value)) {
      payload.aliasValue = value
    }

    // ensures that only one value isDefault at a time for radio filter
    if (type === 'radio' && key === 'isDefault') {
      items.forEach((item, index) => {
        if (item.isDefault) {
          items[index] = {
            ...item,
            isDefault: false
          }
        }
      })
    }

    items[index] = payload

    // if value is edited, check for duplicate ids
    const duplicateIds = this.findIdsForDuplicateValues(items)
    this.setState({
      duplicateIds,
      sortBy: ''
    })
    onChange([...items], duplicateIds)
  }

  // find ids for duplicateValues by value or aliasValue
  findIdsForDuplicateValues(items) {
    items = items.filter(({ action }) => action !== 'delete')
    const duplicateIdByValue = chain(entries(groupBy(items, 'value')))
      .filter(([, values]) => values.length > 1)
      .map(([, values]) => values)
      .flatten()
      .map('id')
      .value()
    const duplicateIdByAliasValue = chain(entries(groupBy(items, 'aliasValue')))
      .filter(([, values]) => values.length > 1)
      .map(([, values]) => values)
      .flatten()
      .map('id')
      .value()

    const duplicateIds = uniq([...duplicateIdByValue, ...duplicateIdByAliasValue])
    return duplicateIds.length ? duplicateIds : null
  }

  // create a new value and add it at the end of the list
  onAddValue = () => {
    const { items } = this.props
    const newItem = {
      id: `new _${cuid.slug()}`,
      value: '',
      aliasValue: '',
      isDefault: false,
      action: 'create'
    }
    items.push(newItem)
    this.processUpdatedValues(items)
    this.setState({
      searchText: '',
      sortBy: '',
      pageNumber: this.getMaxPageCount('')
    })
  }

  // shared method for post processing and cleaning items in array after any changes in the array (adding, removing, sorting)
  // it also reports the change to parent by calling onChange method
  processUpdatedValues(items = this.props.items) {
    const { onChange, type } = this.props
    const activeItems = items.filter(({ action }) => action !== 'delete')

    // ensure that for radio filter has one and only one value with isDefault === true
    if (type === 'radio' && activeItems.length >= 1) {
      const seletectedItems = activeItems.filter(item => item.isDefault)
      const seletectedCount = seletectedItems.length
      if (seletectedCount === 0) {
        activeItems[0].isDefault = true
      } else if (seletectedCount > 1) {
        for (let i = 1; i < seletectedCount; i++) {
          seletectedItems[i].isDefault = false
        }
      }
    }
    // update the order of the active items
    activeItems.forEach((item, index) => {
      item.order = ++index
    })
    const duplicateIds = this.findIdsForDuplicateValues(items)
    this.setState({ duplicateIds })
    // report change to parent
    onChange([...items], duplicateIds)
  }

  onDeleteValue = index => {
    let { items } = this.props
    const item = items[index]
    if (toString(item.id).startsWith('new_')) {
      items = items.splice(index, 1) // if the value was new, nuke it
    } else {
      item.action = 'delete' // if the value was existing, mark it
    }
    this.processUpdatedValues(items)
  }

  onRemoveAll = () => {
    const oldItems = []
    this.props.items.forEach(item => {
      if (!toString(item.id).startsWith('new_')) {
        // only keep old values and mark them as deleted
        item.action = 'delete'
        oldItems.push(item)
      }
    })
    this.props.onChange(oldItems)
  }

  handlePageChange = pageNumber => {
    this.setState({ pageNumber })
  }

  searchChanged = searchText => {
    this.setState({ searchText, pageNumber: 1 })
  }

  clearSearch = () => {
    this.setState({ searchText: '', pageNumber: 1 })
  }

  updateSortBy = sortByField => {
    let { items } = this.props
    items = sortBy(items, [sortByField])
    this.processUpdatedValues(items)
    this.setState({ sortBy: sortByField })
  }

  getMaxPageCount(searchText = this.state.searchText) {
    const { items, pageSize } = this.props
    // filter out deleted items
    let activeItems = items.filter(({ action }) => action !== 'delete')

    // filter out items not matching searchText
    if (searchText) {
      activeItems = activeItems.filter(
        item => item.value.toLowerCase().includes(searchText) || item.aliasValue.toLowerCase().includes(searchText)
      )
    }
    // Calculate max pages based on activeItems count
    return Math.ceil(activeItems.length / pageSize)
  }

  render() {
    const { pageNumber = 1, sorting, searchText, sortBy, duplicateIds } = this.state
    const { pageSize, items, type } = this.props
    return (
      <Col sm={10} className={'filter-values-panel cre-panel-body'}>
        <Row className="filter-values-action-header">
          <Col sm={4}>
            <Input
              clearButton
              placeholder="Search Values"
              className={cx('input', 'search-input-box', 'flex1')}
              value={searchText}
              iconName={<SearchIcon width={20} height={20} />}
              didClickClearButton={this.clearSearch}
              textDidChange={this.searchChanged}
            />
          </Col>
        </Row>
        {duplicateIds && duplicateIds.length > 0 && (
          <div className="has-error filter-value-error">
            <HelpBlock>
              {`"Value" and "Display value" ("Alias value") should be unique across all filter values & not contain any duplicates`}
            </HelpBlock>
          </div>
        )}
        <Row className="filter-values-header">
          <Col sm={1}>ORDER</Col>
          <Col sm={4}>
            <span onClick={() => this.updateSortBy('value')}>
              VALUE <SortIcon asc={sortBy === 'value'} />
            </span>
          </Col>
          <Col sm={4}>
            <span onClick={() => this.updateSortBy('aliasValue')}>
              DISPLAY VALUE <SortIcon asc={sortBy === 'aliasValue'} />
            </span>
          </Col>
          <Col sm={1}>DEFAULT</Col>
          <Col sm={1}>REMOVE</Col>
        </Row>
        <SortableList
          useDragHandle
          sorting={sorting}
          items={items}
          onSortEnd={this.onSortEnd}
          type={type}
          onUpdateValue={this.onUpdateValue}
          onDeleteValue={this.onDeleteValue}
          startIndex={pageSize * (pageNumber - 1) + 1}
          endIndex={pageSize * pageNumber}
          searchText={searchText.toLowerCase()}
          duplicateIds={duplicateIds}
        />
        <PaginationControls onPageChange={this.handlePageChange} maxPages={this.getMaxPageCount()} page={pageNumber} />
        <Row className="filter-values-action-header">
          <Col smOffset={8} sm={4}>
            <button className={'btn_custom btn_small'} type="button" onClick={this.onAddValue}>
              Add New
            </button>
            <button className={'btn_custom btn_small btn_custom_bg_red'} type="button" onClick={this.onRemoveAll}>
              Remove All
            </button>
          </Col>
        </Row>
      </Col>
    )
  }
}

FilterValuesContainer.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  items: PropTypes.array,
  type: PropTypes.string,
  pageSize: PropTypes.number,
  onChange: PropTypes.func
}

FilterValuesContainer.defaultProps = {
  onChange: () => {},
  items: [],
  pageSize: 20
}
