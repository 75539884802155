import { createAction } from '@reduxjs/toolkit'

const deliveryJobsActions = {
  fetch: createAction('FETCH_DELIVERY_JOBS'),
  updateSettings: createAction('UPDATE_SETTINGS_DELIVERY_JOBS'),
  clearFilters: createAction('CLEAR_FILTERS_DELIVERY_JOBS'),
  successLoad: createAction('SUCCESS_LOAAD_DELIVERY_JOBS'),
  failedLoad: createAction('FAILED_LOAAD_DELIVERY_JOBS'),
  save: createAction('SAVE_DELIVERY_JOBS'),
  download: createAction('DOWNLOAD_DELIVERY_JOBS'),

  fetchByCategoryAndDeliveryDate: createAction('FETCH_DELIVERY_JOBS_BY_CATEGORY_DELIVERY_DATE'),
  successLoadByCategoryAndDeliveryDate: createAction('SUCCESS_LOAD_DELIVERY_JOBS_BY_CATEGORY_DELIVERY_DATE'),

  fetchDeliveryJobsTasks: createAction('FETCH_DELIVERY_JOBS_TASKS'),
  setLoadingDeliveryJobsTasks: createAction('SET_LOADING_DELIVERY_JOBS_TASKS'),
  successfetchDeliveryJobsTasks: createAction('SUCCESS_FETCH_DELIVERY_JOBS_TASKS')
}

export default deliveryJobsActions
