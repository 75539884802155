import { createAction } from '@reduxjs/toolkit'

const categoryTestsActions = {
  fetch: createAction('FETCH_CATEGORY_TESTS'),
  updateSettings: createAction('UPDATE_SETTINGS_CATEGORY_TESTS'),
  clearFilters: createAction('CLEAR_FILTERS_CATEGORY_TESTS'),
  successLoad: createAction('SUCCESS_LOAAD_CATEGORY_TESTS'),
  failedLoad: createAction('FAILED_LOAAD_CATEGORY_TESTS'),

  save: createAction('SAVE_CATEGORY_TESTS'),
  download: createAction('DOWNLOAD_CATEGORY_TESTS'),

  runTests: createAction('RUN_CATEGORY_TESTS'),

  // Global Configuration
  fetchGlobalConfig: createAction('FETCH_CATEGORY_TEST_GLOBAL_CONFIGURATION'),
  successLoadGlobalConfig: createAction('SUCCESS_LOAAD_CATEGORY_TEST_GLOBAL_CONFIGURATION'),
  failedLoadGlobalConfig: createAction('FAILED_LOAAD_CATEGORY_TEST_GLOBAL_CONFIGURATION'),
  saveGlobalConfig: createAction('SAVE_CATEGORY_TEST_GLOBAL_CONFIGURATION'),
  clearGlobalConfig: createAction('CLEAR_CATEGORY_TEST_GLOBAL_CONFIGURATION')
}

export default categoryTestsActions
