import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class GroupRoles extends Component {
  clickRoleRadio = e => {
    const groupId = e.target.name.replace(/radio_/, '')
    const groupRole = this.props.groupRoles[groupId]

    groupRole.isAdmin = e.target.value === '1'

    this.props.setRoleRadioState(groupId, groupRole)
  }

  render() {
    const { id, groupRoles } = this.props

    if (!id) return null

    return (
      <div>
        <h3>GROUP ROLE</h3>
        <table>
          <tbody>
            {Object.keys(groupRoles).map(groupId => {
              const group = groupRoles[groupId]

              return (
                group.visible && (
                  <tr key={groupId} style={{ height: '42px' }}>
                    <td>{group.name}</td>
                    <td>
                      <label>
                        <input
                          value="0"
                          type="radio"
                          checked={!group.isAdmin}
                          name={`radio_${groupId}`}
                          onChange={this.clickRoleRadio}
                        />
                        <span>Standard User</span>
                      </label>
                    </td>
                    <td>
                      <label>
                        <input
                          value="1"
                          type="radio"
                          checked={group.isAdmin}
                          name={`radio_${groupId}`}
                          onChange={this.clickRoleRadio}
                        />
                        <span>{group.isPortalGroup ? 'Portal Admin' : 'Group Admin'}</span>
                      </label>
                      {!group.isActive && (
                        <span>
                          &nbsp;&nbsp;<mark>Inactive</mark>
                        </span>
                      )}
                    </td>
                    <td />
                  </tr>
                )
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

GroupRoles.propTypes = {
  id: PropTypes.string,
  groupRoles: PropTypes.object,
  setRoleRadioState: PropTypes.func
}
