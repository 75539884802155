import { normalize, schema } from 'normalizr'

const reportSchema = new schema.Entity('report')
const tabs = new schema.Entity('tabs')
const filters = new schema.Entity('filters')
const filterValues = new schema.Entity('filterValues')

reportSchema.define({
  filters: new schema.Array(filters),
  tabs: new schema.Array(tabs)
})

tabs.define({
  filters: new schema.Array(filters)
})

filters.define({
  filterValues: new schema.Array(filterValues)
})

const normalizeReport = report => normalize(report, reportSchema).entities
export default normalizeReport
