import { permissions as PERMISSIONS } from 'constants/constants'

// You can add new product with own permission
const defaultProductsPermissions = [
  // Permission sequence is important for from low access to high!
  [PERMISSIONS.supraQCToolView, PERMISSIONS.supraQCToolEdit, PERMISSIONS.supraQCToolAdmin], // SupraQC
  [PERMISSIONS.ropsTaxonomyView, PERMISSIONS.ropsTaxonomyEdit], // RopsTaxonomy
  [PERMISSIONS.NIQqcToolView, PERMISSIONS.NIQqcToolEdit, PERMISSIONS.NIQqcToolAdmin] // NIQ QC Tool
]

// This function select lower permission when high is selected and unselect high permission when low is unselected
const updateRelativePermissions = ({ key, val, productsPermissions = defaultProductsPermissions }) => {
  const product = productsPermissions.find(el => el.includes(key))

  if (!key || !product) return {}
  switch (val) {
    case true:
      let defaultFromRight = false
      return product.reduceRight((acc, permission) => {
        if (key === permission) {
          defaultFromRight = true
        }
        return { ...acc, [permission]: defaultFromRight }
      }, {})
    case false:
      let defaultFromLeft = true
      return product.reduce((acc, permission) => {
        if (key === permission) {
          defaultFromLeft = false
        }
        return { ...acc, [permission]: defaultFromLeft }
      }, {})
    default:
      return {}
  }
}

export default updateRelativePermissions
