import React from 'react'

import Beacon from '../Beacon'
import { leftNavBeacon } from '../beacon-locations'

const LeftNavBeacon = () => (
  <Beacon
    isEnabled
    showtoolTip={false}
    id={leftNavBeacon.id}
    key={leftNavBeacon.id}
    modalPosition={'fixed'}
    title={leftNavBeacon.title}
    content={leftNavBeacon.content}
    width={leftNavBeacon.style.width}
    height={leftNavBeacon.style.height}
    modalTop={leftNavBeacon.style.modalTop}
    position={leftNavBeacon.style.position}
    dataStyle={leftNavBeacon.style.dataStyle}
    modalLeft={leftNavBeacon.style.modalLeft}
    beaconTop={leftNavBeacon.style.beaconTop}
    beaconLeft={leftNavBeacon.style.beaconLeft}
    arrowStyle={leftNavBeacon.style.arrowStyle}
    buttonStyle={leftNavBeacon.style.buttonStyle}
    arrowLocation={leftNavBeacon.style.arrowLocation}
    zIndexProperty={leftNavBeacon.style.zIndexProperty}
  />
)

export default LeftNavBeacon
