import { createReducer } from '@reduxjs/toolkit'

import cmeInspectorActions from './cmeInspectorActions'

const CMEInspectorReducers = createReducer(
  {
    loading: false,
    rows: [],
    errors: []
  },
  builder => {
    builder
      .addCase(cmeInspectorActions.fetch, (state, action) => {
        state.loading = true
      })
      .addCase(cmeInspectorActions.successLoad, (state, action) => {
        state.rows = action.payload.rows
        state.errors = action.payload.errors
        state.loading = false
      })
      .addCase(cmeInspectorActions.failedLoad, (state, action) => {
        state.loading = false
      })
  }
)

export default CMEInspectorReducers
