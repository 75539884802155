import { takeLatest, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'

import {
  FETCH_MAILBOX_PROVIDERS,
  fetchMailboxProvidersSuccess,
  fetchMailboxProvidersFailure,
  EXCLUDE_MAILBOX_PROVIDER,
  excludeMailboxProviderSuccess,
  excludeMailboxProviderFailure,
  INCLUDE_MAILBOX_PROVIDER,
  includeMailboxProviderSuccess,
  includeMailboxProviderFailure
} from '../actions/mailbox-provider-actions'
import { fetchGlobalFeedConfig } from '../actions/feed-actions'
import { createAlert } from '../actions/app-actions'

function* fetchMailboxProviders() {
  try {
    const response = yield call(() => axios.get(`/api/admin/idf-dimension/mailbox-providers`))
    yield put(fetchMailboxProvidersSuccess(response.data))
  } catch (err) {
    window.captureException(err)
    yield put(fetchMailboxProvidersFailure(err))
    yield put(createAlert('danger', err.message, `Error fetching mailbox providers`))
  }
}

function* excludeMailboxProvider(action) {
  try {
    yield call(() =>
      axios.post(`/api/feeds/global-mailbox-provider-excludes`, {
        mailboxProviderId: action.mailboxProviderId
      })
    )
    yield put(excludeMailboxProviderSuccess())
    yield put(createAlert('success', '', `Mailbox Provider excluded`))
    yield put(fetchGlobalFeedConfig())
  } catch (err) {
    window.captureException(err)
    yield put(excludeMailboxProviderFailure(err))
    yield put(createAlert('danger', err.message, `Error excluding mailbox provider`))
  }
}

function* includeMailboxProvider(action) {
  try {
    yield call(() =>
      axios.delete(`/api/feeds/global-mailbox-provider-excludes`, {
        data: {
          mailboxProviderId: action.mailboxProviderId
        }
      })
    )
    yield put(includeMailboxProviderSuccess())
    yield put(createAlert('success', '', `Mailbox Provider included`))
    yield put(fetchGlobalFeedConfig())
  } catch (err) {
    window.captureException(err)
    yield put(includeMailboxProviderFailure(err))
    yield put(createAlert('danger', err.message, `Error including mailbox provider`))
  }
}

function* fetchMailboxProvidersFlow() {
  yield takeLatest(FETCH_MAILBOX_PROVIDERS, fetchMailboxProviders)
}

function* excludeMailboxProviderFlow() {
  yield takeLatest(EXCLUDE_MAILBOX_PROVIDER, excludeMailboxProvider)
}

function* includeMailboxProviderFlow() {
  yield takeLatest(INCLUDE_MAILBOX_PROVIDER, includeMailboxProvider)
}

export default [fork(fetchMailboxProvidersFlow), fork(excludeMailboxProviderFlow), fork(includeMailboxProviderFlow)]
