import { createSelector } from 'reselect'

const usersSelector = state => (state.users && state.users.data) || []

const duplicateUsersByGroup = createSelector(usersSelector, user => {
  const usersByGroup = []
  user.forEach(user => {
    user.Groups.forEach(g => {
      const tmpUser = { ...user }
      delete tmpUser.Groups
      delete tmpUser.Permissions
      usersByGroup.push({
        ...tmpUser,
        Group: g
      })
    })
  })
  return usersByGroup
})

export default duplicateUsersByGroup
