import { replace, push } from 'connected-react-router'
import { takeLatest, call, fork, put, select } from 'redux-saga/effects'
import axios from 'axios'
import { getProductRoute } from '../routes'
import { updateSession } from '../actions/app-actions'
import {
  LOGIN,
  LOGOUT,
  profile,
  profileSuccess,
  profileFailed,
  loginSuccess,
  loginFailed,
  logoutSuccess,
  logoutFailed,
  VERIFY_ONBOARD_TOKEN,
  verifyOnboardingTokenStarted,
  verifyOnboardingTokenFailed,
  RESEND_EXPIRED_ONBOARD_EMAIL,
  RESENDING_ONBOARD_EMAIL_STARTED,
  RESENDING_ONBOARD_EMAIL_DONE,
  RESET_PASSWORD
} from '../actions/auth-actions'

import {
  SET_PRODUCT_ONBOARDED,
  setProductOnboardedFailed,
  setProductOnboardedSuccess
} from '../actions/walkthrough-actions'

import authService from '../services/auth-service'
import ApiService from '../services/api-service'
import { getAuth } from './../selectors/session-selectors'

import config from '../config'
import { parse } from 'query-string'

export function getClientHeaders() {
  return {
    'xc-sc-size': window.screen ? `${window.screen.width} x ${window.screen.height}` : null,
    'xc-win-size': `${window.innerWidth} x ${window.innerHeight}`,
    'xc-tzo': new Date().getTimezoneOffset(),
    'xc-platform': navigator.platform || navigator.oscpu,
    'xc-href': window.location.href
  }
}

axios.interceptors.response.use(
  response => response,
  error => {
    if (error) {
      if (error.response && error.response.status && error.response.status === 401) {
        console.error('Intercepted Unauthorized API Access', error)
        authService.logout()
        authService.redirectToLogin()
      }
      return Promise.reject(error)
    } else {
      console.error('no axios interceptor error')
    }
  }
)

const fetchToken = data => ApiService.post('/auth', data, getClientHeaders())
const fetchProfile = ({ token }) => ApiService.isAuth(token).get('/api/profile')
const getRouter = state => state.router

export function* refreshProfile() {
  const auth = yield select(getAuth)
  yield getProfile(auth)
}

function* getProfile(auth) {
  yield put(profile())
  try {
    const profile = auth && (yield fetchProfile(auth))

    yield put(profileSuccess(profile))

    if (profile.user !== undefined) {
      /* Set user context for error logs */
      window.heap && window.heap.identify(profile.user.id)
      if (config.log.sentry.enabled) {
        window.setUserContext({
          email: profile.user.email,
          id: profile.user.id,
          groups: profile.user.Groups ? profile.user.Groups.map(group => group.name).join(',') : []
        })
      }
    }
    return profile
  } catch (error) {
    yield put(profileFailed(error))
    console.error(error)
    if (error && error.response && error.response.status === 401) {
      authService.logout()
      return put(replace('/login'))
    } else {
      // capture non 401 errors
      window.captureException(error)
    }
  }
}

/**
 * @param {object} action.payload: { auth, email, password }
 */
function* login({ payload }) {
  try {
    let auth = payload.auth

    if (!auth) {
      auth = yield fetchToken({
        email: payload.email.toLowerCase().trim(),
        password: payload.password
      })
    }

    authService.setAuth(auth)
    axios.defaults.headers.common.Authorization = `Bearer ${auth.token}`
    yield put(loginSuccess(auth))
    const profile = yield getProfile(auth)
    if (window.location.pathname === '/login') {
      console.info('Profile has been loaded. Redirecting to user homepage...')
      let redirectUrl = parse(window.location.search).redirectUrl
      if (!redirectUrl) {
        redirectUrl = yield getDefaultRoute(profile)
      }
      console.info('User homepage', redirectUrl)
      yield put(replace(redirectUrl))
    } else if (window.location.pathname === '/onboarding') {
      console.info('Profile has not been loaded. Redirecting to user welcome page...')
      yield put(push('/welcome'))
    }
  } catch (error) {
    console.error('Error logging in user: ', error)
    window.captureException(error)
    if (error?.response?.status === 500) {
      yield put(loginFailed(error.response?.data?.name))
    } else {
      yield put(loginFailed(null))
    }
  }
}

function* getDefaultRoute(profile) {
  const state = yield select(state => state)
  return getProductRoute(profile.defaultProduct, state.session)
}

function* logout(action) {
  try {
    yield call([authService, authService.logout])
    yield put(logoutSuccess())
    window.setUserContext()
    if (!action.skipRedirect) yield put(replace('/login'))
  } catch (error) {
    window.captureException(error)
    yield put(logoutFailed(error))
  }
}

function* setProductOnboarded() {
  try {
    const { user } = yield select(state => state.session)
    const response = yield call(() =>
      axios.post(`${config.apiBaseUrl}/user/`, { id: user.id, onboardUserProduct: true })
    )

    if (response.status === 200) {
      yield put(setProductOnboardedSuccess())
    } else {
      yield put(setProductOnboardedFailed(response.data))
    }
  } catch (error) {
    window.captureException(error)
    yield put(setProductOnboardedFailed(error.message))
  }
}

function* verifyOnboardingToken({ payload: { email, token } }) {
  try {
    // Fix IE 11 issues and avoid weird logout behavior across browsers - ch3523
    // yield logout({ skipRedirect: true })
    yield put(verifyOnboardingTokenStarted())
    const response = yield call(() => axios.post('/onboard/verify', { email, token }, { headers: getClientHeaders() }))
    yield login({ payload: { auth: response.data } })
  } catch (error) {
    console.error(error)
    window.captureException(error)
    yield put(verifyOnboardingTokenFailed(error.response.data))
  }
}

function* resendExpiredOnboardEmail({ payload }) {
  try {
    const { email, token } = payload
    yield put({ type: RESENDING_ONBOARD_EMAIL_STARTED })
    const response = yield call(() => axios.post('/onboard/resend', { email, token }, { headers: getClientHeaders() }))
    yield put({
      type: RESENDING_ONBOARD_EMAIL_DONE,
      payload: response.data
    })
  } catch (error) {
    console.error(error)
    window.captureException(error)
    yield put({
      type: RESENDING_ONBOARD_EMAIL_DONE,
      payload: error.response.data
    })
  }
}

function* resetPassword({ payload }) {
  try {
    const router = yield select(getRouter)
    const { token, email } = parse(router.location.search)
    const response = yield call(() =>
      axios.post('/reset_password', {
        email,
        token,
        password: payload.password,
        confirmPassword: payload.password
      })
    )
    if (response.data && response.data.success && payload.callback) {
      yield put(
        updateSession({
          loading: false,
          sessionMessageType: 'resetPasswordSuccess',
          sessionMessage: 'Password Updated. Please login using the new password.'
        })
      )
      return payload.callback()
    }
  } catch (error) {
    console.error(`Error: ${error}`)
    window.captureException(error)
    yield put(
      updateSession({
        loading: false,
        sessionMessageType: error.response?.data?.name || error.name // first for Axios Error, second for default Errors
      })
    )
  }
}

// =====================================
//  WATCHERS
// -------------------------------------

function* watchLogin() {
  yield takeLatest(LOGIN, login)
}

function* watchLogout() {
  yield takeLatest(LOGOUT, logout)
}

function* watchProductOnboard() {
  yield takeLatest(SET_PRODUCT_ONBOARDED, setProductOnboarded)
}

function* watchOnboarding() {
  yield takeLatest(VERIFY_ONBOARD_TOKEN, verifyOnboardingToken)
  yield takeLatest(RESEND_EXPIRED_ONBOARD_EMAIL, resendExpiredOnboardEmail)
}

function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPassword)
}

// =====================================
//  AUTH SAGAS
// -------------------------------------

export default [
  fork(watchLogin),
  fork(watchLogout),
  fork(watchProductOnboard),
  fork(watchOnboarding),
  fork(watchResetPassword)
]
