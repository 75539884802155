import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'

class AddGroupButton extends Component {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    handleOnClick: PropTypes.func,
    rules: PropTypes.array,
    level: PropTypes.number,
    readOnly: PropTypes.bool
  }

  render() {
    const tooltip = name => <Tooltip id="add-rule-group-tooltip">{name}</Tooltip>
    return (
      <div className={this.props.className}>
        <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Add Rule Group')}>
          <Button bsSize="small" bsStyle="link" onClick={this.props.handleOnClick} disabled={this.props.readOnly}>
            <Glyphicon glyph={'plus'} /> Rule Group
          </Button>
        </OverlayTrigger>
      </div>
    )
  }
}

export default AddGroupButton
