import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'

export default class NIQIcon extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object,
    fill: PropTypes.string
  }

  static defaultProps = {
    width: 30,
    height: 20,
    fill: 'currentColor'
  }

  render() {
    return (
      <IconBase viewBox="-20 0 110 80" {...this.props}>
        <path d="M58.6,75.5c-2.6,0-5.1-1.1-6.9-3.1L18.4,34.9v40.3H0V10.7c0-3.8,2.4-7.2,5.9-8.6c3.6-1.4,7.6-0.4,10.1,2.5  l33.3,37.5V1.8h18.4v64.5c0,3.8-2.4,7.2-5.9,8.6C60.8,75.3,59.7,75.5,58.6,75.5L58.6,75.5z" />
      </IconBase>
    )
  }
}
