import React from 'react'
import PropTypes from 'prop-types'

import { HelpBlock } from 'react-bootstrap'
import InputField from './InputField'

const getS3BucketSuffix = groupId => {
  return `/partner-${groupId || 'new'}`
}

const BasicFeedConfig = props => {
  const { groupId, formState, handleInputBlur, handleInputChange } = props

  return (
    <React.Fragment>
      <h4>FTPS Delivery</h4>

      <HelpBlock>
        {
          'FTPS is the default delivery option. If S3 delivery fields are entered, files will be delivered via S3 using KMS encryption.'
        }
      </HelpBlock>
      <HelpBlock>{'If both FTPS and S3 sections are filled out, files will be delivered to S3.'}</HelpBlock>

      <br />
      <br />

      <InputField
        label="FTPS Username"
        id="feedSftpUsername"
        placeholder="FTPS username"
        onBlur={handleInputBlur}
        value={formState.feedSftpUsername}
        onChange={handleInputChange}
      />

      <InputField
        type="password"
        id="feedSftpPassword"
        label="FTPS Password"
        placeholder="FTPS password"
        onBlur={handleInputBlur}
        value={formState.feedSftpPassword}
        onChange={handleInputChange}
      />

      <InputField
        id="feedSftpFolderName"
        label={'FTPS Folder Name'}
        onBlur={handleInputBlur}
        placeholder="FTPS folder name"
        afterLabel={getS3BucketSuffix(groupId)}
        value={formState.feedSftpFolderName}
        onChange={handleInputChange}
      />

      <InputField
        id="feedsFilePublicEncryptionKey"
        label="GPG Public Key"
        placeholder="GPG Public Key"
        onBlur={handleInputBlur}
        value={formState.feedsFilePublicEncryptionKey}
        onChange={handleInputChange}
      />

      <br />

      <h4>S3 Delivery</h4>

      <HelpBlock>{'Leave blank if no S3 delivery required.'}</HelpBlock>

      <br />
      <br />

      <InputField
        label="S3 Bucket"
        id="s3Bucket"
        placeholder="vpc-intel-staging-search"
        onBlur={handleInputBlur}
        value={formState.s3Bucket}
        onChange={handleInputChange}
      />

      <InputField
        id="s3KmsKeyId"
        label="S3 KMS Key ID"
        placeholder="vpc-intel-staging-search"
        onBlur={handleInputBlur}
        value={formState.s3KmsKeyId}
        onChange={handleInputChange}
      />

      <br />
      <br />

      <h4>Notifications</h4>

      <HelpBlock>{'Leave blank if no notifications are required after feed is delivered'}</HelpBlock>

      <InputField
        id="idfAdditionalEmails"
        label="Email List"
        placeholder="Emails separated by semicolons"
        onBlur={handleInputBlur}
        value={formState.idfAdditionalEmails}
        onChange={handleInputChange}
        componentClass="textarea"
      />
    </React.Fragment>
  )
}

BasicFeedConfig.propTypes = {
  groupId: PropTypes.string,
  formState: PropTypes.object,
  handleInputBlur: PropTypes.func,
  handleInputChange: PropTypes.func
}

export default BasicFeedConfig
