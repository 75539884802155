import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import createRootReducer from './reducers'
import sagas from './sagas'
import searchSagas from './sagas/search-sagas'
import supraQCSagas from './sagas/supra-qc-sagas'
import qcSagas from './sagas/qc-sagas'

import niqSearchSagas from 'components/NIQSearchPage/sagas/niq-search-sagas'
import niqSupraQCSagas from 'components/NIQSearchPage/sagas/niq-qc-sagas'

import { default as reduxActionMiddleware } from './socket'

import { routerMiddleware } from 'connected-react-router'

import { createBrowserHistory } from 'history'

import commonRtkApi from './commonRtkApi'

export const history = createBrowserHistory()

const initStore = () => {
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer: createRootReducer(history),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        // options https://redux-toolkit.js.org/api/getDefaultMiddleware
        serializableCheck: {
          ignoredActions: ['ADD_MODAL' /* IN-1409 */, 'REGISTER_BEACON', '@@redux-form/INITIALIZE'],
          ignoredPaths: ['modals', 'walkthrough', 'form.feed-form']
        },
        immutableCheck: {
          ignoredPaths: [
            'searchQC.splitByChart',
            'search.queryTree',
            'searchEdit',
            'supraQCToolV2',
            'supraQCTool',
            'niqSearch.queryTree',
            'niqSearchEdit'
          ]
        }
      }).concat(commonRtkApi.middleware, sagaMiddleware, routerMiddleware(history), reduxActionMiddleware),
    devTools: process.env.NODE_ENV !== 'production'
  })
  sagaMiddleware.run(sagas)
  sagaMiddleware.run(searchSagas)
  sagaMiddleware.run(qcSagas)
  sagaMiddleware.run(supraQCSagas)
  sagaMiddleware.run(niqSearchSagas)
  sagaMiddleware.run(niqSupraQCSagas)
  return store
}

export default initStore
