// @flow
export const FETCH_L1_CATEGORIES = 'FETCH_L1_CATEGORIES'
export const FETCH_L1_CATEGORIES_SUCCESS = 'FETCH_L1_CATEGORIES_SUCCESS'
export const FETCH_L1_CATEGORIES_FAILED = 'FETCH_L1_CATEGORIES_FAILED'

export function fetchL1Categories(moduleId: Object): Object {
  return {
    type: FETCH_L1_CATEGORIES,
    moduleId
  }
}

export function fetchL1CategoriesSuccess(payload: Object): Object {
  return {
    type: FETCH_L1_CATEGORIES_SUCCESS,
    payload
  }
}

export function fetchL1CategoriesFailed(error: Object): Object {
  return {
    type: FETCH_L1_CATEGORIES_FAILED,
    error
  }
}
