import config from '../config'

const validateRouteProtocol = () => {
  if (config.enforceHttps) {
    const https = 'https:'
    if (window && window.location && window.location.protocol !== https) {
      window.location.protocol = https
    }
  }
}

export default validateRouteProtocol
