import React from 'react'
import PropTypes from 'prop-types'

import { FormGroup, FormControl, Col, ControlLabel } from 'react-bootstrap'

const DropDownField = ({ label, id, onChange, options, value, placeholder }) => {
  return (
    <FormGroup>
      <Col sm={2}>
        <ControlLabel>{label}</ControlLabel>
      </Col>

      <Col sm={4}>
        <FormControl
          id={id}
          onChange={onChange}
          componentClass="select"
          placeholder={placeholder}
          value={value === null ? '' : value}
        >
          {options.map((option, idx) => (
            <option value={option.value} key={`${option.value}-${idx}`}>
              {option.label}
            </option>
          ))}
        </FormControl>
      </Col>
    </FormGroup>
  )
}

DropDownField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default DropDownField
