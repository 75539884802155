export const EXCLUDE_PARTNER_MAILBOX_PROVIDER = 'EXCLUDE_PARTNER_MAILBOX_PROVIDER'
export const EXCLUDE_PARTNER_MAILBOX_PROVIDER_SUCCESS = 'EXCLUDE_PARTNER_MAILBOX_PROVIDER_SUCCESS'
export const EXCLUDE_PARTNER_MAILBOX_PROVIDER_FAILURE = 'EXCLUDE_PARTNER_MAILBOX_PROVIDER_FAILURE'

export const INCLUDE_PARTNER_MAILBOX_PROVIDER = 'INCLUDE_PARTNER_MAILBOX_PROVIDER'
export const INCLUDE_PARTNER_MAILBOX_PROVIDER_SUCCESS = 'INCLUDE_PARTNER_MAILBOX_PROVIDER_SUCCESS'
export const INCLUDE_PARTNER_MAILBOX_PROVIDER_FAILURE = 'INCLUDE_PARTNER_MAILBOX_PROVIDER_FAILURE'

export const excludePartnerMailboxProvider = (partnerId, mailboxProviderId) => ({
  type: EXCLUDE_PARTNER_MAILBOX_PROVIDER,
  partnerId,
  mailboxProviderId
})

export function excludePartnerMailboxProviderSuccess() {
  return {
    type: EXCLUDE_PARTNER_MAILBOX_PROVIDER_SUCCESS
  }
}

export function excludePartnerMailboxProviderFailure(error) {
  return {
    type: EXCLUDE_PARTNER_MAILBOX_PROVIDER_FAILURE,
    error
  }
}

export const includePartnerMailboxProvider = (partnerId, mailboxProviderId) => ({
  type: INCLUDE_PARTNER_MAILBOX_PROVIDER,
  partnerId,
  mailboxProviderId
})

export function includePartnerMailboxProviderSuccess() {
  return {
    type: INCLUDE_PARTNER_MAILBOX_PROVIDER_SUCCESS
  }
}

export function includePartnerMailboxProviderFailure(error) {
  return {
    type: INCLUDE_PARTNER_MAILBOX_PROVIDER_FAILURE,
    error
  }
}
