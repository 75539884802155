import cuid from 'cuid'

export const ADD_MODAL = 'ADD_MODAL'
export const REMOVE_MODAL = 'REMOVE_MODAL'
export const CLEAR_MODALS = 'CLEAR_MODALS'

export const addModal = (options, actionParams) => ({
  type: ADD_MODAL,
  ...actionParams,
  options: {
    id: `modal-${cuid()}`,
    ...options
  }
})

export const removeModal = id => ({
  type: REMOVE_MODAL,
  id
})

export const clearModals = () => ({
  type: CLEAR_MODALS
})

export const successModal = (content, onShow = null, onShowParams = [], title = '') => ({
  type: ADD_MODAL,
  successModal: true,
  options: {
    id: `success-modal-${cuid()}`,
    title,
    content,
    onShow,
    onShowParams
  }
})

export const errorModal = (content = null, title = null, onShow = null) => ({
  type: ADD_MODAL,
  errorModal: true,
  options: {
    id: `error-modal-${cuid()}`,
    title,
    content,
    onShow,
    modalTitle: title
  }
})

export const confirmModal = (
  content = null,
  title = null,
  { okButton, deleteButton },
  callback = null,
  size,
  className
) => ({
  type: ADD_MODAL,
  confirmModal: true,
  options: {
    id: `confirm-modal-${cuid()}`,
    title: '',
    size,
    callback,
    content,
    modalTitle: title || 'Confirm Action',
    okButton,
    deleteButton,
    className
  }
})

export const confirmModalReport = (content = null, { okButton }, callback = null, size) => ({
  type: ADD_MODAL,
  confirmModal: true,
  options: {
    hideCloseButton: true,
    callback,
    content,
    okButton,
    size
  }
})

export const addFormModal = options => ({
  type: ADD_MODAL,
  formModal: true,
  options: {
    okButton: false,
    cancelButton: false,
    ...options
  }
})

export const addCustomModal = options => ({
  type: ADD_MODAL,
  [options.modalType]: true,
  options: {
    okButton: false,
    cancelButton: false,
    ...options
  }
})
