// @flow
import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import buttonField from './button-form'

const RenderFeedEditField = props => {
  const hasActiveGroup = () => {
    let hasActiveGroup = false
    if (props.userEdit.Groups) {
      props.userEdit.Groups.forEach(group => {
        if (hasActiveGroup) return
        hasActiveGroup = group.feedsEnabled
      })
    }
    return hasActiveGroup
  }

  const clickFeedRadio = (ev: Object) => {
    props.setFeedState(ev.target.value === '1')
  }

  if (!hasActiveGroup()) return null

  return (
    <div>
      <h3>DATA FEED</h3>
      <Field
        type="text"
        name="isFeedNotificationOn"
        component={buttonField}
        onChange={clickFeedRadio}
        checked={props.isFeedNotificationOn}
      />
    </div>
  )
}

RenderFeedEditField.propTypes = {
  setFeedState: PropTypes.func,
  isFeedNotificationOn: PropTypes.bool,
  userEdit: PropTypes.object
}

export default RenderFeedEditField
