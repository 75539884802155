import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import 'babel-polyfill'
import './initialize'

import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import DocumentTitle from 'react-document-title'

import initStore, { history } from './store'
import Alerts from './components/alerts'
import Modals from './components/Modals'
import App from './App'

import { appName } from './constants/constants'
import themeMUI from './mui/theme.ts'

const store = initStore()
const rootElement = document.getElementById('root')

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={themeMUI}>
      <DocumentTitle title={appName}>
        <ConnectedRouter history={history}>
          <div>
            <Route path="/" component={App} />
            <Modals />
            <Alerts />
          </div>
        </ConnectedRouter>
      </DocumentTitle>
    </ThemeProvider>
  </Provider>,
  rootElement
)
