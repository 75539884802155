import { createApi } from '@reduxjs/toolkit/query/react'

import config from 'config/index'
import axiosBaseQuery from 'utils/rtk-query-axios'

/**
 * This is common RTK Query API
 */
const commonRtkApi = createApi({
  reducerPath: 'rtkQueryApi',
  baseQuery: axiosBaseQuery({ baseUrl: config.apiBaseUrl }),
  endpoints: _ => ({})
})

export default commonRtkApi
