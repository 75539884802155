import { filter } from 'lodash'

class GroupService {
  /**
   * Gets the number of seats already taken in a group
   * @param  {Object} group
   * @return {Integer}
   */
  getSeatsTaken(group) {
    return group.seatCount - this.getOpenSeatsLeft(group)
  }

  /**
   * Gets the number of open seats left in a group
   * - Inactive user within a group do not take 1 seat
   * - User passed as a parameter does not count as 1 seat
   * @param  {Object} group
   * @param  {Object} user
   * @return {Integer}
   */
  getOpenSeatsLeft(group, user = null) {
    try {
      // Either the counts are already aggregated on the group or we calculate it
      const userCount = group.userCount
        ? group.userCount
        : filter(group.Users, u => {
            if (user === null) {
              return u.GroupUser.isActive === true
            }
            return u.GroupUser.isActive === true && (!user || user.id !== u.id)
          }).length
      return group.seatCount - userCount
    } catch (err) {
      window.captureException(err)
      throw err
    }
  }

  /**
   * Whether the given user belong to the given group
   * @param  {Object} group
   * @param  {Object} user
   * @return {Boolean}
   */
  belongToGroup(group, user) {
    return filter(group.Users, u => u.id === user.id).length
  }

  getFilteredGroups(groups, text) {
    return groups.filter(group => group.name.toLowerCase().indexOf(text.toLowerCase()) > -1)
  }
}

export default new GroupService()
