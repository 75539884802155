import { createAction } from '@reduxjs/toolkit'

// FEED MODULES
export const setSelectedFeedModuleAction = createAction('SET_SELECTED_FEED_MODULES')
export const resetSelectedFeedModuleAction = createAction('RESET_SELECTED_FEED_MODULES')
export const setEditedFeedModuleAction = createAction('SET_EDITED_FEED_MODULES')
export const resetEditedFeedModuleAction = createAction('RESET_EDITED_FEED_MODULES')

export const setFeedModuleLoadingAction = createAction('SET_FEED_MODULE_LOADING')
export const fetchFeedModulesAction = createAction('FETCH_FEED_MODULES')
export const fetchFeedModulesAndSelectAction = createAction('FETCH_FEED_MODULES_AND_SELECT')
export const successLoadFeedModulesAction = createAction('SUCCESS_LOAAD_FETCH_FEED_MODULES')
export const saveFeedModuleAction = createAction('SAVE_FEED_MODULES')
export const successSaveFeedModuleAction = createAction('SUCCESS_SAVE_FEED_MODULES')
export const removeFeedModuleAction = createAction('REMOVE_FEED_MODULES')
export const successRemoveFeedModuleAction = createAction('SUCCESS_REMOVE_FEED_MODULES')

// QUERIES
export const setFeedModuleQueryLoadingAction = createAction('SET_FEED_MODULE_QUERY_LOADING')
export const fetchFeedModuleQueriesAction = createAction('FETCH_FEED_MODULE_QUERIES')
export const successLoadFeedModuleQueriesAction = createAction('SUCCESS_LOAAD_FETCH_FEED_MODULE_QUERIES')
export const saveFeedModuleQueryAction = createAction('SAVE_FEED_MODULE_QUERY')
export const successSaveFeedModuleQueryAction = createAction('SUCCESS_SAVE_FEED_MODULE_QUERY')
export const setOpenNewFeedModuleQueryAction = createAction('SET_OPEN_NEW_FEED_MODULE_QUERY')
export const removeFeedModuleQueryAction = createAction('REMOVE_FEED_MODULE_QUERY')
export const successRemoveFeedModuleQueryAction = createAction('SUCCESS_REMOVE_FEED_MODULE_QUERY')
