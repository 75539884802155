export const FETCH_ENTRIES = 'AUTH_LOG_FETCH_ENTRIES'
export const FETCH_ENTRIES_DONE = 'AUTH_LOG_FETCH_ENTRIES_DONE'
export const RESOLVE_BROWSER = 'AUTH_LOG_RESOLVE_BROWSER'
export const RESOLVE_LOCATION = 'AUTH_LOG_RESOLVE_LOCATION'
export const RESOLVE_LOCATION_DONE = 'AUTH_LOG_RESOLVE_LOCATION_DONE'

export const fetchEntries = ({ pageNumber, sizePerPage, searchField, searchValue }) => ({
  type: FETCH_ENTRIES,
  pageNumber,
  sizePerPage,
  searchField,
  searchValue
})

export const resolveLocations = (ipList = []) => ({
  type: RESOLVE_LOCATION,
  ipList
})
