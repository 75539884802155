export const defaultPaginationOptions = {
  page: 1, // which page you want to show as default
  sizePerPageList: [
    {
      text: '20',
      value: 20
    },
    {
      text: '50',
      value: 50
    },
    {
      text: '100',
      value: 100
    }
  ], // you can change the dropdown list for size per page
  sizePerPage: 100, // which size per page you want to locate as default
  pageStartIndex: 1, // where to start counting the pages
  paginationSize: 3, // the pagination bar size.
  prePage: 'Prev', // Previous page button text
  nextPage: 'Next', // Next page button text
  firstPage: 'First', // First page button text
  lastPage: 'Last', // Last page button text
  prePageTitle: 'Go to Previous', // Previous page button title
  nextPageTitle: 'Go to Next', // Next page button title
  firstPageTitle: 'Go to First', // First page button title
  lastPageTitle: 'Go to Last', // Last page button title
  paginationShowsTotal: true, // Accept bool or function
  paginationPosition: 'bottom', // default is bottom, top and both is all available
  hideSizePerPage: true, // You can hide the dropdown for sizePerPage
  alwaysShowAllBtns: false, // Always show next and previous button
  withFirstAndLast: true, // Hide the going to First and Last page button
  hidePageListOnlyOnePage: true // Hide the page list if only one page.
}

export const searchFields = [
  { id: 0, label: 'Description', value: 'description', visible: true },
  { id: 1, label: 'Brand', value: 'brand_name', visible: true },
  { id: 2, label: 'Merchant', value: 'merchant_name', visible: true },
  { id: 3, label: 'Category Full Path', value: 'category_full_path', visible: true },
  { id: 4, label: 'Revenue', value: 'revenue', visible: true },
  { id: 5, label: 'Count', value: 'count', visible: true },
  { id: 6, label: 'Cumulative', value: 'cumulative', visible: true }
]

export const qcFields = [
  { id: 0, label: 'Description', value: 'description', visible: true },
  { id: 1, label: 'Merchant', value: 'merchant_name', visible: true },
  { id: 3, label: 'Curr. Brand', value: 'brand_name', visible: true },
  { id: 2, label: 'Prev. Brand', value: 'last_brand_name', visible: true },
  { id: 5, label: 'Curr. Category', value: 'category_full_path', visible: true },
  { id: 4, label: 'Prev. Category', value: 'last_category_full_path', visible: true },
  { id: 6, label: 'Revenue', value: 'revenue', visible: true },
  { id: 7, label: 'Count', value: 'count', visible: true }
]
