import { takeLatest, select, call, put, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import contentRange from 'content-range'
import axios from 'axios'

import { successModal, errorModal } from '../actions/modal-actions'

import {
  CREATE_FEED,
  createFeedSuccess,
  createFeedFailed,
  FETCH_FEED,
  RESET_FEED,
  SWITCH_TOGGLE_FEED,
  FETCH_FEEDS,
  FORM_VALUES_UPDATE,
  FETCH_FEEDS_FAILED,
  FETCH_FEEDS_SUCCESS,
  SAVE_FEED,
  CREATE_FIREHOSE_FEED,
  FETCH_FEED_DELIVERIES,
  FETCH_FEED_DELIVERIES_SUCCESS,
  FETCH_FEED_DELIVERIES_FAILURE,
  FETCH_FEED_DELIVERIES_SUMMARY,
  FETCH_FEED_DELIVERIES_SUMMARY_SUCCESS,
  FETCH_FEED_DELIVERIES_SUMMARY_FAILURE,
  FETCH_FEED_GROUP_LOCKS,
  FETCH_FEED_GROUP_LOCKS_SUCCESS,
  FETCH_FEED_GROUP_LOCKS_FAILURE,
  DELETE_FEED_GROUP_LOCK,
  DELETE_FEED_GROUP_LOCK_FAILURE,
  FETCH_CLIENT_FEED_INFO,
  FETCH_CLIENT_FEED_INFO_SUCCESS,
  FETCH_CLIENT_FEED_INFO_FAILURE,
  FETCH_GLOBAL_FEED_CONFIG,
  FETCH_GLOBAL_FEED_CONFIG_SUCCESS,
  FETCH_GLOBAL_FEED_CONFIG_FAILURE
} from '../actions/feed-actions'
import { updateSession, requestError, createAlert } from '../actions/app-actions'

function* createFeed({ feed }) {
  try {
    const response = yield call(() => axios.post('/api/admin/feeds', { ...feed }))
    yield put(createFeedSuccess({ payload: response.data }))

    yield put(successModal('Feed Created!'))
    yield put(push('/admin/feeds'))
  } catch (error) {
    window.captureException(error)
    yield put(createFeedFailed({ error }))
    return yield put(errorModal('Error creating the feed.'))
  }
}

/**
 * Saves a single group
 * @param {Object} options.payload: {groupData, products} [description]
 */
function* saveFeed(action, useCallback = true) {
  try {
    yield call(() => axios.put(`/api/admin/feeds/${action.feed.id}`, { ...action.feed }))
    yield fetchFeeds()
    if (useCallback) {
      yield put(successModal('Feed Updated!'))
      yield put(push('/admin/feeds'))
    }
  } catch (error) {
    window.captureException(error)
    yield put(requestError(error))
    return yield put(errorModal('Error updating the feed.'))
  }
}

/**
 * Gets single group
 * @param {Object} options.payload Group id
 */
function* fetchFeeds(action = {}) {
  const state = yield select(state => state)
  try {
    const searchEntity = state.feeds.searchEntity
    const response = yield call(() =>
      axios.get('/api/admin/feeds/', {
        params: {
          offset: action.offset,
          count: action.count,
          q: action.search ? action.search : undefined,
          sort: '-lastDeliveryDate,moduleId',
          searchEntity
        }
      })
    )
    const parts = contentRange.parse(response.headers['content-range'])
    yield put({
      type: FETCH_FEEDS_SUCCESS,
      data: response.data,
      offset: action.offset,
      count: action.search ? state.feeds.count : parts ? parts.length : 0
    })
  } catch (err) {
    window.captureException(err)
    yield put({ type: FETCH_FEEDS_FAILED, error: err.message })
  }
}

/**
 * Gets single group
 * @param {Object} options.payload Group id
 */
function* fetchFeed(action) {
  try {
    yield put(updateSession({ loading: true }))
    const response = yield call(() => axios.get(`/api/admin/feeds/${action.feedId}`))
    yield put(updateSession({ loading: false, feed: response.data }))
    yield put({
      type: FORM_VALUES_UPDATE,
      formName: 'feed-form',
      values: response.data
    })
  } catch (err) {
    window.captureException(err)
    yield put({ type: FETCH_FEEDS_FAILED, message: err.message })
  }
}

function* resetFeed() {
  try {
    yield put(updateSession({ feed: null }))
  } catch (err) {
    window.captureException(err)
    throw new Error(`Reset feed failed with error: ${err.message}`)
  }
}

function* createFirehoseFeed(action) {
  try {
    yield call(() =>
      axios.post(`/api/admin/etl/orchestrate`, {
        type: 'idf-generate',
        metadata: {
          feedId: action.feedId,
          frequency: 'firehose',
          end_date: new Date(),
          triggerSource: 'backoffice-manual-firehose'
        }
      })
    )
    yield put(successModal('Firehose feed task created'))
  } catch (err) {
    window.captureException(err)
    return yield put(errorModal(`Error orchestrating a firehose feed task. ${err?.response?.data || err.message}`))
  }
}

function* fetchFeedDeliveries(action) {
  try {
    const { startDate, endDate, page = 1, count = 25, sortOrder = 'desc', sortBy = 'createdAt' } = action.payload

    const offset = (page - 1) * count

    const response = yield call(() =>
      axios.get('/api/feed-deliveries', {
        params: {
          offset,
          count,
          sortBy,
          sortOrder,
          startDate,
          endDate
        }
      })
    )
    if (response && response.data) {
      yield put({
        type: FETCH_FEED_DELIVERIES_SUCCESS,
        ...response.data
      })
    }
  } catch (error) {
    const errorMessage = `Error fetching feed deliveries: ${error.message}`
    yield put({
      type: FETCH_FEED_DELIVERIES_FAILURE,
      error: errorMessage
    })
  }
}

function* fetchFeedDeliveriesSummary(action) {
  try {
    const { startDate, endDate, groups } = action.payload

    const response = yield call(() =>
      axios.get('/api/feed-deliveries-summary', {
        params: {
          startDate,
          endDate,
          groups
        }
      })
    )
    if (response && response.data) {
      yield put({
        type: FETCH_FEED_DELIVERIES_SUMMARY_SUCCESS,
        ...response.data
      })
    }
  } catch (error) {
    const errorMessage = `Error fetching feed deliveries: ${error.message}`
    yield put({
      type: FETCH_FEED_DELIVERIES_SUMMARY_FAILURE,
      error: errorMessage
    })
  }
}

function* fetchFeedGroupLocks() {
  try {
    const response = yield call(() => axios.get('/api/admin/idf-locks'))
    if (response && response.data) {
      yield put({
        type: FETCH_FEED_GROUP_LOCKS_SUCCESS,
        data: response.data
      })
    }
  } catch (error) {
    const errorMessage = `Error fetching feed group locks: ${error.message}`
    yield put({
      type: FETCH_FEED_GROUP_LOCKS_FAILURE,
      error: errorMessage
    })
  }
}

function* deleteFeedGroupLock(action) {
  try {
    const response = yield call(() => axios.delete(`/api/admin/idf-lock/${action.id}`))
    if (response && response.data) {
      yield put({
        type: FETCH_FEED_GROUP_LOCKS
      })
    }
  } catch (error) {
    const errorMessage = `Error deleting feed group lock: ${error.message}`
    yield put({
      type: DELETE_FEED_GROUP_LOCK_FAILURE,
      error: errorMessage
    })
  }
}

function* fetchClientFeedInfo() {
  try {
    const response = yield call(() => axios.get('/api/admin/idf-client-feed-info'))
    if (response && response.data) {
      yield put({
        type: FETCH_CLIENT_FEED_INFO_SUCCESS,
        data: response.data
      })
    }
  } catch (error) {
    const errorMessage = `Error fetching client feed info: ${error.message}`
    yield put({
      type: FETCH_CLIENT_FEED_INFO_FAILURE,
      error: errorMessage
    })
  }
}

function* fetchGlobalFeedConfig() {
  try {
    const response = yield call(() => axios.get('/api/feeds/global-config'))
    if (response && response.data) {
      yield put({
        type: FETCH_GLOBAL_FEED_CONFIG_SUCCESS,
        data: response.data
      })
    }
  } catch (error) {
    yield put({
      type: FETCH_GLOBAL_FEED_CONFIG_FAILURE,
      error
    })
    yield put(createAlert('danger', error.message, `Error fetching global feed config`))
  }
}

function* getCreateFeedFlow() {
  yield takeLatest(CREATE_FEED, createFeed)
}

function* fetchFeedsFlow() {
  yield takeLatest(FETCH_FEEDS, fetchFeeds)
  yield takeLatest(FETCH_FEED_DELIVERIES, fetchFeedDeliveries)
  yield takeLatest(FETCH_FEED_DELIVERIES_SUMMARY, fetchFeedDeliveriesSummary)
  yield takeLatest(FETCH_FEED_GROUP_LOCKS, fetchFeedGroupLocks)
  yield takeLatest(DELETE_FEED_GROUP_LOCK, deleteFeedGroupLock)
  yield takeLatest(FETCH_CLIENT_FEED_INFO, fetchClientFeedInfo)
  yield takeLatest(FETCH_GLOBAL_FEED_CONFIG, fetchGlobalFeedConfig)
}

function* fetchFeedFlow() {
  yield takeLatest(FETCH_FEED, fetchFeed)
  yield takeLatest(RESET_FEED, resetFeed)
  yield takeLatest(SAVE_FEED, saveFeed)
  yield takeLatest(SWITCH_TOGGLE_FEED, action => saveFeed(action.feed, false))
  yield takeLatest(CREATE_FIREHOSE_FEED, createFirehoseFeed)
}

export default [fork(getCreateFeedFlow), fork(fetchFeedsFlow), fork(fetchFeedFlow)]
