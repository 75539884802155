// @flow
import { filter, get, isEmpty, keys, intersection, values } from 'lodash'
import moment from 'moment'
import constants from 'constants/constants'
import {
  QC_SPLIT_BY_CHANGED,
  QC_DRILLDOWN_BY_CHANGED,
  QC_QUERY_RUN_STARTED,
  QC_QUERY_RUN_SUCCEEDED,
  QC_QUERY_RUN_FAILED,
  QC_TIME_INTERVAL_CHANGED,
  QC_CHART_VIEW_CHANGED,
  QC_DESCRIPTION_SELECTION_CHANGED,
  QC_SELECTED_TIME_RANGE_CHANGED,
  CHANGE_QC_COLUMNS_VISIBILITY,
  QC_DESCRIPTION_COUNT_SUCCEEDED,
  CHANGE_DESCRIPTION_ITEM_TYPE,
  QC_DESCRIPTION_COUNT_STARTED,
  TOGGLE_DRILLDOWN_ENABLE_STATUS,
  TOGGLE_DESCRIPTION_HISTORY_STATUS
} from '../actions/niq-qc-actions.js'
import { GET_SEARCH_METADATA_SUCCEEDED, APPLY_QUERY } from '../actions/niq-search-actions'
import {
  QC_DESCRIPTION_SAVE_EDITS_FAILED,
  DESCRIPTION_SAVE_EDITS_STARTED,
  QC_DESCRIPTION_SAVE_EDITS_STARTED,
  DESCRIPTION_SAVE_EDITS_FAILED,
  DESCRIPTION_SAVE_EDITS_SUCCEEDED,
  TOGGLE_QC_DESCRIPTION_SEARCH_STATUS,
  DESCRIPTION_EDIT_STATUS_UPDATED,
  FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS,
  FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED,
  FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_FAILED
} from '../actions/niq-widget-actions'
import { LOGOUT_SUCCESS } from 'actions/auth-actions'
import { qcFields } from '../constants/NIQDescriptionTableConfig'
import updateDescription from 'utils/qc-tool-description'

const { aggregations, dateFormat, itemTypes, fieldNames, aggGroups } = constants

const defaultVisibleColumns = filter(qcFields, 'visible', 'false').map(field => field.value)
const initialTopBCMSize = 20 // TODO: use same value for default query load
const initialSearchQCState = {
  splitBy: 'none',
  drillDownBy: null,
  timeInterval: 'month',
  chartView: 'absolute',
  from: moment()
    .subtract(3, 'months')
    .startOf('day')
    .valueOf(), // startOf/endOf day provides consistent data and also helps in ES caching.
  to: moment()
    .endOf('day')
    .valueOf(),
  rangeStart: moment()
    .subtract(3, 'months')
    .startOf('day')
    .format(dateFormat.qcTool),
  rangeEnd: moment()
    .endOf('day')
    .format(dateFormat.qcTool),
  descriptionTypeCounts: {},
  descriptionItemType: itemTypes.BOTH_ITEM,
  drillDownOptions: {
    [aggregations.BRAND]: {
      data: [],
      selectedSize: initialTopBCMSize,
      splitByField: fieldNames.BRAND,
      sortBy: 'revenue',
      sortOrder: 'desc'
    },
    [aggregations.CATEGORY]: {
      data: [],
      selectedSize: initialTopBCMSize,
      splitByField: fieldNames.CATEGORY,
      sortBy: 'revenue',
      sortOrder: 'desc'
    },
    [aggregations.MERCHANT]: {
      data: [],
      selectedSize: initialTopBCMSize,
      splitByField: fieldNames.MERCHANT,
      sortBy: 'revenue',
      sortOrder: 'desc'
    },
    loading: false
  },
  splitByChart: {
    data: {
      revenue: {},
      last_revenue: {}
    },
    loading: false,
    timeInterval: 'month',
    selectedSize: 8
  },
  drillDownChart: {
    data: {},
    loading: false,
    timeInterval: 'month'
  },
  descriptionWithHistory: {
    data: [],
    loading: false,
    selectedSize: 200,
    sortBy: '_count',
    sortOrder: 'desc',
    columns: defaultVisibleColumns
  },
  drilldownEnabledStatus: {
    [aggregations.REVENUE]: true,
    [aggregations.ITEM_COUNT]: true,
    [aggregations.WEIGHT]: false,
    [aggregations.FACTOR]: true,
    [aggregations.QUANTITY]: true,
    [aggregations.PRICE]: true,
    [aggregations.UNITS]: true,
    [aggregations.USER_ID]: false,
    [aggregations.REVENUE]: true
  },
  isDescriptionEnable: false,
  showQCDescSearch: false
}

const getUpdatedDrillDownProps = (state, action) => {
  if (state.drillDownBy !== null && (state.drillDownBy.name === 'none' || action.aggKey === state.drillDownBy.name)) {
    return {
      drillDownBy: null,
      drillDownChart: {
        ...state.drillDownChart,
        data: {}
      },
      descriptionWithHistory: {
        ...state.descriptionWithHistory,
        data: []
      },
      descriptionTypeCounts: {}
    }
  }
}

const initializeSplitByChartData = (state, { params }) => {
  const splitByChartData = state.splitByChart.data
  const queryKey = get(params, ['terms', 0, 'values', 0])
  // Create empty data entries for empty charts to load.
  // The existence of queryKey indicates that the splitByChart !== 'none'
  if (queryKey && params.queryCurrent) {
    splitByChartData.revenue[queryKey] = null
  } else if (queryKey && params.queryPrevious) {
    splitByChartData.last_revenue[queryKey] = null
  } else {
    // when splitBy is 'none'
    splitByChartData.revenue.revenue = params.queryCurrent ? null : splitByChartData.revenue.revenue
    splitByChartData.last_revenue.last_revenue = params.queryPrevious
      ? null
      : splitByChartData.last_revenue.last_revenue
  }
  return splitByChartData
}

const updateSplitByChartData = (state, { params, data }) => {
  const splitByChartData = state.splitByChart.data
  const queryKey = get(params, ['terms', 0, 'values', 0])

  if (queryKey && params.queryCurrent) {
    splitByChartData.revenue[queryKey] = (data && data.revenue[queryKey]) || []
  } else if (queryKey && params.queryPrevious) {
    splitByChartData.last_revenue[queryKey] = (data && data.last_revenue[queryKey]) || []
  } else {
    if (params.queryCurrent) {
      splitByChartData.revenue.revenue =
        data && data.revenue.revenue ? data.revenue.revenue : splitByChartData.revenue.revenue
    } else {
      splitByChartData.last_revenue.last_revenue =
        data && data.last_revenue.last_revenue
          ? data.last_revenue.last_revenue
          : splitByChartData.last_revenue.last_revenue
    }
  }
  return splitByChartData
}

const searchQCReducer = (state: Object = initialSearchQCState, action: Object): Object => {
  switch (action.type) {
    case QC_QUERY_RUN_STARTED:
      let data
      let updatedDrillDownProps
      if (action.aggGroup === aggGroups.DESCRIPTION_WITH_HISTORY) {
        data = []
      } else if (action.aggGroup === aggGroups.SPLIT_BY_CHART) {
        data = {
          ...initializeSplitByChartData(state, action)
        }
        updatedDrillDownProps = getUpdatedDrillDownProps(state, action)
      } else if (action.aggGroup === aggGroups.DRILL_DOWN_CHART) {
        const entryKey = intersection(values(aggregations), keys(action.params))
        const entry = {
          [entryKey[0]]:
            action.params.drillDownBy.name === 'none'
              ? { [entryKey[0]]: null }
              : { [action.params.drillDownBy.name]: null }
        }
        data = {
          ...state.drillDownChart.data,
          ...entry
        }
      } else {
        data = {}
      }
      return {
        ...state,
        [action.aggGroup]: {
          ...state[action.aggGroup],
          data,
          loading: action.loading
        },
        ...updatedDrillDownProps,
        drillDownBy: action.aggGroup === aggGroups.DRILL_DOWN_OPTIONS ? null : state.drillDownBy
      }

    case QC_QUERY_RUN_SUCCEEDED:
      let updatedData = {}
      switch (action.aggGroup) {
        case aggGroups.DRILL_DOWN_OPTIONS:
          updatedData = {}
          for (const aggType of Object.keys(action.data)) {
            const fieldName = state.drillDownOptions[aggType].splitByField
            updatedData[aggType] = {
              ...state.drillDownOptions[aggType],
              data: action.data[aggType].map(item => {
                return {
                  name: item[fieldName],
                  type: aggType,
                  id: item[fieldName],
                  count: item.count,
                  revenue: item.revenue
                }
              })
            }
          }
          return {
            ...state,
            drillDownOptions: {
              ...state.drillDownOptions,
              loading: false,
              ...updatedData
            }
          }

        case aggGroups.SPLIT_BY_CHART:
          return {
            ...state,
            splitByChart: {
              ...state.splitByChart,
              data: {
                ...updateSplitByChartData(state, action)
              },
              loading: false
            }
          }
        case aggGroups.DRILL_DOWN_CHART:
          updatedData = {}
          for (const aggType of Object.keys(action.data)) {
            updatedData[aggType] = isEmpty(action.data[aggType])
              ? { [state.drillDownBy.name]: [] }
              : action.data[aggType]
          }
          return {
            ...state,
            drillDownChart: {
              ...state.drillDownChart,
              data: {
                ...state.drillDownChart.data,
                ...updatedData
              },
              loading: false
            }
          }

        case aggGroups.DESCRIPTION_WITH_HISTORY:
          return {
            ...state,
            descriptionWithHistory: {
              ...state.descriptionWithHistory,
              data: action.data[aggGroups.DESCRIPTION_WITH_HISTORY],
              loading: false
            }
          }
        default:
          return state
      }

    case QC_QUERY_RUN_FAILED:
      switch (action.aggGroup) {
        case constants.aggGroups.DRILL_DOWN_CHART:
          updatedData = {}
          for (const aggType of Object.keys(action.params.aggregationFields)) {
            const queryKey = state.drillDownBy.name !== 'none' ? state.drillDownBy.name : aggType
            updatedData[aggType] = { [queryKey]: [] }
          }
          return {
            ...state,
            drillDownChart: {
              ...state.drillDownChart,
              data: {
                ...state.drillDownChart.data,
                ...updatedData
              },
              loading: false
            }
          }
        default:
          return {
            ...state,
            [action.aggGroup]: {
              ...state[action.aggGroup],
              loading: false
            }
          }
      }

    case QC_DRILLDOWN_BY_CHANGED:
      return {
        ...state,
        drillDownBy: action.value
      }

    case QC_SPLIT_BY_CHANGED:
      return {
        ...state,
        splitBy: action.value,
        drillDownBy: null,
        drillDownChart: {
          ...state.drillDownChart,
          data: {}
        },
        descriptionWithHistory: {
          ...state.descriptionWithHistory,
          data: []
        },
        descriptionTypeCounts: {},
        splitByChart: {
          ...state.splitByChart,
          data: {
            revenue: {},
            last_revenue: {}
          }
        }
      }

    case QC_TIME_INTERVAL_CHANGED:
      return {
        ...state,
        timeInterval: action.value,
        drillDownBy: null,
        drillDownChart: {
          ...state.drillDownChart,
          data: {}
        },
        descriptionWithHistory: {
          ...state.descriptionWithHistory,
          data: []
        },
        descriptionTypeCounts: {}
      }

    case QC_CHART_VIEW_CHANGED:
      return {
        ...state,
        chartView: action.value
      }

    case QC_DESCRIPTION_SELECTION_CHANGED:
      return {
        ...state,
        descriptionWithHistory: {
          ...state.descriptionWithHistory,
          [action.attribute]: action.value
        }
      }

    case QC_SELECTED_TIME_RANGE_CHANGED:
      if (action.from || action.to) {
        return {
          ...state,
          from: moment(parseInt(action.from, 10)).valueOf(),
          to: moment(parseInt(action.to, 10)).valueOf()
        }
      } else {
        return {
          ...state,
          from: moment(state.rangeStart).valueOf(),
          to: moment(state.rangeEnd).valueOf()
        }
      }

    case GET_SEARCH_METADATA_SUCCEEDED:
      return {
        ...state,
        rangeStart: action.data.dateRange.from,
        rangeEnd: action.data.dateRange.to,
        from: moment(action.data.dateRange.from).valueOf(),
        to: moment(action.data.dateRange.to).valueOf(),
        dateRangeInit: action.data.dateRangeInit
      }

    case CHANGE_QC_COLUMNS_VISIBILITY:
      const columns = state.descriptionWithHistory.columns
      const index = columns.indexOf(action.column)
      if (index === -1) {
        columns.push(action.column)
      } else {
        columns.splice(index, 1)
      }
      return {
        ...state,
        descriptionWithHistory: {
          ...state.descriptionWithHistory,
          columns: [...columns]
        }
      }

    case LOGOUT_SUCCESS:
      return initialSearchQCState

    case APPLY_QUERY:
      return {
        ...state,
        drillDownBy: null,
        splitByChart: {
          ...state.splitByChart,
          data: {
            revenue: {},
            last_revenue: {}
          }
        },
        drillDownChart: {
          ...state.drillDownChart,
          data: {}
        },
        descriptionWithHistory: {
          ...state.descriptionWithHistory,
          data: []
        },
        descriptionTypeCounts: {},
        dataIndex: action.payload.dataIndex
      }

    case QC_DESCRIPTION_COUNT_STARTED:
      return {
        ...state,
        descriptionTypeCounts: {
          ...state.descriptionTypeCounts,
          [action.filterType]: null
        }
      }

    case DESCRIPTION_EDIT_STATUS_UPDATED:
      let qcDescriptionData = state.descriptionWithHistory.data
      if (qcDescriptionData && qcDescriptionData.length) {
        for (const key in action.data) {
          if (action.data.hasOwnProperty(key)) {
            const value = action.data[key]
            // update brand values
            qcDescriptionData = updateDescription(qcDescriptionData, key, value.brand, 'brand_id', 'brand_name')
            // update category values
            qcDescriptionData = updateDescription(
              qcDescriptionData,
              key,
              value.category,
              'category_id',
              'category_full_path'
            )
          }
        }
      }
      return {
        ...state,
        descriptionWithHistory: {
          ...state.descriptionWithHistory,
          data: qcDescriptionData
        }
      }

    case QC_DESCRIPTION_COUNT_SUCCEEDED:
      return {
        ...state,
        descriptionTypeCounts: {
          ...state.descriptionTypeCounts,
          [action.filterType]: action.count
        }
      }

    case CHANGE_DESCRIPTION_ITEM_TYPE:
      return {
        ...state,
        descriptionItemType: action.itemType
      }
    case QC_DESCRIPTION_SAVE_EDITS_STARTED:
    case DESCRIPTION_SAVE_EDITS_STARTED:
      return {
        ...state,
        descriptionWithHistory: {
          ...state.descriptionWithHistory,
          loading: true
        }
      }
    case DESCRIPTION_SAVE_EDITS_SUCCEEDED:
      return {
        ...state,
        isLocked: false,
        descriptionWithHistory: {
          ...state.descriptionWithHistory,
          loading: false
        }
      }
    case QC_DESCRIPTION_SAVE_EDITS_FAILED:
    case DESCRIPTION_SAVE_EDITS_FAILED:
      return {
        ...state,
        descriptionWithHistory: {
          ...state.descriptionWithHistory,
          loading: false
        }
      }
    case TOGGLE_DRILLDOWN_ENABLE_STATUS:
      return {
        ...state,
        drilldownEnabledStatus: {
          ...state.drilldownEnabledStatus,
          [action.drillDownType]: !state.drilldownEnabledStatus[action.drillDownType]
        }
      }

    case TOGGLE_DESCRIPTION_HISTORY_STATUS:
      return {
        ...state,
        isDescriptionEnable: !state.isDescriptionEnable
      }

    case TOGGLE_QC_DESCRIPTION_SEARCH_STATUS:
      return {
        ...state,
        showQCDescSearch: !state.showQCDescSearch
      }

    case FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS:
      return {
        ...state,
        descriptionWithHistory: {
          ...state.descriptionWithHistory,
          loading: true
        }
      }

    case FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_SUCCEEDED: {
      return {
        ...state,
        descriptionWithHistory: {
          ...state.descriptionWithHistory,
          loading: action.loading
        }
      }
    }

    case FETCH_QC_DESCRIPTION_EDIT_SUGGESTIONS_FAILED:
      return {
        ...state,
        descriptionWithHistory: {
          ...state.descriptionWithHistory,
          loading: false
        }
      }

    default:
      return state
  }
}

export default searchQCReducer
