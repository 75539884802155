import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { SortableItem, ListItem as UnsortableItem } from './ListItem'
import { SortableContainer } from 'react-sortable-hoc'
import cx from 'classnames'

/**
 * Dumb sortable list to display all the Filters
 */
class ListContainer extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    sorting: PropTypes.bool,
    onItemClick: PropTypes.func,
    className: PropTypes.string,
    emptyMessage: PropTypes.string,
    tooltipText: PropTypes.string
  }

  render() {
    const { items, onItemClick, sorting, className, emptyMessage = '', tooltipText } = this.props

    const ListItem = sorting ? SortableItem : UnsortableItem
    return (
      <React.Fragment>
        <ul className={cx(className)}>
          {items.map((item, index) => (
            <ListItem
              key={index}
              item={item}
              index={index}
              onClick={() => {
                onItemClick(item.id, index)
              }}
              sorting={sorting}
              tooltipText={tooltipText}
            />
          ))}
        </ul>
        {!items.length && <div className="no-value-msg">{emptyMessage}</div>}
      </React.Fragment>
    )
  }
}

export default SortableContainer(ListContainer)
