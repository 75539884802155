import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, FormGroup, FormControl, ControlLabel, InputGroup, Glyphicon } from 'react-bootstrap'

const isTextFieldValid = val => val !== null && val.length > 0
const FieldGroup = ({ id, label, onFormTypeToggle, ...props }) => {
  return (
    <FormGroup controlId={id}>
      <ControlLabel>{label}</ControlLabel>
      <InputGroup>
        <FormControl {...props} />
        <InputGroup.Addon>
          <Glyphicon
            onClick={onFormTypeToggle}
            style={{ cursor: 'pointer' }}
            glyph={props.type === 'password' ? 'eye-open' : 'eye-close'}
          />
        </InputGroup.Addon>
      </InputGroup>
    </FormGroup>
  )
}
FieldGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  onFormTypeToggle: PropTypes.func,
  type: PropTypes.string
}

export default class SetPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onSave = this.onSave.bind(this)
    this.disableSave = this.disableSave.bind(this)
    this.state = {
      formType: {
        adminPassword: 'password',
        userEditPassword: 'password',
        userEditPasswordConfirm: 'password'
      },
      values: {
        adminPassword: null,
        userEditPassword: null,
        userEditPasswordConfirm: null
      }
    }
  }

  onChange(key, event) {
    event.preventDefault()
    this.setState({
      values: {
        ...this.state.values,
        [key]: event.target.value
      }
    })
  }

  onChangeType(key, event) {
    event.preventDefault()
    this.setState({
      formType: {
        ...this.state.formType,
        [key]: this.state.formType[key] === 'password' ? 'text' : 'password'
      }
    })
  }

  onSave(event) {
    event.preventDefault()
    const { adminPassword, userEditPassword } = this.state.values
    this.props.onSave({
      adminPassword,
      userEditPassword,
      userEditId: this.props.userEdit.id
    })
    this.props.onClose()
  }

  disableSave() {
    const { adminPassword, userEditPassword, userEditPasswordConfirm } = this.state.values
    const notEmpty =
      isTextFieldValid(adminPassword) && isTextFieldValid(userEditPassword) && isTextFieldValid(userEditPasswordConfirm)
    return !notEmpty || !this.doUserPasswordsMatch()
  }

  doUserPasswordsMatch() {
    const { userEditPassword, userEditPasswordConfirm } = this.state.values
    return (
      isTextFieldValid(userEditPassword) &&
      isTextFieldValid(userEditPasswordConfirm) &&
      userEditPassword === userEditPasswordConfirm
    )
  }

  render() {
    return (
      <Form>
        <div>
          <FieldGroup
            id="userEditPassword"
            type={this.state.formType.userEditPassword}
            label={`${this.props.userEdit.fullname}'s new password`}
            placeholder="Enter user password"
            autoComplete="new-password"
            onChange={event => this.onChange('userEditPassword', event)}
            onFormTypeToggle={event => this.onChangeType('userEditPassword', event)}
          />
        </div>
        <div>
          <FieldGroup
            id="userEditPasswordConfirm"
            type={this.state.formType.userEditPasswordConfirm}
            label={`Confirm password for ${this.props.userEdit.fullname} ${
              this.doUserPasswordsMatch() ? '(Passwords Match!)' : ''
            }`}
            placeholder="Confirm user password"
            autoComplete="new-password"
            onChange={event => this.onChange('userEditPasswordConfirm', event)}
            onFormTypeToggle={event => this.onChangeType('userEditPasswordConfirm', event)}
          />
        </div>
        <div>
          <FieldGroup
            id="adminPassword"
            type={this.state.formType.adminPassword}
            label="Enter your password"
            required
            placeholder="Enter your password"
            autoComplete="current-password"
            onChange={event => this.onChange('adminPassword', event)}
            onFormTypeToggle={event => this.onChangeType('adminPassword', event)}
          />
        </div>
        <div>
          <p>
            {`Caution: A notification about this change will be sent to the user.
            It is your responsibility to communicate this change to the user`}
          </p>
        </div>
        <div className="modal-footer">
          <div className="modal-buttons">
            <Button className="btn-cancel" onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button disabled={this.disableSave()} className="btn-ok" onClick={this.onSave}>
              Save
            </Button>
          </div>
        </div>
      </Form>
    )
  }
}

SetPasswordForm.propTypes = {
  adminUser: PropTypes.object,
  userEdit: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
