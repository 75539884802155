import { takeLatest, call, put, fork, select } from 'redux-saga/effects'
import axios from 'axios'
import config from '../../../config'

import {
  // modules
  setSelectedFeedModuleAction,
  setEditedFeedModuleAction,
  setFeedModuleLoadingAction,
  fetchFeedModulesAction,
  fetchFeedModulesAndSelectAction,
  successLoadFeedModulesAction,
  saveFeedModuleAction,
  successSaveFeedModuleAction,
  removeFeedModuleAction,
  successRemoveFeedModuleAction,
  // queries
  setFeedModuleQueryLoadingAction,
  fetchFeedModuleQueriesAction,
  successLoadFeedModuleQueriesAction,
  saveFeedModuleQueryAction,
  successSaveFeedModuleQueryAction,
  setOpenNewFeedModuleQueryAction,
  removeFeedModuleQueryAction,
  successRemoveFeedModuleQueryAction
} from './actions'

import { createAlert } from '../../../actions/app-actions'

const getFeedModules = state => state.feedModules.data

function* fetchFeedModulesSaga({ payload = {}, settings }) {
  try {
    yield put(setFeedModuleLoadingAction(true))
    const { data } = yield call(() => axios.get(`${config.apiBaseUrl}/admin/feed/modules`, {}))
    yield put(successLoadFeedModulesAction(data))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.response.data.message || error.message, `Data Feed Modules fetch failed`))
  } finally {
    yield put(setFeedModuleLoadingAction(false))
  }
}

function* fetchFeedModulesAndSelectSaga({ payload = {}, settings }) {
  try {
    yield call(fetchFeedModulesSaga, {})
    const data = yield select(getFeedModules)
    const selectedFeedModule = data.rows.find(m => m.id === payload.selectedFeedModuleValue)
    yield put(setSelectedFeedModuleAction({ value: selectedFeedModule.id, label: selectedFeedModule.name }))
    yield put(setEditedFeedModuleAction(selectedFeedModule))
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data.message || error.message, `Failed to fetch and select module`))
  } finally {
    yield put(setFeedModuleLoadingAction(false))
  }
}

function* saveFeedModuleSaga({ payload = {}, settings }) {
  try {
    yield put(setFeedModuleLoadingAction(true))
    const { data } = yield call(() => {
      delete payload.lastUpdatedByUser // we use editorId, with this field server doesn't save properly
      if (payload.isNew) {
        return axios.post(`${config.apiBaseUrl}/admin/feed/modules`, {
          ...payload
        })
      } else {
        return axios.put(`${config.apiBaseUrl}/admin/feed/modules/${payload.id}`, {
          ...payload
        })
      }
    })
    yield put(createAlert('success', '', `Data feed module saved`))
    yield put(successSaveFeedModuleAction(data))

    yield put(setSelectedFeedModuleAction({ value: data.id, label: data.name }))
    yield put(setEditedFeedModuleAction(data))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.response.data.message || error.message, `Data Feed Module save failed`))
  } finally {
    yield put(setFeedModuleLoadingAction(false))
  }
}

function* removeFeedModulesSaga({ payload = {}, settings }) {
  try {
    yield put(setFeedModuleLoadingAction(true))

    yield call(() => axios.delete(`${config.apiBaseUrl}/admin/feed/modules/${payload.id}`))
    yield put(createAlert('success', '', `Data Feed Module was removed!`))
    yield put(successRemoveFeedModuleAction({ id: payload.id }))
    yield put(setSelectedFeedModuleAction(null))
    yield put(setEditedFeedModuleAction(null))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.response.data.message || error.message, `Data Feed Modules removing failed`))
  } finally {
    yield put(setFeedModuleLoadingAction(false))
  }
}

// QURIES
function* fetchFeedModuleQueriesSaga({ payload = {}, settings }) {
  try {
    yield put(setFeedModuleQueryLoadingAction(true))

    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/admin/feed/module-queries`, {
        params: {
          ...payload
        }
      })
    )
    yield put(successLoadFeedModuleQueriesAction(data))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.response.data.message || error.message, `Data Feed Modules fetch failed`))
  } finally {
    yield put(setFeedModuleQueryLoadingAction(false))
  }
}

function* saveFeedModuleQuerySaga({ payload = {}, settings }) {
  try {
    yield put(setFeedModuleQueryLoadingAction(true))

    const isNew = !payload.id
    const { data } = yield call(() => {
      if (isNew) {
        return axios.post(`${config.apiBaseUrl}/admin/feed/module-queries`, {
          ...payload
        })
      } else {
        return axios.put(`${config.apiBaseUrl}/admin/feed/module-queries/${payload.id}`, {
          ...payload
        })
      }
    })
    if (isNew) {
      yield put(setOpenNewFeedModuleQueryAction(false))
      yield put(createAlert('success', '', `Data Feed Module Query created`))
    } else {
      yield put(createAlert('success', '', `Data Feed Module Query updated`))
    }
    yield put(successSaveFeedModuleQueryAction(data))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.response.data.message || error.message, `Data Feed Module save failed`))
  } finally {
    yield put(setFeedModuleQueryLoadingAction(false))
  }
}

function* removeFeedModuleQuerySaga({ payload = {}, settings }) {
  try {
    yield put(setFeedModuleQueryLoadingAction(true))
    yield call(() => axios.delete(`${config.apiBaseUrl}/admin/feed/module-queries/${payload.id}`))
    yield put(createAlert('success', '', `Data Feed Module Query was removed!`))
    yield put(successRemoveFeedModuleQueryAction({ id: payload.id }))
  } catch (error) {
    window.captureException(error)

    yield put(
      createAlert('danger', error.response.data.message || error.message, `Data Feed Module Query removing failed`)
    )
  } finally {
    yield put(setFeedModuleQueryLoadingAction(false))
  }
}

function* fetchFeedModulesFlow() {
  // MODULES
  yield takeLatest(fetchFeedModulesAction.type, fetchFeedModulesSaga)
  yield takeLatest(fetchFeedModulesAndSelectAction.type, fetchFeedModulesAndSelectSaga)
  yield takeLatest(saveFeedModuleAction.type, saveFeedModuleSaga)
  yield takeLatest(removeFeedModuleAction.type, removeFeedModulesSaga)
  // QUERIES
  yield takeLatest(fetchFeedModuleQueriesAction.type, fetchFeedModuleQueriesSaga)
  yield takeLatest(saveFeedModuleQueryAction.type, saveFeedModuleQuerySaga)
  yield takeLatest(removeFeedModuleQueryAction.type, removeFeedModuleQuerySaga)
}

export default [fork(fetchFeedModulesFlow)]
