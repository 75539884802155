import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import Box from '../Box'
import Input from '../Input'
import CheckBox from '../CheckBox'
import ThreeWayCheckbox from '../ThreeWayCheckbox'
import ArrowDownIcon from '../Icon/ArrowDown'
import ArrowUpIcon from '../Icon/ArrowUp'
import SearchIcon from '../Icon/SearchIcon'
import cx from 'classnames'
import { isEqual } from 'lodash'
import './index.scss'

export default class ReportsPanel extends Component {
  constructor(props) {
    super(props)

    const selectedReports = props.selectedReports && props.selectedReports[0] ? props.selectedReports[0] : {}

    const expanded =
      props.alwaysExpanded || (selectedReports && Object.values(selectedReports).length > 0)
        ? true
        : props.disabledSelected

    this.state = {
      expanded,
      search: '',
      selectedReports
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedReports } = prevState
    if (!isEqual(this.props.selectedReports['0'], selectedReports)) {
      this.setState({
        ...prevState,
        selectedReports: prevProps.selectedReports['0']
      })
    }
  }

  _textDidChange = () => value => {
    this.setState({ search: value.toLowerCase() })
  }

  _didClickClearButton = () => () => {
    this.setState({ ...this.state, search: '' })
  }

  onReportClick = reportId => checked => {
    const reportListId = 0
    const selectedReports = this.state.selectedReports
    if (checked) {
      selectedReports[reportId] = true
    } else {
      delete selectedReports[reportId]
    }
    this.props.onChangeReports(reportListId, selectedReports)
    this.setState({ ...this.state, selectedReports })
  }

  handleSelectReport = all => checked => {
    const reportId = 0
    let selectedReports = {}
    const filteredReports = this.getFilteredReports()
    if (checked) {
      selectedReports = all
        ? filteredReports.reduce((acc, report) => {
            acc[report.id] = true
            return acc
          }, {})
        : {}
    }

    const productReportCount = Object.values(filteredReports).filter(p => selectedReports[p.id] === true).length

    let toggle = false
    if (productReportCount > 0) toggle = true
    this.props.onChangeReports(reportId, selectedReports)
    this.setState({
      ...this.state,
      expanded: this.props.alwaysExpanded || toggle,
      selectedReports
    })
  }

  getFilteredReports = () => {
    if (!this.props.report) {
      return []
    }
    return Object.values(this.props.report).filter(
      value => value.name.toLowerCase().indexOf(this.state.search || '') > -1
    )
  }

  getCheckedStatus = () => {
    const filteredReports = this.getFilteredReports()
    const productReportCount = Object.values(filteredReports).filter(p => {
      return this.state.selectedReports && this.state.selectedReports[p.id] === true
    }).length
    if (productReportCount === 0) {
      return 'none'
    }

    if (productReportCount === Object.keys(filteredReports).length) {
      return 'all'
    }

    return 'partial'
  }

  /**
   * Toggle display/hiding of a reports list
   */
  toggleSelectReport = () => {
    const toggle = !this.state.expanded
    this.setState({
      ...this.state,
      expanded: this.props.alwaysExpanded || toggle
    })
  }

  getReportsList = reports => {
    const list = []
    for (let i = 0; i < reports.length; i += 2) {
      const report = (
        <div key={i}>
          <Col md={4} lg={4}>
            {
              <CheckBox
                className="checkboxInline"
                valueDidChange={this.onReportClick(reports[i].id)}
                checked={this.state.selectedReports && this.state.selectedReports[reports[i].id]}
              />
            }
            {reports[i].name}
          </Col>

          <Col md={4} lg={4}>
            {i + 1 < reports.length && (
              <CheckBox
                className="checkboxInline"
                valueDidChange={this.onReportClick(reports[i + 1].id)}
                checked={this.state.selectedReports && this.state.selectedReports[reports[i + 1].id]}
              />
            )}
            {reports[i + 1] && reports[i + 1].name}
          </Col>
        </div>
      )
      list.push(report)
    }
    return list
  }

  render() {
    return (
      <div className={cx({ 'disable-panel': this.props.disabled })}>
        {
          <div className={cx('clearfix', 'products')}>
            <div className={cx('productHeader', 'clearfix')}>
              {!this.props.disabledSelected && !this.props.alwaysExpanded && (
                <ThreeWayCheckbox
                  valueDidChange={this.handleSelectReport(true)}
                  className="checkboxInline"
                  checkedStatus={this.getCheckedStatus()}
                />
              )}
              <span className="productTitle">{this.props.productTitle}</span>
              {
                <span className="productShow" onClick={this.toggleSelectReport.bind(this)}>
                  {!this.props.alwaysExpanded &&
                    (this.state.expanded ? (
                      <ArrowUpIcon width={32} height={32} />
                    ) : (
                      <ArrowDownIcon width={32} height={32} />
                    ))}
                </span>
              }
              {(this.props.alwaysExpanded || this.state.expanded) && (
                <div className="productContent">
                  <Box style={{ padding: '5px 10px 0px' }} className="CheckBoxMultiSelect alignItemsCenter">
                    <Input
                      clearButton
                      className={cx('input', 'flex1')}
                      placeholder="Search reports"
                      value={this.state.search}
                      iconName={<SearchIcon width={24} height={24} />}
                      didClickClearButton={this._didClickClearButton()}
                      textDidChange={this._textDidChange()}
                    />
                  </Box>

                  <div className="listOptionsGroup clearfix">
                    {<Row>{this.getReportsList(this.getFilteredReports())}</Row>}
                  </div>
                </div>
              )}
            </div>
          </div>
        }
      </div>
    )
  }
}

ReportsPanel.propTypes = {
  disabled: PropTypes.bool,
  alwaysExpanded: PropTypes.bool,
  productTitle: PropTypes.string,
  report: PropTypes.array.isRequired,
  disabledSelected: PropTypes.bool,
  selectedReports: PropTypes.object.isRequired,
  onChangeReports: PropTypes.func.isRequired
}

ReportsPanel.defaultProps = {
  productTitle: 'Custom Reports',
  disabled: false,
  alwaysExpanded: false
}
