import React, { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * IconBase component
 */

class IconBase extends Component {
  render() {
    const styles = {
      verticalAlign: 'middle'
    }

    const props = {
      fill: 'currentColor',
      width: this.props.size,
      height: this.props.size
    }

    return (
      <svg {...props} {...this.props} preserveAspectRatio="xMidYMid meet" style={{ ...styles, ...this.props.style }}>
        {this.props.children}
      </svg>
    )
  }
}

IconBase.propTypes = {
  children: PropTypes.element,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object
}

export default IconBase
