import { FETCH_FOLDER, FETCH_FOLDER_ERROR, FETCH_FOLDER_SUCCESS } from '../actions/report-folder-actions'
import { sortBy } from 'lodash'

const initialState = {
  currFolderId: 0,
  currFolder: {},
  subfolders: [],
  reports: [],
  loading: false,
  error: null
}

const reportFolderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FOLDER:
      return {
        ...state,
        currFolderId: action.folderId,
        loading: true
      }
    case FETCH_FOLDER_SUCCESS:
      const { id, subfolders, reports, ...others } = action.payload
      return {
        ...state,
        loading: false,
        currFolderId: id,
        subfolders: sortBy(subfolders, ['name']),
        reports: sortBy(reports, ['name']),
        currFolder: {
          id,
          ...others
        }
      }
    case FETCH_FOLDER_ERROR:
      return {
        loading: false,
        error: action.error || true
      }
    default:
      return state
  }
}

export default reportFolderReducer
