export const FETCH_ITEM_ATTRIBUTES = 'FETCH_ITEM_ATTRIBUTES'
export const FETCH_ITEM_ATTRIBUTES_SUCCESS = 'FETCH_ITEM_ATTRIBUTES_SUCCESS'
export const FETCH_ITEM_ATTRIBUTES_FAILED = 'FETCH_ITEM_ATTRIBUTES_FAILED'

export function fetchItemAttributes(moduleId) {
  return {
    type: FETCH_ITEM_ATTRIBUTES,
    moduleId
  }
}

export function fetchItemAttributesSuccess(payload) {
  return {
    type: FETCH_ITEM_ATTRIBUTES_SUCCESS,
    payload
  }
}

export function fetchItemAttributesFailed(error) {
  return {
    type: FETCH_ITEM_ATTRIBUTES_FAILED,
    error
  }
}
