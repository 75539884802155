import {
  QUERY_RUN_FAILED,
  QUERY_RUN_SUCCEEDED,
  DELETE_QUERY_FAILED,
  DELETE_GROUP_FAILED,
  CREATE_GROUP_FAILED,
  DELETE_QUERY_SUCCEEDED,
  DELETE_GROUP_SUCCEEDED,
  SAVE_SEARCH_QUERY_FAILED,
  SAVE_SEARCH_QUERY_SUCCEEDED,
  QUERY_STATS_FAILED,
  CREATE_QUERY_SUCCEEDED,
  GET_SEARCH_METADATA_FAILED
} from '../actions/search-actions'

import {
  DESCRIPTION_SAVE_EDITS_FAILED,
  DESCRIPTION_SAVE_EDITS_SUCCEEDED,
  DESCRIPTION_EDIT_STATUS_UPDATE_FAILED,
  DESCRIPTION_SAVE_EDITS_STARTED,
  QC_DESCRIPTION_SAVE_EDITS_STARTED
} from '../actions/widget-actions'

import {
  REPORT_REPLICATE_END,
  REPLICATE_REPORT_START,
  REPORT_REPLICATE_ERROR
} from '../actions/custom-report-actions.js'

import { ALERT_CREATE, ALERT_DISMISS } from '../actions/app-actions'
import { QC_QUERY_RUN_FAILED } from '../actions/qc-actions.js'
import { LOGOUT_SUCCESS } from '../actions/auth-actions'

import {
  SUPRA_QC_ADD_TO_DICT_FAILED,
  SUPRA_QC_ADD_TO_DICT_QUERY_FAILED,
  SUPRA_QC_ADD_TO_DICT_SUCCEEDED,
  SUPRA_QC_FETCH_DICT_ENTRIES_FAILED
} from '../actions/supra-qc-tool-actions'

/*
  alert: {
    id: 1, //Required
    type: 'info' | 'danger' | 'success' | 'warning'
    message: '', //Required
    headline: ''
  }
*/

const initialState = {
  alerts: []
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_QUERY_SUCCEEDED:
    case QUERY_RUN_SUCCEEDED:
    case QUERY_RUN_FAILED:
    case QC_QUERY_RUN_FAILED:
    case DESCRIPTION_SAVE_EDITS_STARTED:
    case QC_DESCRIPTION_SAVE_EDITS_STARTED:
    case DESCRIPTION_SAVE_EDITS_FAILED:
    case DESCRIPTION_SAVE_EDITS_SUCCEEDED:
    case DESCRIPTION_EDIT_STATUS_UPDATE_FAILED:
    case SAVE_SEARCH_QUERY_SUCCEEDED:
    case SAVE_SEARCH_QUERY_FAILED:
    case GET_SEARCH_METADATA_FAILED:
    case DELETE_QUERY_SUCCEEDED:
    case DELETE_QUERY_FAILED:
    case DELETE_GROUP_SUCCEEDED:
    case DELETE_GROUP_FAILED:
    case CREATE_GROUP_FAILED:
    case REPLICATE_REPORT_START:
    case REPORT_REPLICATE_END:
    case REPORT_REPLICATE_ERROR:
    case QUERY_STATS_FAILED:
    case ALERT_CREATE:
    case SUPRA_QC_ADD_TO_DICT_FAILED:
    case SUPRA_QC_ADD_TO_DICT_QUERY_FAILED:
    case SUPRA_QC_ADD_TO_DICT_SUCCEEDED:
    case SUPRA_QC_FETCH_DICT_ENTRIES_FAILED:
      if (!action.alerts) {
        return state
      }
      return {
        ...state,
        alerts: [...state.alerts, ...action.alerts],
        alertTimeout: action.alertTimeout
      }

    case 'FORM_VALUES_UPDATE':
      return {
        ...state,
        form: {
          ...state.form,
          [action.formName]: {
            values: action.values
          }
        }
      }

    case ALERT_DISMISS:
      return {
        ...state,
        alerts: state.alerts.filter(alert => alert.id !== action.id)
      }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default appReducer
