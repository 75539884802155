import { takeLatest, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'
import { createAlert } from 'actions/app-actions'
import config from '../../../config'

import { fetchMerchantFields, successLoadMerchantFields, faliedLoadMerchantFields } from './merchantSlice'

function* fetchFields({ payload = {} }) {
  try {
    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/merchant/fields`, {
        params: { ...payload }
      })
    )

    yield put(successLoadMerchantFields(data))
  } catch (error) {
    window.captureException(error)
    yield put(faliedLoadMerchantFields())

    yield put(createAlert('danger', error.response.data.message || error.message, `Merchant fields fetch failed`))
  }
}

function* merchantsFlow() {
  yield takeLatest(fetchMerchantFields.type, fetchFields)
}

export default [fork(merchantsFlow)]
