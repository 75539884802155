import {
  FETCH_L1_CATEGORIES,
  FETCH_L1_CATEGORIES_SUCCESS,
  FETCH_L1_CATEGORIES_FAILED
} from '../actions/category-actions'

import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialState = {}

const l1CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_L1_CATEGORIES:
      return { isLoading: true }

    case FETCH_L1_CATEGORIES_SUCCESS:
      return { data: action.payload }

    case FETCH_L1_CATEGORIES_FAILED:
      return { error: action.error }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default l1CategoryReducer
