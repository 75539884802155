import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import Loader from '../Loader'
import Table from '../Table/ReactVirtualizedTable'

import { updateGroupForm } from '../../actions/group-edit-actions'

import { products as PRODUCTS } from '../../constants/constants'

// Only show products not in other tabs
const validProducts = {
  [PRODUCTS.adminToolsFeeds]: true,
  [PRODUCTS.adminToolsQCTool]: true,
  [PRODUCTS.adminToolsJobMonitoring]: true,
  [PRODUCTS.adminToolsDeliveryManager]: true,
  [PRODUCTS.NIQqcTool]: true
}

class AdminToolsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: {}
    }
  }

  componentDidUpdate(prevProps) {
    if (isEmpty(this.state.checked) && isEmpty(prevProps.groupProducts) && !isEmpty(this.props.groupProducts)) {
      this.setState({ checked: this.props.groupProducts })
    }
  }

  onCheckedValueChange = (key, val) => {
    const newState = this.updateState(key, val)
    this.setState(newState)
    this.props.updateGroupForm('products', newState.checked)
  }

  updateState(key, val) {
    return {
      checked: {
        ...this.state.checked,
        [key]: val
      }
    }
  }

  getColumns = () => {
    const columns = ['name', 'description', 'key', 'enabled']

    return columns.map(column => {
      return {
        key: column,
        label: column,
        type: column === 'enabled' ? 'checkbox' : ''
      }
    })
  }

  getRows = () =>
    this.props.products.map(product => {
      product.enabled = this.state.checked[product.key]
      return product
    })

  render() {
    const { loading, isPortalGroup, products } = this.props

    if (isPortalGroup) {
      return <span className="group-note">{'This is a Portal Group. You cannot edit settings for this group.'}</span>
    }

    if (loading) {
      return <Loader />
    }

    return (
      <div className="admin-tools-table">
        <Table
          height={500}
          data={this.getRows()}
          enableSearch={false}
          fetchData={() => {}}
          columns={this.getColumns()}
          remoteRowCount={products.length}
          onCheckedValueChange={this.onCheckedValueChange}
        />
      </div>
    )
  }
}

AdminToolsTable.propTypes = {
  loading: PropTypes.bool,
  groupId: PropTypes.string,
  products: PropTypes.array,
  isPortalGroup: PropTypes.bool,
  updateGroupForm: PropTypes.func,
  groupProducts: PropTypes.object
}

AdminToolsTable.defaultProps = {
  products: []
}

const mapStateToProps = ({ session: { products }, groupEdit: { basicInfo } }) => {
  const productsToDisplay = Object.values(products).filter(product => validProducts[product.key])

  const groupProducts = {}
  Object.values(basicInfo.Products).forEach(product => {
    if (validProducts[product.key]) {
      groupProducts[product.key] = true
    }
  })

  return {
    groupProducts,
    products: productsToDisplay
  }
}

export default connect(mapStateToProps, { updateGroupForm })(AdminToolsTable)
