import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'
import TextInputField from './components/TextInputField'
import FolderSelectPanel from './components/FolderSelectPanel'
import KeySelect from './components/KeySelect'
import './index.scss'
import { updateBasicDetails, fetchReportFolders } from '../../actions/custom-report-edit-actions'

class BasicDetails extends Component {
  updateFormValue = (key, value) => {
    this.props.updateBasicDetails({
      [key]: value,
      dirty: true,
      ...this.getValidationDetails(key, value)
    })
  }

  getValidationDetails(key, value) {
    const validationKeyMap = { name: 'isNameValid' }
    const validationKey = validationKeyMap[key]
    if (validationKey) return { [validationKey]: this.isValid(key, value) }
    return {}
  }

  isValid(key, value = '') {
    switch (key) {
      case 'name':
        value = value.trim()
        return !!value
      default:
        return false
    }
  }

  render() {
    const {
      name = '',
      folderId = 0,
      baseTableName = '',
      baseTableValue = '',
      isNameValid,
      dirty,
      folders,
      fetchReportFolders,
      apiKey
    } = this.props

    return (
      <Form horizontal className={dirty ? 'dirty' : ''}>
        <h4> Basic Details </h4>
        <TextInputField
          autoFocus
          id="reportName"
          label="Name"
          type="text"
          placeholder="Enter name"
          value={name}
          validationState={isNameValid === false ? 'error' : null}
          onChange={event => this.updateFormValue('name', event.target.value)}
          help={isNameValid === false ? 'Report name cannot be empty' : null}
          required
        />
        <FolderSelectPanel
          folderId={folderId}
          folders={folders}
          updateFormValue={this.updateFormValue}
          fetchReportFolders={fetchReportFolders}
        />
        <KeySelect updateFormValue={this.updateFormValue} apiKey={apiKey} />
        <TextInputField
          id="baseTableName"
          label="Table Filter Name (PS)"
          type="text"
          placeholder="Enter Periscope table filter name"
          value={baseTableName}
          onChange={event => this.updateFormValue('baseTableName', event.target.value)}
        />
        <TextInputField
          id="baseTableValue"
          label="Table Filter Value"
          type="text"
          placeholder="Enter database table name"
          value={baseTableValue}
          onChange={event => this.updateFormValue('baseTableValue', event.target.value)}
        />
      </Form>
    )
  }
}

BasicDetails.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  folderId: PropTypes.number,
  folders: PropTypes.object,
  baseTableName: PropTypes.string,
  baseTableValue: PropTypes.string,
  dirty: PropTypes.bool,
  isNameValid: PropTypes.bool,
  updateBasicDetails: PropTypes.func,
  fetchReportFolders: PropTypes.func,
  apiKey: PropTypes.string
}

function mapStateToProps({ reportEdit }) {
  const {
    basicDetailsForm: { id, name, baseTableName, baseTableValue, dirty, isNameValid, folderId, apiKey },
    folders
  } = reportEdit
  return { id, name, baseTableName, baseTableValue, dirty, isNameValid, folderId, folders, apiKey }
}

export default connect(mapStateToProps, { updateBasicDetails, fetchReportFolders })(BasicDetails)
