import moment from 'moment'

export default function parseDictionaryCoverageResult(result) {
  const xAxis = result.map(data => moment.utc(data.monthYear).format("MMM 'YY"))
  const yAxisDict = result.map(data => data.dictCount)
  const yAxisNonDict = result.map(data => data.nonDictCount)
  const parsedResult = {
    dictionary_coverage: {
      xAxis,
      yAxis: [
        {
          name: 'Non Dictionary',
          data: yAxisNonDict,
          color: '#f18e7b'
        },
        {
          name: 'Dictionary',
          data: yAxisDict,
          color: '#65bfcc'
        }
      ]
    }
  }
  return parsedResult
}
