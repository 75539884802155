import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import DropDownButton from '../DropDownButton/DropDownButton'
import Profile from '../Icon/Profile'
import HelpButton from '../Walkthrough/HelpButton'
import { HEADER_HEIGHT } from '../../constants/constants'
// load tenant specific file
// eslint-disable-next-line
import logo from './logo-APP_TENANT.png'
import './index.scss'

const Header = props => {
  const dropDownOptions = {}

  const onReportsPage = props.pathname.includes('/custom/reports')

  const allStyleProps = {
    width: 30,
    height: 30,
    fill: '#4C4C4C',
    style: { marginTop: 12 }
  }

  if (props.user && props.user.isOnboarded) {
    // Route to the current user profile
    dropDownOptions.profile = { key: 'profile', name: 'Profile', href: '/profile' }
  }
  dropDownOptions.logout = { key: 'logout', name: 'Log Out', onClick: props.onLogout }

  return (
    <div className="header alignItemsCenter flex" style={{ height: `${HEADER_HEIGHT}px` }}>
      <div className="extendedHeader alignItemsCenter flex">
        <Link to="/">
          <img className="logo" src={logo} alt="header_logo" />
        </Link>
        <div className="flex1" />
        {onReportsPage && props.user && <HelpButton />}
        {props.user && (
          <DropDownButton
            className="dropDownButton--header"
            options={dropDownOptions}
            menuListClass="user-header-dropdown"
            icon={<Profile width="32" height="32" {...allStyleProps} />}
            label={<span className="medium profilePadding">{props.user.fullname}</span>}
          />
        )}
      </div>
    </div>
  )
}

Header.propTypes = {
  user: PropTypes.object,
  onLogout: PropTypes.func,
  isWalkthroughEnabled: PropTypes.bool,
  pathname: PropTypes.string
}

export default Header
