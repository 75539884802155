import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './index.scss'

class PaginationControls extends Component {
  static propTypes = {
    onPageChange: PropTypes.func.isRequired,
    maxPages: PropTypes.number,
    page: PropTypes.number,
    totalCount: PropTypes.number,
    entityName: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      invalidInput: null
    }
  }

  handleOnPrevClick = () => {
    const { page: currentPage } = this.props
    const nextPage = currentPage > 1 ? currentPage - 1 : 1
    this.props.onPageChange(nextPage)
  }

  handleOnNextClick = () => {
    const { page: currentPage } = this.props
    let { maxPages } = this.props
    maxPages = maxPages || 1
    const nextPage = currentPage < maxPages ? currentPage + 1 : maxPages
    this.props.onPageChange(nextPage)
  }

  inputValueChange = event => {
    let { value } = event.target
    if (value.length > 0 && isNaN(value)) {
      return
    }
    let valid = false
    let { maxPages } = this.props
    maxPages = maxPages || 1
    if (value && value.length > 0) {
      value = parseInt(value, 10)
      if (value > maxPages) {
        value = maxPages
      }
      if (this.props.onPageChange) {
        valid = true
        this.props.onPageChange(value)
      }
    }
    this.setState({ invalidInput: valid ? null : value })
  }

  render() {
    const { page, totalCount, entityName } = this.props
    let { maxPages } = this.props
    maxPages = maxPages || 1
    const { invalidInput } = this.state
    return (
      <div className="pagination-wrapper">
        <span
          className={cx('glyphicon glyphicon-menu-left prev', {
            disabled: page <= 1
          })}
          onClick={this.handleOnPrevClick}
        />
        <span>
          <input
            type="text"
            className="page-number"
            value={invalidInput === null ? page : invalidInput}
            onChange={this.inputValueChange}
          />
          {` of ${maxPages}`}
        </span>
        <span
          className={cx('glyphicon glyphicon-menu-right next', {
            disabled: page >= maxPages
          })}
          onClick={this.handleOnNextClick}
        />
        {(totalCount || totalCount === 0) && (
          <span className="ml-2">
            Total: {totalCount} {entityName || 'rows'}
          </span>
        )}
      </div>
    )
  }
}

export default PaginationControls
