import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './index.scss'
import tableChart from './images/tableChart.png'

const tooltip = {
  width: 235,
  height: 75
}

const BeaconModal = props => {
  const getTemplate = beaconKey => {
    if (beaconKey === 'left-nav') {
      return <div className="beacon-modal-content">{props.content}</div>
    } else if (beaconKey === 'periscope-filters') {
      return (
        <div className="beacon-modal-content">
          To look at different charts we can create with your data, select the report tabs and filters at the top of the
          page.
          <br />
          <br />
          Click the <strong>Apply</strong> button to make the changes to your dashboard view.
        </div>
      )
    } else {
      return (
        <div>
          <div className="chartPill">Charts</div>

          <div className="chartContent">
            {'Go to the top right corner to reveal your '}
            <strong>{'download'}</strong>
            {' option'}
          </div>

          <div className="tablePill">Tables</div>

          <div className="tableContent">
            <strong>{'Customize the look '}</strong>
            {' of your data table with pivot tables'}
          </div>

          <div className="tableChartDiv">
            <img src={tableChart} className="tableChartImg" alt="" />
          </div>
        </div>
      )
    }
  }

  const stylePos = {
    top: props.modalTop,
    left: props.modalLeft,
    position: props.modalPosition,
    width: props.showtoolTip ? tooltip.width : props.width
  }

  if (!props.isOpen || props.id !== props.activeBeacon) return null

  return (
    <div>
      <div
        className={cx(props.arrowStyle, props.arrowLocation)}
        style={{ left: props.arrowLeft, top: props.arrowTop }}
      />

      <div className="beacon-modal" style={stylePos}>
        <div className="beacon-modal-title">{props.header}</div>
        {!props.showtoolTip && getTemplate(props.id)}
        {!props.showtoolTip && (
          <button onClick={props.onButtonClick} className="beacon-modal-button">
            Got it
          </button>
        )}
      </div>
    </div>
  )
}

BeaconModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalTop: PropTypes.number,
  modalLeft: PropTypes.number,
  header: PropTypes.string,
  content: PropTypes.string,
  width: PropTypes.number,
  arrowStyle: PropTypes.string,
  contentStyle: PropTypes.string,
  id: PropTypes.string,
  showtoolTip: PropTypes.bool,
  onButtonClick: PropTypes.func,
  activeBeacon: PropTypes.string,
  style: PropTypes.object,
  modalPosition: PropTypes.string,
  arrowLocation: PropTypes.string,
  arrowTop: PropTypes.number,
  arrowLeft: PropTypes.number
}

export default BeaconModal
