export const FETCH_MAILBOX_PROVIDERS = 'FETCH_MAILBOX_PROVIDERS'
export const FETCH_MAILBOX_PROVIDERS_SUCCESS = 'FETCH_MAILBOX_PROVIDERS_SUCCESS'
export const FETCH_MAILBOX_PROVIDERS_FAILURE = 'FETCH_MAILBOX_PROVIDERS_FAILURE'

export const EXCLUDE_MAILBOX_PROVIDER = 'EXCLUDE_MAILBOX_PROVIDER'
export const EXCLUDE_MAILBOX_PROVIDER_SUCCESS = 'EXCLUDE_MAILBOX_PROVIDER_SUCCESS'
export const EXCLUDE_MAILBOX_PROVIDER_FAILURE = 'EXCLUDE_MAILBOX_PROVIDER_FAILURE'

export const INCLUDE_MAILBOX_PROVIDER = 'INCLUDE_MAILBOX_PROVIDER'
export const INCLUDE_MAILBOX_PROVIDER_SUCCESS = 'INCLUDE_MAILBOX_PROVIDER_SUCCESS'
export const INCLUDE_MAILBOX_PROVIDER_FAILURE = 'INCLUDE_MAILBOX_PROVIDER_FAILURE'

export const fetchMailboxProviders = () => ({
  type: FETCH_MAILBOX_PROVIDERS
})

export function fetchMailboxProvidersSuccess(data) {
  return {
    type: FETCH_MAILBOX_PROVIDERS_SUCCESS,
    data
  }
}

export function fetchMailboxProvidersFailure(error) {
  return {
    type: FETCH_MAILBOX_PROVIDERS_FAILURE,
    error
  }
}

export const excludeMailboxProvider = mailboxProviderId => ({
  type: EXCLUDE_MAILBOX_PROVIDER,
  mailboxProviderId
})

export function excludeMailboxProviderSuccess() {
  return {
    type: EXCLUDE_MAILBOX_PROVIDER_SUCCESS
  }
}

export function excludeMailboxProviderFailure(error) {
  return {
    type: EXCLUDE_MAILBOX_PROVIDER_FAILURE,
    error
  }
}

export const includeMailboxProvider = mailboxProviderId => ({
  type: INCLUDE_MAILBOX_PROVIDER,
  mailboxProviderId
})

export function includeMailboxProviderSuccess() {
  return {
    type: INCLUDE_MAILBOX_PROVIDER_SUCCESS
  }
}

export function includeMailboxProviderFailure(error) {
  return {
    type: INCLUDE_MAILBOX_PROVIDER_FAILURE,
    error
  }
}
