import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Select from 'react-select'
import { Field } from 'redux-form'

import './index.scss'

class renderSelect extends Component {
  static propTypes = {
    input: PropTypes.object,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    meta: PropTypes.object,
    onInputChange: PropTypes.func,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    selectValue: PropTypes.string,
    asyncOptions: PropTypes.func
  }

  render() {
    const {
      input,
      options,
      placeholder,
      meta: { touched, error },
      onInputChange,
      isLoading
    } = this.props
    return (
      <div className="FormSelect__field">
        <Select
          {...input}
          options={options}
          onBlur={() => input.value}
          onChange={event => {
            const newValue = event.value ? event.value : null
            input.onChange(newValue)
          }}
          value={options.find(el => el.value === input.value)}
          placeholder={placeholder}
          onInputChange={onInputChange}
          isLoading={isLoading}
        />
        {touched && error && <span className="FeedEditPage__error">{error}</span>}
      </div>
    )
  }
}

const FormSelect = ({
  label,
  isRequired,
  fieldName,
  placeholder,
  options,
  onInputChange,
  onChange,
  isLoading,
  selectValue,
  asyncOptions
}) => {
  return (
    <div className="FormSelect__container">
      <label className={`${isRequired ? 'required' : ''}`}>{label}</label>

      <Field
        name={fieldName}
        placeholder={placeholder}
        component={renderSelect}
        options={options}
        onInputChange={onInputChange}
        onChange={onChange}
        isLoading={isLoading}
        selectValue={selectValue}
        asyncOptions={asyncOptions}
      />
    </div>
  )
}

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  selectValue: PropTypes.string,
  asyncOptions: PropTypes.func
}

FormSelect.defaultProps = {
  isRequired: false,
  options: []
}

export default FormSelect
