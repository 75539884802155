import { createAction } from '@reduxjs/toolkit'

const RequestsActions = {
  fetch: createAction('FETCH_REQUESTS'),
  successLoad: createAction('SUCCESS_REQUESTS'),
  failedLoad: createAction('FAILED_REQUESTS'),
  updateSettings: createAction('UPDATE_REQUESTS_SETTINGS'),
  clearFilters: createAction('CLEAR_FILTERS_REQUESTS'),

  save: createAction('SAVE_REQUESTS')
}

export default RequestsActions
