import { createTheme } from '@mui/material/styles'
import { alpha } from '@mui/system'

const theme = createTheme()

// https://mui.com/material-ui/customization/theming/
const themeMUI = createTheme({
  components: {
    MuiDialogTitle: {
      defaultProps: {
        fontWeight: 700
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiSelect: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiInputLabel: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true
      },
      styleOverrides: {
        tooltip: {
          fontSize: '1rem',
          boxShadow: theme.shadows[4],
          backgroundColor: alpha(theme.palette.grey[700], 0.96)
        }
      }
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#32aec0'
    },
    secondary: {
      main: '#ee6b50'
    },
    background: {
      default: '#f7f7f7'
    }
  }
})

export default themeMUI
