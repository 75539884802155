import React from 'react'
import { Table } from 'react-bootstrap'

export const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0

export const keyActions = {
  NEXT_FOLDER_ITEM: 'NEXT_FOLDER_ITEM',
  PREV_FOLDER_ITEM: 'PREV_FOLDER_ITEM',
  DELETE_FOLDER_ITEM: 'DELETE_FOLDER_ITEM',
  PARENT_FOLDER: 'PARENT_FOLDER',
  START_RENAME_ITEM: 'START_RENAME_ITEM',
  CANCEL_RENAME_ITEM: 'CANCEL_RENAME_ITEM',
  SAVE_RENAME_ITEM: 'SAVE_RENAME_ITEM',
  OPEN_FOLDER_ITEM: 'OPEN_FOLDER_ITEM'
}
export const folderPageKeyMap = {
  [keyActions.NEXT_FOLDER_ITEM]: ['right', 'down'],
  [keyActions.PREV_FOLDER_ITEM]: ['left', 'up'],
  [keyActions.PARENT_FOLDER]: ['backspace'],
  [keyActions.DELETE_FOLDER_ITEM]: isMac ? { sequence: 'command+backspace', action: 'keypress' } : ['del']
}

export const getFolderItemKeyMap = editing => ({
  [keyActions.START_RENAME_ITEM]: editing ? [] : isMac ? ['enter'] : ['f2'],
  [keyActions.CANCEL_RENAME_ITEM]: editing ? ['escape'] : [],
  [keyActions.SAVE_RENAME_ITEM]: editing ? ['enter'] : [],
  [keyActions.OPEN_FOLDER_ITEM]: editing ? [] : isMac ? ['command+o'] : ['enter']
})

/* Prints key bindings listed above */
export const KeyMapInfo = () => {
  const keyMapText = {
    [keyActions.NEXT_FOLDER_ITEM]: 'Navigate to Next Folder',
    [keyActions.PREV_FOLDER_ITEM]: 'Navigate to Previous Folder',
    [keyActions.PARENT_FOLDER]: 'Navigate to Parent Folder',
    [keyActions.DELETE_FOLDER_ITEM]: 'Delete Selected Folder',
    [keyActions.START_RENAME_ITEM]: 'Rename Folder / Report',
    [keyActions.OPEN_FOLDER_ITEM]: 'Open Folder / Report'
  }
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Action</th>
          <th>Shortcut (⌘/Ctrl +)</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries({
          ...folderPageKeyMap,
          ...getFolderItemKeyMap(false)
        }).map(([action, keyBinding]) => {
          const keyBindingText = Array.isArray(keyBinding) ? keyBinding.join(' / ') : keyBinding && keyBinding.sequence
          if (!keyBindingText) {
            return null
          }
          return (
            <tr key={action}>
              <td> {keyMapText[action]} </td>
              <td>
                {' '}
                <code> {keyBindingText} </code>{' '}
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
// DOM helper methods
export function findFolderParentLi(el) {
  if (el && el.closest) {
    return el.closest('li')
  }
}

export function findFolderItemDetails(folderEl) {
  const parentLi = findFolderParentLi(folderEl)
  if (parentLi && parentLi.id) {
    const [itemType, itemId] = parentLi.id.split('-')
    if (['file', 'folder'].includes(itemType)) {
      return { itemType, itemId }
    }
  }
  return {}
}

export function focusFirstItem() {
  const firstItem = findFirstItem(document)
  if (firstItem) {
    firstItem.focus()
  } else {
    const list = document.querySelector('h2 .folder-item')
    if (list) {
      list.focus()
    }
  }
}

export function findFirstItem(parentEl) {
  if (parentEl) {
    return parentEl.querySelector('ul.sub-folders li .folder-item')
  }
}

export function findNextItem(folderEl) {
  const parentLi = findFolderParentLi(folderEl)
  if (parentLi.nextSibling) {
    return parentLi.nextSibling.querySelector('.folder-item')
  }
}

export function findPreviousItem(folderEl) {
  const parentLi = findFolderParentLi(folderEl)
  if (parentLi.previousSibling) {
    return parentLi.previousSibling.querySelector('.folder-item')
  }
}

export function isFolderItemFocused() {
  const activeEl = document.activeElement
  return activeEl && activeEl.classList.contains('folder-item')
}

export function focusNextFolderItem(event) {
  const { target } = event
  if (!target || target.tagName.toUpperCase() === 'INPUT') {
    return
  }
  const { itemId } = findFolderItemDetails(target)
  if (!itemId) {
    return
  }
  const focusItem = findNextItem(target)
  if (focusItem) {
    focusItem.focus()
    event.stopPropagation()
    event.preventDefault()
  }
}

export function focusPrevFolderItem(event) {
  const { target } = event
  if (!target || target.tagName.toUpperCase() === 'INPUT') {
    return
  }
  const { itemId } = findFolderItemDetails(target)
  if (!itemId) {
    return
  }
  const focusItem = findPreviousItem(target)
  if (focusItem) {
    focusItem.focus()
    event.stopPropagation()
    event.preventDefault()
  }
}
