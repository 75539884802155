import React, { useState } from 'react'
import PropTypes from 'prop-types'
import OutsideClickHandler from 'react-outside-click-handler'
import MenuList from '../MenuList'
import './index.scss'

const DropDownButton = ({ icon, label, options, className, menuListClass, valueDidChange }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className={`dropDownButton ${className}`} onClick={() => setIsOpen(!isOpen)}>
        <div className={`${className + (isOpen ? ' active' : '')} alignItemsCenter flex`}>
          {icon}
          {label}
        </div>
        {isOpen && (
          <div className={`containerMenu bottom center ${className}`}>
            <MenuList options={options} valueDidChange={valueDidChange} className={menuListClass} />
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}

DropDownButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.node,
  valueDidChange: PropTypes.func,
  options: PropTypes.object.isRequired,
  menuListClass: PropTypes.string
}

export default DropDownButton
