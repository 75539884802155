import { takeLatest, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'

import {
  FETCH_PARTNERS,
  fetchPartnersSuccess,
  fetchPartnersFailure,
  EXCLUDE_PARTNER,
  excludePartnerSuccess,
  excludePartnerFailure,
  INCLUDE_PARTNER,
  includePartnerSuccess,
  includePartnerFailure
} from '../actions/partner-actions'
import { fetchGlobalFeedConfig } from '../actions/feed-actions'
import { createAlert } from '../actions/app-actions'

function* fetchPartners() {
  try {
    const response = yield call(() => axios.get(`/api/admin/idf-dimension/partners`))
    yield put(fetchPartnersSuccess(response.data))
  } catch (err) {
    window.captureException(err)
    yield put(fetchPartnersFailure(err))
    yield put(createAlert('danger', err.message, `Error fetching partners`))
  }
}

function* excludePartner(action) {
  try {
    yield call(() =>
      axios.post(`/api/feeds/global-partner-excludes`, {
        partnerId: action.partnerId
      })
    )
    yield put(excludePartnerSuccess())
    yield put(createAlert('success', '', `Partner excluded`))
    yield put(fetchGlobalFeedConfig())
  } catch (err) {
    window.captureException(err)
    yield put(excludePartnerFailure(err))
    yield put(createAlert('danger', err.message, `Error excluding partner`))
  }
}

function* includePartner(action) {
  try {
    yield call(() =>
      axios.delete(`/api/feeds/global-partner-excludes`, {
        data: {
          partnerId: action.partnerId
        }
      })
    )
    yield put(includePartnerSuccess())
    yield put(createAlert('success', '', `Partner included`))
    yield put(fetchGlobalFeedConfig())
  } catch (err) {
    window.captureException(err)
    yield put(includePartnerFailure(err))
    yield put(createAlert('danger', err.message, `Error including partner`))
  }
}

function* fetchPartnersFlow() {
  yield takeLatest(FETCH_PARTNERS, fetchPartners)
}

function* excludePartnerFlow() {
  yield takeLatest(EXCLUDE_PARTNER, excludePartner)
}

function* includePartnerFlow() {
  yield takeLatest(INCLUDE_PARTNER, includePartner)
}

export default [fork(fetchPartnersFlow), fork(excludePartnerFlow), fork(includePartnerFlow)]
