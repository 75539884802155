import React from 'react'

import { products } from 'constants/constants'
import usePermissions from './../usePermissions'
import SubMenuItem from './../SubMenuItem'

const SupraSubMenu = () => {
  const { isPortalAdmin, hasAdminToolsAccess, isProductAuthorized } = usePermissions()

  const menuItems = [
    {
      label: 'Supra QC Tool',
      route: '/supra/supra-qc-tool',
      isAuthorized: (isPortalAdmin || hasAdminToolsAccess) && isProductAuthorized(products.adminToolsSupraQCTool)
    },
    {
      label: 'Monitoring',
      route: '/supra/monitoring',
      info:
        'Page with statistical SupraQC data in charts: receipts vs. non-receipts & receipt categories & supra vs. rules count, receipt (need to enable Slice VPN)',
      isAuthorized: (isPortalAdmin || hasAdminToolsAccess) && isProductAuthorized(products.adminToolsSupraQCTool)
    },
    {
      label: 'Taxonomy',
      route: '/supra/taxonomy',
      isAuthorized: (isPortalAdmin || hasAdminToolsAccess) && isProductAuthorized(products.adminToolsSupraQCTool)
    },
    {
      label: 'Orchestrator',
      route: '/supra/orchestrator',
      isAuthorized: (isPortalAdmin || hasAdminToolsAccess) && isProductAuthorized(products.adminToolsSupraQCTool)
    }
  ]

  return (
    <>
      {menuItems.map(
        ({ isAuthorized, label, route, isBeta, info }, index) =>
          isAuthorized && <SubMenuItem key={index} label={label} route={route} isBeta={isBeta} info={info} />
      )}
    </>
  )
}

export default SupraSubMenu
