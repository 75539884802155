import { takeLatest, select, call, put, fork } from 'redux-saga/effects'
import { stopAsyncValidation } from 'redux-form'
import contentRange from 'content-range'
import axios from 'axios'
import cuid from 'cuid'
import moment from 'moment'

// components
import { successModal, errorModal } from '../actions/modal-actions'

// actions
import {
  updateSession,
  requestError,
  FETCH_GROUP_PRODUCTS,
  FETCH_GROUP_PRODUCTS_SUCCESS,
  FETCH_GROUP_PRODUCTS_FAILED,
  ALERT_CREATE,
  createAlert
} from '../actions/app-actions'

import {
  FETCH_GROUP,
  FETCH_GROUPS,
  SAVE_GROUP,
  SAVE_GROUP_PERMISSIONS,
  SAVE_GROUP_PERMISSIONS_SUCCESS,
  FETCH_GROUP_PERMISSIONS,
  FETCH_GROUP_PERMISSIONS_SUCCESS,
  FETCH_GROUPS_FAILED,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUP_SUGGESTIONS,
  FETCH_GROUP_SUGGESTIONS_FAILED,
  FETCH_GROUP_SUGGESTIONS_SUCCESS,
  DOWNLOAD_GROUPS,
  DELETE_USER_GROUP
} from '../actions/group-actions'

import { refreshProfile } from '../sagas/auth-sagas'

// services
import ApiService from '../services/api-service'

const DOWNLOAD_DATETIME = 'MM-DD-YYYY:hh:mm:ss'

const saveGroupQuery = (token, payload) => ApiService.isAuth(token).post('/api/admin/portal-group', payload)

const postDeleteUserGroup = ({ token, id }) => ApiService.isAuth(token).delete(`/api/admin/group/${id}`)

/**
 * Fetch multiple groups. Action needs count and offset. Searchterm optional
 *
 * @param {any} [action={ offset: Number, count: Number, search: String }]
 */
function* fetchGroups(action = {}) {
  const state = yield select(state => state)
  try {
    yield put(updateSession({ loading: true }))

    const response = yield call(() =>
      axios.get(`/api/groups-list`, {
        params: {
          sort: 'name',
          offset: action.offset,
          count: action.count,
          searchOnlyNames: action.search ? action.search : undefined
        }
      })
    )

    const parts = contentRange.parse(response.headers['content-range'])
    yield put({
      type: FETCH_GROUPS_SUCCESS,
      data: response.data,
      offset: action.offset,
      count: action.search ? state.groups.count : parts ? parts.length : 0
    })

    yield put(updateSession({ loading: false }))
  } catch (error) {
    window.captureException(error)
    yield put(updateSession({ loading: false }))
    yield put({
      type: FETCH_GROUPS_FAILED,
      message: error.message
    })
  }
}

/**
 * Gets single group
 * @param {Object} options.payload Group id
 */
function* fetchGroup(action) {
  try {
    yield put(updateSession({ loading: true }))
    const response = yield call(() => axios.get(`/api/admin/groups-read/${action.id}`))
    yield put(updateSession({ loading: false, group: response.data }))
  } catch (error) {
    window.captureException(error)
    yield put(updateSession({ loading: false }))
    yield put(requestError(error))
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: `An error ocurred while getting details for group ${action.id} - ${error.message}`,
          headline: 'Group Details'
        }
      ]
    })
  }
}

function* fetchGroupSuggestions(action = {}) {
  try {
    const response = yield call(() =>
      axios.get(`/api/groups-list`, {
        params: {
          sort: 'name',
          limit: 10,
          searchOnlyNames: action.searchTerm
        }
      })
    )

    yield put({
      type: FETCH_GROUP_SUGGESTIONS_SUCCESS,
      groups: response.data
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: FETCH_GROUP_SUGGESTIONS_FAILED,
      message: error.message
    })
  }
}

/**
 * Saves a single group
 * @param {Object} options.payload: {groupData } [description]
 */
function* saveGroup({ groupData, isDeliverInfo, userProducts, callback }) {
  try {
    if (groupData.feedsEnabled !== undefined && groupData.feedsEnabled === '1') {
      if (groupData.s3Bucket && !groupData.s3KmsKeyId) {
        return yield put(
          errorModal('For S3 Deliveries, KMS Key ID is required. Please enter a KMS Key ID and try again.')
        )
      }
      if (!groupData.s3Bucket && !groupData.feedsFilePublicEncryptionKey) {
        return yield put(
          errorModal(
            'Encryption is enforced for Data Feeds delivered through FTPS. Please enter a public key and try again.'
          )
        )
      }
    }
    const { auth } = yield select(state => state.session)
    const data = yield call(saveGroupQuery, auth.token, {
      groupData,
      userProducts
    })
    if (data.success === false) {
      yield put(stopAsyncValidation('group', data.form_messages))
      return yield put(errorModal(Object.values(data.form_messages)[0]))
    }

    // Updating user below removes some elements from the state
    yield put(
      updateSession({
        // user: data.user,
        group: data.group
      })
    )

    // Update products for group
    if (data.products) {
      yield put({
        type: FETCH_GROUP_PRODUCTS_SUCCESS,
        id: data.group.id,
        products: data.products
      })
    }

    yield fetchGroups()

    if (data.group.isPortalGroup) {
      yield refreshProfile()
    }

    if (isDeliverInfo) {
      yield put(
        successModal(`Default Delivery Information updated for group ${data.group.name}`, callback, [data.group])
      )
    }
    return yield put(successModal('Group Saved!', callback, [data.group]))
  } catch (e) {
    window.captureException(e)
    yield put(updateSession({ loading: false }))
    yield put(requestError(e))
  }
}

function* fetchGroupProducts(action) {
  try {
    const groupProducts = yield call(() => axios.get(`/api/products/group/${action.id}`))
    yield put({
      type: FETCH_GROUP_PRODUCTS_SUCCESS,
      id: action.id,
      products: groupProducts.data
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: FETCH_GROUP_PRODUCTS_FAILED,
      error: error.message
    })
  }
}

export function* saveGroupPermissions(action) {
  try {
    const response = yield call(() => axios.post(`/api/permissions/group/${action.groupId}`, action.permissions))
    yield put({
      type: SAVE_GROUP_PERMISSIONS_SUCCESS,
      data: response.data,
      groupId: action.groupId
    })
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: `Updated permissions successfully`,
          headline: 'Group Permissions'
        }
      ]
    })
  } catch (err) {
    window.captureException(err)
    const msg = 'Failed to update permissions'
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: msg,
          headline: 'Group Permissions'
        }
      ]
    })
  }
}

export function* fetchGroupPermissions(action) {
  try {
    const response = yield call(() => axios.get(`/api/permissions/group/${action.groupId}`))
    yield put({
      type: FETCH_GROUP_PERMISSIONS_SUCCESS,
      data: response.data,
      groupId: action.groupId
    })
  } catch (err) {
    window.captureException(err)
    const msg = 'Failed to fetch group permissions'
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: msg,
          headline: 'Group Permissions'
        }
      ]
    })
  }
}

function* downloadGroups({ searchTerm }) {
  try {
    yield put(createAlert('success', '', 'Preparing CSV for download'))

    const response = yield call(() =>
      axios({
        url: 'api/admin/groups/download',
        method: 'get',
        responseType: 'blob',
        params: {
          searchTerm
        }
      })
    )

    const url = window.URL.createObjectURL(response.data)
    const downloadLink = document.createElement('a')
    downloadLink.href = url

    downloadLink.setAttribute('download', `groups-${moment().format(DOWNLOAD_DATETIME)}.csv`)

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  } catch (error) {
    yield put(createAlert('danger', error.message, 'Groups CSV download failed'))
  }
}

function* deleteUserGroup({ payload }) {
  try {
    const { auth } = yield select(state => state.session)
    yield call(postDeleteUserGroup, { token: auth.token, id: payload.id })
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: `Group deleted successfully`,
          headline: 'Group Delete'
        }
      ]
    })
    return payload.callback()
  } catch (error) {
    yield put(createAlert('danger', error.message, 'Group delete failed'))
  }
}

function* fetchGroupsFlow() {
  yield takeLatest(FETCH_GROUPS, fetchGroups)
}

function* saveGroupFlow() {
  yield takeLatest(SAVE_GROUP, saveGroup)
}

function* fetchGroupFlow() {
  yield takeLatest(FETCH_GROUP, fetchGroup)
}

function* fetchGroupProductsFlow() {
  yield takeLatest(FETCH_GROUP_PRODUCTS, fetchGroupProducts)
}

function* fetchGroupSuggestionsFlow() {
  yield takeLatest(FETCH_GROUP_SUGGESTIONS, fetchGroupSuggestions)
}

export function* fetchGroupPermissionsFlow() {
  yield takeLatest(FETCH_GROUP_PERMISSIONS, fetchGroupPermissions)
}

export function* saveGroupPermissionsFlow() {
  yield takeLatest(SAVE_GROUP_PERMISSIONS, saveGroupPermissions)
}

function* downloadGroupsFlow() {
  yield takeLatest(DOWNLOAD_GROUPS, downloadGroups)
}

function* deleteUserGroupFlow() {
  yield takeLatest(DELETE_USER_GROUP, deleteUserGroup)
}

export default [
  fork(saveGroupFlow),
  fork(fetchGroupFlow),
  fork(fetchGroupsFlow),
  fork(fetchGroupProductsFlow),
  fork(fetchGroupSuggestionsFlow),
  fork(fetchGroupPermissionsFlow),
  fork(saveGroupPermissionsFlow),
  fork(downloadGroupsFlow),
  fork(deleteUserGroupFlow)
]
