import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from '../Loader'
import Box from '../Box'
import Input from '../Input'
import CheckBox from '../CheckBox'
import PersonIcon from '../Icon/Person'
import LockIcon from '../Icon/Lock'
import DocumentTitle from 'react-document-title'

import { login } from '../../actions/auth-actions'

import './index.scss'
import validateRouteProtocol from '../../utils/enforce-https'
import { appName } from '../../constants/constants'

const INACTIVE_ACCOUTN_MASSAGE =
  'Your account has been temporarily disabled due to prolonged inactivity or security concerns. To reactivate your account, please reset your password.'

/**
 * Login component
 */
class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      keepMeLoggedIn: true,
      loginMessage: null,
      view: 'login'
    }
    validateRouteProtocol()
  }

  didChangeEmail = (ev = '') => {
    this.setState({ email: ev.toLowerCase().trim() })
  }

  didChangePassword = ev => {
    this.setState({ password: ev })
  }

  didChangekeepMeLoggedIn = value => {
    this.setState({ keepMeLoggedIn: value })
  }

  handleSubmitLogin = event => {
    event.preventDefault()
    this.props.onLogin({
      email: this.state.email,
      password: this.state.password
    })
  }

  redirectToForgotPassword = () => {
    this.props.history.push('/forgot_password')
  }

  didChangeEmailForgotPassword = ev => {
    const value = ev.target.value || ''
    this.setState({ emailForgotPassword: value.toLowerCase().trim() })
  }

  render() {
    const ResetPasswordLink = () => {
      if (this.props.sendResetPasswordEmail) return null
      return (
        <span onClick={this.redirectToForgotPassword} className="forgot-link btn_link">
          Reset password?
        </span>
      )
    }

    let message = ''

    switch (this.props.sessionMessageType) {
      case 'Error':
        message = (
          <div>
            <div className="errorMessage">You provided a wrong username and/or password.</div>
            <ResetPasswordLink />
            <br />
            <br />
          </div>
        )
        break
      case 'forgotPasswordEmailSuccess':
      case 'resetPasswordSuccess':
        message = (
          <div>
            <div className="successMessage">{this.props.sessionMessage}</div>
            <br />
          </div>
        )
        break
      case 'InactiveUserError':
        message = (
          <div>
            <div className="errorMessage">{INACTIVE_ACCOUTN_MASSAGE}</div>
            <br />
          </div>
        )
        break
      default:
        break
    }
    return (
      <DocumentTitle title={this.props.documentTitle ? `${appName} | ${this.props.documentTitle}` : appName}>
        <form onSubmit={this.handleSubmitLogin.bind(this)}>
          <Box className="authView alignItemsCenter justifyContentCenter loginPage">
            <Box className="authBox alignItemsCenter directionColumn">
              <>
                <h1 className="title">Your view of the digital consumer just got sharper</h1>
                {message}
                <Input
                  id="email-input"
                  className="email inputWithBackgroundColor inputWithDarkBackground"
                  iconName={<PersonIcon width="24" height="24" />}
                  type="text"
                  value={this.state.email}
                  textDidChange={this.didChangeEmail}
                  placeholder="Email"
                  autoFocus
                  tabIndex="1"
                />

                <Input
                  id="password-input"
                  className="password inputWithBackgroundColor inputWithDarkBackground"
                  iconName={<LockIcon width="24" height="24" />}
                  type="password"
                  value={this.state.password}
                  textDidChange={this.didChangePassword}
                  placeholder="Password"
                  tabIndex="2"
                />

                <CheckBox
                  className="keepMeLoggedIn"
                  checked={this.state.keepMeLoggedIn}
                  valueDidChange={this.didChangekeepMeLoggedIn}
                  label="Keep me logged in"
                />

                {!this.props.loading && <input id="login-button" className="submit" value="Log In" type="submit" />}

                {this.props.loading && (
                  <div className="submit loading">
                    <Loader height={45} />
                  </div>
                )}

                <ResetPasswordLink />

                <div className="footer" />
              </>
            </Box>
          </Box>
        </form>
      </DocumentTitle>
    )
  }
}

LoginPage.propTypes = {
  onLogin: PropTypes.func,
  sessionState: PropTypes.string,
  sessionMessageType: PropTypes.oneOf([
    'Error',
    'forgotPasswordEmailSuccess',
    'InactiveUserError',
    'resetPasswordSuccess',
    null
  ]),
  sessionMessage: PropTypes.string,
  loginError: PropTypes.func,
  sendResetPasswordEmail: PropTypes.bool,
  loading: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
  documentTitle: PropTypes.string
}

LoginPage.defaultProps = {
  documentTitle: 'Login'
}

// =====================================
//  CONNECT
// -------------------------------------

function mapDispatchToProps(dispatch) {
  return {
    onLogin: payload => dispatch(login(payload))
  }
}

function mapStateToProps(state) {
  if (!state.session) {
    return { sessionState: 'anonymous' }
  }

  return {
    loading: state.session.loading,
    sessionMessageType: state.session.sessionMessageType,
    sessionMessage: state.session.sessionMessage
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
