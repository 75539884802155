import searchReducer from './niq-search-reducers'
import searchEditReducer from './niq-search-edit-reducers'
import searchQCReducer from './niq-search-qc-reducers'

export const NIQ_SEARCH_STORE_PATH = 'niqSearch'
export const NIQ_SEARCH_EDIT_STORE_PATH = 'niqSearchEdit'
export const NIQ_SEARCH_QC_STORE_PATH = 'niqSearchQC'

export default {
  [NIQ_SEARCH_STORE_PATH]: searchReducer,
  [NIQ_SEARCH_EDIT_STORE_PATH]: searchEditReducer,
  [NIQ_SEARCH_QC_STORE_PATH]: searchQCReducer
}
