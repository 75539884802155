export default {
  NAME: 'NielsenIQ',
  appBaseUrls: {
    development: 'http://localhost:4001',
    staging: null,
    production: 'https://d2i3ekart1j6i9.cloudfront.net'
  },
  serverBaseUrls: {
    development: 'http://localhost:8080',
    staging: null,
    production: 'https://portal-production-nielsen-api.slicetest.com'
  },
  sentry: {
    development: 'https://908b9231e97247e48ce375f3b24a5a42@sentry.io/157565',
    // cicd: 'https://908b9231e97247e48ce375f3b24a5a42@sentry.io/157565',
    staging: 'https://68586df99368423cb9737bac8a6f6693@sentry.io/162223',
    production: 'https://1b3c189edd834041a4eb98968859e1ca@sentry.io/163067'
  },
  colors: {
    primaryColor: '#2d6df6',
    secondaryColor: '#ef5f17',
    dayCellColor: '#659eff'
  }
}
