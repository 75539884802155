import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { Collapse } from 'react-bootstrap'
import cx from 'classnames'

import BetaIcon from 'components/Icon/BetaIcon'
import NavArrowIcon from 'components/Icon/ArrowNav'

import useLeftNav from './leftNavContext'

const MenuItemLabel = ({ label, Icon, isBeta, children }) => {
  return (
    <div className="root-nav-item-main" title={label}>
      <span className="root-nav-item-icon">{<Icon />}</span>
      <span className="willBeInvisible">
        <span className="root-nav-item-label">{label}</span>
        {isBeta && <BetaIcon />}
      </span>
      {children}
    </div>
  )
}

MenuItemLabel.propTypes = {
  label: PropTypes.string.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  isBeta: PropTypes.bool,
  children: PropTypes.node
}

/**
 * MenuItem is used to render root lbevel nav items in the Navigation Tab.
 * It can have links with 'route' and/or with SubMenu
 */
const MenuItem = ({ label, route, rootPath, icon: Icon, className, subMenu: SubMenu, isBeta = false }) => {
  const { toggleSubMenu, isOpen, subMenuState } = useLeftNav()
  const menuKey = rootPath

  const toggleSubItems = e => {
    if (SubMenu) {
      toggleSubMenu(menuKey)
      e.preventDefault()
    }
  }

  const isItemWithLink = !!route
  const isSubMenuOpen = !!(isOpen && subMenuState[menuKey])

  // define is open base on the pathname
  const location = useLocation()
  const [, firstPathPart] = location.pathname.split('/')
  const isSelected = firstPathPart === rootPath

  return (
    <li className={cx(className, 'root-nav-item')} title={label}>
      {isItemWithLink ? (
        <NavLink className="sidebar-nav-link" to={route}>
          <MenuItemLabel label={label} Icon={Icon} isBeta={isBeta} />
        </NavLink>
      ) : (
        <div
          onClick={toggleSubItems}
          className={cx({
            sidebarSelectedPanel: isSelected
          })}
        >
          <MenuItemLabel label={label} Icon={Icon} isBeta={isBeta}>
            <span
              className={cx('spanRight willBeInvisible', {
                rotationStart: isSubMenuOpen,
                rotationStop: !isSubMenuOpen
              })}
            >
              <NavArrowIcon />
            </span>
          </MenuItemLabel>
          <Collapse in={isSubMenuOpen}>
            <div className={cx('willBeInvisible sub-menu-dropdown')}>
              <SubMenu />
            </div>
          </Collapse>
        </div>
      )}
    </li>
  )
}

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  rootPath: PropTypes.string.isRequired,
  route: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  isBeta: PropTypes.bool,
  className: PropTypes.string,
  subMenu: PropTypes.elementType
}

export default MenuItem
