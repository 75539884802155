import { UPDATE_FILTER } from '../actions/filter-actions'
import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialState = {
  feedModuleName: '',
  feedGroupName: '',
  groups: '',
  jobs: '',
  customReports: ''
}

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FILTER:
      return {
        ...state,
        [action.filterId]: action.searchedText
      }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default filterReducer
