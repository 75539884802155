import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'

import { isAuthValid } from '../selectors/session-selectors'

// creates a component by merging all the props
const renderMergedProps = (component, ...rest) => {
  if (!component) return null
  const finalProps = Object.assign({}, ...rest)
  return React.createElement(component, finalProps)
}

/**
 * This Route allows us to pass custom attributes on Route,
 * from Parent to Child route component
 */
const RouteWithProps = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={routeProps => {
        return renderMergedProps(component, rest, routeProps)
      }}
    />
  )
}

RouteWithProps.propTypes = {
  component: PropTypes.func
}

/**
 * This Route redirects to login page is isAuthValid == false.
 * It also allows us to pass custom attributes on Route,
 * from Parent to Child route component
 */
const Protected = ({ component: Component, exact = false, path, isAuthValid, ...rest }) => (
  <Route
    exact={exact}
    path={path}
    render={renderProps => {
      const { location: { pathname = '' } = {} } = renderProps
      let redirectUrl = '/login'
      if (pathname && pathname !== '/' && pathname !== '/login') {
        redirectUrl = `/login?redirectUrl=${encodeURIComponent(pathname)}`
      }
      return isAuthValid ? renderMergedProps(Component, rest, renderProps) : <Redirect to={redirectUrl} />
    }}
  />
)

Protected.propTypes = {
  component: PropTypes.elementType,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  isAuthValid: PropTypes.bool.isRequired,
  routes: PropTypes.array,
  location: PropTypes.object.isRequired
}

const Auth = ({ component: Component, exact = false, path, isAuthValid, routes = [] }) => (
  <Route
    exact={exact}
    path={path}
    render={props => {
      return !isAuthValid ? Component ? <Component {...props} routes={routes} /> : null : <Redirect to="/" />
    }}
  />
)

Auth.propTypes = {
  component: PropTypes.func,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  isAuthValid: PropTypes.bool.isRequired,
  routes: PropTypes.array
}

// connect
const mapStateToProps = state => ({
  isAuthValid: isAuthValid(state) || false
})

export const PropsRoute = withRouter(RouteWithProps)

export const ProtectedRoute = withRouter(connect(mapStateToProps, null)(Protected))

export const AuthRoute = withRouter(connect(mapStateToProps, null)(Auth))

export function loadAsyncComponents(moduleMap, importPromiseMap) {
  function loadComponent(compName, pathPromise) {
    pathPromise.then(module => {
      moduleMap[compName] = module.default ? module.default : module
    })
  }
  Object.keys(importPromiseMap).forEach(key => loadComponent(key, importPromiseMap[key]))
}
