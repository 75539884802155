import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Box from '../Box'
import CheckMark from '../Icon/CheckMark'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import cx from 'classnames'
import './index.scss'

/*!
 * globals
 */

const noop = () => {}

/**
 * CheckBox component
 */

class CheckBox extends PureComponent {
  onClick = () => {
    this.props.valueDidChange(!this.props.checked)
  }

  render() {
    const { label, className, showTooltipOnHover, checked } = this.props
    const tooltip = () => <Tooltip id={'checkbox_filter'}>{label}</Tooltip>
    return (
      <Box onClick={this.onClick} className={cx('CheckBox noWrap alignItemsCenter flex', className)}>
        <>
          {<CheckMark id="CheckBox-checkMark" checked={checked} className={cx('checkmarkNoRadius', { checked })} />}
          {label &&
            (showTooltipOnHover ? (
              <OverlayTrigger rootClose placement="top" overlay={tooltip()} delayShow={1000}>
                <label className="label">{label}</label>
              </OverlayTrigger>
            ) : (
              <label className="label">{label}</label>
            ))}
        </>
      </Box>
    )
  }
}

CheckBox.propTypes = {
  checked: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  valueDidChange: PropTypes.func,
  className: PropTypes.string,
  showTooltipOnHover: PropTypes.bool
}

CheckBox.defaultProps = {
  checked: false,
  valueDidChange: noop,
  showTooltipOnHover: false
}

/*!
 * export
 */

export default CheckBox
