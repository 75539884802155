import { takeLatest, takeEvery, fork, call, put } from 'redux-saga/effects'
import axios from 'axios'

import { createAlert } from 'actions/app-actions'

import metricsActions from './metricsActions'

function* fetchQuery(action) {
  const { queryType, categoryId } = action.payload
  try {
    const params = { params: { category_id: categoryId, graph_type: queryType } }

    yield put({
      type: metricsActions.setQueryLoading.type,
      payload: { queryType, loading: true }
    })

    const apiPath = '/api/monitoring/graph'
    const { data } = yield call(() => axios.get(apiPath, params))

    if (data[0]) {
      yield put({
        type: metricsActions.successLoadQuery.type,
        payload: {
          groupQueryId: data[0].query_group_id,
          groupQueryLabel: data[0].query_group,
          queryId: data[0].query_id,
          queryLabel: data[0].query,
          queryType
        }
      })
    }
  } catch (error) {
    yield put(createAlert('danger', error.message, `Get Queries fetch failed`))
    window.captureException(error)
  }

  yield put({
    type: metricsActions.setQueryLoading.type,
    payload: { queryType, loading: false }
  })
}

function* saveQuery(action) {
  const {
    categoryId,
    shortCategoryFullPath,
    groupQueryLabel,
    groupQueryId,
    queryLabel,
    queryId,
    queryType
  } = action.payload

  try {
    yield put({
      type: metricsActions.setQueryLoading.type,
      payload: { queryType, loading: true }
    })

    const params = {
      category_id: categoryId,
      // NOTE we use "category" column only for additional information about selected category
      // We can remove it in the future
      category: shortCategoryFullPath,
      query_group: groupQueryLabel,
      query_group_id: groupQueryId,
      query: queryLabel,
      query_id: queryId,
      graph_type: queryType
    }

    const apiPath = '/api/monitoring/graph'
    yield call(() => axios.post(apiPath, params))
    yield put({
      type: metricsActions.successLoadQuery.type,
      payload: {
        groupQueryId,
        groupQueryLabel,
        queryId,
        queryLabel,
        queryType
      }
    })
    yield put(createAlert('success', '', `Query saved`))
  } catch (error) {
    yield put(createAlert('danger', error.message, `Query save failed!`))
    window.captureException(error)
  }

  yield put({
    type: metricsActions.setQueryLoading.type,
    payload: { queryType, loading: false }
  })
}

function* removeQuery(action) {
  const { queryType, categoryId } = action.payload

  const params = {
    category_id: categoryId,
    graph_type: queryType
  }
  try {
    yield put({
      type: metricsActions.setQueryLoading.type,
      payload: { queryType, loading: true }
    })

    const apiPath = '/api/monitoring/graph'
    yield call(() => axios.delete(apiPath, { data: { ...params } }))
    // remove query from store
    yield put({
      type: metricsActions.successRemoveQuery.type,
      payload: { queryType }
    })
    yield put(createAlert('success', '', `Query removed`))
  } catch (error) {
    yield put(createAlert('danger', error.message, `Remove Graph failed`))
    window.captureException(error)
  }

  yield put({
    type: metricsActions.setQueryLoading.type,
    payload: { queryType, loading: false }
  })
}

function* metricsSagaFlow() {
  yield takeEvery(metricsActions.fetchQuery.type, fetchQuery)
  yield takeLatest(metricsActions.saveQuery.type, saveQuery)
  yield takeLatest(metricsActions.removeQuery.type, removeQuery)
}

export default [fork(metricsSagaFlow)]
