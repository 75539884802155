export const FETCH_USER = 'FETCH_USER'
export const FETCH_USERS = 'FETCH_USERS'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR'
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED'
export const SAVE_USER = 'SAVE_USER'
export const SEND_RESET_PASSWORD_EMAIL = 'SEND_RESET_PASSWORD_EMAIL'
export const RESEND_ONBOARDING_LINK = 'RESEND_ONBOARDING_LINK'
export const FETCH_USERS_SEARCH_ENTITY_CHANGED = 'FETCH_USERS_SEARCH_ENTITY_CHANGED'
export const SAVE_USER_PERMISSIONS = 'SAVE_USER_PERMISSIONS'
export const SAVE_USER_PERMISSIONS_SUCCESS = 'SAVE_USER_PERMISSIONS_SUCCESS'
export const FETCH_USER_PERMISSIONS = 'FETCH_USER_PERMISSIONS'
export const FETCH_USER_PERMISSIONS_SUCCESS = 'FETCH_USER_PERMISSIONS_SUCCESS'
export const SET_USER_PASSWORD_BY_ADMIN = 'SET_USER_PASSWORD_BY_ADMIN'
export const DOWNLOAD_USERS = 'DOWNLOAD_USERS'
export const DISABLE_USER = 'DISABLE_USER'
export const ENABLE_USER = 'ENABLE_USER'
export const DELETE_USER = 'DELETE_USER'

export function fetchUser(userId) {
  return {
    type: FETCH_USER,
    userId
  }
}

export function fetchUsers(offset, count, search) {
  return {
    type: FETCH_USERS,
    offset,
    count,
    search
  }
}

export function fetchUsersSuccess(payload) {
  return {
    type: FETCH_USERS_SUCCESS,
    payload
  }
}

export function saveUser(payload) {
  return {
    type: SAVE_USER,
    payload
  }
}

export function sendResetPasswordEmail(payload) {
  return {
    type: SEND_RESET_PASSWORD_EMAIL,
    payload
  }
}

export function setUserPasswordByAdmin({ adminPassword, userEditPassword, userEditId }) {
  return {
    type: SET_USER_PASSWORD_BY_ADMIN,
    adminPassword,
    userEditPassword,
    userEditId
  }
}

export function resendOnboardingLink(email) {
  return {
    type: RESEND_ONBOARDING_LINK,
    email
  }
}

export function fetchUsersSearchEntityChanged(selectedSearchEntity) {
  return {
    type: FETCH_USERS_SEARCH_ENTITY_CHANGED,
    selectedSearchEntity
  }
}

export function saveUserPermissions(permissions, userId) {
  return {
    type: SAVE_USER_PERMISSIONS,
    permissions,
    userId
  }
}

export function fetchUserPermissions(userId) {
  return {
    type: FETCH_USER_PERMISSIONS,
    userId
  }
}

export const downloadUsers = (searchEntity, searchTerm) => ({
  type: DOWNLOAD_USERS,
  searchEntity,
  searchTerm
})

export const disableUser = payload => ({
  type: DISABLE_USER,
  payload
})

export const enableUser = payload => ({
  type: ENABLE_USER,
  payload
})

export const deleteUser = payload => ({
  type: DELETE_USER,
  payload
})
