/* eslint-disable max-len */

/*!
 * deps
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'

class Home extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    fill: PropTypes.string
  }

  static defaultProps = {
    width: 30,
    height: 20,
    fill: 'none'
  }

  render() {
    return (
      <IconBase fill="none" viewBox="4 4 24 20" {...this.props}>
        <g id="Dashboard">
          <polygon id="Shape" points="0 0 30 0 30 30 0 30" />
          <path
            d="M6.5,18.5 L6.5,18 L6,18.5 L6.5,18.5 Z M13.5,18.5 L14,18.5 L13.5,18 L13.5,18.5 Z M13.5,23.5 L13.5,24 L14,23.5 L13.5,23.5 Z M6.5,23.5 L6,23.5 L6.5,24 L6.5,23.5 Z M6.5,23.5 L6.5,18.5 L13.5,18.5 L13.5,23.5 L6.5,23.5 Z M16.5,14.5 L16.5,14 L16,14.5 L16.5,14.5 Z M23.5,14.5 L24,14.5 L23.5,14 L23.5,14.5 Z M23.5,23.5 L23.5,24 L24,23.5 L23.5,23.5 Z M16.5,23.5 L16,23.5 L16.5,24 L16.5,23.5 Z M16.5,23.5 L16.5,14.5 L23.5,14.5 L23.5,23.5 L16.5,23.5 Z M16.5,6.5 L16.5,11.5 L23.5,11.5 L23.5,6.5 L16.5,6.5 Z"
            id="Shape"
            stroke="currentColor"
          />
          <g id="Path">
            <path
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="1"
              d="M6.5,15.5 L13.5,15.5 L13.5,6.5 L6.5,6.5 L6.5,15.5 Z"
            />
          </g>
        </g>
      </IconBase>
    )
  }
}
export default Home
