// @flow
import * as actions from '../actions/custom-report-actions'
import { LOGOUT_SUCCESS } from '../actions/auth-actions'
import normalizeReport from '../utils/normalize-report'
import { omit, sortBy, toString } from 'lodash'

/**
 * [Intializing the reports state, will be update based on users interactions]
 * @type {array} allReports all the reports on the platform
 * @type {Object} reportDetails all the details for a given report
 * @type {boolean} loading if any of the actions for the above three states is in progress
 * @type {Object} formState Object containg all the details for current filter and tab.
 * @type {Object} deleted Object containing all the deleted tabs and filters.
 */

const initialState = {
  allReports: [],
  reportDetails: {},
  queryResult: [],
  loading: false,
  report: {},
  tabs: {},
  filters: {},
  filterValues: {},
  formState: { filter: {}, tab: {} },
  activeReportId: -1,
  activeTabId: -1,
  activetFilterId: -1,
  deleted: { filters: [], filterValues: [], tabs: [], tabFilters: [] },
  validation: { isSaved: false },
  refreshingFilterValues: false,
  data: [],
  count: 0,
  offset: 0,
  selectedSearchEntity: 'name',
  searchEntities: {
    name: {
      display: 'Report Name'
    },
    folderName: {
      display: 'Folder Name'
    }
  }
}

export function formatResponse(response) {
  const formattedReport = omit(response, ['Tabs', 'Filters'])
  formattedReport.id = formattedReport.id.toString()

  function getFilterValuesOrder(values) {
    return sortBy(values, ['order', 'aliasValue']).map(value => {
      return { id: `${value.id}`, order: value.order }
    })
  }

  function formatFilter(filter) {
    const reportFilter = omit(filter, ['FilterValues'])
    reportFilter.id = reportFilter.id.toString()
    reportFilter.filterValues = filter.FilterValues.map(filterValue => {
      filterValue.id = filterValue.id.toString()
      return filterValue
    })
    reportFilter.valuesOrder = getFilterValuesOrder(filter.FilterValues)
    return reportFilter
  }

  formattedReport.filters = response.Filters.map(filter => {
    return formatFilter(filter)
  })

  formattedReport.tabs = response.Tabs.map(tab => {
    const formattedTab = omit(tab, ['Filters'])
    formattedTab.id = formattedTab.id.toString()
    formattedTab.filterOrders = []
    formattedTab.filters = tab.Filters.map(filter => {
      formattedTab.filterOrders.push({
        id: `${filter.id}`,
        order: filter.TabFilter.filterOrder ? filter.TabFilter.filterOrder : 0
      })
      return formatFilter(filter)
    })
    return formattedTab
  })
  return formattedReport
}

const reportsReducer = (state: Array<Object> = initialState, action: Object): Array<Object> => {
  switch (action.type) {
    case actions.FETCH_ALL_REPORTS:
      return {
        ...state,
        loading: true
      }

    case actions.FETCH_ALL_REPORTS_SUCCESS:
      return {
        ...state,
        ...action.payload
      }

    case actions.FETCH_REPORT_DETAILS:
      return {
        ...state,
        loading: true
      }

    case actions.FETCH_REPORT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        reportDetails: action.payload.reportDetails ? action.payload.reportDetails : {}
      }
    case actions.CREATE_DASHBOARD_URL: {
      const reportDetails = state.reportDetails
      const dashboardLinks = omit(reportDetails.dashboardLinks, action.outdatedTabIds)
      if (reportDetails && toString(reportDetails.id) === toString(action.reportId)) {
        return {
          ...state,
          reportDetails: {
            ...reportDetails,
            dashboardLinks: {
              ...dashboardLinks,
              [action.tabId]: {
                url: 'loading'
              }
            }
          }
        }
      } else {
        return state
      }
    }

    case actions.UPDATE_DASHBOARD_URL: {
      const reportDetails = state.reportDetails
      if (reportDetails && toString(reportDetails.id) === toString(action.reportId)) {
        return {
          ...state,
          reportDetails: {
            ...reportDetails,
            dashboardLinks: {
              ...reportDetails.dashboardLinks,
              [action.tabId]: action.data
            }
          }
        }
      } else {
        return state
      }
    }

    case actions.CREATE_REPORT:
      return {
        ...state
      }

    case actions.REPLICATE_REPORT:
      return {
        ...state,
        loading: true
      }

    case actions.DELETE_REPORT:
      return {
        ...state,
        loading: true
      }

    case actions.RESET_REPORT_STATE:
      return {
        ...state,
        report: {},
        tabs: {},
        filters: {},
        filterValues: {},
        formState: { filter: {}, tab: {} },
        activeReportId: -1,
        activeTabId: -1,
        activetFilterId: -1,
        deleted: { filters: [], filterValues: [], tabs: [], tabFilters: [] },
        validation: { isSaved: false }
      }

    case actions.INITIALIZE_REPORT:
      const response = formatResponse(action.payload)
      const normalizedResponse = normalizeReport(response)
      return {
        ...state,
        ...normalizedResponse,
        activeReportId: response.id
      }

    case LOGOUT_SUCCESS:
      return initialState

    case actions.FETCH_REPORTS_SUCCESS:
      return {
        ...state,
        count: action.count,
        offset: action.offset,
        data: action.offset ? [...state.data, ...action.data] : action.data
      }

    case actions.FETCH_REPORTS_FAILED:
      return { error: action.message }

    case actions.CUSTOM_REPORTS_SEARCH_ENTITY_CHANGED:
      return {
        ...state,
        count: 0,
        selectedSearchEntity: action.selectedSearchEntity
      }

    default:
      return state
  }
}

export default reportsReducer
