import { findIndex } from 'lodash'

const updateDescription = (descriptions, key, update, idField, nameField) => {
  let index
  // updating the descriptions only for edits that are DONE
  if (update && update.status === 'DONE') {
    index = findIndex(descriptions, ['dictionary_key', key])
    if (
      index !== -1 &&
      // in case the new values are already updated,
      // ignoring the update to avoid extra UI rendering
      descriptions[index][idField] !== update.newId
    ) {
      descriptions = [...descriptions]
      descriptions[index][nameField] = update.newName
      descriptions[index][idField] = update.newId
    }
  }
  return descriptions
}

export default updateDescription
