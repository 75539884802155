import React, { useState } from 'react'
import PropTypes from 'prop-types'
import OutsideClickHandler from 'react-outside-click-handler'
import cx from 'classnames'

import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import ReportsMenuList from '../ReportsMenuList'
import ArrowUpIcon from '../../../Icon/ArrowUp'
import ArrowDownIcon from '../../../Icon/ArrowDown'
import Popup from '../../Popup'
import Input from '../../../Input'
import SearchIcon from '../../../Icon/SearchIcon'

import './RadioFilter.scss'

const RadioFilter = ({ id, options = [], className, valueDidChange, selectedValue, label }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchText, setSearchText] = useState('')

  if (!selectedValue) return null

  const toggleOpenPanel = event => {
    // Don't close panel when selecting search input
    const idsToIgnore = {
      'close-icon-svg': true,
      'radio-filter-search-icon': true,
      'radio-filter-search-input': true
    }
    if (idsToIgnore[event.target.id]) return
    setIsOpen(!isOpen)
  }

  const getFilteredOptions = () => {
    if (searchText === undefined || searchText === '') return options

    return options.filter(
      filterValue => (filterValue?.aliasValue || '').toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    )
  }

  const updateSearchText = searchText => setSearchText(searchText)

  const clearSearchText = () => setSearchText(searchText)

  const showSearchbar = options.length > 8

  const tooltip = name => (
    <Tooltip id={name}>
      <div className="tooltip-box">{`${name}:  ${selectedValue?.aliasValue || 'None'}`}</div>
    </Tooltip>
  )

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className={cx('radio-filter', className)} onClick={event => toggleOpenPanel(event)}>
        <OverlayTrigger rootClose placement="top" overlay={tooltip(label)}>
          <div
            className={cx('squareBoxWithArrow', {
              squareBoxWithArrowSelected: isOpen
            })}
          >
            <span className="filterDetailsWrap">
              <div className={cx('filterDescriptorTop', '')}>{`${label}`}</div>
              <div className={cx('filterSelector')}>{selectedValue?.aliasValue || `Select`}</div>
            </span>
            <span className="spanRightArrowIconFilter">
              {isOpen ? (
                <ArrowUpIcon fill="#4C4C4C" id={`arrowUp${id}`} width={32} height={32} />
              ) : (
                <ArrowDownIcon className="defaultArrowDown" id={`arrowDown${id}`} width={32} height={32} />
              )}
            </span>
          </div>
        </OverlayTrigger>

        <Popup isOpen={isOpen} className="SquarePopoverSlice bottomAnimation bottom start Z2-shadow">
          {showSearchbar && (
            <Input
              clearButton
              value={searchText}
              placeholder="Search"
              id="radio-filter-search-input"
              textDidChange={updateSearchText}
              didClickClearButton={clearSearchText}
              className={'input search-input-box flex1 radio-filter-search-input'}
              iconName={<SearchIcon width={20} height={20} id="radio-filter-search-icon" />}
            />
          )}
          <div>
            {
              <ReportsMenuList
                searchText={searchText}
                options={getFilteredOptions()}
                searchHighlight={showSearchbar}
                valueDidChange={valueDidChange}
              />
            }
          </div>
        </Popup>
      </div>
    </OutsideClickHandler>
  )
}
RadioFilter.propTypes = {
  options: PropTypes.array,
  valueDidChange: PropTypes.func,
  label: PropTypes.string,
  selectedValue: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.number
}

RadioFilter.defaultProps = {
  className: ''
}
export default RadioFilter
