import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { values } from 'lodash'

import BaseModal from '../Modals/BaseModal'
import { ModalProvider } from './ModalContext'
import { removeModal } from '../../actions/modal-actions'
import './modals.scss'

const Modals = props => (
  <ModalProvider>
    <div id="modals-go-here">
      {values(props.modals).map((modal, index) => (
        <BaseModal {...modal} key={modal.options.id || index} removeModal={props.removeModal} />
      ))}
    </div>
  </ModalProvider>
)

Modals.propTypes = {
  modals: PropTypes.object.isRequired,
  removeModal: PropTypes.func.isRequired
}

// Connect

const mapStateToProps = ({ modals }) => ({
  modals
})

const mapDispatchToProps = dispatch => ({
  removeModal: id => dispatch(removeModal(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Modals)
