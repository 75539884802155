import { isPasswordValid, PASSWORD_VALIDATION_MESSAGE } from '../../utils/auth-helper'

const validate = (
  { fullname, email, groups, password, passwordConfirm, currentPassword, isFeedNotificationOn },
  { dirty, id, touch, registeredFields }
) => {
  const errors = {}
  if (!dirty) {
    return errors
  }

  if (!fullname) {
    errors.fullname = 'Display name is required'
  }

  // email is required for user creation
  if (id === 'new' && (!email || !email.toLowerCase().match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}/))) {
    errors.email = 'Please provide a valid email address'
  }

  if (!groups) {
    errors.groups = 'Please choose one or more groups for the user'
  }

  const isPasswordRequired =
    Object.values(registeredFields).filter(f => f.name === 'currentPassword').length === 0 ||
    currentPassword !== undefined
  if (!password && isPasswordRequired) {
    errors.password = 'Please choose your password'
  }

  if (password || passwordConfirm) {
    const errorKey = password ? 'password' : 'passwordConfirm'
    const confirmErrorKey = errorKey === 'password' ? 'passwordConfirm' : 'password'

    if (!currentPassword) {
      errors.currentPassword = 'Please enter your current password'
      touch('currentPassword')
    }

    const checkedPassword = password || passwordConfirm
    if (!isPasswordValid(checkedPassword)) {
      errors[errorKey] = PASSWORD_VALIDATION_MESSAGE
    }

    if (password !== passwordConfirm) {
      errors[confirmErrorKey] = 'Passwords must match'
    }
  }

  return errors
}

export default validate
