import React from 'react'
import PropTypes from 'prop-types'

import { Button, Glyphicon } from 'react-bootstrap'
import { uniq, filter } from 'lodash'
import TextareaAutosize from 'react-textarea-autosize'

function getDescriptionActions(text) {
  if (!text) {
    return []
  }

  text = text.trim().toLowerCase()

  let actionItems = [
    {
      actionLabel: (
        <span>
          <Glyphicon glyph={'plus'} />
          VALUE
        </span>
      ),
      value: text
    }
  ]

  const SEPARATORS = /[,\n\r\f\v\t]/
  const NEWLINE_SEPARATORS = /[\n\r\f]+/
  const TAB_SEPARATORS = /[\t\v]+/
  const COMMA_SEPARATOR = /[,]+/
  // check if a separator exists
  if (text.match(SEPARATORS)) {
    // try spliting by newline separators
    let values = text.split(NEWLINE_SEPARATORS)
    if (values.length < 2) {
      // try spliting by tab separators
      values = text.split(TAB_SEPARATORS)
    }
    if (values.length < 2) {
      // try spliting by comma
      values = text.split(COMMA_SEPARATOR)
    }
    values = filter(uniq(values))
    actionItems = [
      ...actionItems,
      {
        actionLabel: (
          <span>
            <Glyphicon glyph={'plus'} />
            {`RULES (${values.length})`}
          </span>
        ),
        action: 'addInCurrentGroup',
        value: values
      },
      {
        actionLabel: (
          <span>
            <Glyphicon glyph={'plus'} />
            RULE GROUP
          </span>
        ),
        action: 'addNewRuleGroup',
        value: values
      }
    ]
  }
  return actionItems
}

function DescriptionValueEditor({ inputText, onChange, onInputChange }) {
  let i = 0
  return (
    <div className="description-value-editor">
      <TextareaAutosize
        minRows={1}
        maxRows={10}
        placeholder="Enter values"
        value={inputText}
        onChange={ev => onInputChange(ev.target.value)}
        autoFocus
      />
      <div className="action-buttons">
        {getDescriptionActions(inputText).map(item => (
          <Button key={++i} onClick={() => onChange([item])} bsSize="small" bsStyle="link">
            {item.actionLabel}
          </Button>
        ))}
      </div>
    </div>
  )
}

DescriptionValueEditor.propTypes = {
  inputText: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func
}

export default DescriptionValueEditor
