/* eslint-disable no-confusing-arrow */
import * as d3 from 'd3'

const stripZero = str => {
  return str.replace(/(\.[1-9])+0*([^0-9])*$/, '$1$2').replace(/\.0*([^0-9])*$/, '$1')
}

const dollarSmallNumberFormatter = value =>
  d3
    .format('$,.1f')(value)
    .replace(/G/, 'B')
const dollarLargeNumberFormatter = value =>
  d3
    .format('$.3s')(value)
    .replace(/G/, 'B')

export const smallNumberFormatter = d3.format(',.1f')
const largeNumberFormatter = value =>
  d3
    .formatPrefix(value)
    .scale(value)
    .toFixed(2) + d3.formatPrefix(value).symbol.replace(/G/, 'B')
export const simpleNumberFormatter = d3.format(',')

export const numberFormatter = value => {
  return value > 999 ? stripZero(largeNumberFormatter(value)) : stripZero(smallNumberFormatter(value))
}

export const priceFormatter = value => {
  return value > 1000 ? stripZero(dollarLargeNumberFormatter(value)) : stripZero(dollarSmallNumberFormatter(value))
}

export function ordinalSuffixFor(num) {
  if (isNaN(num)) {
    return num
  }
  num = Number(num)
  const first = num % 10
  const second = num % 100
  if (first === 1 && second !== 11) {
    return 'st'
  }
  if (first === 2 && second !== 12) {
    return 'nd'
  }
  if (first === 3 && second !== 13) {
    return 'rd'
  }
  return 'th'
}

export const strRemoveMilliseconds = date => {
  if (typeof date !== 'string') {
    return 'Wrong date format'
  }
  return date.slice(0, -4)
}

export const calculateDurationFromMilliseconds = mlsec => {
  if (!mlsec) return 'Invalid format'
  const date = new Date(mlsec)
  const isoDate = date.toISOString()
  // this functin retun duration only in 'HH:mm:ss' format
  return isoDate.substring(11, 19)
}
