import { ADD_MODAL, REMOVE_MODAL, CLEAR_MODALS } from '../actions/modal-actions'
import { omit } from 'lodash'

const initialState = {}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MODAL:
      return {
        ...state,
        [action.options.id]: { ...action }
      }

    case REMOVE_MODAL:
      const modals = omit(state, action.id)
      return modals

    case CLEAR_MODALS:
      return initialState

    default:
      return state
  }
}

export default modalReducer
