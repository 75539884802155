import { takeLatest, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'

import {
  EXCLUDE_PARTNER_MAILBOX_PROVIDER,
  excludePartnerMailboxProviderSuccess,
  excludePartnerMailboxProviderFailure,
  INCLUDE_PARTNER_MAILBOX_PROVIDER,
  includePartnerMailboxProviderSuccess,
  includePartnerMailboxProviderFailure
} from '../actions/partner-mailbox-provider-actions'
import { fetchGlobalFeedConfig } from '../actions/feed-actions'
import { createAlert } from '../actions/app-actions'

function* excludePartnerMailboxProvider(action) {
  try {
    yield call(() =>
      axios.post(`/api/feeds/global-partner-mailbox-provider-excludes`, {
        partnerId: action.partnerId,
        mailboxProviderId: action.mailboxProviderId
      })
    )
    yield put(excludePartnerMailboxProviderSuccess())
    yield put(createAlert('success', '', `Partner + Mailbox Provider Combination excluded`))
    yield put(fetchGlobalFeedConfig())
  } catch (err) {
    window.captureException(err)
    yield put(excludePartnerMailboxProviderFailure(err))
    yield put(createAlert('danger', err.message, `Error excluding partner + mailbox provider combination`))
  }
}

function* includePartnerMailboxProvider(action) {
  try {
    yield call(() =>
      axios.delete(`/api/feeds/global-partner-mailbox-provider-excludes`, {
        data: {
          partnerId: action.partnerId,
          mailboxProviderId: action.mailboxProviderId
        }
      })
    )
    yield put(includePartnerMailboxProviderSuccess())
    yield put(createAlert('success', '', `Partner + Mailbox Provider Combination included`))
    yield put(fetchGlobalFeedConfig())
  } catch (err) {
    window.captureException(err)
    yield put(includePartnerMailboxProviderFailure(err))
    yield put(createAlert('danger', err.message, `Error including partner + mailbox provider combination`))
  }
}

function* excludePartnerMailboxProviderFlow() {
  yield takeLatest(EXCLUDE_PARTNER_MAILBOX_PROVIDER, excludePartnerMailboxProvider)
}

function* includePartnerMailboxProviderFlow() {
  yield takeLatest(INCLUDE_PARTNER_MAILBOX_PROVIDER, includePartnerMailboxProvider)
}

export default [fork(excludePartnerMailboxProviderFlow), fork(includePartnerMailboxProviderFlow)]
