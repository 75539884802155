export const FETCH_MERCHANTS_SUCCESS = 'FETCH_MERCHANTS_SUCCESS'
export const FETCH_MERCHANTS_FAILED = 'FETCH_MERCHANTS_FAILED'
export const FETCH_AUTOCOMPLETE_MERCHANTS = 'FETCH_AUTOCOMPLETE_MERCHANTS'
export const FETCH_SELECTED_MERCHANTS = 'FETCH_SELECTED_MERCHANTS'

export function fetchSelectedMerchants(filteredIds) {
  return {
    type: FETCH_SELECTED_MERCHANTS,
    filteredIds
  }
}

export function fetchMerchantsSuccess(payload) {
  return {
    type: FETCH_MERCHANTS_SUCCESS,
    payload
  }
}

export function fetchMerchantsFailed(error) {
  return {
    type: FETCH_MERCHANTS_FAILED,
    error
  }
}

export function fetchAutocompleteMerchants(searchName) {
  return {
    type: FETCH_AUTOCOMPLETE_MERCHANTS,
    searchName
  }
}
