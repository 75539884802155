import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import { find } from 'lodash'

class DropdownSelector extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.string,
    className: PropTypes.string,
    level: PropTypes.number,
    handleOnChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
  }

  render() {
    const selectedOption = find(this.props.options, opt => opt.name === this.props.value)
    return (
      <DropdownButton
        id={`dropdown-selector-${this.props.level}`}
        onSelect={this.props.handleOnChange}
        className={`qb-dropdown-selector ${this.props.className}`}
        title={selectedOption ? selectedOption.label : 'select'}
        disabled={this.props.readOnly}
      >
        {this.props.options &&
          this.props.options.map(option => (
            <MenuItem key={option.name} eventKey={option.name} active={option.name === this.props.value}>
              {option.label}
            </MenuItem>
          ))}
      </DropdownButton>
    )
  }
}

export default DropdownSelector
