import React from 'react'
import PropTypes from 'prop-types'

const ButtonField = props => {
  return (
    <table>
      <tbody>
        <tr style={{ height: '42px' }}>
          <td>Email notification for data feed refreshes</td>
          <td>
            <label>
              <input
                type="radio"
                name="radio_feed"
                value="1"
                onChange={props.onChange}
                defaultChecked={props.checked}
              />
              <span>Yes</span>
            </label>
          </td>

          <td>
            <label>
              <input
                type="radio"
                name="radio_feed"
                value="0"
                onChange={props.onChange}
                defaultChecked={!props.checked}
              />
              <span>No</span>
            </label>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

ButtonField.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool
}

export default ButtonField
