// @flow
import { ETL_STATE_LOAD_SUCCEEDED, UPDATE_ETL_PARAM } from '../actions/task-actions'
import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialstate = {
  data: [],
  count: 0,
  pageNumber: 1,
  sizePerPage: 20,
  sortBy: 'updatedAt',
  sortOrder: 'desc',
  search: ''
}

const etlStatesReducer = (state: Object = initialstate, action: Object): Object => {
  switch (action.type) {
    case ETL_STATE_LOAD_SUCCEEDED:
      return {
        ...state,
        data: action.data,
        count: action.count
      }
    case UPDATE_ETL_PARAM:
      return {
        ...state,
        ...action.payload
      }

    case LOGOUT_SUCCESS:
      return initialstate

    default:
      return state
  }
}

export default etlStatesReducer
