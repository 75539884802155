// @flow
import * as actions from '../actions/delivery-actions'
import { UPDATE_CONFIG_FORM_DATA } from '../actions/delivery-config-actions'
import { cloneDeep } from 'lodash'

const initialState = {
  loading: false,
  currentDelivery: null,
  pastDeliveryCount: null,
  pastDeliveries: [],
  activeStep: null,
  cleanupJobs: {},
  error: null,
  substepDetails: {
    show: false,
    substep: null
  },
  editDeadlineForm: {
    deadlineDate: null,
    isDateValid: null,
    loading: false,
    error: null
  }
}

const deliveryReducer = (state: Array<Object> = cloneDeep(initialState), action: Object): Array<Object> => {
  switch (action.type) {
    case actions.CREATE_NEW_DELVIERY: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case actions.CREATE_NEW_DELVIERY_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null
      }
    }
    case actions.CREATE_NEW_DELVIERY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error
      }
    }
    case actions.FETCH_CURRENT_DELIVERY:
      return {
        ...cloneDeep(initialState),
        loading: true
      }
    case actions.UPDATE_CURRENT_DELIVERY_DETAILS:
      return {
        ...state,
        loading: true
      }
    case actions.FETCH_CURRENT_DELIVERY_SUCCESS:
      return {
        ...state,
        loading: false,
        currentDelivery: action.payload
      }
    case actions.REFRESH_DELIVERY_STATUS_SUCCESS:
      return {
        ...state,
        currentDelivery: {
          ...state.currentDelivery,
          ...action.payload
        }
      }
    case actions.UPDATE_EDIT_DEADLINE_FORM:
      return {
        ...state,
        editDeadlineForm: {
          ...state.editDeadlineForm,
          ...action.payload
        }
      }
    case actions.UPDATE_DELIVERY_DEADLINE:
      return {
        ...state,
        editDeadlineForm: {
          ...state.editDeadlineForm,
          loading: true,
          error: null
        }
      }
    case actions.TOGGLE_SUBSTEP_DETAILS:
      return {
        ...state,
        substepDetails: {
          ...state.substepDetails,
          show: action.payload.showDetails,
          selectedSubstep: action.payload.substep
        }
      }
    case actions.UPDATE_DELIVERY_DEADLINE_SUCCESS:
      return {
        ...state,
        editDeadlineForm: {
          ...state.editDeadlineForm,
          loading: false,
          error: null
        }
      }
    case actions.UPDATE_DELIVERY_DEADLINE_ERROR:
      return {
        ...state,
        editDeadlineForm: {
          ...state.editDeadlineForm,
          loading: false,
          error: action.error
        }
      }
    case actions.UPDATE_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.activeStep
      }
    case actions.FETCH_CURRENT_DELIVERY_FAILURE:
      return {
        ...state,
        loading: false
      }

    case actions.FETCH_PAST_DELIVERIES:
    case actions.CATEGORIES_CLEANUP_STATUS:
      return {
        ...state,
        loading: true
      }

    case actions.FETCH_PAST_DELIVERIES_SUCCESS:
      return {
        ...state,
        loading: false,
        pastDeliveries: action.deliveries
      }

    case actions.FETCH_PAST_DELIVERIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case actions.CATEGORIES_CLEANUP_STATUS_SUCCESS:
      const { mergePayload, payload } = action
      let cleanupJobs = {}
      if (mergePayload) {
        cleanupJobs = { ...state.cleanupJobs, ...payload }
      } else {
        cleanupJobs = action.payload
      }
      return {
        ...state,
        cleanupJobs,
        loading: false
      }
    case actions.CREATE_CLEANUP_JOB:
      return {
        ...state,
        loading: true
      }
    case actions.CREATE_CLEANUP_JOB_ERROR:
    case actions.CATEGORIES_CLEANUP_STATUS_ERROR:
      return {
        ...state,
        loading: false
      }
    case UPDATE_CONFIG_FORM_DATA:
      const { pastDeliveryCount } = action.deliverableData || {}
      return {
        ...state,
        pastDeliveryCount
      }
    default:
      return state
  }
}

export default deliveryReducer
