// @flow
// eslint-disable-next-line no-redeclare
/* global TENANT */
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import { loadAsyncComponents } from 'utils/route-util'
import UsersPage from '../UsersPage'
import UserEditPage from '../UserEditPage'
import GroupsPage from '../GroupsPage'
import GroupEditPage from '../GroupEditPage'
import NewGroupEditPage from '../GroupEditPageNew'
import ReportsFolderPage from '../ReportsFolderPage'
import ReportsManagerPage from '../ReportsManagerPage'
import CustomReportEditPage from '../CustomReportEditPage'
import CustomReportViewPage from '../CustomReportViewPage'

const asyncPages = {}

if (TENANT === 'ri') {
  loadAsyncComponents(asyncPages, {
    JobListPage: import('../JobListPage' /* webpackMode: "eager" */),
    DataFeeds: import('../DataFeeds' /* webpackMode: "eager" */),
    FeedEditPage: import('../FeedEditPage' /* webpackMode: "eager" */),
    AuthLogsPage: import('../AuthLogsPage' /* webpackMode: "eager" */),
    DeliveryLogsPage: import('../DeliveryManager/DeliveryLogsPage' /* webpackMode: "eager" */),
    DeliveryManager: import('../DeliveryManager' /* webpackMode: "eager" */)
  })
}

/**
 * Container for the Admin page root (/admin). Required by the router.
 */
const AdminPage = () => {
  const { user } = useSelector(state => state.session)

  if (!user) return null

  return (
    <div className="admin-container">
      <Switch>
        <Route exact path="/admin/users" component={UsersPage} />
        <Route path="/admin/users/:id" component={UserEditPage} />
        <Route exact path="/admin/groups" component={GroupsPage} />
        <Route exact path="/admin/groups/:id" component={GroupEditPage} />
        <Route path="/admin/groups/:id/new" component={NewGroupEditPage} />
        <Route path="/admin/jobs" component={asyncPages.JobListPage} />
        <Route path="/admin/feeds/edit/:id" component={asyncPages.FeedEditPage} />
        <Route path="/admin/feeds/modules/:moduleId" component={asyncPages.DataFeeds} />
        <Route path="/admin/feeds" component={asyncPages.DataFeeds} />
        <Redirect exact from="/admin/report/folders" to="/admin/report/folders/0" />
        <Route path="/admin/report/folders/:folderId" component={ReportsFolderPage} />
        <Route exact path="/admin/reports" component={ReportsManagerPage} />
        <Route path="/admin/reports/view/:id" component={CustomReportViewPage} />
        <Route path="/admin/reports/edit/:id" exact component={CustomReportEditPage} />
        <Redirect from="/admin/reports/*" to="/admin/reports" />
        <Route path="/admin/auth-logs" component={asyncPages.AuthLogsPage} />
        <Route path="/admin/delivery-logs" component={asyncPages.DeliveryLogsPage} />

        <Route // Graceful transition to new delivery manager routes
          path="/admin/dc"
          render={({ location: { pathname = '', search = '' } }) => {
            pathname = pathname.replace('admin/dc', 'admin/dm')
            return <Redirect to={`${pathname}/${search}`} />
          }}
        />

        <Route path="/admin/dm" component={asyncPages.DeliveryManager} />
      </Switch>
    </div>
  )
}

export default AdminPage
