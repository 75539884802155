import React from 'react'

import usePermissions from './../usePermissions'
import SubMenuItem from './../SubMenuItem'

const NIQSubMenu = () => {
  const { hasNIQqcToolAccess } = usePermissions()

  const menuItems = [
    {
      label: 'NIQ QC Tool',
      route: '/niq/search',
      isAuthorized: hasNIQqcToolAccess
    }
  ]

  return (
    <>
      {menuItems.map(
        ({ isAuthorized, label, route, isBeta, info }, index) =>
          isAuthorized && <SubMenuItem key={index} label={label} route={route} isBeta={isBeta} info={info} />
      )}
    </>
  )
}

export default NIQSubMenu
