import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Error from './error'

const isProdEnv = process.env.REACT_APP_DEPLOY_ENV && process.env.REACT_APP_DEPLOY_ENV.toLowerCase() === 'production'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      errorInfo: null,
      errorDetails: null
    }
  }

  componentDidCatch(error, info) {
    if (isProdEnv) {
      console.error('One or more child components has encountered an error')
    } else {
      console.log('One or more child components has encountered an error')
      console.error(error)
      console.log(info)
    }
    this.setState({
      error: true,
      errorInfo: info,
      errorDetails: error
    })
    if (this.props.reportToService) {
      if (window.captureBreadcrumb) {
        window.captureBreadcrumb({
          message: error.message || null,
          category: 'react-error',
          data: info
        })
      }
      window.captureException(error)
    }
  }

  render() {
    if (this.state.error) {
      return <Error info={this.state.errorInfo} details={this.state.errorDetails} />
    } else {
      return this.props.children
    }
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  reportToService: PropTypes.bool
}

ErrorBoundary.defaultProps = {
  reportToService: true // Enable / disable error reporting service capture
}
