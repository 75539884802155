import { takeLatest, call, put, fork, select } from 'redux-saga/effects'
import axios from 'axios'
import moment from 'moment'

import config from 'config/index'
import createDownloadLink from 'utils/download-link'
import { DOWNLOAD_DATETIME_FORMAT } from 'constants/constants'
import { createAlert } from 'actions/app-actions'

import CategoryGuidelineActions from './actions'

// GET METHOD
function* fetchCategoryGuideline({ payload = {} }) {
  try {
    const state = yield select(state => state.categoryGuideline)

    const {
      page = state.page,
      sortBy = state.sortBy,
      pageSize = state.pageSize,
      sortOrder = state.sortOrder,
      filters = state.filters
    } = payload

    yield put(
      CategoryGuidelineActions.updateSettings({
        page,
        sortBy,
        pageSize,
        sortOrder,
        filters
      })
    )

    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/rops/category-guidelines`, {
        params: {
          page,
          sortBy,
          pageSize,
          sortOrder,
          filters
        }
      })
    )
    yield put(CategoryGuidelineActions.successLoad(data))
  } catch (error) {
    window.captureException(error)
    yield put(CategoryGuidelineActions.failedLoad())

    yield put(createAlert('danger', error.message, `Category Guideline fetch failed`))
  }
}

// DOWNLOAD
function* downloadCategoryGuidelines() {
  try {
    yield put(createAlert('success', '', 'Preparing CSV for download'))

    const { sortBy, sortOrder, filters } = yield select(state => state.categoryGuideline)

    const response = yield call(() =>
      axios({
        url: `${config.apiBaseUrl}/rops/category-guidelines/download`,
        method: 'get',
        responseType: 'blob',
        params: {
          sortBy,
          sortOrder,
          filters
        }
      })
    )

    createDownloadLink(response.data, `category-guideline-${moment().format(DOWNLOAD_DATETIME_FORMAT)}.csv`)
  } catch (error) {
    yield put(createAlert('danger', error.message, 'Category Guideline CSV download failed'))
  }
}

// SAVE METHOD
function* saveCategoryGuidelines({ payload }) {
  try {
    yield call(() => axios.post(`${config.apiBaseUrl}/rops/category-guidelines`, { payload }))

    yield put(createAlert('success', '', `Category Guideline saved`))

    yield put(CategoryGuidelineActions.fetch())
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data.message || error.message, `Category Guideline saving failed`))
  }
}

/*
 * FLOWS
 */
function* categoryGuidelineFlow() {
  yield takeLatest(CategoryGuidelineActions.fetch.type, fetchCategoryGuideline)
  yield takeLatest(CategoryGuidelineActions.download.type, downloadCategoryGuidelines)
  yield takeLatest(CategoryGuidelineActions.save.type, saveCategoryGuidelines)
}

export default [fork(categoryGuidelineFlow)]
