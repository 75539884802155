import { matchPath } from 'react-router-dom'
import { parse, stringify } from 'query-string'

/**
 * Used for encoding client/contract/deliverable name while creating route/nav links
 * @param {String} name Client/Contract/Deliverable name to be encoded for Url
 */
export const encodeNameForUrl = (name = '') => {
  return encodeURIComponent(
    name
      .replace(/\s+/g, '-') // change spaces to hyphens
      .replace(/-+/g, '-') // collapse multiple hyphens to single
      .substring(0, 50) // make sure string length is not beyond 50
  )
}

/**
 * It creates route url for seeing contracts of a given client
 * @param {Object} client
 */
export const createContractsLinkUrl = (client = {}) => {
  const { id: clientId, name: clientName } = client
  let link = `/admin/dm/contracts`
  const clientParam = `${clientId}-${encodeNameForUrl(clientName)}`
  if (clientId) {
    link += `?${stringify({ client: clientParam })}`
  }
  return link
}

/**
 * It creates route url for seeing deliverables filtered by a given client or contract
 * @param {String} client
 * @param {String} contract
 */
export const createDeliverablesLinkUrl = (client = {}, contract = {}) => {
  const { id: clientId, name: clientName } = client
  const { id: contractId, name: contractName } = contract
  let link = `/admin/dm/deliverables`
  let clientParam = null
  let contractParam = null
  if (clientId) {
    clientParam = `${clientId}-${encodeNameForUrl(clientName)}`
    if (contractId) {
      contractParam = `${contractId}-${encodeNameForUrl(contractName)}`
    }
    link += `?${stringify({ contract: contractParam, client: clientParam })}`
  }
  return link
}

/**
 * It creates route url for seeing all deliveries for a given deliverable
 * @param {String} client
 * @param {String} contract
 */
export const createDeliveriesLinkUrl = (deliverable = {}, suffix = 'config') => {
  const { id, name } = deliverable
  let link = `/admin/dm/delivery-${suffix}`
  if (id) {
    const deliverableParam = `${id}-${encodeNameForUrl(name)}`
    link += `?${stringify({ deliverable: deliverableParam })}`
  }
  return link
}

export const createFindDeliveryLinkUrl = (deliveryId, deliverableId) =>
  `/admin/dm/delivery-current?deliverable=${deliverableId}&deliveryId=${deliveryId}&locateDelivery=true`

export const getSelectedPage = (location = window.location) => {
  const { params: { page } = {} } = matchPath(location.pathname, {
    path: '/admin/dm/:page?'
  })
  return page
}

export const isDeliveryPage = (page = '') => page.startsWith('delivery')

/**
 * This method extracts selectedPage, selectedClient, selectedContract from location.pathName. If location is not provided, it defaults to window.location
 * @param {object} location
 */
export const extractCCDPathParams = (location = window.location) => {
  const { client: clientStr, contract: contractStr, deliverable: deliverableStr } = parse(location.search)
  let client
  let contract
  let deliverable
  if (clientStr) {
    const [, clientId, clientName] = clientStr.match(/(\d+)(?:-?(.*))/) || []
    if (clientId) {
      client = {
        id: Number(clientId),
        name: clientName
      }
      if (contractStr) {
        const [, contractId, contractName] = contractStr.match(/(\d+)(?:-?(.*))/) || []
        if (contractId) {
          contract = {
            id: Number(contractId),
            name: contractName
          }
        }
      }
    }
  }
  if (deliverableStr) {
    const [, deliverableId, deliverableName] = deliverableStr.match(/(\d+)(?:-?(.*))/) || []
    if (deliverableId) {
      deliverable = {
        id: Number(deliverableId),
        name: deliverableName
      }
    }
  }
  return {
    client,
    contract,
    deliverable
  }
}

export const deliverableConfigSteps = {
  BASIC_INFO: 'basic-info',
  DATA_SELECTION: 'data-selection',
  REPORTS: 'config-reports',
  SCRIPTS: 'config-scripts'
}

export const currentDeliverySteps = {
  DATA_CLEANUP: 'data-cleanup-jobs',
  DATA_CUSTOMIZATION: 'data-customization',
  QC: 'data-qc',
  PROD: 'data-prod'
}
