import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const ModalContext = createContext({
  isOkButtonDisabled: false,
  setOkButtonDisabled: () => {}
})

const ModalProvider = ({ children }) => {
  const [isOkButtonDisabled, setIsOkButtonDisabled] = useState(false)

  const setOkButtonDisabled = disabled => {
    setIsOkButtonDisabled(disabled)
  }

  return <ModalContext.Provider value={{ isOkButtonDisabled, setOkButtonDisabled }}>{children}</ModalContext.Provider>
}

ModalProvider.propTypes = {
  children: PropTypes.node
}

export { ModalContext, ModalProvider }
