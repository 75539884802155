import { LOGOUT_SUCCESS } from '../actions/auth-actions'
import {
  FETCH_ROPS_BRANDS,
  FETCH_ROPS_CATEGORIES,
  FETCH_DELIVERABLES_BY_CATEGORY,
  FETCH_ROPS_BRANDS_SUCCESS,
  FETCH_ROPS_CATEGORIES_SUCCESS,
  FETCH_ROPS_MERCHANTS_SUCCESS,
  FETCH_DELIVERABLES_BY_CATEGORY_SUCCESS,
  CREATE_BRAND,
  CREATE_CATEGORY,
  CREATE_BRAND_SUCCESS,
  CREATE_CATEGORY_SUCCESS,
  EDIT_BRAND,
  EDIT_CATEGORY,
  EDIT_BRAND_SUCCESS,
  EDIT_CATEGORY_SUCCESS,
  TAXONOMY_ERROR,
  UPDATE_BRANDS_TABLE,
  UPDATE_CATEGORIES_TABLE,
  UPDATE_MERCHANTS_TABLE,
  FETCH_BRAND_SUGGESTIONS_SUCCESS,
  FETCH_CATEGORY_SUGGESTIONS_SUCCESS
} from '../actions/taxonomy-actions'

import { DATA_TABLE } from './../components/DataTable/constants'

const initialState = {
  brands: {
    loading: false,
    page: 1,
    pageSize: DATA_TABLE.PAGE_SIZE,
    sortBy: 'id',
    sortOrder: 'asc',
    suggestions: [],
    filters: {},
    data: {
      count: 0,
      rows: []
    }
  },
  categories: {
    loading: false,
    page: 1,
    pageSize: DATA_TABLE.PAGE_SIZE,
    sortBy: 'id',
    sortOrder: 'asc',
    suggestions: [],
    filters: {},
    data: {
      count: 0,
      rows: []
    },
    deliverables: {
      loading: false,
      data: {
        count: 0,
        rows: []
      }
    }
  },
  merchants: {
    loading: false,
    page: 1,
    pageSize: DATA_TABLE.PAGE_SIZE,
    sortBy: 'id',
    sortOrder: 'asc',
    filters: {},
    data: {
      count: 0,
      rows: []
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROPS_BRANDS:
    case CREATE_BRAND:
    case EDIT_BRAND:
      return {
        ...state,
        brands: {
          ...state.brands,
          loading: true
        }
      }

    case FETCH_ROPS_CATEGORIES:
    case CREATE_CATEGORY:
    case EDIT_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: true
        }
      }

    case FETCH_DELIVERABLES_BY_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          deliverables: {
            loading: true,
            data: {
              count: 0,
              rows: []
            }
          }
        }
      }

    case FETCH_DELIVERABLES_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          deliverables: {
            loading: false,
            data: action.deliverables
          }
        }
      }

    case FETCH_ROPS_BRANDS_SUCCESS:
      return {
        ...state,
        brands: {
          ...state.brands,
          loading: false,
          data: action.brands
        }
      }

    case FETCH_ROPS_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          data: action.categories
        }
      }

    case FETCH_ROPS_MERCHANTS_SUCCESS:
      return {
        ...state,
        merchants: {
          ...state.merchants,
          loading: false,
          data: action.merchants
        }
      }

    case CREATE_BRAND_SUCCESS:
    case EDIT_BRAND_SUCCESS:
      return {
        ...state,
        brands: {
          ...state.brands,
          loading: false
        }
      }

    case EDIT_CATEGORY_SUCCESS:
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false
        }
      }

    case TAXONOMY_ERROR:
      return {
        ...state,
        brands: {
          ...state.brands,
          loading: false
        },
        categories: {
          ...state.categories,
          loading: false
        }
      }

    case FETCH_BRAND_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        brands: {
          ...state.brands,
          suggestions: action.suggestions
        }
      }

    case FETCH_CATEGORY_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          suggestions: action.suggestions
        }
      }

    case UPDATE_BRANDS_TABLE:
      return {
        ...state,
        brands: {
          ...state.brands,
          ...action.brandsTableConfig
        }
      }

    case UPDATE_CATEGORIES_TABLE:
      return {
        ...state,
        categories: {
          ...state.categories,
          ...action.categoriesTableConfig
        }
      }
    case UPDATE_MERCHANTS_TABLE:
      return {
        ...state,
        merchants: {
          ...state.merchants,
          ...action.merchantsTableConfig
        }
      }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}
