import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from '../Loader'
import cx from 'classnames'

class EmbeddedRenderer extends Component {
  render() {
    const { url, fullscreen, sourceName, className, styles = {}, iframeHeight, onLoad } = this.props

    return (
      <div className={cx('embedded-frame-renderer', className)} style={styles}>
        {(!url || url === 'loading') && <Loader />}
        {url && url !== 'invalid' && url !== 'loading' && (
          /* if url is not falsy i.e [null, undefined, ''].. & not 'invalid' show iframe */
          <iframe
            name={sourceName}
            ref={el => {
              this.frame = el
            }}
            title={sourceName}
            allowFullScreen={fullscreen}
            height={iframeHeight}
            src={url}
            onLoad={() => {
              if (onLoad && typeof onLoad === 'function') onLoad()
            }}
            style={{
              width: '100%',
              border: '0px'
            }}
          />
        )}
      </div>
    )
  }
}

EmbeddedRenderer.propTypes = {
  className: PropTypes.string,
  iframeHeight: PropTypes.string,
  url: PropTypes.string,
  fullscreen: PropTypes.bool,
  onLoad: PropTypes.func,
  sourceName: PropTypes.string,
  height: PropTypes.string,
  styles: PropTypes.object
}

export default EmbeddedRenderer
