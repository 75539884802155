import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getProductRoute } from './routes'

const DefaultPageRouter = ({ session, history }) => {
  useEffect(() => {
    if (session && session.user) {
      const route = getProductRoute(session.user.defaultProduct, session)
      history.push(route)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

DefaultPageRouter.propTypes = {
  session: PropTypes.object,
  history: PropTypes.object
}

const mapStateToProps = ({ session }) => ({
  session
})

export default connect(mapStateToProps, null)(DefaultPageRouter)
