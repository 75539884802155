import { takeLatest, takeEvery, select, call, put, fork } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import cuid from 'cuid'
import axios from 'axios'
import contentRange from 'content-range'
import { omit, some, get as getProperty } from 'lodash'

import { errorModal, successModal } from '../actions/modal-actions'

import * as actions from '../actions/custom-report-actions'
import * as editActions from '../actions/custom-report-edit-actions'
import { ALERT_CREATE } from '../actions/app-actions'

import ApiService from '../services/api-service'
import { refreshProfile } from '../sagas/auth-sagas'

const saveReportQuery = (token, payload) => ApiService.isAuth(token).post('/api/reports/create', payload)
/**
 * @summary Gets all reports available on the platform
 * @param {none}
 */
function* fetchAllReports() {
  try {
    yield put(actions.fetchAllReportsSuccess({ loading: true }))
    const response = yield call(() => axios.get(`/api/reports`))
    yield put(
      actions.fetchAllReportsSuccess({
        loading: false,
        allReports: response.data
      })
    )
  } catch (error) {
    window.captureException(error)
    yield put(errorModal(`Error occurred while fetching portal reports. Please try again by refreshing the page`))
  }
}

function* fetchReportsFlow() {
  yield takeLatest(actions.FETCH_REPORTS, fetchReports)
}

function* fetchReports(action = {}) {
  const state = yield select(state => state)
  try {
    const response = yield call(
      state =>
        axios.get(`/api/reports-list`, {
          params: {
            sort: 'name',
            offset: action.offset,
            count: action.count,
            [`${state.reports.selectedSearchEntity}Param`]: action.search ? action.search : undefined,
            searchEntity: state.reports.selectedSearchEntity
          }
        }),
      state
    )

    const parts = contentRange.parse(response.headers['content-range'])
    const count = parts ? parts.length : 0
    yield put({
      type: actions.FETCH_REPORTS_SUCCESS,
      data: response.data,
      offset: action.offset,
      count
    })
  } catch (error) {
    console.error(error)
    window.captureException(error)
    yield put({
      type: actions.FETCH_REPORTS_FAILED,
      message: error.message
    })
  }
}

function* fetchAllReportsFlow() {
  yield takeLatest(actions.FETCH_ALL_REPORTS, fetchAllReports)
}

/**
 * @summary Gets all report details for the given report id.
 * @param {string} reportId The report id for which the data has to be fetched.
 * @param {boolean} isView Determines if the data being fetched is for view or edit.
 */
function* fetchReportDetails({ reportId, mode }) {
  try {
    const response = yield call(() =>
      axios.get(`/api/reports/${reportId}`, {
        params: {
          mode
        }
      })
    )
    if (response.error || response.data.error) {
      throw new Error(response.message || response.data.message)
    }

    yield put(
      actions.fetchReportDetailsSuccess({
        loading: false,
        reportDetails: mode === 'view' ? response.data : {}
      })
    )
    if (mode === 'edit') {
      yield put(actions.initializeReport({ payload: response.data }))
    } else if (mode === 'replicate') {
      return response.data
    }
  } catch (error) {
    window.captureException(error)
    errorModal(error.response.data || error.response)
  }
}

function* fetchReportDetailsFlow() {
  yield takeLatest(actions.FETCH_REPORT_DETAILS, fetchReportDetails)
}

/**
 * @summary Creates a new report and all its associations in the DB.
 * @param  {Object}    payload An object containing all the report data.
 * @return {Object} The response object containg the new report id with success message.
 */
function* createReport({ payload }) {
  try {
    const { auth } = yield select(state => state.session)
    const response = yield call(saveReportQuery, auth.token, payload)
    if (response.error) {
      throw new Error(response.message)
    }
    yield put(actions.resetReportState())
    yield call(refreshProfile)
    if (payload && payload.replicate) {
      yield put({
        type: actions.REPORT_REPLICATE_END,
        alerts: [
          {
            id: cuid(),
            type: 'success',
            message: `Report ${payload.report.name} has been created`,
            headline: 'Report Manager'
          }
        ]
      })
    }
    yield put(actions.fetchReports(0, 30, ''))
    return response
  } catch (error) {
    window.captureException(error)
    if (payload && payload.replicate) {
      createErrorAlert(error.message)
    } else {
      yield put(errorModal(error.message))
    }
  }
}

function* createErrorAlert(errorMessage) {
  yield put({
    type: ALERT_CREATE,
    alerts: [
      {
        id: cuid(),
        type: 'danger',
        message: errorMessage,
        headline: 'Report Manager'
      }
    ],
    alertTimeout: 0
  })
}

function* createReportFlow() {
  yield takeEvery(actions.CREATE_REPORT, createReport)
}

/**
 * @summary Deletes the report with all its associations.
 * @param  {Object} report The object containg report id and other basic details.
 * @param  {number} groupId The group to which this report is associated.
 */
function* deleteReport({ report }) {
  try {
    const response = yield call(() => axios.delete(`/api/reports/${report.id}`))
    if (response.data.error) {
      throw new Error(response.data.message)
    }
    yield call(refreshProfile)
    yield put(actions.fetchReports(0, 30, ''))
  } catch (error) {
    window.captureException(error)
    yield put(errorModal(error.message))
  }
}

/**
 * @summary Replicates a report with all its associations with a new name.
 * @param  {Object} report The basic report details conating id and name.
 * @param  {number} groupId The group with which this report is already associated
 * @see createReport
 */
function* replicateReport({ report, groupId }) {
  try {
    yield put({
      type: actions.REPLICATE_REPORT_START,
      alerts: [
        {
          id: cuid(),
          type: 'info',
          message: `Replicating report ${report.name}...`,
          headline: 'Report Manager'
        }
      ]
    })
    const reportDetails = yield fetchReportDetails({
      reportId: report.id,
      mode: 'replicate'
    })
    const reportData = omit(report, ['Tabs', 'Filters', 'createdAt', 'updatedAt'])
    reportData.id = `new_${reportData.id}`
    const tabFilters = {}
    const formatFilterAndValues = function(filter) {
      const updatedFilter = omit(filter, ['FilterValues'])
      updatedFilter.id = `new_${updatedFilter.id}`
      tabFilters[filter.id] = null
      updatedFilter.FilterValues = filter.FilterValues.map(filterValue => {
        const updatedValue = omit(filterValue, ['updatedAt', 'createdAt'])
        updatedValue.id = `new_${updatedValue.id}`
        return updatedValue
      })
      return updatedFilter
    }

    reportData.Tabs = reportDetails.Tabs.map(tab => {
      const updatedTab = omit(tab, ['Filters'])
      updatedTab.id = `new_${updatedTab.id}`
      updatedTab.Filters = tab.Filters.map(filter => {
        return formatFilterAndValues(filter)
      })
      return updatedTab
    })
    reportDetails.Filters = reportDetails.Filters.filter(filter => {
      return !tabFilters.hasOwnProperty(filter.id)
    })
    reportData.Filters = reportDetails.Filters.map(filter => {
      return formatFilterAndValues(filter)
    })
    yield createReport({
      payload: { report: reportData, groupId, replicate: true }
    })
  } catch (error) {
    window.captureException(error)
    createErrorAlert(`Error occurred while replicating report details. Please try again.\n`)
  }
}

function* deleteReportFlow() {
  yield takeEvery(actions.DELETE_REPORT, deleteReport)
}

function* replicateReportFlow() {
  yield takeEvery(actions.REPLICATE_REPORT, replicateReport)
}

export function* queryFilterValues({ payload }) {
  try {
    const { filter, reportRefId, filterRefId, baseTable } = payload
    const result = yield axios.post(`/api/report/query/filter-values/`, {
      filter,
      baseTable
    })
    if (result.data.error) {
      // handling custom SQL errors
      throw new Error(result.data.message)
    }
    yield put(successModal(result.data.message))
    yield put(
      editActions.queryFilterValuesSuccess({
        filter: result.data.filter,
        dateValues: result.data.dateValues,
        reportRefId,
        filterRefId
      })
    )
  } catch (error) {
    yield put({
      type: editActions.QUERY_FILTER_VALUES_FAILED
    })
    window.captureException(error)
    if (error.message) {
      yield put(errorModal(`${error.message}`, 'Error while fetching filter values'))
    } else {
      yield put(errorModal(`Error while fetching filter values `))
    }
  }
}

function* queryFilterValuesFlow() {
  yield takeLatest(editActions.QUERY_FILTER_VALUES, queryFilterValues)
}

export function* updateFilterDefaults({ payload }) {
  try {
    const resp = yield axios.post('/api/reports/update-filter-defaults', {
      payload
    })
    if (resp.data.error) {
      throw new Error(resp.message)
    } else {
      const { reports } = yield select(state => state)
      yield fetchReportDetails({
        reportId: reports.reportDetails.id,
        mode: 'view'
      })
    }
  } catch (error) {
    window.captureException(error)
    yield put(errorModal(error.message))
  }
}

function* updateFilterDefaultsFlow() {
  yield takeLatest(actions.UPDATE_FILTER_DEFAULTS, updateFilterDefaults)
}

function* createDashboardUrl(action) {
  try {
    const result = yield call(() => axios.post('/api/reports/dashboard/create', { payload: action.data }))
    yield put(actions.updateDashboardUrl(action.reportId, action.tabId, result.data))
  } catch (error) {
    const extraInfo = getProperty(error, 'response.data.error', '')
    window.captureException(error)
    yield put(errorModal(`There was an error loading report. ${extraInfo}`))
    yield put(
      actions.updateDashboardUrl(action.reportId, action.tabId, {
        url: 'invalid'
      })
    )
  }
}

function* createDashbaordUrlFlow() {
  yield takeLatest(actions.CREATE_DASHBOARD_URL, createDashboardUrl)
}

function* fetchReportForEdit({ id }) {
  try {
    const result = yield call(() => axios.get(`/api/reports/${id}/edit`))
    yield put({
      type: editActions.FETCH_REPORT_FOR_EDIT_SUCCESS,
      id,
      data: result.data
    })

    // Fetch report folders
    const folderId = getProperty(result, 'data.report.folderId', 0)
    yield fetchReportFoldersWithParents({ id: folderId })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: editActions.FETCH_REPORT_FOR_EDIT_FAILED,
      id,
      error: error.message || 'There was an error fetching Report details.'
    })
  }
}

function* fetchReportFolders({ id }) {
  try {
    const result = yield call(() => axios.get(`/api/report-folders/${id}`))
    yield put({
      type: editActions.FETCH_REPORT_FOLDERS_SUCCESS,
      data: {
        [result.data.id]: result.data
      }
    })
  } catch (error) {
    window.captureException(error)
    errorModal('There was an error fetching folder details')
  }
}

function* fetchReportFoldersWithParents({ id }) {
  try {
    const result = yield call(() => axios.get(`/api/report-folders/parents/${id}`))
    yield put({
      type: editActions.FETCH_REPORT_FOLDERS_SUCCESS,
      data: result.data
    })
  } catch (error) {
    window.captureException(error)
  }
}

function* saveEditReport() {
  try {
    const {
      reportEdit: {
        basicDetailsForm: basicDetails = {},
        filterForm: { filters = {} },
        tabForm: { tabs = {}, tabIdList = [] }
      }
    } = yield select(state => state)
    tabIdList.forEach((tabId, index) => {
      if (tabs[tabId]) {
        tabs[tabId].order = index
        // update all existing tabs with new ordering
        if (tabs[tabId].action !== 'create') {
          tabs[tabId].action = 'update'
        }
      }
    })

    const tabsToUpdate = Object.values(tabs).filter(tab => tab.action)
    const filtersToUpdate = Object.values(filters)
      // skip filter if they have no changes
      .filter(filterObject => filterObject.action)
      .map(filter => {
        const fvs = filter.FilterValues
        return {
          ...filter,
          periscopeName: filter.periscopeName.trim(),
          // skip FilterValues if none of them have change
          // take all otherwise
          FilterValues: some(fvs, 'action') ? fvs : undefined
        }
      })

    validateReportDetails(basicDetails, tabs)
    if (!basicDetails.dirty && tabsToUpdate.length === 0 && filtersToUpdate.length === 0) {
      throw new Error('No changes found to Save!')
    }
    const response = yield call(() =>
      axios.post(`/api/reports/save`, {
        basicDetails,
        filters: filtersToUpdate,
        tabs: tabsToUpdate
      })
    )

    yield put({
      type: editActions.SAVE_REPORT_DONE,
      reportId: response.data.id
    })

    yield put(replace(`/admin/reports/edit/${response.data.id}`))
  } catch (error) {
    window.captureException(error)
    const message = 'There was an error saving the Report.'
    yield put(errorModal(message))
    yield put({
      type: editActions.SAVE_REPORT_DONE,
      error: message
    })
  }
}

function validateReportDetails(basicDetails = {}, tabs = {}) {
  const { id: reportId, isNameValid } = basicDetails
  const isNewReport = !reportId || reportId === 'new'
  if (
    // new report should have valid fields
    (isNewReport && !isNameValid) ||
    // old report should ensure fields are untouched or valid
    (!isNewReport && isNameValid === false)
  ) {
    throw new Error('Basic report details missing')
  }
  const remainingTabCount = Object.values(tabs).filter(tab => tab.action !== 'delete').length
  if (remainingTabCount === 0) {
    throw new Error('Cannot save a Report without any Tabs')
  }
  return true
}

function* editReportFlow() {
  yield takeLatest(editActions.FETCH_REPORT_FOR_EDIT, fetchReportForEdit)
  yield takeLatest(editActions.SAVE_REPORT, saveEditReport)
  yield takeLatest(editActions.FETCH_REPORT_FOLDERS, fetchReportFolders)
}

export default [
  fork(fetchAllReportsFlow),
  fork(fetchReportDetailsFlow),
  fork(replicateReportFlow),
  fork(deleteReportFlow),
  fork(createReportFlow),
  fork(queryFilterValuesFlow),
  fork(updateFilterDefaultsFlow),
  fork(fetchReportsFlow),
  fork(createDashbaordUrlFlow),
  fork(editReportFlow)
]
