import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CheckBox from '../../CheckBox'

import { toggleGroupProduct } from '../../../actions/group-edit-actions'

class ProductToggle extends Component {
  handleProductToggle = () => {
    const { groupId, productId, productEnabled, toggleGroupProduct } = this.props

    toggleGroupProduct({ groupId, productId, enabled: !productEnabled })
  }

  render() {
    const { productEnabled } = this.props

    return (
      <div className="toggle-group-product">
        <CheckBox checked={productEnabled} label={'Enable this product'} valueDidChange={this.handleProductToggle} />
      </div>
    )
  }
}

ProductToggle.propTypes = {
  groupId: PropTypes.string,
  productId: PropTypes.number,
  productKey: PropTypes.string,
  productEnabled: PropTypes.bool,
  toggleGroupProduct: PropTypes.func
}

const mapStateToProps = ({ session: { products }, groupEdit: { basicInfo } }, { productKey }) => {
  const productEnabled = basicInfo && basicInfo.Products ? basicInfo.Products[productKey] !== undefined : false

  const productId = products && products[productKey] ? products[productKey].id : null

  return {
    productId,
    productEnabled
  }
}

export default connect(mapStateToProps, { toggleGroupProduct })(ProductToggle)
