// @flow
export const FETCH_ALL_REPORTS = 'FETCH_ALL_REPORTS'
export const FETCH_REPORT_DETAILS = 'FETCH_REPORT_DETAILS'
export const FETCH_ALL_GROUP_REPORTS = 'FETCH_ALL_GROUP_REPORTS'
export const FETCH_ALL_REPORTS_SUCCESS = 'FETCH_ALL_REPORTS_SUCCESS'
export const FETCH_REPORT_DETAILS_SUCCESS = 'FETCH_REPORT_DETAILS_SUCCESS'
export const FETCH_ALL_GROUP_REPORTS_SUCCESS = 'FETCH_ALL_GROUP_REPORTS_SUCCESS'
export const CREATE_REPORT = 'CREATE_REPORT'
export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS'
export const REPLICATE_REPORT = 'REPLICATE_REPORT'
export const DELETE_REPORT = 'DELETE_REPORT'
export const RESET_INITIAL_FIELD_VALUES = 'RESET_INITIAL_FIELD_VALUES'
export const RESET_REPORT_STATE = 'RESET_REPORT_STATE'
export const INITIALIZE_REPORT = 'INITIALIZE_REPORT'
export const UPDATE_FILTER_DEFAULTS = 'UPDATE_FILTER_DEFAULTS'
export const REPLICATE_REPORT_START = 'REPLICATE_REPORT_START'
export const REPORT_REPLICATE_END = 'REPORT_REPLICATE_END'
export const REPORT_REPLICATE_ERROR = 'REPORT_REPLICATE_ERROR'
export const FETCH_REPORTS = 'FETCH_REPORTS'
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS'
export const FETCH_REPORTS_FAILED = 'FETCH_REPORTS_FAILED'
export const CUSTOM_REPORTS_SEARCH_ENTITY_CHANGED = 'CUSTOM_REPORTS_SEARCH_ENTITY_CHANGED'
export const CREATE_DASHBOARD_URL = 'CREATE_DASHBOARD_URL'
export const UPDATE_DASHBOARD_URL = 'UPDATE_DASHBOARD_URL'

export function createDashboardURL(reportId, tabId, data, outdatedTabIds) {
  return {
    type: CREATE_DASHBOARD_URL,
    reportId,
    tabId,
    data,
    outdatedTabIds
  }
}

export function updateDashboardUrl(reportId, tabId, data) {
  return {
    type: UPDATE_DASHBOARD_URL,
    reportId,
    tabId,
    data
  }
}
export function fetchReports(offset, count, search): Object {
  return {
    type: FETCH_REPORTS,
    offset,
    count,
    search
  }
}
export function fetchAllReports() {
  return {
    type: FETCH_ALL_REPORTS
  }
}

export function fetchAllReportsSuccess(payload) {
  return {
    type: FETCH_ALL_REPORTS_SUCCESS,
    payload
  }
}

export function fetchReportDetails({ payload }) {
  return {
    type: FETCH_REPORT_DETAILS,
    ...payload
  }
}

export function fetchReportDetailsSuccess(payload) {
  return {
    type: FETCH_REPORT_DETAILS_SUCCESS,
    payload
  }
}

export function createReport(payload) {
  return {
    type: CREATE_REPORT,
    payload
  }
}

export function deleteReport({ payload }) {
  return {
    type: DELETE_REPORT,
    ...payload
  }
}

export function replicateReport({ payload }) {
  return {
    type: REPLICATE_REPORT,
    ...payload
  }
}

export function resetReportState() {
  return {
    type: RESET_REPORT_STATE
  }
}

export function initializeReport({ payload }) {
  return {
    type: INITIALIZE_REPORT,
    payload
  }
}

export function updateFilterDefaults({ payload }) {
  return {
    type: UPDATE_FILTER_DEFAULTS,
    payload
  }
}

export function customReportsSearchEntityChanged(selectedSearchEntity: String): Object {
  return {
    type: CUSTOM_REPORTS_SEARCH_ENTITY_CHANGED,
    selectedSearchEntity
  }
}
