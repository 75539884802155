import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Loader from '../Loader'
import RVTable from '../Table/ReactVirtualizedTable'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { has, keys } from 'lodash'
import updateRelativePermissions from './updateRelativePermissions'

const PermissionTable = ({ data, height, permissions, fetchPermissions, onChange }) => {
  const [newPermissions, setNewPermissions] = useState(data)

  const getColumns = () => {
    return [
      {
        key: 'label',
        label: 'label',
        width: 100,
        // eslint-disable-next-line react/display-name
        cellRenderer: data => {
          return (
            <OverlayTrigger placement="top" overlay={<Tooltip id={data.id}>{data.key}</Tooltip>}>
              <span className="slice-teal">{data.label}</span>
            </OverlayTrigger>
          )
        }
      },
      {
        key: 'description',
        label: 'description',
        width: 400
      },
      {
        key: 'value',
        label: 'Enabled',
        type: 'checkbox'
      }
    ]
  }

  const getData = () => {
    const permissionKeys = keys(permissions.data)
    return permissionKeys.map(key => {
      return {
        ...permissions.data[key],
        value: has(newPermissions, key) ? newPermissions[key] : false
      }
    })
  }

  const onCheckedValueChange = (key, val) => {
    setNewPermissions({
      ...newPermissions,
      [key]: val,
      // This behavior adds lower permission automatically when adding Admin in SupraQC and vice versa
      ...updateRelativePermissions({ key, val })
    })

    onChange({ [key]: val, ...updateRelativePermissions({ key, val }) })
  }

  if (!permissions) {
    return <Loader />
  }

  return (
    <div>
      <div>
        <RVTable
          enableSearch={false}
          data={getData()}
          columns={getColumns()}
          height={height}
          remoteRowCount={permissions.count}
          fetchData={fetchPermissions}
          onCheckedValueChange={onCheckedValueChange}
        />
      </div>
    </div>
  )
}

PermissionTable.propTypes = {
  permissions: PropTypes.shape({
    data: PropTypes.object,
    count: PropTypes.number,
    offset: PropTypes.number
  }),
  data: PropTypes.object, // { 'abc': true, 'def': false }
  fetchPermissions: PropTypes.func,
  onChange: PropTypes.func,
  height: PropTypes.number
}

export default PermissionTable
