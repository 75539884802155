import { all } from 'redux-saga/effects'

import groupFlow from './sagas/group-sagas'
import userFlow from './sagas/user-sagas'
import jobFlow from './sagas/job-sagas'
import feedFlow from './sagas/feed-sagas'
import partnerFlow from './sagas/partner-sagas'
import mailboxProviderFlow from './sagas/mailbox-provider-sagas'
import partnerMailboxProviderFlow from './sagas/partner-mailbox-provider-sagas'
import merchantFlow from './sagas/merchant-sagas'
import merchantFieldsFlow from './components/Taxonomy/Merchants/merchantSaga'
import categoryFlow from './sagas/category-sagas'
import authFlow from './sagas/auth-sagas'
import reportFlow from './sagas/custom-reports-sagas'
import appFlow from './sagas/app-sagas'
import helpDocFlow from './sagas/help-doc-sagas'
import authLogsFlow from './sagas/authlogs-sagas'
import deliveryJobsFlows from './components/DeliveryJobsPage/DeliveryJobs/deliveryJobsSaga'
import categoryTestsFlows from './components/DeliveryJobsPage/CategoryTests/categoryTestsSaga'
import deliveryManagerFlow from './sagas/dm-sagas'
import deliveryConfigFlow from './sagas/delivery-config-sagas'
import deliveryFlow from './sagas/delivery-sagas'
import groupEditFlow from './sagas/group-edit-sagas'
import reportFoldersFlow from './sagas/report-folders-sagas'
import taxonomyFlow from './sagas/taxonomy-sagas'
import itemAttributeFlow from './sagas/item-attributes-sagas'

import rulesFlow from './components/Rules/sagas'
import suggestionsFlow from './components/Suggestion/sagas'
import dataFeedModulesFlow from './components/DataFeeds/FeedModules/sagas'
import supraQCToolV2Flow from './components/SupraQCToolPage/sagas'
import cmeInspectorFlow from './components/CMEInspectorPage/cmeInspectorSaga'
import CategoryGuidelineFlow from './components/QCSupportPage/CategoryGuideline/sagas'
import RequestsFlow from './components/QCSupportPage/Requests/sagas'
import metricsSaga from './components/content/widgets/Metrics/metricsSaga'
import cmeRetrainerFlow from './components/Retrainer/sagas'

function* saga() {
  yield all([
    ...authFlow,
    ...groupFlow,
    ...userFlow,
    ...jobFlow,
    ...feedFlow,
    ...dataFeedModulesFlow,
    ...deliveryJobsFlows,
    ...categoryTestsFlows,
    ...partnerFlow,
    ...mailboxProviderFlow,
    ...partnerMailboxProviderFlow,
    ...merchantFlow,
    ...merchantFieldsFlow,
    ...categoryFlow,
    ...reportFlow,
    ...appFlow,
    ...helpDocFlow,
    ...authLogsFlow,
    ...deliveryManagerFlow,
    ...deliveryConfigFlow,
    ...deliveryFlow,
    ...groupEditFlow,
    ...reportFoldersFlow,
    ...taxonomyFlow,
    ...itemAttributeFlow,
    ...rulesFlow,
    ...suggestionsFlow,
    ...supraQCToolV2Flow,
    ...cmeInspectorFlow,
    ...CategoryGuidelineFlow,
    ...RequestsFlow,
    ...metricsSaga,
    ...cmeRetrainerFlow
  ])
}

export default saga
