import { EventEmitter } from 'events'
import cuid from 'cuid'

class AuthService extends EventEmitter {
  // Setting logout-event key triggers logout in other open tabs. Random to ensure re-assign each time
  logout() {
    window.localStorage.setItem('auth', null)
    window.localStorage.setItem('logout-event', `logout-${cuid()}`)
  }

  setAuth(auth) {
    window.localStorage.setItem('auth', JSON.stringify(auth))
  }

  redirectToLogin(replace, location) {
    location = location || window.location
    replace = replace || window.location.replace.bind(window.location)
    const redirectUrl = location.pathname + location.search
    // Below code also fixes IE 11 re-direction issue [ch3447] during onboarding where IE 11
    // fires local storage event that leads to this function being called on the same tab
    if (
      !redirectUrl.startsWith('/login') &&
      !redirectUrl.startsWith('/onboarding') &&
      !redirectUrl.startsWith('/reset_password')
    ) {
      if (redirectUrl && redirectUrl !== '/') {
        replace(`/login?redirectUrl=${encodeURIComponent(redirectUrl)}`)
      } else {
        replace('/login')
      }
    }
  }
}

export default new AuthService()
