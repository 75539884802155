import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { every } from 'lodash'

import DropDownButton from '../../DropDownButton/DropDownButton'
import helpIcon from './images/ic-help'
import './index.scss'

import { toggleBeacons } from '../../../actions/walkthrough-actions'
import { fetchHelpDoc } from '../../../actions/help-doc-actions'

class HelpButton extends Component {
  componentDidUpdate() {
    const { beacons } = this.props
    const beaconGroup = this.getSelectedReport()

    if (every(beacons[beaconGroup], beacon => beacon === false)) {
      this.toggleTutorial(beaconGroup)
    }
  }

  toggleTutorial = () => {
    this.props.toggleBeacons(this.getSelectedReport())
  }

  isEnabled = () => {
    const { periscopeEnabled } = this.props

    return periscopeEnabled
  }

  getSelectedReport = () => {
    return 'periscope'
  }

  getDropdownOptions = () => {
    const faqDropdownOptions = {
      walkthrough: {
        key: 'walkthrough',
        onClick: this.toggleTutorial,
        name: this.isEnabled() ? 'Hide Tutorial' : 'Start Tutorial'
      }
      /*
       * Note: currently we don't use use this functionality.
       * It's disabled in this component in UI, but API (/help-doc/:product/:guideType) still exists in backend
       * See more info in IN-1160
      'user-guide': {
        key: 'user-guide',
        name: 'Download User Guide',
        onClick: this.getHelpDoc
      }
      */
    }

    return faqDropdownOptions
  }

  render() {
    return (
      <DropDownButton
        className="dropDownButton--faq"
        options={this.getDropdownOptions()}
        menuListClass="faq-dropdown"
        icon={helpIcon}
      />
    )
  }
}

HelpButton.propTypes = {
  beacons: PropTypes.object,
  fetchHelpDoc: PropTypes.func,
  toggleBeacons: PropTypes.func,
  periscopeEnabled: PropTypes.bool,
  walkthroughEnabled: PropTypes.bool
}

// connect

const mapStateToProps = ({ walkthrough }) => ({
  beacons: walkthrough.beacons,
  walkthroughEnabled: walkthrough.isEnabled,
  periscopeEnabled: walkthrough.periscopeEnabled
})

const mapDispatchToProps = dispatch => ({
  fetchHelpDoc: (url, targetWindow) => dispatch(fetchHelpDoc(url, targetWindow)),
  toggleBeacons: beaconGroup => dispatch(toggleBeacons(beaconGroup))
})

export default connect(mapStateToProps, mapDispatchToProps)(HelpButton)
