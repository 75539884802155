import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Col, FormGroup, ControlLabel } from 'react-bootstrap'

import Toggle from '../../../Toggle'
import InputField from './InputField'
import DropDownField from './DropDownField'

export default class AdvancedFeedConfig extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showConfig: false
    }
  }

  handleShowHide = clickEvent => {
    clickEvent.preventDefault()
    this.setState({ showConfig: !this.state.showConfig })
  }

  getAdvancedInputs = () => {
    const { formState, handleInputBlur, handleInputChange, handleToggleChange, handleSelectChange } = this.props

    return (
      <React.Fragment>
        <FormGroup>
          <Col sm={2}>
            <ControlLabel>{'Concatenate Files'}</ControlLabel>
          </Col>

          <Col sm={4}>
            <div className="GroupEditPage__toggle">
              <Toggle
                text={''}
                inactiveText={''}
                onChange={handleToggleChange}
                isActive={formState.concatenateFeedFiles}
              />
            </div>
          </Col>
        </FormGroup>

        <InputField
          type="text"
          label="FTPS File Path"
          id="feedSftpPath"
          placeholder="FTPS file path"
          onBlur={handleInputBlur}
          value={formState.feedSftpPath}
          onChange={handleInputChange}
        />

        <DropDownField
          id="ObfuscationTypeId"
          label="Obfuscation Type ID"
          onChange={handleSelectChange}
          value={formState.ObfuscationTypeId}
          options={[
            { label: '1', value: 1 },
            { label: '2', value: 2 }
          ]}
        />

        <DropDownField
          id="feedSftpFileNamingScheme"
          label="File Name Convention"
          onChange={handleSelectChange}
          placeholder="Select File Naming Scheme"
          value={formState.feedSftpFileNamingScheme}
          options={[
            { label: 'sql-stored-prefix (legacy)', value: 'legacy' },
            { label: 'Feed Based', value: 'feed' }
          ]}
        />
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <h4>Advanced Options</h4>

        <span onClick={this.handleShowHide} style={{ cursor: 'pointer' }}>
          {this.state.showConfig ? 'Hide' : 'Show'}
        </span>
        {this.state.showConfig && this.getAdvancedInputs()}
      </React.Fragment>
    )
  }
}

AdvancedFeedConfig.propTypes = {
  formState: PropTypes.object,
  handleInputBlur: PropTypes.func,
  handleInputChange: PropTypes.func,
  handleToggleChange: PropTypes.func,
  handleSelectChange: PropTypes.func
}
