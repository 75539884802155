import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import cx from 'classnames'
import { startCase } from 'lodash'
import DocumentTitle from 'react-document-title'
import './index.scss'

// actions
import { updateFilter } from '../../actions/filter-actions'
import duplicateUsersByGroup from '../../selectors/user-selector'
import { saveUser, fetchUsers, downloadUsers, fetchUsersSearchEntityChanged } from '../../actions/user-actions'

// components
import { Glyphicon, Label } from 'react-bootstrap'
import GroupLogo from '../GroupLogo'
import RVTable from '../Table/ReactVirtualizedTable'
import Loader from '../Loader'
import Unauthorized from '../Unauthorized'
import userDeactivationModal from '../Modals/UserDeactivationModal'

// services
import { UserService } from '../../services'

import { appName } from '../../constants/constants'

class UsersPage extends Component {
  componentDidMount() {
    this.props.fetchUsers(0, this.props.batchSize)
  }

  onGroupClicked = group => {
    this.props.fetchUsersSearchEntityChanged('groups')
    this.props.updateFilter(group)
    this.props.fetchUsers(0, this.props.batchSize, group)
  }

  getColumns = () => {
    return [
      {
        key: 'fullname',
        label: 'Full Name'
      },
      {
        key: 'email',
        label: 'Email'
      },
      {
        key: 'type',
        label: 'Type',
        cellRenderer: data => {
          const group = data.Group
          const getAccountType = () => {
            const accountType = UserService.getAccountTypeString(group.GroupUser, group)
            let className
            switch (accountType) {
              case 'Portal admin':
                className = 'success'
                break
              case 'Group admin':
                className = 'primary'
                break
              case 'Standard User':
              default:
                className = 'default'
                break
            }
            return {
              text: accountType,
              className
            }
          }
          const accountType = getAccountType()
          return <Label bsStyle={accountType.className}>{startCase(accountType.text)}</Label>
        }
      },
      {
        key: 'user_active',
        label: 'User Active',
        cellRenderer: data => {
          if (data.isActive) {
            return <Glyphicon style={{ color: 'green' }} glyph="ok" />
          } else {
            return <Glyphicon style={{ color: 'red' }} glyph="remove" />
          }
        }
      },
      {
        key: 'edit',
        label: 'Edit',
        cellRenderer: data => {
          return <Link to={`/admin/users/${data.id}`}>Edit</Link>
        }
      },
      {
        key: 'group',
        label: 'Group',
        cellRenderer: data => {
          const group = data.Group
          return (
            <div>
              <GroupLogo height={15} width={15} />
              &nbsp;&nbsp;
              <span className="btn_link" onClick={() => this.onGroupClicked(group.name)}>
                {group.name}
              </span>
            </div>
          )
        }
      },
      {
        key: 'group_relation',
        label: 'Group Relation',
        cellRenderer: data => {
          const group = data.Group
          return (
            group.isActive && (
              <div
                onClick={this.changeStatus.bind(this, data, group)}
                className={cx('statusButton', group.GroupUser.isActive ? 'active' : 'inactive')}
              >
                <div className={'statusMarker'} />
              </div>
            )
          )
        }
      },
      {
        key: 'status',
        label: 'Status',
        cellRenderer: data => {
          const group = data.Group
          const getStatus = () => {
            if (!data.isOnboarded) {
              return {
                text: 'Pending',
                className: 'warning'
              }
            }
            if (!group.isActive) {
              return {
                text: 'Group inactive',
                className: 'default'
              }
            }
            if (data.lastLoggedInAt) {
              return {
                text: `Last logged in on ${moment(data.lastLoggedInAt).format('M/D/YYYY')}`
              }
            }
            return {
              text: ''
            }
          }
          const status = getStatus()
          return status.className ? <Label bsStyle={status.className}>{status.text}</Label> : <span>{status.text}</span>
        }
      }
    ]
  }

  changeStatus = (user, group) => {
    const callback = () => {
      return this.props.saveUser(
        {
          user: {
            isFeedNotificationOn: user.isFeedNotificationOn,
            id: user.id,
            isActive: !user.isActive,
            group: {
              id: group.id,
              relation: { isActive: !user.Group.GroupUser.isActive }
            }
          }
        },
        () => this.props.fetchUsers()
      )
    }

    if (user.Group.GroupUser.isActive) {
      return this.props.userDeactivationModal(user, group, callback)
    }

    return callback()
  }

  mapUserData = users => {
    return users.map(user => ({
      id: user.id,
      email: user.email,
      fullname: user.fullname,
      Group: user.Group,
      isVerified: user.isVerified,
      isOnboarded: user.isOnboarded,
      isActive: user.isActive,
      lastLoggedInAt: user.lastLoggedInAt
    }))
  }

  handleDownload = clickEvent => {
    clickEvent.preventDefault()

    const { search, selectedSearchEntity } = this.props
    this.props.downloadUsers(selectedSearchEntity, search)
  }

  render() {
    if (!this.props.isAdmin) return <Unauthorized />

    if (this.props.isUsersLoading) return <Loader loading />

    return (
      <DocumentTitle title={this.props.documentTitle ? `${appName} | ${this.props.documentTitle}` : appName}>
        <div className="container">
          <div className={'textRight'} style={{ paddingBottom: '20px' }}>
            <Link className={'btn_custom'} to="/admin/users/new">
              Add User
            </Link>

            <button
              onClick={this.handleDownload}
              className="btn_custom_secondary download-csv-button-custom pull-right"
            >
              Download CSV
            </button>
          </div>

          <div className="users-list">
            <RVTable
              enableSearch
              minimumBatchSize={this.props.batchSize}
              columns={this.getColumns()}
              data={this.mapUserData(this.props.users)}
              fetchData={this.props.fetchUsers}
              updateFilter={this.props.updateFilter}
              remoteRowCount={this.props.usersCount}
              search={this.props.search}
              query={this.props.query}
              searchEntities={this.props.searchEntities}
              selectedSearchEntity={this.props.selectedSearchEntity}
              onSearchEntityChanged={this.props.fetchUsersSearchEntityChanged}
            />
          </div>
        </div>
      </DocumentTitle>
    )
  }
}

UsersPage.propTypes = {
  users: PropTypes.array.isRequired,
  isUsersLoading: PropTypes.bool,
  searchValueUsers: PropTypes.string,
  searchValueGroups: PropTypes.string,
  onSearchValueChange: PropTypes.func,
  saveUser: PropTypes.func,
  fetchUsers: PropTypes.func.isRequired,
  search: PropTypes.string,
  userProducts: PropTypes.object,
  isAdmin: PropTypes.bool,
  updateFilter: PropTypes.func,
  query: PropTypes.object,
  usersCount: PropTypes.number,
  fetchUsersSearchEntityChanged: PropTypes.func,
  selectedSearchEntity: PropTypes.string,
  searchEntities: PropTypes.object,
  batchSize: PropTypes.number,
  userDeactivationModal: PropTypes.func,
  documentTitle: PropTypes.string,
  downloadUsers: PropTypes.func.isRequired
}

UsersPage.defaultProps = {
  batchSize: 20,
  documentTitle: 'Users'
}

// Connect

const mapStateToProps = (state, ownProps) => ({
  users: duplicateUsersByGroup(state),
  usersCount: state.users.count,
  search: state.filter.users,
  query: ownProps.location.query,
  searchEntities: state.users.searchEntities,
  selectedSearchEntity: state.users.selectedSearchEntity,
  isUsersLoading: state.users.loading,
  isAdmin: UserService.isAdmin(state.session.user)
})

const mapDispatchToProps = dispatch => ({
  fetchUsers: (offset, count, search) => {
    if (search !== null) dispatch(fetchUsers(offset, count, search))
  },
  updateFilter: (text, id) => dispatch(updateFilter(text, 'users')),
  saveUser: (data, callback) => dispatch(saveUser({ ...data, callback })),
  fetchUsersSearchEntityChanged: selected => dispatch(fetchUsersSearchEntityChanged(selected)),
  userDeactivationModal: (user, group, callback) => dispatch(userDeactivationModal(user, group, callback)),
  downloadUsers: (searchEntity, searchTerm) => dispatch(downloadUsers(searchEntity, searchTerm))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersPage))
