import React from 'react'
import IconBase from './IconBase'

/**
 * Drag component
 */
const Drag = props => {
  return (
    <IconBase viewBox="0 0 30 30" id="drag-icon-svg" {...props}>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="drag" fillRule="nonzero" fill="#000000">
          <path
            d="M1,7.97826087 C1,7.42391304 1.40444444,7 1.93333333,7 L28.0666667,7 C28.5955556,7 29,7.42391304 29,7.97826087 C29,8.5326087 28.5955556,8.95652174 28.0666667,8.95652174 L1.93333333,8.95652174 C1.40444444,8.95652174 1,8.5326087 1,7.97826087 Z M28.0666667,13.5217391 L1.93333333,13.5217391 C1.40444444,13.5217391 1,13.9456522 1,14.5 C1,15.0543478 1.40444444,15.4782609 1.93333333,15.4782609 L28.0666667,15.4782609 C28.5955556,15.4782609 29,15.0543478 29,14.5 C29,13.9456522 28.5955556,13.5217391 28.0666667,13.5217391 Z M28.0666667,20.0434783 L1.93333333,20.0434783 C1.40444444,20.0434783 1,20.4673913 1,21.0217391 C1,21.576087 1.40444444,22 1.93333333,22 L28.0666667,22 C28.5955556,22 29,21.576087 29,21.0217391 C29,20.4673913 28.5955556,20.0434783 28.0666667,20.0434783 Z"
            id="Shape"
          />
        </g>
      </g>
    </IconBase>
  )
}

export default Drag
