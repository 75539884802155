import React from 'react'

export default function BreadCrumbSeparator(props) {
  return (
    <svg
      className="bread-crumb-separator"
      width="24px"
      height="12px"
      viewBox="0 0 6 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      {/* Generator: Sketch 51.2 (57519) - http://www.bohemiancoding.com/sketch */}
      <desc>Created with Sketch.</desc>
      <defs />
      <g id="Components" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-162.000000, -202.000000)">
          <g id="ic-arrow" transform="translate(150.000000, 193.000000)">
            <g id="Arrow-line">
              <rect id="spacing" x={0} y={0} width={30} height={30} />
              <polyline id="Path-2" stroke="currentColor" points="12 10 17.7458624 15.5 12 21" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
