export const QC_SPLIT_BY_CHANGED = 'NIQ_QC_SPLIT_BY_CHANGED'
export const QC_DRILLDOWN_BY_CHANGED = 'NIQ_QC_DRILLDOWN_BY_CHANGED'
export const QC_QUERY_RUN_STARTED = 'NIQ_QC_QUERY_RUN_STARTED'
export const QC_QUERY_RUN_SUCCEEDED = 'NIQ_QC_QUERY_RUN_SUCCEEDED'
export const QC_QUERY_RUN_FAILED = 'NIQ_QC_QUERY_RUN_FAILED'
export const QC_TIME_INTERVAL_CHANGED = 'NIQ_QC_TIME_INTERVAL_CHANGED'
export const QC_SELECTED_TIME_RANGE_CHANGED = 'NIQ_QC_SELECTED_TIME_RANGE_CHANGED'
export const QC_CHART_VIEW_CHANGED = 'NIQ_QC_CHART_VIEW_CHANGED'
export const QC_DESCRIPTION_SELECTION_CHANGED = 'NIQ_QC_DESCRIPTION_SELECTION_CHANGED'
export const QC_REFRESH_DESCRIPTION_DATA = 'NIQ_QC_REFRESH_DESCRIPTION_DATA'
export const QC_SHOW_MORE_SPLITBY_CHARTS = 'NIQ_QC_SHOW_MORE_SPLITBY_CHARTS'
export const CHANGE_QC_COLUMNS_VISIBILITY = 'NIQ_CHANGE_QC_COLUMNS_VISIBILITY'
export const QC_DESCRIPTION_COUNT_STARTED = 'NIQ_QC_DESCRIPTION_COUNT_STARTED'
export const QC_DESCRIPTION_COUNT_SUCCEEDED = 'NIQ_QC_DESCRIPTION_COUNT_SUCCEEDED'
export const QC_DESCRIPTION_COUNT_FAILED = 'NIQ_QC_DESCRIPTION_COUNT_FAILED'
export const CHANGE_DESCRIPTION_ITEM_TYPE = 'NIQ_CHANGE_DESCRIPTION_ITEM_TYPE'
export const TOGGLE_DRILLDOWN_ENABLE_STATUS = 'NIQ_TOGGLE_DRILLDOWN_ENABLE_STATUS'
export const REFRESH_QC_GRAPH = 'NIQ_REFRESH_QC_GRAPH'
export const TOGGLE_DESCRIPTION_HISTORY_STATUS = 'NIQ_TOGGLE_DESCRIPTION_HISTORY_STATUS'

export const QC_MONITORING_GRAPHS_CLEANUP = 'NIQ_QC_MONITORING_GRAPHS_CLEANUP'

export function onSplitByChanged(value) {
  return {
    type: QC_SPLIT_BY_CHANGED,
    value
  }
}

export function onDrillDownByChanged(value) {
  return {
    type: QC_DRILLDOWN_BY_CHANGED,
    value
  }
}

export function onChartViewChanged(value) {
  return {
    type: QC_CHART_VIEW_CHANGED,
    value
  }
}

export function onTimeIntervalChanged(value) {
  return {
    type: QC_TIME_INTERVAL_CHANGED,
    value
  }
}

export function onDescriptionSelectionChanged(attribute, value) {
  return {
    type: QC_DESCRIPTION_SELECTION_CHANGED,
    attribute,
    value
  }
}

export function refreshDescriptionData() {
  return {
    type: QC_REFRESH_DESCRIPTION_DATA
  }
}

export function showMoreSplitByCharts(value) {
  return {
    type: QC_SHOW_MORE_SPLITBY_CHARTS,
    value
  }
}

export function onSelectedTimeRangeChanged(value) {
  return {
    type: QC_SELECTED_TIME_RANGE_CHANGED,
    from: value.from,
    to: value.to
  }
}

export function onChangeQcColumnsVisibility(column) {
  return {
    type: CHANGE_QC_COLUMNS_VISIBILITY,
    column
  }
}

export function onChangeDescriptionItemType(itemType) {
  return {
    type: CHANGE_DESCRIPTION_ITEM_TYPE,
    itemType
  }
}

export function onChangeDrilldownEnabledStatus(drillDownType) {
  return {
    type: TOGGLE_DRILLDOWN_ENABLE_STATUS,
    drillDownType
  }
}

export function refreshQCGraphData(payload) {
  return {
    type: REFRESH_QC_GRAPH,
    payload
  }
}

export function onChangeDescriptionHistoryStatus() {
  return {
    type: TOGGLE_DESCRIPTION_HISTORY_STATUS
  }
}
