import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Modal, Button } from 'react-bootstrap'
import cx from 'classnames'
import './index.scss'

import { ModalContext } from '../ModalContext'

// inline to override react-bootstrap styles
const modalStyle = {
  display: 'flex'
}

export default class BaseModal extends Component {
  componentDidMount() {
    const {
      options: { onShow, onShowParams }
    } = this.props

    if (onShow && typeof onShow === 'function') {
      onShow(...onShowParams)
    }
  }

  static contextType = ModalContext

  onModalHide = (confirm = false) => {
    const {
      removeModal,
      confirmModal,
      options: { id, callback }
    } = this.props

    removeModal(id)

    // confirm = true only needed if confirmModal
    if (((confirmModal && confirm) || !confirmModal) && callback && typeof callback === 'function') {
      callback()
    }
  }

  getContent = () => {
    const { errorModal, options } = this.props

    if (errorModal) {
      return (
        <div>{options.content || <span>{'There was an issue completing this action. Please try again.'}</span>}</div>
      )
    }

    return <span style={{ fontSize: '14px' }}>{options.content}</span>
  }

  getFooter = () => {
    const {
      confirmModal,
      options: { cancelButton, okButton, deleteButton }
    } = this.props

    const { isOkButtonDisabled } = this.context

    const isShowFooter = !!confirmModal || !!cancelButton || !!okButton || !!deleteButton

    if (!isShowFooter) {
      return null
    }

    return (
      <Modal.Footer>
        <div className="modal-buttons">
          {(confirmModal || cancelButton) && (
            <Button className="btn-cancel" onClick={() => this.onModalHide(false)}>
              {cancelButton || 'Cancel'}
            </Button>
          )}

          {okButton && (
            <Button className="btn-ok" onClick={() => this.onModalHide(true)} disabled={isOkButtonDisabled}>
              {okButton}
            </Button>
          )}

          {deleteButton && (
            <Button className="btn-delete" onClick={() => this.onModalHide(true)}>
              {deleteButton}
            </Button>
          )}
        </div>
      </Modal.Footer>
    )
  }

  render() {
    const { options, component, errorModal, confirmModal, infoModal, successModal, formModal } = this.props
    // require user to click confirm or cancel
    const allowKeyboard = !confirmModal

    if (confirmModal && component) {
      return component
    }
    return (
      <Modal
        show
        autoFocus
        restoreFocus
        style={modalStyle}
        bsSize={options.size}
        keyboard={allowKeyboard}
        onHide={this.onModalHide}
        backdrop={confirmModal ? 'static' : true}
        bsClass={cx(
          {
            'info-modal': infoModal,
            'form-modal': formModal,
            'error-modal': errorModal,
            'confirm-modal': confirmModal,
            'success-modal': successModal
          },
          options.className,
          'modal'
        )}
      >
        <Modal.Header closeButton={options.closeButton}>
          <Modal.Title>{options.title || options.modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{this.getContent()}</Modal.Body>

        {this.getFooter()}
      </Modal>
    )
  }
}

BaseModal.defaultProps = {
  okButton: 'Close',
  deleteButton: 'Delete'
}

BaseModal.propTypes = {
  removeModal: PropTypes.func,
  onShow: PropTypes.func,
  onShowParams: PropTypes.array,
  className: PropTypes.string,
  content: PropTypes.any,
  infoModal: PropTypes.bool,
  formModal: PropTypes.bool,
  errorModal: PropTypes.bool,
  confirmModal: PropTypes.bool,
  successModal: PropTypes.bool,
  options: PropTypes.object,
  component: PropTypes.element
}
