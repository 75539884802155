export const UPDATE_BRANDS_TABLE = 'UPDATE_BRANDS_TABLE'
export const UPDATE_CATEGORIES_TABLE = 'UPDATE_CATEGORIES_TABLE'
export const UPDATE_MERCHANTS_TABLE = 'UPDATE_MERCHANTS_TABLE'

export const FETCH_ROPS_BRANDS = 'FETCH_ROPS_BRANDS'
export const FETCH_ROPS_CATEGORIES = 'FETCH_ROPS_CATEGORIES'
export const FETCH_ROPS_MERCHANTS = 'FETCH_ROPS_MERCHANTS'

export const FETCH_ROPS_BRANDS_SUCCESS = 'FETCH_ROPS_BRANDS_SUCCESS'
export const FETCH_ROPS_CATEGORIES_SUCCESS = 'FETCH_ROPS_CATEGORIES_SUCCESS'
export const FETCH_ROPS_MERCHANTS_SUCCESS = 'FETCH_ROPS_MERCHANTS_SUCCESS'

export const FETCH_DELIVERABLES_BY_CATEGORY = 'FETCH_DELIVERABLES_BY_CATEGORY'
export const FETCH_DELIVERABLES_BY_CATEGORY_SUCCESS = 'FETCH_DELIVERABLES_BY_CATEGORY_SUCCESS'

export const CREATE_BRAND = 'CREATE_BRAND'
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS'
export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'

export const EDIT_BRAND = 'EDIT_BRAND'
export const EDIT_BRAND_SUCCESS = 'EDIT_BRAND_SUCCESS'
export const EDIT_CATEGORY = 'EDIT_CATEGORY'
export const EDIT_CATEGORY_SUCCESS = 'EDIT_CATEGORY_SUCCESS'

export const DELETE_BRAND = 'DELETE_BRAND'
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS'
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'

export const TAXONOMY_ERROR = 'TAXONOMY_ERROR'

export const FETCH_BRAND_SUGGESTIONS = 'FETCH_BRAND_SUGGESTIONS'
export const FETCH_BRAND_SUGGESTIONS_SUCCESS = 'FETCH_BRAND_SUGGESTIONS_SUCCESS'
export const FETCH_CATEGORY_SUGGESTIONS = 'FETCH_CATEGORY_SUGGESTIONS'
export const FETCH_CATEGORY_SUGGESTIONS_SUCCESS = 'FETCH_CATEGORY_SUGGESTIONS_SUCCESS'

export const DOWNLOAD_BRANDS = 'DOWNLOAD_BRANDS'
export const DOWNLOAD_CATEGORIES = 'DOWNLOAD_CATEGORIES'
export const DOWNLOAD_MERCHANTS = 'DOWNLOAD_MERCHANTS'

export const updateBrandsTable = brandsTableConfig => ({
  type: UPDATE_BRANDS_TABLE,
  brandsTableConfig
})

export const updateCategoriesTable = categoriesTableConfig => ({
  type: UPDATE_CATEGORIES_TABLE,
  categoriesTableConfig
})

export const updateMerchantsTable = merchantsTableConfig => ({
  type: UPDATE_MERCHANTS_TABLE,
  merchantsTableConfig
})

export const fetchBrands = (brandsPayload = {}) => ({
  type: FETCH_ROPS_BRANDS,
  brandsPayload
})

export const fetchCategories = (categoriesPayload = {}) => ({
  type: FETCH_ROPS_CATEGORIES,
  categoriesPayload
})

export const fetchMerchants = (merchantsPayload = {}) => ({
  type: FETCH_ROPS_MERCHANTS,
  merchantsPayload
})

export const fetchDeliverablesByCategory = deliverablesPayload => ({
  type: FETCH_DELIVERABLES_BY_CATEGORY,
  deliverablesPayload
})

export const fetchDeliverablesByCategorySuccess = deliverables => ({
  type: FETCH_DELIVERABLES_BY_CATEGORY_SUCCESS,
  deliverables
})

export const fetchBrandsSuccess = brands => ({
  type: FETCH_ROPS_BRANDS_SUCCESS,
  brands
})

export const fetchCategoriesSuccess = categories => ({
  type: FETCH_ROPS_CATEGORIES_SUCCESS,
  categories
})

export const fetchMerchantsSuccess = merchants => ({
  type: FETCH_ROPS_MERCHANTS_SUCCESS,
  merchants
})

export const createBrand = payload => {
  const { nameList, ancestorId } = payload
  return {
    type: CREATE_BRAND,
    brandPayload: {
      nameList,
      ancestorId
    }
  }
}

export const createCategory = payload => {
  const { nameList, isCPG, ancestorId } = payload
  return {
    type: CREATE_CATEGORY,
    categoryPayload: {
      nameList,
      isCPG,
      ancestorId
    }
  }
}

export const createBrandSuccess = () => ({
  type: CREATE_BRAND_SUCCESS
})

export const createCategorySuccess = () => ({
  type: CREATE_CATEGORY_SUCCESS
})

export const editBrand = brandPayload => ({
  type: EDIT_BRAND,
  brandPayload
})

export const editCategory = categoryPayload => ({
  type: EDIT_CATEGORY,
  categoryPayload
})

export const deleteBrand = brandId => ({
  type: DELETE_BRAND,
  brandId
})

export const deleteCategory = categoryId => ({
  type: DELETE_CATEGORY,
  categoryId
})

export const deleteBrandSuccess = () => ({
  type: DELETE_BRAND_SUCCESS
})

export const deleteCategorySuccess = () => ({
  type: DELETE_CATEGORY_SUCCESS
})

export const taxonomyError = error => ({
  type: TAXONOMY_ERROR,
  error
})

export const fetchBrandSuggestions = searchText => ({
  type: FETCH_BRAND_SUGGESTIONS,
  searchText
})

export const fetchBrandSuggestionsSuccess = suggestions => ({
  type: FETCH_BRAND_SUGGESTIONS_SUCCESS,
  suggestions
})

export const fetchCategorySuggestions = searchText => ({
  type: FETCH_CATEGORY_SUGGESTIONS,
  searchText
})

export const fetchCategorySuggestionsSuccess = suggestions => ({
  type: FETCH_CATEGORY_SUGGESTIONS_SUCCESS,
  suggestions
})

export const downloadBrands = () => ({
  type: DOWNLOAD_BRANDS
})

export const downloadCategories = () => ({
  type: DOWNLOAD_CATEGORIES
})

export const downloadMerchants = () => ({
  type: DOWNLOAD_MERCHANTS
})
