import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

class Popup extends Component {
  state = {
    hOffset: 0
  }

  componentDidMount() {
    window.addEventListener('resize', this.resetPopupPositions)
  }

  componentDidUpdate() {
    this.resetPopupPositions()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resetPopupPositions)
  }

  resetPopupPositions = () => {
    const popupBox = this.popupBox
    const { minEdgePadding = 0, align } = this.props

    if (popupBox && popupBox.parentElement) {
      const { left: pLeft, right: pRight } = popupBox.parentElement.getBoundingClientRect()
      const { width } = popupBox.getBoundingClientRect()
      // We assume body is the container, we want popup to stay inside
      const containerWidth = document.body.clientWidth
      const parentOffset =
        align === 'right'
          ? containerWidth - pRight //  offset from right boundary
          : pLeft // offset from left boundary
      // Minimum total possible width without handling offset
      const totalWidth = parentOffset + width + minEdgePadding
      if (containerWidth < totalWidth) {
        // if containerWidth < totalWidth, we may get unecessary scrollbars
        // hence we apply the difference as horizontal offset
        const newHOffset = containerWidth - totalWidth
        if (this.state.hOffset !== newHOffset) {
          this.setState({
            hOffset: newHOffset
          })
        }
      } else if (this.state.hOffset !== 0) {
        // if scrollbars are not going to appear we just render popup with no offset
        this.setState({
          hOffset: 0
        })
      }
    }
  }

  render() {
    const { isOpen, children, className, align } = this.props
    const { hOffset } = this.state
    if (!isOpen) {
      return null
    }
    return (
      <div
        className={cx(className)}
        style={{
          [align]: hOffset,
          position: 'absolute'
        }}
        ref={el => {
          this.popupBox = el
        }}
      >
        {children}
      </div>
    )
  }
}

Popup.defaultProps = {
  minEdgePadding: 50,
  isOpen: false,
  className: 'filter-popup',
  align: 'left'
}

Popup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  minEdgePadding: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  align: PropTypes.oneOf(['right', 'left'])
}

export default Popup
