import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'

const SortHandle = SortableHandle(() => {
  return (
    <div style={{ display: 'inline-block' }}>
      <Glyphicon glyph="menu-hamburger" className="sort-handle" />
    </div>
  )
})

/**
 * Dumb component to display individual Filter
 * passes all actions/edits to parent
 */
export class ListItem extends PureComponent {
  render() {
    const { item, onClick, sorting, tooltipText } = this.props
    return (
      <li>
        <div style={{ display: 'flex' }}>
          {sorting && <SortHandle />}
          <OverlayTrigger
            key={item.id}
            delayShow={1000}
            placement="top"
            overlay={<Tooltip id="item-click">{tooltipText}</Tooltip>}
          >
            <div style={{ display: 'inline-block' }} onClick={onClick}>
              <span className="unselectable">{item.name}</span>
              {<div className="subtext unselectable">{`${item.periscopeName} (ID: ${item.id})`}</div>}
            </div>
          </OverlayTrigger>
        </div>
      </li>
    )
  }
}

ListItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  sorting: PropTypes.bool,
  tooltipText: PropTypes.string
}

export const SortableItem = SortableElement(ListItem)
