import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Col, HelpBlock, FormGroup, FormControl, ControlLabel } from 'react-bootstrap'

import { groupFormValid, groupFormInvalid } from '../../../../actions/group-edit-actions'

const InputField = props => {
  const {
    id,
    value,
    type,
    label,
    placeholder,
    onChange,
    onBlur,
    afterLabel,
    componentClass,
    autoComplete,
    disabled,
    validationFunc,
    validationText,
    validationError
  } = props

  const validationState = validationFunc(value)

  if (validationState === 'error') {
    props.groupFormInvalid(props.id)
  } else if (validationError) {
    props.groupFormValid(props.id)
  }

  return (
    <FormGroup validationState={validationState}>
      <Col sm={2}>
        <ControlLabel>{label}</ControlLabel>
      </Col>

      <Col sm={4}>
        <FormControl
          id={id}
          type={type}
          onBlur={onBlur}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete={autoComplete}
          componentClass={componentClass}
          value={value === null ? '' : value}
        />

        <FormControl.Feedback />
      </Col>

      {afterLabel && <Col sm={2}>{afterLabel}</Col>}
      {validationState === 'error' && (
        <Col sm={4}>
          <HelpBlock>{validationText}</HelpBlock>
        </Col>
      )}
    </FormGroup>
  )
}

InputField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  afterLabel: PropTypes.string,
  componentClass: PropTypes.string,
  autoComplete: PropTypes.string,
  validationFunc: PropTypes.func,
  validationText: PropTypes.string,
  groupFormValid: PropTypes.func,
  groupFormInvalid: PropTypes.func,
  validationError: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

InputField.defaultProps = {
  disabled: false,
  type: 'text',
  autoComplete: '',
  onBlur: () => {},
  validationFunc: () => null
}

const mapStateToProps = ({ groupEdit: { validationErrors } }, ownProps) => ({
  validationError: validationErrors[ownProps.id]
})

export default connect(mapStateToProps, { groupFormValid, groupFormInvalid })(InputField)
