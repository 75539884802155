import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// UI components
import { FormGroup, Form, ButtonGroup, Button, Row, Col, Glyphicon } from 'react-bootstrap'
import SingleSelect from './components/SingleSelect'
import TextInputField from './components/TextInputField'
import SourceQueryInput from './components/SourceQueryInput'
import SingleDateInput from '../SingleDateInput'
import FilterValues from './FilterValues'

// utils
import cx from 'classnames'
import { toString, cloneDeep, values } from 'lodash'
import cuid from 'cuid'
import moment from 'moment'

// actions
import {
  updateFilterDetails,
  updateAllFilterDetails,
  deleteFilter,
  saveFilter,
  queryFilterValues
} from '../../actions/custom-report-edit-actions'

// constants
const FILTER_TYPE_OPTIONS = [
  { label: 'Checkbox', id: 'checkbox' },
  { label: 'Radio', id: 'radio' },
  { label: 'Date Range', id: 'dateRange' },
  { label: 'User Defined', id: 'userDefined' }
]
const NEW_FILTER = { label: 'Add New Filter', id: 'new', type: 'checkbox' }

const MAX_ERRORS_COUNT = 3

class FilterDetails extends Component {
  componentDidUpdate(prevProps) {
    // if filter has changed & is a duplicate, indicate that
    if (this.props.activeFilterId !== prevProps.activeFilterId) {
      this.validateIfCurrentFilterIsDuplicate()
    }
  }

  // this method performs validation on current filter for duplicity
  // and updates necessary props to indicate that
  validateIfCurrentFilterIsDuplicate() {
    const { periscopeName, isPeriscopeNameValid } = this.props
    if (
      isPeriscopeNameValid == null && // periscopeName validation is pending
      periscopeName // has a periscopeName
    ) {
      // validate if current filter is a duplicate, if so indicate the issue
      const isDuplicateFilter = this.isDuplicateFilter()
      if (isDuplicateFilter) {
        this.props.updateFilterDetails({
          isPeriscopeNameValid: false
        })
      }
    }
  }

  // method to find if current filter is a duplicate filter
  isDuplicateFilter(periscopeName = this.props.periscopeName) {
    const { filters, activeFilterId } = this.props
    const isDuplicateFilter =
      values(filters).filter(item => {
        return (
          item.action !== 'delete' && // ignore deleted Filters
          item.id !== activeFilterId && // ignore current Filter
          item.periscopeName === periscopeName // find Filter with matching periscopeName
        )
      }).length > 0
    return isDuplicateFilter
  }

  // returns select options for filter selector
  getFilterOptions = () => {
    const { filters = {} } = this.props
    const activeFilters = Object.values(filters)
      .filter(item => item.action !== 'delete') // ignore deleted filters
      .map(item => {
        item.id = toString(item.id)
        item.label = item.label || item.name // use label or name as a fallback
        item.className = item.action
        return item
      })
    return [NEW_FILTER, ...activeFilters]
  }

  // validates the key, value and returns object with appropriate validation attributes
  getValidationAttributes(key, value) {
    const validationKeyMap = {
      name: 'isNameValid',
      periscopeName: 'isPeriscopeNameValid',
      sourceQuery: 'isSourceQueryValid'
    }
    const validationKey = validationKeyMap[key]
    if (validationKey) {
      return { [validationKey]: this.isValid(key, value) }
    }
    return {}
  }

  // Returns true if the 'value' is a valid for the key
  isValid(key, value = '') {
    switch (key) {
      case 'name':
      case 'sourceQuery':
        value = value.trim()
        return !!value
      case 'periscopeName':
        value = value.trim()
        if (!value) {
          return false // empty values are invalid
        }
        return !this.isDuplicateFilter(value) // having duplicate Filter is invalid
      case 'filterValue':
        return value && value.value && value.value.trim() && value.aliasValue && value.aliasValue.trim()
      default:
        return false
    }
  }

  // event handler when a filter is selected
  handleFilterSelection = ({
    id,
    name,
    periscopeName,
    sourceQuery,
    type,
    FilterValues,
    dateRange,
    isAutoRefreshActive,
    errorsCount
  }) => {
    const { showFilterValues, showSourceQuery } = this.props
    this.props.updateFilterDetails(
      {
        activeFilterId: id,
        name,
        periscopeName,
        sourceQuery,
        showSourceQuery,
        showFilterValues,
        isAutoRefreshActive,
        errorsCount,
        type,
        /**
         * deep cloning the values ensures that any changes to the values
         * do not affect FilterValues. And incase a user cancel's editing filter
         * we can revert to old values
         */
        values: cloneDeep(FilterValues) || [],
        dateRange: cloneDeep(dateRange) || {},
        dirty: false
      },
      false // false ensures we skip the merging & discard all existing values
    )
  }

  handleFilterTypeSelection = ({ id }) => {
    this.props.updateFilterDetails({
      type: id,
      dirty: true
    })
  }

  queryFilterValues = () => {
    const {
      activeFilterId,
      filters,
      type,
      sourceQuery,
      name,
      periscopeName,
      activeReportId,
      baseTableValue,
      isAutoRefreshActive,
      errorsCount
    } = this.props
    const action = filters[activeFilterId] ? filters[activeFilterId].action : null
    const filterPayload = {
      ReportId: activeReportId !== 'new' ? activeReportId : undefined,
      id: activeFilterId !== 'new' && action !== 'create' ? activeFilterId : undefined,
      type,
      sourceQuery,
      name,
      periscopeName,
      isAutoRefreshActive,
      errorsCount
    }
    this.props.queryFilterValues({
      filter: filterPayload,
      baseTable: baseTableValue,
      reportRefId: activeReportId,
      filterRefId: activeFilterId
    })
  }

  updateFormValue = (key, value) => {
    this.props.updateFilterDetails({
      [key]: value,
      ...this.getValidationAttributes(key, value),
      dirty: true
    })
  }

  updateAllFilters = payload => {
    const { activeFilterId } = this.props
    if (activeFilterId !== 'new') {
      this.props.updateFilterDetails(payload)
    }
    this.props.updateAllFilterDetails(payload)
  }

  updateFilterValues = (filterValueList, duplicateIds) => {
    this.props.updateFilterDetails({
      values: filterValueList,
      hasInvalidFilterValues: duplicateIds && duplicateIds.length > 0,
      dirty: true
    })
  }

  discardFilterChanges = () => {
    const { activeFilterId, filters } = this.props
    // reselecting current filter or NEW_FILTER also discards any changes
    this.handleFilterSelection(filters[activeFilterId] || NEW_FILTER)
  }

  deleteFilter = () => {
    const { activeFilterId, deleteFilter } = this.props
    if (activeFilterId !== 'new') {
      deleteFilter(activeFilterId)
    }
    this.handleFilterSelection(NEW_FILTER)
  }

  // check whether filter has the minimum information needed
  // to save it
  isFilterInvalid = () => {
    const { name, periscopeName, hasInvalidFilterValues } = this.props
    return !this.isValid('name', name) || !this.isValid('periscopeName', periscopeName) || hasInvalidFilterValues
  }

  onSaveFilter = () => {
    const {
      name,
      periscopeName,
      activeFilterId,
      type,
      sourceQuery,
      values,
      dateRange,
      isAutoRefreshActive,
      errorsCount
    } = this.props

    if (this.isFilterInvalid()) {
      // if current filter is invalid, skip saving and update the validation attributes
      this.updateFormValue({
        ...this.getValidationAttributes('name', name),
        ...this.getValidationAttributes('periscopeName', periscopeName),
        dirty: true
      })
    } else {
      const updatedFilter = {
        // if current filter is new, generate an ID for it
        id: activeFilterId === 'new' ? `new_${cuid.slug()}` : activeFilterId,
        name,
        periscopeName,
        type,
        sourceQuery,
        isAutoRefreshActive,
        errorsCount,
        // only save all the valid values.
        FilterValues: values ? values.filter(fv => this.isValid('filterValue', fv)) : [],
        dateRange
      }
      this.props.saveFilter(updatedFilter)
      this.handleFilterSelection(updatedFilter) // reselect current updated filter
    }
  }

  renderFilterOption(item, labelKey, options = []) {
    const hasDuplicate =
      options.filter(option => option.action !== 'delete' && option[labelKey] === item[labelKey]).length > 1
    return (
      <span className={cx(item.className)}>
        {item[labelKey]}
        {hasDuplicate && <div className="subtext">{`${item.periscopeName} (ID: ${item.id})`}</div>}
      </span>
    )
  }

  renderFilterActions() {
    const { activeFilterId, dirty } = this.props
    return (
      <Row>
        <Col sm={10}>
          <ButtonGroup className="pull-right">
            <Button
              onClick={this.discardFilterChanges}
              disabled={!dirty} // 'Cancel' only enabled if filter is dirty/modified
            >
              Cancel
            </Button>
            <Button
              onClick={this.deleteFilter}
              disabled={activeFilterId === 'new'} // 'Delete' is disabled for new filters
            >
              Delete
            </Button>
            <Button
              bsStyle="primary"
              disabled={!dirty || this.isFilterInvalid()} // if filter is not modified or is invalid, it cannot be 'Saved'
              onClick={this.onSaveFilter}
            >
              {activeFilterId === 'new' ? 'Create' : 'Update'}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    )
  }

  renderSourceQueryPanel() {
    const { type, showSourceQuery, sourceQuery, isSourceQueryValid, updateFilterDetails, fetchingValues } = this.props
    return (
      type !== 'userDefined' && (
        <FormGroup>
          <Col
            sm={10}
            className="cre-panel-heading"
            onClick={() =>
              updateFilterDetails({
                showSourceQuery: !showSourceQuery
              })
            }
          >
            SQL Query
            <Glyphicon className="pull-right" glyph={showSourceQuery ? 'triangle-bottom' : 'triangle-right'} />
          </Col>
          {showSourceQuery && (
            <SourceQueryInput
              sourceQuery={sourceQuery}
              type={type}
              isSourceQueryValid={isSourceQueryValid}
              onChange={value => this.updateFormValue('sourceQuery', value)}
              fetchingValues={fetchingValues}
              queryFilterValues={this.queryFilterValues}
            />
          )}
        </FormGroup>
      )
    )
  }

  renderFilterValuesPanel() {
    const { showFilterValues, values, type, activeFilterId } = this.props
    return (
      <FormGroup>
        <Col
          className="cre-panel-heading"
          sm={10}
          onClick={() =>
            this.props.updateFilterDetails({
              showFilterValues: !showFilterValues
            })
          }
        >
          Filter Values
          <Glyphicon className="pull-right" glyph={showFilterValues ? 'triangle-bottom' : 'triangle-right'} />
        </Col>
        {showFilterValues && (type === 'radio' || type === 'checkbox') && (
          <FilterValues id={activeFilterId} items={values || []} type={type} onChange={this.updateFilterValues} />
        )}
        {showFilterValues && type === 'dateRange' && this.renderDateSelectorPanel()}
      </FormGroup>
    )
  }

  renderDateSelectorPanel() {
    const { dateRange = {} } = this.props
    const getSelectedDate = key => {
      return dateRange[key] && dateRange[key].value ? moment(dateRange[key].value) : undefined
    }
    const selectedStart = getSelectedDate('start')
    const selectedEnd = getSelectedDate('end')
    const selectedDefaultStart = getSelectedDate('defaultStart')
    const selectedDefaultEnd = getSelectedDate('defaultEnd')
    const handleDateChange = (key, value) => {
      dateRange[key] = {
        ...dateRange[key],
        value
      }
      if (key === 'start' && !selectedDefaultStart) {
        dateRange.defaultStart = {
          ...dateRange.defaultStart,
          value
        }
      }
      if (key === 'end' && !selectedDefaultEnd) {
        dateRange.defaultEnd = {
          ...dateRange.defaultEnd,
          value
        }
      }
      this.updateFormValue('dateRange', { ...dateRange })
    }
    return (
      <Col sm={10} className="cre-panel-body">
        <FormGroup>
          <Col sm={3}>Start Date</Col>
          <Col sm={2}>
            <SingleDateInput
              popupTitle="Select Minimum Start Date"
              selectedDate={selectedStart}
              onDateChange={val => handleDateChange('start', val)}
            />
          </Col>
          <Col sm={3}>End Date</Col>
          <Col sm={2}>
            <SingleDateInput
              popupTitle="Select Maximum End Date"
              selectedDate={selectedEnd}
              minDate={selectedStart}
              onDateChange={val => handleDateChange('end', val)}
            />
          </Col>
        </FormGroup>
        <FormGroup>
          <Col sm={3}>Default Start Date</Col>
          <Col sm={2}>
            <SingleDateInput
              popupTitle="Select Default Start Date"
              selectedDate={selectedDefaultStart}
              disabled={!selectedStart || !selectedEnd}
              minDate={selectedStart}
              maxDate={selectedEnd}
              onDateChange={val => handleDateChange('defaultStart', val)}
            />
          </Col>
          <Col sm={3}>Default End Date</Col>
          <Col sm={2}>
            <SingleDateInput
              popupTitle="Select Default End Date"
              selectedDate={selectedDefaultEnd}
              disabled={!selectedStart || !selectedEnd}
              minDate={selectedStart}
              maxDate={selectedEnd}
              onDateChange={val => handleDateChange('defaultEnd', val)}
            />
          </Col>
        </FormGroup>
      </Col>
    )
  }

  renderGlobalIsAutoRefreshComponent() {
    const { filters } = this.props

    const activeFilters = Object.values(filters).filter(item => item.action !== 'delete')

    const autoRefresOnCount = activeFilters.filter(item => item.isAutoRefreshActive).length
    const autoRefresOffCount = activeFilters.length - autoRefresOnCount

    return (
      <FormGroup>
        <Col sm={2}>Auto-refresh global</Col>
        <Col sm={6} className="">
          <span>
            Enabled: {autoRefresOnCount}. Disabled: {autoRefresOffCount}
          </span>
          {autoRefresOnCount > 0 && (
            <Button
              bsStyle="link"
              className="addButton pull-right value-action"
              onClick={() => {
                this.updateAllFilters({
                  isAutoRefreshActive: false
                })
              }}
            >
              Disable in all filters
            </Button>
          )}
          {autoRefresOffCount > 0 && (
            <Button
              bsStyle="link"
              className="addButton pull-right value-action"
              onClick={() => {
                this.updateAllFilters({
                  isAutoRefreshActive: true,
                  errorsCount: 0
                })
              }}
            >
              Enable in all filters and clear errors
            </Button>
          )}
        </Col>
      </FormGroup>
    )
  }

  getClearErrorsCountComponent() {
    const { errorsCount = 0 } = this.props

    return (
      <div className="filter-values-errors-count">
        <span className="pull-left">Number of errors: {errorsCount}</span>
        {errorsCount > 0 && (
          <Button
            bsStyle="link"
            className="addButton pull-left value-action"
            onClick={() => {
              this.updateFormValue('errorsCount', 0)
            }}
          >
            Clear error counter
          </Button>
        )}
      </div>
    )
  }

  render() {
    const {
      dirty,
      activeFilterId,
      periscopeName,
      isPeriscopeNameValid,
      name,
      isNameValid,
      type,
      filters,
      isAutoRefreshActive,
      errorsCount
    } = this.props
    const action = filters[activeFilterId] ? filters[activeFilterId].action : null
    return (
      <Form horizontal className={dirty ? 'dirty' : ''}>
        <h4> Filters </h4>
        {this.renderGlobalIsAutoRefreshComponent()}
        <SingleSelect
          name={'selected-filter'}
          placeholder={'Select'}
          selectedId={activeFilterId}
          controlLabel={'Available filters'}
          options={this.getFilterOptions()}
          handleChange={this.handleFilterSelection}
          labelKey={'label'}
          className={action || ''}
          renderMenuItem={this.renderFilterOption}
        />
        <TextInputField id="filterId" label="ID" type="text" value={activeFilterId} readOnly />
        <TextInputField
          id="periscopeName"
          label="Periscope Name"
          type="text"
          placeholder="Enter Periscope name"
          value={periscopeName || ''}
          validationState={isPeriscopeNameValid === false ? 'error' : null}
          onChange={event => this.updateFormValue('periscopeName', event.target.value)}
          help={isPeriscopeNameValid === false ? 'Periscope Name of the filter has to be unique and not empty' : null}
          required
        />
        <TextInputField
          id="filterName"
          label="Display name"
          type="text"
          placeholder="Enter display name"
          value={name || ''}
          validationState={isNameValid === false ? 'error' : null}
          onChange={event => this.updateFormValue('name', event.target.value)}
          help={isNameValid === false ? 'Display name of the filter cannot be empty' : null}
          required
        />
        <SingleSelect
          name={'selected-type'}
          placeholder={'Select'}
          selectedId={type}
          controlLabel={'Filter Type'}
          options={FILTER_TYPE_OPTIONS}
          handleChange={this.handleFilterTypeSelection}
          labelKey={'label'}
        />
        <TextInputField
          id="isAutoRefreshActive"
          label="Auto-refresh"
          type="checkbox"
          disabled={errorsCount > MAX_ERRORS_COUNT}
          checked={isAutoRefreshActive || false}
          onChange={event => this.updateFormValue('isAutoRefreshActive', !isAutoRefreshActive)}
          actionComponent={this.getClearErrorsCountComponent()}
          help={
            'Automatically refresh this filter each day.' +
            (errorsCount > MAX_ERRORS_COUNT ? ' Clear errors if you want to enable auto-refresh.' : '')
          }
        />

        {type !== 'userDefined' && this.renderSourceQueryPanel()}
        {type !== 'userDefined' && this.renderFilterValuesPanel()}
        {this.renderFilterActions()}
      </Form>
    )
  }
}

FilterDetails.defaultProps = {
  activeFilterId: 'new',
  type: 'checkbox'
}
FilterDetails.propTypes = {
  activeReportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dirty: PropTypes.bool,
  activeFilterId: PropTypes.string.isRequired,
  filters: PropTypes.object,
  name: PropTypes.string,
  periscopeName: PropTypes.string,
  type: PropTypes.string,
  sourceQuery: PropTypes.string,
  values: PropTypes.array,
  dateRange: PropTypes.object,
  showSourceQuery: PropTypes.bool,
  showFilterValues: PropTypes.bool,
  isNameValid: PropTypes.bool,
  isPeriscopeNameValid: PropTypes.bool,
  isSourceQueryValid: PropTypes.bool,
  hasInvalidFilterValues: PropTypes.bool,
  saveFilter: PropTypes.func,
  deleteFilter: PropTypes.func,
  updateFilterDetails: PropTypes.func,
  updateAllFilterDetails: PropTypes.func,
  queryFilterValues: PropTypes.func,
  fetchingValues: PropTypes.bool,
  baseTableValue: PropTypes.string,
  isAutoRefreshActive: PropTypes.bool,
  errorsCount: PropTypes.number
}

function mapStateToProps({ reportEdit }) {
  const {
    activeReportId,
    fetchingValues,
    basicDetailsForm: { baseTableValue },
    filterForm: {
      dirty,
      activeFilterId,
      filters,
      name,
      periscopeName,
      type,
      sourceQuery,
      showSourceQuery,
      showFilterValues,
      isNameValid,
      isPeriscopeNameValid,
      isSourceQueryValid,
      hasInvalidFilterValues,
      values,
      dateRange,
      isAutoRefreshActive,
      errorsCount
    }
  } = reportEdit
  return {
    activeReportId,
    dirty,
    activeFilterId,
    filters,
    name,
    periscopeName,
    type,
    sourceQuery,
    showSourceQuery,
    showFilterValues,
    isNameValid,
    isPeriscopeNameValid,
    isSourceQueryValid,
    hasInvalidFilterValues,
    values,
    dateRange,
    fetchingValues,
    baseTableValue,
    isAutoRefreshActive,
    errorsCount
  }
}

export default connect(mapStateToProps, {
  updateFilterDetails,
  updateAllFilterDetails,
  deleteFilter,
  saveFilter,
  queryFilterValues
})(FilterDetails)
