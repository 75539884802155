import React from 'react'

import { confirmModal } from '../../../actions/modal-actions'
import './index.scss'

export default (userName, callback) =>
  confirmModal(
    <div>
      <strong>{userName}</strong> will be removed from the Portal
    </div>,
    `Confirm User Delete`,
    { deleteButton: 'Delete' },
    callback,
    'large',
    'confirm-user-delete'
  )
