// @flow

import * as actions from '../actions/dm-actions'

const initialState = {
  totalClientCount: null,
  totalContractCount: null,
  totalDeliverableCount: null,
  selectedClient: {},
  selectedContract: {},
  selectedDeliverable: {},
  searchResults: {
    loading: false,
    clientsCount: 0,
    contractsCount: 0,
    deliverablesCount: 0,
    clients: {},
    contracts: {},
    deliverables: {}
  },
  clients: {
    loading: false,
    page: 1,
    pageSize: 10,
    sortBy: 'isActive',
    sortOrder: 'desc',
    data: []
  },
  contracts: {
    loading: false,
    page: 1,
    pageSize: 10,
    sortBy: 'isActive',
    sortOrder: 'desc',
    data: []
  },
  deliverables: {
    loading: false,
    page: 1,
    pageSize: 10,
    sortBy: 'isActive',
    sortOrder: 'desc',
    data: [],
    deliveriesProgress: {}
  },
  clientForm: {
    loading: false
  },
  contractForm: {
    loading: false
  },
  deliverableForm: {
    loading: false
  },
  clientLookupValue: '',
  clientSuggestions: [],
  userLookupValue: '',
  userLookupField: '',
  userSuggestions: [],
  deliveryLogs: {
    data: [],
    loading: false,
    error: '',
    pageSize: 25,
    pageNumber: 1
  },
  showInactive: false
}

const dmReducer = (state: Array<Object> = initialState, action: Object): Array<Object> => {
  switch (action.type) {
    case actions.FETCH_CCD_COUNTS_SUCCESS:
      const { totalClientCount, totalContractCount, totalDeliverableCount } = action.payload
      return {
        ...state,
        totalClientCount,
        totalContractCount,
        totalDeliverableCount
      }
    case actions.UPDATE_SELECTED_CLIENT_SUCCESS:
      return {
        ...state,
        selectedClient: {
          id: action.payload.id,
          name: action.payload.name
        },
        selectedContract: {},
        selectedDeliverable: {}
      }
    case actions.UPDATE_SELECTED_CONTRACT_SUCCESS: {
      const { id, name, Client = {} } = action.payload
      const { id: clientId, name: clientName } = Client
      return {
        ...state,
        selectedClient: {
          id: clientId,
          name: clientName
        },
        selectedContract: { id, name },
        selectedDeliverable: {}
      }
    }
    case actions.UPDATE_SELECTED_DELIVERABLE_SUCCESS: {
      const { id, name, Contract = {} } = action.payload
      const { id: contractId, name: contractName, Client = {} } = Contract
      const { id: clientId, name: clientName } = Client
      return {
        ...state,
        selectedClient: {
          id: clientId,
          name: clientName
        },
        selectedContract: {
          id: contractId,
          name: contractName
        },
        selectedDeliverable: { id, name }
      }
    }
    case actions.REMOVE_SELECTED_DELIVERABLE: {
      return {
        ...state,
        selectedDeliverable: {}
      }
    }
    case actions.INITIALIZE_NEW_DELIVERABLE: {
      return {
        ...state,
        selectedDeliverable: { id: 'new', name: 'New Deliverable' }
      }
    }
    case actions.FETCH_CLIENTS:
      return {
        ...state,
        clients: {
          ...state.clients,
          ...action.payload,
          loading: true
        }
      }
    case actions.FETCH_CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: {
          ...state.clients,
          loading: false,
          data: action.payload
        }
      }
    }
    case actions.FETCH_CONTRACTS:
      return {
        ...state,
        contracts: {
          ...state.contracts,
          ...action.payload,
          loading: true
        }
      }
    case actions.FETCH_CONTRACTS_SUCCESS: {
      return {
        ...state,
        contracts: {
          ...state.contracts,
          loading: false,
          data: action.payload
        }
      }
    }
    case actions.FETCH_DELIVERABLES:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          ...action.payload,
          loading: true
        }
      }
    case actions.FETCH_DELIVERABLES_SUCCESS: {
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          loading: false,
          data: action.payload
        }
      }
    }
    case actions.INITIALIZE_CLIENT_FORM: {
      return {
        ...state,
        clientForm: {
          ...action.payload
        }
      }
    }
    case actions.UPDATE_CLIENT_FORM: {
      return {
        ...state,
        clientForm: {
          ...state.clientForm,
          ...action.payload
        }
      }
    }
    case actions.UPDATE_CLIENT_NAME_AVAILABLE: {
      const { name, isNameAvailable } = action.payload
      if (state.clientForm.name === name) {
        return {
          ...state,
          clientForm: {
            ...state.clientForm,
            name,
            isNameAvailable
          }
        }
      }
      break
    }
    case actions.SAVE_CLIENT: {
      return {
        ...state,
        clientForm: {
          ...state.clientForm,
          loading: true
        }
      }
    }
    case actions.SAVE_CLIENT_SUCCESS: {
      return {
        ...state,
        clientForm: {
          ...state.clientForm,
          loading: false
        }
      }
    }
    case actions.INITIALIZE_CONTRACT_FORM: {
      return {
        ...state,
        contractForm: {
          ...action.payload,
          loading: true
        }
      }
    }
    case actions.INITIALIZE_CONTRACT_FORM_SUCCESS: {
      return {
        ...state,
        contractForm: {
          ...action.payload,
          loading: false
        }
      }
    }
    case actions.UPDATE_CONTRACT_FORM: {
      return {
        ...state,
        contractForm: {
          ...state.contractForm,
          ...action.payload
        }
      }
    }
    case actions.UPDATE_CONTRACT_NAME_AVAILABLE: {
      const { name, isNameAvailable } = action.payload
      if (state.contractForm.name === name) {
        return {
          ...state,
          contractForm: {
            ...state.contractForm,
            name,
            isNameAvailable
          }
        }
      } else {
        return state
      }
    }
    case actions.SAVE_CONTRACT: {
      return {
        ...state,
        contractForm: {
          ...state.contractForm,
          loading: true
        }
      }
    }
    case actions.SAVE_CONTRACT_SUCCESS: {
      return {
        ...state,
        contractForm: {
          ...state.contractForm,
          loading: false
        }
      }
    }
    case actions.SEARCH_CLIENTS: {
      return {
        ...state,
        clientLookupValue: action.clientLookupValue
      }
    }
    case actions.SEARCH_CLIENTS_SUCCESS: {
      return {
        ...state,
        clientSuggestions: action.payload
      }
    }
    case actions.SEARCH_USERS: {
      return {
        ...state,
        userLookupField: action.userLookupField,
        userLookupValue: action.userLookupValue
      }
    }
    case actions.SEARCH_USERS_SUCCESS: {
      return {
        ...state,
        userSuggestions: action.payload
      }
    }
    case actions.FETCH_CURRENT_DELIVERIES_PROGRESS_SUCCESS: {
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          deliveriesProgress: action.payload
        }
      }
    }

    case actions.FETCH_DELIVERY_LOGS:
      return {
        ...state,
        deliveryLogs: {
          ...state.deliveryLogs,
          loading: true,
          error: ''
        }
      }

    case actions.FETCH_DELIVERY_LOGS_SUCCESS:
      return {
        ...state,
        deliveryLogs: {
          ...state.deliveryLogs,
          error: '',
          loading: false,
          data: action.deliveryLogs
        }
      }

    case actions.FETCH_DELIVERY_LOGS_FAILURE:
      return {
        ...state,
        deliveryLogs: {
          ...state.deliveryLogs,
          loading: false,
          error: action.error
        }
      }

    case actions.UPDATE_DELIVERY_LOGS_TABLE:
      return {
        ...state,
        deliveryLogs: {
          ...state.deliveryLogs,
          ...action.payload
        }
      }
    case actions.SEARCH_MORE_CCD:
    case actions.SEARCH_CCD:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: true
        }
      }
    case actions.SEARCH_CCD_ERROR:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: false
        }
      }
    case actions.SEARCH_CCD_SUCCESS:
      return {
        ...state,
        searchResults: {
          loading: false,
          ...action.payload
        }
      }
    case actions.SEARCH_MORE_CCD_SUCCESS:
      const { type, items } = action.payload
      const typeKey = `${type}s`
      const newItems = [...state.searchResults[typeKey], ...items]
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: false,
          [typeKey]: newItems
        }
      }
    case actions.TOGGLE_SHOW_INACTIVE:
      return {
        ...state,
        showInactive: !state.showInactive
      }

    default:
      return state
  }
}

export default dmReducer
