import { takeLatest, call, put, fork, select } from 'redux-saga/effects'
import axios from 'axios'

import config from 'config/index'
import { createAlert } from 'actions/app-actions'

import RequestsActions from './actions'

// GET METHOD
function* fetchRequests({ payload = {} }) {
  try {
    const state = yield select(state => state.requests)

    const {
      page = state.page,
      sortBy = state.sortBy,
      pageSize = state.pageSize,
      sortOrder = state.sortOrder,
      filters = state.filters
    } = payload

    yield put(
      RequestsActions.updateSettings({
        page,
        sortBy,
        pageSize,
        sortOrder,
        filters
      })
    )

    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/rops/requests`, {
        params: {
          page,
          sortBy,
          pageSize,
          sortOrder,
          filters
        }
      })
    )
    yield put(RequestsActions.successLoad(data))
  } catch (error) {
    window.captureException(error)
    yield put(RequestsActions.failedLoad())

    yield put(createAlert('danger', error.message, `Requests fetch failed`))
  }
}

// SAVE METHOD
function* saveRequests({ payload }) {
  try {
    yield call(() => axios.post(`${config.apiBaseUrl}/rops/requests`, { payload }))

    yield put(createAlert('success', '', `Request saved`))

    yield put(RequestsActions.fetch())
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data.message || error.message, `Request saving failed`))
  }
}

/*
 * FLOWS
 */
function* requestsFlow() {
  yield takeLatest(RequestsActions.fetch.type, fetchRequests)
  yield takeLatest(RequestsActions.save.type, saveRequests)
}

export default [fork(requestsFlow)]
