// @flow
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AlertList } from 'react-bs-notifier'
import { connect } from 'react-redux'
import { onDismissAlert } from '../../actions/app-actions'
import config from '../../config'
import '../../styles/alerts.scss'

class AlertListWrapper extends Component {
  static propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.object),
    onDismissAlert: PropTypes.func,
    position: PropTypes.string,
    timeout: PropTypes.number
  }

  render() {
    return (
      this.props.alerts.length !== 0 && (
        <AlertList
          alerts={this.props.alerts}
          position={this.props.position || 'bottom-right'}
          showIcon
          timeout={
            this.props.timeout === null || this.props.timeout === undefined
              ? config.alert.defaultNotificationTimeout
              : this.props.timeout
          }
          onDismiss={alert => this.props.onDismissAlert(alert)}
        />
      )
    )
  }
}

const mapStateToProps = state => ({
  timeout: state.app.alertTimeout,
  alerts: state.app.alerts
})

export default connect(mapStateToProps, { onDismissAlert })(AlertListWrapper)
