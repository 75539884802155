import { takeLatest, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'
import { createAlert } from 'actions/app-actions'

import {
  fetchLatestRetrainerDagRun,
  fetchLastSuccessfulRetrainedModel,
  fetchLatestDeploymentDagRun,
  triggerRetrainerDAG,
  triggerDeploymentDAG,
  fetchCurrentProductionModel,
  fetchRecentModels
} from './actions'

import {
  fetchLatestRetrainerDagRunSucceeded,
  fetchLatestDeploymentDagRunSucceeded,
  fetchLastSucessfulRetrainedModelSucceeded,
  fetchCurrentProductionModelSucceeded,
  fetchRecentModelsSucceeded
} from './slices'

function* fetchLatestRetrainerDagRunSaga(action) {
  const apiPath = '/api/cme-retrainer/retrain/latest-dag-run'
  try {
    const { data } = yield call(() => axios.get(apiPath))
    yield put(fetchLatestRetrainerDagRunSucceeded(data))
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data, 'Failed to fetch latest DAG run details for retrainer'))
  }
}

function* fetchLatestDeploymentDagRunSaga(action) {
  const apiPath = '/api/cme-retrainer/deploy/latest-dag-run'
  try {
    const { data } = yield call(() => axios.get(apiPath))
    yield put(fetchLatestDeploymentDagRunSucceeded(data))
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data, 'Failed to fetch latest deployment DAG run details'))
  }
}

function* fetchLastSucessfulRetrainedModelSaga(action) {
  const apiPath = '/api/cme-retrainer/retrain/last-successful-model'
  try {
    const { data } = yield call(() => axios.get(apiPath))
    yield put(fetchLastSucessfulRetrainedModelSucceeded(data))
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data, 'Failed to fetch last successful retrained model'))
  }
}

function* triggerRetrainerDAGSaga(action) {
  const apiPath = '/api/cme-retrainer/retrain/trigger'
  try {
    yield call(() => axios.post(apiPath))
    yield put(createAlert('success', 'Triggered CME retrainer DAG', 'Success'))
    setTimeout(() => {
      window.location.reload()
    }, 2000)
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data, 'Failed to trigger retrainer DAG'))
  }
}

function* triggerDeploymentDAGSaga(action) {
  const apiPath = '/api/cme-retrainer/deploy/trigger'
  try {
    yield call(() => axios.post(apiPath, { model_version: action.payload.model_version }))
    yield put(
      createAlert('success', `Triggered CME deployment DAG with model ID: ${action.payload.model_version}`, 'Success')
    )
    setTimeout(() => {
      window.location.reload()
    }, 2000)
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data, 'Failed to trigger deployment DAG'))
  }
}

function* fetchCurrentProductionModelSaga(action) {
  const apiPath = '/api/cme-retrainer/current-production-model'
  try {
    const { data } = yield call(() => axios.get(apiPath))
    yield put(fetchCurrentProductionModelSucceeded(data))
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data, 'Failed to fetch current production model'))
  }
}

function* fetchRecentModelsSaga(action) {
  const apiPath = '/api/cme-retrainer/deploy/recent-models'
  try {
    const { data } = yield call(() => axios.get(apiPath))
    yield put(fetchRecentModelsSucceeded(data))
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data, 'Failed to fetch recent models'))
  }
}

function* cmeRetrainerFlow() {
  yield takeLatest(fetchLatestRetrainerDagRun.type, fetchLatestRetrainerDagRunSaga)
  yield takeLatest(triggerRetrainerDAG.type, triggerRetrainerDAGSaga)
  yield takeLatest(fetchLastSuccessfulRetrainedModel.type, fetchLastSucessfulRetrainedModelSaga)
  yield takeLatest(fetchLatestDeploymentDagRun.type, fetchLatestDeploymentDagRunSaga)
  yield takeLatest(fetchCurrentProductionModel.type, fetchCurrentProductionModelSaga)
  yield takeLatest(fetchRecentModels.type, fetchRecentModelsSaga)
  yield takeLatest(triggerDeploymentDAG.type, triggerDeploymentDAGSaga)
}

export default [fork(cmeRetrainerFlow)]
