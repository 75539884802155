import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'

import LeftNavBeacon from './LeftNavBeacon'
import PeriscopeBeacons from './PeriscopeBeacons'
import WelcomeModal from './WelcomeModal'

import UserService from '../../services/user-service'
import { activateWalkthrough, setProductOnboarded, updateBeaconLocations } from '../../actions/walkthrough-actions'

import { products as PRODUCTS } from '../../constants/constants'

class Walkthrough extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false
    }
  }

  componentDidMount() {
    const { isProductOnboarded, hasPeriscopeAccess, activateWalkthrough } = this.props
    const products = {
      active: false,
      periscope: hasPeriscopeAccess
    }

    if (!isProductOnboarded) {
      products.active = true
    }
    activateWalkthrough(products)

    if (!this.props.isProductOnboarded) {
      this.setState({ modalOpen: true })
    }
    window.addEventListener('resize', debounce(this.props.updateBeaconLocations, 200))
  }

  setOnboarded = () => {
    this.setState({ modalOpen: false })
    this.props.setProductOnboarded()
  }

  render() {
    const { modalOpen } = this.state
    const { pathname, beaconLocations, periscopeEnabled } = this.props

    if (!pathname.includes('/custom/reports')) return null

    const location = 'periscope'

    return (
      <div>
        {periscopeEnabled && <LeftNavBeacon beaconLocations={beaconLocations} />}
        {location === 'periscope' && (
          <PeriscopeBeacons beaconLocations={beaconLocations} periscopeEnabled={periscopeEnabled} />
        )}
        {<WelcomeModal modalOpen={modalOpen} setOnboarded={this.setOnboarded} />}
      </div>
    )
  }
}

Walkthrough.propTypes = {
  pathname: PropTypes.string.isRequired,
  periscopeEnabled: PropTypes.bool.isRequired,
  beaconLocations: PropTypes.object.isRequired,
  isProductOnboarded: PropTypes.bool.isRequired,
  hasPeriscopeAccess: PropTypes.bool.isRequired,
  activateWalkthrough: PropTypes.func.isRequired,
  setProductOnboarded: PropTypes.func.isRequired,
  updateBeaconLocations: PropTypes.func.isRequired
}

const mapStateToProps = ({ walkthrough, session: { user } }) => ({
  beaconLocations: (walkthrough && walkthrough.beaconLocations) || {},
  isProductOnboarded: user && user.isProductOnboarded,
  periscopeEnabled: walkthrough && walkthrough.periscopeEnabled,
  hasPeriscopeAccess:
    user && user.products ? UserService.isProductAuthorized(user.products, PRODUCTS.customReportsPeriscope) : false
})

const mapDispatchToProps = dispatch => ({
  setProductOnboarded: () => dispatch(setProductOnboarded()),
  updateBeaconLocations: () => dispatch(updateBeaconLocations()),
  activateWalkthrough: products => dispatch(activateWalkthrough(products))
})

export default connect(mapStateToProps, mapDispatchToProps)(Walkthrough)
