import {
  FETCH_REPORT_FOR_EDIT,
  FETCH_REPORT_FOR_EDIT_SUCCESS,
  FETCH_REPORT_FOR_EDIT_FAILED,
  INITIALIZE_NEW_REPORT,
  DISMISS_REPORT_EDIT_ERROR,
  UPDATE_BASIC_DETAILS,
  UPDATE_FILTER_DETAILS,
  UPDATE_ALL_FILTER_DETAILS,
  UPDATE_TAB_DETAILS,
  DISCARD_CHANGES,
  DELETE_FILTER,
  DELETE_TAB,
  ADD_TAB,
  SAVE_FILTER,
  SAVE_TAB,
  SAVE_REPORT,
  SAVE_REPORT_DONE,
  QUERY_FILTER_VALUES,
  QUERY_FILTER_VALUES_SUCCESS,
  QUERY_FILTER_VALUES_FAILED,
  FETCH_REPORT_FOLDERS_SUCCESS
} from '../actions/custom-report-edit-actions'

import { toString, pick, cloneDeep, omit, values, chain } from 'lodash'

const initialState = {
  activeReportId: null,
  report: {}, // basic report data from backend
  tabs: {}, // tabs data from backend
  filters: {}, // filters data from backend,
  folders: {}, // folders data from backend
  basicDetailsForm: {}, // contains current form values for basic report details
  filterForm: { filters: {} }, // contains form values for currently edited filter and all staged filters
  tabForm: {}, // conatains form values for currently edited tab and staged tabs
  error: false, // error to display on top
  loading: false,
  fetchingValues: false
}

// initialize report form
function initializeEditFormData(data = {}) {
  let { report = {}, tabs = {}, filters = {} } = data
  // TODO: deep freeze the objects to ensure they aren't accidentally modified
  report = Object.freeze(report)
  tabs = Object.freeze(tabs)
  filters = Object.freeze(filters)

  return {
    activeReportId: toString(report.id),
    basicDetailsForm: initBasicDetailsForm(report),
    filterForm: initFilterForm(filters),
    tabForm: initTabForm(tabs),
    report,
    tabs,
    filters
  }
}

function initTabForm(tabDetails = {}) {
  const tabs = cloneDeep(Object.assign({}, tabDetails))
  const tabIdList = chain(tabs)
    .values()
    .sortBy('order')
    .map(item => item.id)
    .value()
  const activeTabId = tabIdList.length ? tabIdList[0] : null
  const { name, dashboardId, inheritBaseTable, associatedTables, filterIdList } = tabs[activeTabId] || {}

  return {
    tabIdList,
    activeTabId,
    name,
    dashboardId,
    inheritBaseTable,
    associatedTables,
    filterIdList,
    tabs
  }
}

/**
 * Clones filter data from API filter data
 * @param {any} [reportFilters={}] data from backend API
 * @returns
 */
function initFilterForm(reportFilters = {}) {
  const newFilters = {}
  // Add filters
  values(reportFilters).forEach(filter => {
    if (!newFilters[filter.id]) {
      newFilters[filter.id] = cloneDeep(filter) // cloneDeep ensures we do not mess with original filter or filter.FilterValues
    }
  })

  return {
    dirty: false,
    activeFilterId: 'new',
    type: 'checkbox',
    filters: newFilters
  }
}

// initilaize form details from report data
function initBasicDetailsForm(report = {}) {
  return {
    dirty: false,
    ...pick(report, ['id', 'name', 'folderId', 'baseTableName', 'baseTableValue', 'apiKey'])
  }
}

const reportsReducer = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case FETCH_REPORT_FOR_EDIT: {
      return {
        ...cloneDeep(initialState),
        activeReportId: action.id,
        loading: true,
        error: false
      }
    }
    case INITIALIZE_NEW_REPORT: {
      return {
        ...state,
        ...cloneDeep(initialState),
        ...initializeEditFormData({
          report: { id: 'new', folderId: 0 }
        }),
        loading: false,
        error: false,
        activeReportId: 'new'
      }
    }
    case SAVE_REPORT: {
      return {
        ...state,
        loading: true
      }
    }
    case SAVE_REPORT_DONE: {
      return {
        ...state,
        activeReportId: action.error ? state.activeReportId : null,
        loading: false,
        error: action.error
      }
    }
    case DISCARD_CHANGES: {
      // re-initialize forms
      return {
        ...state,
        basicDetailsForm: initBasicDetailsForm(state.report),
        filterForm: initFilterForm(state.filters),
        tabForm: initTabForm(state.tabs)
      }
    }
    case DELETE_FILTER: {
      let { filters } = state.filterForm
      const filter = filters[action.id]
      filters = omit(filters, action.id)
      // deleting filter
      if (filter.action !== 'create') {
        // if filter is not a new filter, mark action as delete
        // and keep it in the filters map
        filter.action = 'delete'
        filters[action.id] = { ...filter }
      }
      let { tabs, filterIdList } = state.tabForm
      const removeFilterIdFromList = idList => {
        const index = idList.indexOf(action.id) > -1
        if (index > -1) {
          idList = idList.splice(index, 1)
        }
        return idList
      }
      // deleting tabFilter associations
      filterIdList = removeFilterIdFromList(filterIdList)
      Object.entries(tabs).forEach(([tabId, tab]) => {
        tab.filterIdList = removeFilterIdFromList(tab.filterIdList)
        tabs[tabId] = { ...tab }
      })
      return {
        ...state,
        filterForm: {
          ...state.filterForm,
          filters
        },
        tabForm: {
          ...state.tabForm,
          filterIdList,
          tabs: {
            ...tabs
          }
        }
      }
    }

    case DELETE_TAB: {
      let { tabs, tabIdList, activeTabId } = state.tabForm
      const tab = tabs[action.id]
      tabs = omit(tabs, action.id)
      let payload = {}
      let selectedTab = {}
      if (tab.action !== 'create') {
        // if tab is not a new tab, mark action as delete
        // and keep it in the tabs map
        tab.action = 'delete'
        tabs[action.id] = { ...tab }
      }
      let selectedIndex = tabIdList.indexOf(activeTabId)
      tabIdList.splice(selectedIndex, 1)
      if (tabIdList.length && selectedIndex > tabIdList.length - 1) {
        selectedIndex = 0
      }
      activeTabId = tabIdList[selectedIndex] || null
      selectedTab = tabs[activeTabId] || {}
      const { name, dashboardId, inheritBaseTable, associatedTables, filterIdList } = selectedTab
      payload = {
        tabs,
        tabIdList,
        activeTabId,
        name,
        dashboardId,
        inheritBaseTable,
        associatedTables,
        filterIdList
      }

      return {
        ...state,
        tabForm: {
          ...state.tabForm,
          ...payload
        }
      }
    }

    case ADD_TAB: {
      const { tabs = {}, tabIdList = [] } = state.tabForm
      tabs.new = { id: 'new' }
      tabIdList.push('new')
      return {
        ...state,
        tabForm: {
          activeTabId: 'new',
          inheritBaseTable: true,
          tabs: { ...tabs },
          tabIdList: [...tabIdList]
        }
      }
    }
    case SAVE_FILTER: {
      const filterPayload = action.payload
      let filterAction = 'update'
      if (!state.filters[filterPayload.id] || state.filters[filterPayload.id].action === 'create') {
        filterAction = 'create'
      }
      state.filterForm.filters[filterPayload.id] = {
        ...filterPayload,
        action: filterAction
      }
      return {
        ...state,
        filterForm: {
          ...state.filterForm,
          filters: {
            ...state.filterForm.filters
          }
        }
      }
    }
    case SAVE_TAB: {
      const tabPayload = action.payload
      const { tabs, tabIdList } = state.tabForm
      let tabAction = 'update'
      if (!tabs[tabPayload.id]) {
        delete tabs.new
        tabIdList.pop()
        tabIdList.push(tabPayload.id)
        tabAction = 'create'
      } else if (tabs[tabPayload.id].action === 'create') {
        tabAction = 'create'
      }
      tabs[tabPayload.id] = {
        ...tabPayload,
        action: tabAction
      }
      return {
        ...state,
        tabForm: {
          ...state.tabForm,
          activeTabId: tabPayload.id,
          dirty: false,
          tabIdList: [...tabIdList],
          tabs: {
            ...tabs
          }
        }
      }
    }
    case FETCH_REPORT_FOR_EDIT_SUCCESS: {
      const { report = {} } = action.data || {}
      if (toString(report.id) !== state.activeReportId) return state
      return {
        ...state,
        ...initializeEditFormData(action.data),
        loading: false,
        error: false
      }
    }
    case FETCH_REPORT_FOLDERS_SUCCESS: {
      return {
        ...state,
        folders: {
          ...state.folders,
          ...action.data
        }
      }
    }
    case FETCH_REPORT_FOR_EDIT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.error || true
      }
    }
    case DISMISS_REPORT_EDIT_ERROR: {
      return {
        ...state,
        error: false
      }
    }
    case UPDATE_BASIC_DETAILS: {
      const { payload, merge } = action
      let { basicDetailsForm = {} } = state
      if (merge) {
        basicDetailsForm = {
          ...basicDetailsForm,
          ...payload
        }
      } else {
        basicDetailsForm = {
          ...payload
        }
      }
      return {
        ...state,
        basicDetailsForm
      }
    }
    case UPDATE_FILTER_DETAILS: {
      const { payload, merge } = action
      let { filterForm = {} } = state
      if (merge) {
        filterForm = {
          ...filterForm,
          ...payload
        }
      } else {
        filterForm = {
          ...payload,
          filters: filterForm.filters
        }
      }
      return {
        ...state,
        filterForm
      }
    }

    case UPDATE_ALL_FILTER_DETAILS: {
      const { payload } = action
      let { filterForm = {} } = state

      let filters = {}

      // update all filters
      for (let key in filterForm.filters) {
        filters[key] = { ...filterForm.filters[key], ...payload, action: 'update' }
      }
      filterForm = {
        ...filterForm,
        filters
      }

      return {
        ...state,
        filterForm
      }
    }

    case UPDATE_TAB_DETAILS: {
      const { payload, merge } = action
      let { tabForm = {} } = state
      if (merge) {
        tabForm = {
          ...tabForm,
          ...payload
        }
      } else {
        tabForm = {
          ...payload,
          tabIdList: tabForm.tabIdList,
          tabs: tabForm.tabs
        }
      }
      return {
        ...state,
        tabForm
      }
    }
    case QUERY_FILTER_VALUES: {
      return {
        ...state,
        fetchingValues: true
      }
    }
    case QUERY_FILTER_VALUES_FAILED: {
      return {
        ...state,
        fetchingValues: false
      }
    }
    case QUERY_FILTER_VALUES_SUCCESS: {
      const { filter, filterRefId, reportRefId, dateValues } = action.payload
      let { filters, filterForm } = state
      // if current report has changed, ignore any updates
      if (reportRefId === state.activeReportId) {
        filter.FilterValues = []
        filters = {
          ...omit(filters, filterRefId),
          [filter.id]: filter
        }
        filters = Object.freeze(filters)
        filterForm.filters = {
          ...omit(filters, filterRefId),
          [filter.id]: filter
        }
        filter.action = 'update'
        if (filter.type === 'dateRange') {
          const dateRange = { ...filter.dateRange }
          Object.keys(dateValues).forEach(key => {
            dateRange[key] = {
              ...dateRange[key],
              value: dateValues[key]
            }
          })
          filter.dateRange = dateRange
        }
        // if currently selected filter is same, update filterForm
        if (filterForm.activeFilterId === filterRefId) {
          filterForm = {
            ...filterForm,
            activeFilterId: toString(filter.id),
            dirty: filter.type === 'dateRange',
            ...pick(
              filter,
              'name',
              'periscopeName',
              'type',
              'sourceQuery',
              'dateRange',
              'action',
              'isAutoRefreshActive'
            ),
            values: [],
            showFilterValues: filter.type === 'dateRange'
          }
        }
      }
      return {
        ...state,
        fetchingValues: false,
        filters: { ...filters },
        filterForm: { ...filterForm }
      }
    }
    default:
      return state
  }
}

export default reportsReducer
