// @flow
export const CREATE_FEED = 'CREATE_FEED'
export const CREATE_FEED_SUCCESS = 'CREATE_FEED_SUCCESS'
export const CREATE_FEED_FAILED = 'CREATE_FEED_FAILED'

export const FETCH_FEED = 'FETCH_FEED'
export const FETCH_FEED_FAILED = 'FETCH_FEED_FAILED'
export const FETCH_FEED_SUCCESS = 'FETCH_FEED_SUCCESS'

export const FETCH_FEEDS = 'FETCH_FEEDS'
export const FETCH_FEEDS_SUCCESS = 'FETCH_FEEDS_SUCCESS'
export const FETCH_FEEDS_FAILED = 'FETCH_FEEDS_FAILED'

export const SAVE_FEED = 'SAVE_FEED'

export const RESET_FEED = 'RESET_FEED'
export const SWITCH_TOGGLE_FEED = 'SWITCH_TOGGLE_FEED'

export const FORM_VALUES_UPDATE = 'FORM_VALUES_UPDATE'

export const TOGGLE_SWITCH = 'TOGGLE_SWITCH'

export const CREATE_FIREHOSE_FEED = 'CREATE_FIREHOSE_FEED'
export const FETCH_FEEDS_SEARCH_ENTITY_CHANGED = 'FETCH_FEEDS_SEARCH_ENTITY_CHANGED'

export const FETCH_FEED_DELIVERIES = 'FETCH_FEED_DELIVERIES'
export const FETCH_FEED_DELIVERIES_SUCCESS = 'FETCH_FEED_DELIVERIES_SUCCESS'
export const FETCH_FEED_DELIVERIES_FAILURE = 'FETCH_FEED_DELIVERIES_FAILURE'

export const FETCH_FEED_DELIVERIES_SUMMARY = 'FETCH_FEED_DELIVERIES_SUMMARY'
export const FETCH_FEED_DELIVERIES_SUMMARY_SUCCESS = 'FETCH_FEED_DELIVERIES_SUMMARY_SUCCESS'
export const FETCH_FEED_DELIVERIES_SUMMARY_FAILURE = 'FETCH_FEED_DELIVERIES_SUMMARY_FAILURE'

export const FETCH_FEED_GROUP_LOCKS = 'FETCH_FEED_GROUP_LOCKS'
export const FETCH_FEED_GROUP_LOCKS_SUCCESS = 'FETCH_FEED_GROUP_LOCKS_SUCCESS'
export const FETCH_FEED_GROUP_LOCKS_FAILURE = 'FETCH_FEED_GROUP_LOCKS_FAILURE'
export const DELETE_FEED_GROUP_LOCK = 'DELETE_FEED_GROUP_LOCK'
export const DELETE_FEED_GROUP_LOCK_FAILURE = 'DELETE_FEED_GROUP_LOCK_FAILURE'

export const FETCH_CLIENT_FEED_INFO = 'FETCH_CLIENT_FEED_INFO'
export const FETCH_CLIENT_FEED_INFO_SUCCESS = 'FETCH_CLIENT_FEED_INFO_SUCCESS'
export const FETCH_CLIENT_FEED_INFO_FAILURE = 'FETCH_CLIENT_FEED_INFO_FAILURE'

export const FETCH_GLOBAL_FEED_CONFIG = 'FETCH_GLOBAL_FEED_CONFIG'
export const FETCH_GLOBAL_FEED_CONFIG_SUCCESS = 'FETCH_GLOBAL_FEED_CONFIG_SUCCESS'
export const FETCH_GLOBAL_FEED_CONFIG_FAILURE = 'FETCH_GLOBAL_FEED_CONFIG_FAILURE'

export function createFirehoseFeed(feedId: String): Object {
  return {
    type: 'CREATE_FIREHOSE_FEED',
    feedId
  }
}

export function switchToggleFeed(feed: Object): Object {
  return {
    type: SWITCH_TOGGLE_FEED,
    feed
  }
}

export function createFeed(feed: Object): Object {
  return {
    type: CREATE_FEED,
    feed
  }
}

export function createFeedSuccess(payload: Object): Object {
  return {
    type: CREATE_FEED_SUCCESS,
    payload
  }
}

export function createFeedFailed(error: Object): Object {
  return {
    type: CREATE_FEED_FAILED,
    error
  }
}

export function fetchFeed(feedId: String): Object {
  return {
    type: FETCH_FEED,
    feedId
  }
}

export function resetFeed(): Object {
  return {
    type: RESET_FEED
  }
}

export function saveFeed(feed: Object): Object {
  return {
    type: SAVE_FEED,
    feed
  }
}

export function fetchFeeds(offset: Number, count: Number, search: String): Object {
  return {
    type: FETCH_FEEDS,
    offset,
    count,
    search
  }
}

export function fetchFeedsSearchEntityChanged(searchEntity: string) {
  return {
    type: FETCH_FEEDS_SEARCH_ENTITY_CHANGED,
    searchEntity
  }
}

export const fetchFeedDeliveries = payload => ({
  type: FETCH_FEED_DELIVERIES,
  payload
})

export const fetchFeedDeliveriesSummary = payload => ({
  type: FETCH_FEED_DELIVERIES_SUMMARY,
  payload
})

export const fetchFeedGroupLocks = payload => ({
  type: FETCH_FEED_GROUP_LOCKS,
  payload
})

export const deleteFeedGroupLock = id => ({
  type: DELETE_FEED_GROUP_LOCK,
  id
})

export const fetchClientFeedInfo = () => ({
  type: FETCH_CLIENT_FEED_INFO
})

export const fetchGlobalFeedConfig = () => ({
  type: FETCH_GLOBAL_FEED_CONFIG
})

export const fetchGlobalFeedConfigSuccess = data => ({
  type: FETCH_GLOBAL_FEED_CONFIG_SUCCESS,
  data
})

export const fetchGlobalFeedConfigFailure = error => ({
  type: FETCH_GLOBAL_FEED_CONFIG_FAILURE,
  error
})
