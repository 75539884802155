import React, { Component } from 'react'
import { connect } from 'react-redux'
import SetPasswordForm from './set-password-form'
import PropTypes from 'prop-types'

import { Field } from 'redux-form'
import renderUserEditField from './render-field'

import { successModal, confirmModal, addFormModal, clearModals } from '../../actions/modal-actions'
import { setUserPasswordByAdmin } from '../../actions/user-actions'

class PasswordEdit extends Component {
  resetPassword = () => {
    const payload = {
      email: this.props.userEdit.email,
      requester: 'admin',
      callback: () => {
        this.props.successModal(`Reset Password email sent to ${this.props.userEdit.email}!`)
      }
    }
    this.props.resetPassword(payload)
  }

  render() {
    const { id, user, userEdit, isPortalAdmin, isUserEditPortalAdmin } = this.props
    return (
      <div>
        {id > 0 && (
          <div className={'clearfix'}>
            <div className={'rightFormButton'}>
              <button
                className={'btn_custom_secondary'}
                type="button"
                onClick={() =>
                  this.props.confirmModal(
                    `Are you sure you want to send a reset password email to this user (${userEdit.email})?`,
                    'Confirm sending of reset password email',
                    { okButton: 'Yes, Send' },
                    () => this.resetPassword()
                  )
                }
              >
                Send Reset Password Email
              </button>
              {isPortalAdmin && !isUserEditPortalAdmin && (
                <button
                  className={'btn_custom_secondary'}
                  type="button"
                  onClick={event => {
                    event.preventDefault()
                    this.props.addFormModal({
                      id: 'set-password-modal',
                      className: 'set-password-modal',
                      title: `Set new password for ${userEdit.fullname} (${userEdit.email})`,
                      content: (
                        <SetPasswordForm
                          userEdit={userEdit}
                          onClose={this.props.clearModals}
                          onSave={this.props.setUserPasswordByAdmin}
                        />
                      )
                    })
                  }}
                >
                  {`Set Password`}
                </button>
              )}
            </div>
            <h3>RESET PASSWORD</h3>
          </div>
        )}
        {!id && (
          <div>
            <h3>PASSWORD</h3>
            <table>
              <tbody>
                {user.isOnboarded && (
                  <Field
                    name="currentPassword"
                    type="password"
                    component={renderUserEditField}
                    label="Current Password"
                  />
                )}
                <Field name="password" type="password" component={renderUserEditField} label="New Password" />
                <Field
                  name="passwordConfirm"
                  type="password"
                  component={renderUserEditField}
                  label="Confirm Password"
                />
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }
}

PasswordEdit.propTypes = {
  id: PropTypes.string,
  userEdit: PropTypes.object,
  user: PropTypes.object,
  onForgotPassword: PropTypes.func,
  successModal: PropTypes.func,
  confirmModal: PropTypes.func,
  resetPassword: PropTypes.func,
  isPortalAdmin: PropTypes.bool,
  isUserEditPortalAdmin: PropTypes.bool,
  addFormModal: PropTypes.func,
  clearModals: PropTypes.func,
  setUserPasswordByAdmin: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  successModal: content => dispatch(successModal(content)),
  confirmModal: (content, title, okButton, callback) => dispatch(confirmModal(content, title, okButton, callback)),
  addFormModal: payload => dispatch(addFormModal(payload)),
  clearModals: () => dispatch(clearModals()),
  setUserPasswordByAdmin: payload => dispatch(setUserPasswordByAdmin(payload))
})

export default connect(null, mapDispatchToProps)(PasswordEdit)
