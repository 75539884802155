/* eslint-disable max-len */

/*!
 * deps
 */

import React from 'react'
import IconBase from './IconBase'

/**
 * ArrowDown component
 */
const ArrowDown = props => {
  return (
    <IconBase viewBox="0 0 30 30" {...props}>
      <path id="arrowDown" d="M19.9 14l-5.4 5.5L9.1 14z" {...props} />
    </IconBase>
  )
}

export default ArrowDown
