import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cx from 'classnames'

import { isEmpty } from 'lodash'

import { Form } from 'react-bootstrap'

import BasicFeedConfig from './BasicFeedConfig'
import AdvancedFeedConfig from './AdvancedFeedConfig'
import ProductToggle from '../../Tabs/ProductToggle'

import { updateGroupForm, fetchGroupFeedsInfo } from '../../../../actions/group-edit-actions'

import { products as PRODUCTS } from '../../../../constants/constants'

import './index.scss'

const FEEDS_PRODUCT_KEY = PRODUCTS.adminToolsFeeds

class DataFeedsTab extends Component {
  constructor(props) {
    super(props)

    this.state = {
      feedsEnabled: false,
      feedsFilePublicEncryptionKey: null,
      feedSftpUsername: null,
      feedSftpPassword: null,
      feedSftpFolderName: null,
      feedSftpPath: null,
      feedSftpFileNamingScheme: null,
      concatenateFeedFiles: null,
      areFeedsCompressed: false,
      s3Region: 'us-east-1',
      s3AccessKeyId: null,
      s3SecretAccessKey: null,
      s3Bucket: null,
      s3KmsKeyId: null,
      idfAdditionalEmails: null
    }
  }

  componentDidMount() {
    this.props.fetchGroupFeedsInfo(this.props.groupId)
  }

  componentDidUpdate(prevProps) {
    if (isEmpty(this.state) && isEmpty(prevProps.feedForm) && !isEmpty(this.props.feedForm)) {
      this.setState(this.props.feedForm)
    }
  }

  handleInputChange = inputEvent => {
    const { id, value } = inputEvent.target

    this.setState({ [id]: value })
  }

  handleInputBlur = () => {
    this.props.updateGroupForm('feeds', this.state)
  }

  handleToggleChange = newPosition => {
    this.setState({ concatenateFeedFiles: newPosition }, this.handleInputBlur)
  }

  handleSelectChange = selectEvent => {
    const { id, value } = selectEvent.target

    this.setState({ [id]: value }, this.handleInputBlur)
  }

  render() {
    const { groupId, productEnabled } = this.props

    return (
      <div className="group-edit-feeds-tab">
        <ProductToggle groupId={groupId} productKey={FEEDS_PRODUCT_KEY} />

        <Form horizontal className={cx({ 'disable-panel': !productEnabled })}>
          <BasicFeedConfig
            groupId={groupId}
            formState={this.state}
            handleInputBlur={this.handleInputBlur}
            handleInputChange={this.handleInputChange}
          />

          <AdvancedFeedConfig
            formState={this.state}
            handleInputBlur={this.handleInputBlur}
            handleInputChange={this.handleInputChange}
            handleToggleChange={this.handleToggleChange}
            handleSelectChange={this.handleSelectChange}
          />
        </Form>
      </div>
    )
  }
}

DataFeedsTab.propTypes = {
  groupId: PropTypes.string,
  feedForm: PropTypes.object,
  productEnabled: PropTypes.bool,
  updateGroupForm: PropTypes.func,
  fetchGroupFeedsInfo: PropTypes.func
}

DataFeedsTab.defaultProps = {}

const mapStateToProps = ({
  groupEdit: {
    basicInfo,
    groupForm: { feeds }
  }
}) => ({
  feedForm: feeds,
  productEnabled: basicInfo && basicInfo.Products ? basicInfo.Products[FEEDS_PRODUCT_KEY] !== undefined : false
})

export default connect(mapStateToProps, {
  updateGroupForm,
  fetchGroupFeedsInfo
})(DataFeedsTab)
