import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

export default class SearchTypeahead extends Component {
  onChange(data) {
    this.props.onChange(data)

    /* Clear the search input if specified */
    if (data && data.length && this.props.clearOnSelect) {
      this.clear()
    }
  }

  clear() {
    this.typeaheadRef.getInstance().clear()
  }

  onInputChange(text) {
    if (!text || text.length < 1) return
    this.props.onInputChange(text.toLowerCase())
  }

  focus() {
    this.typeaheadRef.getInstance().focus()
  }

  onBlurChange = () => {
    if (this.props.autoClean) {
      this.clear()
    }
  }

  render() {
    return (
      <Typeahead
        paginate
        minLength={this.props.minLength}
        align={this.props.align}
        bsSize={this.props.size}
        className={this.props.className}
        autoFocus={this.props.autoFocus}
        labelKey={this.props.labelKey}
        disabled={this.props.disabled}
        clearButton={this.props.clearButton}
        multiple={this.props.multiple}
        options={this.props.suggestions}
        paginationText="Show More Results"
        onChange={this.onChange.bind(this)}
        onMenuShow={this.props.onMenuShow}
        placeholder={this.props.placeholder}
        selected={this.props.selectedValues}
        ref={ref => {
          this.typeaheadRef = ref
        }}
        onInputChange={this.onInputChange.bind(this)}
        renderMenuItemChildren={this.props.renderSuggestions}
        renderToken={this.props.renderToken}
        isLoading={this.props.isLoading}
        emptyLabel={this.props.isLoading ? 'Loading...' : 'No matches found.'}
        onBlur={e => this.onBlurChange(e)}
      />
    )
  }
}

SearchTypeahead.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onMenuShow: PropTypes.func,
  onInputChange: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  clearOnSelect: PropTypes.bool,
  renderSuggestions: PropTypes.func,
  size: PropTypes.string,
  align: PropTypes.string,
  selectedValues: PropTypes.array,
  multiple: PropTypes.bool,
  labelKey: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  clearButton: PropTypes.bool,
  renderToken: PropTypes.func,
  minLength: PropTypes.number,
  isLoading: PropTypes.bool,
  autoClean: PropTypes.bool
}

SearchTypeahead.defaultProps = {
  minLength: 1,
  isLoading: false,
  autoClean: false,
  onMenuShow: () => {},
  suggestions: [],
  autoFocus: true,
  align: 'justify',
  labelKey: 'value',
  clearButton: false,
  className: 'search_search'
}
