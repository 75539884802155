import { takeLatest, call, fork } from 'redux-saga/effects'
import axios from 'axios'

import { FETCH_HELP_DOC } from '../actions/help-doc-actions'

function* getHelpDocFlow() {
  yield takeLatest(FETCH_HELP_DOC, getHelpDoc)
}

function* getHelpDoc(action) {
  try {
    if (!action.url) {
      return console.log('Help doc action did not include URL')
    }

    const helpDoc = yield call(() =>
      axios({
        url: action.url,
        method: 'get',
        responseType: 'blob'
      })
    )
    const docLink = window.URL.createObjectURL(helpDoc.data)
    if (action.targetWindow) {
      action.targetWindow.location = docLink
    } else {
      window.open(docLink) // will cause pop-up to be blocked by browser
    }
  } catch (error) {
    window.captureException(error.message)
    console.log(error.message)
    if (action.targetWindow) {
      action.targetWindow.close()
    }
  }
}

export default [fork(getHelpDocFlow)]
