import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'

import BetaIcon from 'components/Icon/BetaIcon'
import useLeftNav from './leftNavContext'
import InfoTooltipWhite from '../Tooltips/InfoTooltipWhite'

const SubMenuItem = ({ label, route, isBeta = false, info = '' }) => {
  const { closeMenu } = useLeftNav()

  return (
    <span className={cx('willBeInvisible')}>
      <NavLink
        className="sub-menu-link"
        to={route}
        onClick={e => {
          closeMenu()
          e.stopPropagation()
        }}
      >
        {label}
        {isBeta && <BetaIcon />}
        {info && (
          <span style={{ position: 'relative', top: '3px' }}>
            <InfoTooltipWhite iconClass="ml-2" text={info} />
          </span>
        )}
      </NavLink>
    </span>
  )
}

SubMenuItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isBeta: PropTypes.bool,
  info: PropTypes.string
}

export default SubMenuItem
