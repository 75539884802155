// @flow
// eslint-disable-next-line no-redeclare
/* global TENANT */
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { loadAsyncComponents } from 'utils/route-util'

const asyncPages = {}

if (TENANT === 'ri') {
  loadAsyncComponents(asyncPages, {
    SearchPage: import('../SearchPage' /* webpackMode: "eager" */),
    Taxonomy: import('../Taxonomy' /* webpackMode: "eager" */),
    RulesPage: import('../Rules/RulesPage' /* webpackMode: "eager" */),
    RetainerPage: import('../Retrainer/RetrainerPage' /* webpackMode: "eager" */),
    CMEDictionary: import('../CMEDictionary/CMEDictionaryPage' /* webpackMode: "eager" */),
    CMEInspectorPage: import('../CMEInspectorPage' /* webpackMode: "eager" */),
    DeliveryJobsPage: import('../DeliveryJobsPage/DeliveryJobsPage' /* webpackMode: "eager" */),
    QCSupportPage: import('../QCSupportPage' /* webpackMode: "eager" */)
  })
}

/**
 * Container for the CME page root (/cme). Required by the router.
 */
const CMEPage = () => {
  const { user } = useSelector(state => state.session)
  if (!user) return null

  return (
    <div className="admin-container">
      <Switch>
        <Route path="/cme/search" component={asyncPages.SearchPage} />
        <Route path="/cme/taxonomy" component={asyncPages.Taxonomy} />
        <Route path="/cme/rules" component={asyncPages.RulesPage} />
        <Route path="/cme/retrainer" component={asyncPages.RetainerPage} />
        <Route path="/cme/dictionary" component={asyncPages.CMEDictionary} />
        <Route path="/cme/cme-inspector" component={asyncPages.CMEInspectorPage} />
        <Route path="/cme/delivery-jobs/:tab?" component={asyncPages.DeliveryJobsPage} />
        <Route path="/cme/qc-support/:tab?" component={asyncPages.QCSupportPage} />
      </Switch>
    </div>
  )
}

export default CMEPage
