import { createAction } from '@reduxjs/toolkit'

// GENERAL MODAL
export const RuleModalActions = {
  startRuleLoading: createAction('RULE_MODAL_START_RULE_LOADING'),
  clear: createAction('RULE_MODAL_CLEAR')
}

// CATEGORY-BRAND
export const CategoryBrandRulesActions = {
  fetch: createAction('FETCH_RULES_CATEGORY_BRAND'),
  successLoad: createAction('SUCCESS_LOAAD_RULES_CATEGORY_BRAND'),
  failedLoad: createAction('FAILED_LOAAD_RULES_CATEGORY_BRAND'),
  updateSettings: createAction('UPDATE_RULES_CATEGORY_BRAND_SETTINGS'),
  clearFilters: createAction('CLEAR_FILTERS_RULES_CATEGORY_BRAND_SETTINGS'),

  save: createAction('SAVE_RULE_CATEGORY_BRAND'),

  download: createAction('DOWNLOAD_RULES_CATEGORY_BRAND')
}

// MARCHANT-CATEGORY
export const MerchantCategoryRulesActions = {
  fetch: createAction('FETCH_RULES_MERCHANT_CATEGORY'),
  successLoad: createAction('SUCCESS_LOAAD_RULES_MERCHANT_CATEGORY'),
  failedLoad: createAction('FAILED_LOAAD_RULES_MERCHANT_CATEGORY'),
  updateSettings: createAction('UPDATE_RULES_MERCHANT_CATEGORY_SETTINGS'),
  clearFilters: createAction('CLEAR_FILTERS_RULES_MERCHANT_CATEGORY_SETTINGS'),

  fetchByMerchantAndCategory: createAction('FETCH_RULE_BY_MERCHANT_AND_CATEGORY'),
  successLoadByMerchantAndCategory: createAction('SUCCESS_LOAD_RULE_BY_MERCHANT_AND_CATEGORY'),

  save: createAction('SAVE_RULE_MERCHANT_CATEGORY'),

  download: createAction('DOWNLOAD_RULES_MERCHANT_CATEGORY')
}

// MARCHANT-BRAND
export const MerchantBrandRulesActions = {
  fetch: createAction('FETCH_RULES_MERCHANT_BRAND'),
  successLoad: createAction('SUCCESS_LOAAD_RULES_MERCHANT_BRAND'),
  failedLoad: createAction('FAILED_LOAAD_RULES_MERCHANT_BRAND'),
  updateSettings: createAction('UPDATE_RULES_MERCHANT_BRAND_SETTINGS'),
  clearFilters: createAction('CLEAR_FILTERS_RULES_MERCHANT_BRAND_SETTINGS'),

  fetchByMerchantAndBrand: createAction('FETCH_RULE_BY_MERCHANT_AND_BRAND'),
  successLoadByMerchantAndBrand: createAction('SUCCESS_LOAD_RULE_BY_MERCHANT_AND_BRAND'),

  save: createAction('SAVE_RULE_MERCHANT_BRAND'),

  download: createAction('DOWNLOAD_RULES_MERCHANT_BRAND')
}

// MARCHANT-CATEGORY-REGEX
export const MerchantCategoryRegexRulesActions = {
  fetch: createAction('FETCH_RULES_MERCHANT_CATEGORY_REGEX'),
  successLoad: createAction('SUCCESS_LOAAD_RULES_MERCHANT_CATEGORY_REGEX'),
  failedLoad: createAction('FAILED_LOAAD_RULES_MERCHANT_CATEGORY_REGEX'),
  updateSettings: createAction('UPDATE_RULES_MERCHANT_CATEGORY_REGEX_SETTINGS'),
  clearFilters: createAction('CLEAR_FILTERS_RULES_MERCHANT_CATEGORY_REGEX_SETTINGS'),

  fetchRuleByMainFields: createAction('FETCH_RULE_MERCHANT_AND_CATEGORY_REGEX_BY_MAIN_FIELDS'),
  successLoadByMainFields: createAction('SUCCESS_LOAD_RULE_MERCHANT_AND_CATEGORY_REGEX_BY_MAIN_FIELDS'),

  save: createAction('SAVE_RULE_MERCHANT_CATEGORY_REGEX'),

  download: createAction('DOWNLOAD_RULES_MERCHANT_CATEGORY_REGEX')
}

// MARCHANT-BRAND-REGEX
export const MerchantBrandRegexRulesActions = {
  fetch: createAction('FETCH_RULES_MERCHANT_BRAND_REGEX'),
  successLoad: createAction('SUCCESS_LOAAD_RULES_MERCHANT_BRAND_REGEX'),
  failedLoad: createAction('FAILED_LOAAD_RULES_MERCHANT_BRAND_REGEX'),
  updateSettings: createAction('UPDATE_RULES_MERCHANT_BRAND_REGEX_SETTINGS'),
  clearFilters: createAction('CLEAR_FILTERS_RULES_MERCHANT_BRAND_REGEX_SETTINGS'),

  fetchRuleByMainFields: createAction('FETCH_RULE_MERCHANT_AND_BRAND_REGEX_BY_MAIN_FIELDS'),
  successLoadByMainFields: createAction('SUCCESS_LOAD_RULE_MERCHANT_AND_BRAND_REGEX_BY_MAIN_FIELDS'),

  save: createAction('SAVE_RULE_MERCHANT_BRAND_REGEX'),

  download: createAction('DOWNLOAD_RULES_MERCHANT_BRAND_REGEX')
}

// BRAND-SYNONYM
export const BrandSynonymRulesActions = {
  fetch: createAction('FETCH_RULES_BRAND_SYNONYM'),
  successLoad: createAction('SUCCESS_LOAAD_RULES_BRAND_SYNONYM'),
  failedLoad: createAction('FAILED_LOAAD_RULES_BRAND_SYNONYM'),
  updateSettings: createAction('UPDATE_RULES_BRAND_SYNONYM_SETTINGS'),
  clearFilters: createAction('CLEAR_FILTERS_RULES_BRAND_SYNONYM_SETTINGS'),

  fetchRuleByMainFields: createAction('FETCH_RULE_BRAND_SYNONYM_BY_MAIN_FIELDS'),
  successLoadByMainFields: createAction('SUCCESS_LOAD_RULE_BRAND_SYNONYM_REGEX_BY_MAIN_FIELDS'),

  save: createAction('SAVE_RULE_BRAND_SYNONYM'),

  download: createAction('DOWNLOAD_RULES_BRAND_SYNONYM')
}

// CATEGORY-BRAND-PATH
export const CategoryBrandPathRulesActions = {
  fetch: createAction('FETCH_RULES_CATEGORY_BRAND_PATH'),
  successLoad: createAction('SUCCESS_LOAAD_RULES_CATEGORY_BRAND_PATH'),
  failedLoad: createAction('FAILED_LOAAD_RULES_CATEGORY_BRAND_PATH'),
  updateSettings: createAction('UPDATE_RULES_CATEGORY_BRAND_PATH_SETTINGS'),
  clearFilters: createAction('CLEAR_FILTERS_RULES_CATEGORY_BRAND_PATH_SETTINGS'),

  download: createAction('DOWNLOAD_RULES_CATEGORY_BRAND_PATH')
}

// DEPLOY INFO
export const DeployStatsActions = {
  fetch: createAction('FETCH_RULES_DEPLOY_STATS'),
  successLoad: createAction('SUCCESS_LOAD_RULES_DEPLOY_STATS')
}
