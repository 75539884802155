export const FETCH_PARTNERS = 'FETCH_PARTNERS'
export const FETCH_PARTNERS_SUCCESS = 'FETCH_PARTNERS_SUCCESS'
export const FETCH_PARTNERS_FAILURE = 'FETCH_PARTNERS_FAILURE'

export const EXCLUDE_PARTNER = 'EXCLUDE_PARTNER'
export const EXCLUDE_PARTNER_SUCCESS = 'EXCLUDE_PARTNER_SUCCESS'
export const EXCLUDE_PARTNER_FAILURE = 'EXCLUDE_PARTNER_FAILURE'

export const INCLUDE_PARTNER = 'INCLUDE_PARTNER'
export const INCLUDE_PARTNER_SUCCESS = 'INCLUDE_PARTNER_SUCCESS'
export const INCLUDE_PARTNER_FAILURE = 'INCLUDE_PARTNER_FAILURE'

export const fetchPartners = () => ({
  type: FETCH_PARTNERS
})

export function fetchPartnersSuccess(data) {
  return {
    type: FETCH_PARTNERS_SUCCESS,
    data
  }
}

export function fetchPartnersFailure(error) {
  return {
    type: FETCH_PARTNERS_FAILURE,
    error
  }
}

export const excludePartner = partnerId => ({
  type: EXCLUDE_PARTNER,
  partnerId
})

export function excludePartnerSuccess() {
  return {
    type: EXCLUDE_PARTNER_SUCCESS
  }
}

export function excludePartnerFailure(error) {
  return {
    type: EXCLUDE_PARTNER_FAILURE,
    error
  }
}

export const includePartner = partnerId => ({
  type: INCLUDE_PARTNER,
  partnerId
})

export function includePartnerSuccess() {
  return {
    type: INCLUDE_PARTNER_SUCCESS
  }
}

export function includePartnerFailure(error) {
  return {
    type: INCLUDE_PARTNER_FAILURE,
    error
  }
}
