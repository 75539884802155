import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import DocumentTitle from 'react-document-title'

import { Tabs, Tab, Alert } from 'react-bootstrap'

import Loader from '../Loader'
import GroupEditToolbar from './GroupEditToolbar/index'

import GeneralTab from './Tabs/GeneralTab'
import CustomReportsTab from './Tabs/CustomReportsTab'
import PermissionsTab from './Tabs/PermissionsTab'
import AdminToolsTab from './Tabs/AdminToolsTab'
import DataFeedsTab from './Tabs/DataFeedsTab/index'

import { fetchGroupBasicInfo } from '../../actions/group-edit-actions'
import { fetchProducts } from '../../actions/app-actions'

import { appName } from '../../constants/constants'

import './index.scss'
import './Tabs/tabs.scss'

const DATA_FEEDS = 'DATA_FEEDS'
const PERMISSIONS = 'PERMISSIONS'
const ADMIN_TOOLS = 'ADMIN_TOOLS'
const GENERAL_TAB = 'GENERAL_TAB'
const CUSTOM_REPORTS_TAB = 'CUSTOM_REPORTS_TAB'

class GroupEditPageNew extends Component {
  constructor(props) {
    super(props)

    this.state = {
      displayError: false,
      selectedTab: GENERAL_TAB,
      enabledProducts: {}
    }
  }

  componentDidMount() {
    if (isEmpty(this.props.products)) {
      this.props.fetchProducts()
    }

    if (this.props.groupId !== 'new') {
      this.props.fetchGroupBasicInfo(this.props.groupId)
    }
  }

  componentDidUpdate(prevProps) {
    // Update route with new group's id
    if (prevProps.groupId === 'new' && this.props.basicInfo.id !== undefined && this.props.basicInfo.id !== 'new') {
      this.props.history.replace(`/admin/groups/${this.props.basicInfo.id}/new`)
    }

    // Incoming error
    if (prevProps.error !== this.props.error) {
      this.setState({ displayError: true })
    }

    // Set initial form state
    if (isEmpty(this.state.enabledProducts) && !isEmpty(this.props.basicInfo.Products)) {
      this.setState({ enabledProducts: this.props.basicInfo.Products })
    }
  }

  handleTabSelect = key => {
    this.setState({ selectedTab: key })
  }

  handleErrorDismiss = () => {
    this.setState({ displayError: false })
  }

  getTabs = () => {
    const { selectedTab, enabledProducts } = this.state
    const { groupForm, basicInfo, groupId } = this.props

    const disableTabs = groupId === 'new' || !basicInfo.isActive

    return (
      <Tabs id={'group-edit-tabs'} className="portal-tabs" activeKey={selectedTab} onSelect={this.handleTabSelect}>
        <Tab title="General" eventKey={GENERAL_TAB}>
          <GeneralTab basicInfo={basicInfo} formState={groupForm.basicInfo} />
        </Tab>

        <Tab mountOnEnter title="Custom Reports" eventKey={CUSTOM_REPORTS_TAB} disabled={disableTabs}>
          <CustomReportsTab groupId={groupId} enabledProducts={enabledProducts} />
        </Tab>

        <Tab mountOnEnter title="Data Feeds" eventKey={DATA_FEEDS} disabled={disableTabs}>
          <DataFeedsTab groupId={groupId} enabledProducts={enabledProducts} />
        </Tab>

        <Tab mountOnEnter title="Permissions" eventKey={PERMISSIONS} disabled={disableTabs}>
          <PermissionsTab groupId={groupId} enabledProducts={enabledProducts} />
        </Tab>

        <Tab title="Admin Tools" eventKey={ADMIN_TOOLS} disabled={disableTabs}>
          <AdminToolsTab groupId={groupId} enabledProducts={enabledProducts} isPortalGroup={basicInfo.isPortalGroup} />
        </Tab>
      </Tabs>
    )
  }

  render() {
    const { displayError } = this.state
    const { error, loading, groupId, newDocumentTitle, editDocumentTitle } = this.props
    const documentTitle =
      newDocumentTitle && editDocumentTitle
        ? `${appName} | ${groupId === 'new' ? newDocumentTitle : editDocumentTitle}`
        : appName
    return (
      <DocumentTitle title={documentTitle}>
        <div className="group-edit-page">
          <GroupEditToolbar />

          {loading && <Loader overlap />}

          {this.getTabs()}

          {displayError && (
            <Alert bsStyle="danger" onDismiss={this.handleErrorDismiss}>
              {error}
            </Alert>
          )}
        </div>
      </DocumentTitle>
    )
  }
}

GroupEditPageNew.defaultProps = {
  editDocumentTitle: 'Edit Group',
  newDocumentTitle: 'Create Group'
}

GroupEditPageNew.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  groupId: PropTypes.string,
  params: PropTypes.object,
  history: PropTypes.object,
  products: PropTypes.object,
  basicInfo: PropTypes.object,
  groupForm: PropTypes.object,
  fetchProducts: PropTypes.func,
  fetchGroupBasicInfo: PropTypes.func,
  editDocumentTitle: PropTypes.string,
  newDocumentTitle: PropTypes.string
}

const mapStateToProps = (
  { session: { products }, groupEdit: { basicInfo, loading, error, groupForm } },
  { match: { params } }
) => {
  return {
    error,
    groupId: params.id,
    loading,
    basicInfo,
    groupForm,
    products
  }
}

export default connect(mapStateToProps, {
  fetchProducts,
  fetchGroupBasicInfo
})(GroupEditPageNew)
