import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './index.scss'

const SortIcon = ({ className, asc, desc }) => {
  return (
    <span className={cx('sort-icon', className)}>
      <span
        className={cx('sort-by', {
          asc,
          desc
        })}
      />
    </span>
  )
}

SortIcon.propTypes = {
  className: PropTypes.string,
  asc: PropTypes.bool,
  desc: PropTypes.bool
}

export default SortIcon
