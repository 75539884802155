import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  PROFILE,
  PROFILE_SUCCESS,
  PROFILE_FAILED,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  VERIFY_ONBOARD_TOKEN_STARTED,
  VERIFY_ONBOARD_TOKEN_FAILED,
  RESENDING_ONBOARD_EMAIL_STARTED,
  RESENDING_ONBOARD_EMAIL_DONE
} from '../actions/auth-actions'

import {
  SESSION_UPDATE_SUCCESS,
  SESSION_UPDATE_ERROR,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_GROUP_PRODUCTS_SUCCESS
} from '../actions/app-actions'

import { FETCH_GROUP_SUGGESTIONS_SUCCESS, FETCH_GROUP_SUGGESTIONS_FAILED } from '../actions/group-actions'

import { SET_PRODUCT_ONBOARDED_FAILED, SET_PRODUCT_ONBOARDED_SUCCESS } from '../actions/walkthrough-actions'

const initialState = {
  userEdit: {
    groupSuggestions: []
  },
  products: {},
  permissions: {
    data: {},
    count: 0
  },
  isOnboarding: false
}

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GROUP_PRODUCTS_SUCCESS:
      return {
        ...state,
        groupProducts: {
          ...state.groupProducts,
          [action.id]: {
            ...action.products
          }
        }
      }

    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.products
      }

    case FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: {
          // If we gave an offset, we append the data instead of replacing it
          data: action.offset ? [...state.data, ...action.data] : action.data,
          count: action.count,
          offset: action.offset
        }
      }

    case SESSION_UPDATE_SUCCESS:
      return {
        ...state,
        ...(action.data ? action.data : action.payload)
      }

    case SESSION_UPDATE_ERROR:
      return {
        ...state,
        loading: false
      }
    case LOGIN:
      return {
        ...state,
        loading: true
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isOnboarding: false,
        auth: action.payload
      }

    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        sessionMessageType: action.payload
      }

    case PROFILE:
      return {
        ...state,
        loading: true
      }

    case PROFILE_SUCCESS:
      const { user, customReports, permissions, products, defaultProduct } = action.payload

      return {
        ...state,
        loading: false,
        user: {
          ...user,
          customReports,
          products,
          permissions,
          defaultProduct
        }
      }

    case PROFILE_FAILED:
      return {
        ...state,
        sessionMessageType: null,
        loading: false,
        error: action.payload
      }

    case FETCH_GROUP_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          groupSuggestions: action.groups
        }
      }

    case FETCH_GROUP_SUGGESTIONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message
      }

    case SET_PRODUCT_ONBOARDED_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isProductOnboarded: true
        }
      }

    case SET_PRODUCT_ONBOARDED_FAILED:
      return {
        ...state,
        error: action.error
      }

    case LOGOUT_SUCCESS:
      return {
        ...initialState
      }

    case LOGOUT_FAILED:
      return {
        ...state,
        error: action.payload
      }

    case VERIFY_ONBOARD_TOKEN_STARTED:
      return {
        ...initialState,
        isOnboarding: true,
        error: null
      }
    case VERIFY_ONBOARD_TOKEN_FAILED:
      return {
        ...state,
        isOnboarding: false,
        error: action.payload
      }
    case RESENDING_ONBOARD_EMAIL_STARTED:
      return {
        ...state,
        isOnboarding: true
      }
    case RESENDING_ONBOARD_EMAIL_DONE:
      return {
        ...state,
        isOnboarding: false,
        resendEmail: {
          ...action.payload
        }
      }
    default:
      return state
  }
}

export default sessionReducer
