export const AUTOSUGGEST_SUCCEEDED = 'AUTOSUGGEST_SUCCEEDED'
export const AUTOSUGGEST_FAILED = 'AUTOSUGGEST_FAILED'
export const CLOSE_ERROR_MESSAGE = 'CLOSE_ERROR_MESSAGE'
export const QUERY_RUN_STARTED = 'QUERY_RUN_STARTED'
export const QUERY_RUN_SUCCEEDED = 'QUERY_RUN_SUCCEEDED'
export const QUERY_RUN_FAILED = 'QUERY_RUN_FAILED'
export const QUERY_SET_QUALITY = 'QUERY_SET_QUALITY'
export const SEARCH_TERM_SUGGEST = 'SEARCH_TERM_SUGGEST'
export const QUERY_TREE_CHANGED = 'QUERY_TREE_CHANGED'
export const APPLY_QUERY = 'APPLY_QUERY'
export const LOAD_DEFAULT_QUERY = 'LOAD_DEFAULT_QUERY'
export const FETCH_TYPE_CHILDREN = 'FETCH_TYPE_CHILDREN'
export const FETCH_TYPE_CHILDREN_SUCCEEDED = 'FETCH_TYPE_CHILDREN_SUCCEEDED'
export const FETCH_TYPE_CHILDREN_FAILED = 'FETCH_TYPE_CHILDREN_FAILED'
export const TOGGLE_QC_MODE = 'TOGGLE_QC_MODE'
export const TOGGLE_TREE_VISIBILITY = 'TOGGLE_TREE_VISIBILITY'
export const HIDE_TREE = 'HIDE_TREE'
export const GET_SEARCH_METADATA = 'GET_SEARCH_METADATA'
export const GET_SEARCH_METADATA_SUCCEEDED = 'GET_SEARCH_METADATA_SUCCEEDED'
export const GET_SEARCH_METADATA_FAILED = 'GET_SEARCH_METADATA_FAILED'
export const CHANGE_CATEGORY_FILTER = 'CHANGE_CATEGORY_FILTER'
export const TOGGLE_LAYOUT_LOCK = 'TOGGLE_LAYOUT_LOCK'
export const FETCH_SAVED_GROUP = 'FETCH_SAVED_GROUP'
export const FETCH_SAVED_GROUP_SUCCEEDED = 'FETCH_SAVED_GROUP_SUCCEEDED'
export const FETCH_SAVED_GROUP_FAILED = 'FETCH_SAVED_GROUP_FAILED'
export const INPUT_GROUP_CHANGE = 'INPUT_GROUP_CHANGE'
export const GROUP_SELECT = 'GROUP_SELECT'
export const GROUP_CREATE = 'GROUP_CREATE'

export const CREATE_GROUP_SUCCEEDED = 'CREATE_GROUP_SUCCEEDED'
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED'
export const FETCH_QUERIES_BY_GROUP = 'FETCH_QUERIES_BY_GROUP'
export const FETCH_QUERIES_BY_GROUP_SUCCEEDED = 'FETCH_QUERIES_BY_GROUP_SUCCEEDED'
export const FETCH_QUERIES_BY_GROUP_FAILED = 'FETCH_QUERIES_BY_GROUP_FAILED'

export const QUERY_SELECT = 'QUERY_SELECT'
export const QUERY_CREATE = 'QUERY_CREATE'

export const CREATE_QUERY_SUCCEEDED = 'CREATE_QUERY_SUCCEEDED'
export const CREATE_QUERY_FAILED = 'CREATE_QUERY_FAILED'
export const INPUT_QUERY_CHANGE = 'INPUT_QUERY_CHANGE'
export const GET_PREVIOUS_QUERY = 'GET_PREVIOUS_QUERY'
export const GET_NEXT_QUERY = 'GET_NEXT_QUERY'
export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_SUCCEEDED = 'DELETE_GROUP_SUCCEEDED'
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED'
export const DELETE_QUERY = 'DELETE_QUERY'
export const DELETE_QUERY_SUCCEEDED = 'DELETE_QUERY_SUCCEEDED'
export const DELETE_QUERY_FAILED = 'DELETE_QUERY_FAILED'
export const RESET_QUERY_HEADER = 'RESET_QUERY_HEADER'
export const SAVE_SEARCH_QUERY = 'SAVE_SEARCH_QUERY'
export const SAVE_SEARCH_QUERY_SUCCEEDED = 'SAVE_SEARCH_QUERY_SUCCEEDED'
export const SAVE_SEARCH_QUERY_FAILED = 'SAVE_SEARCH_QUERY_FAILED'
export const UPDATE_QUERY_VALUE = 'UPDATE_QUERY_VALUE'
export const UPDATE_GROUP_VALUE = 'UPDATE_GROUP_VALUE'
export const GROUP_SEARCH_MODE = 'GROUP_SEARCH_MODE'
export const QUERY_SEARCH_MODE = 'QUERY_SEARCH_MODE'
export const QUERY_STATS_STARTED = 'QUERY_STATS_STARTED'
export const QUERY_STATS_SUCCEEDED = 'QUERY_STATS_SUCCEEDED'
export const QUERY_STATS_FAILED = 'QUERY_STATS_FAILED'
export const STAT_FILTER_CHANGED = 'STAT_FILTER_CHANGED'
export const SET_ACTIVE_SEARCH_TAB = 'SET_ACTIVE_SEARCH_TAB'
export const UPDATE_QUERY_HEADER_EDIT_STATUS = 'UPDATE_QUERY_HEADER_EDIT_STATUS'
export const CLEAR_QUERY_TREE = 'CLEAR_QUERY_TREE'
export const CLEAR_COMPLETE_QUERY = 'CLEAR_COMPLETE_QUERY'
export const CREATE_MULTIPLE_RULES = 'CREATE_MULTIPLE_RULES'
export const GET_QUERY_SQL = 'GET_QUERY_SQL'
export const GET_QUERY_SQL_STARTED = 'GET_QUERY_SQL_STARTED'
export const GET_QUERY_SQL_SUCCEEDED = 'GET_QUERY_SQL_SUCCEEDED'
export const GET_QUERY_SQL_FAILED = 'GET_QUERY_SQL_FAILED'

export function loadDefaultQuery() {
  return {
    type: LOAD_DEFAULT_QUERY
  }
}
export function searchTermSuggest({ term, filter, excludeParents, excludeInactive }) {
  return {
    type: SEARCH_TERM_SUGGEST,
    data: term,
    filter,
    excludeParents,
    excludeInactive
  }
}
export function changeCategoryFilter(filterList) {
  return {
    type: CHANGE_CATEGORY_FILTER,
    filterList
  }
}
export function getSearchMetaData() {
  return {
    type: GET_SEARCH_METADATA
  }
}
export function fetchTypeChildren(type, value, id) {
  return {
    type: FETCH_TYPE_CHILDREN,
    data: {
      type,
      value,
      id
    }
  }
}

export function toggleQCMode() {
  return {
    type: TOGGLE_QC_MODE
  }
}

export function setSelectedTab(payload) {
  return {
    type: SET_ACTIVE_SEARCH_TAB,
    payload
  }
}

export function toggleTreeVisibility() {
  return {
    type: TOGGLE_TREE_VISIBILITY
  }
}

export function hideTree() {
  return {
    type: HIDE_TREE
  }
}

export function handleQueryTreeChanged(queryTree, event, actionArgs) {
  return {
    type: QUERY_TREE_CHANGED,
    queryTree,
    event,
    actionArgs
  }
}

export function applyQuery(payload = {}) {
  return {
    type: APPLY_QUERY,
    payload
  }
}

export function toggleLayoutLock() {
  return {
    type: TOGGLE_LAYOUT_LOCK
  }
}

export function getAllGroup() {
  return {
    type: FETCH_SAVED_GROUP
  }
}

export function onInputGroupChange(inputValue) {
  return {
    type: INPUT_GROUP_CHANGE,
    inputValue
  }
}

export function onGroupChange(selectedGroup) {
  return {
    type: GROUP_SELECT,
    selectedGroup
  }
}

export function onGroupCreate(value) {
  return {
    type: GROUP_CREATE,
    value
  }
}

export function fetchQueries() {
  return {
    type: FETCH_QUERIES_BY_GROUP
  }
}

export function onQueryChange(selectedQuery) {
  return {
    type: QUERY_SELECT,
    selectedQuery
  }
}

export function onQueryCreate(value) {
  return {
    type: QUERY_CREATE,
    value
  }
}

export function onInputQueryChange(inputValue) {
  return {
    type: INPUT_QUERY_CHANGE,
    inputValue
  }
}

export function getPreviousQuery() {
  return {
    type: GET_PREVIOUS_QUERY
  }
}

export function getNextQuery() {
  return {
    type: GET_NEXT_QUERY
  }
}

export function onDeleteGroup() {
  return {
    type: DELETE_GROUP
  }
}

export function onDeleteQuery() {
  return {
    type: DELETE_QUERY
  }
}

export function resetQueryHeader() {
  return {
    type: RESET_QUERY_HEADER
  }
}

export function saveSearchQuery() {
  return {
    type: SAVE_SEARCH_QUERY
  }
}

export function onUpdatedQueryValue(value) {
  return {
    type: UPDATE_QUERY_VALUE,
    value
  }
}

export function onUpdatedGroupValue(value) {
  return {
    type: UPDATE_GROUP_VALUE,
    value
  }
}

export function onClickGroupSearch() {
  return {
    type: GROUP_SEARCH_MODE
  }
}

export function onClickQuerySearch() {
  return {
    type: QUERY_SEARCH_MODE
  }
}

export function onStatFilterChanged(selectedFilter) {
  return {
    type: STAT_FILTER_CHANGED,
    selectedFilter
  }
}

export function updateQueryHeaderEditStatus(status) {
  return {
    type: UPDATE_QUERY_HEADER_EDIT_STATUS,
    status
  }
}

export function clearQueryTree() {
  return {
    type: CLEAR_QUERY_TREE
  }
}

export function getQuerySQL(queryTree) {
  return {
    type: GET_QUERY_SQL,
    queryTree
  }
}

export function clearCompleteQuery() {
  return {
    type: CLEAR_COMPLETE_QUERY
  }
}

export function createMultipleRules(event, ruleId, values) {
  return {
    type: CREATE_MULTIPLE_RULES,
    event,
    ruleId,
    values
  }
}
