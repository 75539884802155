import { createAction } from '@reduxjs/toolkit'

export const refreshSamplingSessionsTable = createAction('REFRESH_SAMPLING_SESSIONS_TABLE')
export const refreshDictionaryTable = createAction('REFRESH_DICTIONARY_TABLE')
export const generateNewSampleForSupraQC = createAction('GENERATE_NEW_SAMPLE_FOR_SUPRA_QC')
export const fetchSampleDataForSession = createAction('FETCH_SAMPLE_DATA_FOR_SESSION')
export const refreshSamplingQCTable = createAction('REFRESH_SAMPLING_QC_TABLE')
export const fetchRegexMatchesAcrossSample = createAction('FETCH_REGEX_MATCHES_ACROSS_SAMPLE')
export const supraQCToolApplyQuerySamplingQC = createAction('SUPRA_QC_TOOL_APPLY_QUERY_SAMPLING_QC')
export const triggerDictionaryChecks = createAction('SUPRA_QC_TOOL_TRIGGER_DICTIONARY_CHECKS')
export const toggleDictionaryEntryDeletedStatus = createAction('SUPRA_QC_TOOL_TOGGLE_DICTIONARY_ENTRY_DELETED_STATUS')
export const fetchErrorDetailsForSamplingSession = createAction(
  'SUPRA_QC_TOOL_FETCH_ERROR_DETAILS_FOR_SAMPLING_SESSION'
)
export const cancelSamplingSessionJob = createAction('SUPRA_QC_TOOL_CANCEL_SAMPLING_SESSION_JOB')
