import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'

export default class CME extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object,
    fill: PropTypes.string
  }

  static defaultProps = {
    width: 30,
    height: 20,
    fill: 'currentColor'
  }

  render() {
    return (
      <IconBase viewBox="0 0 30 20" {...this.props}>
        <g id="svg_7">
          <rect id="svg_1" height="3.22918" width="3.03001" y="16.52095" x="16.04253" />
          <rect id="svg_2" height="3.22918" width="3.03001" y="16.52095" x="21.34505" />
          <rect id="svg_3" height="3.22918" width="3.03001" y="10.86989" x="16.04253" />
          <rect id="svg_4" height="3.22918" width="3.03001" y="10.86989" x="21.34505" />
          <path
            id="svg_5"
            d="m24.37506,6.02612l-4.54501,0l0,-3.22918c0,-1.3724 -0.98475,-2.42188 -2.27251,-2.42188l-4.54501,0c-1.28775,0 -2.27251,1.04948 -2.27251,2.42188l0,3.22918l-4.54501,0c-0.4545,0 -0.7575,0.32292 -0.7575,0.80729c0,0.08073 0,0.08073 0,0.16146l1.43925,9.76827c0.07575,0.80729 0.7575,1.3724 1.515,1.3724l6.13577,0l0,-1.61459l-6.13577,0l-1.28775,-8.88024l17.27105,0l0,-1.61459zm-12.12004,-3.22918c0,-0.48438 0.303,-0.80729 0.7575,-0.80729l4.54501,0c0.4545,0 0.7575,0.32292 0.7575,0.80729l0,3.22918l-6.06002,0l0,-3.22918l0.00001,0z"
          />
        </g>
      </IconBase>
    )
  }
}
