// @flow
import {
  FETCH_GROUPS_FAILED,
  FETCH_GROUPS_SUCCESS,
  SAVE_GROUP_PERMISSIONS_SUCCESS,
  FETCH_GROUP_PERMISSIONS_SUCCESS
} from '../actions/group-actions'

import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialState = {
  data: [],
  count: 0,
  offset: 0,
  permissions: {}
}

const groupsReducer = (state: Object = initialState, action: Object): Object => {
  switch (action.type) {
    case FETCH_GROUP_PERMISSIONS_SUCCESS:
    case SAVE_GROUP_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [action.groupId]: action.data
        }
      }

    case FETCH_GROUPS_SUCCESS:
      return {
        count: action.count,
        offset: action.offset,
        data: action.offset ? [...state.data, ...action.data] : action.data
      }

    case FETCH_GROUPS_FAILED:
      return { error: action.message }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default groupsReducer
