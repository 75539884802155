import { takeLatest, select, put, call } from 'redux-saga/effects'
import axios from 'axios'
import cuid from 'cuid'
import {
  SUPRA_QC_ADD_TO_DICT_REGEX_CHANGED,
  SUPRA_QC_ADD_TO_DICT_FROM_ADDR_REGEX_CHANGED,
  SUPRA_QC_ADD_TO_DICT_RESULTS_FETCHED,
  SUPRA_QC_ADD_ENTRY_TO_DICT,
  SUPRA_QC_ADD_TO_DICT_SUCCEEDED,
  SUPRA_QC_ADD_TO_DICT_MODAL_OPEN,
  SUPRA_QC_ADD_TO_DICT_FAILED,
  SUPRA_QC_ADD_TO_DICT_QUERY_FAILED,
  SUPRA_QC_ADD_TO_DICT_QUERY_STARTED,
  SUPRA_QC_ON_DICT_TABLE_REFRESH,
  SUPRA_QC_FETCH_DICT_ENTRIES_STARTED,
  SUPRA_QC_FETCH_DICT_ENTRIES_SUCCEEDED,
  SUPRA_QC_FETCH_DICT_ENTRIES_FAILED,
  SUPRA_QC_FETCH_SAVED_GROUP_SUCCEEDED,
  SUPRA_QC_FETCH_SAVED_GROUP_FAILED,
  SUPRA_QC_FETCH_SAVED_GROUP,
  SUPRA_QC_RESET_QUERY_HEADER,
  SUPRA_QC_CREATE_GROUP_SUCCEEDED,
  SUPRA_QC_CREATE_GROUP_FAILED,
  SUPRA_QC_GROUP_CREATE,
  SUPRA_QC_FETCH_QUERIES_BY_GROUP_SUCCEEDED,
  SUPRA_QC_FETCH_QUERIES_BY_GROUP_FAILED,
  SUPRA_QC_FETCH_QUERIES_BY_GROUP,
  SUPRA_QC_CREATE_QUERY_SUCCEEDED,
  SUPRA_QC_CREATE_QUERY_FAILED,
  SUPRA_QC_DELETE_GROUP_SUCCEEDED,
  SUPRA_QC_DELETE_GROUP_FAILED,
  SUPRA_QC_DELETE_QUERY_SUCCEEDED,
  SUPRA_QC_DELETE_QUERY_FAILED,
  SUPRA_QC_SAVE_SEARCH_QUERY_SUCCEEDED,
  SUPRA_QC_SAVE_SEARCH_QUERY_FAILED,
  SUPRA_QC_DELETE_GROUP,
  SUPRA_QC_DELETE_QUERY,
  SUPRA_QC_SAVE_SEARCH_QUERY,
  SUPRA_QC_QUERY_CREATE,
  SUPRA_QC_ADD_TO_DICT_TEMPLATE_CHANGED
} from 'actions/supra-qc-tool-actions'
import { createAlert } from 'actions/app-actions'

const getSupraQCToolState = state => state.supraQCTool

function* fetchDictionaryTableEntriesFromApi(action) {
  const apiPath = '/api/supra-qc/fetch-dictionary-entries'
  try {
    yield put({
      type: SUPRA_QC_FETCH_DICT_ENTRIES_STARTED,
      loading: true
    })
    const queryResult = yield call(() => axios.get(apiPath))
    yield put({
      type: SUPRA_QC_FETCH_DICT_ENTRIES_SUCCEEDED,
      rows: queryResult.data.rows,
      total: queryResult.data.total,
      lastDictionarySync: queryResult.data.lastDictionarySync
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: SUPRA_QC_FETCH_DICT_ENTRIES_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.response.data,
          headline: 'Dictionary Tab'
        }
      ],
      alertTimeout: 10000,
      message: error.response.data,
      loading: false
    })
  }
}

function* fetchAddToDictRegexMatches(action) {
  let regex, from_addr_regex, is_regex, source
  const supraQCTool = yield select(getSupraQCToolState)
  const addToDictModal = supraQCTool.addToDictModal

  if (action.type === SUPRA_QC_ADD_TO_DICT_MODAL_OPEN) {
    regex = action.selectedRow.subject
    from_addr_regex = action.selectedRow.from_addr
    is_regex = Boolean(action.selectedRow.is_regex)
    source = action.source
  } else {
    regex = addToDictModal.regex
    from_addr_regex = addToDictModal.from_addr_regex
    is_regex = addToDictModal.isRegex
    source = action.source
  }
  const apiPath = '/api/supra-qc/fetch-regex-matches'
  try {
    yield put({
      type: SUPRA_QC_ADD_TO_DICT_QUERY_STARTED,
      loading: true
    })
    const queryResult = yield call(() => axios.post(apiPath, { regex, from_addr_regex, is_regex, source }))
    yield put({
      type: SUPRA_QC_ADD_TO_DICT_RESULTS_FETCHED,
      rows: queryResult.data.rows,
      total: queryResult.data.total,
      existingRules: queryResult.data.existingRules
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: SUPRA_QC_ADD_TO_DICT_QUERY_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.response.data,
          headline: 'Add to Dictionary'
        }
      ],
      alertTimeout: 10000,
      message: error.response.data,
      loading: false
    })
  }
}

function* addEntryToDictionary(action) {
  const { dictionaryEntry } = action
  const apiPath = '/api/supra-qc/add-to-dictionary'
  try {
    yield call(() => axios.post(apiPath, { dictionaryEntry }))
    yield put({
      type: SUPRA_QC_ADD_TO_DICT_SUCCEEDED,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: 'Successfully added entry to dictionary',
          headline: 'Add to Dictionary'
        }
      ],
      alertTimeout: 10000,
      message: 'Successfully added entry to dictionary'
    })
    yield put({
      type: SUPRA_QC_ON_DICT_TABLE_REFRESH
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: SUPRA_QC_ADD_TO_DICT_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.response.data,
          headline: 'Add to Dictionary'
        }
      ],
      alertTimeout: 10000,
      message: error.response.data,
      loading: false
    })
  }
}

function* fetchGroups() {
  try {
    const response = yield call(() => axios.get('/api/supra-qc/group'))
    yield put({
      type: SUPRA_QC_FETCH_SAVED_GROUP_SUCCEEDED,
      data: response.data || []
    })
  } catch (error) {
    yield put({
      type: SUPRA_QC_FETCH_SAVED_GROUP_FAILED
    })
  }
}

function* onCreateGroup(action) {
  try {
    const response = yield call(() => axios.post('/api/supra-qc/group', { name: action.value }))
    yield put({
      type: SUPRA_QC_CREATE_GROUP_SUCCEEDED,
      data: response.data
    })
    yield fetchGroups()
    yield fetchQueriesByGroup(false)
  } catch (error) {
    yield put({
      type: SUPRA_QC_CREATE_GROUP_FAILED
    })
  }
}

function* fetchQueriesByGroup(clear) {
  const supraQCToolState = yield select(getSupraQCToolState)
  try {
    const response = yield call(() =>
      axios.get('/api/supra-qc/query', { params: { id: supraQCToolState.queryHeader.group.selectedID } })
    )
    yield put({
      type: SUPRA_QC_FETCH_QUERIES_BY_GROUP_SUCCEEDED,
      clear,
      data: response.data || []
    })
  } catch (error) {
    yield put({
      type: SUPRA_QC_FETCH_QUERIES_BY_GROUP_FAILED
    })
    yield put(createAlert('danger', error.message, `Queries by Group fetch failed`))
  }
}

function* fetchSavedQueries() {
  yield fetchQueriesByGroup(false)
}

function* onCreateGroupQuery(action) {
  const supraQCToolState = yield select(getSupraQCToolState)
  try {
    const response = yield call(() =>
      axios.post('/api/supra-qc/query', {
        queryName: action.value,
        query: supraQCToolState.queryTree,
        groupName: supraQCToolState.queryHeader.group.selectedValue
      })
    )
    yield put({
      type: SUPRA_QC_CREATE_QUERY_SUCCEEDED,
      data: response.data,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: 'Successfully created Query',
          headline: 'Query Created'
        }
      ]
    })
    yield fetchQueriesByGroup(true)
  } catch (error) {
    window.captureException(error)
    const errorMessage = 'Failed to create query group'
    yield put({
      type: SUPRA_QC_CREATE_QUERY_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: errorMessage,
          headline: 'Create Query Group'
        }
      ],
      alertTimeout: 10000
    })
  }
}

function* deleteGroup() {
  const supraQCToolState = yield select(getSupraQCToolState)
  try {
    const response = yield call(() =>
      axios.delete('/api/supra-qc/group', { data: { id: supraQCToolState.queryHeader.group.selectedID } })
    )
    yield put({
      type: SUPRA_QC_DELETE_GROUP_SUCCEEDED,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: response.data,
          headline: 'Group Deleted'
        }
      ]
    })
    yield fetchGroups()
  } catch (error) {
    window.captureException(error)
    const errorMessage = 'Failed to delete query group'
    yield put({
      type: SUPRA_QC_DELETE_GROUP_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: errorMessage,
          headline: 'Delete Query Group'
        }
      ],
      alertTimeout: 10000
    })
  }
}

function* deleteQuery() {
  const supraQCToolState = yield select(getSupraQCToolState)
  try {
    const response = yield call(() =>
      axios.delete('/api/supra-qc/query', { data: { id: supraQCToolState.queryHeader.query.selectedID } })
    )
    yield put({
      type: SUPRA_QC_DELETE_QUERY_SUCCEEDED,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: response.data,
          headline: 'Query Deleted'
        }
      ]
    })
    yield fetchQueriesByGroup(true)
  } catch (error) {
    window.captureException(error)
    const errorMessage = 'Failed to delete query'
    yield put({
      type: SUPRA_QC_DELETE_QUERY_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: errorMessage,
          headline: 'Delete Query'
        }
      ],
      alertTimeout: 10000
    })
  }
}

function* saveSearchQuery() {
  try {
    const supraQCToolState = yield select(getSupraQCToolState)
    const payload = {
      group: {
        id: supraQCToolState.queryHeader.group.selectedID,
        name: supraQCToolState.queryHeader.group.updatedValue
      },
      query: {
        id: supraQCToolState.queryHeader.query.selectedID,
        name: supraQCToolState.queryHeader.query.updatedValue
      },
      queryTree: supraQCToolState.queryTree
    }
    const response = yield call(() => axios.post('/api/supra-qc', payload))
    yield put({
      type: SUPRA_QC_SAVE_SEARCH_QUERY_SUCCEEDED,
      data: response.data,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: `Updated Query Successfully`,
          headline: 'Query Manager'
        }
      ]
    })
  } catch (error) {
    window.captureException(error)
    const errorMessage = 'Failed to update query'
    yield put({
      type: SUPRA_QC_SAVE_SEARCH_QUERY_FAILED,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: errorMessage,
          headline: 'Query Manager'
        }
      ],
      alertTimeout: 10000
    })
  }
}

function* supraQCSaga() {
  yield takeLatest(SUPRA_QC_ON_DICT_TABLE_REFRESH, fetchDictionaryTableEntriesFromApi)
  yield takeLatest(SUPRA_QC_ADD_TO_DICT_REGEX_CHANGED, fetchAddToDictRegexMatches)
  yield takeLatest(SUPRA_QC_ADD_TO_DICT_FROM_ADDR_REGEX_CHANGED, fetchAddToDictRegexMatches)
  yield takeLatest(SUPRA_QC_ADD_TO_DICT_MODAL_OPEN, fetchAddToDictRegexMatches)
  yield takeLatest(SUPRA_QC_ADD_ENTRY_TO_DICT, addEntryToDictionary)
  yield takeLatest([SUPRA_QC_FETCH_SAVED_GROUP, SUPRA_QC_RESET_QUERY_HEADER], fetchGroups)
  yield takeLatest(SUPRA_QC_GROUP_CREATE, onCreateGroup)
  yield takeLatest(SUPRA_QC_FETCH_QUERIES_BY_GROUP, fetchSavedQueries)
  yield takeLatest(SUPRA_QC_DELETE_GROUP, deleteGroup)
  yield takeLatest(SUPRA_QC_DELETE_QUERY, deleteQuery)
  yield takeLatest(SUPRA_QC_SAVE_SEARCH_QUERY, saveSearchQuery)
  yield takeLatest(SUPRA_QC_QUERY_CREATE, onCreateGroupQuery)
  yield takeLatest(SUPRA_QC_ADD_TO_DICT_TEMPLATE_CHANGED, fetchAddToDictRegexMatches)
}

export default supraQCSaga
