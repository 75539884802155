import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DnDWrapper from './DnDWrapper'
import { Glyphicon } from 'react-bootstrap'
import cx from 'classnames'
import { keyActions, getFolderItemKeyMap } from './folder-utils'
import { HotKeys } from 'react-hotkeys'

const noop = () => {}

/**
 * Generic component to display a folder component i.e. both file and folder
 * @export
 * @class FolderItem
 * @extends {Component}
 */
export default class FolderItem extends Component {
  static getDerivedStateFromProps(props, state) {
    if (!state.editing) {
      return {
        editName: props.name
      }
    }
    return null
  }

  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      editName: ''
    }
    this.keyHandlers = {
      [keyActions.START_RENAME_ITEM]: this.initializeNameEditing,
      [keyActions.CANCEL_RENAME_ITEM]: this.cancelRename,
      [keyActions.SAVE_RENAME_ITEM]: this.saveRename,
      [keyActions.OPEN_FOLDER_ITEM]: this.openFolderItem
    }
  }

  saveUpdatedName = () => {
    let { editName: newName = '' } = this.state
    const { name: oldName, onRename, id } = this.props
    newName = newName.trim()
    if (onRename && newName && oldName !== newName) {
      onRename(id, newName)
    }
  }

  handleNameChange = () => {
    this.saveUpdatedName()
    this.nameInput.blur()
  }

  onNameInputBlur = () => {
    this.focusFolderItem()
    this.setState({ editing: false })
  }

  initializeNameEditing = () => {
    const { onRename } = this.props
    const { editing } = this.state
    if (onRename && !editing) {
      this.setState({ editing: true }, () => {
        this.nameInput.focus()
        this.nameInput.select()
      })
    }
  }

  focusFolderItem = () => {
    setTimeout(() => {
      if (this.folderItem) {
        this.folderItem.focus()
      }
    }, 0)
  }

  cancelRename = () => {
    if (this.state.editing) {
      this.onNameInputBlur()
    }
  }

  openFolderItem = () => {
    if (!this.state.editing) {
      const { onClick, onDoubleClick } = this.props
      ;(onClick || onDoubleClick || noop)()
    }
  }

  saveRename = () => {
    if (this.state.editing) {
      this.handleNameChange()
    }
  }

  render() {
    const {
      dragKey,
      name,
      onClick,
      onDoubleClick,
      draggable,
      droppable,
      glyph,
      className,
      onItemDragOver,
      onItemDropped,
      dragOverDelay,
      Tag,
      tabIndex
    } = this.props
    const { editing, editName } = this.state
    return (
      <DnDWrapper
        id={dragKey}
        Tag={Tag}
        draggable={draggable}
        droppable={droppable}
        onDragOver={onItemDragOver}
        dragOverDelay={dragOverDelay}
        onDropReceived={onItemDropped}
      >
        <HotKeys keyMap={getFolderItemKeyMap(editing)} handlers={this.keyHandlers} component="span">
          <div
            tabIndex={tabIndex}
            className={cx('folder-item', className)}
            ref={el => (this.folderItem = el)}
            onClick={onClick || this.focusFolderItem}
            onDoubleClick={onDoubleClick}
          >
            <Glyphicon glyph={glyph} />
            {!editing && (
              /* hide label if name is being edited */
              <span className="folder-item-name" style={{ display: editing ? 'none' : undefined }}>
                {name}
              </span>
            )}
            <input
              readOnly={!editing}
              style={{
                /* hide input box if not editing name */
                display: editing ? undefined : 'none'
              }}
              type="text"
              value={editName}
              ref={el => (this.nameInput = el)}
              onChange={event => {
                this.setState({ editName: event.target.value })
              }}
              onBlur={this.onNameInputBlur}
            />
          </div>
        </HotKeys>
      </DnDWrapper>
    )
  }
}

FolderItem.defaultProps = {
  glyph: 'file',
  dragOverDelay: 0,
  draggable: false,
  droppable: false,
  onItemDragOver: noop,
  onItemDropped: noop,
  onDoubleClick: noop,
  Tag: 'div'
}

FolderItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dragKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  draggable: PropTypes.bool,
  droppable: PropTypes.bool,
  onItemDragOver: PropTypes.func,
  onItemDropped: PropTypes.func,
  onRename: PropTypes.func,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  dragOverDelay: PropTypes.number,
  glyph: PropTypes.string.isRequired,
  Tag: PropTypes.string.isRequired,
  tabIndex: PropTypes.number
}
