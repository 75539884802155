import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { confirmModal } from '../../actions/modal-actions'

const ResendOnboardingLink = ({ email, confirmModal, onResendOnboardingLink }) => (
  <div>
    {
      <div className={'clearfix'}>
        <div className={'rightFormButton'}>
          <button
            className={'btn_custom_secondary'}
            type="button"
            onClick={() =>
              confirmModal(
                `Are you sure you want to re-send an onboarding email to this user (${email})?`,
                'Confirm resending of onboarding email',
                { okButton: 'Yes, Send' },
                () => onResendOnboardingLink(email)
              )
            }
          >
            Resend Onboarding Email
          </button>
        </div>
        <h3>Onboarding / Re-verification</h3>
      </div>
    }
  </div>
)

ResendOnboardingLink.propTypes = {
  email: PropTypes.string,
  confirmModal: PropTypes.func,
  onResendOnboardingLink: PropTypes.func
}
const mapDispatchToProps = dispatch => ({
  confirmModal: (content, title, okButton, callback) => dispatch(confirmModal(content, title, okButton, callback))
})

export default connect(null, mapDispatchToProps)(ResendOnboardingLink)
