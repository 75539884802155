import config from '../config'

const primaryColor = config.colors.primaryColor
const theme = {
  Calendar: {
    color: '#4C4C4C',
    fontSize: '15px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 'bold'
  },
  MonthAndYear: {
    fontSize: '15px',
    fontWeight: 'bold',
    background: '#fff'
  },
  MonthButton: {
    background: '#fff'
  },
  MonthArrowPrev: {
    borderRightColor: primaryColor
  },
  MonthArrowNext: {
    borderLeftColor: primaryColor
  },
  Day: {
    background: '#f7f7f7',
    color: '#999999',
    border: '3px solid #fff'
  },
  DaySelected: {
    background: primaryColor
  },
  DayHover: {
    background: '#f7f7f7',
    border: `2px solid ${primaryColor}`,
    color: '#000'
  },
  DayInRange: {
    background: primaryColor,
    color: '#fff'
  },
  PredefinedRangesItem: {
    color: primaryColor,
    background: '#fff'
  },
  PredefinedRangesItemActive: {
    color: primaryColor,
    background: '#fff',
    fontWeight: 'bold'
  }
}
export default theme
