export const FETCH_CCD_COUNTS = 'FETCH_CCD_COUNTS'
export const FETCH_CCD_COUNTS_SUCCESS = 'FETCH_CCD_COUNTS_SUCCESS'
export const FETCH_CLIENTS = 'FETCH_CLIENTS'
export const FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS'
export const FETCH_CLIENTS_ERROR = 'FETCH_CLIENTS_ERROR'
export const FETCH_CONTRACTS = 'FETCH_CONTRACTS'
export const FETCH_CONTRACTS_SUCCESS = 'FETCH_CONTRACTS_SUCCESS'
export const FETCH_CONTRACTS_ERROR = 'FETCH_CONTRACTS_ERROR'
export const FETCH_DELIVERABLES = 'FETCH_DELIVERABLES'
export const FETCH_DELIVERABLES_SUCCESS = 'FETCH_DELIVERABLES_SUCCESS'
export const FETCH_DELIVERABLES_ERROR = 'FETCH_DELIVERABLES_ERROR'
export const UPDATE_SELECTED_CLIENT = 'UPDATE_SELECTED_CLIENT'
export const UPDATE_SELECTED_CLIENT_SUCCESS = 'UPDATE_SELECTED_CLIENT_SUCCESS'
export const UPDATE_SELECTED_CONTRACT = 'UPDATE_SELECTED_CONTRACT'
export const UPDATE_SELECTED_CONTRACT_SUCCESS = 'UPDATE_SELECTED_CONTRACT_SUCCESS'
export const UPDATE_SELECTED_DELIVERABLE = 'UPDATE_SELECTED_DELIVERABLE'
export const UPDATE_SELECTED_DELIVERABLE_SUCCESS = 'UPDATE_SELECTED_DELIVERABLE_SUCCESS'
export const REMOVE_SELECTED_DELIVERABLE = 'REMOVE_SELECTED_DELIVERABLE'
export const INITIALIZE_NEW_DELIVERABLE = 'INITIALIZE_NEW_DELIVERABLE'
export const INITIALIZE_CLIENT_FORM = 'INITIALIZE_CLIENT_FORM'
export const UPDATE_CLIENT_FORM = 'UPDATE_CLIENT_FORM'
export const CHECK_CLIENT_NAME_AVAILABLE = 'CHECK_CLIENT_NAME_AVAILABLE'
export const UPDATE_CLIENT_NAME_AVAILABLE = 'UPDATE_CLIENT_NAME_AVAILABLE'
export const SAVE_CLIENT = 'SAVE_CLIENT'
export const SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS'
export const INITIALIZE_CONTRACT_FORM = 'INITIALIZE_CONTRACT_FORM'
export const INITIALIZE_CONTRACT_FORM_SUCCESS = 'INITIALIZE_CONTRACT_FORM_SUCCESS'
export const UPDATE_CONTRACT_FORM = 'UPDATE_CONTRACT_FORM'
export const CHECK_CONTRACT_NAME_AVAILABLE = 'CHECK_CONTRACT_NAME_AVAILABLE'
export const UPDATE_CONTRACT_NAME_AVAILABLE = 'UPDATE_CONTRACT_NAME_AVAILABLE'
export const CHECK_DELIVERABLE_NAME_AVAILABLE = 'CHECK_DELIVERABLE_NAME_AVAILABLE'
export const UPDATE_DELIVERABLE_NAME_AVAILABLE = 'UPDATE_DELIVERABLE_NAME_AVAILABLE'
export const SAVE_CONTRACT = 'SAVE_CONTRACT'
export const SAVE_CONTRACT_SUCCESS = 'SAVE_CONTRACT_SUCCESS'
export const SEARCH_CCD = 'SEARCH_CCD'
export const SEARCH_CCD_SUCCESS = 'SEARCH_CCD_SUCCESS'
export const SEARCH_CCD_ERROR = 'SEARCH_CCD_ERROR'
export const SEARCH_MORE_CCD = 'SEARCH_MORE_CCD'
export const SEARCH_MORE_CCD_SUCCESS = 'SEARCH_MORE_CCD_SUCCESS'
export const SEARCH_CLIENTS = 'SEARCH_CLIENTS'
export const SEARCH_CLIENTS_SUCCESS = 'SEARCH_CLIENTS_SUCCESS'
export const SEARCH_CLIENTS_ERROR = 'SEARCH_CLIENTS_ERROR'
export const SEARCH_CONTRACTS = 'SEARCH_CONTRACTS'
export const SEARCH_CONTRACTS_SUCCESS = 'SEARCH_CONTRACTS_SUCCESS'
export const SEARCH_CONTRACTS_ERROR = 'SEARCH_CONTRACTS_ERROR'
export const SEARCH_USERS = 'SEARCH_USERS'
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS'
export const SEARCH_USERS_ERROR = 'SEARCH_USERS_ERROR'
export const UPDATE_DELIVERABLE_STATUS = 'UPDATE_DELIVERABLE_ACTIVATION_STATUS'
export const FETCH_CURRENT_DELIVERIES_PROGRESS = 'FETCH_CURRENT_DELIVERIES_PROGRESS'
export const FETCH_CURRENT_DELIVERIES_PROGRESS_SUCCESS = 'FETCH_CURRENT_DELIVERIES_PROGRESS_SUCCESS'
export const FETCH_DELIVERY_LOGS = 'FETCH_DELIVERY_LOGS'
export const FETCH_DELIVERY_LOGS_SUCCESS = 'FETCH_DELIVERY_LOGS_SUCCESS'
export const FETCH_DELIVERY_LOGS_FAILURE = 'FETCH_DELIVERY_LOGS_FAILURE'
export const UPDATE_DELIVERY_LOGS_TABLE = 'UPDATE_DELIVERY_LOGS_TABLE'
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const DELETE_CONTRACT = 'DELETE_CONTRACT'
export const DELETE_DELIVERABLE = 'DELETE_DELIVERABLE'
export const TOGGLE_SHOW_INACTIVE = 'TOGGLE_SHOW_INACTIVE'
export const DOWNLOAD_CLIENTS = 'DOWNLOAD_CLIENTS'
export const DOWNLOAD_CONTRACTS = 'DOWNLOAD_CONTRACTS'
export const DOWNLOAD_DELIVERABLES = 'DOWNLOAD_DELIVERABLES'

export function fetchCCDCounts(payload) {
  return {
    type: FETCH_CCD_COUNTS,
    payload
  }
}

export function fetchClients(payload) {
  return {
    type: FETCH_CLIENTS,
    payload
  }
}

export function fetchContracts(payload) {
  return {
    type: FETCH_CONTRACTS,
    payload
  }
}

export function fetchDeliverables(payload) {
  return {
    type: FETCH_DELIVERABLES,
    payload
  }
}

export function updateSelectedClient(payload) {
  return {
    type: UPDATE_SELECTED_CLIENT,
    payload
  }
}

export function updateSelectedContract(payload) {
  return {
    type: UPDATE_SELECTED_CONTRACT,
    payload
  }
}

export function updateSelectedDeliverable(payload) {
  return {
    type: UPDATE_SELECTED_DELIVERABLE,
    payload
  }
}

export function removeSelectedDeliverable() {
  return {
    type: REMOVE_SELECTED_DELIVERABLE
  }
}

export function initializeNewDeliverable() {
  return {
    type: INITIALIZE_NEW_DELIVERABLE
  }
}

export function initializeClientForm(payload) {
  return {
    type: INITIALIZE_CLIENT_FORM,
    payload
  }
}

export function updateClientForm(payload) {
  return {
    type: UPDATE_CLIENT_FORM,
    payload
  }
}

export function checkIfClientNameAvailable(payload) {
  return {
    type: CHECK_CLIENT_NAME_AVAILABLE,
    payload
  }
}

export function saveClient(payload) {
  return {
    type: SAVE_CLIENT,
    payload
  }
}

export function initializeContractForm(payload) {
  return {
    type: INITIALIZE_CONTRACT_FORM,
    payload
  }
}

export function updateContractForm(payload) {
  return {
    type: UPDATE_CONTRACT_FORM,
    payload
  }
}

export function checkIfContractNameAvailable(payload) {
  return {
    type: CHECK_CONTRACT_NAME_AVAILABLE,
    payload
  }
}

export function checkIfDeliverableNameAvailable(payload) {
  return {
    type: CHECK_DELIVERABLE_NAME_AVAILABLE,
    payload
  }
}
export function saveContract(payload) {
  return {
    type: SAVE_CONTRACT,
    payload
  }
}

export function onCCDSearchChange({ value }) {
  return {
    type: SEARCH_CCD,
    payload: {
      value
    }
  }
}

export function onCCDSearchMore({ value, type, offset }) {
  return {
    type: SEARCH_MORE_CCD,
    payload: {
      value,
      type,
      offset
    }
  }
}

export function onClientSearchChange(clientLookupValue) {
  return {
    type: SEARCH_CLIENTS,
    clientLookupValue
  }
}

export function onContractSearchChange(contractLookupValue, clientId) {
  return {
    type: SEARCH_CONTRACTS,
    contractLookupValue,
    clientId
  }
}

export function onUserSearchChange(userLookupField, userLookupValue) {
  return {
    type: SEARCH_USERS,
    userLookupField,
    userLookupValue
  }
}

export function updateDeliverableStatus(payload) {
  return {
    type: UPDATE_DELIVERABLE_STATUS,
    payload
  }
}

export function refreshDeliveriesProgress(currentDeliveryIds) {
  return {
    type: FETCH_CURRENT_DELIVERIES_PROGRESS,
    currentDeliveryIds
  }
}

export const fetchDeliveryLogs = payload => ({
  type: FETCH_DELIVERY_LOGS,
  payload
})

export const updateDeliveryLogsTable = payload => ({
  type: UPDATE_DELIVERY_LOGS_TABLE,
  payload
})

export const deleteClient = clientId => ({
  type: DELETE_CLIENT,
  clientId
})

export const deleteContract = contractId => ({
  type: DELETE_CONTRACT,
  contractId
})

export const deleteDeliverable = deliverableId => ({
  type: DELETE_DELIVERABLE,
  deliverableId
})

export const toggleShowInactive = () => ({
  type: TOGGLE_SHOW_INACTIVE
})

export const downloadClients = () => ({
  type: DOWNLOAD_CLIENTS
})

export const downloadContracts = () => ({
  type: DOWNLOAD_CONTRACTS
})

export const downloadDeliverables = () => ({
  type: DOWNLOAD_DELIVERABLES
})
