import {
  HOVER_BEACON,
  BEACON_VIEWED,
  TOGGLE_BEACONS,
  REGISTER_BEACON,
  ACTIVATE_WALKTHROUGH,
  UPDATE_BEACON_LOCATIONS
} from '../actions/walkthrough-actions'

import { LOGOUT_SUCCESS } from '../actions/auth-actions'

import getComponentLocation from '../services/react-dom-service'

const initialState = {
  beacons: {},
  beaconLocations: {},
  isEnabled: true,
  periscopeEnabled: false,
  activeBeacon: 'left-nav',
  lastUpdate: new Date()
}

const beaconGroups = {
  periscope: {
    'left-nav': true,
    'periscope-filters': true,
    'periscope-actions': true
  }
}

const walkthroughReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIVATE_WALKTHROUGH:
      let beacons = {}

      if (action.products.periscope) {
        beacons = {
          ...beacons,
          periscope: beaconGroups.periscope
        }
      }

      return {
        ...state,
        beacons,
        periscopeEnabled: action.products.active
      }

    case TOGGLE_BEACONS:
      let toggleGroup = {}
      if (action.beaconGroup === 'periscope') {
        toggleGroup = {
          periscopeEnabled: !state.periscopeEnabled
        }
      }

      return {
        ...state,
        ...toggleGroup,
        beacons: {
          ...state.beacons,
          [action.beaconGroup]: beaconGroups[action.beaconGroup]
        }
      }

    case HOVER_BEACON:
      return {
        ...state,
        activeBeacon: action.activeBeacon
      }

    case BEACON_VIEWED:
      if (action.beaconId === 'left-nav') {
        return {
          ...state,
          beacons: {
            periscope: {
              ...state.beacons.periscope,
              'left-nav': false
            }
          }
        }
      }

      return {
        ...state,
        beacons: {
          ...state.beacons,
          [action.beaconGroup]: {
            ...state.beacons[action.beaconGroup],
            [action.beaconId]: false
          }
        }
      }

    case REGISTER_BEACON:
      return {
        ...state,
        beaconLocations: {
          ...state.beaconLocations,
          [action.beaconId]: getComponentLocation(action.beaconRef)
        }
      }

    case UPDATE_BEACON_LOCATIONS:
      return {
        ...state,
        lastUpdate: new Date()
      }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default walkthroughReducer
