import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import modalReducer from './reducers/modal-reducers'
import tasksReducer from './reducers/tasks-reducers'
import etlStatesReducer from './reducers/etl-states-reducers'
import feedReducer from './reducers/feed-reducers'
import jobReducer from './reducers/job-reducers'
import appReducer from './reducers/app-reducers'
import sessionReducer from './reducers/session-reducers'
import searchReducer from './reducers/search-reducers'
import searchEditReducer from './reducers/search-edit-reducers'
import searchQCReducer from './reducers/search-qc-reducers'

import supraQCToolReducer from './reducers/supra-qc-tool-reducer'

import usersReducer from './reducers/users-reducers'
import partnerReducer from './reducers/partner-reducer'
import mailboxProviderReducer from './reducers/mailbox-provider-reducer'
import merchantReducer from './reducers/merchant-reducers'
import l1CategoryReducer from './reducers/l1-category-reducers'
import filterReducer from './reducers/filter-reducers'
import reportsReducer from './reducers/custom-reports-reducers'
import reportFoldersReducer from './reducers/report-folders-reducer'
import reportEditReducer from './reducers/custom-report-edit-reducer'
import groupsReducer from './reducers/groups-reducers'
import walkthroughReducer from './reducers/walkthrough-reducers'
import authlogsReducer from './reducers/authlogs-reducer'
import dmReducer from './reducers/dm-reducer'
import deliveryConfigReducer from './reducers/delivery-config-reducer'
import deliveryReducer from './reducers/delivery-reducer'
import etlStatsReducer from './reducers/etl-stats-reducer'
import groupEditReducer from './reducers/group-edit-reducer'
import taxonomyReducer from './reducers/taxonomy-reducer'
import itemAttributesReducer from './reducers/item-attributes-reducer'

import rulesReducer from './components/Rules/rulesReducers'
import CMEInspectorReducer from './components/CMEInspectorPage/cmeInspectorReducers'
import suggestionsReducer from './components/Suggestion/slices'
import feedModulesReducer from './components/DataFeeds/FeedModules/feedModulesReducers'
import supraQCToolV2Reducer from './components/SupraQCToolPage/slices'
import deliveryJobsReducers from './components/DeliveryJobsPage/DeliveryJobs/deliveryJobsReducers'
import CategoryGuidelineReducers from './components/QCSupportPage/CategoryGuideline/categoryGuidelineReducers'
import RequestsReducers from './components/QCSupportPage/Requests/requestsReducers'
import categoryTestsReducers, {
  CATEGORY_TEST_STORE_PATH
} from './components/DeliveryJobsPage/CategoryTests/categoryTestsReducers'
import metricsReducer, { METRICS_STORE_PATH } from './components/content/widgets/Metrics/metricsReducer'
import merchantFieldsReducer, { MERCHANT_FIELDS_STORE_PATH } from './components/Taxonomy/Merchants/merchantSlice'
import cmeRetrainerReducer from './components/Retrainer/slices'
import cmeDictionaryReducer from './components/CMEDictionary/slices'

import commonRtkApi from './commonRtkApi'

import niqSearchReducers from './components/NIQSearchPage/reducers/niq-reducers'

const createRootReducer = history =>
  combineReducers({
    reportFolders: reportFoldersReducer,
    dm: dmReducer,
    deliveryConfig: deliveryConfigReducer,
    delivery: deliveryReducer,
    deliveryJobs: deliveryJobsReducers,
    router: connectRouter(history),
    form: formReducer,
    modals: modalReducer,
    app: appReducer,
    session: sessionReducer,
    search: searchReducer,
    searchEdit: searchEditReducer,
    searchQC: searchQCReducer,
    supraQCTool: supraQCToolReducer,
    tasks: tasksReducer,
    etlStates: etlStatesReducer,
    feeds: feedReducer,
    feedModules: feedModulesReducer,
    jobListText: jobReducer,
    groups: groupsReducer,
    users: usersReducer,
    partners: partnerReducer,
    mailboxProviders: mailboxProviderReducer,
    merchants: merchantReducer,
    [MERCHANT_FIELDS_STORE_PATH]: merchantFieldsReducer,
    l1Categories: l1CategoryReducer,
    filter: filterReducer,
    reports: reportsReducer,
    reportEdit: reportEditReducer,
    walkthrough: walkthroughReducer,
    authlogs: authlogsReducer,
    etlStats: etlStatsReducer,
    groupEdit: groupEditReducer,
    taxonomy: taxonomyReducer,
    itemAttributes: itemAttributesReducer,
    rules: rulesReducer,
    cmeInspector: CMEInspectorReducer,
    suggestions: suggestionsReducer,
    supraQCToolV2: supraQCToolV2Reducer,
    cmeRetrainer: cmeRetrainerReducer,
    cmeDictionary: cmeDictionaryReducer,
    categoryGuideline: CategoryGuidelineReducers,
    requests: RequestsReducers,
    [CATEGORY_TEST_STORE_PATH]: categoryTestsReducers,
    [METRICS_STORE_PATH]: metricsReducer,
    [commonRtkApi.reducerPath]: commonRtkApi.reducer,
    ...niqSearchReducers
  })

export default createRootReducer
