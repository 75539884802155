// @flow
export const UPDATE_FILTER = 'UPDATE_FILTER'

export function updateFilter(searchedText: String, filterId: String): Object {
  return {
    type: UPDATE_FILTER,
    searchedText,
    filterId
  }
}
