import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const noop = () => {}

/**
 * A Generic Wrapper component which adds Drag or Drop or both capabilities,
 * around its children. This component sets up and handles most of DnD events
 * and stops propogation of original dnd events.
 * @export
 * @class DnDWrapper
 * @extends {Component}
 */
export default class DnDWrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDragged: false, // is Component currently being dragged
      isReceivingADrop: false // is Component currently receiving some drop
    }
  }

  componentDidUpdate() {
    const { draggable, droppable } = this.props
    const { isReceivingADrop, isDragged } = this.state
    if (isReceivingADrop && !droppable) {
      // is no longer droppable
      this.setState({ isReceivingADrop: false })
    }
    if (isDragged && !draggable) {
      // is no longer draggable
      this.setState({ isDragged: false })
    }
  }

  /**
   * When dragging starts for a draggable component
   * @memberof DnDWrapper
   */
  onDragStart = event => {
    event.stopPropagation()
    const { id } = this.props
    event.dataTransfer.setData('text', id)
    setTimeout(() => this.setState({ isDragged: true }), 0) // b'coz JS eventloop!
  }

  /**
   * When dragging ends for a draggable component
   * @memberof DnDWrapper
   */
  onDragEnd = event => {
    event.stopPropagation()
    this.setState({ isDragged: false })
  }

  /**
   * When current component is droppabale and starts recieving a drop
   * @memberof DnDWrapper
   */
  onDragEnter = event => {
    event.stopPropagation()
    const { isDragged } = this.state
    // you cannot drop the element on itself
    if (!isDragged) {
      this.setState({ isReceivingADrop: true })
    }
  }

  /**
   * When current component is droppabale and no longer recieving a drop
   * @memberof DnDWrapper
   */
  onDragLeave = event => {
    event.stopPropagation()
    const { dragOverTimeout } = this.state
    if (dragOverTimeout) {
      clearTimeout(dragOverTimeout)
    }
    this.setState({
      isReceivingADrop: false,
      dragOverTimeout: null
    })
  }

  /**
   * When current component is droppabale and another draggable component
   * is being dragged over it. Invokes this.props.onDragOver event handler
   * after debouncing for this.props.dragOverDelay milliseconds
   * @memberof DnDWrapper
   */
  onDragOver = event => {
    event.stopPropagation()
    const { isDragged } = this.state
    const { onDragOver, dragOverDelay } = this.props
    if (isDragged) {
      // you cannot drop the element on itself, so early return
      return
    }
    event.preventDefault()
    let { dragOverTimeout } = this.state
    if (!dragOverTimeout) {
      dragOverTimeout = setTimeout(() => {
        onDragOver(event)
      }, dragOverDelay)
    }
    this.setState({ dragOverTimeout, isReceivingADrop: true })
  }

  /**
   * When a drop is successfully received on a droppable component
   */
  onDropReceived = event => {
    const { id, onDropReceived } = this.props
    const { dragOverTimeout } = this.state
    event.preventDefault()
    event.stopPropagation()
    onDropReceived(id, event.dataTransfer.getData('text'), event)
    if (dragOverTimeout) {
      clearTimeout(dragOverTimeout)
    }
    this.setState({ isReceivingADrop: false, dragOverTimeout: null })
  }

  render() {
    const { isDragged, isReceivingADrop } = this.state
    const { draggable, droppable, className, Tag, children } = this.props
    return (
      <Tag
        className={cx(className, {
          draggable,
          droppable,
          'is-dragged': draggable && isDragged,
          'is-receiving-a-drop': droppable && isReceivingADrop
        })}
        draggable={draggable}
        onDragStart={draggable ? this.onDragStart : undefined}
        onDragEnd={draggable ? this.onDragEnd : undefined}
        onDragEnter={droppable ? this.onDragEnter : undefined}
        onDragOver={droppable ? this.onDragOver : undefined}
        onDragLeave={droppable ? this.onDragLeave : undefined}
        onDrop={droppable ? this.onDropReceived : undefined}
      >
        {children}
      </Tag>
    )
  }
}

DnDWrapper.defaultProps = {
  draggable: false,
  droppable: false,
  dragOverDelay: 0,
  onDragOver: noop,
  onDropReceived: noop,
  Tag: 'div'
}

DnDWrapper.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  Tag: PropTypes.string.isRequired,
  className: PropTypes.string,
  draggable: PropTypes.bool,
  droppable: PropTypes.bool,
  onDragOver: PropTypes.func,
  onDropReceived: PropTypes.func,
  dragOverDelay: PropTypes.number,
  children: PropTypes.any
}
