// Inspired by https://connoratherton.com/loaders
// Inspired by https://github.com/jonjaques/react-loaders
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './loader.scss'

const Loader = ({ height, overlap }) => {
  return (
    <div className="slice-loader-wrapper">
      <div
        style={{ height: height }}
        className={cx('slice-loader-container', {
          overlapping: overlap
        })}
      >
        <div className="slice-loader">
          <div className="slice-loader-ball-pulse">
            <div className="slice-bg-teal" />
            <div className="slice-bg-red" />
            <div className="slice-bg-yellow" />
          </div>
        </div>
      </div>
    </div>
  )
}

Loader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // whether the loader should be an overlapping (absolute) component or not.
  overlap: PropTypes.bool
}

Loader.defaultProps = {
  height: 200,
  overlap: false
}

export default Loader
