// https://sentry.io
import config from './config'
import { noop, get } from 'lodash'
import * as Sentry from '@sentry/browser'

const IGNORE_ERRORS = ['invalid_user_password', 'invalid username']

// TODO: always initialize sentry. Use .enabled check pass correct/falsy value of DSN

const shouldLogError = event => {
  const error = get(event, 'exception.values[0].value', '')
  if (error && IGNORE_ERRORS.includes(error)) {
    return false
  }
  return true
}

const initializeSentry = () => {
  if (config.log && config.log.sentry && config.log.sentry.enabled) {
    console.log('Sentry is enabled')
    Sentry.init({
      dsn: config.log.sentry.dsn,
      environment: process.env.REACT_APP_DEPLOY_ENV,
      release: process.env.REACT_APP_VERSION,
      maxBreadcrumbs: 50,
      whitelistUrls: [config.appBaseUrl, config.serverBaseUrl, config.periscopeBaseUrl],
      beforeSend(event) {
        if (shouldLogError(event)) {
          return event
        }
        return null
      }
    })

    // Global sentry handlers
    window.captureException = (err, context) => {
      if (context) {
        Sentry.withScope(scope => {
          scope.setExtra('context', context)
          Sentry.captureException(err)
        })
      } else {
        Sentry.withScope(scope => {
          Sentry.captureException(err)
        })
      }
    }
    window.captureBreadcrumb = breadcrumb => {
      Sentry.addBreadcrumb(breadcrumb)
    }
    window.captureMessage = (message, options) => {
      Sentry.withScope(scope => {
        Object.keys(options).forEach(option => {
          scope.setExtra(option, options[option])
        })
        Sentry.captureMessage(message)
      })
    }
    window.setUserContext = userPayload => {
      Sentry.configureScope(scope => {
        scope.setUser(userPayload)
      })
    }
  } else {
    console.log('Sentry is disabled')
    window.captureException = console.error
    window.captureBreadcrumb = noop
    window.captureMessage = noop
    window.setUserContext = noop
  }
}
initializeSentry()
