/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { Col, ControlLabel, FormGroup, Radio } from 'react-bootstrap'

export default class FolderSelectPanel extends Component {
  constructor(props) {
    super(props)
    this.state = { selectedApiKey: props.apiKey || 'ri' }
  }

  handleChange = e => {
    this.setState({ selectedApiKey: e.target.id })
    this.props.updateFormValue('apiKey', e.target.id)
  }

  isChecked = id => id === (this.props.apiKey || 'ri')

  render() {
    return (
      <FormGroup controlId="apiKey">
        <Col sm={2}>
          <ControlLabel className="required"> {'API Key '}</ControlLabel>
        </Col>
        <Col sm={10}>
          <FormGroup style={{ paddingLeft: '1em' }}>
            <Radio
              name="apiKeyRadioButtonGroup"
              id="ri"
              inline
              checked={this.isChecked('ri')}
              onChange={this.handleChange}
            >
              Slice Intelligence Key
            </Radio>

            <Radio
              name="apiKeyRadioButtonGroup"
              id="nielsen"
              inline
              checked={this.isChecked('nielsen')}
              onChange={this.handleChange}
            >
              Nielsen Key
            </Radio>
          </FormGroup>
        </Col>
      </FormGroup>
    )
  }
}
