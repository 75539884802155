import _ from 'lodash'
import { takeLatest, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'

import {
  FETCH_SELECTED_MERCHANTS,
  FETCH_AUTOCOMPLETE_MERCHANTS,
  fetchMerchantsSuccess,
  fetchMerchantsFailed
} from '../actions/merchant-actions'

import { updateSession } from '../actions/app-actions'

function* fetchMerchantsFlow() {
  yield takeLatest(FETCH_SELECTED_MERCHANTS, fetchSelectedMerchants)
}

function* fetchAutocompleteMerchantsFlow() {
  yield takeLatest(FETCH_AUTOCOMPLETE_MERCHANTS, fetchMerchantsAutocomplete)
}

function getFormatedMerchants(merchants) {
  return merchants.map(m => ({
    id: String(m.id),
    name: m.quality_merchant_id ? `(Q) ${m.name}` : m.name
  }))
}

function* fetchSelectedMerchants(action) {
  try {
    const response = yield call(() =>
      axios.get(`/api/admin/idf-dimension/merchants-by-id?ids=${action.filteredIds.join(',')}`)
    )
    yield put(fetchMerchantsSuccess(_.keyBy(getFormatedMerchants(response.data), 'id')))
  } catch (err) {
    window.captureException(err)
    yield put(fetchMerchantsFailed(err))
  }
}

function* fetchMerchantsAutocomplete(action) {
  try {
    const response = yield call(() => axios.get(`/api/admin/idf-dimension/merchants?name=${action.searchName}`))
    yield put(
      updateSession({
        autocompleteMerchants: getFormatedMerchants(response.data)
      })
    )
  } catch (err) {
    window.captureException(err)
  }
}

export default [fork(fetchMerchantsFlow), fork(fetchAutocompleteMerchantsFlow)]
