import { combineReducers } from 'redux'
import { createReducer } from '@reduxjs/toolkit'

import {
  RuleModalActions,
  CategoryBrandRulesActions,
  MerchantCategoryRulesActions,
  MerchantBrandRulesActions,
  MerchantCategoryRegexRulesActions,
  MerchantBrandRegexRulesActions,
  BrandSynonymRulesActions,
  CategoryBrandPathRulesActions,
  DeployStatsActions
} from './actions'

import { STORE_PATH } from './constants'

import { DATA_TABLE } from './../DataTable/constants'

// ********** TABLE REDUCERS ****************//
const initialState = {
  loading: false,
  page: 1,
  pageSize: DATA_TABLE.PAGE_SIZE,
  sortBy: 'id',
  sortOrder: 'asc',
  filters: {},
  data: {
    count: 0,
    rows: []
  }
}

const generalReducers = {
  updateSettings: (state, action) => {
    return {
      ...state,
      ...action.payload
    }
  },
  clearFilters: (state, action) => {
    state.filters = {}
    // also clear sorting
    state.sortBy = initialState.sortBy
    state.sortOrder = initialState.sortOrder
  },
  fetchStart: (state, action) => {
    state.loading = true
  },
  successLoad: (state, action) => {
    state.data.count = action.payload.count
    state.data.rows = action.payload.rows
    state.loading = false
  },
  failedLoad: state => {
    state.loading = false
  }
}

const categoryBrandReducers = createReducer(initialState, builder => {
  builder
    .addCase(CategoryBrandRulesActions.fetch.type, generalReducers.fetchStart)
    .addCase(CategoryBrandRulesActions.successLoad.type, generalReducers.successLoad)
    .addCase(CategoryBrandRulesActions.failedLoad.type, generalReducers.failedLoad)
    .addCase(CategoryBrandRulesActions.updateSettings.type, generalReducers.updateSettings)
    .addCase(CategoryBrandRulesActions.clearFilters.type, generalReducers.clearFilters)
})

const merchantCategoryReducers = createReducer(initialState, builder => {
  builder
    .addCase(MerchantCategoryRulesActions.fetch.type, generalReducers.fetchStart)
    .addCase(MerchantCategoryRulesActions.successLoad.type, generalReducers.successLoad)
    .addCase(MerchantCategoryRulesActions.failedLoad.type, generalReducers.failedLoad)
    .addCase(MerchantCategoryRulesActions.updateSettings.type, generalReducers.updateSettings)
    .addCase(MerchantCategoryRulesActions.clearFilters.type, generalReducers.clearFilters)
})

const merchantBrandReducers = createReducer(initialState, builder => {
  builder
    .addCase(MerchantBrandRulesActions.fetch.type, generalReducers.fetchStart)
    .addCase(MerchantBrandRulesActions.successLoad.type, generalReducers.successLoad)
    .addCase(MerchantBrandRulesActions.failedLoad.type, generalReducers.failedLoad)
    .addCase(MerchantBrandRulesActions.updateSettings.type, generalReducers.updateSettings)
    .addCase(MerchantBrandRulesActions.clearFilters.type, generalReducers.clearFilters)
})

const merchantCategoryRegexReducers = createReducer(initialState, builder => {
  builder
    .addCase(MerchantCategoryRegexRulesActions.fetch.type, generalReducers.fetchStart)
    .addCase(MerchantCategoryRegexRulesActions.successLoad.type, generalReducers.successLoad)
    .addCase(MerchantCategoryRegexRulesActions.failedLoad.type, generalReducers.failedLoad)
    .addCase(MerchantCategoryRegexRulesActions.updateSettings.type, generalReducers.updateSettings)
    .addCase(MerchantCategoryRegexRulesActions.clearFilters.type, generalReducers.clearFilters)
})

const merchantBrandRegexReducers = createReducer(initialState, builder => {
  builder
    .addCase(MerchantBrandRegexRulesActions.fetch.type, generalReducers.fetchStart)
    .addCase(MerchantBrandRegexRulesActions.successLoad.type, generalReducers.successLoad)
    .addCase(MerchantBrandRegexRulesActions.failedLoad.type, generalReducers.failedLoad)
    .addCase(MerchantBrandRegexRulesActions.updateSettings.type, generalReducers.updateSettings)
    .addCase(MerchantBrandRegexRulesActions.clearFilters.type, generalReducers.clearFilters)
})

const brandSynonymReducers = createReducer(initialState, builder => {
  builder
    .addCase(BrandSynonymRulesActions.fetch.type, generalReducers.fetchStart)
    .addCase(BrandSynonymRulesActions.successLoad.type, generalReducers.successLoad)
    .addCase(BrandSynonymRulesActions.failedLoad.type, generalReducers.failedLoad)
    .addCase(BrandSynonymRulesActions.updateSettings.type, generalReducers.updateSettings)
    .addCase(BrandSynonymRulesActions.clearFilters.type, generalReducers.clearFilters)
})

const categoryBrandPathReducers = createReducer(initialState, builder => {
  builder
    .addCase(CategoryBrandPathRulesActions.fetch.type, generalReducers.fetchStart)
    .addCase(CategoryBrandPathRulesActions.successLoad.type, generalReducers.successLoad)
    .addCase(CategoryBrandPathRulesActions.failedLoad.type, generalReducers.failedLoad)
    .addCase(CategoryBrandPathRulesActions.updateSettings.type, generalReducers.updateSettings)
    .addCase(CategoryBrandPathRulesActions.clearFilters.type, generalReducers.clearFilter)
})

// ********** MODAL REDUCERS ****************//

const generalModalReducers = {
  fetchOneRule: (state, action) => {
    state.ruleLoading = true
    state.rule = null
  },
  successFetchOneRule: (state, action) => {
    state.ruleLoading = false
    const value = action.payload.rows && action.payload.rows[0] ? action.payload.rows[0] : null
    state.rule = value
  }
}
const modalReducers = createReducer(
  {
    ruleLoading: false,
    rule: null
  },
  builder => {
    builder
      // general
      .addCase(RuleModalActions.startRuleLoading.type, generalModalReducers.fetchOneRule)
      .addCase(RuleModalActions.clear, (state, action) => {
        state.ruleLoading = false
        state.rule = null
      })

      // merchant-category
      .addCase(MerchantCategoryRulesActions.fetchByMerchantAndCategory.type, generalModalReducers.fetchOneRule)
      .addCase(
        MerchantCategoryRulesActions.successLoadByMerchantAndCategory.type,
        generalModalReducers.successFetchOneRule
      )

      // merchant-brand
      .addCase(MerchantBrandRulesActions.fetchByMerchantAndBrand.type, generalModalReducers.fetchOneRule)
      .addCase(MerchantBrandRulesActions.successLoadByMerchantAndBrand.type, generalModalReducers.successFetchOneRule)

      // merchant-category-regex
      .addCase(MerchantCategoryRegexRulesActions.fetchRuleByMainFields.type, generalModalReducers.fetchOneRule)
      .addCase(MerchantCategoryRegexRulesActions.successLoadByMainFields.type, generalModalReducers.successFetchOneRule)

      // merchant-brand-regex
      .addCase(MerchantBrandRegexRulesActions.fetchRuleByMainFields.type, generalModalReducers.fetchOneRule)
      .addCase(MerchantBrandRegexRulesActions.successLoadByMainFields.type, generalModalReducers.successFetchOneRule)

      // brand-synonym
      .addCase(BrandSynonymRulesActions.fetchRuleByMainFields.type, generalModalReducers.fetchOneRule)
      .addCase(BrandSynonymRulesActions.successLoadByMainFields.type, generalModalReducers.successFetchOneRule)
  }
)

// ********** DEPLOY STATS REDUCERS ****************//
const deployStatsReducers = createReducer(
  {
    staging: {},
    production: {}
  },
  builder => {
    builder.addCase(DeployStatsActions.successLoad.type, (state, action) => {
      state = { ...state, ...action.payload.deployStats }
      return state
    })
  }
)

export default combineReducers({
  modal: modalReducers,
  deployStats: deployStatsReducers,
  [STORE_PATH.categoryBrand]: categoryBrandReducers,
  [STORE_PATH.merchantCategory]: merchantCategoryReducers,
  [STORE_PATH.merchantBrand]: merchantBrandReducers,
  [STORE_PATH.merchantCategoryRegex]: merchantCategoryRegexReducers,
  [STORE_PATH.merchantBrandRegex]: merchantBrandRegexReducers,
  [STORE_PATH.brandSynonym]: brandSynonymReducers,
  [STORE_PATH.categoryBrandPath]: categoryBrandPathReducers
})
