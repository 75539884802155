// @flow
import { cloneDeep, findIndex, find } from 'lodash'
import { findRule, findParent } from '../utils/portal-query-builder'
import { supraQCToolTabs } from '../constants/constants'
import {
  SUPRA_QC_QUERY_TREE_CHANGED,
  SUPRA_QC_CREATE_MULTIPLE_RULES,
  SUPRA_QC_ADD_TO_DICT_RESULTS_FETCHED,
  SUPRA_QC_ADD_TO_DICT_MODAL_CLOSED,
  SUPRA_QC_ADD_TO_DICT_MODAL_OPEN,
  SUPRA_QC_DICTIONARY_LABEL_CHANGED,
  SUPRA_QC_ADD_TO_DICT_REGEX_CHANGED,
  SUPRA_QC_ADD_TO_DICT_FROM_ADDR_REGEX_CHANGED,
  SUPRA_QC_USE_IN_TRAINING_CHANGED,
  SUPRA_QC_IS_DELETED_CHANGED,
  SUPRA_QC_ADD_TO_DICT_SUCCEEDED,
  SUPRA_QC_ADD_TO_DICT_QUERY_STARTED,
  SUPRA_QC_ADD_TO_DICT_QUERY_FAILED,
  SUPRA_QC_ADD_ENTRY_TO_DICT,
  SUPRA_QC_ADD_TO_DICT_FAILED,
  SUPRA_QC_FETCH_DICT_ENTRIES_STARTED,
  SUPRA_QC_FETCH_DICT_ENTRIES_SUCCEEDED,
  SUPRA_QC_FETCH_DICT_ENTRIES_FAILED,
  SUPRA_QC_SET_ACTIVE_TAB,
  SUPRA_QC_CLEAR_QUERY_TREE,
  SUPRA_QC_INPUT_GROUP_CHANGE,
  SUPRA_QC_FETCH_SAVED_GROUP_SUCCEEDED,
  SUPRA_QC_GROUP_SELECT,
  SUPRA_QC_CREATE_GROUP_SUCCEEDED,
  SUPRA_QC_FETCH_QUERIES_BY_GROUP_SUCCEEDED,
  SUPRA_QC_INPUT_QUERY_CHANGE,
  SUPRA_QC_CLEAR_COMPLETE_QUERY,
  SUPRA_QC_SAVE_SEARCH_QUERY_SUCCEEDED,
  SUPRA_QC_DELETE_QUERY_SUCCEEDED,
  SUPRA_QC_UPDATE_QUERY_VALUE,
  SUPRA_QC_UPDATE_GROUP_VALUE,
  SUPRA_QC_QUERY_SELECT,
  SUPRA_QC_GET_PREVIOUS_QUERY,
  SUPRA_QC_GET_NEXT_QUERY,
  SUPRA_QC_UPDATE_QUERY_HEADER_EDIT_STATUS,
  SUPRA_QC_ADD_TO_DICT_TEMPLATE_CHANGED
} from '../actions/supra-qc-tool-actions'

import { convertTemplateToRegex } from 'components/SupraQCToolPage/Modals/useTemplateRegex'

const { SAMPLING_SESSIONS } = supraQCToolTabs

const defaultQueryTree = {
  combinator: 'and',
  id: 'g-default',
  rules: [
    {
      field: 'template',
      id: 'r-default',
      operator: 'regex',
      value: ''
    }
  ]
}

const defaultSupraQCToolGroup = {
  list: [],
  selectedValue: '',
  selectedID: null,
  inputValue: '',
  updatedValue: ''
}
const defaultSupraQCToolQuery = {
  list: [],
  selectedValue: '',
  selectedID: null,
  inputValue: '',
  updatedValue: ''
}

// initialState
const initialSidebarState = {
  seq: 2,
  errorMessage: 'A',
  searchValue: '',
  searchSuggestions: [],
  defaultQueryTree: cloneDeep(defaultQueryTree),
  queryTree: cloneDeep(defaultQueryTree),
  addToDictResult: {
    loading: false
  },
  addToDictModal: {
    show: false,
    selectedRow: {},
    regex: '',
    from_addr_regex: '',
    dictionaryLabel: '',
    useInTraining: false,
    isDeleted: false,
    isRegex: true
  },
  dictionaryTable: {
    rows: [],
    total: 0,
    page: 1,
    loading: false
  },
  queryHeader: {
    currentIndex: -1,
    group: cloneDeep(defaultSupraQCToolGroup),
    query: cloneDeep(defaultSupraQCToolQuery)
  },
  isQueryHeaderEdited: false,
  isActiveTab: SAMPLING_SESSIONS,
  querySQLLoading: false,
  querySQL: ''
}

const supraQCToolReducer = (state = initialSidebarState, action) => {
  // let group
  let query
  let selectedQuery
  let currentIndex
  let enabledQueries

  switch (action.type) {
    case SUPRA_QC_QUERY_TREE_CHANGED:
      return {
        ...state,
        queryTree: action.queryTree
      }
    case SUPRA_QC_CREATE_MULTIPLE_RULES: {
      const rule = findRule(action.ruleId, state.queryTree)
      const newRules = action.values.map((value, index) => ({
        ...rule,
        id: `${rule.id}-${index}`,
        value
      }))
      const parent = findParent(rule.id, state.queryTree)
      if (action.event === 'addInCurrentGroup') {
        const index = findIndex(parent.rules, { id: rule.id })
        // replace the current rule with new rules
        parent.rules.splice(index, 1, ...newRules)
      } else if (action.event === 'addNewRuleGroup') {
        // convert current rule to a ruleGroup
        // with newRules as its childRules
        rule.id = `g-${rule.id}`
        // TODO: dynamically make combinator 'or' or 'and'
        rule.combinator = 'or'
        rule.ruleGroupLabel = action.ruleGroupLabel
        rule.rules = newRules
        rule.isMinimized = true
        rule.value = null
      }
      return {
        ...state,
        searchValue: '',
        searchSuggestions: [],
        /**
         * since the rule in queryTree which was modified, could be at any level,
         * we need to deep clone the entire tree
         */
        queryTree: cloneDeep(state.queryTree)
      }
    }

    case SUPRA_QC_ADD_TO_DICT_QUERY_FAILED:
      return {
        ...state,
        addToDictResult: {
          ...state.addToDictResult,
          loading: false
        }
      }

    case SUPRA_QC_ADD_TO_DICT_RESULTS_FETCHED:
      return {
        ...state,
        addToDictResult: {
          rows: action.rows,
          total: action.total,
          existingRules: action.existingRules
        }
      }

    case SUPRA_QC_ADD_TO_DICT_MODAL_OPEN:
      return {
        ...state,
        addToDictModal: {
          ...state.addToDictModal,
          show: true,
          selectedRow: action.selectedRow,
          source: action.source,
          regex: action.selectedRow.subject,
          template: action.selectedRow.modded_template || '',
          from_addr_regex: action.selectedRow.from_addr,
          dictionaryLabel: action.selectedRow.type.toUpperCase(),
          useInTraining: Boolean(action.selectedRow.use_in_training),
          isDeleted: Boolean(action.selectedRow.is_deleted),
          isRegex: Boolean(action.selectedRow.is_regex)
        }
      }
    case SUPRA_QC_DICTIONARY_LABEL_CHANGED:
      return {
        ...state,
        addToDictModal: {
          ...state.addToDictModal,
          dictionaryLabel: action.dictionaryLabel
        }
      }
    case SUPRA_QC_ADD_TO_DICT_REGEX_CHANGED:
      return {
        ...state,
        addToDictModal: {
          ...state.addToDictModal,
          regex: action.regex
        }
      }

    case SUPRA_QC_ADD_TO_DICT_TEMPLATE_CHANGED:
      return {
        ...state,
        addToDictModal: {
          ...state.addToDictModal,
          template: action.template,
          regex: convertTemplateToRegex(action.template)
        }
      }

    case SUPRA_QC_ADD_TO_DICT_FROM_ADDR_REGEX_CHANGED:
      return {
        ...state,
        addToDictModal: {
          ...state.addToDictModal,
          from_addr_regex: action.from_addr_regex
        }
      }

    case SUPRA_QC_USE_IN_TRAINING_CHANGED:
      return {
        ...state,
        addToDictModal: {
          ...state.addToDictModal,
          useInTraining: action.useInTraining
        }
      }

    case SUPRA_QC_IS_DELETED_CHANGED:
      return {
        ...state,
        addToDictModal: {
          ...state.addToDictModal,
          isDeleted: action.isDeleted
        }
      }

    case SUPRA_QC_ADD_TO_DICT_SUCCEEDED:
      return {
        ...state,
        addToDictResult: {},
        addToDictModal: {
          ...state.addToDictModal,
          show: false,
          selectedRow: {},
          regex: '',
          from_addr_regex: '',
          dictionaryLabel: '',
          useInTraining: false,
          isRegex: true,
          loading: false
        }
      }

    case SUPRA_QC_ADD_TO_DICT_QUERY_STARTED:
      return {
        ...state,
        addToDictResult: {
          ...state.addToDictResult,
          loading: true
        }
      }

    case SUPRA_QC_ADD_TO_DICT_MODAL_CLOSED:
      return {
        ...state,
        addToDictResult: {
          loading: false
        },
        addToDictModal: {
          ...state.addToDictModal,
          show: false,
          selectedRow: {},
          regex: '',
          from_addr_regex: '',
          dictionaryLabel: '',
          useInTraining: false,
          isRegex: true,
          loading: false
        }
      }

    case SUPRA_QC_ADD_TO_DICT_FAILED:
      return {
        ...state,
        addToDictModal: {
          ...state.addToDictModal,
          loading: false
        }
      }

    case SUPRA_QC_ADD_ENTRY_TO_DICT:
      return {
        ...state,
        addToDictModal: {
          ...state.addToDictModal,
          loading: true
        }
      }

    case SUPRA_QC_FETCH_DICT_ENTRIES_STARTED:
      return {
        ...state,
        dictionaryTable: {
          ...state.dictionaryTable,
          loading: true
        }
      }

    case SUPRA_QC_FETCH_DICT_ENTRIES_SUCCEEDED:
      return {
        ...state,
        dictionaryTable: {
          rows: action.rows,
          total: action.total,
          lastDictionarySync: action.lastDictionarySync,
          loading: false
        }
      }

    case SUPRA_QC_FETCH_DICT_ENTRIES_FAILED:
      return {
        ...state,
        dictionaryTable: {
          ...state.dictionaryTable,
          loading: false
        }
      }

    case SUPRA_QC_SET_ACTIVE_TAB:
      return {
        ...state,
        isActiveTab: action.payload
      }

    case SUPRA_QC_CLEAR_QUERY_TREE:
      return {
        ...state,
        queryTree: cloneDeep(defaultQueryTree)
      }

    case SUPRA_QC_INPUT_GROUP_CHANGE:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            inputValue: action.inputValue
          }
        }
      }

    case SUPRA_QC_GROUP_SELECT:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            selectedID: action.selectedGroup[0].id,
            selectedValue: action.selectedGroup[0].label,
            updatedValue: action.selectedGroup[0].label
          },
          currentIndex: -1,
          query: cloneDeep(defaultSupraQCToolGroup)
        }
      }

    case SUPRA_QC_CREATE_GROUP_SUCCEEDED:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            selectedID: action.data.id,
            selectedValue: action.data.name,
            updatedValue: action.data.name,
            inputValue: action.data.name
          }
        }
      }

    case SUPRA_QC_FETCH_QUERIES_BY_GROUP_SUCCEEDED:
      currentIndex = findIndex(action.data, {
        id: state.queryHeader.query.selectedID
      })

      query = state.queryHeader.query
      if (!action.clear) {
        currentIndex = -1
        query = {
          selectedValue: '',
          selectedID: null,
          inputValue: '',
          updatedValue: ''
        }
      }
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex,
          query: {
            ...query,
            list: action.data
          }
        }
      }

    case SUPRA_QC_FETCH_SAVED_GROUP_SUCCEEDED:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            list: action.data
          }
        }
      }

    case SUPRA_QC_INPUT_QUERY_CHANGE:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          query: {
            ...state.queryHeader.query,
            inputValue: action.inputValue
          }
        }
      }

    case SUPRA_QC_CLEAR_COMPLETE_QUERY:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex: -1,
          query: cloneDeep(defaultSupraQCToolQuery),
          group: {
            ...state.queryHeader.group,
            selectedID: null,
            selectedValue: '',
            updatedValue: '',
            inputValue: ''
          }
        },
        queryTree: cloneDeep(defaultQueryTree)
      }

    case SUPRA_QC_SAVE_SEARCH_QUERY_SUCCEEDED:
      const groupList = state.queryHeader.group.list
      const groupIndex = findIndex(groupList, { id: action.data.group.id })
      const queryList = state.queryHeader.query.list
      const queryIndex = findIndex(queryList, { id: action.data.query.id })

      if (groupIndex >= 0) {
        groupList[groupIndex].name = action.data.group.name || ''
      }

      if (queryIndex >= 0) {
        queryList[queryIndex].name = action.data.query.name || ''
        queryList[queryIndex].query = JSON.stringify(action.data.queryTree) || cloneDeep(defaultQueryTree)
      }

      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            selectedValue: action.data.group.name,
            updatedValue: action.data.group.name,
            selectedID: action.data.group.id,
            inputValue: action.data.group.name,
            list: groupList
          },
          query: {
            ...state.queryHeader.query,
            selectedValue: action.data.query.name,
            updatedValue: action.data.query.name,
            selectedID: action.data.query.id,
            inputValue: action.data.query.name,
            list: queryList
          }
        },
        isQueryHeaderEdited: false
      }

    case SUPRA_QC_DELETE_QUERY_SUCCEEDED:
      query = state.queryHeader.query
      currentIndex = state.queryHeader.currentIndex
      enabledQueries = query.list.filter(data => !data.isDisable && data.id !== query.selectedID)
      if (enabledQueries.length === 0) {
        return {
          ...state,
          queryHeader: {
            ...state.queryHeader,
            currentIndex: -1,
            query: cloneDeep(defaultSupraQCToolQuery)
          },
          queryTree: cloneDeep(defaultQueryTree)
        }
      }
      let updatedIndex = currentIndex
      for (let i = currentIndex + 1; i < query.list.length; i++) {
        if (!query.list[i].isDisable) {
          updatedIndex = i
          break
        }
      }
      if (updatedIndex === currentIndex) {
        for (let i = currentIndex - 1; i >= 0; i--) {
          if (!query.list[i].isDisable) {
            updatedIndex = i
            break
          }
        }
      }
      const nextQuery = query.list[updatedIndex]
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex: updatedIndex,
          query: {
            ...state.queryHeader.query,
            selectedValue: nextQuery ? nextQuery.name : '',
            updatedValue: nextQuery ? nextQuery.name : '',
            inputValue: nextQuery ? nextQuery.name : '',
            selectedID: nextQuery ? nextQuery.id : null
          }
        },
        queryTree: nextQuery ? JSON.parse(nextQuery.query) : cloneDeep(defaultQueryTree)
      }

    case SUPRA_QC_UPDATE_QUERY_VALUE:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          query: {
            ...state.queryHeader.query,
            updatedValue: action.value
          }
        }
      }

    case SUPRA_QC_UPDATE_GROUP_VALUE:
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          group: {
            ...state.queryHeader.group,
            updatedValue: action.value
          }
        }
      }

    case SUPRA_QC_QUERY_SELECT:
      query = state.queryHeader.query
      selectedQuery = action.selectedQuery[0]
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex: findIndex(query.list, {
            name: selectedQuery.label
          }),
          query: {
            ...state.queryHeader.query,
            selectedID: selectedQuery.id,
            selectedValue: selectedQuery.label,
            updatedValue: selectedQuery.label
          }
        },
        queryTree: JSON.parse(find(query.list, { id: selectedQuery.id }).query)
      }

    case SUPRA_QC_GET_PREVIOUS_QUERY:
      currentIndex = state.queryHeader.currentIndex
      query = state.queryHeader.query
      let previousIndex = currentIndex
      for (let i = currentIndex - 1; i >= 0; i--) {
        if (!query.list[i].isDisable) {
          previousIndex = i
          break
        }
      }
      if (currentIndex === previousIndex) {
        return state
      }

      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex: previousIndex,
          query: {
            ...state.queryHeader.query,
            selectedID: query.list[previousIndex].id,
            selectedValue: query.list[previousIndex].name,
            updatedValue: query.list[previousIndex].name,
            inputValue: query.list[previousIndex].name
          }
        },
        queryTree: JSON.parse(find(query.list, { id: query.list[previousIndex].id }).query)
      }

    case SUPRA_QC_GET_NEXT_QUERY:
      currentIndex = state.queryHeader.currentIndex
      query = state.queryHeader.query
      let nextIndex = currentIndex
      for (let i = currentIndex + 1; i < query.list.length; i++) {
        if (!query.list[i].isDisable) {
          nextIndex = i
          break
        }
      }
      if (currentIndex === nextIndex) {
        return state
      }
      return {
        ...state,
        queryHeader: {
          ...state.queryHeader,
          currentIndex: nextIndex,
          query: {
            ...state.queryHeader.query,
            selectedID: query.list[nextIndex].id,
            selectedValue: query.list[nextIndex].name,
            updatedValue: query.list[nextIndex].name,
            inputValue: query.list[nextIndex].name
          }
        },
        queryTree: JSON.parse(find(query.list, { id: query.list[nextIndex].id }).query)
      }

    case SUPRA_QC_UPDATE_QUERY_HEADER_EDIT_STATUS:
      return {
        ...state,
        isQueryHeaderEdited: action.status
      }

    default:
      return state
  }
}

export default supraQCToolReducer
