import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Box from '../Box'
import Input from '../Input'
import SearchIcon from '../Icon/SearchIcon'
import { InfiniteLoader, Table, Column, AutoSizer } from 'react-virtualized'
import { FormControl, Row, Col } from 'react-bootstrap'
import CheckBox from '../CheckBox'
import { debounce } from 'lodash'
import 'react-virtualized/styles.css'

/**
 * Generic React Virtualized Table with infinite scroll and server side search functionality with one or more entities
 * @export
 * @class RVTable
 * @extends {Component}
 * @example ./RVTable.md
 * @see See [ReactVirtualizedTable](https://github.com/bvaughn/react-virtualized) for more info
 */

export default class RVTable extends Component {
  constructor(props) {
    super(props)

    this.isRowLoaded = this.isRowLoaded.bind(this)
    this.loadMoreRows = this.loadMoreRows.bind(this)
    this.debounceSearch = this.debounceSearch.bind(this)
    this.updateSearchEntity = this.updateSearchEntity.bind(this)
    this.updateSearchWithDebounce = this.updateSearchWithDebounce.bind(this)
  }

  componentDidMount() {
    // If search is enabled and a query is part of the url, fetch data
    if (this.props.enableSearch && this.props.query && this.props.query.search) {
      this.updateSearchWithDebounce(this.props.query.search)
    }
  }

  debounceSearch = debounce(search => {
    this.props.fetchData(this.props.defaultOffset, this.props.fetchCount, search)
  }, this.props.debounceWaitTime)

  updateSearchWithDebounce(search) {
    this.props.updateFilter(search)
    this.debounceSearch(search)
  }

  updateSearchEntity(event) {
    this.props.onSearchEntityChanged(event.target.value)
    if (this.props.search) {
      this.updateSearchWithDebounce(this.props.search)
    }
  }

  isRowLoaded({ index }) {
    return !!this.props.data[index]
  }

  loadMoreRows({ startIndex, stopIndex }) {
    this.props.fetchData(startIndex, stopIndex - startIndex + 1, this.props.search)
  }

  onCheckedValueChange(val, key) {
    this.props.onCheckedValueChange(val, key)
  }

  renderOptions() {
    const searchOptionKeys = Object.keys(this.props.searchEntities)
    return searchOptionKeys.map(searchOption => {
      const display = this.props.searchEntities[searchOption].display

      return (
        <option key={searchOption} value={searchOption}>
          {display}
        </option>
      )
    })
  }

  sort = ({ sortBy, sortDirection }) => {
    this.props.sort(sortBy, sortDirection)
  }

  buildColumns = columns => {
    return columns.map(column => (
      <Column
        key={column.key || `column-${column.label}`}
        label={column.label}
        width={column.width || this.props.columnWidth}
        flexGrow={column.flexGrow === undefined ? 1 : column.flexGrow}
        flexShrink={column.flexShrink === undefined ? 1 : column.flexShrink}
        className={column.className}
        style={column.style || {}}
        dataKey={column.key}
        headerStyle={column.headerStyle}
        disableSort={[true, false].includes(column.disableSort) ? column.disableSort : true}
        headerRenderer={headerData => {
          if (column.headerRenderer) {
            return column.headerRenderer(headerData)
          }
          return column.label
        }}
        cellRenderer={({ rowData }) => {
          if (column.cellRenderer) {
            return column.cellRenderer(rowData)
          }

          return (
            <div className={'table-cell'}>
              {column.type === 'checkbox' ? (
                <div style={{ paddingTop: '5px' }}>
                  <CheckBox
                    className="checkboxInline"
                    valueDidChange={this.onCheckedValueChange.bind(this, rowData.key)}
                    checked={rowData[column.key]}
                  />
                </div>
              ) : (
                rowData[column.key]
              )}
            </div>
          )
        }}
      />
    ))
  }

  render() {
    const {
      selectedSearchEntity = '',
      searchEntities,
      enableSearch,
      searchPlaceHolder,
      search,
      height,
      rowHeight,
      headerHeight,
      remoteRowCount,
      minimumBatchSize,
      data,
      columns,
      sortBy,
      sortDirection
    } = this.props
    const searchOptionCount = Object.keys(searchEntities).length

    return (
      <div style={{ marginTop: '0px' }} className="RVT_Container">
        {enableSearch && (
          <div className="RVT__searchContainer">
            <div className="RVT__search">
              <Box className="flex alignItemsCenter">
                <div style={{ width: '100%' }}>
                  <Row>
                    {searchOptionCount > 1 && (
                      <Col md={2}>
                        <FormControl
                          componentClass="select"
                          placeholder="Search Field"
                          onChange={this.updateSearchEntity}
                          value={selectedSearchEntity}
                        >
                          {this.renderOptions()}
                        </FormControl>
                      </Col>
                    )}

                    <Col md={10}>
                      <Input
                        clearButton
                        placeholder={searchPlaceHolder || 'Search'}
                        className="CheckBoxMultiSelect input flex flex1"
                        value={search}
                        iconName={<SearchIcon width={24} height={24} />}
                        didClickClearButton={() => this.updateSearchWithDebounce('')}
                        textDidChange={this.updateSearchWithDebounce}
                      />
                    </Col>
                  </Row>
                </div>
              </Box>
            </div>
          </div>
        )}

        <div className={'top-spacing'} />

        <div style={{ minHeight: height }}>
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            rowCount={remoteRowCount}
            minimumBatchSize={minimumBatchSize}
          >
            {({ onRowsRendered }) => (
              <AutoSizer>
                {({ width }) => (
                  <Table
                    width={width}
                    height={height}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    onRowsRendered={onRowsRendered}
                    rowCount={data.length}
                    rowGetter={({ index }) => data[index]}
                    sort={this.sort}
                    sortBy={sortBy}
                    sortDirection={sortDirection && sortDirection.toUpperCase()}
                  >
                    {this.buildColumns(columns)}
                  </Table>
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
      </div>
    )
  }
}

RVTable.propTypes = {
  /** An array of column objects with title and other information */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      flexGrow: PropTypes.number,
      flexShrink: PropTypes.number,
      width: PropTypes.number,
      style: PropTypes.object,
      cellRenderer: PropTypes.func,
      headerRenderer: PropTypes.func,
      headerStyle: PropTypes.object,
      disableSort: PropTypes.bool
    })
  ),
  /** Row data with properties that match column.key */
  data: PropTypes.array,
  /** Function to execute on every infinite scroll fetch data action */
  fetchData: PropTypes.func,
  /** Function to execute when the search box text is changed (if search is enabled) */
  updateFilter: PropTypes.func,
  /** The number of remote rows */
  remoteRowCount: PropTypes.number,
  /** The function to execute on checkbox change if a column is of type checkbox */
  onCheckedValueChange: PropTypes.func,
  /** Height of the table container */
  height: PropTypes.number,
  /** Height of the header container */
  headerHeight: PropTypes.number,
  /** Height of the row container */
  rowHeight: PropTypes.number,
  /** The default column width */
  columnWidth: PropTypes.number,
  /** Show/hide search box */
  enableSearch: PropTypes.bool,
  /** Search term to display in the search box (if enabled) */
  search: PropTypes.string,
  /** Search term in the url query string  url?search=${query} */
  query: PropTypes.object,
  /** Number of items to fetch */
  fetchCount: PropTypes.number,
  /** Default fetch offset */
  defaultOffset: PropTypes.number,
  /** Time to debounce searching as user types  */
  debounceWaitTime: PropTypes.number,
  /** More entities will render a dropdown for searching between entities and fire the onSearchEntityChanged when switched */
  searchEntities: PropTypes.object,
  /** Selected option in search dropdown */
  selectedSearchEntity: PropTypes.string,
  /** Function to execute when the search entity/dimension in the search dropdown is changed */
  onSearchEntityChanged: PropTypes.func,
  /** The minimum load batch size */
  minimumBatchSize: PropTypes.number,
  /** Placeholder for search box  **/
  searchPlaceHolder: PropTypes.string,
  /** Column name to sort data **/
  sortBy: PropTypes.string,
  /** [ASC or DESC] order for sorting column **/
  sortDirection: PropTypes.string,
  /** Function to execute on column sorting change  **/
  sort: PropTypes.func
}

RVTable.defaultProps = {
  columns: [],
  data: [],
  height: 600,
  rowHeight: 50,
  headerHeight: 20,
  columnWidth: 100,
  defaultOffset: 0,
  fetchCount: 10,
  debounceWaitTime: 300,
  searchEntities: {},
  minimumBatchSize: 10
}
