/* eslint-disable max-len */

/*!
 * deps
 */

import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'

/**
 * CheckMark component
 */
const CheckMark = props => {
  return (
    <IconBase viewBox="0 0 30 30" {...props}>
      <path
        d={
          props.checked
            ? 'M11.5 22.8L5 16.3c-.3-.3-.4-.6-.4-.9s.1-.7.4-.9c.5-.5 1.3-.5 1.8 0l5.6 5.6L23.5 7.9c.5-.5 1.3-.5 1.8 0s.5 1.3 0 1.8l-12 13.1c-.5.5-1.3.5-1.8 0z'
            : ''
        }
      />
    </IconBase>
  )
}

CheckMark.propTypes = {
  checked: PropTypes.bool
}

export default CheckMark
