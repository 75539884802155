// @flow
/* eslint-disable no-use-before-define */

import moment from 'moment'

const oneDayTime = 1000 * 60 * 60 * 24

/**
 * Singleton that store the max and min date available for the portal data
 */
class DateService {
  minDate: Date
  maxDate: Date

  constructor() {
    // By default we display 3 years of data
    this.minDate = moment()
      .subtract(36, 'month')
      .toDate()
    this.maxDate = moment().toDate()
  }

  getMaxDate(): Date {
    return this.maxDate
  }

  getMinDate(): Date {
    return this.minDate
  }

  setMaxDate(date: string) {
    this.maxDate = moment(date).toDate()
  }

  setMinDate(date: string) {
    this.minDate = moment(date).toDate()
  }

  setRange(minDate: string, maxDate: string) {
    this.setMinDate(minDate)
    this.setMaxDate(maxDate)
  }

  getMaxMonthDate(): string {
    return moment(this.maxDate)
      .startOf('month')
      .add(1, 'month')
      .toDate()
  }

  formatDate(date, formatStr) {
    return moment(date).format(formatStr)
  }

  isAfter(dateOne, dateTwo, unit) {
    return moment(dateOne).isAfter(moment(dateTwo), unit)
  }

  isBefore(dateOne, dateTwo, unit) {
    return moment(dateOne).isBefore(moment(dateTwo), unit)
  }

  isBetweenRange(beforeDate, afterDate, currentDate, endDate) {
    return moment(currentDate).isSameOrAfter(moment(beforeDate)) && moment(endDate).isSameOrBefore(moment(afterDate))
  }
}

export const dateService = new DateService()

/**
 * Options available in the date picker
 * The property "key" is used by rangeToDate to generate a Date range.
 */
const quickDateRanges = {
  calYearToDate: {
    key: 'calYearToDate',
    name: 'Year to date'
  },
  last6m: {
    key: 'last6m',
    name: 'Last 6 months'
  },
  last12m: {
    key: 'last12m',
    name: 'Last 12 months'
  },
  last24m: {
    key: 'last24m',
    name: 'Last 24 months'
  },
  allTime: {
    key: 'allTime',
    name: 'All Time'
  }
}

/**
 * Shift range by one day
 * @todo to merge with shiftRangeBy
 */
export const shiftRange = (_range: string) => {
  const range = rangeToDate(_range)
  const delta = oneDayTime + (range[1] - range[0])
  return range.map(date => new Date(date - delta))
}

/**
 * Transform a range key to an array of two dates
 * See quickDateRanges for key definition
 */
export const rangeToDate = (range: string): Date[] => {
  if (!range) {
    throw new Error('rangeToDate requires a range')
  }

  if (Array.isArray(range)) {
    return range
  }

  switch (range) {
    case 'calYearToDate':
      return [
        moment(dateService.getMaxMonthDate())
          .startOf('year')
          .toDate(),
        dateService.getMaxDate()
      ]
    case 'last6m':
      return [
        moment(dateService.getMaxMonthDate())
          .subtract(6, 'month')
          .toDate(),
        dateService.getMaxDate()
      ]
    case 'last12m':
      return [
        moment(dateService.getMaxMonthDate())
          .subtract(12, 'month')
          .toDate(),
        dateService.getMaxDate()
      ]
    case 'last24m':
      return [
        moment(dateService.getMaxMonthDate())
          .subtract(24, 'month')
          .toDate(),
        dateService.getMaxDate()
      ]
    case 'allTime':
      return [dateService.getMinDate(), dateService.getMaxDate()]
    default:
      break
  }

  throw new Error('can not convert range')
}

/**
 * urlEncode a date range
 */
export const encodeDateRange = (name: string) => (range: string) => {
  return `${name}=${rangeToDate(range)
    .map(date => date && moment(date).format('YYYY-MM-DD'))
    .join('.')}`
}

/**
 * Decode the date range from and URI part
 */
export const decodeDateRange = (range: string) => {
  return range.split('.').map(date => moment(date, 'YYYY-MM-DD').toDate())
}

/**
 * Format a given range to a formated string
 */
export const rangeToString = (range: string, prefix: boolean = false, format: string = 'MM/DD/YYYY') => {
  if (!range) {
    return ''
  }

  const str = rangeToDate(range)
    .map(date => {
      return date ? moment(date).format(format) : ' - '
    })
    .join(' to ')
  return prefix && quickDateRanges[range] ? `${quickDateRanges[range].name} (${str})` : str
}

/* eslint-enable no-use-before-define */
