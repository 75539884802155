export const CREATE_NEW_DELVIERY = 'CREATE_NEW_DELVIERY'
export const CREATE_NEW_DELVIERY_SUCCESS = 'CREATE_NEW_DELVIERY_SUCCESS'
export const CREATE_NEW_DELVIERY_ERROR = 'CREATE_NEW_DELVIERY_ERROR'
export const UPDATE_EDIT_DEADLINE_FORM = 'UPDATE_EDIT_DEADLINE_FORM'
export const UPDATE_DELIVERY_DEADLINE = 'UPDATE_DELIVERY_DEADLINE'
export const UPDATE_DELIVERY_DEADLINE_SUCCESS = 'UPDATE_DELIVERY_DEADLINE_SUCCESS'
export const UPDATE_DELIVERY_DEADLINE_ERROR = 'UPDATE_DELIVERY_DEADLINE_ERROR'
export const FETCH_CURRENT_DELIVERY = 'FETCH_CURRENT_DELIVERY'
export const UPDATE_CURRENT_DELIVERY_DETAILS = 'UPDATE_CURRENT_DELIVERY_DETAILS'
export const FETCH_CURRENT_DELIVERY_SUCCESS = 'FETCH_CURRENT_DELIVERY_SUCCESS'
export const FETCH_CURRENT_DELIVERY_FAILURE = 'FETCH_CURRENT_DELIVERY_FAILURE'
export const UPDATE_ACTIVE_STEP = 'UPDATE_CURRENT_DELIVERY_ACTIVE_STEP'
export const START_DATA_CUSTOMIZATION = 'START_CURRENT_DELIVERY_DATA_CUSTOMIZATION'
export const CANCEL_DELIVERY = 'CANCEL_CURRENT_DELIVERY'
export const CATEGORIES_CLEANUP_STATUS = 'FETCH_CATEGORIES_CLEANUP_STATUS'
export const CATEGORIES_CLEANUP_STATUS_SUCCESS = 'CATEGORIES_CLEANUP_STATUS_SUCCESS'
export const CATEGORIES_CLEANUP_STATUS_ERROR = 'CATEGORIES_CLEANUP_STATUS_ERROR'
export const CREATE_CLEANUP_JOB = 'CREATE_CATEGORY_CLEANUP_JOB'
export const CREATE_CLEANUP_JOB_ERROR = 'CREATE_CATEGORY_CLEANUP_JOB_ERROR'
export const FETCH_PAST_DELIVERIES = 'FETCH_PAST_DELIVERIES'
export const FETCH_PAST_DELIVERIES_SUCCESS = 'FETCH_PAST_DELIVERIES_SUCCESS'
export const FETCH_PAST_DELIVERIES_FAILURE = 'FETCH_PAST_DELIVERIES_FAILURE'
export const TOGGLE_SUBSTEP_DETAILS = 'TOGGLE_SUBSTEP_DETAILS'
export const RETRY_DELIVERY_STEP = 'RETRY_DELIVERY_STEP'
export const PROCEED_TO_DATA_CUSTOMIZATION = 'PROCEED_DELIVERY_TO_DATA_CUSTOMIZATION'
export const APPROVE_QC = 'APPROVE_QC'
export const REFRESH_DELIVERY_STATUS = 'REFRESH_CURRENT_DELIVERY_STATUS'
export const REFRESH_DELIVERY_STATUS_SUCCESS = 'REFRESH_CURRENT_DELIVERY_STATUS_SUCCESS'

export function updateEditDeadlineForm(payload) {
  return {
    type: UPDATE_EDIT_DEADLINE_FORM,
    payload
  }
}

export function onToggleDetails(showDetails, substep) {
  return {
    type: TOGGLE_SUBSTEP_DETAILS,
    payload: {
      showDetails,
      substep
    }
  }
}

export function updateDeliveryDeadline(payload, callback) {
  return {
    type: UPDATE_DELIVERY_DEADLINE,
    payload,
    callback
  }
}

export function createNewDelivery(payload, callback) {
  return {
    type: CREATE_NEW_DELVIERY,
    payload,
    callback
  }
}

export function fetchCurrentDelivery(payload) {
  return {
    type: FETCH_CURRENT_DELIVERY,
    payload
  }
}

export function refreshDeliveryStatus(deliveryId) {
  return {
    type: REFRESH_DELIVERY_STATUS,
    payload: {
      deliveryId
    }
  }
}

export function startDataCustomization(deliveryId, rerun, callback) {
  return {
    type: START_DATA_CUSTOMIZATION,
    deliveryId,
    rerun,
    callback
  }
}

export function retryDeliveryStep(step, substep, deliveryId) {
  return {
    type: RETRY_DELIVERY_STEP,
    payload: {
      step,
      substep,
      deliveryId
    }
  }
}

export function proceedToDataCustomization(deliveryId, skipDataCleanup, callback) {
  return {
    type: PROCEED_TO_DATA_CUSTOMIZATION,
    deliveryId,
    skipDataCleanup,
    callback
  }
}

export function approveQC({ deliveryId, approve, notes, callback }) {
  return {
    type: APPROVE_QC,
    payload: {
      deliveryId,
      approve,
      notes
    },
    callback
  }
}

export function updateActiveStep(activeStep) {
  return {
    type: UPDATE_ACTIVE_STEP,
    activeStep
  }
}

export function cancelDelivery({ deliveryId, notes, callback }) {
  return {
    type: CANCEL_DELIVERY,
    deliveryId,
    notes,
    callback
  }
}

export function fetchCategoriesCleanupStatus(payload) {
  return {
    type: CATEGORIES_CLEANUP_STATUS,
    payload
  }
}

export function createCategoryCleanupJob(payload) {
  return {
    type: CREATE_CLEANUP_JOB,
    payload
  }
}

export const fetchPastDeliveries = payload => ({
  type: FETCH_PAST_DELIVERIES,
  payload
})
