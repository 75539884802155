import { createAction } from '@reduxjs/toolkit'

// TODO use `FETCH_CATEGORY_SUGGESTIONS` remove/replace this action which we use in taxonomy
export const fetchCategorySuggestionsAction = createAction('FETCH_CATEGORY_SUGGESTIONS_COMPONENT')

export const fetchBrandSuggestionsAction = createAction('FETCH_BRAND_SUGGESTIONS_COMPONENT')

export const fetchMerchantSuggestionsAction = createAction('FETCH_MERCHANT_SUGGESTIONS_COMPONENT')

// Queries
export const fetchGroupQuerySuggestionsAction = createAction('FETCH_GROPUP_QUERY_SUGGESTIONS_COMPONENT')
export const fetchQuerySuggestionsAction = createAction('FETCH_QUERY_SUGGESTIONS_COMPONENT')
