import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SearchTypeahead from '../../../components/SearchTypeahead'
import DescriptionValueEditor from './DescriptionValueEditor'
import { default as constants, validEmailTypes, validSources } from '../../../constants/constants'
import { searchTermSuggest } from '../../../actions/search-actions'
import { OverlayTrigger, Button, Glyphicon, Tooltip } from 'react-bootstrap'

function mapStateToProps(state, ownProps) {
  return {
    searchValue: state.search.searchValue,
    searchSuggestions: state.search.searchSuggestions.reduce((suggArray, suggestion) => {
      const isParentSuggestion = isParent(suggestion)
      if (isParentSuggestion) {
        suggArray.push({
          ...suggestion,
          prefixLabel: constants.parentElementPrefix
        })
      }
      suggArray.push({
        ...suggestion,
        isChild: true,
        prefixLabel: !suggestion.isActive ? 'Select Inactive: ' : isParentSuggestion ? 'Select Parent: ' : ''
      })
      return suggArray
    }, [])
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSearchTermSuggest: ({ term, filter }) => {
      dispatch(searchTermSuggest({ term, filter, excludeInactive: true }))
    }
  }
}

function isParent(suggestion) {
  return (
    suggestion.type &&
    constants.fields[suggestion.type].canBeParent &&
    suggestion.isChild !== null &&
    !suggestion.isChild
  )
}

class CustomValueEditor extends Component {
  static propTypes = {
    field: PropTypes.string,
    value: PropTypes.any,
    searchValue: PropTypes.string,
    handleOnChange: PropTypes.func,
    onSearchTermSuggest: PropTypes.func,
    searchSuggestions: PropTypes.arrayOf(PropTypes.object),
    readOnly: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.state = {
      inEdit: false,
      inputText: ''
    }
  }

  renderMenuItemChildren(option, props, idx) {
    return (
      <span>
        <span>{`${option.prefixLabel ? option.prefixLabel : ''}`}</span>
        <span style={{ fontWeight: 'bold' }}>{option.value}</span>
      </span>
    )
  }

  render() {
    const tooltip = name => <Tooltip id="in-edit-value-clear">{name}</Tooltip>
    if (this.props.readOnly || (this.props.value && !this.state.inEdit)) {
      if (typeof this.props.value === 'string') {
        return (
          <div
            className="qb-applied-value"
            onDoubleClick={e => {
              e.preventDefault()
              this.setState({ inEdit: true, inputText: this.props.value })
            }}
          >
            {this.props.value}
          </div>
        )
      } else {
        return <div>{`...`}</div>
      }
    }
    const onInputChange = text => {
      this.setState({ inputText: text })
      this.props.onSearchTermSuggest({
        term: text,
        filter: this.props.field
      })
    }

    const onChange = selectionList => {
      if (selectionList && Array.isArray(selectionList) && selectionList.length > 0) {
        const selected = selectionList[0]
        if (Array.isArray(selected.value)) {
          this.props.handleOnChange(selected)
        } else if (isParent(selected)) {
          this.props.handleOnChange(constants.parentElementPrefix + selected.value)
        } else {
          this.props.handleOnChange(selected.value)
        }
        this.setState({ inEdit: false })
      }
    }
    let suggestions = this.props.searchSuggestions
    const descriptionValueEditorFields = ['description', 'from_addr', 'subject', 'domain', 'template']
    switch (this.props.field) {
      case 'description':
      case 'subject':
      case 'from_addr':
      case 'domain':
      case 'template':
        suggestions = []
        break
      case 'email_type':
      case 'bbx_supra':
        const email_types = validEmailTypes.map(type => type.toLowerCase())
        suggestions = email_types.map(email_type => {
          return {
            type: this.props.field,
            value: email_type,
            label: constants.fields[this.props.field].label
          }
        })

        break
      case 'quantity':
      case 'price':
      case 'distinct_msg_uuid':
      case 'distinct_mailbox_uuid':
      case 'cnt_msg':
      case 'percentage_msg':
      case 'cnt_mailbox':
      case 'percentage_msg_total_cusum':
        if (isNaN(this.state.inputText)) {
          suggestions = []
        } else {
          suggestions = [
            {
              type: this.props.field,
              value: this.state.inputText,
              label: constants.fields[this.props.field].label
            }
          ]
        }
        break
      case 'is_cfg':
      case 'source':
        const sources = validSources.map(source => source.toLowerCase())
        suggestions = sources.map(source => {
          return {
            type: this.props.field,
            value: source,
            label: constants.fields[this.props.field].label
          }
        })
        break
      default:
        break
    }
    return (
      <div className="qb-in-edit-value">
        {descriptionValueEditorFields.includes(this.props.field) ? (
          <DescriptionValueEditor inputText={this.state.inputText} onChange={onChange} onInputChange={onInputChange} />
        ) : (
          <SearchTypeahead
            placeholder={'Enter value'}
            selectedValues={[{ value: this.props.value }]}
            onInputChange={onInputChange}
            suggestions={suggestions}
            onChange={onChange}
            renderSuggestions={this.renderMenuItemChildren}
          />
        )}
        <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Cancel')}>
          <Button
            className="value-clear-button"
            bsStyle="link"
            onClick={event => {
              this.setState({ inEdit: false })
              onInputChange('')
              event.preventDefault()
            }}
          >
            <Glyphicon glyph="remove" />
          </Button>
        </OverlayTrigger>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomValueEditor)
