import React from 'react'

import { confirmModal } from '../../../actions/modal-actions'
import './index.scss'

export default (groupName, callback) =>
  confirmModal(
    <div>
      <strong>{groupName}</strong> will be removed from the Portal
    </div>,
    `Confirm Group Delete`,
    { deleteButton: 'Delete' },
    callback,
    'large',
    'confirm-delete-modal'
  )
