import moment from 'moment'

import { METRIC_GRAPTH } from './constants'

export const qcMonitoringResult = (result, graphType) => {
  const xAxis = result.context.map(data => moment.utc(data.order_date).format("MMM 'YY"))
  const yAxisContext = result.context.map(data => parseInt(data.revenue, 10))

  const yAxisQuery = result.query ? result.query.map(data => parseInt(data.revenue, 10)) : []

  let parsedResult

  const color = {
    query: '#f18e7b', // red
    context: '#65bfcc' // blue
  }

  if (graphType === METRIC_GRAPTH.INCLUDE) {
    parsedResult = {
      xAxis,
      yAxis: [
        {
          name: 'Revenue To Be Included',
          data: yAxisQuery,
          color: color.query
        },
        {
          name: 'Category Revenue',
          data: yAxisContext,
          color: color.context
        }
      ]
    }
  } else if (graphType === METRIC_GRAPTH.EXCLUDE) {
    parsedResult = {
      xAxis,
      yAxis: [
        {
          name: 'Revenue To Be Excluded',
          data: yAxisQuery,
          color: color.query
        },
        {
          name: 'Correct Category Revenue',
          data: yAxisContext,
          color: color.context
        }
      ]
    }
  } else if (graphType === METRIC_GRAPTH.MISBRAND) {
    parsedResult = {
      xAxis,
      yAxis: [
        {
          name: 'Revenue To Be Branded',
          data: yAxisQuery,
          color: color.query
        },
        {
          name: 'Revenue Branded',
          data: yAxisContext,
          color: color.context
        }
      ]
    }
  } else if (graphType === METRIC_GRAPTH.INCLUDE_COVERAGE) {
    parsedResult = {
      xAxis,
      yAxis: [
        {
          name: 'Revenue To Be Covered',
          data: yAxisQuery,
          color: color.query
        },
        {
          name: 'Revenue Covered',
          data: yAxisContext,
          color: color.context
        }
      ]
    }
  }

  return parsedResult
}

export const getMetricWarningMessage = ({ queryId, isQueryCorrect, isNoData }) => {
  if (queryId === null) {
    return 'Query not selected. Please choose a query.'
  } else if (isQueryCorrect === false) {
    return 'Query not correct or is disabled. Please check the query.'
  } else if (isNoData) {
    return 'No data found.'
  }

  return null
}
