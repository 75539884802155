import React from 'react'
import PropTypes from 'prop-types'

import alertSVG from './images/ic-notice.svg'
import closeSVG from './images/ic-close.svg'
import './index.scss'

const BetaAlert = ({ enabled, message, onDismissAlert }) => {
  if (!enabled) return null

  return (
    <div className="beta-alert">
      <img src={alertSVG} alt="" />

      <p>{message}</p>

      {onDismissAlert && (
        <div className="beta-alert-dismiss" onClick={onDismissAlert}>
          <img src={closeSVG} alt="" />
        </div>
      )}
    </div>
  )
}

BetaAlert.defaultProps = {
  enabled: true,
  message:
    'This feature is currently in beta testing. Some features might not be fully implimented or functional. Please report any issues or bugs encountered to the Product team.'
}

BetaAlert.propTypes = {
  enabled: PropTypes.bool,
  message: PropTypes.string,
  onDismissAlert: PropTypes.func
}

export default BetaAlert
