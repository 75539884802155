// @flow
import { CHECK_JOB_BOX, CLEAR_CHECK_BOX } from '../actions/group-actions'
import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialState = {
  value: '',
  selectedJobs: {}
}

const jobListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_JOB_BOX:
      return {
        ...state,
        selectedJobs: { ...state.selectedJobs, ...action.data }
      }

    case CLEAR_CHECK_BOX:
      return {
        ...state,
        selectedJobs: {}
      }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default jobListReducer
