import React, { useState } from 'react'
import PropTypes from 'prop-types'
import OutsideClickHandler from 'react-outside-click-handler'

import cx from 'classnames'
import './index.scss'

const DropDownList = ({ label, className, options, selectedValue, id, onValueChange }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onOptionClick = (key, option) => {
    if (onValueChange) {
      onValueChange(key, option)
    }
    setIsOpen(false)
  }

  const keys = Object.keys(options)
  const borderClass = isOpen ? 'borderSliceTeal' : 'borderNormal'
  const borderBottomClass = isOpen ? 'borderBottomOpen' : ''

  const selectedOption = selectedValue ? options[selectedValue] : null

  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div id={id} className={cx(className, `dropdown-wrapper ${borderClass}`)}>
        <div className={cx(`dropdown-button ${borderBottomClass}`)} onClick={() => setIsOpen(!isOpen)}>
          <div>{selectedOption && selectedOption.name ? selectedOption.name : label}</div>
          <div className={`arrow-wrapper${isOpen ? ' dropup' : ''}`}>
            <span className="caret" />
          </div>
        </div>
        {isOpen && keys && (
          <ul className="dropdown-options">
            {keys.map(key => {
              const option = options[key]
              return (
                <li
                  key={key}
                  className={cx('dropdown-option', {
                    active: option === selectedOption
                  })}
                  onClick={() => onOptionClick(key, option)}
                >
                  {option.name}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  )
}

DropDownList.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.object,
  selectedValue: PropTypes.string,
  onValueChange: PropTypes.func,
  handleOuterClick: PropTypes.func
}

export default DropDownList
