import axios from 'axios'

class ApiService {
  constructor() {
    this.headers = { 'Content-Type': 'application/json' }
  }

  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response
    } else {
      const error = new Error(response.statusText)
      error.response = response
      throw error
    }
  }

  parseData(response = {}) {
    return response.data
  }

  get(apiUrl, query, headers = {}) {
    switch (typeof query) {
      case 'string':
        apiUrl += `?${query}`
        break
      case 'object':
        break
      default:
        break
    }
    return axios
      .get(apiUrl, { headers: { ...this.headers, ...headers } })
      .then(this.checkStatus)
      .then(this.parseData)
  }

  post(url, data, headers = {}) {
    return axios
      .post(url, data, {
        headers: { ...this.headers, ...headers }
      })
      .then(this.checkStatus)
      .then(this.parseData)
  }

  put(url, data, headers = {}) {
    return axios
      .put(url, data, {
        headers: { ...this.headers, ...headers }
      })
      .then(this.checkStatus)
      .then(this.parseData)
  }

  delete(url, data, headers = {}) {
    return axios
      .delete(url, data, {
        headers: { ...this.headers, ...headers }
      })
      .then(this.checkStatus)
      .then(this.parseData)
  }

  isAuth(token) {
    this.headers = { ...this.headers, ...{ Authorization: `Bearer ${token}` } }
    return this
  }
}

export default new ApiService()
