import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Col } from 'react-bootstrap'
import { SortableItem, ListItem as UnsortableItem } from './ListItem'
import { SortableContainer } from 'react-sortable-hoc'
import cx from 'classnames'
/**
 * Dumb sortable list to display all the non-deleted Filter value items
 * passes all actions to parent
 */
class ListContainer extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string,
    onUpdateValue: PropTypes.func,
    onDeleteValue: PropTypes.func,
    startIndex: PropTypes.number,
    endIndex: PropTypes.number,
    sorting: PropTypes.bool,
    searchText: PropTypes.string,
    duplicateIds: PropTypes.array
  }

  render() {
    const {
      items,
      type,
      onUpdateValue,
      onDeleteValue,
      startIndex,
      endIndex,
      sorting,
      searchText,
      duplicateIds
    } = this.props
    const activeItems = []
    const ListItem = sorting ? SortableItem : UnsortableItem
    let pageCount = 0
    items.forEach((item, index) => {
      if (item.action === 'delete') return
      if (
        searchText &&
        !item.value.toLowerCase().includes(searchText) &&
        !item.aliasValue.toLowerCase().includes(searchText)
      ) {
        return
      }
      pageCount++
      if (pageCount < startIndex || pageCount > endIndex) {
        return
      }
      activeItems.push(
        <ListItem
          key={`item-${item.id}`}
          index={index}
          type={type}
          indexNo={index}
          onUpdateValue={onUpdateValue}
          onDeleteValue={onDeleteValue}
          isDuplicate={duplicateIds && duplicateIds.includes(item.id)}
          {...item}
        />
      )
    })
    return (
      <ul
        className={cx('filter-values-list', {
          sortable: sorting
        })}
      >
        {activeItems.length > 0 ? activeItems : <Col className="no-value-msg"> NO VALUES FOUND </Col>}
      </ul>
    )
  }
}

export default SortableContainer(ListContainer)
