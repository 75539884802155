import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SubMenuItem from '../SubMenuItem'
import Input from 'components/Input'
import SearchIcon from 'components/Icon/SearchIcon'
import Highlighter from 'react-highlight-words'

import UserService from 'services/user-service'
import { sortBy, filter } from 'lodash'
import cx from 'classnames'

const THRESHOLD_TO_DISPLAY_SEARCH = 8
/**
 * Navigation Component that contains all SubMenu options to be added under Reports
 */
class ReportsSubMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: ''
    }
  }

  getCustomReports() {
    const { userCustomReports = [] } = this.props
    return userCustomReports.map(report => ({
      id: report.id,
      name: report.name,
      route: `/custom/reports/view/${report.id}`
    }))
  }

  searchChanged = searchText => {
    this.setState({ searchText })
  }

  clearSearch = () => {
    this.setState({ searchText: '' })
  }

  render() {
    const allReports = [...this.getCustomReports()]
    let reportsToDisplay = allReports
    const { searchText } = this.state
    const searchEnabled = allReports.length > THRESHOLD_TO_DISPLAY_SEARCH
    if (searchEnabled) {
      const filterText = searchText.toLowerCase()
      reportsToDisplay = filter(allReports, report => report.name.toLowerCase().includes(filterText))
    }
    reportsToDisplay = [...sortBy(reportsToDisplay, [report => report.name.toLowerCase()])]

    return (
      <div
        onClick={e => {
          // stop the event to prevent closing Left Menu
          e.stopPropagation()
        }}
      >
        {searchEnabled && (
          <div className="reports-search-box">
            <Input
              clearButton
              placeholder="Search report"
              className={cx('input', 'search-input-box')}
              value={searchText}
              iconName={<SearchIcon width={20} height={20} />}
              didClickClearButton={this.clearSearch}
              textDidChange={this.searchChanged}
            />
          </div>
        )}
        {reportsToDisplay.map(({ id, name, route, isActive }) => {
          return (
            <SubMenuItem
              key={`r_${id}`}
              label={
                searchEnabled && searchText ? (
                  <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={name}
                    highlightClassName="highlight-text"
                    autoEscape
                  />
                ) : (
                  name
                )
              }
              route={route}
              isActive={isActive}
            />
          )
        })}
      </div>
    )
  }
}

ReportsSubMenu.propTypes = {
  userCustomReports: PropTypes.array
}

function mapStateToProps({ session: { user } }) {
  return {
    userCustomReports: UserService.getCustomReports(user)
  }
}

export default connect(mapStateToProps)(ReportsSubMenu)
