import { createAction } from '@reduxjs/toolkit'

const metricsActions = {
  // set category for showing in metrics tab
  setCategory: createAction('SET_METRICS_CATEGORY'),

  // Dictionary
  fetchDictionaryGraph: createAction('FETCH_METRICS_DICTIONARY_GRAPH'),

  // Query
  fetchQuery: createAction('FETCH_METRICS_QUERY'),
  successLoadQuery: createAction('SUCCESS_LOAD_METRICS_QUERY'),

  saveQuery: createAction('SAVE_METRICS_QUERY'),

  removeQuery: createAction('REMOVE_METRICS_QUERY'),
  successRemoveQuery: createAction('SUCCESS_REMOVE_METRICS_QUERY'),

  setQueryLoading: createAction('SET_LOADING_METRICS_QUERY'),

  cleanupQueries: createAction('CLEANUPs_METRICS_QUERIES'),

  // Graph
  fetchGraph: createAction('FETCH_METRICS_GRAPH'),

  // show widgets in UI
  toggleWidget: createAction('TOGGLE_METRICS_WIDGET')
}

export default metricsActions
