import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import CheckBox from '../../CheckBox'
import Radio from '../../Radio'
import { Col, FormControl, HelpBlock, Glyphicon, FormGroup } from 'react-bootstrap'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import cx from 'classnames'

const SortHandle = SortableHandle(() => {
  return <Glyphicon glyph="menu-hamburger" className="sort-handle" />
})

/**
 * Dumb component to display and edit individual Filter Value
 * passes all actions/edits to parent
 */
export class ListItem extends PureComponent {
  render() {
    const {
      value,
      aliasValue,
      isDefault,
      id,
      order,
      indexNo: index,
      type,
      onUpdateValue,
      onDeleteValue,
      isDuplicate
    } = this.props
    return (
      <FormGroup key={id} controlId={`${id}`} validationState={isDuplicate ? 'error' : null}>
        <Col sm={1}>
          <SortHandle />
          {order}
        </Col>
        <Col sm={4}>
          <FormControl
            name={'value'}
            placeholder={'Enter value'}
            value={value}
            onChange={event => {
              onUpdateValue(index, 'value', event.target.value)
            }}
            type="text"
            autoFocus={!value}
          />
          {isDuplicate && <HelpBlock>{'This Filter value already exits!'}</HelpBlock>}
        </Col>
        <Col sm={4}>
          <FormControl
            name={'aliasValue'}
            placeholder={'Enter display value'}
            value={aliasValue}
            className={cx({ unedited: value === aliasValue })}
            onChange={event => {
              onUpdateValue(index, 'aliasValue', event.target.value)
            }}
            type="text"
          />
        </Col>
        <Col sm={1}>
          {type === 'checkbox' ? (
            <CheckBox
              className={'item'}
              valueDidChange={() => {
                onUpdateValue(index, 'isDefault', !isDefault)
              }}
              checked={isDefault}
            />
          ) : (
            <Radio
              checked={isDefault}
              onChange={() => {
                onUpdateValue(index, 'isDefault', true)
              }}
            />
          )}
        </Col>
        <Col sm={1}>
          <Glyphicon
            onClick={() => {
              onDeleteValue(index)
            }}
            glyph="trash"
            className="removeButton value-action"
          />
        </Col>
      </FormGroup>
    )
  }
}

ListItem.propTypes = {
  value: PropTypes.string,
  aliasValue: PropTypes.string,
  isDefault: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  order: PropTypes.number,
  indexNo: PropTypes.number,
  type: PropTypes.string,
  onUpdateValue: PropTypes.func,
  onDeleteValue: PropTypes.func,
  isDuplicate: PropTypes.bool
}

export const SortableItem = SortableElement(ListItem)
