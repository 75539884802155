import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'

class RepeatRuleButton extends Component {
  render() {
    const tooltip = name => <Tooltip id="remove-button-tooltip">{name}</Tooltip>
    return (
      <Button className={`${this.props.className}`} bsSize="xsmall" bsStyle="link" onClick={this.props.handleOnClick}>
        <OverlayTrigger delayShow={1000} placement="top" overlay={tooltip('Duplicate')}>
          <Glyphicon glyph={'duplicate'} />
        </OverlayTrigger>
      </Button>
    )
  }
}

RepeatRuleButton.propTypes = {
  label: PropTypes.string, //
  className: PropTypes.string, // css classNames to be applied
  handleOnClick: PropTypes.func, // callback function to invoke repeating the current level rule
  level: PropTypes.number // The level of the current rule
}

export default RepeatRuleButton
