import { createSelector } from 'reselect'
import { isTokenExpired } from '../utils/jwt-helper'

const authSelector = state => state.session && state.session.auth

export const getAuth = createSelector(authSelector, auth => {
  if (auth) return auth
  try {
    return JSON.parse(window.localStorage.getItem('auth'))
  } catch (error) {
    console.error(error)
  }
  return null
})

export const isAuthValid = createSelector(
  state => getAuth(state),
  auth => {
    return (auth && auth.token && !isTokenExpired(auth.token)) || false
  }
)
