import React from 'react'

import { confirmModal } from '../../../actions/modal-actions'
import GroupLogo from '../../GroupLogo'

const tableStyle = {
  borderTop: '2px solid #E3E3E3',
  borderBottom: '2px solid #E3E3E3',
  width: '100%',
  margin: '10px 0px'
}

const userDeactivationModal = (user, group, callback) =>
  confirmModal(
    <div>
      The user access will be modified for all of this group&#8217;s products.
      <table style={tableStyle}>
        <tbody>
          <tr>
            <td className={'groupLogo'}>
              <GroupLogo imgSrc={user.image} imgAlt="group_logo" />
            </td>
            <td>{user.fullname}</td>
            <td>{user.email}</td>
            <td>{group.name}</td>
          </tr>
        </tbody>
      </table>
    </div>,
    'Are you sure?',
    { okButton: 'Continue' },
    callback
  )

export default userDeactivationModal
