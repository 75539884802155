/* eslint-disable max-len */

/*!
 * deps
 */

import React from 'react'
import IconBase from './IconBase'

/**
 * Close component
 */
const Lock = props => {
  return (
    <IconBase viewBox="0 0 30 30" {...props}>
      <path d="M8 23v-8.4c.2-.7.7-.8 1.3-.7v-.7c-.1-1.3 0-2.6.4-3.9.5-1.6 1.4-2.9 3-3.6 1.1-.5 2.2-.6 3.4-.5 1.1.1 2.2.5 3.1 1.2 1.1.9 1.7 2.2 2 3.6.2 1.1.3 2.3.2 3.5v.4h.2c.7 0 .9.3.9.9v8c0 .5-.3.9-.9.9H9.2c-.6 0-1-.1-1.2-.7zm11.5-9.2c0-.2 0-.5.1-.7.1-1.2 0-2.3-.3-3.4-.4-1.2-1-2.1-2.2-2.5-.7-.3-1.5-.3-2.2-.2-1.4.1-2.5.8-3.1 2.2-.8 1.3-.8 2.8-.7 4.3v.4c2.9-.1 5.6-.1 8.4-.1zM15 20.2v.6c0 .3.1.5.4.4.2 0 .4-.2.4-.4v-1c0-.2 0-.3.2-.4.3-.3.3-.7 0-1.1-.3-.3-.9-.3-1.2 0-.3.3-.3.8 0 1 .2.2.2.3.2.5v.4z" />
    </IconBase>
  )
}

export default Lock
