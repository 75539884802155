/* eslint-disable max-len */

/*!
 * deps
 */

import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'

/**
 * Reports Icon
 */
class Reports extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object,
    fill: PropTypes.string
  }

  static defaultProps = {
    width: 30,
    height: 20,
    fill: 'none'
  }

  render() {
    return (
      <IconBase viewBox="0 4 30 20" {...this.props}>
        <g>
          <rect x="0" y="0" width="30" height="30" />
          <g id="report" transform="translate(6.000000, 5.000000)">
            <rect id="path-1" x="0" y="0" width="14" height="16" stroke="currentColor" strokeWidth="1.2px" />
            <rect
              id="Rectangle-2"
              fill="currentColor"
              transform="translate(4.000000, 8.000000) rotate(-90.000000) translate(-4.000000, -8.000000) "
              x="3"
              y="7"
              width="2"
              height="2"
            />
            <rect
              id="Rectangle-2-Copy"
              fill="currentColor"
              transform="translate(7.000000, 7.000000) rotate(-90.000000) translate(-7.000000, -7.000000) "
              x="5"
              y="6"
              width="4"
              height="2"
            />
            <rect
              id="Rectangle-2-Copy-3"
              fill="currentColor"
              transform="translate(7.000000, 10.500000) rotate(-360.000000) translate(-7.000000, -10.500000) "
              x="3"
              y="10"
              width="8"
              height="1"
            />
            <rect
              id="Rectangle-2-Copy-4"
              fill="currentColor"
              transform="translate(7.000000, 12.500000) rotate(-360.000000) translate(-7.000000, -12.500000) "
              x="3"
              y="12"
              width="8"
              height="1"
            />
            <rect
              id="Rectangle-2-Copy-2"
              fill="currentColor"
              transform="translate(10.000000, 6.000000) rotate(-90.000000) translate(-10.000000, -6.000000) "
              x="7"
              y="5"
              width="6"
              height="2"
            />
          </g>
          <g
            id="report-2"
            transform="translate(16.500000, 16.000000) rotate(-180.000000) translate(-16.500000, -16.000000) translate(10.000000, 8.000000)"
            fill="currentColor"
          >
            <rect
              id="Rectangle-2-Copy-3"
              transform="translate(6.000000, 0.500000) rotate(-360.000000) translate(-6.000000, -0.500000) "
              x="1.18329136e-30"
              y="-2.10942375e-15"
              width="12"
              height="1"
            />
            <rect
              id="Rectangle-2-Copy-5"
              transform="translate(1.000000, 15.500000) rotate(-360.000000) translate(-1.000000, -15.500000) "
              x="7.10542736e-15"
              y="15"
              width="2"
              height="1"
            />
            <rect
              id="Rectangle-2-Copy-3"
              transform="translate(0.500000, 7.500000) rotate(-90.000000) translate(-0.500000, -7.500000) "
              x="-7"
              y="7"
              width="15"
              height="1"
            />
            <rect
              id="Rectangle-2-Copy-3"
              transform="translate(12.500000, 1.000000) rotate(-90.000000) translate(-12.500000, -1.000000) "
              x="11.5"
              y="0.5"
              width="2"
              height="1"
            />
          </g>
          <g>
            <path d="M28 0h-24c-2.2 0-4 1.8-4 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4v-24c0-2.2-1.8-4-4-4zM28 28h-24v-24h24v24z" />
          </g>
        </g>
      </IconBase>
    )
  }
}
export default Reports
