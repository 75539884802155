// react, redux stuff
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DocumentTitle from 'react-document-title'

// external components
import { Row, Col, Glyphicon } from 'react-bootstrap'

// internal components
import Loader from '../Loader'
import InputBox from '../InputBox'
import SearchIcon from '../Icon/SearchIcon'
import FlatMenuList from './filters/FlatMenuList'
import CheckBoxFilter from './filters/CheckBoxFilter'
import RadioFilter from './filters/RadioFilter/RadioFilter'
import DateRangeFilter from './filters/DateRangeFilter'
import EmbeddedRenderer from '../EmbeddedRenderer'
import ReportButtons from './ReportButtons'

// actions, services, utils
import { fetchReportDetails, updateFilterDefaults, createDashboardURL } from '../../actions/custom-report-actions'
import UserService from '../../services/user-service'
import { products, appName } from '../../constants/constants'
import { findIndex, find, sortBy, some, get, toString, isEmpty, endsWith, intersection } from 'lodash'
import cx from 'classnames'
import moment from 'moment'
import './index.scss'

/**
 * @summary Handles the view of chart related to a tab for the current report w.r.t. applied filters.
 * @class CustomReportViewPage
 * @extends {Component}
 */
class CustomReportViewPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentTab: {
        // the currently selected tab
        id: '',
        name: ''
      },
      stagedFilterValue: {},
      appliedFilterValue: {},
      tabStates: {} // last state of individual tabs
    }
  }

  /** Component lifecycle methods START */

  componentDidMount() {
    const { reportId, fetchReportDetails } = this.props
    if (!reportId) {
      return
    }
    // fetch report details, track page view
    fetchReportDetails({
      reportId,
      mode: 'view',
      shouldTrackPageView: true
    })

    window.addEventListener('message', this.listenToFrameEvent, false)
  }

  componentDidUpdate(prevProps) {
    const { loading, reportDetails, reportId, fetchReportDetails } = this.props
    if (
      !loading && // report details not loading
      (!reportDetails || // report details missing
        reportId !== prevProps.reportId) // report id changed/updated
    ) {
      // fetch report details, track page view
      fetchReportDetails({
        reportId,
        mode: 'view',
        shouldTrackPageView: true
      })
      // clear currentTab
      this.setState({
        currentTab: {},
        stagedFilterValue: {},
        appliedFilterValue: {},
        tabStates: {}
      })
    }

    if (
      /**
       * Report details exist but tab is not loaded
       * */
      reportDetails && // report details exist
      toString(reportDetails.id) === reportId && // report details is of current report
      !this.state.currentTab.id // no currentTab selected
    ) {
      this.loadTab()
    } else if (
      /**
       * Report details were reloaded and older dashbaordLinks are no longer available
       * */
      toString(reportDetails.id) === toString(prevProps.reportDetails.id) && // report details did not change
      isEmpty(reportDetails.dashboardLinks) &&
      !isEmpty(prevProps.reportDetails.dashboardLinks)
    ) {
      const currentStagedvalues = this.state.stagedFilterValue
      this.resetFilters()
      // restoring staged values after reset
      this.setState({ stagedFilterValue: currentStagedvalues })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.listenToFrameEvent)
  }

  /** Component lifecycle methods END */

  /** Helper method START */
  isProductAuthorized() {
    const product = this.isAdminPage() ? products.adminToolsCustomReportManager : products.customReportsPeriscope
    return UserService.isProductAuthorized(UserService.getProducts(this.props.user), product)
  }

  isAdminPage() {
    return this.props.location.pathname.startsWith('/admin')
  }

  formatDate = date => moment(date).format('MM/DD/YYYY')

  getPrevMonth = (date, numOfMon) =>
    moment(date)
      .startOf('month')
      .subtract(numOfMon, 'month')
      .format('MM/DD/YYYY')

  getQuickSelectOptions(defaultStart, defaultEnd, minDate, maxDate) {
    return [
      {
        key: 'default',
        label: 'Default',
        startDate: this.formatDate(defaultStart),
        endDate: this.formatDate(defaultEnd)
      },
      {
        key: 'calYearToDate',
        label: 'Year to Date',
        startDate: moment(maxDate).startOf('year'),
        endDate: this.formatDate(maxDate)
      },
      {
        key: 'last1m',
        label: 'Last Month',
        startDate: moment(maxDate).startOf('month'),
        endDate: this.formatDate(maxDate)
      },
      {
        key: 'last6m',
        label: 'Last 6 Months',
        startDate: this.getPrevMonth(maxDate, 5),
        endDate: this.formatDate(maxDate)
      },
      {
        key: 'last12m',
        label: 'Last 12 Months',
        startDate: this.getPrevMonth(maxDate, 11),
        endDate: this.formatDate(maxDate)
      },
      {
        key: 'all_time',
        label: 'All Time',
        startDate: this.formatDate(minDate),
        endDate: this.formatDate(maxDate)
      }
    ]
  }

  /**
   * @summary gets details of current tab from reportDetails
   * @returns {(Object|null)} tab object or null
   */
  getCurrentTab = () => {
    const { currentTab } = this.state
    const { reportDetails } = this.props
    if (!currentTab || !currentTab.id || !reportDetails || !reportDetails.Tabs) {
      return null
    }
    return find(reportDetails.Tabs, { id: currentTab.id })
  }

  /**
   * @summary gets id of current tab from state
   * @returns {(number|string)} id of current tab
   */
  getCurrentTabId = () => {
    return this.state.currentTab.id
  }

  /**
   * @summary gets state of the tab
   * @param {number} tabId the id of the tab
   */
  getTabState = tabId => {
    const defaultTabState = {
      // default initial state for any tab
      isMoreFilters: false, // are moreFilters (if any) visible or hidden
      reportDimensions: { width: '100%', height: '100%' } // initial reportDimensions
    }
    const {
      tabStates,
      currentTab: { id: currentTabId }
    } = this.state
    tabId = tabId || currentTabId
    return tabStates[tabId] || defaultTabState
  }

  /**
   * @summary updates the current state of the tab by merging the payload
   * @param {number} tabId id of tab that needs to be updated
   * @param {object} payload the update payload
   */
  updateTabState = (tabId, payload = {}) => {
    const tabState = this.getTabState(tabId)
    this.setState({
      tabStates: {
        ...this.state.tabStates,
        [tabId]: {
          ...tabState,
          ...payload
        }
      }
    })
  }

  sortFilters = (filterList = []) => {
    return sortBy(
      filterList.filter(({ type }) => type !== 'userDefined'),
      item => item.TabFilter.filterOrder
    )
  }

  hasMoreFilters = filterList => {
    return some(filterList, filter => {
      return filter.type === 'userDefined'
    })
  }

  getDefaultDateRangeFilterValue = filter => {
    const formatDate = date => moment(date).format('MM/DD/YYYY')
    if (!filter || !filter.FilterValues || filter.type !== 'dateRange') {
      return {}
    }
    const dateRange = {}
    filter.FilterValues.forEach(({ isDefault, aliasValue, value }) => {
      if (isDefault) {
        if (endsWith(aliasValue, '_start')) {
          dateRange.start = formatDate(value)
        } else if (endsWith(aliasValue, '_end')) {
          dateRange.end = formatDate(value)
        }
      }
    })
    return dateRange
  }

  getDefaultFilterValues = filter => {
    if (!filter || !filter.FilterValues) {
      return []
    }
    return filter.FilterValues.filter(item => item.isDefault)
  }

  /** Helper method END */

  /** dashboard payload methods START */

  buildRegularFilterPayload = (tabFilters, appliedFilterValue) => {
    const filterPayload = []
    tabFilters.forEach(filter => {
      let selectedValues = appliedFilterValue[filter.periscopeName]
      switch (filter.type) {
        case 'radio':
          if (!selectedValues) {
            this.getDefaultFilterValues(filter).forEach(item => {
              if (item.value != null || (Array.isArray(item.value) && item.value.length > 0)) {
                filterPayload.push({
                  name: filter.periscopeName,
                  value: item.value,
                  display: item.aliasValue
                })
              }
            })
          } else {
            selectedValues.forEach(selectedValue => {
              const display = find(filter.FilterValues, {
                value: selectedValue
              }).aliasValue
              filterPayload.push({
                name: filter.periscopeName,
                value: selectedValue,
                display
              })
            })
          }
          break
        case 'checkbox':
          if (!selectedValues) {
            selectedValues = this.getDefaultFilterValues(filter).map(item => item.value)
          }
          if (selectedValues && selectedValues.length > 0) {
            filterPayload.push({
              name: filter.periscopeName,
              value: selectedValues
            })
          }
          break
        case 'dateRange':
          const { start, end } = selectedValues || this.getDefaultDateRangeFilterValue(filter)
          if (start) {
            filterPayload.push({
              name: `${filter.periscopeName}_start`,
              value: start
            })
          }
          if (end) {
            filterPayload.push({
              name: `${filter.periscopeName}_end`,
              value: end
            })
          }
          break
        case 'userDefined':
          if (selectedValues) {
            filterPayload.push({
              name: filter.periscopeName,
              value: selectedValues
            })
          }
          break
        default:
          console.error(`unknown filter type: ${filter.type}`)
      }
    })
    return filterPayload
  }

  buildTableFilterPayload = (inheritBaseTable = false, baseTable = {}, associatedTables = []) => {
    const tablePayload = []
    if (inheritBaseTable) {
      tablePayload.push({ name: baseTable.name, value: baseTable.value })
    }
    associatedTables.forEach(table => {
      if (table.name && table.value) {
        tablePayload.push(table)
      }
    })
    return tablePayload
  }

  /**
   * @summary Prepares the payload to fetch dashbaord URL
   * @param {Object} reportDetails The object having compplete report details.
   * @param {String} tabId The id of the selected tab for which the URL needs to be prepared.
   * @return {Boolean} a boolean value indicating whether we need to ignore the filters selection and load with default values
   * @memberof CustomReportViewPage
   */
  reloadDashboard = ({ tabId, outdatedTabIds = [], selectedFilterValue = this.state.appliedFilterValue }) => {
    const { reportDetails } = this.props
    const payloadDefaults = { embed: 'v2', border: 'off' }
    const tabDetails = find(reportDetails.Tabs, { id: tabId })
    if (!tabDetails) return {}

    const { dashboardId: dashboard, inheritBaseTable, Filters: tabFilters } = tabDetails

    const baseTable = JSON.parse(reportDetails.baseTable)
    const associatedTables = JSON.parse(tabDetails.associatedTables)

    const filterPayload = [
      ...this.buildTableFilterPayload(inheritBaseTable, baseTable, associatedTables),
      ...this.buildRegularFilterPayload(tabFilters, selectedFilterValue)
    ]

    let visibleFilters = []

    if (this.isAdminPage()) {
      const visibleMap = {}

      filterPayload.forEach(filter => {
        if (filter.name !== undefined) {
          visibleMap[filter.name] = true
        }
      })

      visibleFilters = Object.keys(visibleMap)
    }

    const payload = {
      ...payloadDefaults,
      dashboard,
      apiKey: reportDetails.apiKey || 'ri',
      filters: filterPayload,
      visible: visibleFilters,
      expires_at: moment()
        .add(1, 'd')
        .unix(),
      data_ts: moment()
        .subtract(15, 'minutes')
        .unix()
    }

    this.props.createDashboardURL(reportDetails.id, tabId, payload, outdatedTabIds)
  }
  /** dashboard payload methods END */

  loadTab = (tabDetails, forceReloadDashbaord = false) => {
    const { reportId, reportDetails } = this.props
    if (
      !reportDetails ||
      reportId !== toString(reportDetails.id) ||
      !reportDetails.Tabs ||
      !reportDetails.Tabs.length
    ) {
      return // no tab details found in the reportDetails
    }

    const { dashboardLinks = {} } = reportDetails

    if (!tabDetails) {
      // selecting first tab as default tab
      tabDetails = reportDetails.Tabs[0]
    }
    const { id, name } = tabDetails
    if (
      forceReloadDashbaord ||
      // check if dashboardLink is missing for tab
      !dashboardLinks[id] ||
      dashboardLinks[id] === 'loading'
    ) {
      this.reloadDashboard({ tabId: id })
    }
    this.setState({
      currentTab: { name, id },
      stagedFilterValue: {} // Discard any existing staged values when switching tabs
    })
  }

  /** event handlers START */
  /**
   * @summary Listen to the PostMessage from periscope to adjust the rendered charts width and height.
   * @listens target.event.message target is an <iframe> containing dashboard.
   * @memberof CustomReportViewPage
   */
  listenToFrameEvent = event => {
    if (event.data.event_type === 'dashboard_resize') {
      const { dashboard_width, dashboard_height } = event.data
      const reportDimensions = {
        width: `${dashboard_width}px`,
        height: `${25 + dashboard_height}px`
      }
      /**
       * NOTE:
       * Following code assumes that event is received from iframe of currentTab.
       * This may not be true, if the user switches tab before the iframe could send message.
       * However, due to CORS issue, currently there is no way
       * to figure out which iframe actually sent the message.
       */
      const tabId = this.getCurrentTabId()
      this.updateTabState(tabId, {
        reportDimensions
      })
    }
  }

  onClickEditButton = () => {
    const { reportId, history } = this.props
    history.replace(`/admin/reports/edit/${reportId}`)
  }

  handleCheckboxFilterChange = (checkedValues, filterName, tabId) => {
    const { stagedFilterValue } = this.state
    stagedFilterValue[filterName] = checkedValues.map(item => item.value)
    this.setState({
      stagedFilterValue: { ...stagedFilterValue }
    })
  }

  handleRadioFilterChange = (changedValue, filterName, tabId) => {
    const { stagedFilterValue } = this.state
    stagedFilterValue[filterName] = [changedValue]
    this.setState({
      stagedFilterValue: { ...stagedFilterValue }
    })
  }

  handleDateFilterChange = (range = {}, name, tabId) => {
    const dateRange = {
      start: range.from,
      end: range.to
    }
    const { stagedFilterValue } = this.state
    stagedFilterValue[name] = dateRange
    this.setState({
      stagedFilterValue: { ...stagedFilterValue }
    })
  }

  handleUserDefinedFilterChange = (value, name) => {
    const { stagedFilterValue } = this.state
    stagedFilterValue[name] = value
    this.setState({
      stagedFilterValue: { ...stagedFilterValue }
    })
  }

  discardStagedFilters = () => {
    this.setState({
      stagedFilterValue: {}
    })
  }

  resetFilters = () => {
    this.setState({
      stagedFilterValue: {},
      appliedFilterValue: {}
    })
    const currentTabId = this.state.currentTab.id
    const { Tabs = [] } = this.props.reportDetails
    const outdatedTabIds = Object.keys(Tabs)
      .map(({ id }) => id)
      .filter(id => id !== currentTabId)
    this.reloadDashboard({
      tabId: currentTabId,
      selectedFilterValue: {},
      outdatedTabIds
    })
  }

  setDefaultFilterValues = () => {
    const { updateFilterDefaults } = this.props
    const { Filters: tabFilters = [] } = this.getCurrentTab()

    const { appliedFilterValue } = this.applyCurrentTabStagedValues()
    const valuesToBeDefaults = {}
    Object.keys(appliedFilterValue).forEach(key => {
      const filter = find(tabFilters, { periscopeName: key })
      if (filter.type !== 'userDefined') {
        valuesToBeDefaults[filter.id] = {
          type: filter.type,
          name: filter.periscopeName,
          value: appliedFilterValue[key]
        }
      }
    })
    updateFilterDefaults(valuesToBeDefaults)
  }

  applyFilters = () => {
    const tabId = this.getCurrentTabId()
    const { updatedFilters, appliedFilterValue } = this.applyCurrentTabStagedValues()
    // find all the tabs that needs to be updated/invalidated
    const outdatedTabIds = this.getTabsToBeUpdated(updatedFilters, tabId)
    this.reloadDashboard({
      tabId,
      selectedFilterValue: appliedFilterValue,
      outdatedTabIds
    })
  }

  applyCurrentTabStagedValues() {
    const { appliedFilterValue = {} } = this.state
    let { stagedFilterValue = {} } = this.state
    const { Filters } = this.getCurrentTab()
    const updatedFilters = []
    // move stagedFilterValues of current tab's Filters to appliedFilterValues
    Filters.map(item => item.periscopeName).forEach(name => {
      if (stagedFilterValue[name] != null) {
        appliedFilterValue[name] = stagedFilterValue[name]
        updatedFilters.push(name)
      }
    })
    // discard all staged values after apply
    stagedFilterValue = {}
    this.setState({
      appliedFilterValue: { ...appliedFilterValue },
      stagedFilterValue: { ...stagedFilterValue }
    })
    return {
      updatedFilters,
      appliedFilterValue,
      stagedFilterValue
    }
  }

  getTabsToBeUpdated(updatedFilters, updatedTabId) {
    const { Tabs } = this.props.reportDetails
    const filteredTabs = Tabs.filter(({ id, Filters }) => {
      if (id === updatedTabId) return false
      return Filters.some(({ periscopeName }) => {
        return updatedFilters.indexOf(periscopeName) > -1
      })
    }).map(({ id }) => id)
    return filteredTabs
  }

  getTabFilterIds() {
    const { Filters = [] } = this.getCurrentTab() || {}
    const filterNames = Filters.map(filter => filter.periscopeName)
    const { stagedFilterValue = {}, appliedFilterValue = {} } = this.state
    const stagedFilterNames = intersection(Object.keys(stagedFilterValue), filterNames)
    const appliedFilterNames = intersection(Object.keys(appliedFilterValue), filterNames)
    return {
      filterNames,
      stagedFilterNames,
      appliedFilterNames
    }
  }

  toggleMorefilters = event => {
    event.preventDefault()
    const tabId = this.getCurrentTabId()
    const { isMoreFiltersVisible } = this.getTabState(tabId)
    this.updateTabState(tabId, { isMoreFiltersVisible: !isMoreFiltersVisible })
  }

  backToReports = () => {
    this.props.history.replace('/admin/reports')
  }

  backToReportFolders = () => {
    const { folderId = 0 } = this.props.reportDetails
    this.props.history.push(`/admin/report/folders/${folderId}`)
  }
  /** event handlers END */

  /** render methods START */

  renderDateRangeFilter = ({ id, name, periscopeName, FilterValues: filterValues }) => {
    let minDate
    let maxDate
    let startDate
    let endDate
    let defaultStart
    let defaultEnd

    const tabId = this.getCurrentTabId()
    const { stagedFilterValue = {}, appliedFilterValue = {} } = this.state

    const selectedValue = stagedFilterValue[periscopeName] || appliedFilterValue[periscopeName] || {}

    filterValues.forEach(({ isDefault, aliasValue, value }) => {
      if (endsWith(aliasValue, '_start')) {
        if (!isDefault) {
          minDate = value
        } else {
          startDate = selectedValue.start || value
          defaultStart = value
        }
      } else if (endsWith(aliasValue, '_end')) {
        if (!isDefault) {
          maxDate = value
        } else {
          endDate = selectedValue.end || value
          defaultEnd = value
        }
      }
    })

    return (
      <DateRangeFilter
        showQuickSelectOptions
        key={id}
        id={id}
        label={name}
        name={periscopeName}
        rangeStart={minDate}
        rangeEnd={maxDate}
        startDate={startDate}
        endDate={endDate}
        defaultStartDate={defaultStart}
        defaultEndDate={defaultEnd}
        quickSelectOptions={this.getQuickSelectOptions(defaultStart, defaultEnd, minDate, maxDate)}
        handleDateSelect={(range, name) => this.handleDateFilterChange(range, name, tabId)}
        className={stagedFilterValue[periscopeName] ? 'staged' : ''}
      />
    )
  }

  renderCheckboxFilter = filter => {
    // TODO: optimize below method and CheckBoxFilter to minimize looping over values
    const tabId = this.getCurrentTabId()
    const { stagedFilterValue = {}, appliedFilterValue = {} } = this.state
    const defaultValues = this.getDefaultFilterValues(filter) || []
    let selectedValues = stagedFilterValue[filter.periscopeName] || appliedFilterValue[filter.periscopeName]
    if (!selectedValues) {
      selectedValues = defaultValues.map(defaultVal => defaultVal.value)
    }
    const selectedIds = new Set(
      filter.FilterValues.filter(item => selectedValues.includes(item.value)).map(item => item.id)
    )
    const component = (
      <CheckBoxFilter
        key={filter.id}
        label={filter.name}
        options={filter.FilterValues}
        selectedValues={selectedIds}
        defaultValues={defaultValues}
        className={stagedFilterValue[filter.periscopeName] ? 'staged' : ''}
        valueDidChange={checkedValues => {
          this.handleCheckboxFilterChange(checkedValues, filter.periscopeName, tabId)
        }}
      />
    )
    return component
  }

  renderRadioFilter = filter => {
    const tabId = this.getCurrentTabId()
    const { stagedFilterValue = {}, appliedFilterValue = {} } = this.state
    const selectedFilterValue = stagedFilterValue[filter.periscopeName] || appliedFilterValue[filter.periscopeName]
    const defaultValue = this.getDefaultFilterValues(filter).length ? this.getDefaultFilterValues(filter)[0] : null
    const selectedValue =
      selectedFilterValue && selectedFilterValue.length
        ? // eslint-disable-next-line
          filter.FilterValues[
            findIndex(filter.FilterValues, {
              value: selectedFilterValue[0]
            })
          ]
        : defaultValue

    return (
      <RadioFilter
        options={filter.FilterValues}
        valueDidChange={changedValue => {
          this.handleRadioFilterChange(changedValue, filter.periscopeName, tabId)
        }}
        key={filter.id}
        id={filter.id}
        label={filter.name}
        selectedValue={selectedValue}
        className={cx({
          staged: stagedFilterValue[filter.periscopeName]
        })}
      />
    )
  }

  renderMoreFilters = () => {
    const { Filters: tabFilters } = this.getCurrentTab() || {}
    const { stagedFilterValue = {}, appliedFilterValue = {} } = this.state
    const remainingFilters = tabFilters
      ? tabFilters.filter(filter => {
          return filter.type === 'userDefined'
        })
      : []
    const filterInputs = remainingFilters.map(({ name, periscopeName }) => {
      const selectedFilterValue =
        stagedFilterValue[periscopeName] == null ? appliedFilterValue[periscopeName] : stagedFilterValue[periscopeName]
      return (
        <InputBox
          key={name}
          clearButton
          placeholder={`Search for ${name}`}
          value={selectedFilterValue || ''}
          iconName={<SearchIcon width={20} height={20} style={{ marginLeft: '-6px' }} />}
          didClickClearButton={() => this.handleUserDefinedFilterChange('', periscopeName)}
          textDidChange={value => this.handleUserDefinedFilterChange(value, periscopeName)}
          className={cx('userDefinedBox', {
            staged: stagedFilterValue[periscopeName] != null
          })}
        />
      )
    })
    return filterInputs
  }

  renderFilterComponents = () => {
    const tabDetails = this.getCurrentTab()
    if (!tabDetails) return []
    const sortedFilters = this.sortFilters(tabDetails.Filters)
    const filterComps = []
    let zIndex = sortedFilters.length
    const renderers = {
      radio: this.renderRadioFilter,
      checkbox: this.renderCheckboxFilter,
      dateRange: this.renderDateRangeFilter
    }
    sortedFilters.forEach(filter => {
      const renderer = renderers[filter.type]
      if (renderer) {
        filterComps.push(
          <span key={filter.id} style={{ position: 'relative', zIndex: zIndex-- }}>
            {renderer(filter)}
          </span>
        )
      }
    })
    return filterComps
  }

  render() {
    // do not have access to reports page
    if (!this.isProductAuthorized()) {
      return null
    }

    // report data is loading
    if (this.props.loading) {
      return <Loader />
    }

    const { reportDetails } = this.props
    const { dashboardLinks = {} } = this.props.reportDetails

    // report data is missing
    if (!reportDetails || !reportDetails.id) {
      return null
    }

    const currentTab = this.getCurrentTab() || {}
    const { reportDimensions, isMoreFiltersVisible } = this.getTabState(currentTab.id)
    const { appliedFilterNames, stagedFilterNames } = this.getTabFilterIds()

    return (
      <DocumentTitle title={reportDetails && reportDetails.name ? `${appName} | ${reportDetails.name}` : appName}>
        <div className={'page-container ctm-reports-view-page'}>
          <Row>
            <Col sm={12}>
              {this.isAdminPage() && (
                <div className={'pull-right'}>
                  <button className={'btn_custom_secondary btn_back'} type="button" onClick={this.backToReportFolders}>
                    <Glyphicon glyph="menu-left" /> Folders
                  </button>
                  <button className={'btn_custom_secondary btn_back'} type="button" onClick={this.backToReports}>
                    <Glyphicon glyph="menu-left" /> Reports
                  </button>
                  <button className={'btn_custom'} type="button" onClick={this.onClickEditButton}>
                    Edit
                  </button>
                </div>
              )}
              <div className="report-title MB30">{reportDetails.name}</div>
            </Col>
          </Row>

          <Row>
            <Col md={12} lg={12}>
              <div>
                <FlatMenuList
                  className="dropDownButton--header"
                  options={reportDetails.Tabs}
                  selectedTab={currentTab.id}
                  valueDidChange={this.loadTab}
                  reportName={reportDetails.name}
                />
              </div>

              <div className="flex">
                <div className="report-filter-list">{this.renderFilterComponents()}</div>
              </div>
            </Col>
          </Row>

          {isMoreFiltersVisible && this.renderMoreFilters()}

          <Row>
            <Col>
              {this.hasMoreFilters(currentTab.Filters) && (
                <div className="flex more-filters">
                  <span className="actionButton PL2 linkButton" onClick={this.toggleMorefilters}>
                    {isMoreFiltersVisible ? 'Fewer Filters' : 'More Filters'}
                    {isMoreFiltersVisible && <span className="glyphicon glyphicon-chevron-up ML5" />}
                    {!isMoreFiltersVisible && <span className="glyphicon glyphicon-chevron-down ML5" />}
                  </span>
                </div>
              )}

              <ReportButtons
                currentTab={currentTab}
                applyFilters={this.applyFilters}
                resetFilters={this.resetFilters}
                stagedFilterNames={stagedFilterNames}
                appliedFilterNames={appliedFilterNames}
                discardStagedFilters={this.discardStagedFilters}
                setDefaultFilterValues={this.setDefaultFilterValues}
              />
            </Col>
          </Row>

          {reportDetails.Tabs &&
            reportDetails.Tabs.map(({ id: tabId, name }) => (
              <div
                className="periscope-report-wrapper"
                key={tabId}
                style={{
                  height: reportDimensions.height || '575px',
                  display: toString(tabId) === toString(currentTab.id) ? 'block' : 'none'
                }}
              >
                <EmbeddedRenderer
                  sourceName={name}
                  url={dashboardLinks[tabId] ? dashboardLinks[tabId].url : null}
                  iframeHeight={reportDimensions.height || '575px'}
                  fullscreen
                />
              </div>
            ))}
        </div>
      </DocumentTitle>
    )
  }
  /** render methods END */
}

CustomReportViewPage.propTypes = {
  location: PropTypes.object,
  loading: PropTypes.bool,
  history: PropTypes.object,
  user: PropTypes.object,
  reportDetails: PropTypes.object.isRequired,
  fetchReportDetails: PropTypes.func.isRequired,
  updateFilterDefaults: PropTypes.func,
  reportId: PropTypes.string,
  createDashboardURL: PropTypes.func,
  periscopeURL: PropTypes.string
}

CustomReportViewPage.defaultProps = {
  reportDimensions: { width: '100%', height: '100%' }
}

function mapStateToProps({ reports, session }, props) {
  const { loading, reportDetails, periscopeURL } = reports
  return {
    reportId: get(props, ['match', 'params', 'id']),
    reportDetails,
    periscopeURL,
    loading,
    user: session.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchReportDetails: payload => dispatch(fetchReportDetails({ payload })),
    updateFilterDefaults: payload => dispatch(updateFilterDefaults({ payload })),
    createDashboardURL: (reportId, tabId, data, outdatedTabIds) =>
      dispatch(createDashboardURL(reportId, tabId, data, outdatedTabIds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomReportViewPage)
