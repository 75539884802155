// @flow
export const LOGIN = 'LOGIN'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_FAILED = 'LOGOUT_FAILED'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const PROFILE = 'PROFILE'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_FAILED = 'PROFILE_FAILED'
export const VERIFY_ONBOARD_TOKEN = 'VERIFY_ONBOARD_TOKEN'
export const VERIFY_ONBOARD_TOKEN_STARTED = 'VERIFY_ONBOARD_TOKEN_STARTED'
export const VERIFY_ONBOARD_TOKEN_FAILED = 'VERIFY_ONBOARD_TOKEN_FAILED'
export const RESEND_EXPIRED_ONBOARD_EMAIL = 'RESEND_EXPIRED_ONBOARD_EMAIL'
export const RESENDING_ONBOARD_EMAIL_STARTED = 'RESENDING_ONBOARD_EMAIL_STARTED'
export const RESENDING_ONBOARD_EMAIL_DONE = 'RESENDING_ONBOARD_EMAIL_DONE'
export const RESET_PASSWORD = 'RESET_PASSWORD'

export function login(payload: Object): Object {
  return {
    type: LOGIN,
    payload
  }
}

export function loginFailed(payload: Object): Object {
  return {
    type: LOGIN_FAILED,
    payload
  }
}

export function loginSuccess(payload: Object): Object {
  return {
    type: LOGIN_SUCCESS,
    payload
  }
}

export function profile(): Object {
  return {
    type: PROFILE
  }
}

export function profileSuccess(payload: Object): Object {
  return {
    type: PROFILE_SUCCESS,
    payload
  }
}

export function profileFailed(payload: Object): Object {
  return {
    type: PROFILE_FAILED,
    payload
  }
}

export function logout(skipRedirect = false): Object {
  return {
    type: LOGOUT,
    skipRedirect
  }
}

export function logoutFailed(payload: Object): Object {
  return {
    type: LOGOUT_FAILED,
    payload
  }
}

export function logoutSuccess(): Object {
  return {
    type: LOGOUT_SUCCESS
  }
}

export function verifyOnboardingToken(payload: Object): Object {
  return {
    type: VERIFY_ONBOARD_TOKEN,
    payload
  }
}
export function verifyOnboardingTokenStarted(): Object {
  return {
    type: VERIFY_ONBOARD_TOKEN_STARTED
  }
}

export function verifyOnboardingTokenFailed(payload: Object): Object {
  return {
    type: VERIFY_ONBOARD_TOKEN_FAILED,
    payload
  }
}

export function resendExpiredOnboardEmail(payload: Object): Object {
  return {
    type: RESEND_EXPIRED_ONBOARD_EMAIL,
    payload
  }
}

export function resetPassword(payload: Object): Object {
  return {
    type: RESET_PASSWORD,
    payload
  }
}
