import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { registerBeacon } from '../../../actions/walkthrough-actions'

import './index.scss'

class ReportButtons extends Component {
  componentDidMount() {
    if (this.props.walkthroughEnabled) {
      this.props.registerBeacon(this.inputBoxWrapper)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.walkthroughEnabled && prevProps.lastUpdate !== this.props.lastUpdate) {
      this.props.registerBeacon(this.inputBoxWrapper)
    }
  }

  render() {
    const { currentTab, stagedFilterNames, appliedFilterNames } = this.props

    if (currentTab.Filters && currentTab.Filters.length > 0) {
      return (
        <div
          className="flex justifyContentCenter alignItemsCenter filter-actions-container"
          ref={el => {
            this.inputBoxWrapper = el
          }}
        >
          {stagedFilterNames.length > 0 && (
            <span className="linkButton" onClick={this.props.discardStagedFilters}>
              Cancel
            </span>
          )}
          {appliedFilterNames.length > 0 && (
            <span id="resetButton" className="linkButton" onClick={this.props.resetFilters}>
              Reset
            </span>
          )}
          <button
            disabled={appliedFilterNames.length === 0 && stagedFilterNames.length === 0}
            className={'custom-report-buttons'}
            onClick={this.props.setDefaultFilterValues}
          >
            Set as default
          </button>

          <button
            disabled={stagedFilterNames.length === 0}
            className={'custom-report-buttons'}
            type="button"
            onClick={this.props.applyFilters}
          >
            Apply
          </button>
        </div>
      )
    }
    return <div />
  }
}

ReportButtons.propTypes = {
  currentTab: PropTypes.object,
  applyFilters: PropTypes.func,
  resetFilters: PropTypes.func,
  stagedFilterNames: PropTypes.array,
  appliedFilterNames: PropTypes.array,
  discardStagedFilters: PropTypes.func,
  setDefaultFilterValues: PropTypes.func,
  registerBeacon: PropTypes.func.isRequired,
  walkthroughEnabled: PropTypes.bool.isRequired,
  lastUpdate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
}

// connect

const mapStateToProps = ({ walkthrough }) => ({
  lastUpdate: (walkthrough && walkthrough.lastUpdate) || '',
  walkthroughEnabled: (walkthrough && walkthrough.isEnabled) || false
})

const mapDispatchToProp = dispatch => ({
  registerBeacon: beaconRef => dispatch(registerBeacon('periscope-actions', beaconRef))
})

export default connect(mapStateToProps, mapDispatchToProp)(ReportButtons)
