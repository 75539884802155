import constants from 'constants/constants'

const { aggregations } = constants

export const METRIC_GRAPTH = {
  DICTIONARY: 'dictionary',
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
  MISBRAND: 'misbrand',
  INCLUDE_COVERAGE: 'include_coverage'
}

export const QC_QUERY_TYPE = {
  INCLUDE: 'include',
  EXCLUDE: 'exclude',
  MISBRAND: 'misbrand'
}

export const METRIC_STORE_KEY = {
  DICTIONARY_COVERAGE: aggregations.DICTIONARY_COVERAGE,
  INCLUDE: aggregations.QC_MONITORING_INCLUDE,
  EXCLUDE: aggregations.QC_MONITORING_EXCLUDE,
  MISBRAND: 'qc_monitoring_misbrand',
  INCLUDE_COVERAGE: 'qc_monitoring_include_coverage'
}

export const getGraphOptions = graphType => {
  switch (graphType) {
    case METRIC_GRAPTH.INCLUDE:
      return {
        queryType: QC_QUERY_TYPE.INCLUDE,
        storeKey: METRIC_STORE_KEY.INCLUDE,
        aggregation: aggregations.QC_MONITORING_INCLUDE,
        type: QC_QUERY_TYPE.INCLUDE //  server QC_MONITORING_TYPE
      }
    case METRIC_GRAPTH.EXCLUDE:
      return {
        queryType: QC_QUERY_TYPE.EXCLUDE,
        storeKey: METRIC_STORE_KEY.EXCLUDE,
        aggregation: aggregations.QC_MONITORING_EXCLUDE,
        type: QC_QUERY_TYPE.EXCLUDE // server QC_MONITORING_TYPE
      }
    case METRIC_GRAPTH.MISBRAND:
      return {
        queryType: QC_QUERY_TYPE.MISBRAND,
        storeKey: METRIC_STORE_KEY.MISBRAND,
        aggregation: aggregations.QC_MONITORING_EXCLUDE,
        type: QC_QUERY_TYPE.EXCLUDE //  server QC_MONITORING_TYPE
      }
    case METRIC_GRAPTH.INCLUDE_COVERAGE:
      return {
        queryType: QC_QUERY_TYPE.INCLUDE,
        storeKey: METRIC_STORE_KEY.INCLUDE_COVERAGE,
        aggregation: aggregations.QC_MONITORING_EXCLUDE,
        type: QC_QUERY_TYPE.EXCLUDE // server QC_MONITORING_TYPE
      }

    default:
      throw new Error(`Unknown graphType: ${graphType}`)
  }
}
