import axios from 'axios'
import moment from 'moment'
import { takeLatest, call, put, fork, select } from 'redux-saga/effects'

import config from 'config/index.js'
import createDownloadLink from 'utils/download-link'
import { DOWNLOAD_DATETIME_FORMAT } from 'constants/constants'

import categoryTestsActions from './categoryTestsActions'

import { createAlert } from 'actions/app-actions'

import { CATEGORY_TEST_STORE_PATH } from './categoryTestsReducers'

function* fetchCategoryTests({ payload = {} }) {
  try {
    const state = yield select(state => state[CATEGORY_TEST_STORE_PATH])

    const {
      page = state.page,
      sortBy = state.sortBy,
      pageSize = state.pageSize,
      sortOrder = state.sortOrder,
      filters = state.filters
    } = payload

    // save rules table settings in store
    yield put(
      categoryTestsActions.updateSettings({
        page,
        sortBy,
        pageSize,
        sortOrder,
        filters
      })
    )

    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/category-tests`, {
        params: {
          page,
          sortBy,
          pageSize,
          sortOrder,
          filters
        }
      })
    )
    yield put(categoryTestsActions.successLoad(data))
  } catch (error) {
    yield put(categoryTestsActions.failedLoad())
    window.captureException(error)
    yield put(createAlert('danger', error.message, `Category Tests fetch failed`))
  }
}

// DOWNLOAD
function* downloadCategoryTests() {
  try {
    yield put(createAlert('success', '', 'Preparing CSV for download'))

    const { sortBy, sortOrder, filters } = yield select(state => state[CATEGORY_TEST_STORE_PATH])

    const response = yield call(() =>
      axios({
        url: `api/category-tests/download`,
        method: 'get',
        responseType: 'blob',
        params: {
          sortBy,
          sortOrder,
          filters
        }
      })
    )

    createDownloadLink(response.data, `category-tests-${moment().format(DOWNLOAD_DATETIME_FORMAT)}.csv`)
  } catch (error) {
    yield put(createAlert('danger', error.message, 'Category Tests CSV download failed'))
  }
}

// SAVE METHOD
function* saveCategoryTest({ payload }) {
  try {
    yield call(() => axios.post(`${config.apiBaseUrl}/category-tests`, { payload }))

    yield put(createAlert('success', '', `Category Test saved`))

    yield put(categoryTestsActions.fetch())
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data.message || error.message, `Category Test saving failed`))
  }
}

// Run tests
function* runCategoryTest({ payload }) {
  try {
    yield call(() => axios.post(`${config.apiBaseUrl}/category-tests-run`, { payload }))

    yield put(createAlert('success', '', `Category Test were run`))

    yield put(categoryTestsActions.fetch())
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data.message || error.message, `Category Test running failed`))
  }
}

// Global configuration
function* fetchCategoryTestGlobalConfig({ payload = {} }) {
  try {
    const { data } = yield call(() => axios.get(`${config.apiBaseUrl}/category-tests/global-config`))
    yield put(categoryTestsActions.successLoadGlobalConfig(data))
  } catch (error) {
    yield put(categoryTestsActions.failedLoadGlobalConfig({ error: error.message }))
    window.captureException(error)
    yield put(createAlert('danger', error.message, `Category Test Global configuration fetch failed`))
  }
}

function* saveCategoryTestGlobalConfig({ payload }) {
  try {
    yield call(() => axios.post(`${config.apiBaseUrl}/category-tests/global-config`, { payload }))

    yield put(createAlert('success', '', `Category Test Global Config saved`))

    yield put(categoryTestsActions.fetch())
  } catch (error) {
    window.captureException(error)
    yield put(
      createAlert('danger', error.response.data.message || error.message, `Category Test Global Config saving failed`)
    )
  }
}

/*
 * FLOWS
 */
function* categoryTestsFlows() {
  yield takeLatest(categoryTestsActions.fetch.type, fetchCategoryTests)
  yield takeLatest(categoryTestsActions.save.type, saveCategoryTest)
  yield takeLatest(categoryTestsActions.runTests.type, runCategoryTest)

  yield takeLatest(categoryTestsActions.download.type, downloadCategoryTests)

  // Global configuration
  yield takeLatest(categoryTestsActions.fetchGlobalConfig.type, fetchCategoryTestGlobalConfig)
  yield takeLatest(categoryTestsActions.saveGlobalConfig.type, saveCategoryTestGlobalConfig)
}

export default [fork(categoryTestsFlows)]
