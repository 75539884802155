import { FETCH_MERCHANTS_SUCCESS, FETCH_MERCHANTS_FAILED } from '../actions/merchant-actions'

import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialState = {}

const merchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MERCHANTS_SUCCESS:
      if (state.data) {
        return { data: { ...state.data, ...action.payload } }
      } else {
        return { data: action.payload }
      }

    case FETCH_MERCHANTS_FAILED:
      return { error: action.error }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default merchantReducer
