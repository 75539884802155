import { createReducer } from '@reduxjs/toolkit'

import CategoryGuidelineActions from './actions'

const initialState = {
  loading: false,
  page: 1,
  pageSize: 20,
  sortBy: 'id',
  sortOrder: 'asc',
  filters: {
    isEdited: {
      value: '1' // it means true :)
    },
    isActive: {
      value: '1'
    }
  },
  data: {
    count: 0,
    rows: []
  }
}

const generalReducers = {
  updateSettings: (state, action) => {
    return {
      ...state,
      ...action.payload
    }
  },
  clearFilters: state => {
    state.filters = {}
    state.sortBy = initialState.sortBy
    state.sortOrder = initialState.sortOrder
  },
  fetchStart: state => {
    state.loading = true
  },
  successLoad: (state, action) => {
    state.data.count = action.payload.count
    state.data.rows = action.payload.rows
    state.loading = false
  },
  failedLoad: state => {
    state.loading = false
  }
}

const CategoryGuidelineReducers = createReducer(initialState, builder => {
  builder
    .addCase(CategoryGuidelineActions.fetch.type, generalReducers.fetchStart)
    .addCase(CategoryGuidelineActions.successLoad.type, generalReducers.successLoad)
    .addCase(CategoryGuidelineActions.failedLoad.type, generalReducers.failedLoad)
    .addCase(CategoryGuidelineActions.updateSettings.type, generalReducers.updateSettings)
    .addCase(CategoryGuidelineActions.clearFilters.type, generalReducers.clearFilters)
})

export default CategoryGuidelineReducers
