// @flow
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { reduxForm, Field, registerField } from 'redux-form'
import { OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import DocumentTitle from 'react-document-title'
import cx from 'classnames'
import './index.scss'

// components
import Toggle from '../Toggle'
import Loader from '../Loader'
import GroupLogo from '../GroupLogo'
import FormSelect from '../FormSelect'
import ButtonField from './ButtonField'
import ProductTable from '../ProductTable/ProductTable'
import PermissionTable from '../PermissionTable'
import ReportsPanel from '../PanelProduct/ReportPanel'
import groupDeleteModal from '../Modals/GroupDeleteModal'

// actions
import {
  fetchGroup,
  saveGroup,
  saveGroupPermissions,
  fetchGroupPermissions,
  deleteUserGroup,
  fetchGroups
} from '../../actions/group-actions'

import { updateSession, fetchPermissions } from '../../actions/app-actions'
import { fetchAllReports } from '../../actions/custom-report-actions'
import { appName } from '../../constants/constants'
import { isPasswordValid, PASSWORD_VALIDATION_MESSAGE } from '../../utils/auth-helper'

// services
import { UserService, GroupService } from '../../services'

const escapeFolderName = name => (name ? name.replace(/[^\w]/gi, '').toLowerCase() : '')
const getS3BucketSuffix = id => {
  return `partner-${id || 'new'}`
}
const MAX_LENGTH_IDF_ADDITIONAL_EMAILS = 2000

class renderGroupField extends Component {
  static propTypes = {
    input: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.string,
    meta: PropTypes.object,
    placeholder: PropTypes.string
  }

  render() {
    const {
      input,
      label,
      type,
      placeholder,
      meta: { touched, dirty, error }
    } = this.props
    return (
      <div>
        {(() => {
          if (type === 'textarea') {
            return <textarea className={'input'} {...input} placeholder={placeholder || label} />
          } else {
            return <input className={'input'} {...input} placeholder={placeholder || label} type={type} />
          }
        })()}
        {(dirty || touched) && error && <span className={'formError'}>{error}</span>}
      </div>
    )
  }
}

// eslint-disable-next-line
const renderRadio = ({ name, label, currentValue, defaultValue, handleChange, meta: { touched, dirty, error } }) => (
  <label>
    <input
      type={'radio'}
      name={name}
      value={defaultValue}
      checked={currentValue === defaultValue}
      onChange={e => {
        handleChange(e.target.value)
      }}
    />
    <span> {label} </span>
  </label>
)

class GroupEdit extends Component {
  constructor(props) {
    super(props)
    this.userProducts = []
    this.trackedGroupPermissions = {}
    this.state = {
      idfAdditionalEmailsLength: 0,
      selectedReports: { '0': {} },
      originalSelectedReports: {},
      showAdvancedFeedOptions: false
    }
  }

  componentDidMount() {
    // Cannot edit/create a group if not a portal admin
    if (!this.props.user || !Object.keys(this.props.user).length) {
      return
    }

    if (!UserService.isPortalAdmin(this.props.user)) {
      this.props.history.replace('/')
    }

    // Only load group if :id != new
    if (this.props.match.params.id !== 'new') {
      this.props.fetchGroup(this.props.match.params.id)
      this.props.fetchGroupPermissions(this.props.match.params.id)
    }

    const { reports, fetchAllReports } = this.props
    if (reports.allReports.length === 0 || reports.allReports.length !== reports.groupReportsCount) {
      fetchAllReports()
    }
    this.props.fetchPermissions(0, 10)
  }

  componentDidUpdate(prevProps, prevState) {
    const newGroup = this.props.group
    const prevGroup = prevProps.group
    if (prevGroup.id !== newGroup.id) {
      this.props.initialize(newGroup)
      this.setState({
        ...prevState,
        feedsEnabled: newGroup.feedsEnabled ? '1' : '0',
        ObfuscationTypeId: newGroup.ObfuscationTypeId ? newGroup.ObfuscationTypeId : '1',
        idfAdditionalEmailsLength: newGroup.idfAdditionalEmails ? newGroup.idfAdditionalEmails.length : 0
      })
      this.updateSelectedReports(newGroup.Reports)
    }
  }

  componentWillUnmount() {
    this.props.resetGroup()
    this.trackedGroupPermissions = {}
  }

  registerProducts(products) {
    this.userProducts = products
  }

  trackPermissionChange(newPermission) {
    this.trackedGroupPermissions = {
      ...this.trackedGroupPermissions,
      ...newPermission
    }
  }

  getReportsData() {
    return (
      <ReportsPanel
        id={0}
        report={this.props.reports.allReports}
        selectedReports={this.state.selectedReports}
        onChangeReports={this.handleChangeReports}
        onClickReport={() => false}
        disabledSelected={false}
      />
    )
  }

  updateSelectedReports = reports => {
    const { selectedReports } = this.state
    if (this.props.match.params.id !== 'new') {
      Object.values(reports).forEach(report => {
        if (!selectedReports['0']) {
          selectedReports['0'] = {}
        }
        selectedReports['0'][report.id] = true
      })
    }
    this.setState({
      selectedReports,
      originalSelectedReports: JSON.parse(JSON.stringify(selectedReports))
    })
  }

  handleSubmit = data => {
    const updatedData = Object.keys(data).reduce((acc, key) => {
      if (this.props.group[key] !== data[key]) {
        acc[key] = data[key]
      }
      return acc
    }, {})
    updatedData.id = this.props.group.id
    if (!updatedData.id) {
      updatedData.ObfuscationTypeId = '1'
    }

    if (!updatedData.id && !updatedData.feedSftpFolderName) {
      updatedData.feedSftpFolderName = escapeFolderName(updatedData.name)
    } else if (updatedData.feedSftpFolderName) {
      updatedData.feedSftpFolderName = escapeFolderName(updatedData.feedSftpFolderName)
    }

    updatedData.feedsEnabled = this.state.feedsEnabled || false
    updatedData.feedsFilePublicEncryptionKey = data.feedsFilePublicEncryptionKey
    updatedData.reports = Object.keys(this.state.selectedReports['0'])

    if (updatedData.s3Bucket && !updatedData.s3KmsKeyId) {
      updatedData.s3KmsKeyId = this.props.group.s3KmsKeyId
    }
    this.props.saveGroup(
      {
        groupData: updatedData,
        isDeliverInfo: false,
        s3Region: data.s3Region,
        s3AccessKeyId: data.s3AccessKeyId,
        s3SecretAccessKey: data.s3SecretAccessKey,
        s3Bucket: data.s3Bucket,
        idfAdditionalEmails: data.idfAdditionalEmails,
        userProducts: this.userProducts
      },
      () => this.props.history.push('/admin/groups')
    )
  }

  /**
   * Activates/Deactivates a group
   */
  changeStatus = () => {
    this.props.saveGroup({
      groupData: { isActive: !this.props.group.isActive, id: this.props.group.id }
    })
  }

  haveGroupReportsChanged = () => {
    return !isEqual(this.state.selectedReports, this.state.originalSelectedReports)
  }

  haveObfuscationTypeChanged = () => {
    const defaultObf = this.props.group.ObfuscationTypeId ? this.props.group.ObfuscationTypeId : '1'
    return !isEqual(this.state.ObfuscationTypeId, defaultObf)
  }

  clickFeedRadio = ev => {
    const input = ev.target
    if (input.value === '0') {
      this.props.change('feedsFilePublicEncryptionKey', null)
    }
    this.setState({
      ...this.state,
      feedsEnabled: input.value === '1' ? '1' : '0',
      clicked: !this.state.clicked
    })
  }

  getSeatsTakenString = () => {
    const seatsTaken = GroupService.getSeatsTaken(this.props.group)
    return `${seatsTaken}/${this.props.group.seatCount} users`
  }

  toggleAdvancedFeed = () => {
    this.setState({
      showAdvancedFeedOptions: !this.state.showAdvancedFeedOptions
    })
  }

  renderToggle = ({ input, isActive, text }) => {
    return <Toggle {...input} isActive={isActive} text={text} isStateful onChange={value => input.onChange(value)} />
  }

  handleChangeReports = (reportId, reports) => {
    this.setState({
      ...this.state,
      selectedReports: { ...this.state.selectedReports, 0: reports }
    })
  }

  deleteGroup = (id, name) =>
    this.props.groupDeleteModal(name, () =>
      this.props.deleteUserGroup({ id }, () => {
        this.props.fetchGroups()
        this.props.history.replace('/admin/groups')
      })
    )

  handleChangeIdfAdditionalEmails = event => {
    const value = event.target.value
    const length = value ? value.length : 0
    if (length > MAX_LENGTH_IDF_ADDITIONAL_EMAILS) {
      event.preventDefault() // doesn't allow entering more than max value
    } else {
      this.setState({
        idfAdditionalEmailsLength: length
      })
    }
  }

  passwordRules = () => {
    return (
      <Tooltip id="ftps-password-format-tooltip">
        <div>
          At least 8 characters in length
          <br />
          Contains at least 3 of the following 4 types of characters:
          <br />
          Lower case letters (a-z)
          <br />
          Upper case letters (A-Z)
          <br />
          Numbers (i.e. 0-9)
          <br />
          Special characters (e.g. !@#$%^&*)
        </div>
      </Tooltip>
    )
  }

  render() {
    const {
      valid,
      dirty,
      loading,
      handleSubmit,
      newDocumentTitle,
      editDocumentTitle,
      match: { params }
    } = this.props

    if (loading) {
      return <Loader />
    }

    return (
      <DocumentTitle
        title={
          newDocumentTitle && editDocumentTitle
            ? `${appName} | ${params.id === 'new' ? newDocumentTitle : editDocumentTitle}`
            : appName
        }
      >
        <React.Fragment>
          {this.props.group.id && (
            <div className={'greyHeader'}>
              <div className={cx('clearfix')}>
                <div className={'logo'}>
                  <GroupLogo altImg="group_logo" />
                </div>
                <div className={'rightFormButton'}>
                  {!this.props.group.isPortalGroup && (
                    <div
                      onClick={this.changeStatus}
                      className={cx('activeBadge', this.props.group.isActive ? 'active' : 'inactive')}
                    >
                      <span />
                      {this.props.group.isActive ? 'Active' : 'Inactive'}
                    </div>
                  )}
                </div>
                <div className={'title'}>{this.props.group.name}</div>
                <div className={'subtitle'}>{this.getSeatsTakenString()}</div>
              </div>
            </div>
          )}
          <div className={'container'}>
            <div className={'content groupEditForm'}>
              <form onSubmit={handleSubmit(this.handleSubmit)}>
                <div className={'clearfix'}>
                  <span className={'formTitle'}>
                    {this.props.match.params.id === 'new' ? 'Create Group' : 'Edit Group'}
                  </span>

                  <div className={'rightFormButton'}>
                    <Link className={'btn_custom_secondary'} to="/admin/groups">
                      Cancel
                    </Link>

                    {this.props.group.id && !this.props.group.isPortalGroup && (
                      <>
                        <button
                          type="button"
                          className={'btn_custom_red'}
                          onClick={() => this.deleteGroup(this.props.match.params.id, this.props.group.name)}
                        >
                          Delete Group
                        </button>
                        <button type="button" className={'btn_custom'} onClick={this.changeStatus}>
                          {this.props.group.isActive ? 'Deactivate' : 'Activate'}
                        </button>
                      </>
                    )}
                    <button
                      disabled={
                        (!dirty && !this.haveObfuscationTypeChanged() && !this.haveGroupReportsChanged()) || !valid
                      }
                      className={'btn_custom'}
                      type="submit"
                    >
                      {this.props.match.params.id === 'new' ? 'Create' : 'Save Changes'}
                    </button>
                  </div>
                </div>

                <h3>BASIC INFORMATION</h3>

                <table id={'basicInfo'}>
                  <tbody>
                    <tr>
                      <td className={'required'}>Group Name</td>
                      <td>
                        <Field name="name" component={renderGroupField} type="text" />
                      </td>
                    </tr>
                    <tr>
                      <td className={'required'}>Number of Seats</td>
                      <td>
                        <Field name="seatCount" component={renderGroupField} type="text" />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h3>DATA FEEDS</h3>
                <Field
                  name="activeDataFeed"
                  type="text"
                  title={'Eligibility'}
                  onChange={this.clickFeedRadio}
                  checked={typeof this.state.feedsEnabled === 'undefined' ? false : this.state.feedsEnabled === '1'}
                  trueString="Yes"
                  falseString="No"
                  component={ButtonField}
                />
                {this.state.feedsEnabled === '1' && (
                  <div>
                    <br />
                    <span className="group-note">
                      FTPS is the default delivery option. If S3 delivery fields are entered, files will be delivered
                      via S3 using KMS encryption. <br />
                      If both FTPS and S3 sections are filled out, files will be delivered to S3.
                    </span>
                    <br />
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <span className="feed-advanced-header">FTPS delivery</span>
                            &nbsp;
                          </td>
                          <td>(leave blank if no FTPS delivery is required)</td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="feedSftpConfigContainer">
                      <tbody>
                        <tr>
                          <td>FTPS username</td>
                          <td>
                            <Field
                              name={'feedSftpUsername'}
                              component={renderGroupField}
                              type={'text'}
                              onChange={this.updatefeedSftpDetails}
                              placeholder={'Enter SFTP Username'}
                              required={this.state.feedsEnabled === '1'}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span>FTPS password</span>
                            <OverlayTrigger placement="right" overlay={this.passwordRules()}>
                              <Glyphicon style={{ marginLeft: '10px' }} className="" glyph="info-sign" />
                            </OverlayTrigger>
                          </td>
                          <td>
                            <Field
                              name={'feedSftpPassword'}
                              component={renderGroupField}
                              type={'password'}
                              placeholder={'Enter SFTP Password'}
                              required={this.state.feedsEnabled === '1'}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>FTPS folder name</td>
                          <td>
                            <Field
                              name={'feedSftpFolderName'}
                              component={renderGroupField}
                              type={'text'}
                              placeholder={'Enter SFTP Folder Name'}
                              value={''}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table id={'publicKey'}>
                      <tbody>
                        <tr>
                          <td className={'required'}>GPG public key</td>
                          <td>
                            <Field name="feedsFilePublicEncryptionKey" component={renderGroupField} type="textarea" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <span className="feed-advanced-header">S3 delivery</span>
                            &nbsp;
                          </td>
                          <td>(leave blank if no S3 delivery is required)</td>
                        </tr>
                        <tr>
                          <td>S3 bucket</td>
                          <td>
                            <Field
                              isRequired
                              name={'s3Bucket'}
                              component={renderGroupField}
                              type={'text'}
                              placeholder={'vpc-intel-staging-search'}
                            />
                          </td>
                          <td className="font-14">
                            <span>{`/${getS3BucketSuffix(this.props.group.id)}`}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {
                      <table>
                        <tbody>
                          <tr>
                            <td>S3 KMS key id</td>
                            <td>
                              <Field
                                isRequired
                                name={'s3KmsKeyId'}
                                component={renderGroupField}
                                type={'text'}
                                placeholder={'Enter KMS Key ID'}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    }
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <span className="feed-advanced-header">Notifications</span>
                              &nbsp;
                            </td>
                            <td>(leave blank if no notifications are required after feeds are delivered)</td>
                          </tr>
                          <tr>
                            <td>
                              <OverlayTrigger
                                placement="left"
                                overlay={
                                  <Tooltip id="feed-emails-tooltip">
                                    {
                                      'A semicolumn-separated list that define who must be additionaly notified that IDF files have been delivered. ex: tom@aol.com;alex@goslice.com'
                                    }
                                  </Tooltip>
                                }
                              >
                                <label className="FeedEditPage__toggle_label">Email list</label>
                              </OverlayTrigger>
                            </td>
                            <td>
                              <Field
                                name={'idfAdditionalEmails'}
                                component={renderGroupField}
                                type={'textarea'}
                                placeholder={'Emails separated with semicolons'}
                                onChange={this.handleChangeIdfAdditionalEmails.bind(this)}
                                value={''}
                              />
                              <span>{`Using ${this.state.idfAdditionalEmailsLength} out of ${MAX_LENGTH_IDF_ADDITIONAL_EMAILS} available characters.`}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <span className="feed-advanced-header">Advanced options</span>
                              &nbsp;
                            </td>
                            <td>
                              <span className="btn_link" onClick={this.toggleAdvancedFeed.bind(this)}>
                                {this.state.showAdvancedFeedOptions ? 'Hide' : 'Show'}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {this.state.showAdvancedFeedOptions && (
                      <div>
                        <div className="feedSelectContainer">
                          <OverlayTrigger
                            placement="left"
                            overlay={
                              <Tooltip id="feed-concatenate-tooltip">
                                {`If enabled, delivered files for this client will be concatenated before encryption.
                                Smaller increments can be concatenated but for larger files (firehoses), it may be useful to not concatenate files and deliver them in parts.`}
                              </Tooltip>
                            }
                          >
                            <label className="FeedEditPage__toggle_label">Concatenate files</label>
                          </OverlayTrigger>
                          <div className="FeedEditPage__toggle toggleContainer">
                            <Field
                              name="concatenateFeedFiles"
                              isActive={this.props.group.concatenateFeedFiles}
                              component={this.renderToggle}
                              text={'enable'}
                            />
                          </div>
                        </div>
                        <div className="feedSelectContainer">
                          <FormSelect
                            label="Obfuscation type id"
                            isRequired
                            fieldName="ObfuscationTypeId"
                            placeholder="Select obfuscation type"
                            options={[
                              { value: '1', label: ' 1' },
                              { value: '2', label: ' 2' }
                            ]}
                          />
                        </div>
                        <div className="feedSelectContainer">
                          <FormSelect
                            label="File naming convention"
                            isRequired={false}
                            fieldName="feedSftpFileNamingScheme"
                            placeholder="Select File Naming Scheme"
                            options={[
                              {
                                value: 'legacy',
                                label: 'sql-stored-prefix (legacy)'
                              },
                              { value: 'feed', label: 'feed based' }
                            ]}
                          />
                        </div>
                        <table>
                          <tbody>
                            <tr>
                              <td>FTPS file path</td>
                              <td>
                                <Field
                                  name={'feedSftpPath'}
                                  component={renderGroupField}
                                  type={'text'}
                                  placeholder={'Enter FTPS Path'}
                                  value={'abc'}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                )}
                {
                  <div>
                    <h3>REPORTS</h3>
                  </div>
                }
                {<div style={{ marginTop: '20px' }}>{this.getReportsData()}</div>}
                <div>
                  <h3>ADMIN PRODUCTS</h3>
                </div>
                <div>
                  <ProductTable
                    loading={this.props.loading}
                    registerChanges={this.registerProducts.bind(this)}
                    groupId={this.props.match.params.id}
                    isPortalGroup={this.props.group.isPortalGroup}
                  />
                </div>
                {this.props.match.params.id !== 'new' && UserService.isPortalAdmin(this.props.user) && (
                  <div>
                    <br />
                    <div className="clearfix">
                      <h3>PERMISSIONS</h3>
                      <div className={'rightFormButton'}>
                        <button
                          disabled={false}
                          className={'btn_custom'}
                          onClick={e => {
                            e.preventDefault()
                            this.props.saveGroupPermissions(this.trackedGroupPermissions, this.props.match.params.id)
                          }}
                        >
                          {'Save Permissions'}
                        </button>
                      </div>
                    </div>
                    <PermissionTable
                      permissions={this.props.permissions}
                      data={this.props.groupPermissions}
                      fetchPermissions={this.props.fetchPermissions}
                      onChange={this.trackPermissionChange.bind(this)}
                    />
                  </div>
                )}
              </form>
            </div>
          </div>
        </React.Fragment>
      </DocumentTitle>
    )
  }
}

GroupEdit.defaultProps = {
  user: {},
  group: {},
  feed: {},
  loading: false,
  editDocumentTitle: 'Edit Group',
  newDocumentTitle: 'Create Group'
}

GroupEdit.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
  user: PropTypes.object,
  params: PropTypes.object,
  fetchGroup: PropTypes.func,
  initialize: PropTypes.func,
  resetGroup: PropTypes.func,
  saveGroup: PropTypes.func,
  handleSubmit: PropTypes.func,
  dirty: PropTypes.bool,
  valid: PropTypes.bool,
  group: PropTypes.object,
  fields: PropTypes.array,
  registerFormField: PropTypes.func,
  reports: PropTypes.object.isRequired,
  fetchAllReports: PropTypes.func,
  change: PropTypes.func,
  loading: PropTypes.bool,
  fetchPermissions: PropTypes.func,
  permissions: PropTypes.shape({
    data: PropTypes.object,
    count: PropTypes.number,
    offset: PropTypes.number
  }),
  saveGroupPermissions: PropTypes.func,
  fetchGroupPermissions: PropTypes.func,
  groupPermissions: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  newDocumentTitle: PropTypes.string,
  editDocumentTitle: PropTypes.string,
  groupDeleteModal: PropTypes.func,
  deleteUserGroup: PropTypes.func,
  fetchGroups: PropTypes.func
}

function mapStateToProps(state, props) {
  return {
    user: state.session.user,
    group: {
      ...state.session.group,
      s3Bucket:
        state.session && state.session.group && state.session.group.s3Bucket ? state.session.group.s3Bucket : undefined
    },
    reports: state.reports ? state.reports : {},
    loading: props.match.params.id === 'new' ? false : state.session.loading,
    permissions: state.session.permissions,
    groupPermissions: state.groups.permissions ? state.groups.permissions[props.match.params.id] : {}
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    fetchAllReports: () => dispatch(fetchAllReports()),
    fetchGroup: id => dispatch(fetchGroup(id)),
    fetchGroups: () => dispatch(fetchGroups(0, 25, '')),
    resetGroup: () =>
      dispatch(
        updateSession({
          group: {}
        })
      ),
    saveGroup: (group, callback) => dispatch(saveGroup({ ...group, callback })),
    registerFormField: (form, name, type) => {
      dispatch(registerField(form, name, type))
    },
    fetchPermissions: () => dispatch(fetchPermissions()),
    fetchGroupPermissions: groupId => dispatch(fetchGroupPermissions(groupId)),
    saveGroupPermissions: (permissions, groupId) => dispatch(saveGroupPermissions(permissions, groupId)),
    groupDeleteModal: (group, callback) => dispatch(groupDeleteModal(group, callback)),
    deleteUserGroup: (data, callback) => dispatch(deleteUserGroup({ ...data, callback }))
  }
}

const validate = (
  {
    name,
    seatCount,
    activeDataFeed,
    idfAdditionalEmails,
    feedsFilePublicEncryptionKey,
    feedSftpUsername,
    feedSftpPassword,
    feedSftpFolderName
  },
  { dirty, touch, registeredFields }
) => {
  const errors = {}
  if (!dirty) {
    return errors
  }

  if (!seatCount || !`${seatCount}`.match(/^[1-9]+[0-9]*$/)) {
    errors.seatCount = 'Invalid seat value'
  }

  if (feedSftpUsername) {
    if (!feedSftpFolderName) {
      errors.feedSftpFolderName = 'FTPS folder name cannot be empty'
    }

    if (!isPasswordValid(feedSftpPassword)) {
      errors.feedSftpPassword = PASSWORD_VALIDATION_MESSAGE
    }
  }

  if (name === '') {
    errors.name = 'Please provide a group name'
  }

  if (idfAdditionalEmails && idfAdditionalEmails.length > MAX_LENGTH_IDF_ADDITIONAL_EMAILS) {
    errors.idfAdditionalEmails = `Additional email field cannot exceed ${MAX_LENGTH_IDF_ADDITIONAL_EMAILS} characters`
  }

  return errors
}

const form = reduxForm({
  form: 'group',
  fields: [
    'name',
    'seatCount',
    'activeDataFeed',
    'ObfuscationTypeId',
    'feedsFilePublicEncryptionKey',
    'idfAdditionalEmails'
  ],
  validate
})(GroupEdit)

export default connect(mapStateToProps, mapDispatchToProps)(form)
