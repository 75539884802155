export const STORE_PATH = {
  categoryBrand: 'categoryBrand',
  merchantCategory: 'merchantCategory',
  merchantBrand: 'merchantBrand',
  merchantCategoryRegex: 'merchantCategoryRegex',
  merchantBrandRegex: 'merchantBrandRegex',
  brandSynonym: 'brandSynonym',
  categoryBrandPath: 'categoryBrandPath'
}

export const DEBOUNCE_ONCHANGE_UI_ACTION_TIMEOUT = 500
