import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty, keys } from 'lodash'

import ReportsPanel from '../../PanelProduct/ReportPanel'
import ProductToggle from '../Tabs/ProductToggle'

import { fetchGroupReports, updateGroupForm } from '../../../actions/group-edit-actions'
import { fetchAllReports } from '../../../actions/custom-report-actions'

import { products as PRODUCTS } from '../../../constants/constants'

const CUSTOM_REPORTS_PRODUCT_KEY = PRODUCTS.customReportsPeriscope

class CustomReportsTab extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedReports: { '0': props.groupReports }
    }
  }

  componentDidMount() {
    if (isEmpty(this.props.allReports)) {
      this.props.fetchAllReports()
    }

    this.props.fetchGroupReports(this.props.groupId)
  }

  componentDidUpdate(prevProps) {
    if (keys(prevProps.groupReports).length !== keys(this.props.groupReports).length) {
      this.setState({ selectedReports: { '0': this.props.groupReports } })
    }
  }

  handleReportSelect = (reportList, selectedReports) => {
    this.props.updateGroupForm('reports', selectedReports)
  }

  render() {
    const { groupId, productEnabled } = this.props
    const disablePanel = !productEnabled

    return (
      <div className="group-edit-reports-tab">
        <ProductToggle groupId={groupId} productKey={CUSTOM_REPORTS_PRODUCT_KEY} />

        <ReportsPanel
          alwaysExpanded
          disabledSelected={false}
          disabled={disablePanel}
          id={'group-edit-reports'}
          onClickReport={() => false}
          productTitle="Report Access"
          report={this.props.allReports}
          onChangeReports={this.handleReportSelect}
          selectedReports={this.state.selectedReports}
        />
      </div>
    )
  }
}

CustomReportsTab.propTypes = {
  groupId: PropTypes.string,
  allReports: PropTypes.array,
  productEnabled: PropTypes.bool,
  groupReports: PropTypes.object,
  fetchAllReports: PropTypes.func,
  updateGroupForm: PropTypes.func,
  fetchGroupReports: PropTypes.func
}

CustomReportsTab.defaultProps = {
  allReports: [],
  groupReports: {}
}

const mapStateToProps = ({
  reports: { allReports },
  groupEdit: {
    basicInfo,
    groupForm: { reports }
  }
}) => ({
  allReports,
  groupReports: reports,
  productEnabled: basicInfo && basicInfo.Products ? basicInfo.Products[CUSTOM_REPORTS_PRODUCT_KEY] !== undefined : false
})

export default connect(mapStateToProps, {
  updateGroupForm,
  fetchAllReports,
  fetchGroupReports
})(CustomReportsTab)
