import { takeLatest, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'
import { createAlert } from './../../actions/app-actions'
import config from '../../config'

import {
  fetchCategorySuggestionsAction,
  fetchBrandSuggestionsAction,
  fetchMerchantSuggestionsAction,
  fetchGroupQuerySuggestionsAction,
  fetchQuerySuggestionsAction
} from './actions'
import {
  successLoadCategorySuggestions,
  successLoadBrandSuggestions,
  successLoadMerchantSuggestions,
  startLoadGroupQuerySuggestions,
  successLoadGroupQuerySuggestions,
  startLoadQuerySuggestions,
  successLoadQuerySuggestions
} from './slices'

function* fetchCategorySuggestions({ payload = {} }) {
  try {
    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/taxonomy/categories/suggestions`, {
        params: { ...payload }
      })
    )

    yield put(successLoadCategorySuggestions(data))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.message, 'Category suggestions fetch failed'))
  }
}

function* fetchBrandSuggestions({ payload = {} }) {
  try {
    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/taxonomy/brands/suggestions`, {
        params: { ...payload }
      })
    )

    yield put(successLoadBrandSuggestions(data))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.message, 'Brand suggestions fetch failed'))
  }
}

function* fetchMerchantSuggestions({ payload = {} }) {
  try {
    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/taxonomy/merchants/suggestions`, {
        params: { ...payload }
      })
    )

    yield put(successLoadMerchantSuggestions(data))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.message, 'Merchant suggestions fetch failed'))
  }
}

// Group && Queries
function* fetchGroupQuerySuggestions({ payload = {} }) {
  try {
    const { id, ...queryParams } = payload

    yield put(startLoadGroupQuerySuggestions({ id }))

    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/group-query/suggestions`, {
        params: { ...queryParams }
      })
    )

    yield put(successLoadGroupQuerySuggestions(data))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.message, 'Group Query suggestions fetch failed'))
  }
}

function* fetchQuerySuggestions({ payload = {} }) {
  const { id, ...queryParams } = payload

  yield put(startLoadQuerySuggestions({ id }))

  try {
    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/query/suggestions`, {
        params: { ...queryParams }
      })
    )

    yield put(successLoadQuerySuggestions(data))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.message, 'Query suggestions fetch failed'))
  }
}

function* fetchSuggestionsFlow() {
  yield takeLatest(fetchCategorySuggestionsAction.type, fetchCategorySuggestions)
  yield takeLatest(fetchBrandSuggestionsAction.type, fetchBrandSuggestions)
  yield takeLatest(fetchMerchantSuggestionsAction.type, fetchMerchantSuggestions)
  yield takeLatest(fetchGroupQuerySuggestionsAction.type, fetchGroupQuerySuggestions)
  yield takeLatest(fetchQuerySuggestionsAction.type, fetchQuerySuggestions)
}

export default [fork(fetchSuggestionsFlow)]
