import { takeLatest, select, call, put, fork } from 'redux-saga/effects'
import { stopAsyncValidation } from 'redux-form'
import axios from 'axios'
import contentRange from 'content-range'
import cuid from 'cuid'
import moment from 'moment'

// actions
import {
  FETCH_USER,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILED,
  SAVE_USER,
  SEND_RESET_PASSWORD_EMAIL,
  RESEND_ONBOARDING_LINK,
  SAVE_USER_PERMISSIONS,
  SAVE_USER_PERMISSIONS_SUCCESS,
  FETCH_USER_PERMISSIONS,
  FETCH_USER_PERMISSIONS_SUCCESS,
  SET_USER_PASSWORD_BY_ADMIN,
  DOWNLOAD_USERS,
  DISABLE_USER,
  ENABLE_USER,
  DELETE_USER
} from '../actions/user-actions'

import { createAlert, requestError, ALERT_CREATE, updateSession } from '../actions/app-actions'

// components
import { successModal, errorModal } from '../actions/modal-actions'

// services
import ApiService from '../services/api-service'
import UserService from '../services/user-service'
import { getClientHeaders } from './auth-sagas'

const DOWNLOAD_DATETIME = 'MM-DD-YYYY:hh:mm:ss'

const saveUserQuery = userData => ApiService.post('/api/user', userData, getClientHeaders())

const saveUserGroupQuery = userData => ApiService.post('/api/user/group', userData, getClientHeaders())

const saveProfileQuery = userData => ApiService.post('/api/profile', userData, getClientHeaders())

const postsendResetPasswordEmail = data => ApiService.post('/forgot_password', data, getClientHeaders())

const postDeleteUser = ({ token, id }) => ApiService.isAuth(token).delete(`/api/admin/user/${id}`)

const postUserStatus = ({ token, data }) => ApiService.isAuth(token).post(`/api/admin/user-status`, data)

export function* fetchUserFlow() {
  yield takeLatest(FETCH_USER, fetchUser)
}

export function* fetchUsersFlow() {
  yield takeLatest(FETCH_USERS, fetchUsers)
}

export function* saveUserFlow() {
  yield takeLatest(SAVE_USER, saveUser)
}

export function* sendOnboardingLinkFlow() {
  yield takeLatest(RESEND_ONBOARDING_LINK, triggerResendOnboardingLink)
}

export function* sendResetPasswordEmailFlow() {
  yield takeLatest(SEND_RESET_PASSWORD_EMAIL, sendResetPasswordEmail)
}

export function* fetchUserPermissionsFlow() {
  yield takeLatest(FETCH_USER_PERMISSIONS, fetchUserPermissions)
}

export function* saveUserPermissionsFlow() {
  yield takeLatest(SAVE_USER_PERMISSIONS, saveUserPermissions)
}

function* setUserPasswordByAdminFlow() {
  yield takeLatest(SET_USER_PASSWORD_BY_ADMIN, setUserPasswordByAdmin)
}

function* downloadUsersFlow() {
  yield takeLatest(DOWNLOAD_USERS, downloadUsers)
}

function* disableUserFlow() {
  yield takeLatest(DISABLE_USER, disableUser)
}

function* enableUserFlow() {
  yield takeLatest(ENABLE_USER, enableUser)
}

function* deleteUserFlow() {
  yield takeLatest(DELETE_USER, deleteUser)
}

function* setUserPasswordByAdmin({ type, ...params }) {
  try {
    yield call(() => axios.post(`/api/admin/reset-user-password`, params))
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: `Updated user password successfully`,
          headline: 'User Password'
        }
      ]
    })
  } catch (err) {
    window.captureException(err)
    const msg = `Error saving user password ${err.response ? `- ${err.response.data}` : ''} - ${err.message}`
    console.error(msg)
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: msg,
          headline: 'User Password'
        }
      ]
    })
  }
}

/*
 * Saves single user
 * @param {Object} options.payload: {user, callback}
 */
export function* saveUser({ payload }) {
  try {
    const userParams = payload.user
    const callback = payload.callback
    const { user } = yield select(state => state.session)

    const data = yield call(
      user.id === userParams.id
        ? saveProfileQuery
        : userParams.group !== undefined
        ? saveUserGroupQuery
        : saveUserQuery,
      userParams
    )
    if (data.success === false) {
      if (data.form_messages) {
        return yield put(stopAsyncValidation('user', data.form_messages))
      }
      return yield put(errorModal(data.message, data.title))
    }

    if (data.user) {
      const update = { userEdit: data.user }
      if (data.user.id === user.id) {
        update.user = {
          ...user,
          ...data.user
        }
      }
      yield put(updateSession(update))
    }

    if (UserService.isAdmin(user)) {
      yield fetchUsers()
    }

    if (!userParams.id) {
      return yield put(successModal(data.message ? data.message : 'User Created!', callback, [user]))
    }
    if (!payload.skipSuccessModal) {
      return yield put(successModal('Changes saved ✓', callback, [data.user, userParams]))
    } else {
      return callback(data.user, userParams)
    }
  } catch (error) {
    console.error(`Critical error when creating an user: ${error.message}`)
    window.captureException(error)
    return yield put(errorModal(error.message))
  }
}

function* triggerResendOnboardingLink({ email }) {
  try {
    yield call(() => ApiService.post('/api/onboard/resend', { email }, getClientHeaders()))
    return successModal(`Sent onboarding link to ${email}`, null)
  } catch (error) {
    window.captureException(error)
    const message = error.response && error.response.data ? error.response.data.message : error.message
    return errorModal(`Error sending onboarding email: ${message}`)
  }
}

/**
 * Gets single user for User Edit page
 * @param {Object} options.payload user id
 */
export function* fetchUser(action) {
  try {
    const { userEdit } = yield select(state => state.session)
    yield put(updateSession({ loading: true }))
    const data = yield ApiService.get(`/api/user/${action.userId}`)

    yield put(
      updateSession({
        loading: false,
        userEdit: {
          ...userEdit,
          ...data.user,
          groupSuggestions: userEdit.groupSuggestions || []
        }
      })
    )
  } catch (error) {
    console.error('Error', error)
    window.captureException(error)
    yield put(updateSession({ loading: false }))
    yield put(requestError(error))
  }
}

/**
 * Fetches multiple users. For use with RVTable pagination component
 */

function* fetchUsers(action = {}) {
  const state = yield select(state => state)
  try {
    const searchType = state.users.selectedSearchEntity

    let endpoint
    let queryParam

    switch (searchType) {
      case 'groups':
        endpoint = `/api/users-group`
        queryParam = 'q'
        break

      case 'users':
      default:
        endpoint = `/api/users`
        queryParam = 'search-ne'
        break
    }

    const response = yield call(() =>
      axios.get(endpoint, {
        params: {
          sort: 'fullname',
          offset: action.offset,
          count: action.count,
          [queryParam]: action.search ? action.search : undefined
        }
      })
    )
    const parts = contentRange.parse(response.headers['content-range'])
    yield put({
      type: FETCH_USERS_SUCCESS,
      data: response.data,
      count: parts ? parts.length : 0,
      offset: action.offset
    })
  } catch (error) {
    console.error('Failed to fetch users')
    window.captureException(error)
    yield put({
      type: FETCH_USERS_FAILED,
      message: error.message
    })
  }
}

/**
 * Send mail change password
 */
export function* sendResetPasswordEmail({ payload }) {
  try {
    yield put(updateSession({ loading: true }))
    const response = yield call(postsendResetPasswordEmail, {
      email: payload.email,
      requester: payload.requester
    })
    yield put(
      updateSession({
        loading: false,
        sessionMessageType: response.success ? 'forgotPasswordEmailSuccess' : 'forgotPassword',
        sessionMessage: response.message
      })
    )

    if (response.success === true && payload.callback) {
      return payload.callback()
    }
  } catch (error) {
    console.error(`Error: ${error}`)
    window.captureException(error)
    yield put(updateSession({ loading: false }))
    yield put(requestError(error))
  }
}

export function* saveUserPermissions(action) {
  try {
    const response = yield call(() => axios.post(`/api/permissions/user/${action.userId}`, action.permissions))
    yield put({
      type: SAVE_USER_PERMISSIONS_SUCCESS,
      data: response.data,
      userId: action.userId
    })
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'success',
          message: `Updated permissions successfully`,
          headline: 'User Permissions'
        }
      ]
    })
  } catch (err) {
    window.captureException(err)
    const msg = 'Failed to update permissions'
    console.error(msg)
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: msg,
          headline: 'User Permissions'
        }
      ]
    })
  }
}

export function* fetchUserPermissions(action) {
  try {
    const response = yield call(() => axios.get(`/api/permissions/user/${action.userId}`))
    yield put({
      type: FETCH_USER_PERMISSIONS_SUCCESS,
      data: response.data,
      userId: action.userId
    })
  } catch (err) {
    window.captureException(err)
    const msg = 'Failed to fetch user permissions'
    console.error(msg)
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: msg,
          headline: 'User Permissions'
        }
      ]
    })
  }
}

function* downloadUsers({ searchEntity, searchTerm }) {
  try {
    yield put(createAlert('success', '', 'Preparing CSV for download'))

    const response = yield call(() =>
      axios({
        url: 'api/users/download',
        method: 'get',
        responseType: 'blob',
        params: {
          searchTerm,
          searchEntity
        }
      })
    )

    const url = window.URL.createObjectURL(response.data)
    const downloadLink = document.createElement('a')
    downloadLink.href = url

    downloadLink.setAttribute('download', `users-${moment().format(DOWNLOAD_DATETIME)}.csv`)

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  } catch (error) {
    console.error(error.message)

    yield put(createAlert('danger', error.message, 'Users CSV download failed'))
  }
}

function* disableUser({ payload }) {
  try {
    const { auth } = yield select(state => state.session)

    yield call(postUserStatus, { token: auth.token, data: { userId: payload.id, isActive: false } })
    yield put(createAlert('success', 'User disable successfully', 'User Disable'))
    yield fetchUsers()

    return payload.callback()
  } catch (error) {
    yield put(createAlert('danger', error.message, 'User disable failed'))
  }
}

function* enableUser({ payload }) {
  try {
    const { auth } = yield select(state => state.session)

    yield call(postUserStatus, { token: auth.token, data: { userId: payload.id, isActive: true } })
    yield put(createAlert('success', 'User enable successfully', 'User Enable'))
    yield fetchUsers()

    return payload.callback()
  } catch (error) {
    yield put(createAlert('danger', error.message, 'User enable failed'))
  }
}

function* deleteUser({ payload }) {
  try {
    const { auth } = yield select(state => state.session)

    yield call(postDeleteUser, { token: auth.token, id: payload.id })
    yield put(createAlert('success', 'User deleted successfully', 'User Delete'))
    yield fetchUsers()

    return payload.callback()
  } catch (error) {
    yield put(createAlert('danger', error.message, 'User delete failed'))
  }
}

export default [
  fork(fetchUserFlow),
  fork(fetchUsersFlow),
  fork(saveUserFlow),
  fork(sendResetPasswordEmailFlow),
  fork(sendOnboardingLinkFlow),
  fork(fetchUserPermissionsFlow),
  fork(saveUserPermissionsFlow),
  fork(setUserPasswordByAdminFlow),
  fork(downloadUsersFlow),
  fork(disableUserFlow),
  fork(enableUserFlow),
  fork(deleteUserFlow)
]
