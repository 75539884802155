import {
  CREATE_FEED,
  CREATE_FEED_SUCCESS,
  CREATE_FEED_FAILED,
  FETCH_FEEDS_SUCCESS,
  FETCH_FEEDS_FAILED,
  TOGGLE_SWITCH,
  FETCH_FEEDS_SEARCH_ENTITY_CHANGED,
  FETCH_FEED_DELIVERIES,
  FETCH_FEED_DELIVERIES_SUCCESS,
  FETCH_FEED_DELIVERIES_FAILURE,
  FETCH_FEED_DELIVERIES_SUMMARY,
  FETCH_FEED_DELIVERIES_SUMMARY_SUCCESS,
  FETCH_FEED_DELIVERIES_SUMMARY_FAILURE,
  FETCH_FEED_GROUP_LOCKS,
  FETCH_FEED_GROUP_LOCKS_SUCCESS,
  FETCH_FEED_GROUP_LOCKS_FAILURE,
  DELETE_FEED_GROUP_LOCK_FAILURE,
  FETCH_CLIENT_FEED_INFO,
  FETCH_CLIENT_FEED_INFO_SUCCESS,
  FETCH_CLIENT_FEED_INFO_FAILURE,
  FETCH_GLOBAL_FEED_CONFIG,
  FETCH_GLOBAL_FEED_CONFIG_SUCCESS,
  FETCH_GLOBAL_FEED_CONFIG_FAILURE
} from '../actions/feed-actions'

import { LOGOUT_SUCCESS } from '../actions/auth-actions'
import moment from 'moment'

const initialState = {
  searchEntity: 'groups',
  feedDeliveries: {
    data: [],
    error: '',
    loading: false,
    deliveredTodayCount: 0
  },
  feedDeliveriesSummary: {
    error: '',
    loading: false,
    startDate: moment()
      .add('-7', 'd')
      .format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    dateLevelSummary: []
  },
  feedGroupLocks: {
    data: [],
    error: null,
    loading: false
  },
  clientFeedInfo: {
    data: [],
    error: null,
    loading: false
  },
  globalFeedConfig: {
    data: {},
    error: null,
    loading: false
  }
}

const feedReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SWITCH:
      return {
        ...state,
        validated: action.data
      }

    case FETCH_FEEDS_SUCCESS:
      return {
        ...state,
        // If we gave an offset, we append the data instead of replacing it
        data: action.offset ? [...state.data, ...action.data] : action.data,
        count: action.count,
        searchEntity: state.searchEntity
      }

    case FETCH_FEEDS_FAILED:
      return {
        ...state,
        searchEntity: state.searchEntity,
        error: action.error
      }

    case CREATE_FEED:
      return {
        ...state,
        isLoading: true
      }

    case CREATE_FEED_SUCCESS:
      const data = state.data || []
      return {
        ...state,
        searchEntity: state.searchEntity,
        data: [...data, action.payload.payload]
      }

    case CREATE_FEED_FAILED:
      return {
        ...state,
        error: action.error
      }

    case FETCH_FEEDS_SEARCH_ENTITY_CHANGED:
      return {
        ...state,
        count: 0,
        searchEntity: action.searchEntity
      }

    case FETCH_FEED_DELIVERIES:
      return {
        ...state,
        feedDeliveries: {
          ...state.feedDeliveries,
          loading: true,
          error: ''
        }
      }

    case FETCH_FEED_DELIVERIES_SUMMARY:
      return {
        ...state,
        feedDeliveriesSummary: {
          ...state.feedDeliveriesSummary,
          startDate: moment()
            .add('-7', 'd')
            .format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD')
        }
      }

    case FETCH_FEED_DELIVERIES_SUCCESS:
      return {
        ...state,
        feedDeliveries: {
          error: '',
          loading: false,
          data: action.feedDeliveries,
          deliveredTodayCount: action.deliveredTodayCount,
          startDate: moment()
            .add('-7', 'd')
            .format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD')
        }
      }

    case FETCH_FEED_DELIVERIES_FAILURE:
      return {
        ...state,
        feedDeliveries: {
          ...state.feedDeliveries,
          error: action.error,
          loading: false
        }
      }

    case FETCH_FEED_DELIVERIES_SUMMARY_SUCCESS:
      return {
        ...state,
        feedDeliveriesSummary: {
          error: '',
          loading: false,
          dateLevelSummary: action.dateLevelSummary,
          startDate: moment()
            .add('-7', 'd')
            .format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD')
        }
      }

    case FETCH_FEED_DELIVERIES_SUMMARY_FAILURE:
      return {
        ...state,
        feedDeliveriesSummary: {
          ...state.feedDeliveriesSummary,
          error: action.error,
          loading: false
        }
      }

    case FETCH_FEED_GROUP_LOCKS:
      return {
        ...state,
        feedGroupLocks: {
          ...state.feedGroupLocks,
          error: null,
          loading: true
        }
      }

    case FETCH_FEED_GROUP_LOCKS_SUCCESS:
      return {
        ...state,
        feedGroupLocks: {
          ...state.feedGroupLocks,
          data: action.data,
          error: null,
          loading: false
        }
      }
    case FETCH_CLIENT_FEED_INFO:
      return {
        ...state,
        clientFeedInfo: {
          ...state.clientFeedInfo,
          error: null,
          loading: true
        }
      }
    case FETCH_CLIENT_FEED_INFO_SUCCESS:
      return {
        ...state,
        clientFeedInfo: {
          ...state.clientFeedInfo,
          data: action.data,
          error: null,
          loading: false
        }
      }
    case FETCH_CLIENT_FEED_INFO_FAILURE:
      return {
        ...state,
        clientFeedInfo: {
          ...state.clientFeedInfo,
          error: action.error,
          loading: false
        }
      }
    case DELETE_FEED_GROUP_LOCK_FAILURE:
    case FETCH_FEED_GROUP_LOCKS_FAILURE:
      return {
        ...state,
        feedGroupLocks: {
          ...state.feedGroupLocks,
          error: action.error,
          loading: false
        }
      }
    case FETCH_GLOBAL_FEED_CONFIG:
      return {
        ...state,
        globalFeedConfig: {
          ...state.globalFeedConfig,
          data: {},
          error: null,
          loading: true
        }
      }
    case FETCH_GLOBAL_FEED_CONFIG_SUCCESS:
      return {
        ...state,
        globalFeedConfig: {
          ...state.globalFeedConfig,
          data: action.data,
          error: null,
          loading: false
        }
      }
    case FETCH_GLOBAL_FEED_CONFIG_FAILURE:
      return {
        ...state,
        globalFeedConfig: {
          ...state.globalFeedConfig,
          data: action.data,
          error: action.error && action.error.message,
          loading: false
        }
      }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default feedReducer
