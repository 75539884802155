import React, { useState } from 'react'
import PropTypes from 'prop-types'

const NO_VALUE = ''

function BooleanValueEditor({ initialValue, onChange }) {
  const [value, setValue] = useState(initialValue)

  const handleChange = event => {
    const selectedValue = event.target.value === 'true'
    setValue(selectedValue)
    onChange([{ value: selectedValue }])
  }

  const valueAsString = value === true ? 'true' : value === false ? 'false' : NO_VALUE

  return (
    <div className="description-value-editor">
      <select value={valueAsString} onChange={handleChange}>
        <option value={NO_VALUE}>Select a value</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
    </div>
  )
}

BooleanValueEditor.propTypes = {
  initialValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

export default BooleanValueEditor
