import React from 'react'
import PropTypes from 'prop-types'

import defaultUserImage from '../../assets/img/slice.png'
import intelligenceLogo from '../../assets/svgs/slice-intel-logo.svg'

const getImageSource = (imgSrc, useIntelligenceLogo) => {
  if (useIntelligenceLogo) {
    return intelligenceLogo
  }

  return imgSrc ? `/static/${imgSrc}` : defaultUserImage
}

const GroupLogo = ({ imgSrc, imgAlt, height, width, useIntelligenceLogo }) => {
  return <img alt={imgAlt} style={{ width, height }} src={getImageSource(imgSrc, useIntelligenceLogo)} />
}

GroupLogo.propTypes = {
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
  useIntelligenceLogo: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

GroupLogo.defaultProps = {
  useIntelligenceLogo: false
}

export default GroupLogo
