import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import { appName } from '../../constants/constants'

const Error = ({ errorInfo, errorDetails }) => (
  <div className="error-boundary">
    <div className="error-boundary-icon" />
    <h1>Oops! The application has encountered an error</h1>
    <h2>
      A report has been automatically created and sent to the {`${appName}`} team. You can try reloading the{' '}
      <a href={window.location.href}>previous page</a> or return <a href="/">Home</a>
    </h2>
  </div>
)

Error.propTypes = {
  errorInfo: PropTypes.any,
  errorDetails: PropTypes.any
}

export default Error
