import React from 'react'
import PropTypes from 'prop-types'

import { Tooltip, OverlayTrigger, FormGroup, Col, Button, Glyphicon } from 'react-bootstrap'
import cx from 'classnames'
import SQLEditor from '../../SQLEditor'
import { SQL_DATE_TEMPLATE, SQL_QUERY_TEMPLATE, SQL_DATE_TOOLTIP, SQL_QUERY_TOOLTIP } from '../../SQLEditor/utils'

const getValuesTooltip = (
  <Tooltip id="tooltip">
    For DateRange filters, this immediately fetches the values. For other filters, this starts a task to get the values.
    This also saves any pending changes made to the filter.
  </Tooltip>
)

export default function SourceQueryInput({
  sourceQuery,
  type,
  isSourceQueryValid,
  onChange,
  queryFilterValues,
  fetchingValues
}) {
  const defaultQuery = type === 'dateRange' ? SQL_DATE_TEMPLATE : SQL_QUERY_TEMPLATE
  const tooltip = type => (
    <Tooltip id="limit-info-tooltip">
      {type === 'dateRange' ? <span>{SQL_DATE_TOOLTIP}</span> : <span>{SQL_QUERY_TOOLTIP}</span>}
    </Tooltip>
  )
  return (
    <FormGroup controlId="cre-sql-query-field-row">
      <Col
        sm={10}
        className={cx('cre-sql-query-field', {
          'has-error': isSourceQueryValid === false
        })}
      >
        <SQLEditor
          name="sourceQuery"
          value={sourceQuery == null ? defaultQuery : sourceQuery}
          placeholder={defaultQuery}
          onChange={(editor, data, value) => {
            onChange(value)
          }}
          autoFocus
          options={{
            placeholder: defaultQuery
          }}
        />
        <div className="query-info">
          <OverlayTrigger className="pull-right" placement="left" overlay={tooltip(type)}>
            <Glyphicon glyph={'info-sign'} />
          </OverlayTrigger>
        </div>
        <div className="query-footer">
          {isSourceQueryValid === false && (
            <span className={'error-text help-block pull-left'}>{'Source SQL Query cannot be empty'}</span>
          )}
          <div className="pull-right">
            <OverlayTrigger placement="right" overlay={getValuesTooltip}>
              <Button
                className="btn_custom_secondary cre-sql-query-get-values"
                onClick={queryFilterValues}
                disabled={fetchingValues || !sourceQuery || isSourceQueryValid === false}
              >
                {fetchingValues ? 'Loading...' : 'Get Values'}
              </Button>
            </OverlayTrigger>
          </div>
        </div>
      </Col>
    </FormGroup>
  )
}

SourceQueryInput.propTypes = {
  sourceQuery: PropTypes.string,
  type: PropTypes.string,
  isSourceQueryValid: PropTypes.bool,
  onChange: PropTypes.func,
  queryFilterValues: PropTypes.func,
  fetchingValues: PropTypes.bool
}
