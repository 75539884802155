// @flow
import {
  TASK_LOAD,
  TASK_LOAD_FAILED,
  TASK_LOAD_SUCCEEDED,
  TASK_UPDATE_SUCCEEDED,
  UPDATE_TASK_AUTO_REFRESH,
  UPDATE_TASK_SEARCH_FILTER
} from '../actions/task-actions'

import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialstate = {
  data: [],
  count: 0,
  loading: false,
  autoRefresh: false,
  searchField: 'type',
  searchValue: ''
}

const tasksReducer = (state: Object = initialstate, action: Object): Object => {
  switch (action.type) {
    case TASK_LOAD:
      return {
        ...state,
        loading: true
      }

    case TASK_LOAD_FAILED:
      return {
        ...state,
        loading: false
      }

    case TASK_LOAD_SUCCEEDED:
      return {
        ...state,
        loading: false,
        data: action.offset ? [...state.data, ...action.data] : action.data,
        count: action.count
      }

    case TASK_UPDATE_SUCCEEDED:
      const tasks = [...state.data]
      return {
        ...state,
        data: tasks.map(task => {
          if (action.data[task.id]) {
            return action.data[task.id]
          }
          return task
        })
      }

    case UPDATE_TASK_AUTO_REFRESH:
      return {
        ...state,
        autoRefresh: action.value
      }

    case UPDATE_TASK_SEARCH_FILTER:
      return {
        ...state,
        searchField: action.field,
        searchValue: action.value
      }

    case LOGOUT_SUCCESS:
      return initialstate

    default:
      return state
  }
}

export default tasksReducer
