import { takeLatest, call, put, fork } from 'redux-saga/effects'
import axios from 'axios'
import { FETCH_L1_CATEGORIES, fetchL1CategoriesSuccess, fetchL1CategoriesFailed } from '../actions/category-actions'

export function* getL1CategoriesFlow() {
  yield takeLatest(FETCH_L1_CATEGORIES, getL1Categories)
}

function* getL1Categories(action) {
  try {
    const response = yield call(() => axios.get(`/api/admin/idf-dimension/l1categories`))
    yield put(fetchL1CategoriesSuccess(response.data))
  } catch (e) {
    window.captureException(e)
    yield put(fetchL1CategoriesFailed({ error: e }))
  }
}
export default [fork(getL1CategoriesFlow)]
