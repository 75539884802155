import React from 'react'
import { products } from './constants/constants'
import { get } from 'lodash'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ProtectedRoute, loadAsyncComponents } from './utils/route-util'
import AdminPage from './components/AdminPage/AdminPage'
import CMEPage from './components/CMEPage/CMEPage'
import SupraPage from './components/SupraPage/SupraPage'
import UserEditPage from './components/UserEditPage'
import LoginPage from './components/LoginPage'
import ForgotPasswordPage from './components/ForgotPasswordPage'
import CustomReportViewPage from './components/CustomReportViewPage'
import DefaultPageRouter from './DefaultPageRouter'
import OnboardingPage from './components/OnboardingPage'
import ResetPassword from './components/ResetPassword'
import NIQPage from 'components/NIQPage/NIQPage'

const asyncPages = {}

// skip loading/bundling stuff for non ri tenants
if (window.Tenant === 'ri') {
  loadAsyncComponents(asyncPages, {
    AppHomePage: import('./components/AppHomePage' /*  webpackMode: "eager" */)
  })
}

const getRoute = () => {
  return (
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/forgot_password" component={ForgotPasswordPage} />
      <Route path="/onboarding" component={OnboardingPage} />
      <Route path="/reset_password" component={ResetPassword} />
      <ProtectedRoute path="/" exact component={DefaultPageRouter} />
      <Redirect from="/home" to="/dashboard" />
      <ProtectedRoute path="/dashboard" component={asyncPages.AppHomePage} />
      <ProtectedRoute path="/welcome" component={UserEditPage} />
      <ProtectedRoute path="/profile" component={UserEditPage} />
      <ProtectedRoute path="/custom/reports/view/:id" component={CustomReportViewPage} />
      <ProtectedRoute path="/admin" component={AdminPage} />
      <ProtectedRoute path="/cme" component={CMEPage} />
      <ProtectedRoute path="/supra" component={SupraPage} />
      <ProtectedRoute path="/niq" component={NIQPage} />
    </Switch>
  )
}

export const getProductRoute = (product, session) => {
  const defaultRoute = '/'
  if (!product || !session) {
    return defaultRoute
  }
  try {
    switch (product) {
      case products.customReportsPeriscope:
        const customReportId = get(session, ['user', 'customReports', 0, 'id'])
        return customReportId ? `/custom/reports/view/${customReportId}` : defaultRoute
      // Admin tools route hooks are in place but not returned by the server at this time
      case products.adminToolsGroups:
        return '/admin'
      case products.adminToolsUsers:
        return '/admin/users'
      case products.adminToolsQCTool:
        return '/cme/search'
      case products.adminToolsSupraQCTool:
        return '/supra/supra-qc-tool'
      case products.adminToolsFeeds:
        return '/admin/feeds'
      case products.adminToolsJobMonitoring:
        return '/admin/jobs'
      case products.adminToolsAuthLogsMonitoring:
        return '/admin/auth-logs'
      default:
        return defaultRoute
    }
  } catch (err) {
    window.captureException(err)
    return defaultRoute
  }
}

export default getRoute
