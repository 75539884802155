// @flow
// eslint-disable-next-line no-redeclare
/* global TENANT */
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { loadAsyncComponents } from 'utils/route-util'

const asyncPages = {}

if (TENANT === 'ri') {
  loadAsyncComponents(asyncPages, {
    SupraQCToolPage: import('../SupraQCToolPage' /* webpackMode: "eager" */),
    SupraQCMonitoringPage: import('./SupraQCMonitoringPage' /* webpackMode: "eager" */),
    SupraTaxonomyPage: import('../../mui/pages/SupraTaxonomyPage/SupraTaxonomyPage' /* webpackMode: "eager" */),
    SupraOrchestratorPage: import(
      '../../mui/pages/SupraOrchestratorPage/SupraOrchestratorPage' /* webpackMode: "eager" */
    )
  })
}

/**
 * Container for the CME page root (/cme). Required by the router.
 */
const SupraPage = () => {
  const { user } = useSelector(state => state.session)
  if (!user) return null

  return (
    <div className="admin-container">
      <Switch>
        <Route path="/supra/supra-qc-tool" component={asyncPages.SupraQCToolPage} />
        <Route path="/supra/monitoring" component={asyncPages.SupraQCMonitoringPage} />
        <Route path="/supra/taxonomy" component={asyncPages.SupraTaxonomyPage} />
        <Route path="/supra/orchestrator" component={asyncPages.SupraOrchestratorPage} />
      </Switch>
    </div>
  )
}

export default SupraPage
