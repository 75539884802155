import {
  FETCH_ITEM_ATTRIBUTES,
  FETCH_ITEM_ATTRIBUTES_SUCCESS,
  FETCH_ITEM_ATTRIBUTES_FAILED
} from '../actions/item-attribute-actions'

import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialState = {
  data: [],
  error: '',
  isLoading: false
}

const itemAttributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ITEM_ATTRIBUTES:
      return {
        ...state,
        isLoading: true
      }

    case FETCH_ITEM_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      }

    case FETCH_ITEM_ATTRIBUTES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default itemAttributesReducer
