// @flow
import decode from 'jwt-decode'

/**
 * Get the expiration date of the given json-web-token
 */
export function getTokenExpirationDate(token: String): Date | null {
  const decoded = decode(token)
  if (!decoded.exp) {
    return null
  }

  const date = new Date(0) // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp)
  return date
}

/**
 * Return true if the given json-web-token is expired or invalid
 */
export function isTokenExpired(token: String): boolean {
  try {
    const date = getTokenExpirationDate(token)
    const offsetSeconds = 0
    if (date === null) {
      return true
    }
    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000)
  } catch (error) {
    console.error(error)
    return true
  }
}
