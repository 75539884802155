import { createSlice } from '@reduxjs/toolkit'

const suggestions = createSlice({
  name: 'suggestions',
  initialState: {
    categories: [],
    brands: [],
    merchants: [],
    groupQueries: {
      data: [],
      loading: false,
      id: null
    },
    queries: {
      data: [],
      loading: false,
      id: null
    }
  },
  reducers: {
    successLoadCategorySuggestions(state, action) {
      state.categories = [...action.payload.results]
    },
    successLoadBrandSuggestions(state, action) {
      state.brands = [...action.payload.results]
    },
    successLoadMerchantSuggestions(state, action) {
      state.merchants = [...action.payload.results]
    },
    // GROUP && QUERIES
    // group
    startLoadGroupQuerySuggestions(state, action) {
      state.groupQueries.id = action.payload.id
      state.groupQueries.loading = true
    },
    successLoadGroupQuerySuggestions(state, action) {
      state.groupQueries.data = [...action.payload]
      state.groupQueries.loading = false
    },
    // queries
    startLoadQuerySuggestions(state, action) {
      state.queries.id = action.payload.id
      state.queries.loading = true
    },
    clearQuerySuggestions(state) {
      state.queries.data = []
    },
    successLoadQuerySuggestions(state, action) {
      state.queries.data = [...action.payload]
      state.queries.loading = false
    }
  }
})

export const {
  successLoadCategorySuggestions,
  successLoadBrandSuggestions,
  successLoadMerchantSuggestions,
  startLoadGroupQuerySuggestions,
  successLoadGroupQuerySuggestions,
  startLoadQuerySuggestions,
  clearQuerySuggestions,
  successLoadQuerySuggestions
} = suggestions.actions

export default suggestions.reducer
