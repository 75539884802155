export const leftNavBeacon = {
  id: 'left-nav',
  title: 'Find your reports, fast',
  content:
    'Your reports are conveniently located in the navigation bar to the left of your screen. To move between reports, choose your report and click.',
  style: {
    width: 242,
    beaconTop: 165,
    beaconLeft: 0,
    modalTop: 205,
    modalLeft: 1,
    arrowStyle: 'arrow-top',
    arrowLocation: 'left-nav-down',
    zIndexProperty: 10000,
    position: 'fixed'
  }
}

export const periscopeBeacons = [
  {
    id: 'periscope-filters',
    title: 'Toggle between report tabs and filters',
    content:
      "To look at different charts we can create with your data, select the report tabs and filters at the top of the page. Click the 'Apply' button to make the changes to your dashboard view.",
    beaconPosition: 'filters-left',
    modalPosition: 'filters-right',
    arrowPosition: 'filters-left',
    style: {
      width: 377,
      arrowStyle: 'arrow-left',
      arrowLocation: 'periscope-filters-left',
      zIndexProperty: 1002
    }
  },
  {
    id: 'periscope-actions',
    title: 'Save your charts and tables, or customize your data',
    content: '',
    beaconPosition: 'actions-right',
    modalPosition: 'actions-left',
    arrowPosition: 'actions-right',
    style: {
      width: 328,
      arrowStyle: 'arrow-right',
      arrowLocation: 'periscope-actions-right',
      zIndexProperty: 1002
    }
  }
]

export const beaconOffsets = (location, beacon) => {
  if (!beacon) return { top: 0, left: 0 }

  switch (location) {
    case 'top':
      return {
        top: beacon.top,
        left: beacon.left + beacon.width
      }
    case 'actions-right':
      return {
        top: beacon.top + 60,
        left: beacon.left + beacon.width - 40
      }
    case 'right':
      return {
        top: beacon.top,
        left: beacon.left + beacon.width
      }
    case 'bottom':
      return {
        top: beacon.top,
        left: beacon.left + beacon.width
      }
    case 'filters-left':
      return {
        top: beacon.top,
        left: beacon.left + 120
      }
    case 'left':
    default:
      return {
        top: beacon.top,
        left: beacon.left - 40
      }
  }
}

export const modalOffsets = (location, beacon, modalWidth) => {
  if (!beacon) return { top: 0, left: 0 }

  switch (location) {
    case 'top':
      return {
        top: beacon.top - 250,
        left: beacon.left + beacon.width - modalWidth / 2
      }
    case 'right':
      return {
        top: beacon.top,
        left: beacon.left + beacon.width
      }
    case 'filters-right':
      return {
        top: beacon.top - 15,
        left: beacon.left + 173
      }
    case 'bottom':
      return {
        top: beacon.top + 55,
        left: beacon.left + beacon.width - modalWidth / 2
      }
    case 'filters-bottom':
      return {
        top: beacon.top + 55,
        left: beacon.left - modalWidth / 2
      }
    case 'actions-left':
      return {
        top: beacon.top + 35,
        left: beacon.left + beacon.width - 378
      }
    case 'left':
    default:
      return {
        top: beacon.top,
        left: beacon.left - (modalWidth + 45)
      }
  }
}

export const arrowOffsets = (location, beacon) => {
  if (!beacon) return { top: 0, left: 0 }

  switch (location) {
    case 'top':
      return {
        top: beacon.top + 47,
        left: beacon.left + beacon.width + 10
      }
    case 'filters-top':
      return {
        top: beacon.top + 47,
        left: beacon.left - 25
      }
    case 'actions-right':
      return {
        top: beacon.top + 73,
        left: beacon.left + beacon.width - 50
      }
    case 'right':
      return {
        top: beacon.top + 20,
        left: beacon.left - 47
      }
    case 'bottom':
      return {
        top: beacon.top - 11,
        left: beacon.left + beacon.width + 14
      }
    case 'filters-left':
      return {
        top: beacon.top + 20,
        left: beacon.left + 165
      }
    case 'left':
    default:
      return {
        top: beacon.top,
        left: beacon.left + beacon.width
      }
  }
}
