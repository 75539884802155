import { useState, useEffect, useMemo } from 'react'
import { escapeRegExp, debounce } from 'lodash'

export function convertTemplateToRegex(template) {
  const escaped = escapeRegExp(template.trim().toLowerCase())
  const additionalEscape = escaped.replace(/&/g, '\\&')
  const leadingTrailingSpaces = `^\\s*${additionalEscape}\\s*$`
  const spacesHandled = leadingTrailingSpaces.replace(/ /g, '\\s*')
  const underscoreReplaced = spacesHandled.replace(/_/g, '(\\S+(\\s+\\S+)*?)')
  return underscoreReplaced
}

const useTemplateRegex = (templateInit = '') => {
  const [template, setTemplate] = useState(templateInit)
  const [regex, setRegex] = useState()

  const onTemplateChange = newTemplate => {
    setTemplate(newTemplate)
  }

  const setRegexDebounced = useMemo(() => debounce(template => setRegex(template), 1000), [])

  useEffect(() => {
    setRegexDebounced(convertTemplateToRegex(template))
  }, [template, setRegexDebounced])

  useEffect(() => {
    onTemplateChange(templateInit)
  }, [templateInit])

  return { template, regex, onTemplateChange }
}

export default useTemplateRegex
