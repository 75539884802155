// the TENANT is global variable
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Popup from '../../Popup'
import Box from '../../../Box'
import Input from '../../../Input'
import SearchIcon from '../../../Icon/SearchIcon'
import CheckBox from '../../../CheckBox'
import ArrowUpIcon from '../../../Icon/ArrowUp'
import ArrowDownIcon from '../../../Icon/ArrowDown'
import DropDownList from '../../../DropDownList/DropDownList'
import PaginationControls from '../../../PaginationControls'
import Highlighter from 'react-highlight-words'
import { simpleNumberFormatter } from '../../../../utils/formatters'
import { keyBy } from 'lodash'
import './index.scss'

const noop = () => {}
const stopPropogation = event => event.stopPropagation()
const characterLengthThreshold = 24
const ITEMS_PER_COLUMN = 15

/**
 * CheckBoxMultiSelectSmall component
 */

class CheckBoxFilter extends Component {
  static forceSingleColumn(nextProps) {
    if (TENANT === 'nielsen') {
      // we are forcing single column for certain filter labels with to display long values
      if (['category', 'description'].includes(nextProps.label.toLowerCase())) {
        return true
      }
    }
    return false
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.options && prevState.options !== nextProps.options) {
      // default behaviour
      let maxColumns = 4
      let paginatedView = nextProps.options.length >= 40

      if (CheckBoxFilter.forceSingleColumn(nextProps)) {
        // force single column behaviour
        maxColumns = 1
        paginatedView = nextProps.options.length > ITEMS_PER_COLUMN
      }
      const pageSize = ITEMS_PER_COLUMN * maxColumns
      return {
        paginatedView,
        pageSize,
        maxColumns,
        filteredOptions: [...nextProps.options],
        options: nextProps.options,
        optionsMap: keyBy(nextProps.options, 'id')
      }
    }
    return null
  }

  constructor(props) {
    super(props)
    this.state = {
      searchValue: '',
      isOpen: false,
      selectedQuickOption: '',
      paginatedView: false,
      filteredOptions: [],
      selectedView: 'all',
      pageNumber: 1,
      maxColumns: 4,
      pageSize: 4 * ITEMS_PER_COLUMN,
      viewOptions: {
        all: { name: 'All' },
        selected: { name: 'Selected' },
        unselected: { name: 'Unselected' }
      },
      optionsMap: {}
    }
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClick)
  }

  componentDidUpdate() {
    if (this.widthCalcWrapper) {
      // wrapper found
      const width = this.widthCalcWrapper.clientWidth
      if (width && !this.state.calcWidth) {
        // width > 0 && width is not yet set in state
        this.setState({ calcWidth: width })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick)
  }

  valueDidChange = key => checked => {
    const { selectedValues } = this.props
    const { optionsMap } = this.state
    if (checked) {
      selectedValues.add(key)
    } else {
      selectedValues.delete(key)
    }
    const newSelectedValues = []
    selectedValues.forEach(id => {
      if (optionsMap[id]) {
        newSelectedValues.push(optionsMap[id])
      }
    })
    this.setState({ selectedQuickOption: '' })
    this.props.valueDidChange(newSelectedValues)
  }

  defaultSelected = () => {
    this.setState({ selectedQuickOption: 'default' })
    this.props.valueDidChange([...this.props.defaultValues])
  }

  selectAll = () => {
    const { valueDidChange, options = [] } = this.props
    this.setState({ selectedQuickOption: 'all' })
    valueDidChange([...options])
  }

  clearAll = () => {
    this.setState({ selectedQuickOption: 'none' })
    this.props.valueDidChange([])
  }

  toggleOpenPanel = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  getLabelTop = () => {
    return this.props.label.length > characterLengthThreshold
      ? `${this.props.label.slice(0, characterLengthThreshold)}...`
      : this.props.label
  }

  /**
   * Displays actual select label text, looking at currently selected values
   */
  getLabel = () => {
    const { selectedValues } = this.props
    const { optionsMap } = this.state
    if (selectedValues && selectedValues.size > 1) {
      return `${selectedValues.size} Selected`
    } else if (selectedValues && selectedValues.size === 1) {
      const firstKey = selectedValues.values().next().value
      const firstValue = optionsMap[firstKey]
      return firstValue ? firstValue.aliasValue : '1 Selected'
    } else {
      return `Select`
    }
  }

  /**
   * Displays select label text using only initial default values.
   * Used for calculating width.
   */
  getLabelForCalc = () => {
    const { options } = this.props
    let defaultText = this.getLabel()

    // pad with spaces to support `NN Selected`
    if (defaultText.padEnd) {
      /* we want to add support display support of "NN Selected"
       * where NN == Number of digits in total options.
       * Hence we pad defaultText with extra space characters to take appropriate width
       */
      defaultText = defaultText.padEnd(
        `${options.length}`.length + ` Selected`.length, // max width for Selected
        '\u2007'
      ) // \u2007 is "figure space". Its width is same as that of numbers
    }
    // truncate if text above characterLengthThreshold
    return defaultText.substring(0, characterLengthThreshold + 1)
  }

  handleClick = event => {
    if (!this.state.isOpen) {
      return
    }
    if (this.checkboxFilterWrapper && !this.checkboxFilterWrapper.contains(event.target)) {
      this.setState({ isOpen: false })
    }
  }

  getFilteredOptions = (selectedView, searchValue) => {
    const { options, selectedValues } = this.props
    let values = options
    if (selectedView === 'selected') {
      values = values.filter(item => selectedValues.has(item.id))
    } else if (selectedView === 'unselected') {
      values = values.filter(item => !selectedValues.has(item.id))
    }
    if (searchValue && searchValue.length !== 0) {
      values = values.filter(option => option.aliasValue.toLowerCase().includes(searchValue))
    }
    return values
  }

  searchChanged = searchValue => {
    searchValue = searchValue.toLowerCase()
    this.setState({ searchValue })
    const { selectedView } = this.state
    const filteredOptions = this.getFilteredOptions(selectedView, searchValue)
    this.setState({ filteredOptions, pageNumber: 1 })
  }

  getActionClass = type => {
    return this.state.selectedQuickOption === type ? 'bold' : 'normal'
  }

  getCheckboxValueStyle = valueCount => {
    const { paginatedView } = this.state
    if (!paginatedView) {
      return 'value-box'
    }
    const { pageSize, maxColumns } = this.state
    const columnlength = Math.floor(pageSize / maxColumns)
    let columnCount = Math.ceil(valueCount / columnlength)
    columnCount = Math.min(4, columnCount)
    return `value-box columns-${columnCount}`
  }

  getCheckboxListStyle = () => {
    return this.state.paginatedView ? 'chbxlist paginated-value-list' : 'chbxlist vertical-value-list'
  }

  clearSearch = () => {
    this.searchChanged('')
  }

  onViewChange = selectedView => {
    this.setState({ selectedView })
    const filteredOptions = this.getFilteredOptions(selectedView, this.state.searchValue)
    this.setState({ filteredOptions, pageNumber: 1 })
  }

  getPaginatedOptions(options, pageNumber = 1, pageSize = this.state.pageSize) {
    // pages logically start with 1, but technically with 0
    return options.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
  }

  selectPage = pageNumber => {
    this.setState({ pageNumber })
  }

  renderQuickActionPanel() {
    const { selectedValues, options } = this.props
    return (
      <React.Fragment>
        <div className={'select_buttons'}>
          <span className={`btn_link medium ${this.getActionClass('default')}`} onClick={this.defaultSelected}>
            Default
          </span>
        </div>
        <div className={'select_buttons'}>
          <span className={`btn_link medium ${this.getActionClass('all')}`} onClick={this.selectAll}>
            Select all
          </span>
        </div>
        {this.props.allowClearAll && (
          <div className={'select_buttons'}>
            <span className={`btn_link medium ${this.getActionClass('none')}`} onClick={this.clearAll}>
              Clear all
            </span>
          </div>
        )}
        <span>
          <span className="bold">{simpleNumberFormatter(selectedValues.size)}</span> of{' '}
          <span className="bold">{simpleNumberFormatter(options.length)}</span> selected
        </span>
      </React.Fragment>
    )
  }

  renderLabelContent = () => {
    const { calcWidth } = this.state
    const isCalculated = !!calcWidth
    return (
      <span
        className={cx('label-content', 'filterDetailsWrap')}
        style={{
          width: calcWidth || 'auto'
        }}
        ref={el => {
          this.widthCalcWrapper = el
        }}
      >
        <div className={cx('filterDescriptorTop', 'hide-text-overflow')}>{this.getLabelTop()}</div>
        <div
          className={cx('filterSelector', 'hide-text-overflow', {
            'before-calc': !isCalculated // this class helps in finding width
          })}
        >
          {isCalculated ? this.getLabel() : this.getLabelForCalc()}
        </div>
      </span>
    )
  }

  renderSelectedValueBox() {
    const { selectedValues, label } = this.props
    const { isOpen, optionsMap } = this.state
    const tooltip = name => {
      const firstKey = selectedValues.values().next().value
      const firstValue = optionsMap[firstKey]
      const txt = selectedValues.size === 1 && firstValue ? firstValue.aliasValue : `${selectedValues.size} Selected`
      return (
        <Tooltip id={name || 'checkbox_filter'}>
          <div className="tooltip-box">{`${label}: ${txt}`}</div>
        </Tooltip>
      )
    }
    return (
      <OverlayTrigger rootClose placement="top" overlay={tooltip(label)}>
        <div
          className={cx('squareBoxWithArrow', {
            squareBoxWithArrowSelected: isOpen
          })}
          onClick={this.toggleOpenPanel}
        >
          {this.renderLabelContent()}
          <span onClick={this.toggleOpenPanel} className="spanRightArrowIconFilter">
            {
              <ArrowUpIcon
                fill="#4C4C4C"
                className={cx('defaultArrowUp', { hidden: !isOpen })}
                width={32}
                height={32}
              />
            }
            {<ArrowDownIcon className={cx('defaultArrowDown', { hidden: isOpen })} width={32} height={32} />}
          </span>
        </div>
      </OverlayTrigger>
    )
  }

  render() {
    const { className, selectedValues } = this.props
    const {
      searchValue,
      filteredOptions,
      selectedView,
      viewOptions,
      pageNumber = 1,
      paginatedView,
      pageSize
    } = this.state
    const paginatedOptions = this.getPaginatedOptions(filteredOptions, pageNumber)
    const checkboxWrapperClass = this.getCheckboxValueStyle(paginatedOptions.length)
    return (
      <div
        ref={el => {
          this.checkboxFilterWrapper = el
        }}
        className={cx('checkbox-filter', className)}
      >
        {this.renderSelectedValueBox()}
        <Popup isOpen={this.state.isOpen} className="filter-popup Z2-shadow MT0">
          <div className={cx(className, 'check')}>
            <Box className={cx('items', 'block')}>
              <>
                <div className={'flex'}>
                  <Input
                    clearButton
                    placeholder="Search"
                    className={cx('input', 'search-input-box', 'flex1')}
                    value={searchValue}
                    iconName={<SearchIcon width={20} height={20} />}
                    didClickClearButton={this.clearSearch}
                    textDidChange={this.searchChanged}
                  />
                  <div className={'flex1'} onClick={stopPropogation}>
                    <DropDownList
                      id="dd-values-filter"
                      className="dd-values-filter"
                      selectedValue={selectedView}
                      options={viewOptions}
                      onValueChange={this.onViewChange}
                    />
                  </div>
                </div>
                {this.renderQuickActionPanel()}
                <div className={this.getCheckboxListStyle()}>
                  {paginatedOptions.map(option => {
                    const { id, aliasValue } = option
                    return (
                      <div key={id} className={checkboxWrapperClass}>
                        <CheckBox
                          className={'item'}
                          valueDidChange={this.valueDidChange(id)}
                          checked={selectedValues.has(id)}
                          showTooltipOnHover={paginatedView}
                          label={
                            searchValue ? (
                              <Highlighter
                                searchWords={[searchValue]}
                                textToHighlight={aliasValue}
                                highlightClassName="highlight-text"
                              />
                            ) : (
                              aliasValue
                            )
                          }
                        />
                      </div>
                    )
                  })}
                </div>
                {paginatedView && (
                  <div className="pagination-footer">
                    <PaginationControls
                      page={pageNumber}
                      maxPages={Math.ceil(filteredOptions.length / pageSize)}
                      onPageChange={this.selectPage}
                    />
                  </div>
                )}
              </>
            </Box>
          </div>
        </Popup>
      </div>
    )
  }
}

CheckBoxFilter.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  selectedValues: PropTypes.instanceOf(Set),
  valueDidChange: PropTypes.func,
  className: PropTypes.string,
  allowClearAll: PropTypes.bool,
  defaultValues: PropTypes.array,
  pageSize: PropTypes.number,
  maxColumns: PropTypes.number
}

CheckBoxFilter.defaultProps = {
  valueDidChange: noop,
  options: {},
  allowClearAll: true,
  label: ''
}

export default CheckBoxFilter
