import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Form } from 'react-bootstrap'
import InputField from './DataFeedsTab/InputField'

import { updateGroup, updateGroupForm } from '../../../actions/group-edit-actions'

class GeneralTab extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editGroupName: null,
      editSeatCount: null
    }
  }

  componentDidUpdate(prevProps) {
    // set form state when group loads
    if (this.props.basicInfo.id !== 'new' && prevProps.basicInfo.name !== this.props.basicInfo.name) {
      const { name, seatCount } = this.props.basicInfo

      this.setState({
        editGroupName: name,
        editSeatCount: seatCount
      })
    }
  }

  handleTextInput = event => {
    const { id, value } = event.target
    this.setState({ [id]: value }, this.updateReduxState)
  }

  updateReduxState = () => {
    this.props.updateGroupForm('general', {
      name: this.state.editGroupName,
      seatCount: this.state.editSeatCount
    })
  }

  groupNameValidation = value => {
    if (value === null) return null
    if (value.length) return 'success'
    return 'error'
  }

  seatCountValidation = value => {
    if (value === null) return null
    if (isNaN(value)) return 'error'
    if (value < 1) return 'error'
    return 'success'
  }

  render() {
    const { editGroupName, editSeatCount } = this.state

    return (
      <div className="group-edit-general-tab">
        <Form horizontal>
          <h4>Basic Info</h4>

          <br />

          <InputField
            id="editGroupName"
            label="Group Name"
            value={editGroupName}
            placeholder="Group Name"
            onChange={this.handleTextInput}
            validationFunc={this.groupNameValidation}
            validationText="Name is required"
          />

          <InputField
            id="editSeatCount"
            label="Number of Seats"
            value={editSeatCount}
            placeholder="Seat Count"
            onChange={this.handleTextInput}
            validationFunc={this.seatCountValidation}
            validationText="Must be a valid number greater than 0"
          />
        </Form>
      </div>
    )
  }
}

GeneralTab.propTypes = {
  basicInfo: PropTypes.object,
  updateGroupForm: PropTypes.func
}

export default connect(null, {
  updateGroup,
  updateGroupForm
})(GeneralTab)
