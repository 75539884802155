import { fork, takeLatest, put, call, all } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import axios from 'axios'
import { get, values } from 'lodash'
import constants from '../constants/constants'

import * as actions from '../actions/delivery-config-actions'
import { createAlert } from '../actions/app-actions'
import { createDeliveriesLinkUrl } from '../utils/delivery-center'

export function* updateDeliveryConfigFormData(id) {
  try {
    const response = yield call(() =>
      axios.get(`/api/deliverable/${id}`, {
        params: {
          details: true
        }
      })
    )
    yield put({
      type: actions.UPDATE_CONFIG_FORM_DATA,
      deliverableData: response.data
    })
    const qcReportId = response && response.data ? response.data.qcReportId : null
    if (qcReportId) {
      yield put({
        type: actions.FETCH_REPORT_TABS,
        payload: qcReportId
      })
    }
  } catch (error) {
    console.error(error)
  }
}

function* saveBasicDetails(action) {
  try {
    const { payload, callback: successCallBack } = action
    if (payload.id && payload.id !== 'new') {
      yield upadteBasicDetails(payload)
    } else {
      const saveRes = yield createNewDeliverable(payload)
      payload.id = saveRes.data.id
    }
    const link = createDeliveriesLinkUrl(payload)
    if (window.location.pathname !== link) {
      yield all([
        put(replace(link)), // this will update url and breadcrumbs
        updateDeliveryConfigFormData(payload.id) // this will update data in the config form
      ])
    }

    yield put({
      type: actions.SAVE_BASIC_DETAILS_FORM_SUCCESS
    })

    yield put(createAlert('success', 'Basic information for Deliverable successfully saved', 'Basic Info updated'))

    if (successCallBack) successCallBack()
  } catch (error) {
    console.error(error)
    yield put(createAlert('danger', error.message, 'Error saving basic info'))
  }
}

function* createNewDeliverable(payload) {
  return yield call(() => axios.post('/api/deliverable/basic', payload))
}
function* upadteBasicDetails(payload) {
  return yield call(() => axios.put('/api/deliverable/basic', payload))
}

function* fetchCategories(action) {
  try {
    const { offset, limit, sortOrder, sortBy, searchValue, searchCategoryIds } = action.payload || {}

    const response = yield call(() =>
      axios.get('/api/deliverables/categories', {
        params: {
          offset,
          size: limit,
          sortBy,
          sortOrder,
          searchValue,
          searchCategoryIds: Array.isArray(searchCategoryIds) ? searchCategoryIds.join(',') : null
        }
      })
    )
    if (response && response.data) {
      yield put({
        type: actions.FETCH_DELIVERABLE_CATEGORIES_SUCCESS,
        payload: response.data.categories,
        totalCount: response.data.totalCount,
        filteredCount: response.data.filteredCount,
        offset,
        limit,
        sortBy,
        sortOrder
      })
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.FETCH_DELIVERABLE_CATEGORIES_ERROR
    })
  }
}

function* fetchCategoryConfig(action) {
  try {
    const { deliverableId: id } = action
    if (!id || id === 'new') {
      throw new Error('Deliverable Id missing')
    }
    const response = yield call(() => axios.get(`/api/deliverable/${id}/category-config`))
    if (response && response.data) {
      yield put({
        type: actions.FETCH_DELIVERABLE_CATEGORY_CONFIG_SUCCESS,
        categories: response.data.categories,
        categoryLabel: response.data.categoryLabel,
        inclExclQuery: response.data.incExc ? response.data.incExc.query : null,
        brandTrackers: response.data.brandTrackers,
        associatedBrandMap: response.data.associatedBrandMap
      })
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.FETCH_DELIVERABLE_CATEGORY_CONFIG_ERROR
    })
  }
}

function* saveCategoryConfig(action) {
  try {
    const {
      payload: { selectedCategoryIds, deliverableId, categoryLabel, inclExclQuery, brandTrackers },
      callback: successCallBack
    } = action

    if (!deliverableId || deliverableId === 'new') {
      throw new Error('Deliverable Id missing')
    }
    yield call(() =>
      axios.post('/api/deliverable/category-config', {
        deliverableId,
        updatedCategoryIds: selectedCategoryIds,
        categoryLabel,
        inclExclQuery,
        brandTrackers
      })
    )
    yield all([updateDeliveryConfigFormData(deliverableId), fetchCategoryConfig({ deliverableId })])
    yield put(createAlert('success', 'Category config for Deliverable successfully saved', 'Category config updated'))
    if (successCallBack) successCallBack()
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.SAVE_DELIVERABLE_CATEGORY_CONFIG_ERROR
    })
    yield put(createAlert('danger', error.message, 'Error saving category config'))
  }
}

function* reportSearch(action) {
  try {
    const { searchText } = action
    const response = yield call(() =>
      axios.get(`/api/reports-list`, {
        params: {
          sort: 'name',
          count: 20,
          nameParam: searchText
        }
      })
    )
    yield put({
      type: actions.REPORT_SEARCH_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.REPORT_SEARCH_ERROR
    })
  }
}

function* fetchReportTabs(action) {
  try {
    const { payload: id } = action
    const response = yield call(() => axios.get(`/api/reports/${id}/edit`))
    const reportTabs = values(get(response, 'data.tabs', {}))
    yield put({
      type: actions.REPORT_TABS_LOAD_SUCCESS,
      payload: reportTabs
    })
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.REPORT_TABS_LOAD_FAILURE
    })
  }
}

function* saveReports(action) {
  try {
    const { payload, callback: successCallBack } = action
    yield call(() =>
      axios.post(`/api/deliverable/reports`, {
        ...payload
      })
    )
    yield updateDeliveryConfigFormData(payload.deliverableId)
    yield put(
      createAlert('success', 'Linked report details for Deliverable successfully saved', 'Report details updated')
    )
    if (successCallBack) successCallBack()
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.SAVE_REPORTS_ERROR
    })
    yield put(createAlert('danger', error.message, 'Error saving reports'))
  }
}

function* loadScriptsData({ deliverableId }) {
  try {
    const response = yield call(() => axios.get(`/api/deliverable-scripts/${deliverableId}`))

    yield put({
      type: actions.LOAD_SCRIPTS_DATA_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: actions.LOAD_SCRIPTS_DATA_ERROR,
      error: error.message
    })
    yield put(createAlert('danger', error.message, 'Error fetching script'))
  }
}

function* saveDeliverableScriptsData(action) {
  try {
    const {
      payload: { deliverableId, ...others },
      callback: successCallBack
    } = action

    if (!deliverableId || deliverableId === 'new') {
      throw new Error('Deliverable Id missing')
    }
    yield call(() =>
      axios.post('/api/deliverable-scripts', {
        deliverableId,
        ...others
      })
    )
    yield loadScriptsData({ deliverableId })

    yield put(createAlert('success', 'Script details for Deliverable successfully saved', 'Script details updated'))

    if (successCallBack) successCallBack()
  } catch (error) {
    console.error('Error saving deliverable script: ', error.message)

    yield put({
      type: actions.SAVE_SCRIPTS_FORM_ERROR
    })

    yield put(createAlert('danger', error.message, 'Error saving script'))
  }
}

function* scheduleNextDelivery(action) {
  try {
    const {
      payload: { deliverableId, afterDate },
      callback: successCallBack
    } = action

    if (!deliverableId || deliverableId === 'new') {
      throw new Error('Deliverable Id missing')
    }
    yield call(() =>
      axios.post('/api/deliverable/schedule-next-delivery', {
        deliverableId,
        afterDate
      })
    )
    yield updateDeliveryConfigFormData(deliverableId)
    if (successCallBack) successCallBack()
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.SCHEDULE_NEXT_DELIVERY_ERROR
    })
  }
}

function* fetchScriptTemplate() {
  try {
    const scriptTemplate = yield call(() => axios.get('/api/deliverable-scripts/template'))

    yield put(actions.fetchScriptTemplateSuccess(scriptTemplate.data))
  } catch (error) {
    console.error(error)
    yield put(actions.fetchScriptTemplateFailed())
    yield put(createAlert('danger', error.message, 'Error fetching script template'))
  }
}

function* fetchBrandSuggestions(action) {
  try {
    const response = yield call(() =>
      axios.get('/api/taxonomy/brands/suggestions', {
        params: {
          responseType: 'qc-tool',
          fullPath: action.searchTerm,
          limit: 100
        }
      })
    )
    const data = response.data.suggestions
    yield put({
      type: actions.HANDLE_BRAND_SEARCH_CHANGE_SUCCESS,
      data: [...data]
    })
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.HANDLE_BRAND_SEARCH_CHANGE_FAILED
    })
    yield put(createAlert('danger', error.message, 'Error in fetching brand suggestions'))
  }
}

function* fetchTypeChildren(action) {
  let parent
  try {
    const replaceRegex = new RegExp(`^${constants.parentElementPrefix}`)
    parent = action.data.value.replace(replaceRegex, '')
    const filterApiMap = {
      brand: '/api/taxonomy/brands/suggestions',
      category: '/api/taxonomy/categories/suggestions'
    }
    const params = {
      responseType: 'qc-tool',
      fullPath: parent,
      isLeaf: true,
      isActive: true,
      limit: 500
    }
    const result = yield call(() => axios.get(filterApiMap[action.data.type], { params }))
    yield put({
      type: actions[`${action.type}_SUCCESS`],
      data: result.data.suggestions,
      ruleId: action.data.id,
      ruleGroupLabel: parent,
      btIndex: action.data.btIndex,
      btqIndex: action.data.btqIndex
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: actions[`${action.type}_ERROR`]
    })
    yield put(createAlert('danger', error.message, `Error in fetching children of ${parent}`))
  }
}

function* getBTInsertSQL(action) {
  try {
    const result = yield call(() => axios.get(`/api/brand-tracker/${action.btId}/insert-sql`))
    yield put({
      type: `${action.type}_SUCCESS`,
      sql: result.data.sql
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: `${action.type}_ERROR`
    })
    yield put(createAlert('danger', error.message, `Error in fetching insert sql`))
  }
}

function* getBTUpdateSQL(action) {
  try {
    const result = yield call(() => axios.get(`/api/brand-tracker/${action.btId}/update-sql`))
    yield put({
      type: `${action.type}_SUCCESS`,
      sql: result.data.sql
    })
  } catch (error) {
    window.captureException(error)
    yield put({
      type: `${action.type}_ERROR`
    })
    yield put(createAlert('danger', error.message, `Error in fetching update sql`))
  }
}

function* configFlow() {
  yield takeLatest(actions.INITIALIZE_EXISTING, action => updateDeliveryConfigFormData(action.payload.id))
  yield takeLatest(actions.SAVE_BASIC_DETAILS_FORM, saveBasicDetails)
  yield takeLatest(actions.FETCH_DELIVERABLE_CATEGORIES, fetchCategories)
  yield takeLatest(actions.FETCH_DELIVERABLE_CATEGORY_CONFIG, fetchCategoryConfig)
  yield takeLatest(actions.SAVE_DELIVERABLE_CATEGORY_CONFIG, saveCategoryConfig)
  yield takeLatest(actions.REPORT_SEARCH, reportSearch)
  yield takeLatest(actions.SAVE_REPORTS, saveReports)
  yield takeLatest(actions.LOAD_SCRIPTS_DATA, loadScriptsData)
  yield takeLatest(actions.SAVE_SCRIPTS_FORM, saveDeliverableScriptsData)
  yield takeLatest(actions.SCHEDULE_NEXT_DELIVERY, scheduleNextDelivery)
  yield takeLatest(actions.FETCH_REPORT_TABS, fetchReportTabs)
  yield takeLatest(actions.FETCH_DELIVERY_SCRIPT_TEMPLATE, fetchScriptTemplate)
  yield takeLatest(actions.HANDLE_BRAND_SEARCH_CHANGE, fetchBrandSuggestions)
  yield takeLatest(
    [
      actions.FETCH_INC_EXC_CHILDREN,
      actions.FETCH_BT_CHILDREN,
      actions.FETCH_BTQ_CHILDREN,
      actions.FETCH_BTBQ_CHILDREN
    ],
    fetchTypeChildren
  )
  yield takeLatest(actions.GET_BT_INSERT_SQL, getBTInsertSQL)
  yield takeLatest(actions.GET_BT_UPDATE_SQL, getBTUpdateSQL)
}

export default [fork(configFlow)]
