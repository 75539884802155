import { createAction } from '@reduxjs/toolkit'

const CategoryGuidelineActions = {
  fetch: createAction('FETCH_CATEGORY_GUIDELINE'),
  successLoad: createAction('SUCCESS_CATEGORY_GUIDELINE'),
  failedLoad: createAction('FAILED_CATEGORY_GUIDELINE'),
  updateSettings: createAction('UPDATE_CATEGORY_GUIDELINE_SETTINGS'),
  clearFilters: createAction('CLEAR_FILTERS_CATEGORY_GUIDELINE'),

  download: createAction('DOWNLOAD_CATEGORY_GUIDELINE'),

  save: createAction('SAVE_CATEGORY_GUIDELINE')
}

export default CategoryGuidelineActions
