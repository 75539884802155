import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './index.scss'

const MenuList = props => {
  const onOptionClick = ({ option, key }) => {
    if (option.onClick) {
      return option.onClick()
    } else {
      return props.valueDidChange(key)
    }
  }

  const { options } = props
  const keys = props.keys || Object.keys(options)

  return (
    <ul className={`menuList ${props.className}`}>
      {keys.map(key => {
        const option = options[key]
        if (option.href) {
          return (
            <li key={key}>
              <Link to={option.href} target={option.target}>
                {option.name}
              </Link>
            </li>
          )
        }

        return (
          <li key={key}>
            <span onClick={() => onOptionClick({ option, key })}>{option.name}</span>
          </li>
        )
      })}
    </ul>
  )
}

MenuList.propTypes = {
  options: PropTypes.object,
  keys: PropTypes.array,
  valueDidChange: PropTypes.func,
  className: PropTypes.string
}

MenuList.defaultProps = {
  valueDidChange: () => {}
}

export default MenuList
