import { takeLatest, call, put, fork, select } from 'redux-saga/effects'
import axios from 'axios'
import { createAlert } from './../../actions/app-actions'

import {
  refreshSamplingSessionsTable,
  generateNewSampleForSupraQC,
  fetchSampleDataForSession,
  refreshSamplingQCTable,
  fetchRegexMatchesAcrossSample,
  supraQCToolApplyQuerySamplingQC,
  triggerDictionaryChecks,
  toggleDictionaryEntryDeletedStatus,
  fetchErrorDetailsForSamplingSession,
  cancelSamplingSessionJob
} from './actions'

import { onDictionaryTableRefresh } from 'actions/supra-qc-tool-actions'

import {
  fetchSamplingSessionsStarted,
  fetchSamplingSessionsSucceeded,
  fetchSamplingSessionsFailed,
  createNewSampleStarted,
  createNewSampleSucceeded,
  createNewSampleFailed,
  fetchSamplingQCDataStarted,
  fetchSampleDataSucceeded,
  fetchMatchesAcrossSampleStarted,
  fetchMatchesAcrossSampleCompleted,
  fetchMatchesAcrossSampleFailed,
  addRuleToDictionary,
  addRuleToDictionaryCompleted,
  closeRegexUtilityModal,
  closeAddEntriesToDictModal,
  updateErrorMessageForRunId
} from './slices'

const EMPTY_REGEX = '^\\s*\\s*$'

const getSupraQCToolV2State = state => state.supraQCToolV2

function* fetchSamplingSessions(action) {
  const apiPath = '/api/supra-qc/sampling-sessions'
  try {
    yield put(fetchSamplingSessionsStarted())
    const { data } = yield call(() => axios.get(apiPath))
    yield put(fetchSamplingSessionsSucceeded(data))
  } catch (error) {
    window.captureException(error)
    yield put(fetchSamplingSessionsFailed())
    yield put(createAlert('danger', error.response.data, 'Sampling sessions fetching failed'))
  }
}

function* triggerDictionaryChecksMultipleDomains(action) {
  const apiPath = '/api/supra-qc/trigger-dictionary-checks'
  try {
    const { domains } = action.payload
    yield call(() => axios.post(apiPath, { domains }))
    yield put(createAlert('success', 'Please check the job monitoring page', 'Success'))
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data, 'Sampling sessions fetching failed'))
  }
}

function* generateNewSample(action) {
  const apiPath = '/api/supra-qc/sampling-sessions'
  try {
    yield put(createNewSampleStarted())
    const { data } = yield call(() =>
      axios.post(apiPath, {
        ...action.payload
      })
    )
    yield put(createNewSampleSucceeded(data))
    yield put(createAlert('success', 'For running click "Dictionary Checks" => "Orchestrate" ', 'Job created'))
    yield put({ type: refreshSamplingSessionsTable.type })
  } catch (error) {
    window.captureException(error)
    yield put(createNewSampleFailed())
    yield put(createAlert('danger', error.response.data, 'Sample generation failed'))
  }
}

function* fetchSampleData(action) {
  const body = {}
  if (action.payload.queryTree) {
    body.queryTree = action.payload.queryTree
  }

  const supraQCToolV2 = yield select(getSupraQCToolV2State)
  const { samplingQCTab } = supraQCToolV2
  const sessionId = action.payload.sessionId ? action.payload.sessionId : samplingQCTab.sessionId
  const apiPath = `/api/supra-qc/sampling-sessions/${sessionId}`
  try {
    yield put(fetchSamplingQCDataStarted({ sessionId: sessionId }))
    const { data } = yield call(() => axios.post(apiPath, body))
    yield put(fetchSampleDataSucceeded(data))
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data, 'Fetching sample data failed'))
  }
}

function* fetchMatchesAcrossSample(action) {
  const { sessionId, regex, templateId, template } = action.payload
  if (regex === EMPTY_REGEX) return
  // TODO: handle scenario where more than 5 underscores in template
  const apiPath = `/api/supra-qc/sampling-sessions/${sessionId}/regex-matches`
  try {
    yield put(fetchMatchesAcrossSampleStarted())
    const { data } = yield call(() => axios.post(apiPath, { regex, templateId, template }))
    yield put(fetchMatchesAcrossSampleCompleted(data))
  } catch (error) {
    window.captureException(error)
    yield put(fetchMatchesAcrossSampleFailed())
    yield put(createAlert('danger', error.response.data, 'Failed to fetch regex matches across sample'))
  }
}

function* addRuleToDictionarySaga(action) {
  const dictionaryEntry = action.payload
  const apiPath = `/api/supra-qc/add-to-dictionary`
  try {
    yield call(() => axios.post(apiPath, { dictionaryEntry }))
    yield put(closeRegexUtilityModal())
    yield put(closeAddEntriesToDictModal())
    yield put(refreshSamplingQCTable({}))
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data, 'Failed to add rule to dictionary'))
  } finally {
    yield put(addRuleToDictionaryCompleted())
  }
}

function* enableDisableDictionaryEntry(action) {
  const dictionaryId = action.payload.dictionaryId
  if (!dictionaryId) return
  const apiPath = `/api/supra-qc/dictionary/${dictionaryId}`
  try {
    yield call(() =>
      axios.put(apiPath, {
        deleted: action.payload.deleted
      })
    )
    yield put(createAlert('success', 'Successfully updated dictionary entry', 'Enable/Disable Dictionary Entry'))
    yield put(onDictionaryTableRefresh())
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data, 'Enable/Disable Dictionary Entry'))
  }
}

function* fetchSamplingSessionErrorDetails(action) {
  const runId = action.payload.runId
  if (!runId) return
  const apiPath = `/api/supra-qc/sampling-sessions/error-details?runId=${runId}`
  try {
    yield put(
      updateErrorMessageForRunId({
        runId,
        errorMessage: 'Loading ...'
      })
    )
    const { data } = yield call(() => axios.get(apiPath))
    yield put(
      updateErrorMessageForRunId({
        runId,
        errorMessage: data.error
      })
    )
  } catch (error) {
    window.captureException(error)
    yield put(
      updateErrorMessageForRunId({
        runId,
        errorMessage: 'Failed to fetch error details from the API'
      })
    )
  }
}

function* cancelRunningJob(action) {
  const sessionId = action.payload
  if (!sessionId) return
  const apiPath = `/api/supra-qc/sampling-sessions/${sessionId}/cancel`
  try {
    yield call(() => axios.post(apiPath))
    yield put(createAlert('success', `Sucessfully cancelled running job: ${sessionId}`, 'Cancel Running Job'))
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data, 'Cancel Running Job'))
  }
}

function* supraQCToolV2Flow() {
  yield takeLatest(refreshSamplingSessionsTable.type, fetchSamplingSessions)
  yield takeLatest(generateNewSampleForSupraQC.type, generateNewSample)
  yield takeLatest(fetchSampleDataForSession.type, fetchSampleData)
  yield takeLatest(supraQCToolApplyQuerySamplingQC.type, fetchSampleData)
  yield takeLatest(refreshSamplingQCTable.type, fetchSampleData)
  yield takeLatest(fetchRegexMatchesAcrossSample.type, fetchMatchesAcrossSample)
  yield takeLatest(fetchRegexMatchesAcrossSample.type, fetchMatchesAcrossSample)
  yield takeLatest(addRuleToDictionary.type, addRuleToDictionarySaga)
  yield takeLatest(triggerDictionaryChecks.type, triggerDictionaryChecksMultipleDomains)
  yield takeLatest(toggleDictionaryEntryDeletedStatus.type, enableDisableDictionaryEntry)
  yield takeLatest(fetchErrorDetailsForSamplingSession.type, fetchSamplingSessionErrorDetails)
  yield takeLatest(cancelSamplingSessionJob.type, cancelRunningJob)
}

export default [fork(supraQCToolV2Flow)]
