import { createSlice } from '@reduxjs/toolkit'

const cmeRetrainer = createSlice({
  name: 'cmeRetrainer',
  initialState: {
    latestRetrainerDagRun: {},
    lastSuccessfulRetrainedModel: {},
    latestDeploymentDagRun: {},
    currentProductionModel: {},
    recentModels: []
  },
  reducers: {
    fetchLatestRetrainerDagRunSucceeded(state, action) {
      state.latestRetrainerDagRun = { ...action.payload }
    },
    fetchLatestDeploymentDagRunSucceeded(state, action) {
      state.latestDeploymentDagRun = { ...action.payload }
    },
    fetchLastSucessfulRetrainedModelSucceeded(state, action) {
      state.lastSuccessfulRetrainedModel = { ...action.payload }
    },
    fetchCurrentProductionModelSucceeded(state, action) {
      state.currentProductionModel = { ...action.payload }
    },
    fetchRecentModelsSucceeded(state, action) {
      state.recentModels = [...action.payload]
    }
  }
})

export const {
  fetchLatestRetrainerDagRunSucceeded,
  fetchLastSucessfulRetrainedModelSucceeded,
  fetchLatestDeploymentDagRunSucceeded,
  fetchRecentModelsSucceeded,
  fetchCurrentProductionModelSucceeded
} = cmeRetrainer.actions

export default cmeRetrainer.reducer
