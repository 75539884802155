import constants from 'constants/constants'
import { isRuleGroup } from './portal-query-builder'

/**
 * This NIQ version of 'es-query-utils'
 * The original file is located at 'src/utils/es-query-utils.js'.
 * 1. add boolan field to the query
 */

// operators for which description_tokens field needs to be queried
const DESC_KEYWORD_QUERY_OPERATORS = [
  'startsWith',
  'notStartsWith',
  'endsWith',
  'notEndsWith',
  'exact',
  'notMatches',
  'wildcard',
  'notWildcard'
]

// operators for which description_stem field needs to be queried
const DESC_STEM_QUERY_OPERATORS = ['matchesStem', 'notMatchesStem']

// operators for which we need to build a mustNot clause
const NEGATIVE_OPERATORS = [
  'notMatches',
  'notContains',
  'notStartsWith',
  'notEndsWith',
  'notWildcard',
  'neq',
  'notMatchesStem',
  'notRegex'
]

/**
 * This function takes in the json query from QueryBuilder
 * component and converts it to Elastic Search query. It also
 * takes queryCurrent and queryPreevious params which determines
 * whether the query runs for an attribute in its current values
 * and/or previous values respectively
 *
 * @export
 * @param {JSON} queryTree
 * @param {boolean} queryCurrent
 * @param {boolean} queryPrevious
 * @returns
 */
export default function createESQuery(queryTree, queryCurrent, queryPrevious) {
  if (!queryTree) {
    return null
  }
  return processRuleGroup(queryTree, queryCurrent, queryPrevious)
}

/**
 * This function creates query for a rule group
 * @param {any} ruleGroup
 * @param {any} queryCurrent
 * @param {any} queryPrevious
 * @returns
 */
function processRuleGroup(ruleGroup, queryCurrent, queryPrevious) {
  // if rule group disabled or has no rules
  if (!ruleGroup || ruleGroup.isDisabled || !ruleGroup.rules || ruleGroup.rules.length < 1) {
    return null
  }
  let notClauses = []
  const matchClauses = []
  ruleGroup.rules.forEach(element => {
    const isGroup = isRuleGroup(element)
    const fn = isGroup ? processRuleGroup : processRule
    const clause = fn(element, queryCurrent, queryPrevious)
    if (clause) {
      if (!isGroup && isNegativeClause(element)) {
        notClauses.push(clause)
      } else {
        matchClauses.push(clause)
      }
    }
  })

  // if no clause found return null
  if (notClauses.length === 0 && matchClauses.length === 0) return null

  // if only one matchClause found return it directly
  if (notClauses.length === 0 && matchClauses.length === 1) {
    return matchClauses[0]
  }

  // create a bool wrapper
  // determine bool combinator
  const boolCombinator = ruleGroup.combinator === 'and' ? 'must' : 'should'
  const minShouldMatch = boolCombinator === 'should' && matchClauses.length > 0 ? 1 : null

  if (ruleGroup.combinator !== 'and') {
    notClauses.forEach(clause => {
      matchClauses.push({
        bool: {
          mustNot: clause
        }
      })
    })
    notClauses = []
  }

  return {
    bool: {
      [boolCombinator]: matchClauses,
      mustNot: notClauses,
      minimum_number_should_match: minShouldMatch
    }
  }
}

/**
 * function to check whether the rule generates a mustNot clause
 */
function isNegativeClause(rule) {
  if (rule && rule.operator) {
    return NEGATIVE_OPERATORS.includes(rule.operator)
  }
  return false
}

/**
 * function to remove any special characters that might be
 * interpreted as a wildcard symbol accidentally
 */
function removeWildCardChars(value) {
  if (value) {
    return value.replace(/\*|\?/g, ' ')
  }
  return value
}

function wildCardQuery(field, value) {
  return {
    wildcard: {
      [field]: {
        value
      }
    }
  }
}

function regexQuery(field, value) {
  return {
    regexp: {
      [field]: {
        value,
        flags: 'EMPTY'
      }
    }
  }
}

function matchQuery(field, value) {
  return {
    match: {
      [field]: value
    }
  }
}

function termQuery(field, value) {
  return {
    term: {
      [field]: value
    }
  }
}

function matchPhrase(field, value) {
  return {
    match_phrase: {
      [field]: value
    }
  }
}

function multiMatchQuery(fields, value, type) {
  return {
    multi_match: {
      query: value,
      fields,
      type
    }
  }
}

/**
 * function to generate ES query for the rule
 */
function processRule(rule, queryCurrent, queryPrevious) {
  if (!rule || rule.isDisabled) return null

  const { field, operator, value } = rule
  const isBoolean = typeof value === 'boolean'
  if (!isBoolean && (!value || !operator || value.startsWith(constants.parentElementPrefix))) {
    return null
  }

  let obj = {}
  switch (field) {
    case 'price':
    case 'distinct_msg_uuid':
    case 'distinct_mailbox_uuid':
    case 'cnt_msg':
    case 'percentage_msg':
    case 'cnt_mailbox':
    case 'percentage_msg_total_cusum':
    case 'nankey':
    case 'sales':
    case 'segment_confidence':
    case 'module_confidence':
    case 'brand_confidence':
    case 'subcategory_confidence':
      obj = createNumericQuery(rule, queryCurrent, queryPrevious)
      break
    case 'description':
      obj = createDescriptionQuery({ value, operator, queryCurrent, queryPrevious })
      break
    case 'coding_mode':
    case 'item_type':
    case 'omnisales_module':
    case 'brand_owner':
      obj = createStringQuery(field, value, operator)
      break
    case 'invalid_category':
    case 'invalid_brand':
      obj = createBooleanQuery(field, value)
      break
    case 'subject':
    case 'from_addr':
    case 'domain':
    case 'template':
      obj = createSubjectFromAddrDomainQuery({
        field: constants.esFields[field],
        value,
        operator
      })
      break
    case 'email_type':
    case 'bbx_supra':
      obj = termQuery(constants.esFields[field], value)
      break
    default:
      const type = constants.esFields[field]
      if (queryCurrent && queryPrevious) {
        obj = multiMatchQuery([type, `last_${type}`], value)
      } else {
        obj = matchQuery(queryPrevious ? `last_${type}` : type, value)
      }
      break
  }
  return obj
}

function createNumericQuery(rule, queryCurrent, queryPrevious) {
  const { field, operator, value } = rule
  const numValue = parseFloat(value)
  const type = constants.esFields[field]
  if (!type || numValue == null) {
    return null
  }
  if (queryCurrent && queryPrevious) {
    switch (operator) {
      case 'lt':
      case 'gt':
      case 'lte':
      case 'gte':
        return {
          bool: {
            should: [createRangeQuery(type, operator, numValue), createRangeQuery(`last_${type}`, operator, numValue)]
          }
        }
      default:
        return multiMatchQuery([type, `last_${type}`], numValue)
    }
  } else {
    const searchField = queryPrevious ? `last_${type}` : type
    switch (operator) {
      case 'lt':
      case 'gt':
      case 'lte':
      case 'gte':
        return createRangeQuery(searchField, operator, numValue)
      default:
        return matchQuery(searchField, numValue)
    }
  }
}

function createRangeQuery(field, operator, value) {
  return {
    range: {
      [field]: {
        [operator]: value
      }
    }
  }
}

export function createDescriptionQuery({ value, operator, queryCurrent, queryPrevious }) {
  let obj = null
  const wcCleanValue = removeWildCardChars(value)
  if (queryCurrent && queryPrevious) {
    // query both current & previous description fields
    switch (operator) {
      case 'startsWith':
      case 'notStartsWith':
        obj = {
          bool: {
            should: [
              wildCardQuery('description', `${wcCleanValue}*`),
              wildCardQuery('last_description', `${wcCleanValue}*`)
            ]
          }
        }
        break
      case 'notEndsWith':
      case 'endsWith':
        obj = {
          bool: {
            should: [
              wildCardQuery('description', `*${wcCleanValue}`),
              wildCardQuery('last_description', `*${wcCleanValue}`)
            ]
          }
        }
        break
      case 'wildcard':
      case 'notWildcard':
        obj = {
          bool: {
            should: [wildCardQuery('description', value), wildCardQuery('last_description', value)]
          }
        }
        break
      case 'exact':
      case 'notMatches':
        obj = {
          bool: {
            should: [matchQuery('description', value), matchQuery('last_description', value)]
          }
        }
        break
      default:
        obj = multiMatchQuery(['description_tokens', 'last_description_tokens'], value, 'phrase')
    }
  } else {
    // query only one description field
    let descriptionField = 'description'
    if (queryPrevious) descriptionField = `last_${descriptionField}`
    if (DESC_STEM_QUERY_OPERATORS.includes(operator)) {
      descriptionField += '_stem'
    } else if (!DESC_KEYWORD_QUERY_OPERATORS.includes(operator)) {
      descriptionField += '_tokens'
    }

    obj = createStringQuery(descriptionField, value, operator)
  }
  return obj
}

export function createStringQuery(field, value, operator) {
  let obj = null
  switch (operator) {
    case 'startsWith':
    case 'notStartsWith':
      obj = wildCardQuery(field, `${removeWildCardChars(value)}*`)
      break
    case 'endsWith':
    case 'notEndsWith':
      obj = wildCardQuery(field, `*${removeWildCardChars(value)}`)
      break
    case 'wildcard':
    case 'notWildcard':
      obj = wildCardQuery(field, value)
      break
    case 'exact':
    case 'notMatches':
      obj = matchQuery(field, value)
      break
    case 'matchesStem':
    case 'notMatchesStem':
      obj = matchPhrase(field, value)
      break
    default:
      obj = matchPhrase(field, value)
  }
  return obj
}

function createBooleanQuery(field, value) {
  const obj = termQuery(field, value)
  return obj
}

function createSubjectFromAddrDomainQuery({ field, value, operator }) {
  let obj = null
  const wcCleanValue = removeWildCardChars(value)
  // query only one description field
  let fieldName = field
  if (operator === 'regex') {
    fieldName = field
  } else if (DESC_STEM_QUERY_OPERATORS.includes(operator)) {
    fieldName += '_stem'
  } else if (!DESC_KEYWORD_QUERY_OPERATORS.includes(operator)) {
    fieldName += '_tokens'
  }
  switch (operator) {
    case 'regex':
    case 'notRegex':
      obj = regexQuery(fieldName, value)
      break
    case 'startsWith':
    case 'notStartsWith':
      obj = wildCardQuery(fieldName, `${wcCleanValue}*`)
      break
    case 'endsWith':
    case 'notEndsWith':
      obj = wildCardQuery(fieldName, `*${wcCleanValue}`)
      break
    case 'wildcard':
    case 'notWildcard':
      obj = wildCardQuery(fieldName, value)
      break
    case 'exact':
    case 'notMatches':
      obj = matchQuery(fieldName, value)
      break
    case 'matchesStem':
    case 'notMatchesStem':
      obj = matchPhrase(fieldName, value)
      break
    default:
      obj = matchPhrase(fieldName, value)
  }
  return obj
}
