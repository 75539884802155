// @flow
export const TASK_RETRY = 'TASK_RETRY'
export const TASK_UPDATE = 'TASK_UPDATE'
export const TASK_DELETE = 'TASK_DELETE'
export const TASK_LOAD_SUCCEEDED = 'TASK_LOAD_SUCCEEDED'
export const TASK_LOAD_FAILED = 'TASK_LOAD_FAILED'
export const ORCHESTRATE = 'ORCHESTRATE'
export const TASK_LOAD = 'TASK_LOAD'
export const TASK_UPDATE_SUCCEEDED = 'TASK_UPDATE_SUCCEEDED'
export const TASK_CREATE = 'TASK_CREATE'
export const UPDATE_ETL_CHECKPOINT = 'UPDATE_ETL_CHECKPOINT'
export const TASK_DOAGAIN = 'TASK_DOAGAIN'
export const ETL_STATE_LOAD = 'ETL_STATE_LOAD'
export const ETL_STATE_LOAD_SUCCEEDED = 'ETL_STATE_LOAD_SUCCEEDED'
export const ETL_STATE_LOAD_FAILED = 'ETL_STATE_LOAD_FAILED'
export const UPDATE_ETL_PARAM = 'UPDATE_ETL_PARAM'
export const UPDATE_TASK_AUTO_REFRESH = 'UPDATE_TASK_AUTO_REFRESH'
export const UPDATE_TASK_SEARCH_FILTER = 'UPDATE_TASK_SEARCH_FILTER'

export function retryTask(task: Object): Object {
  return {
    type: TASK_RETRY,
    data: task
  }
}

export function updateTask(task: Object): Object {
  return {
    type: TASK_UPDATE,
    data: task
  }
}

export function cancelTask(task: Object): Object {
  return {
    type: TASK_UPDATE,
    data: { ...task, status: 'cancelled', endedAt: new Date() }
  }
}

export function doAgainTask(task: Object): Object {
  return {
    type: TASK_DOAGAIN,
    task
  }
}

export function deleteTask(task: Object): Object {
  return {
    type: TASK_DELETE,
    data: task
  }
}

export function orchestrate(taskType: String, metadata: Object): Object {
  return {
    type: ORCHESTRATE,
    taskType,
    metadata
  }
}

export function loadTasks(payload: Object): Object {
  return {
    type: TASK_LOAD,
    payload
  }
}

export function loadETLStates(search: String): Object {
  return {
    type: ETL_STATE_LOAD,
    search
  }
}

export function updateETLparam(payload: Object): Object {
  return {
    type: UPDATE_ETL_PARAM,
    payload
  }
}

export function updateEtlCheckpoint({ taskType, param, value, id }): Object {
  return {
    type: UPDATE_ETL_CHECKPOINT,
    taskType,
    param,
    value,
    id
  }
}

export function updateTaskAutoRefresh(value) {
  return {
    type: UPDATE_TASK_AUTO_REFRESH,
    value
  }
}

export function updateTaskSearchFilter(value, field) {
  return {
    type: UPDATE_TASK_SEARCH_FILTER,
    field,
    value
  }
}
