/* eslint-disable max-len */

/*!
 * deps
 */

import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import cx from 'classnames'

/**
 * Search component
 */
const Search = ({ className, color, ...otherProps }) => {
  return (
    <IconBase viewBox="0 0 15 16" {...otherProps} className={cx('search-icon', className)}>
      <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-277.000000, -170.000000)">
          <g id="ic-search" transform="translate(270.000000, 163.000000)">
            <g id="Search">
              <rect id="spacing" x="0" y="0" />
              <g id="icon" transform="translate(8.000000, 8.000000)">
                <rect
                  id="Path"
                  fill={color}
                  transform="translate(11.000000, 12.000000) rotate(-315.000000) translate(-11.000000, -12.000000) "
                  x="8"
                  y="11"
                  width="6"
                  height="2"
                />
                <circle id="Path" stroke={color} cx="5.5" cy="5.5" r="5.5" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </IconBase>
  )
}

Search.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
}

Search.defaultProps = {
  color: '#999999'
}

export default Search
