import axios from 'axios'
import moment from 'moment'
import { takeLatest, call, put, fork, select } from 'redux-saga/effects'

import config from '../../../config'
import createDownloadLink from 'utils/download-link'

import deliveryJobsActions from './deliveryJobsActions'

import { createAlert } from 'actions/app-actions'
import { DOWNLOAD_DATETIME_FORMAT } from 'constants/constants'

const STORE_PATH = 'deliveryJobs'

function* fetchDeliveryJobs({ payload = {} }) {
  try {
    const state = yield select(state => state[STORE_PATH])

    const {
      page = state.page,
      sortBy = state.sortBy,
      pageSize = state.pageSize,
      sortOrder = state.sortOrder,
      filters = state.filters
    } = payload

    // save rules table settings in store
    yield put(
      deliveryJobsActions.updateSettings({
        page,
        sortBy,
        pageSize,
        sortOrder,
        filters
      })
    )

    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/delivery-jobs`, {
        params: {
          page,
          sortBy,
          pageSize,
          sortOrder,
          filters
        }
      })
    )
    yield put(deliveryJobsActions.successLoad(data))
  } catch (error) {
    yield put(deliveryJobsActions.failedLoad())
    window.captureException(error)
    yield put(createAlert('danger', error.message, `Delivery Jobs fetch failed`))
  }
}

function* fetchDeliveryJobsByCategoryAndDeliveryDate({ payload = {} }) {
  try {
    const {
      page = 1,
      pageSize = 1,
      filters = {
        categoryId: { value: payload.categoryId },
        deliveryDate: {
          value: {
            from: payload.from,
            to: payload.to
          }
        },
        isActive: { value: true }
      }
    } = payload

    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/delivery-jobs`, {
        params: {
          page,
          pageSize,
          filters
        }
      })
    )
    yield put(deliveryJobsActions.successLoadByCategoryAndDeliveryDate(data))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.message, `Delivery Jobs fetch failed`))
  }
}

// SAVE METHOD
function* saveDeliveryJob({ payload }) {
  try {
    yield call(() => axios.post(`${config.apiBaseUrl}/delivery-jobs`, { payload }))

    yield put(createAlert('success', '', `Delivery Job saved`))

    yield put(deliveryJobsActions.fetch())
  } catch (error) {
    window.captureException(error)
    yield put(createAlert('danger', error.response.data.message || error.message, `Delivery Job saving failed`))
  }
}

// DOWNLOAD
function* downloadDeliveryJobs() {
  try {
    yield put(createAlert('success', '', 'Preparing CSV for download'))

    const { sortBy, sortOrder, filters } = yield select(state => state[STORE_PATH])

    const response = yield call(() =>
      axios({
        url: `api/delivery-jobs/download`,
        method: 'get',
        responseType: 'blob',
        params: {
          sortBy,
          sortOrder,
          filters
        }
      })
    )

    createDownloadLink(response.data, `delivery-jobs-${moment().format(DOWNLOAD_DATETIME_FORMAT)}.csv`)
  } catch (error) {
    yield put(createAlert('danger', error.message, 'Delivery Jobs CSV download failed'))
  }
}

/*
 *
 * ************* Delivery Jobs ETL tasks STATS *******************
 *
 */
function* fetchDeliveryJobsTasks() {
  try {
    yield put(deliveryJobsActions.setLoadingDeliveryJobsTasks(true))
    const { data } = yield call(() => axios.get(`${config.apiBaseUrl}/delivery-jobs/publish-stats`, {}))
    yield put(deliveryJobsActions.successfetchDeliveryJobsTasks(data))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.message, `Delivery Jobs tasks stat info fetch failed`))
  } finally {
    yield put(deliveryJobsActions.setLoadingDeliveryJobsTasks(false))
  }
}

/*
 * FLOWS
 */
function* deliveryJobsFlows() {
  yield takeLatest(deliveryJobsActions.fetch.type, fetchDeliveryJobs)
  yield takeLatest(deliveryJobsActions.save.type, saveDeliveryJob)

  yield takeLatest(deliveryJobsActions.download.type, downloadDeliveryJobs)

  yield takeLatest(deliveryJobsActions.fetchByCategoryAndDeliveryDate.type, fetchDeliveryJobsByCategoryAndDeliveryDate)
  yield takeLatest(deliveryJobsActions.fetchDeliveryJobsTasks.type, fetchDeliveryJobsTasks)
}

export default [fork(deliveryJobsFlows)]
