export const FETCH_FOLDER = 'FETCH_FOLDER'
export const FETCH_FOLDER_SUCCESS = 'FETCH_FOLDER_SUCCESS'
export const FETCH_FOLDER_ERROR = 'FETCH_FOLDER_ERROR'
export const CREATE_NEW_FOLDER = 'CREATE_NEW_FOLDER'
export const MOVE_FOLDER = 'MOVE_FOLDER'
export const MOVE_REPORT = 'MOVE_REPORT'
export const RENAME_FOLDER = 'RENAME_FOLDER'
export const DELETE_FOLDER = 'DELETE_FOLDER'
export const RENAME_REPORT = 'RENAME_FOLDER_REPORT'
export const DELETE_REPORT = 'DELETE_FOLDER_REPORT'

export const createNewFolder = (folderId, name) => ({
  type: CREATE_NEW_FOLDER,
  folderId,
  name
})

export const fetchFolder = folderId => ({
  folderId,
  type: FETCH_FOLDER
})

export const moveFolder = (folderId, parentId) => ({
  type: MOVE_FOLDER,
  folderId,
  parentId
})

export const moveReport = (reportId, parentId) => ({
  type: MOVE_REPORT,
  reportId,
  parentId
})

export const renameFolder = (folderId, name) => ({
  type: RENAME_FOLDER,
  folderId,
  name
})

export const deleteFolder = (folderId, onSuccess) => ({
  type: DELETE_FOLDER,
  folderId,
  onSuccess
})

export const renameReport = (reportId, name) => ({
  type: RENAME_REPORT,
  reportId,
  name
})

export const deleteReport = (reportId, onSuccess) => ({
  type: DELETE_REPORT,
  reportId,
  onSuccess
})
