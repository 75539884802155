// @flow
import {
  FETCH_USERS_FAILED,
  FETCH_USERS_SUCCESS,
  SAVE_USER_PERMISSIONS_SUCCESS,
  FETCH_USER_PERMISSIONS_SUCCESS,
  FETCH_USERS_SEARCH_ENTITY_CHANGED
} from '../actions/user-actions'

import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialState = {
  data: [],
  count: 0,
  permissions: {},
  selectedSearchEntity: 'users',
  searchEntities: {
    users: {
      display: 'Users in group'
    },
    groups: {
      display: 'Groups'
    }
  }
}

const usersReducer = (state: Object = initialState, action: Object): Object => {
  switch (action.type) {
    case FETCH_USER_PERMISSIONS_SUCCESS:
    case SAVE_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [action.userId]: action.data
        }
      }

    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.offset ? [...state.data, ...action.data] : action.data,
        count: action.count
      }

    case FETCH_USERS_FAILED:
      return {
        loading: false,
        error: action.message
      }

    case FETCH_USERS_SEARCH_ENTITY_CHANGED:
      return {
        ...state,
        count: 0,
        selectedSearchEntity: action.selectedSearchEntity
      }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default usersReducer
