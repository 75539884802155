import React from 'react'
import PropTypes from 'prop-types'

import AdminToolsTable from '../../AdminToolsTable'

const AdminToolsTab = props => {
  const { groupId, isPortalGroup } = props

  return (
    <div className="group-edit-admin-tab">
      <AdminToolsTable groupId={groupId} isPortalGroup={isPortalGroup} />
    </div>
  )
}

AdminToolsTab.propTypes = {
  groupId: PropTypes.string,
  isPortalGroup: PropTypes.bool
}

export default AdminToolsTab
