import React from 'react'
import { Glyphicon } from 'react-bootstrap'

const Unauthorized = () => (
  <div>
    <h3>
      {'You are unauthorized to view this content'}
      &nbsp;
      <Glyphicon glyph="ban-circle" />
    </h3>
  </div>
)

export default Unauthorized
