import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  Form,
  FormGroup,
  Row,
  Col,
  FormControl,
  Button,
  ButtonGroup,
  ControlLabel,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import FlatMenuList from '../CustomReportViewPage/filters/FlatMenuList'
import TextInputField from './components/TextInputField'
import TabFilters from './TabFilters'
import { chain } from 'lodash'
import cuid from 'cuid'

import { updateTabDetails, deleteTab, addTab, saveTab } from '../../actions/custom-report-edit-actions'
import cx from 'classnames'

class TabDetails extends Component {
  // validates the key, value and returns object with appropriate validation attributes
  getValidationAttributes(key, value) {
    const validationKeyMap = {
      name: 'isNameValid',
      dashboardId: 'isDashboardIdValid'
    }
    const validationKey = validationKeyMap[key]
    if (validationKey) {
      return { [validationKey]: this.isValid(key, value) }
    }
    return {}
  }

  // Returns true if the 'value' is a valid for the key
  isValid(key, value = '') {
    switch (key) {
      case 'name':
        value = value.trim()
        return !!value
      case 'dashboardId':
        return !!(value && !isNaN(value))
      default:
        return false
    }
  }

  // check whether Tab has the minimum information needed
  // to save it
  isTabInvalid = () => {
    const { name, dashboardId } = this.props
    return !this.isValid('name', name) || !this.isValid('dashboardId', dashboardId)
  }

  updateFormValue = (key, value) => {
    this.props.updateTabDetails({
      [key]: value,
      ...this.getValidationAttributes(key, value),
      dirty: true
    })
  }

  updateSelectedTab = tabDetails => {
    const { id, name, dashboardId, inheritBaseTable, associatedTables, filterIdList } = tabDetails || {}
    this.props.updateTabDetails(
      {
        activeTabId: id,
        name,
        dashboardId,
        inheritBaseTable,
        associatedTables,
        filterIdList,
        dirty: false
      },
      false
    )
  }

  handleOnAddTab = () => {
    const { tabs, addTab } = this.props
    if (tabs && tabs.new) {
      this.updateSelectedTab(tabs.new)
      return
    }
    addTab()
  }

  onAddAnoterTable = index => {
    const { associatedTables = [] } = this.props
    const newTables = [...associatedTables]
    newTables.splice(index, 0, { name: '', value: '' })
    this.updateFormValue('associatedTables', newTables)
  }

  onRemoveTable = index => {
    const { associatedTables } = this.props
    const newTables = [...associatedTables]
    newTables.splice(index, 1)
    this.updateFormValue('associatedTables', newTables)
  }

  onUpdateAssociatedTable = (index, key, value) => {
    const { associatedTables } = this.props
    const newTables = [...associatedTables]
    newTables[index] = {
      ...newTables[index],
      [key]: value
    }
    this.updateFormValue('associatedTables', newTables)
  }

  getBaseTableAction = () => {
    const { inheritBaseTable = false } = this.props
    return (
      <div>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="base-table">{inheritBaseTable ? 'Base table is inherited' : 'Inherit base table'}</Tooltip>
          }
        >
          <FormControl
            className="pull-left"
            type="checkbox"
            checked={inheritBaseTable}
            onChange={() => {
              this.updateFormValue('inheritBaseTable', !inheritBaseTable)
            }}
          />
        </OverlayTrigger>
        <Button
          bsStyle="link"
          className="addButton pull-left value-action"
          onClick={() => {
            this.onAddAnoterTable(0)
          }}
        >
          Add another table
        </Button>
      </div>
    )
  }

  getTabOptions = () => {
    const { tabIdList = [], tabs = {} } = this.props
    return tabIdList
      .filter(id => tabs[id])
      .map(id => ({
        ...tabs[id],
        className: tabs[id].action
      }))
  }

  renderAssociatedTables = () => {
    const { associatedTables = [] } = this.props
    return (
      associatedTables.length > 0 &&
      associatedTables.map(({ name, value }, index) => (
        <div key={index} className="table-filter">
          <FormGroup controlId={`table-filter-name-${index}`}>
            <Col sm={2}>
              <ControlLabel>{'Table filter name (PS)'}</ControlLabel>
            </Col>
            <Col sm={6}>
              <FormControl
                type="text"
                value={name}
                onChange={event => this.onUpdateAssociatedTable(index, 'name', event.target.value)}
              />
            </Col>
            <Col sm={2}>
              <Button
                bsStyle="link"
                className="removeButton value-action"
                onClick={() => {
                  this.onRemoveTable(index)
                }}
              >
                Remove
              </Button>
            </Col>
          </FormGroup>
          <FormGroup controlId={`table-filter-value-${index}`}>
            <Col sm={2}>
              <ControlLabel>{'Table filter value'}</ControlLabel>
            </Col>
            <Col sm={6}>
              <FormControl
                type="text"
                value={value}
                onChange={event => this.onUpdateAssociatedTable(index, 'value', event.target.value)}
              />
            </Col>
          </FormGroup>
        </div>
      ))
    )
  }

  onTabOrderChanged = tabs => {
    const tabIdList = chain(tabs)
      .values()
      .sortBy('order')
      .map(item => item.id)
      .filter(item => item && item.id !== 'new')
      .value()
    if (this.props.tabs.new) {
      tabIdList.push('new')
    }
    this.updateFormValue('tabIdList', tabIdList)
  }

  onDiscardTabChanges = () => {
    const { activeTabId, tabs } = this.props
    this.updateSelectedTab(tabs[activeTabId])
  }

  onDeleteTab = () => {
    const { activeTabId, deleteTab } = this.props
    deleteTab(activeTabId)
  }

  onSaveTab = () => {
    const { name, inheritBaseTable, activeTabId, associatedTables = [], dashboardId, filterIdList } = this.props
    if (this.isTabInvalid()) {
      // if current Tab is invalid, skip saving and update the validation attributes
      this.updateFormValue({
        ...this.getValidationAttributes('name', name),
        ...this.getValidationAttributes('dashboardId', dashboardId),
        dirty: true
      })
    } else {
      const newTab = {
        // if current tab is new, generate an ID for it
        id: activeTabId === 'new' ? `new_${cuid.slug()}` : activeTabId,
        name,
        dashboardId,
        inheritBaseTable,
        filterIdList,
        associatedTables
      }
      this.props.saveTab(newTab)
      // reload tab after saving it
      this.updateSelectedTab(newTab)
    }
  }

  renderTabFilters() {
    const { filters, filterIdList = [], tabs, activeTabId } = this.props
    return (
      <TabFilters
        filters={filters}
        filterIdList={filterIdList}
        defaultList={[...(tabs[activeTabId].filterIdList || [])]}
        onUpdateFilterIdList={newList => this.updateFormValue('filterIdList', newList)}
      />
    )
  }

  renderTabActions() {
    const { activeTabId, dirty } = this.props
    return (
      <Row>
        <Col sm={10}>
          <ButtonGroup className="pull-right">
            <Button
              onClick={this.onDiscardTabChanges}
              disabled={!dirty} // 'Cancel' only enabled if filter is dirty/modified
            >
              Cancel
            </Button>
            <Button onClick={this.onDeleteTab}>Delete</Button>
            <Button
              bsStyle="primary"
              disabled={!dirty || this.isTabInvalid()} // if filter is not modified or is invalid, it cannot be 'Saved'
              onClick={this.onSaveTab}
            >
              {activeTabId === 'new' ? 'Create' : 'Update'}
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    )
  }

  renderAddTabButton() {
    return <Button onClick={this.handleOnAddTab}> Add New Tab </Button>
  }

  render() {
    const {
      tabIdList = [],
      activeTabId,
      name = '',
      isNameValid,
      dashboardId = '',
      isDashboardIdValid,
      baseTableName = '',
      dirty
    } = this.props
    if (!tabIdList || !tabIdList.length) {
      return (
        <Form horizontal className={dirty ? 'dirty' : ''}>
          <h4> Tabs </h4>
          {this.renderAddTabButton()}
          <Row>
            <Col sm={10}>
              <div className="no-value-msg">No Tabs found. It is mandatory to have atleast 1 Tab in a Report.</div>
            </Col>
          </Row>
        </Form>
      )
    }
    return (
      <Form horizontal className={cx({ dirty })}>
        <h4> Tabs </h4>
        {this.renderAddTabButton()}
        <FlatMenuList
          options={this.getTabOptions()}
          selectedTab={activeTabId}
          valueDidChange={this.updateSelectedTab}
          sortable
          orderDidChange={this.onTabOrderChanged}
          defaultOptionName="New Tab"
        />
        <TextInputField
          id="tabName"
          label="Name"
          type="text"
          placeholder="Enter display name"
          value={name || ''}
          validationState={isNameValid === false ? 'error' : null}
          onChange={event => this.updateFormValue('name', event.target.value)}
          help={isNameValid === false ? 'Display name of the Tab cannot be empty' : null}
          required
        />
        <TextInputField
          id="dashboardId"
          label="Dashboard Id"
          type="text"
          placeholder="Enter dashboard id"
          value={dashboardId || ''}
          validationState={isDashboardIdValid === false ? 'error' : null}
          onChange={event => this.updateFormValue('dashboardId', event.target.value)}
          help={isDashboardIdValid === false ? 'Dashboard Id of the Tab should be a valid number' : null}
          required
        />
        <TextInputField
          id="baseTableName"
          label="Base Table"
          type="text"
          value={baseTableName || ''}
          readOnly
          actionComponent={this.getBaseTableAction()}
        />
        {this.renderAssociatedTables()}
        {this.renderTabFilters()}
        {this.renderTabActions()}
      </Form>
    )
  }
}

TabDetails.propTypes = {
  tabIdList: PropTypes.array,
  activeTabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  isNameValid: PropTypes.bool,
  dashboardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDashboardIdValid: PropTypes.bool,
  inheritBaseTable: PropTypes.bool,
  associatedTables: PropTypes.array,
  filterIdList: PropTypes.array,
  tabs: PropTypes.object,
  dirty: PropTypes.bool,
  baseTableName: PropTypes.string,
  filters: PropTypes.object,
  updateTabDetails: PropTypes.func,
  deleteTab: PropTypes.func,
  addTab: PropTypes.func,
  saveTab: PropTypes.func
}

function mapStateToProps({ reportEdit }) {
  const {
    tabIdList,
    activeTabId,
    name,
    isNameValid,
    dashboardId,
    isDashboardIdValid,
    inheritBaseTable,
    associatedTables,
    filterIdList,
    tabs,
    dirty
  } = reportEdit.tabForm
  const { baseTableName } = reportEdit.basicDetailsForm
  const { filters } = reportEdit.filterForm
  return {
    tabIdList,
    activeTabId,
    name,
    isNameValid,
    dashboardId,
    isDashboardIdValid,
    inheritBaseTable,
    associatedTables,
    filterIdList,
    tabs,
    dirty,
    baseTableName,
    filters
  }
}

export default connect(mapStateToProps, {
  updateTabDetails,
  deleteTab,
  addTab,
  saveTab
})(TabDetails)
