import axios from 'axios'
import { takeLatest, call, put, fork } from 'redux-saga/effects'
import config from '../../config'

import cmeInspectorActions from './cmeInspectorActions'

import { createAlert } from './../../actions/app-actions'

function* fetchCMEInspector({ payload = {} }) {
  try {
    const { descriptions, parsedCategories, merchantId, env } = payload

    const { data } = yield call(() =>
      axios.post(`${config.apiBaseUrl}/rops/cme-inspector`, {
        descriptions,
        parsedCategories,
        merchantIds: merchantId ? [merchantId] : [],
        env
      })
    )
    yield put(cmeInspectorActions.successLoad(data))
  } catch (error) {
    yield put(cmeInspectorActions.failedLoad())
    window.captureException(error)
    yield put(createAlert('danger', error.message, `CME Inspector fetch failed`))
  }
}
/*
 * FLOWS
 */
function* cmeInspectorFlows() {
  yield takeLatest(cmeInspectorActions.fetch.type, fetchCMEInspector)
}

export default [fork(cmeInspectorFlows)]
