import { fork, takeLatest, takeEvery, put, all, call } from 'redux-saga/effects'
import axios from 'axios'
import moment from 'moment'

import * as actions from '../actions/delivery-actions'
import { createAlert } from '../actions/app-actions'

import { extractCCDPathParams } from '../utils/delivery-center'
import { updateDeliveryConfigFormData } from './delivery-config-sagas'

function* createNewDelivery(action) {
  try {
    const {
      payload: { deliverableId, deadlineDate },
      callback: successCallback
    } = action
    yield call(() =>
      axios.post('/api/delivery', {
        deliverableId,
        deliveryDeadline: deadlineDate
      })
    )
    if (successCallback) successCallback()
    yield all([
      put({
        type: actions.CREATE_NEW_DELVIERY_SUCCESS
      }),
      updateCurrentDelivery()
    ])
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.CREATE_NEW_DELVIERY_ERROR,
      error: error.message
    })
  }
}

function* fetchCurrentDelivery() {
  try {
    const { deliverable: { id: deliverableId } = {} } = extractCCDPathParams()
    const response = yield call(() => axios.get(`/api/delivery/current/${deliverableId}`))
    const delivery = response.data
    yield put({
      type: actions.FETCH_CURRENT_DELIVERY_SUCCESS,
      payload: delivery || null
    })
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.FETCH_CURRENT_DELIVERY_FAILURE
    })
  }
}

function* refreshDeliveryStatus(action) {
  try {
    const response = yield call(() => axios.get(`/api/delivery/${action.payload.deliveryId}`))
    const delivery = response.data
    yield put({
      type: actions.REFRESH_DELIVERY_STATUS_SUCCESS,
      payload: delivery || {}
    })
  } catch (error) {
    console.error('Error while refreshing delivery status', error)
  }
}

function* updateCurrentDelivery() {
  try {
    yield put({
      type: actions.UPDATE_CURRENT_DELIVERY_DETAILS
    })
    yield fetchCurrentDelivery()
  } catch (error) {
    console.error(error)
  }
}

function* startDataCustomization(action) {
  try {
    const response = yield call(() =>
      axios.post(`/api/delivery/tasks/${action.deliveryId}/start`, {
        task: 'dataCustomization',
        startType: action.rerun ? 'rerun' : 'fresh'
      })
    )
    yield updateCurrentDelivery()
    if (action.callback) {
      action.callback()
    }
    return response
  } catch (error) {
    console.error(error)
  }
}

function* retryDeliveryStep(action) {
  try {
    const { step, substep, deliveryId } = action.payload
    const response = yield call(() =>
      axios.post(`/api/delivery/tasks/${deliveryId}/start`, {
        task: step,
        startType: 'retry',
        retryPhase: substep
      })
    )
    yield updateCurrentDelivery()
    if (action.callback) {
      action.callback()
    }
    return response
  } catch (error) {
    console.error(error)
  }
}

function* proceedToDataCustomization(action) {
  try {
    const { deliveryId, skipDataCleanup } = action
    const response = yield call(() =>
      axios.put(`/api/delivery/${deliveryId}`, {
        categoryCleanupStatus: skipDataCleanup ? 'SKIPPED' : 'COMPLETED'
      })
    )
    yield startDataCustomization(action)
    return response
  } catch (error) {
    console.error(error)
  }
}

function* approveQC(action) {
  try {
    const { deliveryId, approve, notes } = action.payload

    const response = yield call(() =>
      axios.put(`/api/delivery/${deliveryId}`, {
        notes,
        qcStatus: approve ? 'APPROVED' : 'REJECTED'
      })
    )

    if (approve) {
      yield call(() =>
        axios.post(`/api/delivery/tasks/${deliveryId}/start`, {
          task: 'prod',
          startType: 'fresh'
        })
      )
    }

    yield updateCurrentDelivery()

    if (action.callback) {
      action.callback()
    }
    return response
  } catch (error) {
    console.error(error)
  }
}

function* cancelDelivery(action) {
  try {
    yield call(() =>
      axios.post('/api/delivery/cancel', {
        deliveryId: action.deliveryId,
        notes: action.notes
      })
    )
    const { deliverable: { id: deliverableId } = {} } = extractCCDPathParams()
    yield all([
      updateCurrentDelivery(),
      updateDeliveryConfigFormData(deliverableId),
      fetchPastDeliveries({ deliverableId })
    ])
    if (action.callback) {
      action.callback()
    }
  } catch (error) {
    console.error(error)
    yield put(createAlert('danger', error.message, 'Error Cancelling Delivery'))
  }
}

function* updateDeliveryDeadline(action) {
  try {
    const { deadlineDate, deliveryId } = action.payload
    yield call(() =>
      axios.post('/api/delivery/update-deadline', {
        deliveryId,
        deliveryDate: deadlineDate
      })
    )

    if (action.callback) {
      action.callback()
    }
    const { deliverable: { id: deliverableId } = {} } = extractCCDPathParams()
    yield all([
      put({
        type: actions.UPDATE_DELIVERY_DEADLINE_SUCCESS
      }),
      updateCurrentDelivery(),
      updateDeliveryConfigFormData(deliverableId)
    ])
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.UPDATE_DELIVERY_DEADLINE_ERROR,
      error: error.message
    })
  }
}

function* fetchCategoriesCleanupStatus(action) {
  try {
    const { categoryIds, deliveryDate, mergePayload = false } = action.payload
    if (!deliveryDate || !categoryIds.length) {
      return
    }
    const month = moment(deliveryDate).month() + 1
    const year = moment(deliveryDate).year()
    const response = yield call(() =>
      axios.get('/api/delivery/category-cleanup/status', {
        params: {
          ids: categoryIds,
          month,
          year
        }
      })
    )
    if (response && response.data) {
      yield put({
        type: actions.CATEGORIES_CLEANUP_STATUS_SUCCESS,
        payload: response.data,
        mergePayload
      })
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.CATEGORIES_CLEANUP_STATUS_ERROR
    })
  }
}

function* createCategoryCleanupJob(action) {
  try {
    const { categoryId, deliveryDate, deliveryId } = action.payload
    if (!categoryId || !deliveryDate || !deliveryId) {
      return
    }
    yield call(() =>
      axios.post('/api/delivery/category-cleanup/schedule', {
        categoryId,
        deliveryDate,
        deliveryId
      })
    )
    yield fetchCategoriesCleanupStatus({
      payload: {
        categoryIds: [categoryId],
        deliveryDate,
        mergePayload: true
      }
    })
  } catch (error) {
    console.error(error)
    yield put({
      type: actions.CREATE_CLEANUP_JOB_ERROR
    })
    yield put(createAlert('danger', 'There was an error in trying to schedule cleanup job', 'Could not schedule job'))
  }
}

function* fetchPastDeliveries(action) {
  try {
    const { page = 1, pageSize = 25, sortOrder = 'desc', sortBy = 'completionDate', deliverableId } =
      action.payload || {}

    const offset = (page - 1) * pageSize

    const deliveries = yield call(() =>
      axios.get('/api/delivery/past', {
        params: {
          deliverableId,
          offset,
          size: pageSize,
          sortBy,
          sortOrder
        }
      })
    )

    if (deliveries && deliveries.data) {
      yield put({
        type: actions.FETCH_PAST_DELIVERIES_SUCCESS,
        deliveries: deliveries.data
      })
    }
  } catch (error) {
    const errorMessage = `Error fetching past deliveries: ${error.message}`
    console.error(errorMessage)
    yield put({
      type: actions.FETCH_PAST_DELIVERIES_FAILURE,
      error: errorMessage
    })
  }
}

function* deliveryFlow() {
  yield takeLatest(actions.FETCH_CURRENT_DELIVERY, fetchCurrentDelivery)
  yield takeLatest(actions.CREATE_NEW_DELVIERY, createNewDelivery)
  yield takeLatest(actions.START_DATA_CUSTOMIZATION, startDataCustomization)
  yield takeLatest(actions.REFRESH_DELIVERY_STATUS, refreshDeliveryStatus)
  yield takeLatest(actions.RETRY_DELIVERY_STEP, retryDeliveryStep)
  yield takeLatest(actions.PROCEED_TO_DATA_CUSTOMIZATION, proceedToDataCustomization)
  yield takeLatest(actions.APPROVE_QC, approveQC)
  yield takeLatest(actions.CANCEL_DELIVERY, cancelDelivery)
  yield takeLatest(actions.UPDATE_DELIVERY_DEADLINE, updateDeliveryDeadline)
  yield takeLatest(actions.FETCH_PAST_DELIVERIES, fetchPastDeliveries)
  yield takeLatest(actions.CATEGORIES_CLEANUP_STATUS, fetchCategoriesCleanupStatus)
  yield takeEvery(actions.CREATE_CLEANUP_JOB, createCategoryCleanupJob)
}

export default [fork(deliveryFlow)]
