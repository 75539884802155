import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

export default class renderUserEditField extends Component {
  render() {
    const {
      input,
      label,
      type,
      required,
      meta: { touched, dirty, error }
    } = this.props
    const className = required ? cx('input', 'required') : 'input'

    return (
      <tr>
        <td>{label}</td>
        <td>
          <input {...input} className={className} placeholder={label} type={type} />
        </td>
        <td>{(dirty || touched) && error && <span className={'formError'}>{error}</span>}</td>
      </tr>
    )
  }
}

renderUserEditField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  meta: PropTypes.object
}
