import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, ControlLabel, DropdownButton, MenuItem, Col, ButtonGroup, Button, Glyphicon } from 'react-bootstrap'
import cx from 'classnames'
import { findIndex } from 'lodash'

export default class SingleSelect extends Component {
  static propTypes = {
    name: PropTypes.string,
    input: PropTypes.object,
    options: PropTypes.array,
    handleChange: PropTypes.func,
    placeholder: PropTypes.string,
    controlLabel: PropTypes.string,
    selectedId: PropTypes.string,
    labelKey: PropTypes.string,
    required: PropTypes.bool,
    className: PropTypes.string,
    renderMenuItem: PropTypes.func,
    navButtons: PropTypes.bool
  }

  static defaultProps = {
    labelKey: 'name',
    handleChange: () => {},
    required: false,
    className: '',
    navButtons: false,
    renderMenuItem: (item, labelKey) => <span className={cx(item.className)}>{item[labelKey]}</span>
  }

  onSelectChange = value => {
    this.props.handleChange(value)
  }

  render() {
    const {
      options = [],
      selectedId = '',
      controlLabel,
      placeholder,
      labelKey,
      required,
      className,
      name,
      renderMenuItem,
      navButtons
    } = this.props
    let title = placeholder
    let previousOption = null
    let nextOption = null
    let selectedOption = null
    if (selectedId) {
      const selectedIndex = findIndex(options, { id: selectedId })
      if (selectedIndex > -1) {
        selectedOption = options[selectedIndex]
        title = selectedOption.label
        previousOption = selectedIndex === 0 ? options[options.length - 1] : options[selectedIndex - 1]
        nextOption = selectedIndex === options.length - 1 ? options[0] : options[selectedIndex + 1]
      }
    }
    return (
      <FormGroup className={`${className} single-select`}>
        <Col sm={2}>
          <ControlLabel className={cx('label', { required })}>{controlLabel}</ControlLabel>
        </Col>
        <Col sm={6}>
          <ButtonGroup>
            {navButtons && (
              <Button
                onClick={() => {
                  this.onSelectChange(previousOption)
                }}
              >
                <Glyphicon glyph="triangle-left" />
              </Button>
            )}
            <DropdownButton id={name} title={title} onSelect={this.onSelectChange} className={'single-select-btn'}>
              {options.map(item => (
                <MenuItem key={item.id} eventKey={item} active={item.id === selectedId} divider={item.divider}>
                  {renderMenuItem(item, labelKey, options)}
                </MenuItem>
              ))}
            </DropdownButton>
            {navButtons && (
              <Button
                onClick={() => {
                  this.onSelectChange(nextOption)
                }}
              >
                <Glyphicon glyph="triangle-right" />
              </Button>
            )}
          </ButtonGroup>
        </Col>
      </FormGroup>
    )
  }
}
