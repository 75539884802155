export const INITIALIZE_NEW = 'INITIALIZE_NEW_DELIVERABLE_CONFIG'
export const INITIALIZE_EXISTING = 'INITIALIZE_EXISTING_DELIVERABLE_CONFIG'
export const UPDATE_CONFIG_FORM_DATA = 'UPDATE_DELIVERABLE_CONFIG_FORM_DATA'
export const UPDATE_FORM_STATE = 'UPDATE_DELIVERABLE_CONFIG_FORM_STATE'
export const CHECK_DELIVERABLE_NAME_AVAILABLE = 'CHECK_DELIVERABLE_NAME_AVAILABLE'
export const UPDATE_BASIC_DETAILS_FORM = 'UPDATE_DELIVERABLE_BASIC_DETAILS_FORM'
export const RESET_BASIC_DETAILS_FORM = 'RESET_BASIC_DELIVERABLE_DETAILS_FORM'
export const CANCEL_EDITING = 'CANCEL_EDITING_DELIVERABLE_DETAILS'
export const SAVE_BASIC_DETAILS_FORM = 'SAVE_DELIVERABLE_BASIC_DETAILS_FORM'
export const SAVE_BASIC_DETAILS_FORM_SUCCESS = 'SAVE_DELIVERABLE_BASIC_DETAILS_FORM_SUCCESS'
export const SORT_ASSOCIATED_CATEGORIES = 'SORT_DELIVERABLE_ASSOCIATED_CATEGORIES'
export const FETCH_DELIVERABLE_CATEGORIES = 'FETCH_DELIVERABLE_CATEGORIES'
export const FETCH_DELIVERABLE_CATEGORIES_SUCCESS = 'FETCH_DELIVERABLE_CATEGORIES_SUCCESS'
export const FETCH_DELIVERABLE_CATEGORIES_ERROR = 'FETCH_DELIVERABLE_CATEGORIES_ERROR'
export const UPDATE_DELIVERABLE_CATEGORIES_SEARCH_FILTER = 'UPDATE_DELIVERABLE_CATEGORIES_SEARCH_FILTER'
export const FETCH_DELIVERABLE_CATEGORY_CONFIG = 'FETCH_DELIVERABLE_CATEGORY_CONFIG'
export const FETCH_DELIVERABLE_CATEGORY_CONFIG_SUCCESS = 'FETCH_DELIVERABLE_CATEGORY_CONFIG_SUCCESS'
export const FETCH_DELIVERABLE_CATEGORY_CONFIG_ERROR = 'FETCH_DELIVERABLE_CATEGORY_CONFIG_ERROR'
export const UPDATE_SELECTED_DELIVERABLE_CATEGORY_SELECTION = 'UPDATE_SELECTED_DELIVERABLE_CATEGORY_SELECTION'
export const SAVE_DELIVERABLE_CATEGORY_CONFIG = 'SAVE_DELIVERABLE_CATEGORY_CONFIG'
export const SAVE_DELIVERABLE_CATEGORY_CONFIG_ERROR = 'SAVE_DELIVERABLE_CATEGORY_CONFIG_ERROR'
export const RESET_CATEGORY_FORM = 'RESET_DELIVERABLE_CATEGORY_FORM'

export const REPORT_SEARCH = 'DELIVERABLE_REPORT_SEARCH'
export const REPORT_SEARCH_SUCCESS = 'DELIVERABLE_REPORT_SEARCH_SUCCESS'
export const REPORT_SEARCH_ERROR = 'DELIVERABLE_REPORT_SEARCH_ERROR'
export const UPDATE_REPORTS_FORM = 'UPDATE_DELIVERABLE_REPORTS_FORM'
export const SAVE_REPORTS = 'SAVE_DELIVERABLE_REPORTS'
export const SAVE_REPORTS_SUCCESS = 'SAVE_DELIVERABLE_REPORTS_SUCCESS'
export const SAVE_REPORTS_ERROR = 'SAVE_DELIVERABLE_REPORTS_ERROR'
export const RESET_REPORTS_FROM = 'RESET_DELIVERABLE_REPORTS_FORM'
export const LOAD_SCRIPTS_DATA = 'LOAD_DELIVERABLE_SCRIPTS_DATA'
export const LOAD_SCRIPTS_DATA_SUCCESS = 'LOAD_DELIVERABLE_SCRIPTS_DATA_SUCCESS'
export const LOAD_SCRIPTS_DATA_ERROR = 'LOAD_DELIVERABLE_SCRIPTS_DATA_ERROR'
export const UPDATE_SCRIPTS_FORM = 'UPDATE_DELIVERABLE_SCRIPTS_FORM'
export const RESET_SRIPTS_FORM = 'RESET_DELIVERABLE_SRIPTS_FORM'
export const SAVE_SCRIPTS_FORM = 'SAVE_DELIVERABLE_SCRIPTS_FORM'
export const SAVE_SCRIPTS_FORM_ERROR = 'SAVE_DELIVERABLE_SCRIPTS_FORM_ERROR'
export const SCHEDULE_NEXT_DELIVERY = 'SCHEDULE_NEXT_DELIVERY_FOR_DELIVERABLE'
export const SCHEDULE_NEXT_DELIVERY_ERROR = 'SCHEDULE_NEXT_DELIVERY_FOR_DELIVERABLE_ERROR'
export const FETCH_REPORT_TABS = 'FETCH_REPORT_TABS'
export const REPORT_TABS_LOAD_SUCCESS = 'REPORT_TABS_LOAD_SUCCESS'
export const REPORT_TABS_LOAD_FAILURE = 'REPORT_TABS_LOAD_FAILURE'
export const UPDATE_CATEGORY_LABEL = 'UPDATE_CATEGORY_LABEL'
export const FETCH_DELIVERY_SCRIPT_TEMPLATE = 'FETCH_DELIVERY_SCRIPT_TEMPLATE'
export const FETCH_DELIVERY_SCRIPT_TEMPLATE_SUCCESS = 'FETCH_DELIVERY_SCRIPT_TEMPLATE_SUCCESS'
export const FETCH_DELIVERY_SCRIPT_TEMPLATE_FAILED = 'FETCH_DELIVERY_SCRIPT_TEMPLATE_FAILED'
export const CREATE_INC_EXC_QUERY_TREE = 'CREATE_INC_EXC_QUERY_TREE'
export const REMOVE_INC_EXC_QUERY_TREE = 'REMOVE_INC_EXC_QUERY_TREE'
export const REVERT_INC_EXC_QUERY_TREE = 'REVERT_INC_EXC_QUERY_TREE'
export const CREATE_BRAND_TRACKER = 'CREATE_BRAND_TRACKER'
export const ADD_BRAND_TRACKER_QUERY_TREE = 'ADD_BRAND_TRACKER_QUERY_TREE'
export const HANDLE_BRAND_SEARCH_CHANGE = 'HANDLE_BRAND_SEARCH_CHANGE'
export const HANDLE_BRAND_SEARCH_CHANGE_SUCCESS = 'HANDLE_BRAND_SEARCH_CHANGE_SUCCESS'
export const HANDLE_BRAND_SEARCH_CHANGE_FAILED = 'HANDLE_BRAND_SEARCH_CHANGE_FAILED'
export const SELECT_BRAND = 'SELECT_BRAND'
export const BT_LABEL_CHANGE = 'BT_LABEL_CHANGE'
export const DELETE_BT_QUERY = 'DELETE_BT_QUERY'
export const DELETE_BT = 'DELETE_BT'
export const REVERT_ALL_BT_CHANGES = 'REVERT_ALL_BT_CHANGES'

export const EMPTY_INC_EXC_QUERY_TREE = 'EMPTY_INC_EXC_QUERY_TREE'
export const EMPTY_BT_QUERY_TREE = 'EMPTY_BT_QUERY_TREE'
export const EMPTY_BTQ_QUERY_TREE = 'EMPTY_BTQ_QUERY_TREE'
export const EMPTY_BTBQ_QUERY_TREE = 'EMPTY_BTBQ_QUERY_TREE'

export const UPDATE_INC_EXC_QUERY_TREE = 'UPDATE_INC_EXC_QUERY_TREE'
export const UPDATE_BT_QUERY_TREE = 'UPDATE_BT_QUERY_TREE'
export const UPDATE_BTQ_QUERY_TREE = 'UPDATE_BTQ_QUERY_TREE'
export const UPDATE_BTBQ_QUERY_TREE = 'UPDATE_BTBQ_QUERY_TREE'

export const FETCH_INC_EXC_CHILDREN = 'FETCH_INC_EXC_CHILDREN'
export const FETCH_INC_EXC_CHILDREN_SUCCESS = 'FETCH_INC_EXC_CHILDREN_SUCCESS'
export const FETCH_INC_EXC_CHILDREN_ERROR = 'FETCH_INC_EXC_CHILDREN_ERROR'
export const FETCH_BT_CHILDREN = 'FETCH_BT_CHILDREN'
export const FETCH_BT_CHILDREN_SUCCESS = 'FETCH_BT_CHILDREN_SUCCESS'
export const FETCH_BT_CHILDREN_ERROR = 'FETCH_BT_CHILDREN_ERROR'
export const FETCH_BTQ_CHILDREN = 'FETCH_BTQ_CHILDREN'
export const FETCH_BTQ_CHILDREN_SUCCESS = 'FETCH_BTQ_CHILDREN_SUCCESS'
export const FETCH_BTQ_CHILDREN_ERROR = 'FETCH_BTQ_CHILDREN_ERROR'
export const FETCH_BTBQ_CHILDREN = 'FETCH_BTBQ_CHILDREN'
export const FETCH_BTBQ_CHILDREN_SUCCESS = 'FETCH_BTBQ_CHILDREN_SUCCESS'
export const FETCH_BTBQ_CHILDREN_ERROR = 'FETCH_BTBQ_CHILDREN_ERROR'

export const CREATE_INC_EXC_MULTIPLE_RULES = 'CREATE_INC_EXC_MULTIPLE_RULES'
export const CREATE_BT_MULTIPLE_RULES = 'CREATE_BT_MULTIPLE_RULES'
export const CREATE_BTQ_MULTIPLE_RULES = 'CREATE_BTQ_MULTIPLE_RULES'
export const CREATE_BTBQ_MULTIPLE_RULES = 'CREATE_BTBQ_MULTIPLE_RULES'

export const GET_BT_INSERT_SQL = 'GET_BT_INSERT_SQL'
export const GET_BT_INSERT_SQL_SUCCESS = 'GET_BT_INSERT_SQL_SUCCESS'
export const GET_BT_INSERT_SQL_ERROR = 'GET_BT_INSERT_SQL_ERROR'
export const GET_BT_UPDATE_SQL = 'GET_BT_UPDATE_SQL'
export const GET_BT_UPDATE_SQL_SUCCESS = 'GET_BT_UPDATE_SQL_SUCCESS'
export const GET_BT_UPDATE_SQL_ERROR = 'GET_BT_UPDATE_SQL_ERROR'

export function initializeNew(payload) {
  return {
    type: INITIALIZE_NEW,
    payload
  }
}

export function initializeExisting(payload) {
  return {
    type: INITIALIZE_EXISTING,
    payload
  }
}

export function checkIfDeliverableNameAvailable(payload) {
  return {
    type: CHECK_DELIVERABLE_NAME_AVAILABLE,
    payload
  }
}

export function updateFormState(payload) {
  return {
    type: UPDATE_FORM_STATE,
    payload
  }
}

export function updateBasicDetailsForm(payload) {
  return {
    type: UPDATE_BASIC_DETAILS_FORM,
    payload
  }
}

export function resetBasicDetailsForm() {
  return {
    type: RESET_BASIC_DETAILS_FORM
  }
}

export function cancelEditing() {
  return {
    type: CANCEL_EDITING
  }
}

export function saveBasicDetailsForm(payload, callback) {
  return {
    type: SAVE_BASIC_DETAILS_FORM,
    payload,
    callback
  }
}

export function fetchCategories(payload) {
  return {
    type: FETCH_DELIVERABLE_CATEGORIES,
    payload
  }
}

export function onChangeSearchValue(searchTerm) {
  return {
    type: UPDATE_DELIVERABLE_CATEGORIES_SEARCH_FILTER,
    searchTerm
  }
}

export function fetchCategoryConfig(deliverableId) {
  return {
    type: FETCH_DELIVERABLE_CATEGORY_CONFIG,
    deliverableId
  }
}

export function updateCategorySelection(categoryId) {
  return {
    type: UPDATE_SELECTED_DELIVERABLE_CATEGORY_SELECTION,
    categoryId
  }
}

export function saveCategoryConfig(payload, callback) {
  return {
    type: SAVE_DELIVERABLE_CATEGORY_CONFIG,
    payload,
    callback
  }
}

export function sortAssociatedCategories(payload) {
  return {
    type: SORT_ASSOCIATED_CATEGORIES,
    payload
  }
}

export function resetCategoryForm() {
  return {
    type: RESET_CATEGORY_FORM
  }
}

export function reportSearch(searchText) {
  return {
    type: REPORT_SEARCH,
    searchText
  }
}

export function updateReportsForm(payload) {
  return {
    type: UPDATE_REPORTS_FORM,
    payload
  }
}

export function fetchReportTabs(payload) {
  return {
    type: FETCH_REPORT_TABS,
    payload
  }
}

export function saveReports(payload, callback) {
  return {
    type: SAVE_REPORTS,
    payload,
    callback
  }
}

export function resetReportsForm() {
  return {
    type: RESET_REPORTS_FROM
  }
}

export function loadScriptsData(deliverableId) {
  return {
    type: LOAD_SCRIPTS_DATA,
    deliverableId
  }
}

export function updateScriptsForm(payload) {
  return {
    type: UPDATE_SCRIPTS_FORM,
    payload
  }
}

export function resetScriptsForm(payload) {
  return {
    type: RESET_SRIPTS_FORM,
    payload
  }
}

export function saveScriptsForm(payload, callback) {
  return {
    type: SAVE_SCRIPTS_FORM,
    payload,
    callback
  }
}

export function scheduleNextDelivery(payload, callback) {
  return {
    type: SCHEDULE_NEXT_DELIVERY,
    payload,
    callback
  }
}

export function updateCategoryLabel(payload) {
  return {
    type: UPDATE_CATEGORY_LABEL,
    payload
  }
}

export const fetchScriptTemplate = () => ({
  type: FETCH_DELIVERY_SCRIPT_TEMPLATE
})

export const fetchScriptTemplateSuccess = scriptTemplate => ({
  type: FETCH_DELIVERY_SCRIPT_TEMPLATE_SUCCESS,
  scriptTemplate
})

export const fetchScriptTemplateFailed = () => ({
  type: FETCH_DELIVERY_SCRIPT_TEMPLATE_FAILED
})

export const updateIncExcQueryTree = (queryTree, event) => ({
  type: UPDATE_INC_EXC_QUERY_TREE,
  queryTree,
  event
})

export const emptyIncExcQueryTree = () => ({
  type: EMPTY_INC_EXC_QUERY_TREE
})

export const createIncExcQueryTree = () => ({
  type: CREATE_INC_EXC_QUERY_TREE
})

export const removeIncExcQueryTree = () => ({
  type: REMOVE_INC_EXC_QUERY_TREE
})

export const revertIncExcQueryTree = () => ({
  type: REVERT_INC_EXC_QUERY_TREE
})

export const createBrandTracker = () => ({
  type: CREATE_BRAND_TRACKER
})

export const addBTQueryTree = index => ({
  type: ADD_BRAND_TRACKER_QUERY_TREE,
  index
})

export const onBrandSearchChange = (searchTerm, btIndex, btqindex) => ({
  type: HANDLE_BRAND_SEARCH_CHANGE,
  searchTerm,
  btIndex,
  btqindex
})

export const handleBrandChange = (brand, btIndex, btqIndex, btqId) => ({
  type: SELECT_BRAND,
  brand,
  btIndex,
  btqIndex,
  btqId
})

export const handleBTLabelChange = (label, btIndex, btId) => ({
  type: BT_LABEL_CHANGE,
  label,
  btIndex,
  btId
})

export const updateBTQueryTree = (query, event, btIndex) => ({
  type: UPDATE_BT_QUERY_TREE,
  query,
  event,
  btIndex
})

export const updateBTQQueryTree = (query, event, btIndex, btqIndex) => ({
  type: UPDATE_BTQ_QUERY_TREE,
  query,
  event,
  btIndex,
  btqIndex
})

export const updateBTBQQueryTree = (brandQuery, event, btIndex, btqIndex) => ({
  type: UPDATE_BTBQ_QUERY_TREE,
  brandQuery,
  event,
  btIndex,
  btqIndex
})

export const emptyBTQueryTree = btIndex => ({
  type: EMPTY_BT_QUERY_TREE,
  btIndex
})

export const emptyBTQQueryTree = (btIndex, btqIndex) => ({
  type: EMPTY_BTQ_QUERY_TREE,
  btIndex,
  btqIndex
})

export const emptyBTBQQueryTree = (btIndex, btqIndex) => ({
  type: EMPTY_BTBQ_QUERY_TREE,
  btIndex,
  btqIndex
})

export const removeBTQuery = (btIndex, btqIndex, btqId) => ({
  type: DELETE_BT_QUERY,
  btIndex,
  btqIndex,
  btqId
})

export const removeBT = (btIndex, btId) => ({
  type: DELETE_BT,
  btIndex,
  btId
})

export const revertAllBTChanges = () => ({
  type: REVERT_ALL_BT_CHANGES
})

export const fetchIncExcTypeChildren = (type, value, id) => ({
  type: FETCH_INC_EXC_CHILDREN,
  data: {
    type,
    value,
    id
  }
})

export const createIncExcMultipleRules = (event, ruleId, values) => ({
  type: CREATE_INC_EXC_MULTIPLE_RULES,
  event,
  ruleId,
  values
})

export const fetchBTTypeChildren = ({ type, value, id, btIndex }) => ({
  type: FETCH_BT_CHILDREN,
  data: {
    type,
    value,
    id,
    btIndex
  }
})

export const fetchBTQTypeChildren = ({ type, value, id, btIndex, index: btqIndex }) => ({
  type: FETCH_BTQ_CHILDREN,
  data: {
    type,
    value,
    id,
    btIndex,
    btqIndex
  }
})

export const fetchBTBQTypeChildren = ({ type, value, id, btIndex, index: btqIndex }) => ({
  type: FETCH_BTBQ_CHILDREN,
  data: {
    type,
    value,
    id,
    btIndex,
    btqIndex
  }
})

export const createBTMultipleRules = ({ event, ruleId, values, btIndex }) => ({
  type: CREATE_BT_MULTIPLE_RULES,
  event,
  ruleId,
  values,
  btIndex
})

export const createBTQMultipleRules = ({ event, ruleId, values, btIndex, index: btqIndex }) => ({
  type: CREATE_BTQ_MULTIPLE_RULES,
  event,
  ruleId,
  values,
  btIndex,
  btqIndex
})

export const createBTBQMultipleRules = ({ event, ruleId, values, btIndex, index: btqIndex }) => ({
  type: CREATE_BTBQ_MULTIPLE_RULES,
  event,
  ruleId,
  values,
  btIndex,
  btqIndex
})

export const getBTInsertSQL = btId => ({
  type: GET_BT_INSERT_SQL,
  btId
})

export const getBTUpdateSQL = btId => ({
  type: GET_BT_UPDATE_SQL,
  btId
})
