import { permissions, products } from 'constants/constants'
import { useSelector } from 'react-redux'
import UserService from 'services/user-service'

/**
 * Get permission information for menu
 */
const usePermissions = () => {
  const { user } = useSelector(state => state.session)

  const isPortalAdmin = UserService.isPortalAdmin(user)
  const isGroupAdmin = UserService.isGroupAdmin(user)
  const hasAdminToolsAccess = UserService.hasAdminToolsAccess(user)

  const userProducts = UserService.getProducts(user)
  const hasReportsAccess = isPortalAdmin || (userProducts.reports && userProducts.reports.enabled)

  const isProductAuthorized = key => UserService.isProductAuthorized(userProducts, key)
  const hasPermission = key => UserService.hasPermission(user, key)

  const hasNIQqcToolAccess = hasPermission(permissions.NIQqcToolView) && isProductAuthorized(products.NIQqcTool)

  return {
    isGroupAdmin,
    isPortalAdmin,
    hasReportsAccess,
    hasAdminToolsAccess,
    isProductAuthorized,
    hasNIQqcToolAccess,
    hasPermission
  }
}

export default usePermissions
