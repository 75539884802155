export const UPDATE_GROUP_FORM = 'UPDATE_GROUP_FORM'
export const RESET_GROUP_FORM = 'RESET_GROUP_FORM'
export const GROUP_FORM_VALID = 'GROUP_FORM_VALID'
export const GROUP_FORM_INVALID = 'GROUP_FORM_INVALID'

export const UPDATE_GROUP = 'UPDATE_GROUP'
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS'

export const FETCH_BASIC_GROUP_INFO = 'FETCH_BASIC_GROUP_INFO'
export const FETCH_BASIC_GROUP_INFO_SUCCESS = 'FETCH_BASIC_GROUP_INFO_SUCCESS'

export const UPDATE_GROUP_ACTIVE = 'UPDATE_GROUP_ACTIVE'
export const UPDATE_GROUP_ACTIVE_SUCCESS = 'UPDATE_GROUP_ACTIVE_SUCCESS'

export const FETCH_GROUP_REPORT_INFO = 'FETCH_GROUP_REPORT_INFO'
export const FETCH_GROUP_REPORT_INFO_SUCCESS = 'FETCH_GROUP_REPORT_INFO_SUCCESS'

export const FETCH_GROUP_EDIT_FEED_INFO = 'FETCH_GROUP_EDIT_FEED_INFO'
export const FETCH_GROUP_EDIT_FEED_INFO_SUCCESS = 'FETCH_GROUP_EDIT_FEED_INFO_SUCCESS'

export const FETCH_GROUP_EDIT_PERMISSIONS = 'FETCH_GROUP_EDIT_PERMISSIONS'
export const FETCH_GROUP_EDIT_PERMISSIONS_SUCCESS = 'FETCH_GROUP_EDIT_PERMISSIONS_SUCCESS'

export const FETCH_GROUP_EDIT_PRODUCTS = 'FETCH_GROUP_EDIT_PRODUCTS'
export const FETCH_GROUP_EDIT_PRODUCTS_SUCCESS = 'FETCH_GROUP_EDIT_PRODUCTS_SUCCESS'

export const TOGGLE_GROUP_PRODUCT = 'TOGGLE_GROUP_PRODUCT'
export const TOGGLE_GROUP_PRODUCT_SUCCESS = 'TOGGLE_GROUP_PRODUCT_SUCCESS'

export const GROUP_EDIT_ACTION_FAILED = 'GROUP_EDIT_ACTION_FAILED'

export const updateGroupForm = (form, formValue) => ({
  type: UPDATE_GROUP_FORM,
  form,
  formValue
})

export const resetGroupForm = () => ({
  type: RESET_GROUP_FORM
})

export const groupFormValid = field => ({
  type: GROUP_FORM_VALID,
  field
})

export const groupFormInvalid = field => ({
  type: GROUP_FORM_INVALID,
  field
})

export const toggleGroupProduct = ({ groupId, productId, enabled }) => ({
  type: TOGGLE_GROUP_PRODUCT,
  enabled,
  groupId,
  productId
})

export const toggleGroupProductSuccess = updatedGroup => ({
  type: TOGGLE_GROUP_PRODUCT_SUCCESS,
  updatedGroup
})

export const updateGroup = (groupId, groupPayload) => ({
  type: UPDATE_GROUP,
  groupId,
  groupPayload
})

export const updateGroupSuccess = updatedGroup => ({
  type: UPDATE_GROUP_SUCCESS,
  updatedGroup
})

export const fetchGroupBasicInfo = groupId => ({
  type: FETCH_BASIC_GROUP_INFO,
  groupId
})

export const fetchGroupBasicInfoSuccess = basicInfo => ({
  type: FETCH_BASIC_GROUP_INFO_SUCCESS,
  basicInfo
})

export const fetchGroupReports = groupId => ({
  type: FETCH_GROUP_REPORT_INFO,
  groupId
})

export const fetchGroupReportsSuccess = ({ groupReports }) => ({
  type: FETCH_GROUP_REPORT_INFO_SUCCESS,
  groupReports
})

export const fetchGroupFeedsInfo = groupId => ({
  type: FETCH_GROUP_EDIT_FEED_INFO,
  groupId
})

export const fetchGroupFeedsInfoSuccess = feedsData => ({
  type: FETCH_GROUP_EDIT_FEED_INFO_SUCCESS,
  feedsData
})

export const fetchGroupPermissions = groupId => ({
  type: FETCH_GROUP_EDIT_PERMISSIONS,
  groupId
})

export const fetchGroupPermissionsSuccess = permissionData => ({
  type: FETCH_GROUP_EDIT_PERMISSIONS_SUCCESS,
  permissionData
})

export const fetchGroupProducts = groupId => ({
  type: FETCH_GROUP_EDIT_PRODUCTS,
  groupId
})

export const fetchGroupProductsSuccess = productsData => ({
  type: FETCH_GROUP_EDIT_PRODUCTS_SUCCESS,
  productsData
})

export const updateGroupActive = (groupId, isActive) => ({
  type: UPDATE_GROUP_ACTIVE,
  groupId,
  isActive
})

export const updateGroupActiveSuccess = updatedGroup => ({
  type: UPDATE_GROUP_ACTIVE_SUCCESS,
  updatedGroup
})

export const groupEditActionFailed = error => ({
  type: GROUP_EDIT_ACTION_FAILED,
  error
})
