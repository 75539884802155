// eslint-disable-next-line no-redeclare
/* global TENANT */
import './sentry'
import axios from 'axios'
import cuid from 'cuid'
import config from './config'

if (!TENANT) {
  throw new Error('No TENANT found')
}

axios.defaults.headers.common.tenant = TENANT
window.Tenant = TENANT
// setting server base URL for axios
axios.defaults.baseURL = config.serverBaseUrl

// add trace request id for each request
axios.interceptors.request.use(config => {
  config.headers['X-Request-Id'] = cuid()
  return config
})
