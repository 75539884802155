export const FETCH_ETL_STATS = 'FETCH_ETL_STATS'
export const FETCH_ETL_STATS_SUCCESS = 'FETCH_ETL_STATS_SUCCESS'
export const FETCH_ETL_STATS_FAILED = 'FETCH_ETL_STATS_FAILED'
export const FETCH_ETL_STATS_TIMEFRAME = 'FETCH_ETL_STATS_TIMEFRAME'
export const FETCH_ETL_STATS_TIMEFRAME_SUCCESS = 'FETCH_ETL_STATS_TIMEFRAME_SUCCESS'
export const FETCH_ETL_STATS_TIMEFRAME_FAILED = 'FETCH_ETL_STATS_TIMEFRAME_FAILED'
export const UPDATE_DATE_SELECTION_ETL_STATS = 'UPDATE_DATE_SELECTION_ETL_STATS'

export const fetchETLStats = payload => ({
  type: FETCH_ETL_STATS,
  payload
})

export const fetchETLStatsSuccess = etlTasks => ({
  type: FETCH_ETL_STATS_SUCCESS,
  etlTasks
})

export const fetchETLStatsFailed = error => ({
  type: FETCH_ETL_STATS_FAILED,
  error
})

export const fetchETLStatsTimeframe = () => ({
  type: FETCH_ETL_STATS_TIMEFRAME
})

export const fetchETLStatsTimeframeSuccess = tasksTimeframe => ({
  type: FETCH_ETL_STATS_TIMEFRAME_SUCCESS,
  tasksTimeframe
})

export const fetchETLStatsTimeframeFailed = error => ({
  type: FETCH_ETL_STATS_TIMEFRAME_FAILED,
  error
})

export const updateDateSelectionETLStats = selectedTimeframe => ({
  type: UPDATE_DATE_SELECTION_ETL_STATS,
  selectedTimeframe
})
