/*!
 * deps
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './index.scss'
import Box from '../Box'
import ClearButtonIcon from '../Icon/Close'

/*!
 * globals
 */

const noop = () => {}

/**
 * Input component
 */

class Input extends Component {
  onTextChange = event => {
    return this.props.textDidChange(event.target.value)
  }

  focus = () => {
    this.input && this.input.focus()
  }

  render() {
    const {
      className,
      iconName,
      placeholder,
      id,
      type,
      value,
      autoFocus,
      tabIndex,
      disabled,
      clearButton,
      clearButtonId,
      didClickClearButton
    } = this.props
    return (
      <Box className={cx('input', className, 'alignItemsCenter flex input-search')}>
        <>
          {iconName}
          <input
            ref={el => {
              this.input = el
            }}
            id={id}
            onChange={this.onTextChange}
            placeholder={placeholder}
            type={type}
            value={value}
            className="flex1"
            autoFocus={autoFocus}
            tabIndex={tabIndex}
            disabled={disabled}
          />
          {clearButton && (
            <ClearButtonIcon
              className={cx({ hidden: !value })}
              width={24}
              height={24}
              clearButtonId={clearButtonId}
              onClick={didClickClearButton}
            />
          )}
        </>
      </Box>
    )
  }
}

Input.propTypes = {
  type: PropTypes.oneOf(['password', 'text']),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  textDidChange: PropTypes.func,
  didClickClearButton: PropTypes.func,
  iconName: PropTypes.element,
  clearButton: PropTypes.bool,
  tabIndex: PropTypes.string,
  disabled: PropTypes.bool,
  clearButtonId: PropTypes.string,
  id: PropTypes.string
}

Input.defaultProps = {
  type: 'text',
  value: '',
  autoFocus: false,
  placeholder: '',
  textDidChange: noop,
  didClickClearButton: noop,
  clearButton: false,
  disabled: false,
  clearButtonId: ''
}

export default Input
