import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'

class DisableToggleButton extends Component {
  static propTypes = {
    label: PropTypes.string, // "x"
    className: PropTypes.string, // css classNames to be applied
    handleOnClick: PropTypes.func, // callback function to invoke removing
    level: PropTypes.number, // The level of the current group
    isDisabled: PropTypes.bool
  }

  render() {
    const tooltip = name => <Tooltip id="disable-toggle-tooltip">{name}</Tooltip>
    return (
      <Button className={`${this.props.className}`} bsSize="xsmall" bsStyle="link" onClick={this.props.handleOnClick}>
        <OverlayTrigger
          delayShow={1000}
          placement="top"
          overlay={this.props.isDisabled ? tooltip('Enable') : tooltip('Disable')}
        >
          <Glyphicon glyph={this.props.isDisabled ? 'eye-close' : 'eye-open'} />
        </OverlayTrigger>
      </Button>
    )
  }
}

export default DisableToggleButton
