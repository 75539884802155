/* eslint-disable max-len */

/*!
 * deps
 */

import React from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'
import cx from 'classnames'

/**
 * Close component
 */
const Close = props => {
  return (
    <IconBase
      viewBox="0 0 30 30"
      height={props.height}
      width={props.width}
      onClick={props.onClick}
      className={cx('close-icon-svg', props.className)}
      id={props.clearButtonId ? `close-icon-svg-${props.clearButtonId}` : 'close-icon-svg'}
    >
      <g>
        <path fill="none" d="M0 0h30v30H0z" />
        <path
          id="close-icon-path"
          d="M8.6 8.6C5 12.1 5 17.9 8.6 21.4c3.6 3.6 9.3 3.6 12.9 0 3.6-3.6 3.6-9.3 0-12.9C17.9 5 12.1 5 8.6 8.6zm10.8 3.8l-2.7 2.7 2.7 2.7c.5.5.5 1.2 0 1.6-.5.5-1.2.5-1.6 0l-2.7-2.7-2.7 2.7c-.5.5-1.2.5-1.6 0-.5-.5-.5-1.2 0-1.6l2.7-2.7-2.7-2.7c-.5-.5-.5-1.2 0-1.6.5-.5 1.2-.5 1.6 0l2.7 2.7 2.7-2.7c.5-.5 1.2-.5 1.6 0 .5.4.5 1.1 0 1.6z"
        />
      </g>
    </IconBase>
  )
}

Close.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  clearButtonId: PropTypes.string,
  className: PropTypes.string
}

export default Close
