import { createReducer } from '@reduxjs/toolkit'
import { remove } from 'lodash'

import {
  // modules
  setSelectedFeedModuleAction,
  resetSelectedFeedModuleAction,
  setEditedFeedModuleAction,
  resetEditedFeedModuleAction,
  setFeedModuleLoadingAction,
  successLoadFeedModulesAction,
  successSaveFeedModuleAction,
  successRemoveFeedModuleAction,
  // query
  setFeedModuleQueryLoadingAction,
  successLoadFeedModuleQueriesAction,
  successSaveFeedModuleQueryAction,
  setOpenNewFeedModuleQueryAction,
  successRemoveFeedModuleQueryAction
} from './actions'

const initialState = {
  loading: false,
  queries: [],
  queryLoading: false,
  isOpenAddNewQuery: false,
  selectedFeedModule: null,
  editedFeedModule: null,
  data: {
    rows: []
  }
}

const feedModulesReducer = createReducer(initialState, builder => {
  builder
    // MODULES
    .addCase(setSelectedFeedModuleAction.type, (state, action) => {
      state.selectedFeedModule = action.payload
    })

    .addCase(resetSelectedFeedModuleAction.type, (state, action) => {
      state.selectedFeedModule = null
    })

    .addCase(setEditedFeedModuleAction.type, (state, action) => {
      state.editedFeedModule = action.payload
    })

    .addCase(resetEditedFeedModuleAction.type, (state, action) => {
      state.editedFeedModule = null
    })

    .addCase(setFeedModuleLoadingAction.type, (state, action) => {
      state.loading = action.payload
    })

    .addCase(successLoadFeedModulesAction.type, (state, action) => {
      state.data.rows = action.payload
    })

    .addCase(successSaveFeedModuleAction.type, (state, action) => {
      const module = action.payload
      // save/update in rows
      const indexChangeModule = state.data.rows.findIndex(m => m.id === module.id)
      if (indexChangeModule > -1) {
        state.data.rows[indexChangeModule] = module
      } else {
        state.data.rows.push(module)
      }
    })

    .addCase(successRemoveFeedModuleAction.type, (state, action) => {
      const id = action.payload.id
      remove(state.data.rows, m => m.id === id)
    })

    // QUERIES
    .addCase(setFeedModuleQueryLoadingAction.type, (state, action) => {
      state.queryLoading = action.payload
    })
    .addCase(successLoadFeedModuleQueriesAction.type, (state, action) => {
      state.queries = action.payload
    })

    .addCase(successSaveFeedModuleQueryAction.type, (state, action) => {
      const query = action.payload
      const indexChangedQuery = state.queries.findIndex(q => q.id === query.id)
      if (indexChangedQuery > -1) {
        state.queries[indexChangedQuery] = query
      } else {
        state.queries.push(query)
      }
    })
    .addCase(setOpenNewFeedModuleQueryAction.type, (state, action) => {
      state.isOpenAddNewQuery = action.payload
    })
    .addCase(successRemoveFeedModuleQueryAction.type, (state, action) => {
      const id = action.payload.id
      remove(state.queries, m => m.id === id)
    })
})

export default feedModulesReducer
