import React from 'react'
import PropTypes from 'prop-types'

import { Alert } from 'react-bootstrap'

function Errorbox({ message = 'There was an error', onDismiss }) {
  return (
    <Alert bsStyle="danger" onDismiss={onDismiss}>
      {message}
    </Alert>
  )
}

Errorbox.propTypes = {
  message: PropTypes.string,
  onDismiss: PropTypes.func
}
export default Errorbox
