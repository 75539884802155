import axios from 'axios'

/**
 * Wrapper for using "axios" in RTK Query
 */
const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) => async ({ url, method, data, params }) => {
  try {
    const result = await axios({ url: baseUrl + url, method, data, params })
    return { data: result.data }
  } catch (axiosError) {
    const err = axiosError
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    }
  }
}

export default axiosBaseQuery
