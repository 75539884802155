import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import GroupRoles from './group-roles'
import BasicInfos, { getSelectableGroups } from './basic-infos'
import PasswordEdit from './password-edit'
import ResendOnboardingLink from './resend-onboarding-link'
import RenderFeedEditField from './feed-edit'
import validate from './user-edit-validation'
import { each, reject, isEmpty } from 'lodash'
import { getProductRoute } from '../../routes'
import UserService from '../../services/user-service'

const PROPERTIES_TO_EXCLUDE = [
  'products',
  'categories',
  'permissions',
  'customReports',
  'defaultProduct',
  'groupSuggestions'
]

class UserEditForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      groupRoles: {}
    }
  }

  componentDidMount() {
    if (!this.props.id) {
      this.initializeForm(this.props.user, [], true)
    } else {
      this.initializeForm(this.props.userEdit, this.props.userEdit.Groups, this.props.id === undefined)
    }
  }

  setRoleRadioState = (groupId, groupRole) => {
    this.setState({
      ...this.state,
      clicked: true,
      groupRoles: {
        ...this.state.groupRoles,
        [groupId]: groupRole
      }
    })
  }

  setBasicInfosState = groupRoles => {
    // Disable save button if no selected groups
    const clicked = Object.keys(groupRoles).length > 0

    this.setState({
      ...this.state,
      clicked,
      groupRoles
    })
  }

  setFeedState = isFeedNotificationOn => {
    this.setState({
      ...this.state,
      isFeedNotificationOn,
      clicked: true
    })
  }

  handleSubmit = data => {
    // Remove unwanted properties when saving a user
    const selectedKeys = reject(Object.keys(data), key => PROPERTIES_TO_EXCLUDE.includes(key))
    const updatedData = selectedKeys.reduce((acc, key) => {
      acc[key] = data[key]
      return acc
    }, {})

    updatedData.isFeedNotificationOn = this.state.isFeedNotificationOn
    const isUserEditEmpty = isEmpty(this.props.userEdit)
    updatedData.id = isUserEditEmpty
      ? this.props.id === 'new'
        ? undefined
        : this.props.user.id
      : this.props.userEdit.id

    const groupRoleKeys = Object.keys(this.state.groupRoles)
    if (groupRoleKeys.length > 0) {
      const groups = {}
      groupRoleKeys.forEach(groupId => {
        groups[groupId] = this.state.groupRoles[groupId].isAdmin
      })
      updatedData.groups = groups
    }

    const payload = {
      user: updatedData,
      skipSuccessModal: !this.props.id && !this.props.user.isOnboarded,
      callback: (newUserData, oldUserData) => {
        if (oldUserData && newUserData && newUserData.isOnboarded !== oldUserData.isOnboarded) {
          const productRoute = getProductRoute(this.props.user.defaultProduct, this.props.session)
          return this.props.history.replace(productRoute)
        } else {
          return this.props.history.goBack()
        }
      }
    }
    this.props.saveUser(payload)
  }

  /**
   * Initializes the user edit form
   * @param {Object}  user   User object
   * @param {Array}   groups Groups
   * @param {Boolean} isSelf Whether we are editing our own profile
   */
  initializeForm(user, groups = null, isSelf = false) {
    // We are either editing a user or creating a new one
    if (!isSelf) {
      this.props.initialize({
        ...user,
        groups: Object.values(user.Groups || [])
          .map(group => group.id)
          .join(',')
      })

      const groupRoles = {}
      each(getSelectableGroups(groups), g => {
        groupRoles[g.value] = {
          visible: false,
          isAdmin: false,
          isPortalGroup: g.isPortalGroup,
          name: g.label
        }
      })

      each(user.Groups || [], g => {
        groupRoles[g.id].visible = true
        groupRoles[g.id].isAdmin = g.GroupUser.isAdmin
        groupRoles[g.id].isActive = g.GroupUser.isActive
        groupRoles[g.id].isGroupActive = g.isActive
      })

      this.setState({
        ...this.state,
        groupRoles,
        init: true,
        isFeedNotificationOn: user.isFeedNotificationOn
      })
    } else {
      this.props.initialize(user)
      this.setState({
        ...this.state,
        init: true,
        isFeedNotificationOn: user.isFeedNotificationOn
      })
    }
  }

  canBeDeleted() {
    const isPortalAdmin = UserService.isPortalAdmin(this.props.userEdit)
    const isUserActive = UserService.isUserActive(this.props.userEdit)

    return isPortalAdmin ? isUserActive === false : true
  }

  render() {
    const { handleSubmit, dirty, valid, id, user, groups, userEdit } = this.props
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <div className="clearfix">
          <span className="formTitle">{id === 'new' ? 'Create User' : id ? 'Edit User' : 'Edit Profile'}</span>
          <div className="rightFormButton">
            {user && user.isOnboarded && (
              <button
                className="btn_custom_secondary"
                type="button"
                onClick={() => {
                  this.props.onUpdateFilter('', 'users')
                  this.props.history.goBack()
                }}
              >
                Cancel
              </button>
            )}
            {this.props.userEdit && this.props.userEdit.id && (
              <>
                {userEdit.isActive ? (
                  <button
                    type="button"
                    className={'btn_custom mr-2'}
                    onClick={() => {
                      this.props.disableUser(this.props.userEdit.id, this.props.userEdit.fullname)
                    }}
                  >
                    Disable User
                  </button>
                ) : (
                  <button
                    type="button"
                    className={'btn_custom_secondary mr-2 ml-0'}
                    onClick={() => {
                      this.props.enableUser(this.props.userEdit.id)
                    }}
                  >
                    Enable User
                  </button>
                )}
              </>
            )}
            {this.props.userEdit && this.props.userEdit.id && this.canBeDeleted() && (
              <button
                type="button"
                className={'btn_custom_red'}
                onClick={() => {
                  this.props.deleteUser(this.props.userEdit.id, this.props.userEdit.fullname)
                }}
              >
                Delete User
              </button>
            )}
            <button
              disabled={
                id === 'new' ? !dirty || !valid || !this.state.clicked : (!dirty || !valid) && !this.state.clicked
              }
              className="btn_custom"
              type="submit"
            >
              {id === 'new' ? 'Create' : 'Save Changes'}
            </button>
          </div>
        </div>

        <BasicInfos
          id={id}
          user={user}
          groups={groups}
          userEdit={userEdit}
          groupRoles={this.state.groupRoles}
          setBasicInfosState={this.setBasicInfosState}
          groupSuggestions={this.props.groupSuggestions}
          fetchGroupSuggestions={this.props.fetchGroupSuggestions}
        />

        <GroupRoles id={id} groupRoles={this.state.groupRoles} setRoleRadioState={this.setRoleRadioState} />

        {UserService.isAdmin(user) && userEdit.id && userEdit.id !== 'new' && (
          <ResendOnboardingLink email={userEdit.email} onResendOnboardingLink={this.props.onResendOnboardingLink} />
        )}

        <PasswordEdit
          id={id}
          user={user}
          userEdit={userEdit}
          isPortalAdmin={user ? UserService.isPortalAdmin(user) : false}
          isUserEditPortalAdmin={userEdit && userEdit.Groups ? UserService.isPortalAdmin(userEdit) : false}
          resetPassword={this.props.resetPassword}
        />

        <RenderFeedEditField
          userEdit={userEdit}
          setFeedState={this.setFeedState}
          isFeedNotificationOn={this.state.isFeedNotificationOn}
        />
      </form>
    )
  }
}

UserEditForm.propTypes = {
  handleSubmit: PropTypes.func,
  user: PropTypes.object,
  groups: PropTypes.array,
  userEdit: PropTypes.object,
  saveUser: PropTypes.func,
  disableUser: PropTypes.func,
  enableUser: PropTypes.func,
  deleteUser: PropTypes.func,
  history: PropTypes.object,
  updateCurrentUser: PropTypes.func,
  resetPassword: PropTypes.func,
  initialize: PropTypes.func,
  dirty: PropTypes.bool,
  valid: PropTypes.bool,
  id: PropTypes.string,
  onUpdateFilter: PropTypes.func,
  fetchGroups: PropTypes.func,
  fetchGroupSuggestions: PropTypes.func,
  groupSuggestions: PropTypes.array,
  session: PropTypes.object,
  onResendOnboardingLink: PropTypes.func
}

const form = withRouter(
  reduxForm({
    form: 'user',
    validate
  })(UserEditForm)
)

export default form
