export const SUPRA_QC_CREATE_MULTIPLE_RULES = 'SUPRA_QC_CREATE_MULTIPLE_RULES'
export const SUPRA_QC_LOAD_DEFAULT_QUERY = 'SUPRA_QC_LOAD_DEFAULT_QUERY'
export const SUPRA_QC_QUERY_TREE_CHANGED = 'SUPRA_QC_QUERY_TREE_CHANGED'
export const SUPRA_QC_FETCH_TYPE_CHILDREN = 'SUPRA_QC_FETCH_TYPE_CHILDREN'
export const SUPRA_QC_CLEAR_QUERY_TREE = 'SUPRA_QC_CLEAR_QUERY_TREE'
export const SUPRA_QC_ADD_TO_DICT_REGEX_CHANGED = 'SUPRA_QC_ADD_TO_DICT_REGEX_CHANGED'
export const SUPRA_QC_ADD_TO_DICT_FROM_ADDR_REGEX_CHANGED = 'SUPRA_QC_ADD_TO_DICT_FROM_ADDR_REGEX_CHANGED'
export const SUPRA_QC_ADD_TO_DICT_QUERY_STARTED = 'SUPRA_QC_ADD_TO_DICT_QUERY_STARTED'
export const SUPRA_QC_ADD_TO_DICT_RESULTS_FETCHED = 'SUPRA_QC_ADD_TO_DICT_RESULTS_FETCHED'
export const SUPRA_QC_ADD_ENTRY_TO_DICT = 'SUPRA_QC_ADD_ENTRY_TO_DICT'
export const SUPRA_QC_ADD_TO_DICT_SUCCEEDED = 'SUPRA_QC_ADD_TO_DICT_SUCCEEDED'
export const SUPRA_QC_ADD_TO_DICT_MODAL_OPEN = 'SUPRA_QC_ADD_TO_DICT_MODAL_OPEN'
export const SUPRA_QC_ADD_TO_DICT_MODAL_CLOSED = 'SUPRA_QC_ADD_TO_DICT_MODAL_CLOSED'
export const SUPRA_QC_DICTIONARY_LABEL_CHANGED = 'SUPRA_QC_DICTIONARY_LABEL_CHANGED'
export const SUPRA_QC_USE_IN_TRAINING_CHANGED = 'SUPRA_QC_USE_IN_TRAINING_CHANGED'
export const SUPRA_QC_IS_DELETED_CHANGED = 'SUPRA_QC_IS_DELETED_CHANGED'
export const SUPRA_QC_ADD_TO_DICT_FAILED = 'SUPRA_QC_ADD_TO_DICT_FAILED'
export const SUPRA_QC_ADD_TO_DICT_QUERY_FAILED = 'SUPRA_QC_ADD_TO_DICT_QUERY_FAILED'
export const SUPRA_QC_SET_ACTIVE_TAB = 'SUPRA_QC_SET_ACTIVE_TAB'
export const SUPRA_QC_ON_DICT_TABLE_PAGE_CHANGE = 'SUPRA_QC_ON_DICT_TABLE_PAGE_CHANGE'
export const SUPRA_QC_ON_EDIT_DICT_ENTRY_MODAL_OPEN = 'SUPRA_QC_ON_EDIT_DICT_ENTRY_MODAL_OPEN'
export const SUPRA_QC_ON_DICT_TABLE_REFRESH = 'SUPRA_QC_ON_DICT_TABLE_REFRESH'
export const SUPRA_QC_FETCH_DICT_ENTRIES_STARTED = 'SUPRA_QC_FETCH_DICT_ENTRIES_STARTED'
export const SUPRA_QC_FETCH_DICT_ENTRIES_SUCCEEDED = 'SUPRA_QC_FETCH_DICT_ENTRIES_SUCCEEDED'
export const SUPRA_QC_FETCH_DICT_ENTRIES_FAILED = 'SUPRA_QC_FETCH_DICT_ENTRIES_FAILED'

export const SUPRA_QC_FETCH_SAVED_GROUP = 'SUPRA_QC_FETCH_SAVED_GROUP'
export const SUPRA_QC_FETCH_SAVED_GROUP_SUCCEEDED = 'SUPRA_QC_FETCH_SAVED_GROUP_SUCCEEDED'
export const SUPRA_QC_FETCH_SAVED_GROUP_FAILED = 'SUPRA_QC_FETCH_SAVED_GROUP_FAILED'
export const SUPRA_QC_INPUT_GROUP_CHANGE = 'SUPRA_QC_INPUT_GROUP_CHANGE'
export const SUPRA_QC_GROUP_SELECT = 'SUPRA_QC_GROUP_SELECT'

export const SUPRA_QC_GROUP_CREATE = 'SUPRA_QC_GROUP_CREATE'
export const SUPRA_QC_FETCH_QUERIES_BY_GROUP = 'SUPRA_QC_FETCH_QUERIES_BY_GROUP'
export const SUPRA_QC_QUERY_SELECT = 'SUPRA_QC_QUERY_SELECT'
export const SUPRA_QC_QUERY_CREATE = 'SUPRA_QC_QUERY_CREATE'
export const SUPRA_QC_INPUT_QUERY_CHANGE = 'SUPRA_QC_INPUT_QUERY_CHANGE'
export const SUPRA_QC_DELETE_GROUP = 'SUPRA_QC_DELETE_GROUP'
export const SUPRA_QC_DELETE_QUERY = 'SUPRA_QC_DELETE_QUERY'
export const SUPRA_QC_RESET_QUERY_HEADER = 'SUPRA_QC_RESET_QUERY_HEADER'
export const SUPRA_QC_SAVE_SEARCH_QUERY = 'SUPRA_QC_SAVE_SEARCH_QUERY'
export const SUPRA_QC_CLEAR_COMPLETE_QUERY = 'SUPRA_QC_CLEAR_COMPLETE_QUERY'
export const SUPRA_QC_UPDATE_QUERY_VALUE = 'SUPRA_QC_UPDATE_QUERY_VALUE'
export const SUPRA_QC_UPDATE_GROUP_VALUE = 'SUPRA_QC_UPDATE_GROUP_VALUE'
export const SUPRA_QC_CREATE_GROUP_SUCCEEDED = 'SUPRA_QC_CREATE_GROUP_SUCCEEDED'
export const SUPRA_QC_CREATE_GROUP_FAILED = 'SUPRA_QC_CREATE_GROUP_FAILED'
export const SUPRA_QC_FETCH_QUERIES_BY_GROUP_FAILED = 'SUPRA_QC_FETCH_QUERIES_BY_GROUP_FAILED'
export const SUPRA_QC_FETCH_QUERIES_BY_GROUP_SUCCEEDED = 'SUPRA_QC_FETCH_QUERIES_BY_GROUP_SUCCEEDED'
export const SUPRA_QC_CREATE_QUERY_SUCCEEDED = 'SUPRA_QC_CREATE_QUERY_SUCCEEDED'
export const SUPRA_QC_CREATE_QUERY_FAILED = 'SUPRA_QC_CREATE_QUERY_FAILED'
export const SUPRA_QC_DELETE_GROUP_SUCCEEDED = 'SUPRA_QC_DELETE_GROUP_SUCCEEDED'
export const SUPRA_QC_DELETE_GROUP_FAILED = 'SUPRA_QC_DELETE_GROUP_FAILED'
export const SUPRA_QC_DELETE_QUERY_FAILED = 'SUPRA_QC_DELETE_QUERY_FAILED'
export const SUPRA_QC_DELETE_QUERY_SUCCEEDED = 'SUPRA_QC_DELETE_QUERY_SUCCEEDED'
export const SUPRA_QC_SAVE_SEARCH_QUERY_FAILED = 'SUPRA_QC_SAVE_SEARCH_QUERY_FAILED'
export const SUPRA_QC_SAVE_SEARCH_QUERY_SUCCEEDED = 'SUPRA_QC_SAVE_SEARCH_QUERY_SUCCEEDED'
export const SUPRA_QC_UPDATE_QUERY_HEADER_EDIT_STATUS = 'SUPRA_QC_UPDATE_QUERY_HEADER_EDIT_STATUS'
export const SUPRA_QC_GET_PREVIOUS_QUERY = 'SUPRA_QC_GET_PREVIOUS_QUERY'
export const SUPRA_QC_GET_NEXT_QUERY = 'SUPRA_QC_GET_NEXT_QUERY'
export const SUPRA_QC_GROUP_SEARCH_MODE = 'SUPRA_QC_GROUP_SEARCH_MODE'
export const SUPRA_QC_QUERY_SEARCH_MODE = 'SUPRA_QC_QUERY_SEARCH_MODE'
export const SUPRA_QC_ADD_TO_DICT_TEMPLATE_CHANGED = 'SUPRA_QC_ADD_TO_DICT_TEMPLATE_CHANGED'

export function loadDefaultQuery() {
  return {
    type: SUPRA_QC_LOAD_DEFAULT_QUERY
  }
}

export function createMultipleRules(event, ruleId, values) {
  return {
    type: SUPRA_QC_CREATE_MULTIPLE_RULES,
    event,
    ruleId,
    values
  }
}

export function handleQueryTreeChanged(queryTree, event, actionArgs) {
  return {
    type: SUPRA_QC_QUERY_TREE_CHANGED,
    queryTree,
    event,
    actionArgs
  }
}

export function fetchTypeChildren(type, value, id) {
  return {
    type: SUPRA_QC_FETCH_TYPE_CHILDREN,
    data: {
      type,
      value,
      id
    }
  }
}

export function clearQueryTree() {
  return {
    type: SUPRA_QC_CLEAR_QUERY_TREE
  }
}

export function setSelectedTab(payload) {
  return {
    type: SUPRA_QC_SET_ACTIVE_TAB,
    payload
  }
}

export function onAddToDictRegexChange({ regex, source }) {
  return {
    type: SUPRA_QC_ADD_TO_DICT_REGEX_CHANGED,
    regex,
    source
  }
}

export function onAddToDictTemplateChange({ template }) {
  return {
    type: SUPRA_QC_ADD_TO_DICT_TEMPLATE_CHANGED,
    template
  }
}

export function onFromAddrRegexChange({ from_addr_regex, source }) {
  return {
    type: SUPRA_QC_ADD_TO_DICT_FROM_ADDR_REGEX_CHANGED,
    from_addr_regex,
    source
  }
}

export function onAddToDictModalClosed() {
  return {
    type: SUPRA_QC_ADD_TO_DICT_MODAL_CLOSED
  }
}

export function onAddEntryToDict({ dictionaryEntry }) {
  return {
    type: SUPRA_QC_ADD_ENTRY_TO_DICT,
    dictionaryEntry
  }
}

export function onAddToDictModalOpen({ selectedRow, source }) {
  return {
    type: SUPRA_QC_ADD_TO_DICT_MODAL_OPEN,
    selectedRow,
    source
  }
}

export function onDictionaryLabelChange({ dictionaryLabel }) {
  return {
    type: SUPRA_QC_DICTIONARY_LABEL_CHANGED,
    dictionaryLabel
  }
}

export function onUseInTrainingChange({ useInTraining }) {
  return {
    type: SUPRA_QC_USE_IN_TRAINING_CHANGED,
    useInTraining
  }
}

export function onIsDeletedChange({ isDeleted }) {
  return {
    type: SUPRA_QC_IS_DELETED_CHANGED,
    isDeleted
  }
}

export function onDictionaryTablePageChange() {
  return {
    type: SUPRA_QC_ON_DICT_TABLE_PAGE_CHANGE
  }
}
export function onEditDictEntryModalOpen() {
  return {
    type: SUPRA_QC_ON_EDIT_DICT_ENTRY_MODAL_OPEN
  }
}
export function onDictionaryTableRefresh() {
  return {
    type: SUPRA_QC_ON_DICT_TABLE_REFRESH
  }
}

export function getAllGroup() {
  return {
    type: SUPRA_QC_FETCH_SAVED_GROUP
  }
}

export function onInputGroupChange(inputValue) {
  return {
    type: SUPRA_QC_INPUT_GROUP_CHANGE,
    inputValue
  }
}

export function onGroupChange(selectedGroup) {
  return {
    type: SUPRA_QC_GROUP_SELECT,
    selectedGroup
  }
}

export function onGroupCreate(value) {
  return {
    type: SUPRA_QC_GROUP_CREATE,
    value
  }
}

export function fetchQueries() {
  return {
    type: SUPRA_QC_FETCH_QUERIES_BY_GROUP
  }
}

export function onQueryChange(selectedQuery) {
  return {
    type: SUPRA_QC_QUERY_SELECT,
    selectedQuery
  }
}

export function onQueryCreate(value) {
  return {
    type: SUPRA_QC_QUERY_CREATE,
    value
  }
}

export function onInputQueryChange(inputValue) {
  return {
    type: SUPRA_QC_INPUT_QUERY_CHANGE,
    inputValue
  }
}

export function onDeleteGroup() {
  return {
    type: SUPRA_QC_DELETE_GROUP
  }
}

export function onDeleteQuery() {
  return {
    type: SUPRA_QC_DELETE_QUERY
  }
}

export function resetQueryHeader() {
  return {
    type: SUPRA_QC_RESET_QUERY_HEADER
  }
}

export function saveSearchQuery() {
  return {
    type: SUPRA_QC_SAVE_SEARCH_QUERY
  }
}

export function clearCompleteQuery() {
  return {
    type: SUPRA_QC_CLEAR_COMPLETE_QUERY
  }
}

export function onUpdatedQueryValue(value) {
  return {
    type: SUPRA_QC_UPDATE_QUERY_VALUE,
    value
  }
}

export function onUpdatedGroupValue(value) {
  return {
    type: SUPRA_QC_UPDATE_GROUP_VALUE,
    value
  }
}

export function updateQueryHeaderEditStatus(status) {
  return {
    type: SUPRA_QC_UPDATE_QUERY_HEADER_EDIT_STATUS,
    status
  }
}

export function getPreviousQuery() {
  return {
    type: SUPRA_QC_GET_PREVIOUS_QUERY
  }
}

export function getNextQuery() {
  return {
    type: SUPRA_QC_GET_NEXT_QUERY
  }
}

export function onClickGroupSearch() {
  return {
    type: SUPRA_QC_GROUP_SEARCH_MODE
  }
}

export function onClickQuerySearch() {
  return {
    type: SUPRA_QC_QUERY_SEARCH_MODE
  }
}
