import { takeLatest, takeEvery, fork, call, put, select } from 'redux-saga/effects'
import {
  FETCH_FOLDER,
  FETCH_FOLDER_SUCCESS,
  FETCH_FOLDER_ERROR,
  MOVE_FOLDER,
  MOVE_REPORT,
  RENAME_FOLDER,
  RENAME_REPORT,
  CREATE_NEW_FOLDER,
  DELETE_FOLDER,
  DELETE_REPORT
} from '../actions/report-folder-actions'
import { ALERT_CREATE } from '../actions/app-actions'
import cuid from 'cuid'

import axios from 'axios'

function* fetchFolder({ folderId } = {}) {
  try {
    if (folderId === undefined) {
      folderId = yield select(state => state.reportFolders.currFolderId)
    }
    const response = yield call(() => axios.get(`/api/report-folders/${folderId}`))
    yield put({
      type: FETCH_FOLDER_SUCCESS,
      payload: response.data
    })
  } catch (err) {
    console.error(err)
    yield put({
      type: FETCH_FOLDER_ERROR,
      error: err.message
    })
  }
}

function* updateFolder(folderId, payload) {
  try {
    yield call(() => axios.post(`/api/report-folders/folder/${folderId}`, payload))
  } catch (error) {
    console.error(error)
    yield* processUpdateError(error)
  }
}

function* updateReport(reportId, payload) {
  try {
    yield call(() => axios.post(`/api/report-folders/report/${reportId}`, payload))
  } catch (error) {
    console.error(error)
    yield* processUpdateError(error)
  }
}

function* processUpdateError(error) {
  yield put({
    type: ALERT_CREATE,
    alerts: [
      {
        id: cuid(),
        type: 'danger',
        message: error.response.data || 'There was an error processing request',
        headline: 'Report Manager'
      }
    ]
  })
}

function* moveFolder({ folderId, parentId }) {
  try {
    yield updateFolder(folderId, { parentId })
    yield fetchFolder()
  } catch (error) {
    console.error(error)
  }
}

function* moveReport({ reportId, parentId }) {
  try {
    yield updateReport(reportId, { parentId })
    yield fetchFolder()
  } catch (error) {
    console.error(error)
  }
}

function* renameFolder({ folderId, name }) {
  try {
    yield updateFolder(folderId, { name })
    yield fetchFolder()
  } catch (error) {
    console.error(error)
  }
}

function* renameReport({ reportId, name }) {
  try {
    yield updateReport(reportId, { name })
    yield fetchFolder()
  } catch (error) {
    console.error(error)
  }
}

function* createNewFolder({ folderId, name }) {
  try {
    yield call(() =>
      axios.put(`/api/report-folders/folder`, {
        parentId: folderId,
        name
      })
    )
    yield fetchFolder({ folderId })
  } catch (error) {
    console.error(error)
    yield* processUpdateError(error)
  }
}

function* deleteFolder({ folderId, onSuccess }) {
  try {
    yield call(() => axios.delete(`/api/admin/report-folders/folder/${folderId}`))
    if (onSuccess) onSuccess()
    yield fetchFolder()
  } catch (error) {
    console.error(error)
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.response.data || 'There was an error deleting folder',
          headline: 'Report Manager'
        }
      ]
    })
  }
}

function* deleteReport({ reportId, onSuccess }) {
  try {
    const response = yield call(() => axios.delete(`/api/reports/${reportId}`))
    if (response.data.error) {
      throw new Error(response.data.message)
    }
    if (onSuccess) onSuccess()
    yield fetchFolder()
  } catch (error) {
    console.error(error)
    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message || 'There was an error deleting report',
          headline: 'Report Manager'
        }
      ]
    })
  }
}

function* reportFolderFlow() {
  yield takeLatest(FETCH_FOLDER, fetchFolder)
  yield takeLatest(RENAME_FOLDER, renameFolder)
  yield takeLatest(RENAME_REPORT, renameReport)
  yield takeLatest(CREATE_NEW_FOLDER, createNewFolder)
  yield takeLatest(DELETE_FOLDER, deleteFolder)
  yield takeLatest(DELETE_REPORT, deleteReport)
  yield takeEvery(MOVE_FOLDER, moveFolder)
  yield takeEvery(MOVE_REPORT, moveReport)
}

export default [fork(reportFolderFlow)]
