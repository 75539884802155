import { takeLatest, call, put, fork, select } from 'redux-saga/effects'
import axios from 'axios'
import moment from 'moment'

import config from '../../config'
import createDownloadLink from './../../utils/download-link'

import {
  CategoryBrandRulesActions,
  MerchantCategoryRulesActions,
  MerchantBrandRulesActions,
  MerchantCategoryRegexRulesActions,
  MerchantBrandRegexRulesActions,
  BrandSynonymRulesActions,
  CategoryBrandPathRulesActions,
  DeployStatsActions
} from './actions'

import { createAlert } from '../../actions/app-actions'

import { STORE_PATH } from './constants'
import { DOWNLOAD_DATETIME_FORMAT } from '../../constants/constants'

const URL_DEFINING_PART = {
  categoryBrand: 'category-brand',
  merchantCategory: 'merchant-category',
  merchantBrand: 'merchant-brand',
  merchantCategoryRegex: 'merchant-category-regex',
  merchantBrandRegex: 'merchant-brand-regex',
  brandSynonym: 'brand-synonym',
  categoryBrandPath: 'category-brand-path'
}
// COMMON GET METHOD
function* fetchRules({ payload = {}, settings }) {
  try {
    const state = yield select(state => state.rules[settings.storePath])

    const {
      page = state.page,
      sortBy = state.sortBy,
      pageSize = state.pageSize,
      sortOrder = state.sortOrder,
      filters = state.filters
    } = payload

    // save rules table settings in store
    if (settings.updateSettingsAction) {
      yield put(
        settings.updateSettingsAction({
          page,
          sortBy,
          pageSize,
          sortOrder,
          filters
        })
      )
    }

    const { data } = yield call(() =>
      axios.get(`${config.apiBaseUrl}/rops/rules/${settings.urlPart}`, {
        params: {
          page,
          sortBy,
          pageSize,
          sortOrder,
          filters,
          filterType: settings.filterType || 'table'
        }
      })
    )
    yield put(settings.successAction(data))
  } catch (error) {
    window.captureException(error)
    if (settings.failedAction) {
      yield put(settings.failedAction())
    }

    yield put(createAlert('danger', error.message, `Rules ${settings.urlPart} fetch failed`))
  }
}

function* fetchRulesCategoryBrand({ payload = {} }) {
  const settings = {
    storePath: STORE_PATH.categoryBrand,
    urlPart: URL_DEFINING_PART.categoryBrand,
    updateSettingsAction: CategoryBrandRulesActions.updateSettings,
    successAction: CategoryBrandRulesActions.successLoad,
    failedAction: CategoryBrandRulesActions.failedLoad
  }
  yield fetchRules({ payload, settings })
}

function* fetchRulesMerchantCategory({ payload = {} }) {
  const settings = {
    storePath: STORE_PATH.merchantCategory,
    urlPart: URL_DEFINING_PART.merchantCategory,
    updateSettingsAction: MerchantCategoryRulesActions.updateSettings,
    successAction: MerchantCategoryRulesActions.successLoad,
    failedAction: MerchantCategoryRulesActions.failedLoad
  }
  yield fetchRules({ payload, settings })
}

function* fetchRulesMerchantBrand({ payload = {} }) {
  const settings = {
    storePath: STORE_PATH.merchantBrand,
    urlPart: URL_DEFINING_PART.merchantBrand,
    updateSettingsAction: MerchantBrandRulesActions.updateSettings,
    successAction: MerchantBrandRulesActions.successLoad,
    failedAction: MerchantBrandRulesActions.failedLoad
  }
  yield fetchRules({ payload, settings })
}

function* fetchRulesMerchantCategoryRegex({ payload = {} }) {
  const settings = {
    storePath: STORE_PATH.merchantCategoryRegex,
    urlPart: URL_DEFINING_PART.merchantCategoryRegex,
    updateSettingsAction: MerchantCategoryRegexRulesActions.updateSettings,
    successAction: MerchantCategoryRegexRulesActions.successLoad,
    failedAction: MerchantCategoryRegexRulesActions.failedLoad
  }
  yield fetchRules({ payload, settings })
}

function* fetchRulesMerchantBrandRegex({ payload = {} }) {
  const settings = {
    storePath: STORE_PATH.merchantBrandRegex,
    urlPart: URL_DEFINING_PART.merchantBrandRegex,
    updateSettingsAction: MerchantBrandRegexRulesActions.updateSettings,
    successAction: MerchantBrandRegexRulesActions.successLoad,
    failedAction: MerchantBrandRegexRulesActions.failedLoad
  }
  yield fetchRules({ payload, settings })
}

function* fetchRulesBrandSynonym({ payload = {} }) {
  const settings = {
    storePath: STORE_PATH.brandSynonym,
    urlPart: URL_DEFINING_PART.brandSynonym,
    updateSettingsAction: BrandSynonymRulesActions.updateSettings,
    successAction: BrandSynonymRulesActions.successLoad,
    failedAction: BrandSynonymRulesActions.failedLoad
  }
  yield fetchRules({ payload, settings })
}

function* fetchRulesCategoryBrandPath({ payload = {} }) {
  const settings = {
    storePath: STORE_PATH.categoryBrandPath,
    urlPart: URL_DEFINING_PART.categoryBrandPath,
    updateSettingsAction: CategoryBrandPathRulesActions.updateSettings,
    successAction: CategoryBrandPathRulesActions.successLoad,
    failedAction: CategoryBrandPathRulesActions.failedLoad
  }
  yield fetchRules({ payload, settings })
}

// FETCH ONE RULE BY MAIN FIELDS
// one merchant-category rule
function* fetchRuleByMerchantAndCategory({ payload = {} }) {
  const { merchantId, categoryId } = payload
  const payloadWithFilters = {
    page: 1,
    filters: {
      merchantId: {
        value: merchantId
      },
      categoryId: {
        value: categoryId
      }
    }
  }
  const settings = {
    storePath: STORE_PATH.merchantCategory,
    urlPart: URL_DEFINING_PART.merchantCategory,
    updateSettingsAction: null,
    successAction: MerchantCategoryRulesActions.successLoadByMerchantAndCategory
  }
  yield fetchRules({ payload: payloadWithFilters, settings })
}

function* fetchRuleByMerchantAndBrand({ payload = {} }) {
  const { merchantId, brandId } = payload
  const payloadWithFilters = {
    page: 1,
    filters: {
      merchantId: {
        value: merchantId
      },
      brandId: {
        value: brandId
      }
    }
  }
  const settings = {
    storePath: STORE_PATH.merchantBrand,
    urlPart: URL_DEFINING_PART.merchantBrand,
    updateSettingsAction: null,
    successAction: MerchantBrandRulesActions.successLoadByMerchantAndBrand
  }
  yield fetchRules({ payload: payloadWithFilters, settings })
}

function* fetchRuleMerchantCategoryRegex({ payload = {} }) {
  const { merchantId, categoryId, includeParam, ruleType } = payload

  const payloadWithFilters = {
    page: 1,
    filters: {
      merchantId: {
        value: merchantId
      },
      categoryId: {
        value: categoryId
      },
      ruleType: {
        comparator: '=',
        value: ruleType
      },
      includeParam: {
        value: includeParam
      }
    }
  }

  const settings = {
    storePath: STORE_PATH.merchantCategoryRegex,
    urlPart: URL_DEFINING_PART.merchantCategoryRegex,
    updateSettingsAction: null,
    successAction: MerchantCategoryRegexRulesActions.successLoadByMainFields,
    filterType: 'byMainFields'
  }
  yield fetchRules({ payload: payloadWithFilters, settings })
}

function* fetchRuleMerchantBrandRegex({ payload = {} }) {
  const { merchantId, brandId, includeParam, ruleType } = payload

  const payloadWithFilters = {
    page: 1,
    filters: {
      merchantId: {
        value: merchantId
      },
      brandId: {
        value: brandId
      },
      ruleType: {
        comparator: '=',
        value: ruleType
      },
      includeParam: {
        value: includeParam
      }
    }
  }

  const settings = {
    storePath: STORE_PATH.merchantBrandRegex,
    urlPart: URL_DEFINING_PART.merchantBrandRegex,
    updateSettingsAction: null,
    successAction: MerchantBrandRegexRulesActions.successLoadByMainFields,
    filterType: 'byMainFields'
  }
  yield fetchRules({ payload: payloadWithFilters, settings })
}

// ************** SAVE (create&&update) *******************//
//
// COMMON SAVE METHOD
function* saveRule({ payload, settings }) {
  try {
    yield call(() => axios.post(`${config.apiBaseUrl}/rops/rules/${settings.urlPart}`, { payload }))

    yield put(createAlert('success', '', `${settings.name} rule saved`))

    yield put(settings.successAction())
  } catch (error) {
    window.captureException(error)
    yield put(
      createAlert('danger', error.response.data.message || error.message, `${settings.name} rule saving failed`)
    )
  }
}

//  Category-Brand
function* saveRuleCategoryBrand({ payload }) {
  const settings = {
    urlPart: URL_DEFINING_PART.categoryBrand,
    name: 'Category Brand',
    successAction: CategoryBrandRulesActions.fetch
  }
  yield saveRule({ payload, settings })
}

//  Merchant-Category
function* saveRuleMerchantCategory({ payload }) {
  const settings = {
    urlPart: URL_DEFINING_PART.merchantCategory,
    name: 'Merchant Category',
    successAction: MerchantCategoryRulesActions.fetch
  }
  yield saveRule({ payload, settings })
}

//  Merchant-Brand
function* saveRuleMerchantBrand({ payload }) {
  const settings = {
    urlPart: URL_DEFINING_PART.merchantBrand,
    name: 'Merchant Brand',
    successAction: MerchantBrandRulesActions.fetch
  }
  yield saveRule({ payload, settings })
}

//  Merchant-Category-Regex
function* saveRuleMerchantCategoryRegex({ payload }) {
  const settings = {
    urlPart: URL_DEFINING_PART.merchantCategoryRegex,
    name: 'Merchant Category Regex',
    successAction: MerchantCategoryRegexRulesActions.fetch
  }
  yield saveRule({ payload, settings })
}

//  Merchant-Brand-Regex
function* saveRuleMerchantBrandRegex({ payload }) {
  const settings = {
    urlPart: URL_DEFINING_PART.merchantBrandRegex,
    name: 'Merchant Brand Regex',
    successAction: MerchantBrandRegexRulesActions.fetch
  }
  yield saveRule({ payload, settings })
}

//  Brand-Synonym
function* saveRuleBrandSynonym({ payload }) {
  const settings = {
    urlPart: URL_DEFINING_PART.brandSynonym,
    name: 'Brand Synonym',
    successAction: BrandSynonymRulesActions.fetch
  }
  yield saveRule({ payload, settings })
}

/*
 *
 * ************* DOWNLOAD *******************
 *
 */
function* downloadRules(settings) {
  try {
    yield put(createAlert('success', '', 'Preparing CSV for download'))

    const { sortBy, sortOrder, filters } = yield select(state => state.rules[settings.storePath])

    const response = yield call(() =>
      axios({
        url: `api/rops/rules/${settings.urlPart}/download`,
        method: 'get',
        responseType: 'blob',
        params: {
          sortBy,
          sortOrder,
          filters
        }
      })
    )

    createDownloadLink(response.data, `rules-${settings.urlPart}-${moment().format(DOWNLOAD_DATETIME_FORMAT)}.csv`)
  } catch (error) {
    yield put(createAlert('danger', error.message, 'Rules CSV download failed'))
  }
}

function* downloadRulesCategoryBrand() {
  const settings = {
    storePath: STORE_PATH.categoryBrand,
    urlPart: URL_DEFINING_PART.categoryBrand
  }
  yield downloadRules(settings)
}

function* downloadRulesMerchantCategory() {
  const settings = {
    storePath: STORE_PATH.merchantCategory,
    urlPart: URL_DEFINING_PART.merchantCategory
  }
  yield downloadRules(settings)
}

function* downloadRulesMerchantBrand() {
  const settings = {
    storePath: STORE_PATH.merchantBrand,
    urlPart: URL_DEFINING_PART.merchantBrand
  }
  yield downloadRules(settings)
}

function* downloadRulesMerchantCategoryRegex() {
  const settings = {
    storePath: STORE_PATH.merchantCategoryRegex,
    urlPart: URL_DEFINING_PART.merchantCategoryRegex
  }
  yield downloadRules(settings)
}

function* downloadRulesMerchantBrandRegex() {
  const settings = {
    storePath: STORE_PATH.merchantBrandRegex,
    urlPart: URL_DEFINING_PART.merchantBrandRegex
  }
  yield downloadRules(settings)
}

function* downloadRulesBrandSynonym() {
  const settings = {
    storePath: STORE_PATH.brandSynonym,
    urlPart: URL_DEFINING_PART.brandSynonym
  }
  yield downloadRules(settings)
}

function* downloadRulesCategoryBrandPath() {
  const settings = {
    storePath: STORE_PATH.categoryBrandPath,
    urlPart: URL_DEFINING_PART.categoryBrandPath
  }
  yield downloadRules(settings)
}

/*
 *
 * ************* DEPLOY STATS *******************
 *
 */
function* fetchRulesDeployStats() {
  try {
    const { data } = yield call(() => axios.get(`${config.apiBaseUrl}/rops/rules/deploy-stats`, {}))
    yield put(DeployStatsActions.successLoad(data))
  } catch (error) {
    window.captureException(error)

    yield put(createAlert('danger', error.message, `Rules deploy info fetch failed`))
  }
}

/*
 * FLOWS
 */

function* fetchRulesFlow() {
  // fetch ruels
  yield takeLatest(CategoryBrandRulesActions.fetch.type, fetchRulesCategoryBrand)
  yield takeLatest(MerchantCategoryRulesActions.fetch.type, fetchRulesMerchantCategory)
  yield takeLatest(MerchantBrandRulesActions.fetch.type, fetchRulesMerchantBrand)
  yield takeLatest(MerchantCategoryRegexRulesActions.fetch.type, fetchRulesMerchantCategoryRegex)
  yield takeLatest(MerchantBrandRegexRulesActions.fetch.type, fetchRulesMerchantBrandRegex)
  yield takeLatest(BrandSynonymRulesActions.fetch.type, fetchRulesBrandSynonym)
  yield takeLatest(CategoryBrandPathRulesActions.fetch.type, fetchRulesCategoryBrandPath)

  // fetch one rule
  yield takeLatest(MerchantCategoryRulesActions.fetchByMerchantAndCategory.type, fetchRuleByMerchantAndCategory)
  yield takeLatest(MerchantBrandRulesActions.fetchByMerchantAndBrand.type, fetchRuleByMerchantAndBrand)
  yield takeLatest(MerchantCategoryRegexRulesActions.fetchRuleByMainFields.type, fetchRuleMerchantCategoryRegex)
  yield takeLatest(MerchantBrandRegexRulesActions.fetchRuleByMainFields.type, fetchRuleMerchantBrandRegex)

  // save (create&&edit)
  yield takeLatest(CategoryBrandRulesActions.save.type, saveRuleCategoryBrand)
  yield takeLatest(MerchantCategoryRulesActions.save.type, saveRuleMerchantCategory)
  yield takeLatest(MerchantBrandRulesActions.save.type, saveRuleMerchantBrand)
  yield takeLatest(MerchantCategoryRegexRulesActions.save.type, saveRuleMerchantCategoryRegex)
  yield takeLatest(MerchantBrandRegexRulesActions.save.type, saveRuleMerchantBrandRegex)
  yield takeLatest(BrandSynonymRulesActions.save.type, saveRuleBrandSynonym)

  // download
  yield takeLatest(CategoryBrandRulesActions.download.type, downloadRulesCategoryBrand)
  yield takeLatest(MerchantCategoryRulesActions.download.type, downloadRulesMerchantCategory)
  yield takeLatest(MerchantBrandRulesActions.download.type, downloadRulesMerchantBrand)
  yield takeLatest(MerchantCategoryRegexRulesActions.download.type, downloadRulesMerchantCategoryRegex)
  yield takeLatest(MerchantBrandRegexRulesActions.download.type, downloadRulesMerchantBrandRegex)
  yield takeLatest(BrandSynonymRulesActions.download.type, downloadRulesBrandSynonym)
  yield takeLatest(CategoryBrandPathRulesActions.download.type, downloadRulesCategoryBrandPath)

  // deploy
  yield takeLatest(DeployStatsActions.fetch.type, fetchRulesDeployStats)
}

export default [fork(fetchRulesFlow)]
