import React from 'react'

import { confirmModal } from '../../../actions/modal-actions'
import GroupLogo from '../../GroupLogo'

const tableStyle = {
  borderTop: '2px solid #E3E3E3',
  borderBottom: '2px solid #E3E3E3',
  width: '100%',
  margin: '10px 0px'
}

const userDisableModal = (userName, callback) =>
  confirmModal(
    <div>
      The user will have access to App only after reset password.
      <table style={tableStyle}>
        <tbody>
          <tr>
            <td className={'groupLogo'}>
              <GroupLogo imgAlt="group_logo" />
            </td>
            <td>{userName}</td>
          </tr>
        </tbody>
      </table>
    </div>,
    'Are you sure?',
    { okButton: 'Continue' },
    callback
  )

export default userDisableModal
