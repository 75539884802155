import {
  FETCH_BASIC_GROUP_INFO,
  FETCH_BASIC_GROUP_INFO_SUCCESS,
  UPDATE_GROUP,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_ACTIVE,
  UPDATE_GROUP_ACTIVE_SUCCESS,
  GROUP_EDIT_ACTION_FAILED,
  UPDATE_GROUP_FORM,
  FETCH_GROUP_REPORT_INFO,
  FETCH_GROUP_REPORT_INFO_SUCCESS,
  FETCH_GROUP_EDIT_PERMISSIONS,
  FETCH_GROUP_EDIT_PERMISSIONS_SUCCESS,
  FETCH_GROUP_EDIT_PRODUCTS,
  FETCH_GROUP_EDIT_PRODUCTS_SUCCESS,
  TOGGLE_GROUP_PRODUCT,
  TOGGLE_GROUP_PRODUCT_SUCCESS,
  FETCH_GROUP_EDIT_FEED_INFO,
  FETCH_GROUP_EDIT_FEED_INFO_SUCCESS,
  RESET_GROUP_FORM,
  GROUP_FORM_VALID,
  GROUP_FORM_INVALID
} from '../actions/group-edit-actions'

import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialState = {
  loading: false,
  error: '',
  validationErrors: {},
  basicInfo: {
    Products: {}
  },
  groupForm: {
    general: {}, // name, seatCount
    reports: {},
    feeds: {},
    permissions: {},
    products: {}
  }
}

const groupEditReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BASIC_GROUP_INFO:
    case FETCH_GROUP_REPORT_INFO:
    case FETCH_GROUP_EDIT_FEED_INFO:
    case FETCH_GROUP_EDIT_PERMISSIONS:
    case FETCH_GROUP_EDIT_PRODUCTS:
    case TOGGLE_GROUP_PRODUCT:
    case UPDATE_GROUP_ACTIVE:
    case UPDATE_GROUP:
      return {
        ...state,
        loading: true
      }

    case UPDATE_GROUP_FORM:
      return {
        ...state,
        groupForm: {
          ...state.groupForm,
          [action.form]: action.formValue
        }
      }

    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        basicInfo: {
          ...state.basicInfo,
          ...action.updatedGroup
        }
      }

    case FETCH_BASIC_GROUP_INFO_SUCCESS:
      const { basicInfo } = action

      return {
        ...state,
        loading: false,
        basicInfo,
        groupForm: {
          ...state.groupForm,
          general: {
            name: basicInfo.name,
            seatCount: basicInfo.seatCount
          }
        }
      }

    case FETCH_GROUP_REPORT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        groupForm: {
          ...state.groupForm,
          reports: action.groupReports
        }
      }

    case FETCH_GROUP_EDIT_FEED_INFO_SUCCESS:
      const { feedsData } = action

      return {
        ...state,
        loading: false,
        groupForm: {
          ...state.groupForm,
          feeds: feedsData
        }
      }

    case FETCH_GROUP_EDIT_PERMISSIONS_SUCCESS:
      const { groupPermissions } = action.permissionData

      return {
        ...state,
        loading: false,
        groupForm: {
          ...state.groupForm,
          permissions: groupPermissions
        }
      }

    case FETCH_GROUP_EDIT_PRODUCTS_SUCCESS:
      const { groupProducts } = action.productsData

      return {
        ...state,
        loading: false,
        groupForm: {
          ...state.groupForm,
          products: groupProducts
        }
      }

    case UPDATE_GROUP_ACTIVE_SUCCESS:
      const { updatedGroup } = action

      return {
        ...state,
        loading: false,
        basicInfo: {
          ...state.basicInfo,
          isActive: updatedGroup.isActive
        }
      }

    case TOGGLE_GROUP_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        basicInfo: action.updatedGroup
      }

    case GROUP_EDIT_ACTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case RESET_GROUP_FORM:
      return {
        ...state,
        basicInfo: initialState.basicInfo,
        groupForm: initialState.groupForm
      }

    case GROUP_FORM_VALID:
      return {
        ...state,
        validationErrors: {
          ...state.validationErrors,
          [action.field]: false
        }
      }

    case GROUP_FORM_INVALID:
      return {
        ...state,
        validationErrors: {
          ...state.validationErrors,
          [action.field]: true
        }
      }

    case LOGOUT_SUCCESS:
      return initialState

    default:
      return state
  }
}

export default groupEditReducer
