import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cx from 'classnames'
import { reduce } from 'lodash'

import DragIcon from '../../../Icon/drag'
import './index.scss'
import './flatMenuList.scss'

import { SortableContainer, SortableElement, arrayMove, SortableHandle } from 'react-sortable-hoc'

import { registerBeacon } from '../../../../actions/walkthrough-actions'

const DragHandle = SortableHandle(({ direction }) => (
  <span>
    <DragIcon size="15" className="drag-icon" />
  </span>
))

const SortableTab = SortableElement(({ value, onClick, getClass, count, length, defaultOptionName }) => {
  return (
    <li
      className={cx(getClass(value.id), value.className, 'menu-item')}
      onClick={() => onClick({ option: value })}
      key={value.id}
      id={value.id}
    >
      {count < length && <DragHandle />}
      <span className="unselectable">{value.name || defaultOptionName}</span>
    </li>
  )
})

const SortableTabContainer = SortableContainer(({ item, onClick, getClass, menuStyle, defaultOptionName }) => {
  return (
    <ul className="flatMenuList list" style={menuStyle}>
      {item.length > 0 &&
        item.map((value, index) => (
          <SortableTab
            id={value.id}
            key={value.id}
            index={index}
            value={value}
            onClick={onClick}
            getClass={getClass}
            count={index + 1}
            length={item.length}
            defaultOptionName={defaultOptionName}
          />
        ))}
    </ul>
  )
})

class FlatMenuList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      selectedOption: this.props.selectedOption,
      showScrollLeft: false,
      showScrollRight: false,
      menuStyle: {},
      leftPosition: 0,
      rightPosition: 0,
      lastMarginLeft: 0,
      leftArrowStyle: { visibility: 'hidden' },
      rightArrowStyle: { visibility: 'hidden' }
    }
  }

  componentDidMount() {
    if (this.props.walkthroughEnabled) {
      this.props.registerBeacon(this.wrapper)
    }
    window.addEventListener('resize', this.reAdjust, true)
    this.reAdjust()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.walkthroughEnabled && prevProps.lastUpdate !== this.props.lastUpdate) {
      this.props.registerBeacon(this.wrapper)
    }

    const selectedOption = this.props.selectedTab
    if (prevState.selectedOption !== selectedOption) {
      this.setState({ selectedOption })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.reAdjust)
  }

  onOptionClick = ({ option }) => {
    if (this.state.selectedOption !== option.id) {
      this.setState({ selectedOption: option.id })
      return this.props.valueDidChange(option)
    }
  }

  getClass = key => {
    return this.state.selectedOption === key ? 'selectedMenuItem' : ''
  }

  reAdjust = () => {
    if (!this.wrapper) {
      return
    }
    const offsetWidth = this.wrapper.offsetWidth
    const flatMenuListWidth = this.getFlatMenuOffsetWidth()
    let { showScrollRight, showScrollLeft, lastMarginLeft } = this.state
    if (offsetWidth < flatMenuListWidth) {
      showScrollRight = true
    } else {
      showScrollRight = false
    }
    let leftPosition = this.getLeftPosition()
    let rightPosition = this.getRightPosition()
    if (leftPosition > 0 || rightPosition > flatMenuListWidth) {
      leftPosition = 0
      rightPosition = offsetWidth
      lastMarginLeft = 0
    }
    if (leftPosition < 0) {
      showScrollLeft = true
    } else {
      showScrollLeft = false
    }
    this.setState({
      leftPosition,
      rightPosition,
      showScrollLeft,
      showScrollRight,
      menuStyle: { marginLeft: `${lastMarginLeft}px` },
      lastMarginLeft
    })
  }

  getLeftPosition = () => this.flatMenu.offsetLeft

  getRightPosition = () => this.wrapper.offsetWidth - this.flatMenu.offsetLeft

  getFlatMenuOffsetWidth = () =>
    reduce(
      this.flatMenu.children,
      (total, child) => {
        return total + child.offsetWidth
      },
      10
    )

  scrollRight = () => {
    const { lastMarginLeft } = this.state
    if (!this.flatMenu) {
      return
    }
    const flatMenuListWidth = this.getFlatMenuOffsetWidth()
    const offsetWidth = this.wrapper.offsetWidth
    let margin = 150
    if (flatMenuListWidth + lastMarginLeft - offsetWidth < 300) {
      margin = flatMenuListWidth + lastMarginLeft - offsetWidth
    }
    const currentMarginLeft = lastMarginLeft - margin
    if (margin !== 150) {
      this.setState({
        menuStyle: { marginLeft: `${currentMarginLeft}px` },
        lastMarginLeft: currentMarginLeft,
        showScrollRight: false,
        showScrollLeft: true
      })
    } else {
      this.setState({
        menuStyle: { marginLeft: `${currentMarginLeft}px` },
        lastMarginLeft: currentMarginLeft,
        showScrollLeft: true
      })
    }
  }

  scrollLeft = () => {
    const { lastMarginLeft } = this.state
    const currentMarginLeft = lastMarginLeft + 150
    if (currentMarginLeft >= 0) {
      this.setState({
        menuStyle: { marginLeft: `${0}px` },
        lastMarginLeft: currentMarginLeft,
        showScrollLeft: false,
        showScrollRight: true
      })
    } else {
      this.setState({
        menuStyle: { marginLeft: `${currentMarginLeft}px` },
        lastMarginLeft: currentMarginLeft,
        showScrollRight: true
      })
    }
  }

  widthOfHidden = () => this.flatMenu.offsetWidth - this.wrapper.offsetWidth

  onSortEnd = ({ oldIndex, newIndex }) => {
    let { options } = this.props
    const { orderDidChange } = this.props
    options = arrayMove(options, oldIndex, newIndex)
    const orderedTabs = options.reduce((tabs, option, index) => {
      if (option.id !== 'new') {
        option.order = index
        tabs[option.id] = option
      }
      return tabs
    }, {})
    orderDidChange(orderedTabs)
  }

  render() {
    const { options, sortable, defaultOptionName, className } = this.props

    return (
      <div className={cx('tablistContainer', className, sortable ? '' : 'no-wrap-container')}>
        <div
          ref={el => {
            this.wrapper = el
          }}
        >
          {sortable && (
            <SortableTabContainer
              ref={el => {
                this.flatMenu = el
              }}
              axis={'xy'}
              helperClass={'list-style'}
              lockToContainerEdges
              item={options}
              useDragHandle
              onSortEnd={this.onSortEnd}
              onSortStart={this.onSortStart}
              onClick={this.onOptionClick}
              getClass={this.getClass}
              menuStyle={this.state.menuStyle}
              defaultOptionName={defaultOptionName}
            />
          )}
          {!sortable && (
            <ul
              className="flatMenuList list"
              style={this.state.menuStyle}
              ref={el => {
                this.flatMenu = el
              }}
            >
              {options.map(option => (
                <li
                  key={option.id}
                  className={cx(this.getClass(option.id), 'unselectable')}
                  onClick={() => {
                    this.onOptionClick({ option })
                  }}
                >
                  <span>{option.name || defaultOptionName}</span>
                </li>
              ))}
            </ul>
          )}
        </div>

        {!sortable && (
          <div
            className="scroller scroller-right"
            style={{
              visibility: this.state.showScrollRight ? 'visible' : 'hidden'
            }}
            onClick={() => this.scrollRight()}
          >
            <i className="glyphicon glyphicon-chevron-right glyphi" />
          </div>
        )}
        {!sortable && (
          <div
            className="scroller scroller-left"
            style={{
              visibility: this.state.showScrollLeft ? 'visible' : 'hidden'
            }}
            onClick={() => this.scrollLeft()}
          >
            <i className="glyphicon glyphicon-chevron-left glyphi" />
          </div>
        )}
      </div>
    )
  }
}

FlatMenuList.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  valueDidChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.string,
  sortable: PropTypes.bool,
  orderDidChange: PropTypes.func,
  selectedTab: PropTypes.any,
  lastUpdate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  registerBeacon: PropTypes.func.isRequired,
  walkthroughEnabled: PropTypes.bool.isRequired,
  defaultOptionName: PropTypes.string,
  reportName: PropTypes.string,
  trackEvent: PropTypes.func
}

FlatMenuList.defaultProps = {
  defaultOptionName: 'No name'
}

// connect

const mapStateToProps = ({ walkthrough }) => ({
  lastUpdate: (walkthrough && walkthrough.lastUpdate) || '',
  walkthroughEnabled: (walkthrough && walkthrough.isEnabled) || false
})

const mapDispatchToProp = dispatch => ({
  registerBeacon: beaconRef => dispatch(registerBeacon('periscope-filters', beaconRef))
})

export default connect(mapStateToProps, mapDispatchToProp)(FlatMenuList)
