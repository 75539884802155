// @flow
import {
  FETCH_ETL_STATS,
  FETCH_ETL_STATS_SUCCESS,
  FETCH_ETL_STATS_FAILED,
  FETCH_ETL_STATS_TIMEFRAME_FAILED,
  FETCH_ETL_STATS_TIMEFRAME_SUCCESS,
  UPDATE_DATE_SELECTION_ETL_STATS
} from '../actions/etl-stats-actions'

import { LOGOUT_SUCCESS } from '../actions/auth-actions'

const initialstate = {
  data: [],
  count: 0,
  error: '',
  failedTaskCount: 0,
  pendingTaskCount: 0,
  taskRuntimes: {},
  loading: false,
  tasksTimeframe: {},
  dateFilterSelection: {}
}

const tasksReducer = (state = initialstate, action) => {
  switch (action.type) {
    case FETCH_ETL_STATS:
      return {
        ...state,
        loading: true
      }

    case FETCH_ETL_STATS_SUCCESS:
      const { rows, count, failedTaskCount, pendingTaskCount, taskRuntimes } = action.etlTasks

      return {
        ...state,
        count,
        failedTaskCount,
        taskRuntimes,
        pendingTaskCount,
        loading: false,
        error: '',
        data: rows
      }

    case FETCH_ETL_STATS_FAILED:
    case FETCH_ETL_STATS_TIMEFRAME_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case FETCH_ETL_STATS_TIMEFRAME_SUCCESS:
      return {
        ...state,
        tasksTimeframe: action.tasksTimeframe
      }

    case UPDATE_DATE_SELECTION_ETL_STATS:
      return {
        ...state,
        dateFilterSelection: action.selectedTimeframe
      }

    case LOGOUT_SUCCESS:
      return initialstate

    default:
      return state
  }
}

export default tasksReducer
