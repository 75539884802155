import { createSlice } from '@reduxjs/toolkit'
import { supraQCToolDefaults } from '../../constants/constants'

const { SAMPLING_SESSIONS_SIZE_PER_PAGE } = supraQCToolDefaults

const supraQCToolV2 = createSlice({
  name: 'supraQCToolV2',
  initialState: {
    samplingSessionsTab: {
      rows: [],
      total: 0,
      page: 1,
      pageSize: SAMPLING_SESSIONS_SIZE_PER_PAGE,
      loading: false,
      dictionaryChecksMap: {},
      domains: []
    },
    generateSampleModal: {
      show: false,
      loading: false,
      defaultValue: {}
    },
    regexUtilityModal: {
      show: false,
      selectedRow: {},
      showAddToDictionaryLoader: false,
      matchesAcrossSample: {
        loading: false,
        rows: [],
        matchPercentage: 0,
        totalNumberOfTemplates: 0,
        numberOfMatchingTemplates: 0
      }
    },
    addEntriesToDictionaryModal: {
      show: false,
      template: '',
      showAddToDictionaryLoader: false
    },
    statsModal: {
      stats: {
        createdAt: '',
        statistics: {}
      },
      show: false
    },
    metricsModal: {
      show: false
    },
    samplingQCTab: {
      sessionId: null,
      rows: [],
      total: 0,
      page: 1,
      loading: false,
      lastDictionaryCheck: {}
    }
  },
  reducers: {
    fetchSamplingSessionsStarted(state, action) {
      state.samplingSessionsTab.loading = true
    },
    fetchSamplingSessionsSucceeded(state, action) {
      const { dictionaryChecksMap, domains } = action.payload
      state.samplingSessionsTab.rows = [...action.payload.rows].map(row => {
        row.domain = row.transformedParameters?.filter_target_domain
        row.lastDictionaryCheckedAt = row.domain && dictionaryChecksMap[row.domain]?.updatedAt
        return row
      })
      state.samplingSessionsTab.dictionaryChecksMap = dictionaryChecksMap
      state.samplingSessionsTab.domains = domains
      state.samplingSessionsTab.total = action.payload.total
      state.samplingSessionsTab.loading = false
    },
    fetchSamplingSessionsFailed(state, action) {
      state.samplingSessionsTab.loading = false
    },
    fetchSamplingQCDataStarted(state, action) {
      state.samplingQCTab.loading = true
      state.samplingQCTab.sessionId = action.payload.sessionId
    },
    fetchSamplingQCDataSucceeded(state, action) {
      state.samplingQCTab.rows = [...action.payload.rows]
      state.samplingQCTab.total = action.payload.total
      state.samplingQCTab.loading = false
    },
    fetchSamplingQCDataFailed(state, action) {
      state.samplingQCTab.loading = false
    },
    openGenerateSampleModal(state, action) {
      state.generateSampleModal.show = true
      state.generateSampleModal.defaultValue = action.payload.defaultValue || {}
    },
    closeGenerateSampleModal(state, action) {
      state.generateSampleModal.show = false
    },

    createNewSampleStarted(state, action) {
      state.generateSampleModal.loading = true
    },
    createNewSampleSucceeded(state, action) {
      state.generateSampleModal.loading = false
      state.generateSampleModal.show = false
    },
    createNewSampleFailed(state, action) {
      state.generateSampleModal.loading = false
      state.generateSampleModal.show = false
    },
    fetchSampleDataSucceeded(state, action) {
      state.samplingQCTab.rows = [...action.payload.rows]
      state.samplingQCTab.total = action.payload.total
      state.statsModal.stats = { ...action.payload.statistics }
      state.samplingQCTab.loading = false
      state.samplingQCTab.lastDictionaryCheck = action.payload.lastDictionaryCheck
    },

    openEditEmailTypeModal(state, action) {
      state.editEmailTypeModal.selectedRow = { ...action.payload.selectedRow }
      state.editEmailTypeModal.emailType = action.payload.selectedRow.email_type.toUpperCase()
      state.editEmailTypeModal.show = true
    },
    closeEditEmailTypeModal(state, action) {
      state.editEmailTypeModal.show = false
    },

    openRegexUtilityModal(state, action) {
      state.regexUtilityModal.selectedRow = { ...action.payload.selectedRow }
      state.regexUtilityModal.show = true
    },

    openAddToDictionaryModal(state, action) {
      state.addEntriesToDictionaryModal.show = true
    },

    closeRegexUtilityModal(state) {
      state.regexUtilityModal.show = false
    },

    addRuleToDictionary(state) {
      state.regexUtilityModal.showAddToDictionaryLoader = true
      state.addEntriesToDictionaryModal.showAddToDictionaryLoader = true
    },

    addRuleToDictionaryCompleted(state) {
      state.regexUtilityModal.showAddToDictionaryLoader = false
      state.addEntriesToDictionaryModal.showAddToDictionaryLoader = false
    },

    openStatsModal(state, action) {
      state.statsModal.show = true
    },

    closeStatsModal(state, action) {
      state.statsModal.show = false
    },

    openMetricsModal(state, action) {
      state.metricsModal.show = true
    },

    closeMetricsModal(state, action) {
      state.metricsModal.show = false
    },

    updateEmailTypeForSamplingQCRow(state, action) {
      state.editEmailTypeModal.emailType = action.payload.emailType
    },

    fetchMatchesAcrossSampleStarted(state) {
      state.regexUtilityModal.matchesAcrossSample.loading = true
      state.regexUtilityModal.matchesAcrossSample.rows = []
      state.regexUtilityModal.matchesAcrossSample.matchPercentage = 0
    },

    fetchMatchesAcrossSampleCompleted(state, action) {
      state.regexUtilityModal.matchesAcrossSample.loading = false
      state.regexUtilityModal.matchesAcrossSample.rows = action.payload.examples
      state.regexUtilityModal.matchesAcrossSample.matchPercentage = action.payload.percentage
      state.regexUtilityModal.matchesAcrossSample.totalNumberOfTemplates = action.payload.totalNumberOfTemplates
      state.regexUtilityModal.matchesAcrossSample.numberOfMatchingTemplates = action.payload.numberOfMatchingTemplates
    },

    fetchMatchesAcrossSampleFailed(state, action) {
      state.regexUtilityModal.matchesAcrossSample.loading = false
      state.regexUtilityModal.matchesAcrossSample.rows = []
      state.regexUtilityModal.matchesAcrossSample.matchPercentage = 0
    },

    updateErrorMessageForRunId(state, action) {
      state.samplingSessionsTab.rows.forEach(row => {
        if (row.run_id === action.payload.runId) {
          row.errorMessage = action.payload.errorMessage
        }
      })
    },

    onPageChangeSamplingSession(state, action) {
      state.samplingSessionsTab.page = action.payload
    },

    onPageSizeChangeSamplingSession(state, action) {
      state.samplingSessionsTab.pageSize = action.payload
    },

    closeAddEntriesToDictModal(state, action) {
      state.addEntriesToDictionaryModal.show = false
    }
  }
})

export const {
  fetchSamplingSessionsStarted,
  fetchSamplingSessionsSucceeded,
  fetchSamplingSessionsFailed,
  openGenerateSampleModal,
  closeGenerateSampleModal,
  createNewSampleStarted,
  createNewSampleSucceeded,
  createNewSampleFailed,
  fetchSampleDataSucceeded,
  fetchSamplingQCDataStarted,

  updateCreateTimeRangeForSamplingQC,
  updateEmailSentTimeRangeForSamplingQC,
  updateDomainSamplingQC,
  updateEmailTypeForSamplingQCRow,
  openSQLQueryModal,
  closeSQLQueryModal,
  openStatsModal,
  closeStatsModal,
  openMetricsModal,
  closeMetricsModal,
  openRegexUtilityModal,
  closeRegexUtilityModal,
  onTemplateStringChange,
  onRegexChange,

  fetchMatchesAcrossSampleStarted,
  fetchMatchesAcrossSampleCompleted,
  fetchMatchesAcrossSampleFailed,

  addRuleToDictionary,
  addRuleToDictionaryCompleted,
  updateErrorMessageForRunId,

  onPageChangeSamplingSession,
  onPageSizeChangeSamplingSession,
  openAddToDictionaryModal,
  closeAddEntriesToDictModal
} = supraQCToolV2.actions

export default supraQCToolV2.reducer
