import React from 'react'
import PropTypes from 'prop-types'

const ButtonField = props => {
  const {
    title,
    trueString,
    falseString,
    checked,
    input: { onChange }
  } = props

  return (
    <table id={'dataFeed'}>
      <tbody>
        <tr>
          <td className={'required'}>{title}</td>
          <td>
            <label>
              <input value="1" type="radio" onChange={onChange} name={`radio_${title}`} defaultChecked={checked} />
              <span>{trueString}</span>
            </label>
          </td>
          <td>
            <label>
              <input value="0" type="radio" onChange={onChange} name={`radio_${title}`} defaultChecked={!checked} />
              <span>{falseString}</span>
            </label>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

ButtonField.propTypes = {
  title: PropTypes.string,
  falseString: PropTypes.string,
  trueString: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  input: PropTypes.object
}

export default ButtonField
