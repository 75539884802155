import { createReducer } from '@reduxjs/toolkit'

import metricsActions from './metricsActions'
import { QC_QUERY_TYPE, METRIC_GRAPTH } from './constants'

const METRICS_STORE_PATH = 'metrics'

export { METRICS_STORE_PATH }

const metricsReducer = createReducer(
  {
    categories: [], // selectedCategories for Metric page
    widgetsVisibility: {
      [METRIC_GRAPTH.DICTIONARY]: true,
      [METRIC_GRAPTH.INCLUDE]: false,
      [METRIC_GRAPTH.EXCLUDE]: false,
      [METRIC_GRAPTH.MISBRAND]: false,
      [METRIC_GRAPTH.INCLUDE_COVERAGE]: false
    },
    [QC_QUERY_TYPE.INCLUDE]: {
      groupQueryId: null,
      groupQueryLabel: null,
      queryId: null,
      queryLabel: null,
      loading: false
    },
    [QC_QUERY_TYPE.EXCLUDE]: {
      groupQueryId: null,
      groupQueryLabel: null,
      queryId: null,
      queryLabel: null,
      loading: false
    },
    [QC_QUERY_TYPE.MISBRAND]: {
      groupQueryId: null,
      groupQueryLabel: null,
      queryId: null,
      queryLabel: null,
      loading: false
    }
  },
  builder => {
    builder
      .addCase(metricsActions.setQueryLoading, (state, action) => {
        const { queryType, loading } = action.payload
        state[queryType].loading = loading
      })
      .addCase(metricsActions.successLoadQuery, (state, action) => {
        const { queryType } = action.payload

        state[queryType].groupQueryId = action.payload.groupQueryId
        state[queryType].groupQueryLabel = action.payload.groupQueryLabel
        state[queryType].queryId = action.payload.queryId
        state[queryType].queryLabel = action.payload.queryLabel
        state[queryType].loading = false
      })

      .addCase(metricsActions.successRemoveQuery, (state, action) => {
        const { queryType } = action.payload

        state[queryType].groupQueryId = null
        state[queryType].groupQueryLabel = null
        state[queryType].queryId = null
        state[queryType].queryLabel = null
        state[queryType].loading = false
      })
      .addCase(metricsActions.toggleWidget, (state, action) => {
        state.widgetsVisibility[action.payload.widget] = action.payload.show
      })
      .addCase(metricsActions.setCategory, (state, action) => {
        state.categories = action?.payload.categories || []
      })
      .addCase(metricsActions.cleanupQueries, state => {
        state[QC_QUERY_TYPE.EXCLUDE] = {
          groupQueryId: null,
          groupQueryLabel: null,
          queryId: null,
          queryLabel: null,
          loading: false
        }
        state[QC_QUERY_TYPE.INCLUDE] = {
          groupQueryId: null,
          groupQueryLabel: null,
          queryId: null,
          queryLabel: null,
          loading: false
        }
        state[QC_QUERY_TYPE.MISBRAND] = {
          groupQueryId: null,
          groupQueryLabel: null,
          queryId: null,
          queryLabel: null,
          loading: false
        }
      })
  }
)

export default metricsReducer
