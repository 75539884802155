import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { every, isEmpty } from 'lodash'

import { Button, ButtonGroup } from 'react-bootstrap'
import GroupInfo from './GroupInfo'
import BetaAlert from '../../BetaAlert'
import groupDeleteModal from '../../Modals/GroupDeleteModal'

import { updateGroup, resetGroupForm, updateGroupActive } from '../../../actions/group-edit-actions'

import { deleteUserGroup } from '../../../actions/group-actions'

import './index.scss'

class GroupEditToolbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showBetaAlert: true
    }
  }

  updateGroupActive = () => {
    this.props.updateGroupActive(this.props.basicInfo.id, !this.props.basicInfo.isActive)
  }

  updateGroup = () => {
    this.props.updateGroup(this.props.groupForm)
  }

  deleteGroup = (id, name) =>
    this.props.groupDeleteModal(name, () =>
      this.props.deleteUserGroup({ id }, () => this.props.history.replace('/admin/groups'))
    )

  cancelGroupEdit = () => {
    this.props.resetGroupForm()
    this.props.history.push('/admin/groups')
  }

  getSaveButtons = () => {
    const { groupId, basicInfo, formValid, groupForm } = this.props

    let newButtonDisabled = false
    if (groupId === 'new') {
      if (!groupForm.general.name || !groupForm.general.seatCount) {
        newButtonDisabled = true
      }

      return (
        <Button className="btn_custom" disabled={!formValid || newButtonDisabled} onClick={this.updateGroup}>
          Create Group
        </Button>
      )
    }

    return (
      <Fragment>
        {basicInfo.isPortalGroup === false && (
          <Fragment>
            <Button
              className="btn_custom"
              id="group-edit-delete"
              onClick={() => {
                this.deleteGroup(this.props.basicInfo.id, this.props.basicInfo.name)
              }}
            >
              Delete Group
            </Button>

            <Button className="btn_custom" id="group-edit-deactivate" onClick={this.updateGroupActive}>
              {basicInfo.isActive ? 'Deactivate' : 'Activate'}
            </Button>
          </Fragment>
        )}

        <Button className="btn_custom" onClick={this.updateGroup} disabled={!formValid}>
          Save Changes
        </Button>
      </Fragment>
    )
  }

  render() {
    const { basicInfo } = this.props
    return (
      <Fragment>
        <BetaAlert
          enabled={this.state.showBetaAlert}
          onDismissAlert={() => {
            this.setState({ showBetaAlert: !this.state.showBetaAlert })
          }}
          message={'Note: This feature is in BETA and has not been production tested'}
        />
        <div className="group-edit-toolbar">
          <GroupInfo basicInfo={basicInfo} />

          <ButtonGroup className="group-edit-toolbar-buttons">
            <Button onClick={this.cancelGroupEdit} className="btn_custom_secondary">
              Cancel
            </Button>

            {this.getSaveButtons()}
          </ButtonGroup>
        </div>
      </Fragment>
    )
  }
}

GroupEditToolbar.propTypes = {
  groupId: PropTypes.string,
  history: PropTypes.object,
  groupForm: PropTypes.object,
  formValid: PropTypes.bool,
  updateGroup: PropTypes.func,
  basicInfo: PropTypes.object,
  resetGroupForm: PropTypes.func,
  deleteUserGroup: PropTypes.func,
  groupDeleteModal: PropTypes.func,
  updateGroupActive: PropTypes.func
}

const mapStateToProps = ({ groupEdit: { basicInfo, groupForm, validationErrors } }, { match, history }) => ({
  history,
  groupForm,
  basicInfo,
  groupId: match.params.id,
  formValid: isEmpty(validationErrors) || every(validationErrors, error => error === false)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetGroupForm: () => dispatch(resetGroupForm()),
  updateGroupActive: (groupId, isActive) => dispatch(updateGroupActive(groupId, isActive)),
  updateGroup: groupPayload => dispatch(updateGroup(ownProps.match.params.id, groupPayload)),
  groupDeleteModal: (group, callback) => dispatch(groupDeleteModal(group, callback)),
  deleteUserGroup: (data, callback) => dispatch(deleteUserGroup({ ...data, callback }))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupEditToolbar))
