// @flow
export const SAVE_GROUP = 'SAVE_GROUP'
export const FETCH_GROUP = 'FETCH_GROUP'
export const FETCH_GROUPS = 'FETCH_GROUPS'
export const CHECK_JOB_BOX = 'CLEAR_CHECK_BOX'
export const CLEAR_CHECK_BOX = 'CLEAR_CHECK_BOX'
export const FETCH_GROUPS_FAILED = 'FETCH_GROUPS_FAILED'
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS'
export const FETCH_GROUP_SUGGESTIONS = 'FETCH_GROUP_SUGGESTIONS'
export const FETCH_GROUP_SUGGESTIONS_FAILED = 'FETCH_GROUP_SUGGESTIONS_FAILED'
export const FETCH_GROUP_SUGGESTIONS_SUCCESS = 'FETCH_GROUP_SUGGESTIONS_SUCCESS'
export const SAVE_GROUP_PERMISSIONS = 'SAVE_GROUP_PERMISSIONS'
export const SAVE_GROUP_PERMISSIONS_SUCCESS = 'SAVE_GROUP_PERMISSIONS_SUCCESS'
export const FETCH_GROUP_PERMISSIONS = 'FETCH_GROUP_PERMISSIONS'
export const FETCH_GROUP_PERMISSIONS_SUCCESS = 'FETCH_GROUP_PERMISSIONS_SUCCESS'
export const DOWNLOAD_GROUPS = 'DOWNLOAD_GROUPS'
export const DELETE_USER_GROUP = 'DELETE_USER_GROUP'

export function fetchGroups(offset, count, search): Object {
  return {
    type: FETCH_GROUPS,
    offset,
    count,
    search
  }
}

export function saveGroup(payload: Object): Object {
  return {
    type: SAVE_GROUP,
    ...payload
  }
}

export function fetchGroup(id: String): Object {
  return {
    type: FETCH_GROUP,
    id
  }
}

export function fetchGroupSuggestions(searchTerm: String): Object {
  return {
    type: FETCH_GROUP_SUGGESTIONS,
    searchTerm
  }
}

export function saveGroupPermissions(permissions: Object, groupId: Number) {
  return {
    type: SAVE_GROUP_PERMISSIONS,
    permissions,
    groupId
  }
}

export function fetchGroupPermissions(groupId: Number) {
  return {
    type: FETCH_GROUP_PERMISSIONS,
    groupId
  }
}

export const downloadGroups = searchTerm => ({
  type: DOWNLOAD_GROUPS,
  searchTerm
})

export const deleteUserGroup = payload => ({
  type: DELETE_USER_GROUP,
  payload
})
