import axios from 'axios'
import cuid from 'cuid'
import { takeLatest, call, put, fork } from 'redux-saga/effects'

import {
  FETCH_ITEM_ATTRIBUTES,
  fetchItemAttributesSuccess,
  fetchItemAttributesFailed
} from '../actions/item-attribute-actions'

import { ALERT_CREATE } from '../actions/app-actions'

export function* fetchItemAttributesFlow() {
  yield takeLatest(FETCH_ITEM_ATTRIBUTES, fetchItemAttributes)
}

function* fetchItemAttributes(action = {}) {
  try {
    const response = yield call(() => axios.get(`/api/admin/idf-dimension/attributes`))
    yield put(fetchItemAttributesSuccess(response.data))
  } catch (error) {
    window.captureException(error)

    yield put({
      type: ALERT_CREATE,
      alerts: [
        {
          id: cuid(),
          type: 'danger',
          message: error.message,
          headline: 'Attribute ID fetch failed'
        }
      ]
    })

    yield put(fetchItemAttributesFailed({ error: error.message }))
  }
}

export default [fork(fetchItemAttributesFlow)]
