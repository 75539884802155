import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Col, ControlLabel, OverlayTrigger, Glyphicon, Tooltip } from 'react-bootstrap'

import Select from 'react-select'
import Seperator from '../../Icon/BreadCrumbSeparator'

export default class FolderSelectPanel extends Component {
  getFolderParentChain = (folder, result = []) => {
    if (!folder || folder.id === 0) {
      return [0]
    }
    result.unshift(folder.id)
    if (folder.parent.id !== 0) {
      return this.getFolderParentChain(folder.parent, result)
    }
    result.unshift(0)
    return result
  }

  renderFolderDropdown = (parentFolder, selectedFolderId) => {
    const { updateFormValue, fetchReportFolders } = this.props
    const subFolders = parentFolder ? parentFolder.subfolders : []
    if (subFolders.length === 0) {
      return null
    }
    // Converting data for <Select /> input
    const subFoldersMod = subFolders.map(folder => ({
      label: folder.name,
      value: folder.id
    }))
    return (
      <Select
        className="select-folder"
        placeholder="Select folder"
        key={parentFolder.id}
        onChange={item => {
          updateFormValue('folderId', item.value)
          fetchReportFolders(item.value)
        }}
        value={subFoldersMod.find(el => el.value === selectedFolderId)}
        options={subFoldersMod}
      />
    )
  }

  render() {
    const { folders, folderId } = this.props
    const mainFolder = folders[folderId]
    const folderParentChain = this.getFolderParentChain(mainFolder, [])
    const foldersArr = folderParentChain.map(id => folders[id]).filter(folder => folder)
    return (
      <FormGroup controlId={'folder'}>
        <Col sm={2}>
          <ControlLabel className="required">
            {'Folder '}
            <OverlayTrigger
              rootClose
              placement="top"
              overlay={
                <Tooltip id="folder-info-tooltip">
                  {'You can leave reports in the home directory by not selecting a subfolder to simulate old behavior.'}
                </Tooltip>
              }
              delayShow={300}
            >
              <Glyphicon glyph="info-sign" />
            </OverlayTrigger>
          </ControlLabel>
        </Col>
        <Col sm={10}>
          <div className="folder-selector d-flex align-items-center">
            <React.Fragment key="root">
              <span className="root-folder pt-4">Home</span>
            </React.Fragment>
            {foldersArr.map((parentFolder, idx) => {
              // Add select dropdown for each parent
              return (
                <React.Fragment key={parentFolder.id}>
                  {parentFolder.subfolders && parentFolder.subfolders.length > 0 && <Seperator />}
                  {this.renderFolderDropdown(parentFolder, folderParentChain[idx + 1])}
                </React.Fragment>
              )
            })}
          </div>
        </Col>
      </FormGroup>
    )
  }
}

FolderSelectPanel.propTypes = {
  folderId: PropTypes.number,
  folders: PropTypes.object,
  updateFormValue: PropTypes.func,
  fetchReportFolders: PropTypes.func
}
