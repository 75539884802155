import { fork, takeLatest, put, select, all, call } from 'redux-saga/effects'
import { get } from 'lodash'
import axios from 'axios'
import moment from 'moment'

import { extractCCDPathParams } from '../utils/delivery-center'

import * as dmActions from '../actions/dm-actions'
import { createAlert } from '../actions/app-actions'
import { clearModals } from '../actions/modal-actions'

const DOWNLOAD_DATETIME = 'MM-DD-YYYY:hh:mm:ss'

// TODO: add error handler actions for all necessary methods

function* fetchCCDCounts(action = {}) {
  try {
    const { showInactive } = action.payload || {}
    const { client: { id: clientId } = {}, contract: { id: contractId } = {} } = extractCCDPathParams()
    yield fetchCCDCountsWithParams(clientId, contractId, showInactive)
  } catch (error) {
    console.error(error)
  }
}

function* fetchCCDCountsWithParams(clientId, contractId, showInactive) {
  try {
    const ccdCount = yield call(() =>
      axios.get('/api/ccd/counts', {
        params: {
          clientId,
          contractId,
          isActive: showInactive ? undefined : true
        }
      })
    )
    if (ccdCount.data) {
      yield put({
        type: dmActions.FETCH_CCD_COUNTS_SUCCESS,
        payload: ccdCount.data
      })
    }
  } catch (error) {
    console.error(error)
  }
}

function* updateSelectedClient(action) {
  try {
    let clientData = {}
    if (action.payload.id) {
      const response = yield call(() => axios.get(`/api/client/${action.payload.id}`))
      clientData = response.data
    }
    yield all([
      put({
        type: dmActions.UPDATE_SELECTED_CLIENT_SUCCESS,
        payload: clientData
      }),
      fetchCCDCountsWithParams(clientData.id)
    ])
  } catch (error) {
    console.error(error)
  }
}

function* updateSelectedContract(action) {
  try {
    let contractData = {}
    if (action.payload.id) {
      const response = yield call(() => axios.get(`/api/contract/${action.payload.id}`))
      contractData = response.data
    }
    yield all([
      put({
        type: dmActions.UPDATE_SELECTED_CONTRACT_SUCCESS,
        payload: contractData
      }),
      fetchCCDCountsWithParams(get(contractData, ['Client', 'id']), contractData.id)
    ])
  } catch (error) {
    console.error(error)
  }
}

function* updateSelectedDeliverable(action) {
  try {
    let deliverableData = {}
    if (action.payload.id === 'new') {
      deliverableData = { ...action.payload }
    } else {
      const response = yield call(() => axios.get(`/api/deliverable/${action.payload.id}`))
      deliverableData = response.data
    }
    yield all([
      put({
        type: dmActions.UPDATE_SELECTED_DELIVERABLE_SUCCESS,
        payload: deliverableData
      })
    ])
  } catch (error) {
    console.error(error)
  }
}

function* fetchClients(action) {
  try {
    const { page, pageSize, sortOrder, sortBy, showInactive } = action.payload || {}
    const offset = (page - 1) * pageSize
    const clients = yield call(() =>
      axios.get('/api/clients', {
        params: {
          offset,
          size: pageSize,
          sortBy,
          sortOrder,
          isActive: showInactive ? undefined : true
        }
      })
    )
    if (clients && clients.data) {
      yield put({
        type: dmActions.FETCH_CLIENTS_SUCCESS,
        payload: clients.data
      })
    }
  } catch (error) {
    console.error(error)
  }
}

function* fetchContracts(action) {
  try {
    const { page, pageSize, sortOrder, sortBy, showInactive } = action.payload || {}
    const offset = (page - 1) * pageSize
    const { client: { id: clientId } = {} } = extractCCDPathParams()
    const contracts = yield call(() =>
      axios.get('/api/contracts', {
        params: {
          offset,
          size: pageSize,
          sortBy,
          sortOrder,
          clientId,
          isActive: showInactive ? undefined : true
        }
      })
    )
    yield put({
      type: dmActions.FETCH_CONTRACTS_SUCCESS,
      payload: contracts.data
    })
  } catch (error) {
    console.error(error)
  }
}

function* fetchDeliverables(action) {
  try {
    const { page, pageSize, sortOrder, sortBy, showInactive } = action.payload || {}
    const offset = (page - 1) * pageSize
    const { client: { id: clientId } = {}, contract: { id: contractId } = {} } = extractCCDPathParams()
    const deliverables = yield call(() =>
      axios.get('/api/deliverables', {
        params: {
          offset,
          size: pageSize,
          sortBy,
          sortOrder,
          clientId,
          contractId,
          isActive: showInactive ? undefined : true
        }
      })
    )
    yield put({
      type: dmActions.FETCH_DELIVERABLES_SUCCESS,
      payload: deliverables.data
    })
    if (deliverables.data && deliverables.data.length) {
      const currentDeliveryIds = deliverables.data
        .map(({ currentDeliveryId }) => currentDeliveryId)
        .filter(deliveryId => deliveryId)
      yield fetchCurrentDeliveriesProgress(currentDeliveryIds)
    }
  } catch (error) {
    console.error(error)
  }
}

function* fetchCurrentDeliveriesProgress(currentDeliveryIds) {
  try {
    if (!currentDeliveryIds || !currentDeliveryIds.length) {
      return
    }
    const deliveryProgress = yield call(() =>
      axios.get('/api/deliveries/progress', {
        params: { ids: currentDeliveryIds }
      })
    )
    yield put({
      type: dmActions.FETCH_CURRENT_DELIVERIES_PROGRESS_SUCCESS,
      payload: deliveryProgress.data
    })
  } catch (error) {
    console.error(error)
  }
}

function* checkIfClientNameAvailable(action) {
  try {
    const { name, id } = action.payload
    const response = yield call(() =>
      axios.get('/api/client/name', {
        params: {
          name
        }
      })
    )
    let isNameAvailable = true
    if (response.data.id && id !== response.data.id) {
      isNameAvailable = false
    }
    yield put({
      type: dmActions.UPDATE_CLIENT_NAME_AVAILABLE,
      payload: {
        name,
        isNameAvailable
      }
    })
  } catch (error) {
    console.error(error)
  }
}

function* checkIfContractNameAvailable(action) {
  try {
    const { name, id, clientId } = action.payload
    const response = yield call(() =>
      axios.get('/api/contract/name', {
        params: {
          name,
          clientId
        }
      })
    )
    let isNameAvailable = true
    if (response.data.id && id !== response.data.id) {
      isNameAvailable = false
    }
    yield put({
      type: dmActions.UPDATE_CONTRACT_NAME_AVAILABLE,
      payload: {
        name,
        isNameAvailable
      }
    })
  } catch (error) {
    console.error(error)
  }
}

function* checkIfDeliverableNameAvailable(action) {
  try {
    const { name, id, clientId, contractId } = action.payload
    const response = yield call(() =>
      axios.get('/api/deliverable/basic/name', {
        params: {
          name,
          clientId,
          contractId
        }
      })
    )
    let isNameAvailable = true
    if (response.data.id && id !== response.data.id) {
      isNameAvailable = false
    }
    yield put({
      type: dmActions.UPDATE_DELIVERABLE_NAME_AVAILABLE,
      payload: {
        name,
        isNameAvailable
      }
    })
  } catch (error) {
    console.error(error)
  }
}

function* saveClient(action) {
  try {
    const { id } = action.payload
    if (!id || id === 'new') {
      yield call(() => axios.post('/api/client', { ...action.payload }))
    } else {
      yield call(() => axios.put('/api/client', { ...action.payload }))
    }
    const { page, pageSize, sortOrder, sortBy } = yield select(state => state.dm.clients)
    yield all([
      put(clearModals()),
      put({ type: dmActions.SAVE_CLIENT_SUCCESS }),
      fetchCCDCounts(),
      fetchClients({ payload: { page, pageSize, sortOrder, sortBy } })
    ])
  } catch (error) {
    console.error(error)
  }
}

function* saveContract(action) {
  try {
    const { id } = action.payload
    if (!id || id === 'new') {
      yield call(() => axios.post('/api/contract', { ...action.payload }))
    } else {
      yield call(() => axios.put('/api/contract', { ...action.payload }))
    }
    const { page, pageSize, sortOrder, sortBy } = yield select(state => state.dm.contracts)
    yield all([
      put(clearModals()),
      put({ type: dmActions.SAVE_CONTRACT_SUCCESS }),
      fetchCCDCounts(),
      fetchContracts({ payload: { page, pageSize, sortOrder, sortBy } })
    ])
  } catch (error) {
    console.error(error)
  }
}

function* initializeContractForm(action) {
  try {
    let contractData = { ...action.payload }
    if (action.payload.id && action.payload.id !== 'new') {
      const response = yield call(() =>
        axios.get(`/api/contract/${action.payload.id}`, {
          params: { details: true }
        })
      )
      contractData = response.data
    }
    yield put({
      type: dmActions.INITIALIZE_CONTRACT_FORM_SUCCESS,
      payload: contractData
    })
  } catch (error) {
    console.error(error)
  }
}

function* searchMoreCCD({ payload }) {
  try {
    const { value, limit = 5, offset, type } = payload
    if (!type || !offset) return
    const response = yield call(() =>
      axios.get(`/api/${type}s/search`, {
        params: { value, offset, limit }
      })
    )
    yield put({
      type: dmActions.SEARCH_MORE_CCD_SUCCESS,
      payload: {
        type,
        items: response.data.hits || []
      }
    })
  } catch (error) {
    yield put({
      type: dmActions.SEARCH_CCD_ERROR
    })
    console.error(error)
  }
}

function* searchCCD({ payload }) {
  const { value, limit = 5 } = payload
  try {
    const promises = []
    const clientSearch = call(() =>
      axios.get('/api/clients/search', {
        params: { value, withCount: true, limit }
      })
    )
    promises.push(clientSearch)
    const contractSearch = call(() =>
      axios.get('/api/contracts/search', {
        params: { value, withCount: true, limit }
      })
    )
    promises.push(contractSearch)
    const deliverableSearch = call(() =>
      axios.get('/api/deliverables/search', {
        params: { value, withCount: true, limit }
      })
    )
    promises.push(deliverableSearch)

    const [clientResponse, contractResponse, deliverableResponse] = yield all(promises)
    const { total: clientsCount, hits: clients } = clientResponse.data
    const { total: contractsCount, hits: contracts } = contractResponse.data
    const { total: deliverablesCount, hits: deliverables } = deliverableResponse.data
    yield put({
      type: dmActions.SEARCH_CCD_SUCCESS,
      payload: {
        clientsCount,
        clients,
        contractsCount,
        contracts,
        deliverablesCount,
        deliverables
      }
    })
  } catch (error) {
    yield put({
      type: dmActions.SEARCH_CCD_ERROR
    })
    console.error(error)
  }
}

function* fetchClientSuggestions(action) {
  try {
    const response = yield call(() =>
      axios.get('/api/clients/search', {
        params: { value: action.clientLookupValue }
      })
    )
    yield put({
      type: dmActions.SEARCH_CLIENTS_SUCCESS,
      payload: get(response, ['data', 'hits'], [])
    })
  } catch (error) {
    console.error(error)
  }
}

function* fetchContractSuggestions(action) {
  try {
    const response = yield call(() =>
      axios.get('/api/contracts/search', {
        params: {
          value: action.contractLookupValue,
          clientId: action.clientId
        }
      })
    )
    yield put({
      type: dmActions.SEARCH_CONTRACTS_SUCCESS,
      payload: get(response, ['data', 'hits'], [])
    })
  } catch (error) {
    console.error(error)
  }
}

function* fetchUserSuggestions(action) {
  try {
    const response = yield call(() =>
      axios.get('/api/users', {
        params: { 'search-ne': action.userLookupValue }
      })
    )
    yield put({
      type: dmActions.SEARCH_USERS_SUCCESS,
      payload: response.data
    })
  } catch (error) {
    console.error(error)
  }
}

function* updateDeliverableStatus(action) {
  try {
    yield call(() => axios.put('/api/deliverable/basic', { ...action.payload }))
    const { page, pageSize, sortOrder, sortBy } = yield select(state => state.dm.deliverables)
    yield all([fetchCCDCounts(), fetchDeliverables({ payload: { page, pageSize, sortOrder, sortBy } })])
  } catch (error) {
    console.error(error)
  }
}

function* fetchDeliveriesProgress(action) {
  const { currentDeliveryIds } = action
  if (currentDeliveryIds.length) {
    yield fetchCurrentDeliveriesProgress(currentDeliveryIds)
  }
}

function* fetchDeliveryLogs(action) {
  try {
    const { page = 1, count = 25, sortOrder = 'desc', sortBy = 'createdAt' } = action.payload

    const offset = (page - 1) * count

    const response = yield call(() =>
      axios.get('/api/delivery-logs', {
        params: {
          offset,
          count,
          sortBy,
          sortOrder
        }
      })
    )

    if (response && response.data) {
      yield put({
        type: dmActions.FETCH_DELIVERY_LOGS_SUCCESS,
        deliveryLogs: response.data
      })
    }
  } catch (error) {
    const errorMessage = `Error fetching delivery logs: ${error.message}`
    console.error(errorMessage)
    yield put({
      type: dmActions.FETCH_DELIVERY_LOGS_FAILURE,
      error: errorMessage
    })
  }
}

function* deleteClient(action) {
  try {
    const { clientId } = action
    yield call(() => axios.delete(`/api/admin/client/${clientId}`))
    const { page, pageSize, sortOrder, sortBy } = yield select(state => state.dm.clients)
    yield all([put(clearModals()), fetchCCDCounts(), fetchClients({ payload: { page, pageSize, sortOrder, sortBy } })])
  } catch (error) {
    console.error(error)
  }
}

function* deleteContract(action) {
  try {
    const { contractId } = action
    yield call(() => axios.delete(`/api/admin/contract/${contractId}`))
    const { page, pageSize, sortOrder, sortBy } = yield select(state => state.dm.contracts)
    yield all([
      put(clearModals()),
      fetchCCDCounts(),
      fetchContracts({ payload: { page, pageSize, sortOrder, sortBy } })
    ])
  } catch (error) {
    console.error(error)
  }
}

function* deleteDeliverable(action) {
  try {
    const { deliverableId } = action
    yield call(() => axios.delete(`/api/admin/deliverable/${deliverableId}`))
    const { page, pageSize, sortOrder, sortBy } = yield select(state => state.dm.deliverables)
    yield all([
      put(clearModals()),
      fetchCCDCounts(),
      fetchDeliverables({ payload: { page, pageSize, sortOrder, sortBy } })
    ])
  } catch (error) {
    console.error(error)
  }
}

function createDownloadLink(blobData, csvName) {
  const url = window.URL.createObjectURL(blobData)
  const downloadLink = document.createElement('a')
  downloadLink.href = url

  downloadLink.setAttribute('download', csvName)

  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

function* downloadClients() {
  const { sortBy, sortOrder } = yield select(state => state.dm.clients)

  yield put(createAlert('success', '', 'Preparing CSV for download'))

  const response = yield call(() =>
    axios({
      url: '/api/clients/download',
      method: 'get',
      responseType: 'blob',
      params: {
        sortBy,
        sortOrder
      }
    })
  )

  createDownloadLink(response.data, `clients-${moment().format(DOWNLOAD_DATETIME)}.csv`)
}

function* downloadContracts() {
  const { sortBy, sortOrder } = yield select(state => state.dm.contracts)

  yield put(createAlert('success', '', 'Preparing CSV for download'))

  const response = yield call(() =>
    axios({
      url: '/api/contracts/download',
      method: 'get',
      responseType: 'blob',
      params: {
        sortBy,
        sortOrder
      }
    })
  )

  createDownloadLink(response.data, `contracts-${moment().format(DOWNLOAD_DATETIME)}.csv`)
}

function* downloadDeliverables() {
  const { sortBy, sortOrder } = yield select(state => state.dm.deliverables)

  yield put(createAlert('success', '', 'Preparing CSV for download'))

  const response = yield call(() =>
    axios({
      url: '/api/deliverables/download',
      method: 'get',
      responseType: 'blob',
      params: {
        sortBy,
        sortOrder
      }
    })
  )

  createDownloadLink(response.data, `deliverables-${moment().format(DOWNLOAD_DATETIME)}.csv`)
}

function* ccdFlow() {
  yield takeLatest(dmActions.FETCH_CCD_COUNTS, fetchCCDCounts)
  yield takeLatest(dmActions.UPDATE_SELECTED_CLIENT, updateSelectedClient)
  yield takeLatest(dmActions.UPDATE_SELECTED_CONTRACT, updateSelectedContract)
  yield takeLatest(dmActions.UPDATE_SELECTED_DELIVERABLE, updateSelectedDeliverable)
  yield takeLatest(dmActions.FETCH_CLIENTS, fetchClients)
  yield takeLatest(dmActions.FETCH_CONTRACTS, fetchContracts)
  yield takeLatest(dmActions.FETCH_DELIVERABLES, fetchDeliverables)
  yield takeLatest(dmActions.CHECK_CLIENT_NAME_AVAILABLE, checkIfClientNameAvailable)
  yield takeLatest(dmActions.CHECK_DELIVERABLE_NAME_AVAILABLE, checkIfDeliverableNameAvailable)
  yield takeLatest(dmActions.SAVE_CLIENT, saveClient)
  yield takeLatest(dmActions.INITIALIZE_CONTRACT_FORM, initializeContractForm)
  yield takeLatest(dmActions.CHECK_CONTRACT_NAME_AVAILABLE, checkIfContractNameAvailable)
  yield takeLatest(dmActions.SAVE_CONTRACT, saveContract)
  yield takeLatest(dmActions.SEARCH_CCD, searchCCD)
  yield takeLatest(dmActions.SEARCH_MORE_CCD, searchMoreCCD)
  yield takeLatest(dmActions.SEARCH_CLIENTS, fetchClientSuggestions)
  yield takeLatest(dmActions.SEARCH_CONTRACTS, fetchContractSuggestions)
  yield takeLatest(dmActions.SEARCH_USERS, fetchUserSuggestions)
  yield takeLatest(dmActions.UPDATE_DELIVERABLE_STATUS, updateDeliverableStatus)
  yield takeLatest(dmActions.FETCH_CURRENT_DELIVERIES_PROGRESS, fetchDeliveriesProgress)
  yield takeLatest(dmActions.FETCH_DELIVERY_LOGS, fetchDeliveryLogs)
  yield takeLatest(dmActions.DELETE_CLIENT, deleteClient)
  yield takeLatest(dmActions.DELETE_CONTRACT, deleteContract)
  yield takeLatest(dmActions.DELETE_DELIVERABLE, deleteDeliverable)
  yield takeLatest(dmActions.DOWNLOAD_CLIENTS, downloadClients)
  yield takeLatest(dmActions.DOWNLOAD_CONTRACTS, downloadContracts)
  yield takeLatest(dmActions.DOWNLOAD_DELIVERABLES, downloadDeliverables)
}

export default [fork(ccdFlow)]
