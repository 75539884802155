import React from 'react'
import PropTypes from 'prop-types'
import FolderItem from './FolderItem'
import cx from 'classnames'

export default function Folder(props) {
  const { className, id } = props
  return <FolderItem dragKey={`folder-${id}`} {...props} className={cx('folder', className)} />
}

Folder.defaultProps = {
  ...FolderItem.defaultProps,
  className: 'folder',
  glyph: 'folder-close',
  dragOverDelay: 1000,
  droppable: true,
  tabIndex: 0
}

Folder.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string
}
