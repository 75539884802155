import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'

export default class Supra extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object,
    fill: PropTypes.string
  }

  static defaultProps = {
    width: 30,
    height: 20,
    fill: 'currentColor'
  }

  render() {
    return (
      <IconBase viewBox="0 0 30 20" {...this.props}>
        <g>
          <path d="M24.7288 8.29233L21.7458 6.05476V4.30694C21.7458 3.93253 21.4376 3.62903 21.0574 3.62903H18.5116L15.4068 1.30008C15.1658 1.11934 14.8342 1.11934 14.5932 1.30008L11.4884 3.62896H8.94263C8.56242 3.62896 8.25421 3.93253 8.25421 4.30688V6.05476L5.27118 8.29233C5.1004 8.42041 5 8.62136 5 8.83476V18.1575C5 18.532 5.30357 18.8355 5.67791 18.8355H24.322C24.6964 18.8355 24.9999 18.5319 24.9999 18.1575V8.83476C25 8.62136 24.8996 8.42041 24.7288 8.29233ZM6.35596 9.93173L10.6617 12.0847L6.35596 16.4201V9.93173V9.93173ZM11.9343 12.7212L14.6968 14.1024C14.7923 14.1502 14.8961 14.174 15 14.174C15.1039 14.174 15.2077 14.1502 15.3032 14.1024L18.0657 12.7212L22.7918 17.4796H7.20828L11.9343 12.7212ZM19.3383 12.0847L23.6441 9.93173V16.4201L19.3383 12.0847ZM23.0377 8.71886L21.7457 9.36492V7.74984L23.0377 8.71886ZM15 2.68991L16.2519 3.62896H13.7481L15 2.68991ZM20.3691 4.98486V10.0533L15 12.738L9.63091 10.0532V4.98486H20.3691ZM8.25421 9.36492L6.96222 8.71886L8.25421 7.74977V9.36492Z" />
          <path d="M12.6696 8.34713H17.3307C17.705 8.34713 18.0086 8.04356 18.0086 7.66915C18.0086 7.29474 17.705 6.99124 17.3307 6.99124H12.6696C12.2952 6.99124 11.9917 7.29481 11.9917 7.66915C11.9917 8.04356 12.2952 8.34713 12.6696 8.34713Z" />
          <path d="M12.6696 10.6779H17.3307C17.705 10.6779 18.0086 10.3743 18.0086 10C18.0086 9.62565 17.705 9.32208 17.3307 9.32208H12.6696C12.2952 9.32208 11.9917 9.62565 11.9917 10C11.9917 10.3743 12.2952 10.6779 12.6696 10.6779Z" />
        </g>
      </IconBase>
    )
  }
}
