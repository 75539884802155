/* eslint-disable max-len */

/*!
 * deps
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IconBase from './IconBase'

/**
 * ArrowUp component
 */
class ArrowUp extends Component {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    style: PropTypes.object
  }

  render() {
    const { ...usefulProps } = this.props
    return (
      <IconBase viewBox="0 0 30 30" {...usefulProps}>
        <path d="M19.9 19.5l-5.4 -5.5l-5.4 5.5z" {...usefulProps} />
      </IconBase>
    )
  }
}

ArrowUp.defaultProps = {
  width: 30,
  height: 20,
  fill: 'none'
}

export default ArrowUp
