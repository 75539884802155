import React from 'react'

import { products } from 'constants/constants'
import usePermissions from './../usePermissions'
import SubMenuItem from './../SubMenuItem'

const AdminSubMenu = () => {
  const { isGroupAdmin, isPortalAdmin, hasAdminToolsAccess, isProductAuthorized } = usePermissions()

  // default for all environments
  const menuItems = [
    {
      label: 'Groups',
      route: '/admin/groups',
      isAuthorized: isPortalAdmin
    },
    {
      label: 'Users in group',
      route: '/admin/users',
      isAuthorized: isGroupAdmin || isPortalAdmin
    },
    {
      label: 'Reports Manager',
      route: '/admin/reports',
      isAuthorized:
        (isPortalAdmin || hasAdminToolsAccess) && isProductAuthorized(products.adminToolsCustomReportManager)
    },
    {
      label: 'Reports Manager',
      route: '/admin/report/folders',
      isBeta: true,
      isAuthorized:
        (isPortalAdmin || hasAdminToolsAccess) && isProductAuthorized(products.adminToolsCustomReportManager)
    }
  ]

  if (window.Tenant === 'ri') {
    menuItems.push(
      {
        label: 'Data Feeds',
        route: '/admin/feeds',
        isAuthorized: (isPortalAdmin || hasAdminToolsAccess) && isProductAuthorized(products.adminToolsFeeds)
      },
      {
        label: 'Delivery Manager',
        route: '/admin/dm',
        isAuthorized: isPortalAdmin || isProductAuthorized(products.adminToolsDeliveryManager)
      },
      {
        label: 'Delivery Activity',
        route: '/admin/delivery-logs',
        isAuthorized: isPortalAdmin || isProductAuthorized(products.adminToolsDeliveryManager)
      },
      {
        label: 'Login Activity',
        route: '/admin/auth-logs',
        isAuthorized: isPortalAdmin
      },
      {
        label: 'Job Monitoring',
        route: '/admin/jobs',
        isAuthorized: (isPortalAdmin || hasAdminToolsAccess) && isProductAuthorized(products.adminToolsJobMonitoring)
      }
    )
  }

  return (
    <>
      {menuItems.map(
        (item, index) =>
          item.isAuthorized && <SubMenuItem key={index} label={item.label} route={item.route} isBeta={item.isBeta} />
      )}
    </>
  )
}

export default AdminSubMenu
