import React from 'react'
import PropTypes from 'prop-types'

import { FormGroup, FormControl, Col, ControlLabel, HelpBlock } from 'react-bootstrap'

export default function TextInputField({ id, label, help, validationState, required, actionComponent, ...props }) {
  return (
    <FormGroup controlId={id} validationState={validationState}>
      <Col sm={2}>
        <ControlLabel className={required ? 'required' : ''}>{label}</ControlLabel>
      </Col>
      <Col sm={6}>
        <FormControl {...props} />
        {validationState && <FormControl.Feedback />}
        {help && <HelpBlock>{help}</HelpBlock>}
      </Col>
      {actionComponent && <Col sm={4}>{actionComponent}</Col>}
    </FormGroup>
  )
}

TextInputField.defaultProps = {
  actionComponent: null
}

TextInputField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
  validationState: PropTypes.string,
  required: PropTypes.bool,
  actionComponent: PropTypes.node
}
