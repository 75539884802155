import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { isTokenExpired } from '../../utils/jwt-helper'
import { parse } from 'query-string'

import Loader from '../Loader'
import Box from '../Box'
import Input from '../Input'
import LockIcon from '../Icon/Lock'
import { OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import DocumentTitle from 'react-document-title'

import { resetPassword, logout } from '../../actions/auth-actions'

import './index.scss'
import validateRouteProtocol from '../../utils/enforce-https'
import { isPasswordValid, PASSWORD_VALIDATION_MESSAGE } from '../../utils/auth-helper'
import { appName } from '../../constants/constants'

/**
 * Reset Password component
 */
class ResetPasswordPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      confirmPassword: '',
      errorType: ''
    }
    validateRouteProtocol()
  }

  componentDidMount() {
    this.props.logoutExistingSession()
    this.verifyResetPasswordToken()
  }

  verifyResetPasswordToken = () => {
    if (isTokenExpired(this.props.token)) {
      this.setState({ errorType: 'invalidToken' })
    }
  }

  didChangePassword = (type, value) => {
    let errorType = ''
    if (type === 'confirmPassword' && !this.isPasswordValid()) {
      errorType = 'invalidPassword'
    } else if (type === 'confirmPassword' && value !== this.state.password) {
      errorType = 'unmatchedPassword'
    }
    this.setState({ [type]: value, errorType })
  }

  isPasswordValid = () => {
    return isPasswordValid(this.state.password)
  }

  handleSubmitLogin(event) {
    event.preventDefault()
    if (isEmpty(this.state.password) || isEmpty(this.state.confirmPassword)) {
      this.setState({ errorType: 'emptyPassword' })
      return
    }
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ errorType: 'unmatchedPassword' })
      return
    }
    if (!this.isPasswordValid()) {
      this.setState({ errorType: 'invalidPassword' })
      return
    }
    this.props.onResetPassword({
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword
    })
  }

  loadPasswordFormat = () => {
    return (
      <Tooltip id="refresh-load-password-format">
        <div>
          At least 8 characters in length
          <br />
          Contains at least 3 of the following 4 types of characters:
          <br />
          Lower case letters (a-z)
          <br />
          Upper case letters (A-Z)
          <br />
          Numbers (i.e. 0-9)
          <br />
          Special characters (e.g. !@#$%^&*)
        </div>
      </Tooltip>
    )
  }

  redirectToForgotPassword = () => {
    this.props.history.push('/forgot_password')
  }

  render() {
    let message = ''
    const errorType = this.props.sessionMessageType || this.state.errorType
    switch (errorType) {
      case 'unmatchedPassword':
        message = 'Please ensure the password and the confirm password values are same'
        break
      case 'emptyPassword':
        message = 'Please ensure the password and the confirm password fields are not empty'
        break
      case 'invalidPassword':
        message = PASSWORD_VALIDATION_MESSAGE
        break
      case 'invalidToken':
        message = 'Sorry! This link is either invalid or has expired'
        break
      case 'NoUserError':
        message = 'User not found'
        break
      case 'Error':
        message = 'There was an error resetting password'
        break
      default:
        break
    }

    return (
      <DocumentTitle title={this.props.documentTitle ? `${appName} | ${this.props.documentTitle}` : appName}>
        <form onSubmit={this.handleSubmitLogin.bind(this)}>
          <Box className="authView alignItemsCenter justifyContentCenter resetPasswordPage">
            <Box className="authBox alignItemsCenter directionColumn">
              <>
                <h1>RESET PASSWORD</h1>
                {this.state.errorType === 'invalidToken' ? (
                  <div>
                    <h4>{message}</h4>
                    <span onClick={this.redirectToForgotPassword} className="forgot-link btn_link">
                      Forgot Password
                    </span>
                  </div>
                ) : (
                  <div style={{ width: '100%' }}>
                    <div>
                      <p>
                        <span style={{ color: 'white' }}>
                          Enter a new password for
                          <br />
                          {this.props.email}
                        </span>
                      </p>
                    </div>
                    <br />
                    <div className="errorMessage">{message}</div>
                    <div style={{ width: '60%', margin: 'auto' }}>
                      <div style={{ paddingBottom: '20px', paddingLeft: '360px' }}>
                        <OverlayTrigger placement="left" overlay={this.loadPasswordFormat()}>
                          <Glyphicon className="pull-right" glyph="info-sign" />
                        </OverlayTrigger>
                      </div>
                      <Input
                        id="password"
                        className="password inputWithBackgroundColor inputWithDarkBackground"
                        iconName={<LockIcon width="24" height="24" />}
                        type="password"
                        value={this.state.password}
                        textDidChange={val => this.didChangePassword('password', val)}
                        placeholder="Your new password"
                        tabIndex="2"
                      />

                      <Input
                        id="confirm-password"
                        className="password inputWithBackgroundColor inputWithDarkBackground"
                        iconName={<LockIcon width="24" height="24" />}
                        type="password"
                        value={this.state.confirmPassword}
                        textDidChange={val => this.didChangePassword('confirmPassword', val)}
                        placeholder="Confirm your new password"
                        tabIndex="2"
                      />

                      {!this.props.loading && (
                        <input
                          style={{ backgroundColor: 'rgb(252, 168, 49)' }}
                          className="submit"
                          value="Submit"
                          type="submit"
                        />
                      )}

                      {this.props.loading && (
                        <div className="submit loading">
                          <Loader height={45} />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="footer flex1" />
              </>
            </Box>
          </Box>
        </form>
      </DocumentTitle>
    )
  }
}

ResetPasswordPage.propTypes = {
  onResetPassword: PropTypes.func,
  logoutExistingSession: PropTypes.func,
  sessionMessageType: PropTypes.string,
  sessionMessage: PropTypes.string,
  loading: PropTypes.bool,
  email: PropTypes.string,
  token: PropTypes.string,
  isTokenExpired: PropTypes.func,
  history: PropTypes.object.isRequired,
  documentTitle: PropTypes.string
}

ResetPasswordPage.defaultProps = {
  documentTitle: 'Reset Password'
}

// =====================================
//  CONNECT
// -------------------------------------

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onResetPassword: payload =>
      dispatch(
        resetPassword({
          ...payload,
          callback: () => ownProps.history.push('/login')
        })
      ),
    logoutExistingSession: () => dispatch(logout(true))
  }
}

function mapStateToProps(state) {
  const { email, token } = parse(state.router.location.search)
  return {
    email,
    token,
    loading: state.session.loading,
    sessionMessageType: state.session.sessionMessageType,
    sessionMessage: state.session.sessionMessage
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage)
