// MOCK for disabling sockets
export const ETL_JOBS_ROOM = 'ETL_JOBS_ROOM'
export const DICT_EDIT_ROOM = 'DICT_EDIT_ROOM'
export default store => {
  return next => action => {
    return next(action)
  }
}
export const joinRoom = () => {}
export const leaveRoom = () => {}
// /MOCK

// DISABLED due to IN-931
/*
import Io from 'socket.io-client'
import config from './config'
import { isEmpty } from 'lodash'

export const ETL_JOBS_ROOM = 'ETL_JOBS_ROOM'
export const DICT_EDIT_ROOM = 'DICT_EDIT_ROOM'

const logEnabled = config.log.socket.enabled
const IS_TEST_ENV = !!window.Cypress
let rootSocket
let reduxStore
const rooms = {}

const connectedIfNeeded = () => {
  if (rootSocket.disconnected) {
    console.info('Room added. Establishing connection...')
    rootSocket.connect()
  }
}

const disconnectIfNotNeeded = () => {
  if (isEmpty(rooms)) {
    console.info('No rooms connected. Destroying connection...')
    rootSocket.disconnect()
  }
}

const addRoom = room => {
  rooms[room] = true
  connectedIfNeeded()
}
const removeRoom = room => {
  delete rooms[room]
  disconnectIfNotNeeded()
}

const initSocket = () => {
  if (IS_TEST_ENV) {
    console.info('Skipping rootSocket setup in TEST ENV')
    return
  }
  // creating a socket but not postponing connection until needed

  // TODO: verify effect of changing URL to app url or api url
  // on staging & production. Especially with { transports: ['websocket'] }
  rootSocket = new Io('/', { autoConnect: false })
  rootSocket.on('redux-action', function(data) {
    reduxStore.dispatch(data)
  })
  rootSocket.on('connect', function() {
    if (logEnabled) {
      console.info('connection created')
    }
  })
  rootSocket.on('disconnect', function(data) {
    if (logEnabled) {
      console.info('disconnected from server', data)
    }
  })
  rootSocket.on('connection-accepted', function(data) {
    if (logEnabled) {
      console.info('connection-accepted', data)
    }
  })
  rootSocket.on('reconnecting', attemptNumber => {
    if (logEnabled) {
      console.info('reconnecting... Attempt No: ', attemptNumber)
    }
  })
  rootSocket.on('reconnect_failed', () => {
    if (logEnabled) {
      console.info('reconnect_failed')
    }
  })
  rootSocket.on('reconnect', attemptNumber => {
    if (logEnabled) {
      console.info('reconnection successful. Attempt No: ', attemptNumber)
    }
    disconnectIfNotNeeded()
  })
}

export const joinRoom = room => {
  if (IS_TEST_ENV) {
    console.info('skipping joinRoom in TEST ENV')
    return
  }
  console.info('joining room', room)
  addRoom(room)
  rootSocket.emit('join', room)
}

export const leaveRoom = room => {
  if (IS_TEST_ENV) {
    console.info('skipping leaveRoom in TEST ENV')
    return
  }
  console.info('leaving room', room)
  rootSocket.emit('leave', room)
  removeRoom(room)
}

export default store => {
  reduxStore = store
  console.info('Initializing socket connection')
  initSocket()
  return next => action => {
    return next(action)
  }
}
 */
