import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'
import { isEmpty } from 'lodash'

export default class Header extends Component {
  render() {
    let { userEdit, user, id } = this.props
    if (isEmpty(userEdit)) {
      userEdit = user
    }

    return id === 'new' || !userEdit.isOnboarded ? null : (
      <div className="greyHeader">
        <div className={cx('clearfix')}>
          <div className="userInfo">
            <div className="userTitle">{userEdit.fullname}</div>
            <div>{userEdit.email}</div>
            <div>
              {`Last Login: ${
                userEdit.lastLoggedInAt ? moment(userEdit.lastLoggedInAt).format('MMMM DD, YYYY HH:mm A') : 'N/A'
              }`}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  id: PropTypes.string,
  user: PropTypes.object,
  userEdit: PropTypes.object
}

Header.defaultProps = {
  id: '',
  user: {},
  userEdit: {}
}
