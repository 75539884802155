/* eslint-disable max-len */

/*!
 * deps
 */

import React from 'react'
import IconBase from './IconBase'

/**
 * CheckMark component
 */
const Remove = props => {
  return (
    <IconBase viewBox="0 0 30 30" {...props}>
      <path d="M8 16h14v-3h-14v3z" />
    </IconBase>
  )
}

export default Remove
