import { call, put, fork, takeLatest } from 'redux-saga/effects'
import axios from 'axios'

import { FETCH_ENTRIES, FETCH_ENTRIES_DONE, RESOLVE_LOCATION, RESOLVE_LOCATION_DONE } from '../actions/authlog-actions'

function* loadAuthlogs(action = {}) {
  const { sizePerPage, pageNumber, searchField, searchValue } = action
  try {
    const response = yield call(() =>
      axios.get('/api/admin/auth-logs', {
        params: {
          offset: (pageNumber - 1) * sizePerPage,
          limit: sizePerPage,
          field: searchField,
          value: searchValue
        }
      })
    )
    yield put({
      type: FETCH_ENTRIES_DONE,
      data: response.data,
      sizePerPage,
      pageNumber
    })
  } catch (err) {
    window.captureException(err)
    yield put({ type: FETCH_ENTRIES_DONE, error: err.message })
  }
}

function* resolveLocation({ ipList = [] }) {
  let ipLocationMap = {}
  try {
    const response = yield call(() => axios.post('/api/admin/utils/ipinfo', { ipList }))
    ipLocationMap = response.data
  } catch (error) {
    console.error(error)
  }
  yield put({
    type: RESOLVE_LOCATION_DONE,
    data: ipLocationMap
  })
}

function* authLogsFlow() {
  yield takeLatest(FETCH_ENTRIES, loadAuthlogs)
  yield takeLatest(RESOLVE_LOCATION, resolveLocation)
}

export default [fork(authLogsFlow)]
