import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './index.scss'

const noop = () => {}
class Radio extends Component {
  render() {
    const { checked, onChange, className, withGap, disabled } = this.props
    return (
      <React.Fragment>
        <span className="radio-btn-wrapper" onClick={onChange}>
          <input
            onChange={noop}
            type="radio"
            checked={checked}
            className={cx('radio-btn', className, {
              'with-gap': withGap
            })}
            disabled={disabled}
          />
          <span />
        </span>
      </React.Fragment>
    )
  }
}

Radio.defaultProps = {
  checked: false,
  onChange: noop,
  withGap: false,
  disabled: false
}

Radio.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  withGap: PropTypes.bool,
  disabled: PropTypes.bool
}

export default Radio
